import { GET_REPORTS_URL } from '../../constants/api';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';
import { handleFileDownload } from '../CommonUtilityServices/DownloadFileService';

const getGenerateReportRequestBody = (e, formData) => {
  let requestBody = {
    Area: 'losses-by-agent',
    DetailedContext: [
      {
        name: 'period',
        value: formData.period,
      },
      {
        name: 'agencyCode',
        value: formData.agencyCode,
      },
    ],
    TypeOfRedirect: 'Generate-Report',
  };
  return requestBody;
};

const handleAgencyCodeOnClick = (e, formData, setFormData) => {
  setFormData({ ...formData, agencyCode: e.target.value });
};

const handleOnChangeMonthYear = (e, formData, setFormData) => {
  const period = e.target.value;
  setFormData({ ...formData, period });
};

const handleCreateLbaReport = async (
  e,
  formData,
  setLoadingFile,
  setIsDownloadingFileErrorVisible,
  setDownloadingFileError,
  permAgenciesOptions,
) => {
  const exportType = e.currentTarget.getAttribute('data-export-type');
  let reportRequestBody = getGenerateReportRequestBody(e, formData);
  reportRequestBody.DetailedContext.push({ name: 'exportType', value: exportType });
  if (!formData.period) {
    setIsDownloadingFileErrorVisible(true);
    setLoadingFile(false);
    setDownloadingFileError('Please select a Month/Year');
    return;
  }
  if (!formData.agencyCode) {
    setIsDownloadingFileErrorVisible(true);
    setLoadingFile(false);
    setDownloadingFileError(
      `Please select an agency${permAgenciesOptions.length ? '' : ' name or enter agency code'}`,
    );
    return;
  }
  try {
    setDownloadingFileError('');
    const reportsGenerateUrl = GET_REPORTS_URL;
    if (exportType === 'pdf') {
      handlePdfDownload(
        'POST',
        reportRequestBody,
        reportsGenerateUrl,
        setLoadingFile,
        setDownloadingFileError,
        setIsDownloadingFileErrorVisible,
        'newWindow',
      );
    } else if (exportType === 'excel') {
      handleFileDownload(
        reportsGenerateUrl,
        setLoadingFile,
        setDownloadingFileError,
        setIsDownloadingFileErrorVisible,
        null,
        'POST',
        reportRequestBody,
      );
    } else {
      setDownloadingFileError('Export type not supported');
      return;
    }
  } catch (error) {
    console.error(error);
  }
};

export { handleAgencyCodeOnClick, handleCreateLbaReport, handleOnChangeMonthYear };
