import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../../AORedesign';
import WorkflowDialog from './WorkflowDialog';

const WorkflowViewContainer = ({ appBarTitle, block, buttonLabel, children, startIcon, handleResetOnClose, TriggerButton }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    // call method if passed/defined
    if (typeof handleResetOnClose === 'function') {
      handleResetOnClose();
    }
  };

  const renderTrigger = () => {
    if (TriggerButton) {
      return React.createElement(TriggerButton, { onClick: handleClickOpen });
    } else {
      return (
        <Button variant="text" color="secondary" onClick={handleClickOpen} startIcon={startIcon}>
          {buttonLabel}
        </Button>
      );
    }
  };

  return (
    <>
      {renderTrigger()}
      <WorkflowDialog appBarTitle={appBarTitle} handleClose={handleClose} open={open} block={block}>
        {children}
      </WorkflowDialog>
    </>
  );
};

WorkflowViewContainer.propTypes = {
  appBarTitle: PropTypes.string,
  block: PropTypes.bool,
  buttonLabel: PropTypes.string,
  children: PropTypes.node,
  handleResetOnClose: PropTypes.func,
  startIcon: PropTypes.node,
  TriggerButton: PropTypes.node,
};

export default WorkflowViewContainer;
