import React from 'react';

import { BrandFolder } from '../BrandFolder';

const AgencyToolkitMarketingMaterials = () => {
  return (
    <BrandFolder
      title="Marketing Materials"
      typeOfContainer="Marketing"
      titleAsSubtitle
      showSupplyOrderButton
    />
  );
};

export default AgencyToolkitMarketingMaterials;
