import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { ViewContainer } from '../Layout';
import { AOButton } from '../AOButton';
import { ROUTE_NEWS_ARTICLE_ADMIN } from '../../constants/routes';

const NewsAdminViewContainer = ({ children, title }) => {
  return (
    <ViewContainer title={title}>
      <Box mb={2} display={'flex'} justifyContent={'flex-end'}>
        <AOButton
          color={'secondary'}
          route={ROUTE_NEWS_ARTICLE_ADMIN}
          id="user-admin-container-button-user-admin">
          News Administration
        </AOButton>
      </Box>
      {children}
    </ViewContainer>
  );
};

NewsAdminViewContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default NewsAdminViewContainer;
