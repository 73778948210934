import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Box, Card, Col, Divider, Row, Typography } from 'components/AORedesign';
import { PolicyDetails } from 'components/AORedesignViews/PolicyDetails';
import { handleBondsRedirect } from 'components/Workflow/WorkflowCardBonds';
import { formatDateToYYYYMMDD } from 'helpers/dateHelpers';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getPolicyAsync, setSelectedCachedPolicy } from 'redux/app-store';
import ClientCardPolicySummary from './ClientCardPolicySummary';

const useStyles = makeStyles(() => ({
  clientCard: {
    boxShadow: '',
    '&:hover': {
      boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    },
  },
  actionArea: {
    cursor: 'pointer',
  },
  accountName: {
    fontSize: '1.125rem',
    fontWeight: 500,
  },
}));

// get inquiryDate for policy details based on policy effective date and expiry date
export const getPolicyInquiryDate = (policyEffectiveDate, policyExpirationDate) => {
  const effectiveDate = moment(policyEffectiveDate, 'MM/DD/YYYY');
  const expirationDate = moment(policyExpirationDate, 'MM/DD/YYYY');
  const currentDate = moment().startOf('day'); // currrent date

  // when currentDate is greater than PolicyEffectiveDate or less than PolicyExpirationDate
  // then inquiryDate will be currentDate
  let inquiryDate = currentDate;
  if (currentDate.isAfter(expirationDate) || currentDate.isSame(expirationDate)) {
    // 1. when currentDate equals PolicyExpirationDate
    // policy term expired on this date
    // then inquiryDate will be (PolicyExpirationDate - 1day)
    // 2. when currentDate is greater than PolicyExpirationDate
    // we have expired term for policy
    // then inquiryDate will be (PolicyExpirationDate - 1day)
    inquiryDate = expirationDate.subtract(1, 'days');
  } else if (currentDate.isBefore(effectiveDate) || currentDate.isSame(effectiveDate)) {
    // 1. when currentDate is less than PolicyEffectiveDate
    // policy term has not started yet (policy renewals)
    // then inquiryDate will be (PolicyEffectiveDate)
    // 2. when currentDate equals PolicyEffectiveDate
    // policy term has started on currentDate
    // then inquiryDate will be (PolicyEffectiveDate)
    inquiryDate = effectiveDate;
  }

  return inquiryDate;
};

const ClientCard = ({ account }) => {
  const dispatch = useDispatch();
  const selectedCachedPolicy = useSelector((state) => state.app.selectedPolicyNumber);
  const selectedCachedPolicyArr = selectedCachedPolicy ? selectedCachedPolicy.split('_') : [];
  const selectedPolicyNumber = selectedCachedPolicyArr?.length > 0 ? selectedCachedPolicyArr[0] : null;
  const selectedInquiryDate = selectedCachedPolicyArr?.length > 0 ? selectedCachedPolicyArr[selectedCachedPolicyArr.length - 1] : null;

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  // read policies from Ent Policy Service upon expand of account
  const handleToggleExpandOnClick = (policies) => {
    const selection = window.getSelection().toString();

    if (selection.length === 0) {
      setExpanded((prevState) => !prevState);
    }

    if (!expanded) {
      // grab all policy details for this account and place in app-store cachedPolicies (redux store)
      for (const policy of policies) {
        const policyNumber = policy.PolicyNumber?.trim();
        const inquiryDate = formatDateToYYYYMMDD(getPolicyInquiryDate(policy.PolicyEffectiveDate, policy.PolicyExpirationDate));
        // read and place this policy detail in app-store cachedPolicies (redux store) if it is not already there
        const policyNumberInquiryDate = `${policyNumber}_${inquiryDate}`;
        dispatch(getPolicyAsync(policyNumberInquiryDate));
      }
    }
  };

  const phoneNumber = account.PhoneNumber?.trim();
  const formattedPhoneNumber = phoneNumber.length > 0 ? `+1 (${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}` : '';

  const handlePolicyOnClick = (policy) => {
    if (policy.PolicyTypeCode == '65' || policy.PolicyTypeCode == '64') {
      handleBondsRedirect();
    } else {
      dispatch(
        setSelectedCachedPolicy(
          `${policy.PolicyNumber?.trim()}_${formatDateToYYYYMMDD(getPolicyInquiryDate(policy.PolicyEffectiveDate, policy.PolicyExpirationDate))}`,
        ),
      );
    }
  };

  // Grab the policy object from the client list account policies array
  const getPolicyFromAccount = (policyNumber, inquiryDate) => {
    return account?.Policies?.find(
      (policy) =>
        policy.PolicyNumber?.trim() === policyNumber &&
        formatDateToYYYYMMDD(getPolicyInquiryDate(policy.PolicyEffectiveDate, policy.PolicyExpirationDate)) === inquiryDate,
    );
  };

  // Grab some policy info from the client list account policies array
  //   (If we had the client list accounts in the store, this info could be placed in
  //    the policy in the store's cachedPolicies array.)
  const getPolicySummary = (policyNumber, inquiryDate) => {
    const policyDetails = getPolicyFromAccount(policyNumber, inquiryDate);
    const summary = {
      accountNumber: account.AccountNumber,
      primaryInsured: account.PrimaryInsured,
      policyNumber: policyNumber,
      product: `${policyDetails?.BusinessTypeCode} ${policyDetails?.PolicyTypeDescr}`,
      startDate: policyDetails?.PolicyEffectiveDate,
      endDate: policyDetails?.PolicyExpirationDate,
      phoneNumber: formattedPhoneNumber,
      expiring: policyDetails?.IsPolicyExpiring,
      overdue: policyDetails?.HasOverduePayment,
      policyType: policyDetails?.PolicyTypeCode,
    };
    return summary;
  };

  return (
    <>
      <Box mb={2} className={classes.clientCard}>
        <Card>
          <Box m={-2}>
            <Box p={3} onMouseUp={() => handleToggleExpandOnClick(account?.Policies)} className={classes.actionArea}>
              <Row spacing={3}>
                <Col xs={3}>
                  <Typography color="textPrimary" className={classes.accountName}>
                    {account.PrimaryInsured}
                  </Typography>
                  <Typography color="textPrimary" t={1} className={classes.accountName}>
                    {account.SecondaryInsured}
                  </Typography>
                  <Typography color="textSecondary" t={1} variant="body1">
                    Billing Account #{account.AccountNumber}
                  </Typography>
                </Col>
                <Col xs={3}>
                  <Typography color="textSecondary" variant="body1">
                    {account.Street}
                  </Typography>
                  <Typography color="textSecondary" variant="body1">
                    {account.AddressLine2}
                  </Typography>
                  <Typography color="textSecondary" variant="body1">
                    {account.City?.trim()}, {account.State} {account.PostalCode}
                  </Typography>
                </Col>
                <Col xs={3}>
                  <Typography color="textSecondary" variant="body1">
                    {formattedPhoneNumber}
                  </Typography>
                </Col>
              </Row>
            </Box>

            {account?.Policies?.length > 0 && expanded && (
              <>
                <Divider />
                <Box mt={2} p={3}>
                  {account?.Policies?.map((policy) => (
                    <ClientCardPolicySummary
                      key={`${account.AccountNumber}-${policy.PolicyNumber}`}
                      accountNumber={account.AccountNumber}
                      policy={{ ...policy, Product: `${policy?.BusinessTypeCode} ${policy?.PolicyTypeDescr}` }}
                      handlePolicyOnClick={() => handlePolicyOnClick(policy)}
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Card>
      </Box>
      {selectedPolicyNumber && getPolicyFromAccount(selectedPolicyNumber, selectedInquiryDate) && (
        <PolicyDetails policySummary={getPolicySummary(selectedPolicyNumber, selectedInquiryDate)} />
      )}
    </>
  );
};

ClientCard.propTypes = {
  account: PropTypes.object,
};

export default ClientCard;
