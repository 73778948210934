import React from 'react';

import { Box, Typography } from 'components/AORedesign';
import { UserAdminAddUserLink, UserAdminEditUserLink } from 'components/AORedesignViews/UserAdmin';

const UserManagement = () => {
  return (
    <>
      <Box mt={1} mb={3}>
        <Typography variant="h6">User Management</Typography>
      </Box>
      <Box mb={1}>
        <UserAdminAddUserLink />
      </Box>
      <Box>
        <UserAdminEditUserLink />
      </Box>
    </>
  );
};

export default UserManagement;
