import React from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import FarmRanchSkeleton from './FarmRanchSkeleton';
// import { StyledFarmRanch } from './FarmRanch.styled';

const PanelComponents = {};

const FarmRanch = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`FarmRanch`);

  const localStateMap = {};

  const callBackFunctions = {};

  return loadingSd ? (
    <FarmRanchSkeleton />
  ) : (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          stateMap={localStateMap}
          functionMap={callBackFunctions}
          componentMap={PanelComponents}
        />
      )}
    </>
  );
};

export default FarmRanch;
