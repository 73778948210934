const ALPHA_NUMERIC_REGEX = /[^0-9a-z-]/gi;

const componentIdStringGenerator = (componentName, id) => {
  if (componentName?.length > 0) {
    const sanitizeString = (string) =>
      string?.toLowerCase()?.split(' ')?.join('-')?.replace(ALPHA_NUMERIC_REGEX, '');

    return `ao-${sanitizeString(componentName)}${
      id?.length > 0 ? `-${sanitizeString(id?.toString())}` : ''
    }`;
  }
};

const currencyStringFormatter = (text) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(text);
};

export { ALPHA_NUMERIC_REGEX, componentIdStringGenerator, currencyStringFormatter };
