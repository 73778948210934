import React, { useState } from 'react';
import cuid from 'cuid';
import { Box, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const AgentLookupResultsSkeleton = () => {
  const [skeletonItems] = useState(Array.from(Array(12).keys()));

  return (
    <Box>
      <Box>
        <Skeleton
          width={175}
          style={{ marginRight: `24px`, padding: `6px 8px` }}
          animation="wave"
        />
      </Box>
      <Box display="flex" mx={2}>
        <Skeleton
          width={300}
          animation="wave"
          style={{ margin: `10px 24px 10px 0px`, padding: `6px 8px` }}
        />
        <Skeleton
          width={400}
          animation="wave"
          style={{ margin: `10px 24px 10px 0px`, padding: `6px 8px` }}
        />
      </Box>

      <Paper variant="outlined">
        {skeletonItems.map(() => {
          return (
            <Box px={1} key={cuid()}>
              <Box display="flex" mx={1}>
                <Skeleton
                  width={300}
                  animation="wave"
                  style={{ margin: `2px 24px 2px 0px`, padding: `6px 8px` }}
                />
                <Skeleton
                  width={400}
                  animation="wave"
                  style={{ margin: `2px 24px 2px 0px`, padding: `6px 8px` }}
                />
              </Box>
            </Box>
          );
        })}
      </Paper>
    </Box>
  );
};

export default AgentLookupResultsSkeleton;