import { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import cuid from 'cuid';

import { API_BASE_URL, GET_CONFIGURATION_OKTA } from '../../constants/api';

export function makeAuthInterceptor() {
  return (config) => {
    const apiBaseUrl = `${API_BASE_URL}`;
    if (
      !_.isNil(config.url) &&
      config.url.indexOf(apiBaseUrl) >= 0 &&
      config.url.indexOf(`${GET_CONFIGURATION_OKTA}`) < 0
    ) {
      const okta_token = localStorage.getItem('okta-token-storage');
      let agentsOnlySessionUUID = localStorage.getItem('afe-session-id');
      if (agentsOnlySessionUUID === undefined || agentsOnlySessionUUID === null) {
        const sessionCUID = cuid();
        localStorage.setItem('afe-session-id', sessionCUID);
        agentsOnlySessionUUID = localStorage.getItem('afe-session-id');
      }
      if (okta_token !== undefined && okta_token !== null) {
        const access_token = JSON.parse(okta_token).accessToken.accessToken;
        config.headers.access_token = 'Bearer ' + access_token;
      }
      config.headers['afe-session-id'] = agentsOnlySessionUUID;
    }
    config.headers['afe-trace-id'] = cuid();
    return config;
  };
}

/* Interceptor Component for axios to add auth token and sessionUUID in header */
function AppAxiosInterceptor() {
  const [errorInterceptor, setErrorInterceptor] = useState(undefined);
  const [authInterceptor, setAuthInterceptor] = useState(undefined);
  const addAuthInterceptor = () => {
    const authInterceptor = axios.interceptors.request.use(makeAuthInterceptor(), (error) => {
      return Promise.reject(error);
    });
    setAuthInterceptor(authInterceptor);
  };

  const removeAuthInterceptor = () => {
    axios.interceptors.request.eject(authInterceptor);
    setAuthInterceptor(undefined);
  };

  const addErrorInterceptor = () => {
    const errorInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    setErrorInterceptor(errorInterceptor);
  };

  const removeErrorInterceptor = () => {
    axios.interceptors.response.eject(errorInterceptor);
    setErrorInterceptor(undefined);
  };

  useEffect(() => {
    addAuthInterceptor();
    addErrorInterceptor();
    return () => {
      removeAuthInterceptor();
      removeErrorInterceptor();
    };
  }, []);

  return null;
}

export default AppAxiosInterceptor;
