import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Col,
  DataGrid,
  DatePicker,
  Paper,
  Row,
  Select,
  TextField,
  Typography,
  ViewContainer,
} from 'components/AORedesign';
import cuid from 'cuid';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';
import useFetchSelectMenuItems from 'hooks/useFetchSelectMenuItems';

import {
  GET_BILL_PAYMENT_REPORT_AMOUNT_SELECTIONS,
  GET_BILL_PAYMENT_REPORT_DATE_SELECTIONS,
  GET_BILL_PAYMENT_REPORT_EFT,
  GET_BILL_PAYMENT_REPORT_TRANSACTION_TYPES,
} from 'constants/api';

import {
  fetchAgentCodeSuggestions,
  fetchEnteredBySuggestions,
  fetchReportContent,
  handleAOAutocompleteOnBlur,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleChangeFormData,
  handleClickChangeReport,
  handleDateOnChange,
  handleTextFieldOnChange,
  reportContentColumns,
} from './PaymentsMade.api';

const PaymentsMade = () => {
  const [paymentsDateRangeOptions, setPaymentsDateRangeOptions] = useState([]);
  const [amountTypeOptions, setAmountTypeOptions] = useState([]);
  const [transactionTypeOptions, setTransactionTypeOptions] = useState([]);
  const [eftTypeOptions, setEftTypeOptions] = useState([]);
  const [agentCodeOptions, setAgentCodeOptions] = useState([]);
  const [enteredByOptions, setEnteredByOptions] = useState([]);
  const [errorDownloadingPdf, setErrorDownloadingPdf] = useState(false);
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    agentCode: { value: '', invalidText: '' },
    enteredBy: { value: '', invalidText: '' },
  });
  const [formData, setFormData] = useState({
    agentCode: '',
    amountFrom: '',
    amountTo: '',
    amountType: '0',
    eftType: '0',
    enteredBy: 'All',
    fromDate: null,
    paymentsDateRange: 'Today',
    toDate: null,
    transactionType: '0',
  });
  const [reportContentData, setReportContentData] = useState({
    isVisible: true,
    rows: null,
    columns: reportContentColumns(setErrorDownloadingPdf),
    loading: false,
  });
  const [alertData, setAlertData] = useState({
    message: 'No Payments were made in the specified date range.',
    severity: 'info',
    isVisible: false,
  });

  const handleOnChangeEftType = (e) => handleChangeFormData(e, 'eftType', formData, setFormData);
  const handleOnChangePaymentsDateRange = (e) => handleChangeFormData(e, 'paymentsDateRange', formData, setFormData);
  const handleOnChangeAmountType = (e) => handleChangeFormData(e, 'amountType', formData, setFormData);
  const handleOnChangeTransactionType = (e) => handleChangeFormData(e, 'transactionType', formData, setFormData);
  const handleOnChangeAgentCode = (e, value) => handleAOAutocompleteOnChange('agentCode', e, value, formData, setFormData);
  const handleOnChangeEnteredBy = (e, value) => handleAOAutocompleteOnChange('enteredBy', e, value, formData, setFormData);
  const handleOnInputChangeAgentCode = (e, value) =>
    handleAOAutocompleteOnInputChange('agentCode', e, value, autocompleteInputValues, setAutocompleteInputValues);
  const handleOnInputChangeEnteredBy = (e, value) =>
    handleAOAutocompleteOnInputChange('enteredBy', e, value, autocompleteInputValues, setAutocompleteInputValues);
  const handleOnBlurAgentCode = () => handleAOAutocompleteOnBlur('agentCode', formData, setFormData, autocompleteInputValues);
  const handleOnBlurEnteredBy = () => handleAOAutocompleteOnBlur('enteredBy', formData, setFormData, autocompleteInputValues);
  const handleOnChangeFromDate = (date) => handleDateOnChange(date, 'fromDate', formData, setFormData);
  const handleOnChangeToDate = (date) => handleDateOnChange(date, 'toDate', formData, setFormData);
  const handleOnChangeAmountFrom = (e) => handleTextFieldOnChange('amountFrom', e, setFormData);
  const handleOnChangeAmountTo = (e) => handleTextFieldOnChange('amountTo', e, setFormData);
  const handleClickChangePMReport = () => handleClickChangeReport(formData, setReportContentData, setAlertData);

  // Custom range constants
  const SHOW_CUSTOM_DATE_RANGE = formData.paymentsDateRange === 'Custom Range';
  const DATE_RANGE_LIMIT = 30;
  const DATE_UNIT = 'days';

  // Date/moment objects to handle date restrictions such as range
  const fromDateObj = moment(formData.fromDate);
  const toDateObj = moment(formData.toDate);
  const minDateObj = moment(formData.toDate).subtract(DATE_RANGE_LIMIT, DATE_UNIT);
  const dateDiff = toDateObj.diff(fromDateObj, DATE_UNIT);

  // Conditional flags for date range error cases
  const INVALID_DATE_RANGE = dateDiff > DATE_RANGE_LIMIT || dateDiff < 0;
  const INVALID_FROM_DATE = !moment(formData.fromDate).isValid();
  const INVALID_TO_DATE = !moment(formData.toDate).isValid();
  const DISABLED_BUTTON = SHOW_CUSTOM_DATE_RANGE && (INVALID_DATE_RANGE || INVALID_FROM_DATE || INVALID_TO_DATE);

  // fetch options for date range dropdown
  useFetchSelectMenuItems(GET_BILL_PAYMENT_REPORT_DATE_SELECTIONS, (response) => {
    setPaymentsDateRangeOptions(
      response.data.map((option) => ({
        id: cuid(),
        value: option,
        label: option,
      })),
    );
  });

  // fetch options for amount type dropdown
  useFetchSelectMenuItems(GET_BILL_PAYMENT_REPORT_AMOUNT_SELECTIONS, (response) => {
    setAmountTypeOptions(
      response.data.map(({ name, value }) => ({
        id: cuid(),
        value,
        label: name,
      })),
    );
  });

  // fetch options for transaction types dropdown
  useFetchSelectMenuItems(GET_BILL_PAYMENT_REPORT_TRANSACTION_TYPES, (response) => {
    setTransactionTypeOptions(
      response.data.map(({ name, value }) => ({
        id: cuid(),
        value,
        label: name,
      })),
    );
  });

  // fetch options for agent codes autocomplete
  useEffect(async () => {
    const suggestions = await fetchAgentCodeSuggestions().catch((err) => {
      console.error(err);
    });
    setAgentCodeOptions(_.isNil(suggestions) ? [] : suggestions);
  }, []);

  // fetch options for entered by autocomplete
  useEffect(async () => {
    const suggestions = await fetchEnteredBySuggestions().catch((err) => {
      console.error(err);
    });
    setEnteredByOptions(_.isNil(suggestions) ? [] : suggestions);
  }, []);

  // fetch options for EFT dropdown
  useFetchSelectMenuItems(GET_BILL_PAYMENT_REPORT_EFT, (response) => {
    setEftTypeOptions(
      response.data.map(({ name, value }) => ({
        id: cuid(),
        value,
        label: name,
      })),
    );
  });

  // fetch report content
  useEffect(async () => {
    const data = await fetchReportContent(null, true).catch((err) => {
      console.error(err);
    });
    const dataGridRows = _.isNil(data) ? [] : data;
    if (!_.isNil(dataGridRows) && dataGridRows.length < 1) {
      setAlertData((prevVal) => ({ ...prevVal, isVisible: true }));
      setReportContentData((prevVal) => ({ ...prevVal, rows: null, isVisible: false }));
    } else {
      setReportContentData((prevVal) => ({ ...prevVal, isVisible: true, rows: dataGridRows }));
    }
  }, []);

  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
      <Card id="BillingServicesReportsPaymentsMade">
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Payments Made
          </Typography>
          <Paper>
            <Box mb={2}>
              <Typography variant="subtitle1">Billing Payment Service Report</Typography>
            </Box>

            <Row spacing={2}>
              <Col>
                <Box mb={2}>
                  <Select
                    onChange={handleOnChangePaymentsDateRange}
                    value={formData.paymentsDateRange}
                    menuItems={paymentsDateRangeOptions}
                    id="date-range"
                    labelId="date-range-label-id"
                    label="Date Range"
                  />
                </Box>

                <Box mb={2}>
                  {SHOW_CUSTOM_DATE_RANGE && (
                    <DatePicker
                      onChange={handleOnChangeFromDate}
                      value={formData.fromDate}
                      label="Date From"
                      minDate={minDateObj.isValid() ? minDateObj : undefined}
                      maxDate={formData.toDate}
                      disableFuture
                      name="fromDate"
                    />
                  )}
                </Box>

                <Box>
                  {SHOW_CUSTOM_DATE_RANGE && <DatePicker onChange={handleOnChangeToDate} value={formData.toDate} disableFuture label="Date To" name="toDate" />}
                </Box>
              </Col>

              <Col xs>
                <Box mb={2} display="flex">
                  <Select
                    onChange={handleOnChangeAmountType}
                    menuItems={amountTypeOptions}
                    value={formData.amountType}
                    id="amount-type"
                    labelId="amount-type-label-id"
                    label="Amount Type"
                  />
                  <Box mr={2} />
                  <Select
                    onChange={handleOnChangeTransactionType}
                    value={formData.transactionType}
                    menuItems={transactionTypeOptions}
                    id="transaction-type"
                    labelId="transaction-type-label-id"
                    label="Transaction Type"
                  />
                  <Box mr={2} />
                  <Autocomplete
                    id="payment-management-agent-code-select"
                    value={formData.agentCode}
                    inputValue={formData.agentCodeInputValue}
                    options={agentCodeOptions}
                    disableClearable
                    onChange={handleOnChangeAgentCode}
                    onInputChange={handleOnInputChangeAgentCode}
                    invalidSearch={formData.agentCodeInvalidText}
                    renderInput={(params) => (
                      <TextField {...params} label="Agent Code" placeholder="Select or start typing" variant="outlined" onBlur={handleOnBlurAgentCode} />
                    )}
                  />
                </Box>

                <Box mb={2} display="flex">
                  <TextField style={{ minWidth: `280px` }} onChange={handleOnChangeAmountFrom} value={formData.amountFrom} label="Amount From" />
                  <Box mr={2} />
                  <Select
                    onChange={handleOnChangeEftType}
                    menuItems={eftTypeOptions}
                    value={formData.eftType}
                    id="eft-type"
                    labelId="eft-type-label-id"
                    label="EFT Type"
                    displayLabel="Select EFT Type"
                  />
                  <Box mr={2} />
                  <Autocomplete
                    id="payment-management-entered-by-select"
                    value={formData.enteredBy}
                    inputValue={autocompleteInputValues.enteredBy.value}
                    options={enteredByOptions}
                    disableClearable
                    onChange={handleOnChangeEnteredBy}
                    onInputChange={handleOnInputChangeEnteredBy}
                    invalidSearch={autocompleteInputValues.enteredBy.invalidText}
                    renderInput={(params) => (
                      <TextField {...params} label="Entered By" placeholder="Select or start typing" variant="outlined" onBlur={handleOnBlurEnteredBy} />
                    )}
                  />
                </Box>

                <Box mb={2}>
                  <TextField style={{ minWidth: `280px` }} onChange={handleOnChangeAmountTo} value={formData.amountTo} label="Amount To" />
                </Box>
              </Col>
            </Row>
            <Box mt={2}>
              <Row>
                <Button onClick={handleClickChangePMReport} variant="contained" color="primary" disabled={DISABLED_BUTTON}>
                  Change Report
                </Button>
              </Row>
            </Box>
            <Box mt={2}>
              {alertData.isVisible && <Alert severity={alertData.severity} alertTitleMessage={alertData.message} />}
              {errorDownloadingPdf && <Alert severity="error" alertTitleMessage="Error Downloading PDF. Please try again." />}
              {reportContentData.isVisible && (
                <DataGrid rows={reportContentData.rows} columns={reportContentData.columns} exportToCSV={true} loading={reportContentData.loading} />
              )}
            </Box>
          </Paper>
        </CardContent>
      </Card>
    </ViewContainer>
  );
};

export default PaymentsMade;
