import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const AOTextField = ({ id, label, currency, numbersOnly, invalidSearch, ...props }) => {
  return (
    <TextField
      key={id}
      id={`${id}`}
      label={label}
      variant="outlined"
      error={invalidSearch}
      helperText={invalidSearch}
      InputProps={{
        inputComponent: currency || numbersOnly ? NumberFormatCustom : undefined,
        endAdornment: currency ? <InputAdornment position="start">USD</InputAdornment> : undefined,
      }}
      {...props}
    />
  );
};

AOTextField.propTypes = {
  id: PropTypes.string,
  key: PropTypes.string,
  label: PropTypes.string,
  invalidSearch: PropTypes.string,
  currency: PropTypes.bool,
  numbersOnly: PropTypes.bool,
};

export default AOTextField;
