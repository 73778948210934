import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Typography } from '@material-ui/core';
import { Col, Row } from 'components/AORedesign';

import axios from 'axios';
import cuid from 'cuid';

import RowCol from '../../ScreenSmith/RowCol';
import { AOButton } from '../../AOButton';
import { AOTextField } from '../../AOTextField';
import { handleOnChange } from '../../CommonUtilityServices/EventHandlerService';
import CollapsibleTable from '../CollapsibleTable';
import { USER_ADMIN_SEARCH } from '../../../constants/api';

const defaultFormData = { agencyName: '', lastName: '', userName: '' };

const searchResultColumns = [
  {
    field: 'userName',
    headerName: 'User Name',
  },
  {
    field: 'firstName',
    headerName: 'First Name',
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
  },
];

const SearchUser = ({ handleSetUsername }) => {
  const [formData, setFormData] = useState(defaultFormData);
  const [searchResults, setSearchResults] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);

  const handleSearchOnClick = async () => {
    setSearchLoading(true);
    setSearchResults(null);
    try {
      const response = await axios.get(USER_ADMIN_SEARCH, {
        params: formData,
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error(error);
    }
    setSearchLoading(false);
  };

  const handleClearOnClick = () => {
    setSearchResults(null);
    setFormData(defaultFormData);
  };

  return (
    <>
      <Box mb={4}>
        <Typography variant="h5">{'Search for User'}</Typography>
      </Box>
      <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
        <Box ml={2} mt={2}>
          <RowCol mb={4}>
            <Typography>This form allows you to search for a system user.</Typography>
          </RowCol>
          <Row>
            <Col xs={4}>
              <Box mb={2}>
                <AOTextField
                  id={'search-user-user-name'}
                  name={'userName'}
                  value={formData.userName}
                  onChange={(e) => handleOnChange(e, formData, setFormData)}
                  label={'User Name'}
                  placeholder={'Enter User Name'}
                  style={{ width: 350 }}
                />
              </Box>
              <Box mb={2}>
                <AOTextField
                  id={'search-user-last-name'}
                  name={'lastName'}
                  value={formData.lastName}
                  onChange={(e) => handleOnChange(e, formData, setFormData)}
                  label={'Last Name'}
                  placeholder={'Enter Last Name'}
                  style={{ width: 350 }}
                />
              </Box>
              <Box mb={2}>
                <AOTextField
                  id={'search-user-agency-name'}
                  name={'agencyName'}
                  value={formData.agencyName}
                  onChange={(e) => handleOnChange(e, formData, setFormData)}
                  label={'Agency Name'}
                  placeholder={'Enter Agency Name'}
                  style={{ width: 350 }}
                />
              </Box>
            </Col>
            <Col xs={4}>
              <Typography color={'textSecondary'}>
                The search function has a wildcard at the end of the term submitted. To find{' '}
                <Box display={'inline'} fontWeight={'bold'}>
                  John Doe
                </Box>{' '}
                you could either.
              </Typography>
              <Box mb={2} />
              <Typography>
                Enter{' '}
                <Box display={'inline'} fontWeight={'bold'}>
                  {`"JDo"`}
                </Box>{' '}
                in the User Name field.
              </Typography>
              <Typography>
                Enter{' '}
                <Box display={'inline'} fontWeight={'bold'}>
                  {`"Do"`}
                </Box>{' '}
                in the Last Name field.
              </Typography>
              <Typography>
                Enter the{' '}
                <Box display={'inline'} fontWeight={'bold'}>
                  {`Agency Name`}
                </Box>
                , if known.
              </Typography>
            </Col>
            <Col xs={4} ml={4}>
              <Box ml={10}>
                <AOButton
                  variant={'contained'}
                  color={'primary'}
                  id={'search-user-search-button'}
                  onClick={handleSearchOnClick}
                  loading={searchLoading}
                  disabled={searchLoading || (formData.agencyName.length < 2 && formData.lastName.length < 2 && formData.userName.length < 2)}>
                  Search
                </AOButton>
                <Box display={'inline'} mr={2} />
                <AOButton variant={'outlined'} color={'primary'} onClick={handleClearOnClick} id={'search-user-clear-button'}>
                  Clear
                </AOButton>
              </Box>
            </Col>
          </Row>
        </Box>
      </Paper>

      {searchResults && (
        <Box mt={6}>
          <CollapsibleTable rows={searchResults.map((row) => ({ ...row, id: cuid() }))} columns={searchResultColumns} handleSetUsername={handleSetUsername} />
        </Box>
      )}
    </>
  );
};

SearchUser.propTypes = {
  handleSetUsername: PropTypes.func,
};

export default SearchUser;
