import React from 'react';
import PropTypes from 'prop-types';

import { Box, Select } from '../../../AORedesign';
import useFetchSelectMenuItems from '../../../../hooks/useFetchSelectMenuItems';
import { GET_PERM_AGENCIES } from 'constants/api';

const BMReportExternal = ({ menuItems, onChange, setAgencyCodeOptions, value }) => {
  useFetchSelectMenuItems(GET_PERM_AGENCIES, (response) => {
    setAgencyCodeOptions(
      response.data.map((location) => ({
        label: `${location.agencyCode} - ${location.agencyCity}, ${location.agencyState}`,
        value: location.agencyCode,
      })),
    );
  });

  return (
    <>
      <Box mb={2}>
        <Select
          id="bmreport-agency-codes"
          labelId="bmreport-agency-codes-label"
          label="Select Agency Codes"
          name="agencyCodes"
          menuItems={menuItems}
          onChange={onChange}
          value={value}
        />
      </Box>
    </>
  );
};

BMReportExternal.propTypes = {
  menuItems: PropTypes.array,
  onChange: PropTypes.func,
  setAgencyCodeOptions: PropTypes.func,
  value: PropTypes.string,
};

export default BMReportExternal;
