import { useEffect } from 'react';
import { initialWipRequest, initialPlEntryStartDate, initialPlWipEntryEndDate } from './PLSWip';

const PLSWipExternal = () => {
  useEffect(() => {
    initialWipRequest(initialPlEntryStartDate, initialPlWipEntryEndDate);
  }, []);
  return null;
};

export default PLSWipExternal;
