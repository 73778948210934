import { makeStyles } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Col, DatePicker, Link, Row, Typography } from 'components/AORedesign';
import { getMSSRedirectRequestBody, sendGetPolicyInquiry } from 'components/CommercialLinesServices/CLSPolicyInquiry';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import { redirectUrlFunction } from 'components/CommonUtilityServices/RedirectService';
import { API_BASE_URL } from 'constants/api';
import { selectCachedPolicy, setAppSnackbarProps } from 'redux/app-store';
import { handleRedirectFromEnterprisePolicy } from '../Home/PolicySearch';
import PolicyDetailsCardContainer from './PolicyDetailsCardContainer';
import PolicyDetailsCardSkeleton from './PolicyDetailsCardSkeleton';
import ShowMoreOrLessButton from './ShowMoreOrLessButton';

const SOURCE_SYSTEM_PLMSS_MSP_PLUS = ['msa_oneshield_pl', 'e1p_policy_center'];
const SOURCE_SYSTEM_PLV4 = ['msa_legacy_pl_property', 'msa_legacy_pl_auto', 'msa_legacy_pl_umbrella'];
const SOURCE_SYSTEM_CLMSS = ['msa_insurity_cl_auto', 'MSA_WORKERS_COMP', 'msa_bop'];
const SOURCE_SYSTEM_CLMSE_MSC_PLUS = ['homesite_majesco_v4', 'mic'];

const useStyles = makeStyles(() => ({
  linkText: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
  paddingGridItem: {
    paddingLeft: '24px',
  },
  textPadding: {
    paddingLeft: '0px',
  },
  gridLables: {
    fontSize: '0.75rem',
    fontStyle: 'regular',
    textTransform: 'uppercase',
    opacity: 0.87,
  },
}));

const DocumentsCard = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const cachedPolicy = useSelector(selectCachedPolicy);
  // pointer to policy
  const policy = cachedPolicy.policy;
  const [amendmentDate, setAmendmentDate] = useState(null);
  // get policy source system to derive policy type (PL policy, CLMSS, MSE/MSC+)
  const sourceSystemName = policy?.policy?.sourceSystemName;

  const dispatch = useDispatch();

  const handleClickPdfDownload = (pdfUrl) => {
    if (pdfUrl) {
      // we are getting response url starting with '/documents', hence removing '/documents' from url
      pdfUrl = pdfUrl.replace(/\/documents/g, '');
    }
    handlePdfDownload(
      'GET',
      null,
      pdfUrl,
      () => {},
      () => {},
      () => {},
      null,
      API_BASE_URL,
    );
  };

  const handleOnClick = async () => {
    // redirect to policy source system
    const redirectData = handleRedirectFromEnterprisePolicy(policy.policy.policyNumber, sourceSystemName, (isError) => {
      // on error, show the red snackbar
      if (isError) {
        dispatch(
          setAppSnackbarProps({
            message: 'The service is currently not available. Please try again later.',
            severity: 'error',
          }),
        );
      }
    });
    await redirectData.onClick();
  };
  const handleToggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };
  const getFormattedDate = (date) => {
    return moment(date).format('MM/DD/YYYY');
  };

  const handleOnChangeAmendmentDate = (date) => {
    const formattedDate = getFormattedDate(date);
    setAmendmentDate(date ? formattedDate : null);
  };

  const handleCLMSSOnClickRedirect = () => {
    // function to handle redirect for CL MSS
    const request_body_for_policy_inquiry = {
      inquiryDate: amendmentDate,
      inquiryType: 'View Policy',
      policyNumber: policy.policy.policyNumber,
    };
    sendGetPolicyInquiry(
      request_body_for_policy_inquiry,
      () => {},
      () => {},
      () => {},
      () => {},
    )
      .then((policyInquiryPayload) => {
        const redirect_request_body = getMSSRedirectRequestBody(policyInquiryPayload, amendmentDate);
        redirectUrlFunction(redirect_request_body, 'POST', null, () => {});
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const viewAdditionalDocumentsBtn = () => {
    return (
      <Button className={classes.textPadding} variant="text" color="secondary" onClick={handleOnClick} endIcon={<OpenInNewIcon />}>
        View Additional Documents
      </Button>
    );
  };
  const viewAdditionalDocumentsPlmssOrMspPlus = (sourceSystemName) => {
    console.log('source system: ', sourceSystemName);
    {
      /*Show the view additional documents button on the documents card when the policy source system is not plv4*/
    }
    return SOURCE_SYSTEM_PLMSS_MSP_PLUS.includes(sourceSystemName) ? 'View Additional Documents' : null;
  };
  const renderDocuments = (document) => {
    return (
      <>
        <Row style={{ marginBottom: '16px' }}>
          <Col xs={9}>
            <Link onClick={() => handleClickPdfDownload(document.url)} endIcon="pdf">
              <Typography className={classes.linkText}>{document.documentName}</Typography>
            </Link>
          </Col>
          <Col xs={3} className={classes.paddingGridItem}>
            <Typography color="textSecondary" style={{ fontSize: '1rem', textTransform: 'none' }} variant="body1">
              {`${getFormattedDate(document?.documentDate)}`}
            </Typography>
          </Col>
        </Row>
      </>
    );
  };
  const renderPLSection = (plv4) => {
    const END_DATE = getFormattedDate(policy?.policy?.periodEndDate);

    const MOMENT_START_DATE = moment(END_DATE).subtract(2, 'years');
    const MOMENT_END_DATE = moment(END_DATE);

    const policyDocuments = policy?.documents.filter((document) => {
      const MOMENT_DOC_DATE = moment(document.documentDate);
      // moment isBetween start date, end date, granularity, inclusivity
      return moment(MOMENT_DOC_DATE).isBetween(MOMENT_START_DATE, MOMENT_END_DATE, undefined, []);
    });

    return (
      <Box>
        <Typography color="textSecondary" style={{ fontSize: '0.875rem', fontStyle: 'regular', textTransform: 'none' }} variant="body2">
          Policy documents from the past two years
        </Typography>
        <Box p={0.5} />
        <Row columnSpacing={2}>
          <Row style={{ marginBottom: '16px' }}>
            <Col xs={9}>
              <Typography color="textPrimary" className={classes.gridLables}>
                document name
              </Typography>
            </Col>
            <Col xs={3} className={classes.paddingGridItem}>
              <Typography color="textPrimary" className={classes.gridLables}>
                upload date
              </Typography>
            </Col>
          </Row>
          {policyDocuments?.length > 0 ? (
            <>
              {/* filter and render first 10 from documents list */}
              {policyDocuments
                .filter((el, i) => i < 10)
                .map((document, index) => {
                  return renderDocuments(document, index);
                })}
              {/* after clicking  `show more` button, filter and render documents list if documents list contains more than 10  */}
              {expanded &&
                policyDocuments
                  ?.filter((el, i) => i >= 10)
                  .map((document, index) => {
                    return renderDocuments(document, index + 10);
                  })}
            </>
          ) : (
            <>
              {/*Show this text when the policy source system is plv4*/}
              {plv4 && <Typography color="textSecondary">No information found. Contact Customer Care at 877.927.5672</Typography>}
              {!plv4 && <Typography color="textSecondary">No information found.</Typography>}
            </>
          )}
        </Row>
        {/* show button when policy contains more than 10 documents */}
        {policyDocuments.length > 10 && <ShowMoreOrLessButton expanded={expanded} handleOnClick={handleToggleExpand} />}
      </Box>
    );
  };

  const renderCLMSSSection = () => {
    return (
      <Box>
        <Box mb={2}>
          <Typography variant="body">View policy documents via Policy Inquiry</Typography>
        </Box>
        <Box mb={2}>
          <DatePicker onChange={handleOnChangeAmendmentDate} value={amendmentDate} label="Amendment Date" name="amendmentDate" />
        </Box>
        <Button color="primary" variant="contained" onClick={handleCLMSSOnClickRedirect}>
          View Documents
        </Button>
      </Box>
    );
  };

  const renderContent = () => {
    if (cachedPolicy.retrieveStatus === 'RETRIEVING') {
      return <PolicyDetailsCardSkeleton />;
    } else if (cachedPolicy.retrieveStatus === 'RETRIEVED') {
      return (
        <PolicyDetailsCardContainer
          title="Documents"
          redirectButtonText={viewAdditionalDocumentsPlmssOrMspPlus(sourceSystemName)}
          handleOnClick={handleOnClick}>
          {sourceSystemName ? (
            <>
              {/* render document links when sourceSystem is PL policies */}
              {SOURCE_SYSTEM_PLMSS_MSP_PLUS.includes(sourceSystemName) && renderPLSection(false)}
              {SOURCE_SYSTEM_PLV4.includes(sourceSystemName) && renderPLSection(true)}
              {/* show policy system redirect button to view  documents when sourceSystem is for CLMSS policies*/}
              {SOURCE_SYSTEM_CLMSS.includes(sourceSystemName) && renderCLMSSSection()}
              {/* show policy system redirect button when sourceSystem is for MSE/MSCP */}
              {SOURCE_SYSTEM_CLMSE_MSC_PLUS.includes(sourceSystemName) && viewAdditionalDocumentsBtn()}
              {/* show policy system redirect button when sourceSystem is other than  personal lines, CLMSS and MSE/MSC+ policies*/}
              {![...SOURCE_SYSTEM_PLMSS_MSP_PLUS, ...SOURCE_SYSTEM_PLV4, ...SOURCE_SYSTEM_CLMSS, ...SOURCE_SYSTEM_CLMSE_MSC_PLUS].includes(
                sourceSystemName,
              ) && (
                <Box>
                  <Typography color="textSecondary" className={classes.textPadding}>
                    No information found.
                  </Typography>
                  {viewAdditionalDocumentsBtn()}
                </Box>
              )}
            </>
          ) : (
            <Box>
              <Box>
                <Typography color="textSecondary">No information found.</Typography>
              </Box>
            </Box>
          )}
        </PolicyDetailsCardContainer>
      );
    } else {
      return null;
    }
  };

  return renderContent();
};

export default DocumentsCard;
