import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import NewsArticlesSkeleton from './NewsArticlesSkeleton';

const NewsArticleModal = ({ open, handleClose, loading, error, data }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-label="news-review-modal"
      aria-labelledby="news-review-modal-title"
      aria-describedby="news-review-modal-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper style={{ padding: `1rem` }}>
        {loading ? (
          <NewsArticlesSkeleton />
        ) : error ? (
          <Alert severity="error">{error.message}</Alert>
        ) : (
          <Box>
            <Box mb={2}>
              <Typography variant="h5" dangerouslySetInnerHTML={{ __html: data.title }} />
            </Box>
            <Box>
              <Typography variant="p" dangerouslySetInnerHTML={{ __html: data.txt }} />
            </Box>
          </Box>
        )}
      </Paper>
    </Modal>
  );
};

NewsArticleModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
  data: PropTypes.array,
};

export default NewsArticleModal;
