import React from 'react';
import PropTypes from 'prop-types';

import { Box, Select } from 'components/AORedesign';
import { GET_APNE_URL } from 'constants/api';
import useFetchSelectMenuItems from 'hooks/useFetchSelectMenuItems';

const APnEReportAgentView = ({ menuItems, onChange, setPermAgenciesOptions, value }) => {
  useFetchSelectMenuItems(`${GET_APNE_URL}/permAgencies`, (response) => {
    setPermAgenciesOptions(
      response.data.map((location) => {
        let dropdownLabel;
        let dropdownValue = `${location.type}${location.custOrLoc}`;

        if (!location.city?.trim() && !location.state?.trim()) {
          dropdownLabel = `Customer Report - ${location.custOrLoc?.trim()}`;
        } else {
          dropdownLabel = `${location.custOrLoc?.trim()} ${location.city?.trim()}, ${location.state?.trim()}`;
        }

        return {
          label: dropdownLabel,
          value: dropdownValue,
        };
      }),
    );
  });

  return (
    <Box mb={2}>
      <Select
        id="apne-report-id"
        labelId="apne-report-id-label"
        label="Report Id"
        name="locationNumber"
        menuItems={menuItems || []}
        onChange={onChange}
        value={value}
      />
    </Box>
  );
};

APnEReportAgentView.propTypes = {
  menuItems: PropTypes.array,
  onChange: PropTypes.func,
  setPermAgenciesOptions: PropTypes.func,
  value: PropTypes.string,
};

export default APnEReportAgentView;
