import React from 'react';
import cuid from 'cuid';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';

import TableRow from '@material-ui/core/TableRow';
const DetailsDataTableExpandableRow = ({ rowData }) => {
  return (
    <TableRow>
      <TableCell />
      {rowData.map((el) => (
        <TableCell key={cuid()}>{el}</TableCell>
      ))}
    </TableRow>
  );
};
DetailsDataTableExpandableRow.propTypes = {
  rowData: PropTypes.object,
};

export default DetailsDataTableExpandableRow;
