import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import _ from 'lodash';
import cuid from 'cuid';
import { useParams, useHistory } from 'react-router-dom';

import { GET_BILLING_DETAILS, GET_REPORTS_URL } from 'constants/api';
import {
  BillingInquiryDetailsPDFButton,
  BillingInquiryDetailsPaymentModal,
  BillingInquiryEftIndicatorModal,
} from 'components/BillingInquiryDetails/BillingInquiryDetails.components';
import { redirectUrlFunction } from 'components/CommonUtilityServices/RedirectService';
import AppDataContext from 'contexts/appData.context';
import { dataGridDateFormatter, dataGridCurrencyFormatter, currencyStringFormatter } from 'helpers';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Col,
  DataGrid,
  FormControl,
  Link,
  NumberFormatCustom,
  Paper,
  Radio,
  RadioGroup,
  Row,
  TextField,
  Typography,
  ViewContainer,
} from 'components/AORedesign';
import { ROUTE_CLAIMS } from 'constants/routes';
import { Divider } from '@material-ui/core';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import { BillingDetailsSkeleton } from 'components/AOSkeleton';

const RADIO_LABEL_AGENT = 'Process Payment Using Agency EFT';
const RADIO_LABEL_INSURED_PORTAL = "Insured's Credit/Debit Card and Electronic Check Payments Portal";
const RADIO_VALUE_AGENT = 'agent';
const RADIO_VALUE_INSURED_PORTAL = 'insuredportal';

const BillingDetails = () => {
  const [loading, setLoading] = useState(true);
  const [accountDetail, setAccountDetail] = useState();
  const [, setLoadingPdf] = useState(false);
  const [, setIsDownloadingPdfErrorVisible] = useState(false);
  const [, setDownloadingPdfError] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [paymentAmountType, setPaymentAmountType] = useState('');
  const [paymentOtherAmount, setPaymentOtherAmount] = useState('');
  const [paymentNote, setPaymentNote] = useState('');
  const [paymentFeedback, setPaymentFeedback] = useState('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [, setIsMakePaymentRedirectErrorVisible] = useState(false);
  const [, setMakePaymentRedirectError] = useState(false);
  const [eftIndicatorModal, setEftIndicatorModal] = useState(false);

  const { accountNumber } = useParams();
  const { setClaimsInquiry } = useContext(AppDataContext);

  useEffect(async () => {
    try {
      const getAccountDetailUrl = `${GET_BILLING_DETAILS}/${accountNumber}`;
      const details = await axios.get(getAccountDetailUrl);
      setAccountDetail(details.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const buildRequestBodyForPaymentus = () => {
    const directBillAccountInfo = accountDetail.directBilledAccountInfo;
    const e1pPolicies = accountDetail.activitySummaryList.filter((activitySummary) => activitySummary.policySourceSystem === 'G');
    const farmRanchPolicies = accountDetail.activitySummaryList.filter((activitySummary) => activitySummary.policySourceSystem === 'F');
    const policySourceSystemSet = new Set();
    let policySourceSystem = null;
    accountDetail.activitySummaryList.forEach((item) => {
      policySourceSystemSet.add(item.policySourceSystem?.trim());
    });

    policySourceSystemSet.forEach((element) => {
      if (element === null || element === undefined || element === '' || element === 'M') {
        policySourceSystemSet.delete(element);
      }
    });

    if (policySourceSystemSet.size === 1) {
      [policySourceSystem] = [...policySourceSystemSet];
    }

    let policyNumber = directBillAccountInfo.accountNumber;
    if (accountDetail?.isGuidewirePolicy) {
      policyNumber = e1pPolicies.length > 0 ? e1pPolicies[0].policyNumber : '';
    } else if (accountDetail?.isFarmRanchPolicy) {
      policyNumber = farmRanchPolicies.length > 0 ? farmRanchPolicies[0].policyNumber : '';
    } else {
      policyNumber = directBillAccountInfo.accountNumber;
    }

    const requestBody = {
      Area: 'Billing Payment',
      TypeOfRedirect: 'Agent Payments',
      DetailedContext: [
        {
          name: 'policyNumber',
          value: policyNumber,
        },
        {
          name: 'policySourceSystem',
          value: policySourceSystem,
        },
        {
          name: 'path',
          value: 'billing',
        },
        {
          name: 'AccountDetails',
          value: {
            accountNumber: directBillAccountInfo.accountNumber.trim(),
            agencyNumber: directBillAccountInfo.agentNumber.length === 9 ? directBillAccountInfo.agentNumber : directBillAccountInfo.agentNumber + '001',
            billingZipCode: directBillAccountInfo.postalCode5.trim(),
            expectedAmount: directBillAccountInfo.minimumDue,
            producerCode: directBillAccountInfo.producerCode,
            totalPaymentAmount: directBillAccountInfo.minimumDue,
          },
        },
        {
          name: 'Payor',
          value: {
            FirstName: directBillAccountInfo.name1.trim(),
            LastName: directBillAccountInfo.lastName,
          },
        },
      ],
    };
    return requestBody;
  };

  const handleRadioButtonOnChange = (e, stateSetFunc) => {
    stateSetFunc(e.target.value);
    if (e.target.value === RADIO_VALUE_INSURED_PORTAL) {
      handleMakePayment();
    }
  };

  const handleMakePayment = () => {
    if (accountDetail.directBilledAccountInfo.eftindicator === '1') {
      setEftIndicatorModal(true);
    } else {
      paymentRedirect();
    }
  };

  const closeEftIndicatorModal = () => {
    setEftIndicatorModal(false);
  };

  const handleAOTextFieldOnChange = (e, stateSetFunc) => {
    stateSetFunc(e.target.value);
  };

  const paymentRedirect = () => {
    if (eftIndicatorModal) {
      closeEftIndicatorModal();
    }
    return redirectUrlFunction(buildRequestBodyForPaymentus(), 'POST', null, setMakePaymentRedirectError, setIsMakePaymentRedirectErrorVisible);
  };

  const handleContinueOnClick = () => {
    if (paymentAmountType === '') {
      setPaymentFeedback("Please select the 'Minimum Amount Due', 'Account Balance' or 'Other' radio button before submitting payment");
    } else if (paymentAmountType === 'other' && paymentOtherAmount === '') {
      setPaymentFeedback('Please enter Other Amount');
    } else if (paymentNote === '') {
      setPaymentFeedback('Please enter Payment Type');
    } else {
      setPaymentFeedback('');
      setShowPaymentModal(true);
    }
  };

  const getPaymentAmount = (minimumDue, balance, other, paymentAmountType) => {
    switch (paymentAmountType) {
      case 'min':
        return minimumDue;
      case 'bal':
        return balance;
      case 'other':
        return other;
    }
  };

  const handlePrintPDFOnClick = () => {
    const request_body = {
      Area: 'account-summary',
      DetailedContext: [
        {
          name: 'AccountNumber',
          value: accountDetail?.directBilledAccountInfo?.accountNumber,
        },
      ],
      TypeOfRedirect: 'Generate-PDF',
    };

    handlePdfDownload('POST', request_body, GET_REPORTS_URL, setLoadingPdf, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, true);
  };

  const handleCloseModal = () => setShowPaymentModal(false);

  const renderCell = (params, setClaimsInquiry) => {
    const history = useHistory();
    const claimsInquiryPage = () => {
      setClaimsInquiry({ policyNumber: params.value });
      history.push(ROUTE_CLAIMS);
    };
    return (
      !_.isNil(params.row) && (
        <Link onClick={claimsInquiryPage} color="primary">
          {params.value}
        </Link>
      )
    );
  };

  const getDirectBilledPoliciesColumns = (setClaimsInquiry) => {
    return [
      {
        field: 'policyNumber',
        headerName: 'Policy Number',
        width: 200,
        renderCell: (params) => renderCell(params, setClaimsInquiry),
      },
      {
        field: 'policyEffectiveDate',
        headerName: 'Effective Date',
        width: 200,
        filterable: false,
        type: 'date',
        valueFormatter: dataGridDateFormatter,
      },
      {
        field: 'policyExpirationDate',
        headerName: 'Expiration Date',
        width: 200,
        filterable: false,
        type: 'date',
        valueFormatter: dataGridDateFormatter,
      },
      {
        field: 'policyStatusDescr',
        headerName: 'Status',
        width: 200,
      },
      {
        field: 'minimumDue',
        headerName: 'Min. Amount Due',
        width: 200,
        type: 'number',
        valueFormatter: dataGridCurrencyFormatter,
      },
      {
        field: 'policyBalance',
        headerName: 'Policy Balance',
        width: 200,
        type: 'number',
        valueFormatter: dataGridCurrencyFormatter,
      },
    ];
  };

  const activitySummaryListColumns = [
    {
      field: 'processDate',
      headerName: 'Process Date',
      width: 200,
      filterable: false,
      type: 'date',
      valueFormatter: dataGridDateFormatter,
    },
    {
      field: 'activityType',
      headerName: 'Activity',
      width: 200,
    },
    {
      field: 'policyNumber',
      headerName: 'Policy Number',
      width: 200,
    },
    {
      field: 'activityEffectiveDate',
      headerName: 'Effective Date',
      width: 200,
      filterable: false,
      type: 'date',
      valueFormatter: dataGridDateFormatter,
    },
    {
      field: 'appliedAmount',
      headerName: 'Charges & Credits Applied',
      width: 200,
      type: 'number',
      valueFormatter: dataGridCurrencyFormatter,
    },
    {
      field: 'minimumPayment',
      headerName: 'Minimum Payment Due',
      width: 200,
      type: 'number',
      valueFormatter: dataGridCurrencyFormatter,
    },
    {
      field: 'accountBalance',
      headerName: 'Account Balance',
      width: 200,
      type: 'number',
      valueFormatter: dataGridCurrencyFormatter,
    },
  ];

  const futureBillingsColumns = [
    {
      field: 'billingDate',
      headerName: 'Billing Date',
      width: 200,
      filterable: false,
      type: 'date',
      valueFormatter: dataGridDateFormatter,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 200,
      filterable: false,
      type: 'date',
      valueFormatter: dataGridDateFormatter,
    },
    {
      field: 'billAmount',
      headerName: 'Min. Amount Due',
      width: 200,
      type: 'number',
      valueFormatter: dataGridCurrencyFormatter,
    },
  ];

  return loading ? (
    <BillingDetailsSkeleton items={4} />
  ) : (
    <ViewContainer returnRoute={{ text: 'Billing' }}>
      <Card>
        <CardHeader title="Billing Inquiry Details" />
        <CardContent>
          <Paper>
            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                Account Summary
              </Typography>
            </Box>
            {paymentFeedback && (
              <Box mb={2}>
                <Typography color="error">{paymentFeedback}</Typography>
              </Box>
            )}
            <Box color="text.secondary">
              <Row>
                <Col xs={6}>
                  <Row>
                    <Col xs={5}>
                      <Typography component="div">
                        <Box fontWeight="700">Account Information as of:</Box>
                      </Typography>
                    </Col>
                    <Col xs={7}>
                      <Typography component="div">
                        <Box fontWeight="700">{accountDetail?.directBilledAccountInfo?.asOfDate ?? ''}</Box>
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Box mb={3} />
              <Row>
                <Col xs={6}>
                  <Row>
                    <Col xs={5}>
                      <Typography component="div">
                        <Box fontWeight="500">Insured Name and Address:</Box>
                      </Typography>
                    </Col>
                    <Col xs={7}>
                      <Row>
                        <Row>
                          <Col xs={12}>
                            <Typography>{accountDetail?.directBilledAccountInfo?.name1 ?? ''}</Typography>
                          </Col>
                        </Row>
                      </Row>
                      <Row>
                        <Row>
                          <Col xs={12}>
                            <Typography>{accountDetail?.directBilledAccountInfo?.address1 ?? ''}</Typography>
                          </Col>
                        </Row>
                      </Row>
                      <Row>
                        <Row>
                          <Col xs={12}>
                            <Typography>{accountDetail?.directBilledAccountInfo?.address2 ?? ''}</Typography>
                          </Col>
                        </Row>
                      </Row>
                      <Row>
                        <Col>
                          <Typography>{accountDetail?.directBilledAccountInfo?.city ?? ''}</Typography>
                        </Col>
                        <Col>
                          <Box pr={0.5} />
                        </Col>
                        <Col>
                          <Typography>{accountDetail?.directBilledAccountInfo?.state ?? ''}</Typography>
                        </Col>
                        <Col>
                          <Box pr={0.5} />
                        </Col>
                        <Col>
                          <Typography>{accountDetail?.directBilledAccountInfo?.postalCode ?? ''}</Typography>
                        </Col>
                        <Col>
                          <Box pr={0.5} />
                        </Col>
                        <Col>
                          <Typography>{accountDetail?.directBilledAccountInfo?.country ?? ''}</Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Box mb={1.5} />
                  <Row>
                    <Col xs={5}>
                      <Typography component="div">
                        <Box fontWeight="500">Agent:</Box>
                      </Typography>
                    </Col>
                    <Col xs={7}>
                      <Typography component="div">
                        <Box fontWeight="500">{accountDetail?.directBilledAccountInfo?.agentNumber ?? ''}</Box>
                      </Typography>
                    </Col>
                  </Row>
                  <Box mb={1.5} />
                  <Row>
                    <Col xs={5}>
                      <Typography component="div">
                        <Box fontWeight="500">Phone Number:</Box>
                      </Typography>
                    </Col>
                    <Col xs={7}>
                      <Typography component="div">
                        <Box>{accountDetail?.directBilledAccountInfo?.phone ?? ''}</Box>
                      </Typography>
                      <Box></Box>
                    </Col>
                  </Row>
                  <Box mb={1.5} />
                  <Row>
                    <Col xs={5}>
                      <Typography component="div">
                        <Box fontWeight="500">Account Number:</Box>
                      </Typography>
                    </Col>
                    <Col xs={7}>
                      <Typography component="div">
                        <Box>{accountDetail?.directBilledAccountInfo?.accountNumber ?? ''}</Box>
                      </Typography>
                    </Col>
                  </Row>
                  <Box mb={1.5} />
                  <Col>
                    <BillingInquiryDetailsPDFButton onClick={handlePrintPDFOnClick} buttonText="Print PDF" />
                  </Col>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={5}>
                      <Typography component="div">
                        <Box fontWeight="500">Billing Status:</Box>
                      </Typography>
                    </Col>
                    <Col xs={7}>
                      <Typography component="div">
                        <Box>{accountDetail?.directBilledAccountInfo?.billingStatusDescr ?? ''}</Box>
                      </Typography>
                      {accountDetail?.directBilledAccountInfo?.flgOngoing && (
                        <Typography component="div">
                          <Box>ACCOUNT ON EFT</Box>
                        </Typography>
                      )}
                    </Col>
                  </Row>
                  <Box mb={1.5} />
                  <Row>
                    <Col xs={5}>
                      <Typography component="div">
                        <Box fontWeight="500">Current Invoice Due Date:</Box>
                      </Typography>
                    </Col>
                    <Col xs={7}>
                      <Typography component="div">
                        <Box>{accountDetail?.directBilledAccountInfo?.currentDueDate ?? ''}</Box>
                      </Typography>
                    </Col>
                  </Row>
                  <Box mb={1.5} />
                  {paymentType !== RADIO_VALUE_AGENT && (
                    <>
                      <Row>
                        <Col xs={5}>
                          <Typography component="div">
                            <Box fontWeight="500">Minimum Amount Due:</Box>
                          </Typography>
                        </Col>
                        <Col xs={7}>
                          <Typography component="div">
                            <Box>{currencyStringFormatter(accountDetail?.directBilledAccountInfo?.minimumDue) ?? ''}</Box>
                          </Typography>
                        </Col>
                      </Row>
                      <Box mb={1.5} />
                      <Row>
                        <Col xs={5}>
                          <Typography component="div">
                            <Box fontWeight="500">Account Balance:</Box>
                          </Typography>
                        </Col>
                        <Col xs={7}>
                          <Typography component="div">
                            <Box>{currencyStringFormatter(accountDetail?.directBilledAccountInfo?.accountBalance) ?? ''}</Box>
                          </Typography>
                        </Col>
                      </Row>
                      <Box mb={1.5} />
                    </>
                  )}
                </Col>
              </Row>
              <Box mb={5} />
              <Feature allowed={featureFlags.ACCESS_USER_IS_AGENT}>
                <Box mb={4}>
                  {!accountDetail?.isSbssPolicy && !accountDetail?.isGuidewirePolicy && !accountDetail?.isFarmRanchPolicy && (
                    <Row>
                      <Col xs={6}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="billing-details-payment"
                            name="billing-details-payment"
                            value={paymentType}
                            onChange={(e) => handleRadioButtonOnChange(e, setPaymentType)}>
                            {accountDetail?.strInd && (
                              <Radio key={`${RADIO_LABEL_AGENT}-${RADIO_VALUE_AGENT}`} label={RADIO_LABEL_AGENT} value={RADIO_VALUE_AGENT} />
                            )}
                            <Radio
                              key={`${RADIO_LABEL_INSURED_PORTAL}-${RADIO_VALUE_INSURED_PORTAL}`}
                              label={RADIO_LABEL_INSURED_PORTAL}
                              value={RADIO_VALUE_INSURED_PORTAL}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Col>
                      <Col xs={6}>
                        {paymentType === RADIO_VALUE_AGENT && (
                          <>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="payment-amount-options"
                                name="payment-amount-options"
                                onChange={(e) => handleRadioButtonOnChange(e, setPaymentAmountType)}
                                value={paymentAmountType}>
                                <Radio
                                  key="payment-min-radio"
                                  id="payment-min-radio"
                                  value="min"
                                  label={`Pay Minimum Amt Due: ${currencyStringFormatter(accountDetail?.directBilledAccountInfo?.minimumDue) ?? ''}`}
                                />
                                <Radio
                                  key="payment-bal-radio"
                                  id="payment-bal-radio"
                                  value="bal"
                                  label={`Pay Account Balance: ${currencyStringFormatter(accountDetail?.directBilledAccountInfo?.accountBalance) ?? ''}`}
                                />
                                <Row alignItems="center">
                                  <Col>
                                    <Radio id="payment-other-radio" value="other" label="Pay Other:" />
                                  </Col>
                                  <Col>
                                    <TextField
                                      id="billing-details-payment-other-amt"
                                      value={paymentOtherAmount}
                                      onChange={(e) => handleAOTextFieldOnChange(e, setPaymentOtherAmount)}
                                      name="other-amount"
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Box my={2}>
                                  <TextField
                                    id="billing-details-payment-type-note"
                                    label="Payment Type"
                                    placeholder="Enter Payment Type"
                                    value={paymentNote}
                                    onChange={(e) => handleAOTextFieldOnChange(e, setPaymentNote)}
                                  />
                                </Box>
                              </RadioGroup>
                            </FormControl>

                            <Box>
                              <Button variant="contained" color="primary" onClick={handleContinueOnClick}>
                                Continue
                              </Button>
                            </Box>
                          </>
                        )}
                      </Col>
                    </Row>
                  )}
                </Box>
              </Feature>

              <Feature allowed={featureFlags.ACCESS_USER_IS_AGENT}>
                <>
                  {(accountDetail?.isSbssPolicy || accountDetail?.isGuidewirePolicy || accountDetail?.isFarmRanchPolicy) && (
                    <>
                      <Row justifyContent="center">
                        <Button variant="contained" color="primary" onClick={handleMakePayment}>
                          Make Payment
                        </Button>
                      </Row>
                      <Box mb={2} />
                    </>
                  )}
                </>
              </Feature>
              <Row justifyContent="center">
                <Col>
                  <Typography>Last Payment Received</Typography>
                </Col>
                <Box pr={0.5} />
                <Box>
                  <Typography>{accountDetail?.directBilledAccountInfo?.lastpaymentDate ?? ''}</Typography>
                </Box>
                <Box pr={0.5} />
                <Col>
                  <Typography>in amount of</Typography>
                </Col>
                <Box pr={0.5} />
                <Box>
                  <Typography>{currencyStringFormatter(accountDetail?.directBilledAccountInfo?.amountReceived) ?? ''}</Typography>
                </Box>
                <Col>
                  <Typography>. Thank you.</Typography>
                </Col>
              </Row>
            </Box>
          </Paper>
          <Box mb={3} />
          <Paper>
            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                Policy List
              </Typography>
            </Box>
            <Box color="text.secondary">
              <Row>
                <Row>
                  <Col>
                    <Typography component="div">
                      <Box mr={0.5} fontWeight="500">
                        Policy Numbers within Account #:
                      </Box>
                    </Typography>
                  </Col>
                  <Col>
                    <Typography component="div">
                      <Box>{accountDetail?.directBilledAccountInfo?.accountNumber}</Box>
                    </Typography>
                  </Col>
                </Row>
              </Row>
              <Box mb={1.5} />
              <Row>
                <Row>
                  <Col>
                    <Typography component="div">
                      <Box mr={0.5} fontWeight="500">
                        Insured Name:
                      </Box>
                    </Typography>
                  </Col>
                  <Col>
                    <Typography component="div">
                      <Box>{accountDetail?.directBilledAccountInfo?.name1}</Box>
                    </Typography>
                  </Col>
                </Row>
              </Row>
              <Box mb={3} />
              <Row>
                <Col xs={12}>
                  <DataGrid
                    columns={getDirectBilledPoliciesColumns(setClaimsInquiry)}
                    rows={
                      accountDetail?.directBilledPolicies?.map((el) => ({
                        id: cuid(),
                        ...el,
                      })) ?? []
                    }
                  />
                </Col>
              </Row>
              <Box mb={5} />
              <Row>
                <Row>
                  <Col>
                    <Typography component="div">
                      <Box mr={0.5} fontWeight="500">
                        Fee Balance:
                      </Box>
                    </Typography>
                  </Col>
                  <Col>
                    <Typography component="div">
                      <Box>{currencyStringFormatter(accountDetail?.feeInfo?.feeBalance) ?? ''}</Box>
                    </Typography>
                  </Col>
                </Row>
              </Row>
              <Box mb={1.5} />
              <Row>
                <Row>
                  <Col>
                    <Typography component="div">
                      <Box mr={0.5} fontWeight="500">
                        Pended Credit(s):
                      </Box>
                    </Typography>
                  </Col>
                  <Col>
                    <Typography component="div">
                      <Box>{currencyStringFormatter(accountDetail?.feeInfo?.pendedCredit) ?? ''}</Box>
                    </Typography>
                  </Col>
                </Row>
              </Row>
              <Box mb={3} />
            </Box>
          </Paper>
          <Box mb={3} />
          <Paper>
            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                Activity Summary
              </Typography>
            </Box>
            <Box color="text.secondary">
              <Row>
                <Col xs={12}>
                  <DataGrid
                    columns={activitySummaryListColumns}
                    rows={
                      accountDetail?.activitySummaryList?.map((el) => ({
                        id: cuid(),
                        ...el,
                      })) ?? []
                    }
                    fileName={`activity-summary-${accountDetail?.directBilledAccountInfo?.accountNumber}`}
                    exportToCSV
                  />
                </Col>
              </Row>
              <Box mb={5} />
            </Box>
          </Paper>
          <Box mb={3} />
          <Paper>
            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                Future Billings
              </Typography>
            </Box>
            <Box color="text.secondary">
              <Row>
                <Col xs={12}>
                  <Typography>*Please note that minimum due does not reflect installment fees or pended credits.</Typography>
                </Col>
              </Row>
              <Box mb={3} />
              <Row>
                <Col>
                  <Typography component="div">
                    <Box mr={0.5} fontWeight="500">
                      Account #:
                    </Box>
                  </Typography>
                </Col>
                <Col>
                  <Typography component="div">
                    <Box>{accountDetail?.directBilledAccountInfo?.accountNumber}</Box>
                  </Typography>
                </Col>
              </Row>
              <Box mb={1.5} />
              <Row>
                <Row>
                  <Col>
                    <Typography component="div">
                      <Box mr={0.5} fontWeight="500">
                        Insured Name:
                      </Box>
                    </Typography>
                  </Col>
                  <Col>
                    <Typography component="div">
                      <Box>{accountDetail?.directBilledAccountInfo?.name1}</Box>
                    </Typography>
                  </Col>
                </Row>
              </Row>
              <Box mb={1.5} />
              <Row>
                <Row>
                  <Col>
                    <Typography component="div">
                      <Box mr={0.5} fontWeight="500">
                        Pended Credit(s):
                      </Box>
                    </Typography>
                  </Col>
                  <Col>
                    <Typography component="div">
                      <Box>{currencyStringFormatter(accountDetail?.feeInfo?.pendedCredit) ?? ''}</Box>
                    </Typography>
                  </Col>
                </Row>
              </Row>
              <Box mb={1.5} />
              <Row justifyContent="center">
                <Box mb={6} textAlign="center">
                  <Typography component="div">
                    <Box fontWeight="500">Future Billing Schedule</Box>
                  </Typography>
                </Box>
              </Row>
              <Box mb={3}>
                <Divider />
              </Box>
              {accountDetail?.futureBillings?.length < 1 && (
                <Row justifyContent="center">
                  <Box textAlign="center">
                    <Typography>[There are no Future Billings listed in this account.]</Typography>
                  </Box>
                </Row>
              )}
              {accountDetail?.futureBillings?.length > 0 && (
                <Row>
                  <Col xs={12}>
                    <DataGrid
                      columns={futureBillingsColumns}
                      rows={
                        accountDetail?.futureBillings?.map((el) => ({
                          id: cuid(),
                          ...el,
                        })) ?? []
                      }
                    />
                  </Col>
                </Row>
              )}
            </Box>
          </Paper>
        </CardContent>
      </Card>
      <BillingInquiryDetailsPaymentModal
        handleClose={handleCloseModal}
        open={showPaymentModal}
        accountNumber={accountDetail?.directBilledAccountInfo?.accountNumber}
        accountName={accountDetail?.directBilledAccountInfo?.name1}
        agentNumber={accountDetail?.directBilledAccountInfo?.agentNumber}
        paymentType={paymentType}
        nonPayValue={accountDetail?.directBilledAccountInfo?.nonPayValue}
        paymentNote={paymentNote}
        paymentAmount={getPaymentAmount(
          accountDetail?.directBilledAccountInfo?.minimumDue,
          accountDetail?.directBilledAccountInfo?.accountBalance,
          paymentOtherAmount,
          paymentAmountType,
        )}
      />
      <BillingInquiryEftIndicatorModal handleClose={closeEftIndicatorModal} open={eftIndicatorModal} handleYes={paymentRedirect} />
    </ViewContainer>
  );
};

export default BillingDetails;
