import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Typography, Box, Grid } from '@material-ui/core';

import { ViewContainer } from '../Layout';
import { AOCard } from '../AOCard';
import { AOSelect } from '../AOSelect';
import { handleOnChange } from '../CommonUtilityServices/EventHandlerService';
import {
  GET_ANNOUNCEMENTS_ITEM,
  GET_ANNOUNCEMENTS_ITEMS,
  GET_ANNOUNCEMENTS_MONTHS,
  GET_ANNOUNCEMENTS_PRODUCTS,
  GET_ANNOUNCEMENTS_STATES,
  GET_ANNOUNCEMENTS_YEARS,
} from '../../constants/api';
import { getMonthName } from '../../helpers';
import { AOButton } from '../AOButton';
import { AOAlert } from '../AOAlert';
import { handleFileDownload } from '../CommonUtilityServices/DownloadFileService';
import * as DOMPurify from 'dompurify';

const lobMappings = { cl: 'Commercial Lines', pl: 'Personal Lines' };

const Announcements = () => {
  const [lob, setLob] = useState(lobMappings.cl);
  const [formData, setFormData] = useState({ product: 'CL', state: '', month: '', year: '' });
  const [announcementArticles, setAnnouncementArticles] = useState(null);
  const [menuItems, setMenuItems] = useState({ products: [], states: [], months: [], years: [] });
  const [loading, setLoading] = useState({ search: true });
  const [fetchingArticle, setFetchingArticle] = useState(false);
  const [error, setError] = useState(null);

  const { lineOfBusiness } = useParams();

  useEffect(async () => {
    const lob = lineOfBusiness?.toLowerCase();

    const items = await fetchAnnouncementItems(lob === 'pl' ? 'pl' : 'cl');
    setAnnouncementArticles(items.slice(0, 3));

    try {
      const queryString = '?section=A';
      const { data: products } = await axios.get(GET_ANNOUNCEMENTS_PRODUCTS + queryString);
      const productMenuItems = products.map((product) => ({
        value: product.name,
        code: product.value,
      }));

      const { data: states } = await axios.get(GET_ANNOUNCEMENTS_STATES + queryString);
      const stateMenuItems = states.map((state) => ({
        value: state.name,
        code: state.value,
      }));

      const { data: months } = await axios.get(GET_ANNOUNCEMENTS_MONTHS + queryString);
      const monthMenuItems = months.map((month) => ({
        value: month.name,
        code: month.value,
      }));

      const { data: years } = await axios.get(GET_ANNOUNCEMENTS_YEARS + queryString);
      const yearMenuItems = years.map((year) => ({
        value: year.name,
        code: year.value,
      }));
      setMenuItems({
        products: productMenuItems,
        states: [{ value: '', code: '' }, ...stateMenuItems],
        months: [{ value: '', code: '' }, ...monthMenuItems],
        years: [{ value: '', code: '' }, ...yearMenuItems],
      });
    } catch (err) {
      console.error(err);
    }

    if (lob === 'pl') {
      setLob(lobMappings.pl);
      setFormData((prevState) => ({ ...prevState, product: 'PL' }));
    }
    setLoading((prevState) => ({ ...prevState, search: false }));
  }, []);

  const fetchAnnouncementItems = async (lob = 'cl', queryParams) => {
    let response = [];
    try {
      const { data } = await axios.get(`${GET_ANNOUNCEMENTS_ITEMS}/${lob}`, {
        params: queryParams,
      });
      response = data;
    } catch (err) {
      console.error(err);
    }
    return response;
  };

  const handleSearchOnClick = async () => {
    setLoading((prevState) => ({ ...prevState, search: true }));
    const lob = formData.product.toLowerCase();
    setLob(lobMappings[lob]);
    try {
      const queryParams = {};
      for (const key in formData) {
        if (key !== 'product' && formData[key].length > 0) {
          queryParams[key] = formData[key];
        }
      }
      const items = await fetchAnnouncementItems(lob, queryParams);
      setAnnouncementArticles(items);
    } catch (err) {
      console.error(err);
    }
    setLoading((prevState) => ({ ...prevState, search: false }));
  };

  const handleItemOnClick = async (itemId) => {
    try {
      // Even this itemId is not user input (comes from an a DB call), we are sanitizing this itemId just in case because Snyk is flagging it.
      let sanitizedItemId = DOMPurify.sanitize(itemId);

      // deepcode ignore Ssrf: False positive - https://amfament.atlassian.net/browse/SCO-1960
      handleFileDownload(
        `/${sanitizedItemId}`,
        setFetchingArticle,
        setError,
        setError,
        'newWindow',
        'GET',
        undefined,
        GET_ANNOUNCEMENTS_ITEM,
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ViewContainer title="Product Announcements">
      {error && <AOAlert severity="error">{error}</AOAlert>}
      <AOCard cardTitle={`${lob} Product Change Announcements`}>
        <Typography color="textSecondary">
          {`You can search our database of announcements issued by Main Street America regarding new
          rates, guidelines and other changes to our ${lob} products.`}
        </Typography>

        <Box mt={3} display="flex" alignItems={'center'}>
          <Box mr={1}>
            <AOSelect
              inputLabel="Product"
              id="announcements-product-select"
              menuItems={menuItems.products}
              name="product"
              value={formData.product}
              onChange={(e) => handleOnChange(e, formData, setFormData)}
              disabled={menuItems.products?.length === 0}
            />
          </Box>
          <Box mr={1}>
            <AOSelect
              inputLabel="State"
              id="announcements-state-select"
              menuItems={menuItems.states}
              name="state"
              value={formData.state}
              onChange={(e) => handleOnChange(e, formData, setFormData)}
              disabled={menuItems.states?.length === 0}
            />
          </Box>
          <Box mr={1}>
            <AOSelect
              inputLabel="Month"
              id="announcements-month-select"
              menuItems={menuItems.months}
              name="month"
              value={formData.month}
              onChange={(e) => handleOnChange(e, formData, setFormData)}
              disabled={menuItems.months?.length === 0}
            />
          </Box>
          <Box mr={1}>
            <AOSelect
              inputLabel="Year"
              id="announcements-year-select"
              menuItems={menuItems.years}
              name="year"
              value={formData.year}
              onChange={(e) => handleOnChange(e, formData, setFormData)}
              disabled={menuItems.years?.length === 0}
            />
          </Box>
          <Box>
            <AOButton
              color="primary"
              id="announcements-search-button"
              variant="contained"
              loading={loading.search}
              onClick={handleSearchOnClick}>
              Search
            </AOButton>
          </Box>
        </Box>

        <Box mt={3}>
          <Typography gutterBottom align="center" component="div">
            <Box fontWeight="fontWeightBold">Recent Announcements</Box>
          </Typography>
          <Typography gutterBottom align="center" color="textSecondary">
            Following are the most recent product change announcements:
          </Typography>

          <Box>
            {announcementArticles?.length === 0 ? (
              <Typography color="error">
                No announcements found. Please refine your search and try again.
              </Typography>
            ) : (
              announcementArticles?.map((article, index) => (
                <Box
                  p={2}
                  key={`${article?.seqId}-${article?.state}-${article?.displayMonth}-${article?.displayYear}`}
                  style={{ backgroundColor: index % 2 === 0 ? '#fafafa' : '#ffffff' }}>
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography
                        align="center"
                        component="div"
                        style={{ textDecoration: 'underline' }}>
                        <Box mb={0.5} fontWeight="fontWeightBold">{`${getMonthName(
                          article.displayMonth,
                        )} ${article.displayYear}`}</Box>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography align="center">{article?.state}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <AOButton
                        disabled={fetchingArticle}
                        color="secondary"
                        onClick={() => handleItemOnClick(article?.seqId)}
                        style={{
                          textDecoration: 'underline',
                          padding: 0,
                          textTransform: 'none',
                          fontSize: '1rem',
                          lineHeight: 'unset',
                        }}>
                        {article?.displayName}
                      </AOButton>
                    </Grid>
                  </Grid>
                </Box>
              ))
            )}
          </Box>
        </Box>
      </AOCard>
    </ViewContainer>
  );
};

export default Announcements;
