import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import { GET_REPORTS_URL } from 'constants/api';

const createDetailedContext = (formData) => {
  const detailedContext = [];
  for (const key in formData) {
    if (formData[key]) {
      const map = {};
      map.name = key;

      // Agency code only takes last 6 characters (like legacy)
      if (key === 'agencyCode') {
        map.value = formData[key].slice(-6);
      } else {
        map.value = formData[key];
      }

      detailedContext.push(map);
    }
  }
  return detailedContext;
};

const handleCreateOnClick = async (formData, setLoadingPdf, setErrorMessage) => {
  try {
    setErrorMessage('');

    let reportRequestBody = {
      Area: 'agency-bill-statement',
      DetailedContext: createDetailedContext(formData),
      TypeOfRedirect: 'Generate-Report',
    };

    handlePdfDownload(
      'POST',
      reportRequestBody,
      GET_REPORTS_URL,
      setLoadingPdf,
      setErrorMessage,
      setErrorMessage,
      'newWindow',
    );
  } catch (error) {
    console.error(error);
    setErrorMessage(error.message);
  }
};

const resetAgencyCode = (setFormData, setAutocompleteInputValues) => {
  setFormData((prevState) => ({ ...prevState, agencyCode: '' }));
  setAutocompleteInputValues((prevState) => ({ ...prevState, agencyCode: { value: '' } }));
};

export { handleCreateOnClick, resetAgencyCode };
