import React, { useEffect, useState } from 'react';
import axios from 'axios';
import cuid from 'cuid';
import { useTheme, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import { ScreenSmith } from '../ScreenSmith';
import { PLSPolicyChangeViewSkeleton } from '../AOSkeleton';
import PLSPolicyChangeViewRedirectResponse from './PLSPolicyChangeViewRedirectResponse';
import { useDebounce } from '../../hooks/useDebounce';

import {
  GET_PL_POLICIES_ON_NAME,
  GET_PL_AUTO_COMPLETION_POLICY_ONE_SHIELD,
} from '../../constants/api';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';

const dataGridResultsColumns = [
  {
    field: 'policy',
    headerName: 'Policy Number',
    sortable: true,
    width: 200,
  },
  {
    field: 'insName',
    headerName: 'Name Insured/Description',
    sortable: true,
    width: 200,
  },
  {
    field: 'address',
    headerName: 'Mailing Address',
    sortable: true,
    width: 200,
  },
  {
    field: 'line',
    headerName: 'LOB',
    sortable: true,
    width: 200,
  },
  {
    field: 'code',
    headerName: 'Agent Code',
    sortable: true,
    width: 200,
  },
  {
    field: 'platform',
    headerName: 'Platform',
    sortable: true,
    width: 200,
  },
  {
    field: 'userID',
    headerName: 'User ID',
    sortable: true,
    width: 200,
  },
];

const LocalComponents = {
  PLSPolicyChangeViewRedirectResponse,
};

const PLSPolicyChangeView = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [amendmentDate, setAmendmentDate] = useState(moment().format('MM/DD/yyyy'));
  const [plPolicyChangeViewSearchType, setPlPolicyChangeViewSearchType] = useState('');
  const [policyNumberValue, setPolicyNumberValue] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    policyNumber: '',
  });
  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);
  const [policyNumberOptions, setPolicyNumberOptions] = useState([]);
  const [plPolicyChangeViewSelectedLob, setPlPolicyChangeViewSelectedLob] = useState('ALL');
  const [redirectingToPlPolicyChangeView, setRedirectingToPlPolicyChangeView] = useState(false);
  const [errorPlPolicyChangeViewRedirect, setErrorPlPolicyChangeViewRedirect] = useState(null);
  const [redirectResponseStatusMessage, setRedirectResponseStatusMessage] = useState(null);
  const [isPolicyChangeRedirectErrorVisible, setIsPolicyChangeRedirectErrorVisible] =
    useState(false);
  const [policyChangeRedirectError, setPolicyChangeRedirectError] = useState(false);
  const [additionalSearchOptions, setAdditionalSearchOptions] = useState({
    lob: 'all',
    agency: '',
  });
  const [dataGridResults, setDataGridResults] = useState(null);

  const { UiDefinitionsData, loadingSd } = useScreenDefinition('PLSPolicyChangeViewPanel');

  //--- Event Handlers for Policy Change / Policy View ---
  const handleChangeAmendmentDate = (date) => {
    setAmendmentDate(date);
  };
  const handleChangePlPolicyChangeViewSearchType = (event) => {
    setPlPolicyChangeViewSearchType(event.target.value);
    setSearchInput('');
  };
  const handleChangeSearchInput = (event) => {
    setSearchInput(event.target.value);
  };
  const handlePolicyNumberOnChange = (event, value) => {
    setPolicyNumberValue(value);
  };
  const handleChangePolicyNumberInput = (event, inputValue) => {
    const sanitizedValue = inputValue.toUpperCase().trim();
    setAutocompleteInputValues({ policyNumber: sanitizedValue });
    setDebouncedState(autocompleteInputValues);
  };
  const handlePolicyNumberOnBlur = () => {
    if (policyNumberValue !== autocompleteInputValues.policyNumber) {
      setPolicyNumberValue(autocompleteInputValues.policyNumber);
    }
  };
  const handleChangePlChangeViewSelectedLob = (event) => {
    setPlPolicyChangeViewSelectedLob(event.target.value);
  };
  const handleAdditionalSearchOptionsOnChange = (event) => {
    const stateKey = event.target.name;
    const stateValue = event.target.value;
    setAdditionalSearchOptions({ ...additionalSearchOptions, [stateKey]: stateValue });
  };

  const getRequestBody = (policyNumber) => {
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'View / Change Policy',
      DetailedContext: [
        {
          name: 'effectiveDate',
          value: amendmentDate,
        },
        {
          name: 'policyNumber',
          value: policyNumber ?? (searchInput || policyNumberValue),
        },
        {
          name: 'vendorSystem',
          value: false,
        },
      ],
    };
    return request_body;
  };

  const handleClickPlPolicyChangeViewSubmitButton = async () => {
    setRedirectingToPlPolicyChangeView(true);
    setRedirectResponseStatusMessage(null);
    setErrorPlPolicyChangeViewRedirect(null);
    setPolicyChangeRedirectError(false);
    setIsPolicyChangeRedirectErrorVisible(false);

    const sanitizeAndGetRequestPayload = (requestBody) => {
      for (const key in requestBody) {
        if (
          requestBody[key] === null ||
          requestBody[key] === '' ||
          (key === 'lob' && requestBody[key] === 'all')
        ) {
          delete requestBody[key];
        }
      }
      const requestString = [];

      for (const key in requestBody) {
        requestString.push(`${key}=${requestBody[key]}`);
      }

      return requestString.length ? `?${requestString.join('&')}` : '';
    };

    if (plPolicyChangeViewSearchType === 'policyNumber') {
      const request_body = getRequestBody();
      return redirectUrlFunction(
        request_body,
        'POST',
        null,
        setPolicyChangeRedirectError,
        setIsPolicyChangeRedirectErrorVisible,
      );
    } else if (plPolicyChangeViewSearchType === 'holderLastName') {
      try {
        const requestBody = {
          lastName: searchInput,
          lob: additionalSearchOptions.lob,
          agency: additionalSearchOptions.agency,
        };
        const queryRequestParams = sanitizeAndGetRequestPayload(requestBody);

        const requestUrl = `${GET_PL_POLICIES_ON_NAME}${queryRequestParams}`;

        const response = await axios.get(requestUrl);
        if (response.status === 200) {
          setDataGridResults(response.data);
        }
      } catch (error) {
        setPolicyChangeRedirectError(error.message);
        setIsPolicyChangeRedirectErrorVisible(true);
      }
    }
  };

  const handleOnRowDoubleClick = (params) => {
    const request_body = getRequestBody(params.row.policy);
    redirectUrlFunction(
      request_body,
      'POST',
      null,
      setPolicyChangeRedirectError,
      setIsPolicyChangeRedirectErrorVisible,
    );
  };

  const fetchPolicyNumberSuggestions = async (policyNumberInput) => {
    const { data } = await axios.get(
      `${GET_PL_AUTO_COMPLETION_POLICY_ONE_SHIELD}${policyNumberInput}`,
    );
    return data;
  };

  useEffect(async () => {
    if (autocompleteInputValues.policyNumber.length >= 3) {
      const suggestions = await fetchPolicyNumberSuggestions(
        autocompleteInputValues.policyNumber,
      ).catch((err) => {
        console.error(err);
      });
      setPolicyNumberOptions(suggestions);
    } else {
      setPolicyNumberOptions([]);
    }
  }, [debouncedState.policyNumber]);

  const plState = {
    amendmentDate,
    plPolicyChangeViewSearchType,
    isPolicyNumberSearchType: plPolicyChangeViewSearchType === 'policyNumber',
    isDefaultSearchType: plPolicyChangeViewSearchType !== 'policyNumber',
    policyNumberOptions,
    plPolicyChangeViewSelectedLob,
    isSmall,
    errorPlPolicyChangeViewRedirect,
    redirectingToPlPolicyChangeView,
    searchInput,
    redirectResponseStatusMessage,
    isPolicyChangeRedirectErrorVisible,
    policyChangeRedirectError,
    isSubmitDisabled: plPolicyChangeViewSearchType === '',
    dataGridResultsColumns,
    dataGridResults: dataGridResults?.map((row) => ({
      id: cuid(),
      ...row,
    })),
    showGridResults: dataGridResults && dataGridResults.length > 0,
    showAdditionalSearchOptions: plPolicyChangeViewSearchType === 'holderLastName',
    additionalSearchOptionsLOB: additionalSearchOptions.lob,
    additionalSearchOptionsAgency: additionalSearchOptions.agency,
  };

  const callBackFunctions = {
    handleChangeAmendmentDate,
    handleChangePlPolicyChangeViewSearchType,
    handleChangeSearchInput,
    handlePolicyNumberOnChange,
    handleChangePolicyNumberInput,
    handlePolicyNumberOnBlur,
    handleChangePlChangeViewSelectedLob,
    handleClickPlPolicyChangeViewSubmitButton,
    handleAdditionalSearchOptionsOnChange,
    handleOnRowDoubleClick,
  };

  if (loadingSd) return <PLSPolicyChangeViewSkeleton />;

  return (
    !_.isNil(UiDefinitionsData) && (
      <ScreenSmith
        definition={UiDefinitionsData}
        componentMap={LocalComponents}
        functionMap={callBackFunctions}
        stateMap={plState}
      />
    )
  );
};

export default PLSPolicyChangeView;
