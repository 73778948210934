import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const AODatePicker = ({ label, value, ...props }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        inputVariant="outlined"
        id="date-picker-dialog"
        label={label}
        format="MM/dd/yyyy"
        value={value}
        autoOk
        okLabel="Done"
        cancelLabel={null}
        KeyboardButtonProps={{
          'aria-label': `${label}`,
        }}
        inputProps={{ 'data-testid': `datepicker-${_.kebabCase(label)}` }}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

AODatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default AODatePicker;
