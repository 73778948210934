import React from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const ContactUsSkeleton = ({ ...otherProps }) => {
  return (
    <Box {...otherProps}>
      <Box mb={3}>
        <Skeleton width={375} style={{ marginRight: '24px', padding: '6px 8px' }} animation="wave" />
      </Box>
      <Box mb={2}>
        <Box>
          <Skeleton width={280} style={{ marginRight: '24px', padding: '6px 8px' }} animation="wave" />
        </Box>
        <Box>
          <Skeleton width={188} style={{ marginRight: '24px', padding: '6px 8px' }} animation="wave" />
        </Box>
      </Box>
      <Box>
        <Box>
          <Skeleton width={280} style={{ marginRight: '24px', padding: '6px 8px' }} animation="wave" />
        </Box>
        <Box>
          <Skeleton width={175} style={{ marginRight: '24px', padding: '6px 8px' }} animation="wave" />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUsSkeleton;
