import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiMenu from '@material-ui/core/Menu';

import { componentIdStringGenerator } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const Menu = (props) => {
  const classes = useStyles();

  return (
    <MuiMenu
      className={classes.root}
      {...props}
      id={componentIdStringGenerator('Menu', props.id ? props.id : props.children)}>
      {props.children}
    </MuiMenu>
  );
};

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

export default Menu;
