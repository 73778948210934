import React from 'react';
import { Box } from '@material-ui/core';

import { ViewContainer } from '../Layout';
import { Row, RowCol, Col } from '../ScreenSmith';
import { AOCard } from '../AOCard';
import { Feature } from '../Feature';
import featureFlags from '../../constants/featureFlags';
import { Typography } from '@material-ui/core';
import { AOButton } from '../AOButton';
import { AOLinkButton } from '../AOLinkButton';
import {
  ROUTE_APPLICATION_ADMIN,
  ROUTE_NEWS_ADD,
  ROUTE_NEWS_ARTICLE_ADMIN_SEARCH,
} from '../../constants/routes';

const NewsAdmin = () => {
  return (
    <Feature allowed={featureFlags.ACCESS_NEWS_ADMIN}>
      <ViewContainer title={'News Article Administration'}>
        <Box mb={2} display={'flex'} justifyContent={'flex-end'}>
          <AOButton
            color={'secondary'}
            route={ROUTE_APPLICATION_ADMIN}
            id="news-admin-container-button-application-admin">
            Application Administration
          </AOButton>
        </Box>
        <RowCol mb={2}>
          <AOCard>
            <Row spacing={4}>
              <Col xs={4}>
                <Typography color="textSecondary">
                  Welcome to the News Administration Home Page. The links to the right offer access
                  to all of the resources you need to add or modify news articles on the Home page.
                </Typography>
              </Col>

              <Col xs={8}>
                <RowCol mb={4}>
                  <AOLinkButton route={ROUTE_NEWS_ADD} style={{ fontSize: '1rem' }}>
                    Add News Article
                  </AOLinkButton>
                  <Typography color="textSecondary">
                    From this screen you may add new articles that will be available on the Home
                    page.
                  </Typography>
                </RowCol>

                <RowCol mb={4}>
                  <AOLinkButton
                    route={ROUTE_NEWS_ARTICLE_ADMIN_SEARCH}
                    style={{ fontSize: '1rem' }}>
                    Search/Edit News Articles
                  </AOLinkButton>
                  <Typography color="textSecondary">
                    From this page you may edit, delete or hide existing articles available on the
                    Home page.
                  </Typography>
                </RowCol>

              </Col>
            </Row>
          </AOCard>
        </RowCol>
      </ViewContainer>
    </Feature>
  );
};

export default NewsAdmin;
