import { useEffect, useState, useRef } from 'react';

// See here for usage: https://pgarciacamou.medium.com/react-simple-polling-custom-hook-usepollingeffect-1e9b6b8c9c71
export const usePolling = (
  asyncCallback,
  dependencies = [],
  {
    interval = 5_000, // 5 seconds default
    onCleanUp = () => {},
  } = {},
) => {
  const timeoutIdRef = useRef(null);
  const [isPolling, setIsPolling] = useState(false);
  useEffect(() => {
    if (!isPolling) return;
    let _stopped = false;
    // Side note: preceding semicolon needed for IIFEs.
    (async function pollingCallback() {
      try {
        await asyncCallback();
      } finally {
        // Set timeout after it finished, unless stopped
        timeoutIdRef.current = !_stopped && setTimeout(pollingCallback, interval);
      }
    })();
    // Clean up if dependencies change
    return () => {
      _stopped = true; // prevent racing conditions
      clearTimeout(timeoutIdRef.current);
      onCleanUp();
    };
  }, [...dependencies, isPolling, interval]);
  return [isPolling, setIsPolling];
};
