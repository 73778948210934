import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';
import { Typography } from '@material-ui/core';

// AO Components
import { RowCol } from '../../ScreenSmith';
import { AOCard } from '../../AOCard';
import { Feature } from '../../Feature';
import { AOCircularProgress } from '../../AOCircularProgress';
import featureFlags from '../../../constants/featureFlags';
import NewsAdminViewContainer from '../../NewsArticles/NewsAdminViewContainer';
import { AOSelect } from '../../AOSelect';

import { getNewsArticlesGridRows } from './SearchNewsArticlesDataGrid.api';
import SearchNewsArticlesDataGrid from './SearchNewsArticlesDataGrid';

const SearchNewsArticles = ({ resetFlow }) => {
  console.log(resetFlow);

  const [newsGridRows, setNewsGridRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const menuItems = [{ code: 'Agents', value: 'Agents' }];

  /* Fetch Grid Row table stuct */
  useEffect(async () => {
    try {
      const allNews = await getNewsArticlesGridRows();
      setNewsGridRows(allNews);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  return (
    <Feature allowed={featureFlags.ACCESS_NEWS_ADMIN}>
      <NewsAdminViewContainer title={'Search News'}></NewsAdminViewContainer>
      <AOCard>
        {loading ? (
          <AOCircularProgress visible={loading}>Fetching news...</AOCircularProgress>
        ) : (
          <RowCol>
            <RowCol mb={2}>
              <Typography variant="h5">List News Articles</Typography>
            </RowCol>
            <RowCol mb={4}>
              <RowCol mb={2}>
                <AOSelect
                  id="news-category-id"
                  labelId="news-category-id-label"
                  inputLabel="News Category"
                  name="newsCategory"
                  value="Agents"
                  disabled
                  menuItems={menuItems}
                />
              </RowCol>
              <RowCol>
                <Typography>{newsGridRows.length} records found on Agents news lookup</Typography>
                <Typography>
                  click on the <b>Title</b> to modify the article attribute
                </Typography>
                <Typography>
                  click on the <b>Type</b> to preview the article contents
                </Typography>
              </RowCol>
            </RowCol>
            <RowCol mb={4}>
              <SearchNewsArticlesDataGrid key={cuid()} rows={newsGridRows} />
            </RowCol>
          </RowCol>
        )}
      </AOCard>
    </Feature>
  );
};

SearchNewsArticles.propTypes = {
  resetFlow: PropTypes.func,
};

export default SearchNewsArticles;
