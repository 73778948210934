import React, { useState } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../../ScreenSmith';
import useScreenDefinition from '../../../hooks/useScreenDefinition';
import { PLSMaAutoDecReprintSkeleton } from '../../AOSkeleton';

import {
  getColData,
  handleChangePlMaAutoDecReprintNumber,
  handleOnClickRetrieveMaAutoDecReprintDocuments,
} from './PLSMaAutoDecReprint.api';

const PLSMaAutoDecReprint = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('PLSMaAutoDecReprintPanel');

  const [plMaAutoDecReprintNumber, setPlMaAutoDecReprintNumber] = useState('');
  const [loadingRetrievedDocuments, setLoadingRetrievedDocuments] = useState(false);
  const [dataGridIsVisible, setDataGridIsVisible] = useState(false);
  const [retrievedDocuments, setRetrievedDocuments] = useState(null);
  const [errorRetrievedDocuments, setErrorRetrievedDocuments] = useState(null);
  const [noRetrievedDocuments, setNoRetrievedDocuments] = useState(false);
  const [errorDownloadingPdf, setErrorDownloadingPdf] = useState(false);
  const [invalidPolicyNumber, setInvalidPolicyNumber] = useState(false);
  const [docSearchCustomerInfo, setDocSearchCustomerInfo] = useState({
    insuredName: '',
    policyNumber: '',
  });
  const [insuredStateValue, setInsuredStateValue] = useState('');
  const [policyNumberStateValue, setPolicyNumberStateValue] = useState('');

  const plState = {
    dataGridIsVisible,
    docSearchCustomerInfo,
    errorRetrievedDocuments,
    loadingRetrievedDocuments,
    noRetrievedDocuments,
    plMaAutoDecReprintNumber,
    plPolicyDocumentsColData: getColData(setErrorRetrievedDocuments, setErrorDownloadingPdf),
    retrievedDocuments,
    retrievedDocumentsButtonDisabled:
      invalidPolicyNumber || plMaAutoDecReprintNumber.length < 8 || loadingRetrievedDocuments,
    errorDownloadingPdf,
    insuredStateValue,
    policyNumberStateValue,
    invalidPolicyNumber,
  };

  const callBackFunctions = {
    handleChangePlMaAutoDecReprintNumber: (e) =>
      handleChangePlMaAutoDecReprintNumber(
        e,
        setPlMaAutoDecReprintNumber,
        setPolicyNumberStateValue,
        setInvalidPolicyNumber,
      ),
    handleOnClickRetrieveMaAutoDecReprintDocuments: () =>
      handleOnClickRetrieveMaAutoDecReprintDocuments(
        setLoadingRetrievedDocuments,
        setNoRetrievedDocuments,
        setRetrievedDocuments,
        plMaAutoDecReprintNumber,
        setDataGridIsVisible,
        setDocSearchCustomerInfo,
        setErrorRetrievedDocuments,
        setErrorDownloadingPdf,
        setInsuredStateValue,
      ),
  };
  if (loadingSd) return <PLSMaAutoDecReprintSkeleton />;

  return (
    !_.isNil(UiDefinitionsData) && (
      <ScreenSmith
        definition={UiDefinitionsData}
        functionMap={callBackFunctions}
        stateMap={plState}
      />
    )
  );
};

export default PLSMaAutoDecReprint;
