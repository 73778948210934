import React from 'react';
import axios from 'axios';
import cuid from 'cuid';
import _ from 'lodash';
import {
  POST_BILLING_SEARCH_SUGGESTIONS,
  GET_BILLING_SEARCH_PAGE_COUNT,
  GET_BILLING_EXACT_SEARCH,
  GET_BILLING_SEARCH,
  ACCOUNT_NUMBER_URL,
  INSURED_LAST_NAME_URL,
  POLICY_NUMBER_URL,
} from '../../constants/api';
import { AOButton } from '../AOButton';
import { ROUTE_ACCOUNT_DETAIL } from '../../constants/routes';

const handleChangeSearchCriteriaRadioValue = (
  event,
  _setSearchCriteriaRadioValue,
  _setSearchResultsVisible,
  _setSearchResults,
) => {
  const criteria = event.target.value;
  _setSearchResultsVisible(false);
  _setSearchResults(null);
  _setSearchCriteriaRadioValue(criteria);
};

const handleChangeInsuredLastNameRadioValue = (event, _setInsuredLastNameRadioValue) => {
  const criteria = event.target.value;
  _setInsuredLastNameRadioValue(criteria);
};

const fetchPolicyNumberFormSuggestions = async (params) => {
  const formsData = await axios.post(
    `${POST_BILLING_SEARCH_SUGGESTIONS}${POLICY_NUMBER_URL}`,
    params,
  );
  const formsSuggestion = formsData.data;
  return formsSuggestion;
};

const fetchAccountNumberFormSuggestions = async (params) => {
  const formsData = await axios.post(
    `${POST_BILLING_SEARCH_SUGGESTIONS}${ACCOUNT_NUMBER_URL}`,
    params,
  );
  const formsSuggestion = formsData.data;
  return formsSuggestion;
};

const fetchInsuredNameFormSuggestions = async (params) => {
  const formsData = await axios.post(
    `${POST_BILLING_SEARCH_SUGGESTIONS}${INSURED_LAST_NAME_URL}`,
    params,
  );
  const formsSuggestion = formsData.data;
  return formsSuggestion;
};

const getInvalidText = (property, value) => {
  const trimmedValue = value.trim();
  const trimmedValueLength = trimmedValue.length;
  if (!trimmedValueLength) return '';
  switch (property) {
    case 'policyNumber':
      return trimmedValueLength >= 3 ? '' : 'Please enter at least 3 alphanumeric characters';
    case 'accountNumber':
      return trimmedValueLength >= 3
        ? ''
        : 'When filtering on Account Number, please enter 8 to 15 alphanumeric characters with dash';
    case 'partialInsuredName':
      return trimmedValueLength >= 4 ? '' : 'Please enter a minimum of 3 characters';
    default:
      return '';
  }
};
// Policy Number event handlers
const handleAOAutocompleteOnChange = (property, e, value, formData, setFormData) => {
  setFormData({ ...formData, [property]: value });
};
const handleAOAutocompleteOnInputChange = (
  property,
  e,
  value,
  autocompleteInputValues,
  setAutocompleteInputValues,
  debouncedState,
  setDebouncedState,
) => {
  setAutocompleteInputValues({
    ...autocompleteInputValues,
    [property]: {
      ...autocompleteInputValues[property],
      value,
      invalidText: getInvalidText(property, value),
    },
  });
  setDebouncedState({ ...debouncedState, [property]: { ...debouncedState[property], value } });
};
const handleAOAutocompleteOnBlur = (property, formData, setFormData, autocompleteInputValues) => {
  if (formData[property] !== autocompleteInputValues[property].value) {
    setFormData({ ...formData, [property]: autocompleteInputValues[property].value });
  }
};

// Search
const handleOnClickSearch = async (
  _searchCriteria = 'policyNumber',
  _value,
  _setSearchResults,
  _setSearchResultsVisible,
  _insuredLastNameRadioValue,
  _setLoadingSearchResults,
  _setSearchResultsAlertData,
  _paginationData,
  _setPaginationData,
) => {
  _setLoadingSearchResults(true);
  _setSearchResultsVisible(false);
  _setSearchResultsAlertData((prevState) => ({
    ...prevState,
    isVisible: false,
  }));
  let searchResults;
  switch (_searchCriteria) {
    case 'policyNumber': {
      const rowCount = await axios.get(
        `${GET_BILLING_SEARCH_PAGE_COUNT}/exact${POLICY_NUMBER_URL}/${_value}`,
      );
      searchResults = await axios.get(
        `${GET_BILLING_EXACT_SEARCH}${POLICY_NUMBER_URL}/${_value}/${rowCount.data}`,
      );
      break;
    }
    case 'accountNumber': {
      const rowCount = await axios.get(
        `${GET_BILLING_SEARCH_PAGE_COUNT}/exact${ACCOUNT_NUMBER_URL}/${_value}`,
      );
      searchResults = await axios.get(
        `${GET_BILLING_EXACT_SEARCH}${ACCOUNT_NUMBER_URL}/${_value}/${rowCount.data}`,
      );
      break;
    }
    case 'insuredName': {
      const rowCountData = await axios.get(
        `${GET_BILLING_SEARCH_PAGE_COUNT}/${_insuredLastNameRadioValue}${INSURED_LAST_NAME_URL}/${_value}`,
      );
      const { data: rowCount } = rowCountData;
      _setPaginationData((prevVal) => ({ ...prevVal, totalCount: rowCount }));

      searchResults = await axios.get(
        `${GET_BILLING_SEARCH}${INSURED_LAST_NAME_URL}/${_insuredLastNameRadioValue}/${_value}/${
          _paginationData.currentPage + 1
        }`,
      );
      break;
    }
    default: {
      searchResults = await axios.get(`${GET_BILLING_SEARCH}${POLICY_NUMBER_URL}/${_value}`);
      break;
    }
  }
  const { data } = searchResults;
  if (data.length < 1) {
    _setLoadingSearchResults(false);
    _setSearchResultsAlertData((prevState) => ({
      ...prevState,
      severity: 'info',
      isVisible: true,
      message: 'No Results found. Please modify your search and try again.',
    }));
  } else {
    const srIds = data.map((sr) => ({ id: cuid(), ...sr }));
    _setSearchResults(srIds);
    _setSearchResultsVisible(true);
    _setLoadingSearchResults(false);
  }
};
export const renderCell = (params) => {
  return (
    !_.isNil(params.row) && (
      <AOButton
        aria-label="account-number"
        route={`${ROUTE_ACCOUNT_DETAIL}/${params.row.accountNumber}`}
        color="primary">
        {params.value}
      </AOButton>
    )
  );
};
const policyNumberCols = [
  {
    field: 'name',
    headerName: 'Account Name',
    sortable: true,
    flex: 1,
  },
  {
    field: 'accountNumber',
    headerName: 'Account Number',
    sortable: true,
    minWidth: 200,
    renderCell,
  },
  {
    field: 'address',
    headerName: 'Address',
    sortable: true,
    flex: 2,
  },
];

const handleClickPaginationChange = (
  page, // internal state variable from MUI Datagrid
  _paginationData,
  _setPaginationData,
  _setLoadingSearchResults,
  _setSearchResults,
  _insuredLastNameRadioValue,
  _searchCriteriaTextFieldValue,
) => {
  _setLoadingSearchResults(true);

  // request to api to get next/previous page.  Page is variable
  // that comes from MUI Datagrid.  It has internal state that
  // keeps track of page index.  Index starts at 0.  Our
  // api starts at 1.  For this reason we + 1.
  axios
    .get(
      `${GET_BILLING_SEARCH}${INSURED_LAST_NAME_URL}/${_insuredLastNameRadioValue}/${_searchCriteriaTextFieldValue}/${
        page + 1
      }`,
    )
    .then((res) => {
      const { data } = res;
      // Mui datagrid expects a unique id property for all objects.
      // We map through data and add a unique id.
      const srIds = data.map((sr) => ({ id: cuid(), ...sr }));
      _setSearchResults(srIds);
      _setLoadingSearchResults(false);
    })
    .catch((err) => console.error(err));
};
export {
  handleChangeSearchCriteriaRadioValue,
  handleChangeInsuredLastNameRadioValue,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
  fetchPolicyNumberFormSuggestions,
  fetchAccountNumberFormSuggestions,
  fetchInsuredNameFormSuggestions,
  handleOnClickSearch,
  policyNumberCols,
  handleClickPaginationChange,
  GET_BILLING_SEARCH_PAGE_COUNT,
  GET_BILLING_EXACT_SEARCH,
};
