import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { Box, Button, Col, Icon, Row, Typography, WorkflowViewContainer } from 'components/AORedesign';
import { FindProductToQuote } from 'components/FindProductToQuote';
import PLSQuoteNewBusiness from 'components/PersonalLinesServices/PLSQuoteNewBusiness';
import {
  COMMERCIAL_LOB_VIEW,
  COMMERCIAL_PRODUCTS_VIEW,
  PERSONAL_LOB_VIEW,
  WorkflowCardBonds,
  WorkflowCardCommercial,
  WorkflowCardFarmRanch,
  WorkflowCardPersonal,
  WorkflowContainer,
  WorkflowNoAccess,
} from 'components/Workflow';
import featureFlags from '../../constants/featureFlags';
import agentContext from '../../contexts/agent.context';

const useStyles = makeStyles((theme) => ({
  title: theme.typography.h3Brand,
  container: { margin: `${theme.spacing(5.5)}px auto`, minWidth: '75rem' },
}));

const StartQuote = ({ TriggerButton }) => {
  const [view, setView] = useState('');
  const classes = useStyles();

  const { FeatureFlags } = useContext(agentContext);

  const handlePersonalCardOnClick = () => {
    setView(PERSONAL_LOB_VIEW);
  };

  const handleCommercialCardOnClick = () => {
    setView(COMMERCIAL_LOB_VIEW);
  };

  const handleCommercialFindProductsOnClick = () => {
    setView(COMMERCIAL_PRODUCTS_VIEW);
  };

  const handleResetView = () => {
    setView('');
  };

  const renderTitle = () => {
    const getText = () => {
      switch (view) {
        case '':
          return 'What type of insurance are you looking for?';
        case PERSONAL_LOB_VIEW:
          return 'Personal Lines';
        case COMMERCIAL_LOB_VIEW:
          return 'Commercial Lines';
        default:
          return null;
      }
    };

    return (
      <Box mb={3}>
        <Box mb={view ? 0 : 0.5}>
          <Typography variant="h3" className={classes.title}>
            {getText()}
          </Typography>
        </Box>

        {!view && (
          <Typography variant="body2" color="textSecondary">
            Before we can start a quote we need some basic information.
          </Typography>
        )}
      </Box>
    );
  };

  const renderContent = () => {
    if (!view) {
      return (
        <Row spacing={3} justifyContent="center">
          <Col>
            <WorkflowCardPersonal onClick={handlePersonalCardOnClick} />
          </Col>
          <Col>
            <WorkflowCardCommercial onClick={handleCommercialCardOnClick} />
          </Col>
          <Col>
            <WorkflowCardBonds />
          </Col>
          <Col>
            <WorkflowCardFarmRanch />
          </Col>
        </Row>
      );
    } else if (view === PERSONAL_LOB_VIEW) {
      return FeatureFlags?.[featureFlags.ACCESS_PERSONAL_LINES_SERVICES] ? <PLSQuoteNewBusiness /> : <WorkflowNoAccess />;
    } else if (view.startsWith(COMMERCIAL_LOB_VIEW)) {
      return FeatureFlags?.[featureFlags.ACCESS_COMMERCIAL_LINES_SERVICES] && FeatureFlags?.[featureFlags.APPETITE_ENABLED] ? (
        <FindProductToQuote
          handleCommercialCardOnClick={handleCommercialCardOnClick}
          handleCommercialFindProductsOnClick={handleCommercialFindProductsOnClick}
        />
      ) : (
        <WorkflowNoAccess />
      );
    } else {
      return null;
    }
  };

  return (
    <WorkflowViewContainer
      appBarTitle={'Find Product to Quote'}
      buttonLabel={'Start Quote'}
      startIcon={<Icon iconName="icons-icon-request-quote" />}
      handleResetOnClose={handleResetView}
      TriggerButton={TriggerButton}>
      <Box className={classes.container}>
        {renderTitle()}

        <WorkflowContainer>{renderContent()}</WorkflowContainer>

        {/* show back does not need to be shown within cl products view because FindProductsToQuote has its own back button */}
        {view.length > 0 && ![COMMERCIAL_PRODUCTS_VIEW].includes(view) && (
          <Box pt={3} pb={2}>
            <Button onClick={handleResetView} startIcon={<ArrowBackIcon />} variant="text" color="secondary">
              Back
            </Button>
          </Box>
        )}
      </Box>
    </WorkflowViewContainer>
  );
};

StartQuote.propTypes = {
  TriggerButton: PropTypes.node,
};

export default StartQuote;
