import { parsePhoneNumber } from 'awesome-phonenumber';
import useAxios from 'axios-hooks';
import { Box, Link, Typography } from 'components/AORedesign';
import { ADMIN_REGIONAL_CONTACTS } from 'constants/api';
import AccountAdminContext from 'contexts/accountAdmin.context';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import AccountAdminSkeleton from './AccountAdminSkeleton';

const ContactUs = () => {
  const defaultEmail = 'customer@msagroup.com';
  const defaultPhone = '(877) 927-5672';
  const [responseHasError, setResponseHasError] = useState(false);

  const { contactUsData, setContactUsData, contactUsRegions, setContactUsRegions, accountAdminHasData } = useContext(AccountAdminContext);
  const [{ data, loading, error }] = useAxios(ADMIN_REGIONAL_CONTACTS, { manual: !_.isEmpty(contactUsData) && !_.isEmpty(contactUsRegions) });

  const setRegions = () => {
    if (_.isEmpty(contactUsRegions)) {
      const regions = _.keys(contactUsData).sort();
      setContactUsRegions(regions);
    }
  };

  const contactDetails = (contact, contactType) => {
    const titleMap = {
      sales: 'Sales',
      cl: 'Primary CL UW',
      pl: 'Primary PL UW',
    };

    const title = titleMap[contactType];
    const formatPhone = (phone) => {
      const rawPhone = parsePhoneNumber(phone, { regionCode: 'US' });
      return rawPhone.number.national;
    };

    return (
      <>
        {contact?.firstName && contact?.lastName && (
          <Box mt={2}>
            {contact?.email && contact?.workPhone ? (
              <Box>
                <Box mt={1} fontWeight={'bold'}>
                  {contact.firstName} {contact.lastName}, Your {title} Contact
                </Box>
                <Box mt={1}>
                  <Link href={`mailto:${contact.email}`}>{contact.email}</Link>
                </Box>
                <Box mt={1} style={{ color: 'grey' }}>
                  {formatPhone(contact.workPhone)}
                </Box>
              </Box>
            ) : (
              <Box>
                <Box mt={1} fontWeight={'bold'}>
                  {contact.firstName} {contact.lastName}
                </Box>
                <Box mt={1} style={{ color: 'grey' }}>
                  {title} Contact
                </Box>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!loading && data) {
      setContactUsData(data.data);
      setRegions();
    }
    if (error) {
      console.error(error);
      setResponseHasError(true);
      setContactUsData({});
    }
  }, [loading, error]);

  const regionList = () => {
    const regionList = [];
    setRegions();
    for (const region of contactUsRegions) {
      const salesContact = contactUsData[region]?.ADM;
      const clContact = contactUsData[region]?.CLU;
      const plContact = contactUsData[region]?.PLU;

      regionList.push(
        <Box>
          {(clContact || plContact || salesContact) && (
            <Box mt={4} mb={2}>
              <Typography variant="button" display="block" gutterBottom>
                {contactUsRegions.length === 1 ? 'SALES/UW CONTACTS' : region}
              </Typography>
            </Box>
          )}
          {contactDetails(salesContact, 'sales')}
          {contactDetails(plContact, 'pl')}
          {contactDetails(clContact, 'cl')}
        </Box>,
      );
    }
    return regionList;
  };

  return !accountAdminHasData ? (
    <AccountAdminSkeleton />
  ) : (
    <Box mb={2} styles={{ flexGrow: 1 }}>
      <Box mt={1} mb={4}>
        <Typography variant="h6">Contact Us</Typography>
      </Box>
      <Box mt={2} mb={4}>
        <Box mb={2}>
          <Typography variant="button" display="block" gutterBottom>
            CUSTOMER CARE
          </Typography>
        </Box>
        <Box mt={1} fontWeight={'bold'}>
          Customer Contact Center
        </Box>
        <Box mt={1}>
          <Link href={`mailto:${defaultEmail}`}>{defaultEmail}</Link>
        </Box>
        <Box mt={1} style={{ color: 'grey' }}>
          {defaultPhone}
        </Box>
        {!responseHasError && contactUsRegions && (
          <Box mt={4} mb={4} styles={{ flexGrow: 1 }}>
            <Box>{regionList()}</Box>
          </Box>
        )}
        {responseHasError && (
          <Box>
            <Box mt={4} mb={2}>
              <Typography variant="button" display="block" gutterBottom style={{ color: 'grey' }}>
                SALES/UW CONTACTS
              </Typography>
              <Box mt={1} style={{ color: 'grey' }}>
                Currently unable to display contacts
              </Box>
            </Box>
            <Box mt={4} mb={2}>
              <Typography variant="button" display="block" gutterBottom style={{ color: 'grey' }}>
                REGIONAL CONTACT
              </Typography>
              <Box mt={1} style={{ color: 'grey' }}>
                Currently unable to display contacts
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ContactUs;
