import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import cuid from 'cuid';

import { RowCol } from '../../../ScreenSmith';
import { ManageCurrentPaymentDataGrid } from './ManageCurrentPaymentDataGrid';
import { AOAlert } from '../../../AOAlert';

import { manageCurrentPaymentGridColDef, getManageCurrentPaymentsGridRows } from './ManageCurrentPayment.api';

import { handleClickBackToPaymentManagement } from '../../PaymentManagement/BillingServicesPaymentManagement.api';

const ManageCurrentPayment = ({ resetFlow, hideBackButton }) => {
  const [formData, setFormData] = useState({
    alert: {
      isVisible: false,
      severity: 'error',
      message: '',
    },
  });
  const [manageCurrentPaymentGridRows, setManageCurrPaymentGridRows] = useState([]);
  /* Fetch Grid Row table stuct */
  useEffect(async () => {
    try {
      const currPayments = await getManageCurrentPaymentsGridRows();
      setManageCurrPaymentGridRows(
        currPayments.map((el) => ({
          ...el,
          id: cuid(),
        })),
      );
    } catch (error) {
      console.log(error);
      setFormData((prevValue) => ({
        ...prevValue,
        alert: { isVisible: true, severity: 'error', message: error.message },
      }));
    }
  }, []);
  return (
    <RowCol>
      {!hideBackButton && (
        <RowCol mb={2}>
          <Button startIcon={<ArrowBackIcon />} color="primary" size="small" onClick={() => handleClickBackToPaymentManagement(resetFlow)}>
            Back to Payment Management
          </Button>
        </RowCol>
      )}
      <RowCol mb={2}>
        <Typography variant="h5">Payment List</Typography>
      </RowCol>
      <RowCol mb={2}>
        {formData.alert.isVisible ? (
          <AOAlert message={formData.alert.message} severity={formData.alert.severity} />
        ) : (
          <ManageCurrentPaymentDataGrid
            rows={manageCurrentPaymentGridRows}
            columns={manageCurrentPaymentGridColDef}
            setManageCurrPaymentGridRows={setManageCurrPaymentGridRows}
          />
        )}
      </RowCol>
      <RowCol>
        <Typography>Click on any transaction to alter it.</Typography>
      </RowCol>
    </RowCol>
  );
};

ManageCurrentPayment.propTypes = {
  hideBackButton: PropTypes.bool,
  resetFlow: PropTypes.func,
};

export default ManageCurrentPayment;
