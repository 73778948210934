import React from 'react';
import PropTypes from 'prop-types';

import { Box, Select } from 'components/AORedesign';
import { GET_PERM_AGENCIES } from 'constants/api';
import useFetchSelectMenuItems from 'hooks/useFetchSelectMenuItems';

const LossesByAgentReportAgentView = ({ menuItems, onChange, setPermAgenciesOptions, value }) => {
  useFetchSelectMenuItems(GET_PERM_AGENCIES, (response) => {
    if (response.data.length) {
      setPermAgenciesOptions([
        ...response.data.map((agency) => ({
          label: `${agency.agencyCode} - ${agency.agencyCity?.trim()}, ${agency.agencyState?.trim()}`,
          value: agency.agencyCode,
        })),
      ]);
    }
  });

  return (
    <Box mb={2}>
      <Select
        id="lba-agent-id"
        labelId="lba-agent-id-label"
        label="Agent ID"
        name="agencyCode"
        menuItems={menuItems || []}
        onChange={onChange}
        value={value}
        style={{ minWidth: '435px' }}
      />
    </Box>
  );
};

LossesByAgentReportAgentView.propTypes = {
  menuItems: PropTypes.array,
  onChange: PropTypes.func,
  setPermAgenciesOptions: PropTypes.func,
  value: PropTypes.string,
};

export default LossesByAgentReportAgentView;
