import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import UserAdminViewContainer from './UserAdminViewContainer';
import { ScreenSmith } from '../ScreenSmith';
import UserAdminSkeleton from './UserAdminSkeleton';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import EditUser from './views/EditUser';

const LocalComponents = { EditUser };

const UserAdminEditUser = ({ hideReturn, title, username }) => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`UserAdmEditUser`);

  const localStateMap = { title: title, username };

  const callBackFunctions = {};

  return loadingSd ? (
    <UserAdminSkeleton />
  ) : (
    <UserAdminViewContainer hideReturn={hideReturn}>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith definition={UiDefinitionsData} functionMap={callBackFunctions} stateMap={localStateMap} componentMap={LocalComponents} />
      )}
    </UserAdminViewContainer>
  );
};

UserAdminEditUser.propTypes = {
  title: PropTypes.string,
  hideReturn: PropTypes.bool,
  username: PropTypes.string,
};

export default UserAdminEditUser;
