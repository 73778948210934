import React from 'react';
import { Row, Col, RowCol } from '../ScreenSmith';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

const AgencyToolkitRegionalContacts = () => {
  return (
    <>
      <RowCol mb={2}>
        <Typography variant="h6">Regional Contacts</Typography>
      </RowCol>
      <Row spacing={3} mb={1}>
        <Col xs={6}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <Box p={2}>
              <Box mb={2}>
                <Typography variant={'h6'}>Northeast Region</Typography>
              </Box>
              <Typography component={'div'}>
                <Box fontWeight={'bold'}>Chris Cox, Regional President</Box>
              </Typography>
              <Typography>Beth Dame Regional Sales Director </Typography>
              <Typography>Kristin Schoenbach, Regional Sales Director </Typography>
              <Typography>Renee Tolerico, Regional Sales Director</Typography>
              <Box mb={2}></Box>
              <Typography color={'textSecondary'}>
                <Box display={'inline'}>
                  Includes Connecticut, Maine, Massachusetts, New Hampshire, New York, Rhode Island,
                  and Vermont.
                </Box>
              </Typography>
            </Box>
          </Paper>
        </Col>
        <Col xs={6}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <Box p={2}>
              <Box mb={2}>
                <Typography variant={'h6'}>Southeast Region</Typography>
              </Box>
              <Typography component={'div'}>
                <Box fontWeight={'bold'}>Dan Gaynor, Regional President</Box>
              </Typography>
              <Typography>Kavita Chowdhary, Regional Sales Director</Typography>
              <Typography>Logan Presnell, Regional Sales Director</Typography>
              <Box mb={2}></Box>
              <Typography color={'textSecondary'}>
                <Box display={'inline'}>
                  Includes Delaware, Florida, Georgia, Maryland, Mississippi, North Carolina, South
                  Carolina, Tennessee, and Virginia.
                </Box>
              </Typography>
            </Box>
          </Paper>
        </Col>
      </Row>
      <Row spacing={3}>
        <Col xs={6}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <Box p={2}>
              <Box mb={2}>
                <Typography variant={'h6'}>Central Region</Typography>
              </Box>
              <Typography component={'div'}>
                <Box fontWeight={'bold'}>Stacy Juelfs, Regional President</Box>
              </Typography>
              <Typography>Jaime Wagoner, Regional Sales Director</Typography>
              <Box mb={2}></Box>
              <Typography color={'textSecondary'}>
                <Box display={'inline'}>
                  Includes Illinois, Indiana, Iowa, Kansas, Michigan, Minnesota, Missouri, Nebraska,
                  New Jersey, North Dakota, Ohio, Oklahoma, Pennsylvania, South Dakota, and
                  Wisconsin.
                </Box>
              </Typography>
            </Box>
          </Paper>
        </Col>
        <Col xs={6}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <Box p={2}>
              <Box mb={2}>
                <Typography variant={'h6'}>West Region</Typography>
              </Box>
              <Typography component={'div'}>
                <Box fontWeight={'bold'}>Stacy Juelfs, Regional President</Box>
              </Typography>
              <Typography>Malissa Thorp, Regional Sales Director</Typography>
              <Box mb={2}></Box>
              <Typography color={'textSecondary'}>
                <Box display={'inline'}>
                  Includes Arizona, Colorado, Idaho, Montana, Nevada, New Mexico, Oregon, Utah,
                  Washington, and Wyoming.
                </Box>
              </Typography>
            </Box>
          </Paper>
        </Col>
      </Row>
      <RowCol spacing={2} width="100%" alignItems={'center'}>
        <Box mt={2} display="flex" alignItems={'center'} justifyContent={'center'}>
          <Box
            component="img"
            sx={{ width: '100%', maxWidth: 1000 }}
            alt="Regional sales map"
            src={`${process.env.PUBLIC_URL}/assets/images/regional_sales_map.png`}
          />
        </Box>
      </RowCol>
    </>
  );
};

export default AgencyToolkitRegionalContacts;
