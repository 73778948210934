import { rem } from 'polished';
import { Box, styled } from '@material-ui/core';

import { AOButton } from '../AOButton';

const StyledBillingInquiryDetails = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(16)}`,
  },
}));

const StyledBillingInquiryDetailsPDFButton = styled(AOButton)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  padding: 0,
  textTransform: 'inherit',
  fontSize: '1rem',
  '& .MuiButton-label': {
    color: theme.palette.secondary.main,
  },
}));

const StyledModalContainer = styled(Box)(({ theme }) => ({
  margin: `${rem(30)} auto`,
  width: 1200,
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2),
}));

const StyledConfirmationButton = styled(Box)({
  cursor: 'pointer',
  textDecoration: 'underline',
});

export {
  StyledBillingInquiryDetails,
  StyledBillingInquiryDetailsPDFButton,
  StyledModalContainer,
  StyledConfirmationButton,
};
