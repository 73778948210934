import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Paper, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { RowCol, Row, Col } from '../../../../ScreenSmith';
import { AOAlert } from '../../../../AOAlert';
import { AOButton } from '../../../../AOButton';

import AdjustPaymentDetails from './AdjustPaymentDetails';
import ActionOnPayment from './ActionOnPayment';

import { detailsType } from '../ManageCurrentPayment.types';

const ManageCurrentPaymentModal = ({
  open,
  handleClose,
  loading,
  alertIsActive,
  alertSeverity,
  alertMsg,
  details,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="manage-current-payment-title"
      aria-describedby="manage-current-payment-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper>
        <Box sx={{ width: 800, height: 450 }} p={4}>
          {loading ? (
            <CircularProgress color="inherit" size={20} data-testid="loading-spinner" />
          ) : (
            <RowCol>
              {alertIsActive ? (
                <AOAlert severity={alertSeverity} message={alertMsg} />
              ) : (
                <RowCol>
                  <RowCol mb={4}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <Typography variant="h6">Adjust Payment</Typography>
                      <Box display="flex" justifyContent="flex-end">
                        <Box mx={2}>
                          <AOButton
                            color="secondary"
                            variant="contained"
                            onClick={() => handleClose(false)}>
                            List
                          </AOButton>
                        </Box>
                        <AOButton
                          color="secondary"
                          // variant="contained"
                          onClick={() => handleClose(false)}>
                          Cancel
                        </AOButton>
                      </Box>
                    </Box>
                  </RowCol>
                  <Row>
                    <Col xs={12} md={6}>
                      <AdjustPaymentDetails details={details} />
                    </Col>
                    <Col xs={12} md={6}>
                      <ActionOnPayment amount={details.paymentAmount} index={details.index} handleClose={handleClose} />
                    </Col>
                  </Row>
                </RowCol>
              )}
            </RowCol>
          )}
        </Box>
      </Paper>
    </Modal>
  );
};

ManageCurrentPaymentModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  alertIsActive: PropTypes.bool,
  alertSeverity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  alertMsg: PropTypes.string,
  loading: PropTypes.bool,
  details: detailsType
};

export default ManageCurrentPaymentModal;
