import React from 'react';
import PropTypes from 'prop-types';
import MuiPaper from '@material-ui/core/Paper';
import MuiBox from '@material-ui/core/Box';

const Card = (props) => {
  return (
    <MuiPaper {...props}>
      <MuiBox p={2}>{props.children}</MuiBox>
    </MuiPaper>
  );
};

Card.propTypes = {
  children: PropTypes.node,
};

export default Card;
