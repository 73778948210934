import { useState, useEffect } from 'react';
import axios from 'axios';

import { BRANDFOLDER_VALIDATIONS } from '../../../constants/api';
import { getEnumSearchCriteria } from './filterHelpers';

const useInitializeFilter = (page, item, setInitialQuery) => {
  const [filterSpec, setFilterSpec] = useState({});

  useEffect(() => {
    if (page && item && setInitialQuery) {
      let unmounted = false;

      const controller = new AbortController();

      axios
        .get(BRANDFOLDER_VALIDATIONS, {
          params: {
            page,
            item,
          },
          signal: controller.signal,
        })
        .then((response) => {
          const { data } = response;
          setFilterSpec(data);

          const keyName = data.searchTerm;
          let initialQuery = '';

          if (data.type === 'enum') {
            initialQuery = getEnumSearchCriteria(data.values);
          }

          setInitialQuery(keyName, initialQuery);
        })
        .catch((err) => {
          if (!unmounted) {
            if (axios.isCancel(err)) {
              console.error(`request cancelled in ${page} - ${item}: ${err.message}`);
            } else {
              console.error(`another error happened in ${page} - ${item}: ` + err.message);
            }
          }
        })
        .finally(() => {});

      return () => {
        unmounted = true;
        controller.abort();
      };
    }
  }, []);

  return { filterSpec };
};

export default useInitializeFilter;
