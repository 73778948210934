import { rem } from 'polished';

import { Box, styled, ButtonBase } from '@material-ui/core';

const StyledNewsArticles = styled(Box)(({ theme }) => ({
  padding: rem(theme.spacing(0)),
}));

const StyledArticlePreviewContainer = styled(Box)(({ theme }) => ({
  padding: `${rem(theme.spacing(1))} 0`,
  borderBottom: `${theme.palette.text.disabled} solid 1px`,
  cursor: 'pointer',

  '&:first-child': { paddingTop: 0 },
  '&:last-child': {
    borderBottom: 'none',
    paddingBottom: 0,
  },
}));

const SingleLinePreview = styled(Box)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  lineClamp: 1,
  '-webkit-box-orient': 'vertical',
}));

const StyledButtonBase = styled(ButtonBase)(() => ({
  textAlign: 'left',
}));

export { StyledNewsArticles, StyledArticlePreviewContainer, SingleLinePreview, StyledButtonBase };
