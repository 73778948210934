import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid as MuiDataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';

const DataGrid = ({
  autoHeight,
  exportToCSV,
  fileName,
  noResultsMessage = 'No results returned for this search. Please refine your search and try again.',
  ...props
}) => {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ fileName }} />
      </GridToolbarContainer>
    );
  };

  const rowsLength = props?.rows?.length;

  if (rowsLength > 0) {
    return (
      <MuiBox style={{ height: autoHeight ? '' : 400, width: '100%' }}>
        <MuiDataGrid
          disableSelectionOnClick
          components={{
            Toolbar: exportToCSV ? CustomToolbar : undefined,
          }}
          autoHeight={autoHeight}
          {...props}
        />
      </MuiBox>
    );
  } else if (rowsLength === 0) {
    return <MuiTypography color="textSecondary">{noResultsMessage}</MuiTypography>;
  } else {
    return null;
  }
};

DataGrid.propTypes = {
  autoHeight: PropTypes.bool,
  columns: PropTypes.array,
  error: PropTypes.string,
  exportToCSV: PropTypes.bool,
  fileName: PropTypes.string,
  loading: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  rows: PropTypes.array,
};

export default DataGrid;
