import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import useAxios from 'axios-hooks';
import _ from 'lodash';
import moment from 'moment';

import { ScreenSmith } from '../ScreenSmith';
import { QuoteNewBusinessSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import AppDataContext from '../../contexts/appData.context';
import { AgentLookupModal } from '../AgentLookupModal';
import { FindProductToQuote } from '../FindProductToQuote';

import {
  GET_CL_NEW_QUOTE_NEW_BUSINESS_PRODUCT_LINES_URL,
  GET_QUOTE_NEW_BUSINESS_STATUS,
  GET_RISK_STATES_URL,
  GET_ALLOWED_AGENCY_CODES,
  GET_COMMERCIAL_LINES,
} from '../../constants/api';

const CLSQuoteNewBusiness = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('CLSQuoteNewBusinessPanel');
  const { clearAppetiteData } = useContext(AppDataContext);

  const [selectedLob, setSelectedLob] = useState('');
  const [productLines, setProductLines] = useState([]);
  const [riskStates, setRiskStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedNewQuoteEffectiveDate, setSelectedNewQuoteEffectiveDate] = useState(
    moment().format('MM/DD/yyyy'),
  );

  const [quoteNewBusinessStatus, setQuoteNewBusinessStatus] = useState({});
  const [isNewQuoteButtonVisible, setIsNewQuoteButtonVisible] = useState(false);
  const [effectiveDateOrLater, setEffectiveDateOrLater] = useState(null);
  const [effectiveDateOrLaterIsVisible, setEffectiveDateOrLaterIsVisible] = useState(false);
  const [agencyCodes, setAgencyCodes] = useState();
  const [selectedAgencyCode, setSelectedAgencyCode] = useState('');
  const [isAgencyCodeVisible, setAgencyCodeVisible] = useState(false);
  const [errorGetAllowedAgencies, setErrorGetAllowedAgencies] = useState(null);
  const [isGetAllowedAgenciesAlertVisible, setIsGetAllowedAgenciesAlertVisible] = useState(false);
  const [isQuoteNewBusinessRedirectErrorVisible, setIsQuoteNewBusinessRedirectErrorVisible] =
    useState(false);
  const [quoteNewBusinessRedirectError, setQuoteNewBusinessRedirectError] = useState(false);
  const [isAgentLookupModalOpen, setAgentLookupModalOpen] = useState(false);

  const handleClickNewQuote = () => {
    let requestBody;
    const mseRequestBody = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'MSE Agency Solution Center',
      DetailedContext: [
        {
          name: 'system',
          value: 'SBSS',
        },
        {
          name: 'state',
          value: selectedState,
        },
        {
          name: 'effectiveDate',
          value: selectedNewQuoteEffectiveDate,
        },
        {
          name: 'agencyNumber',
          value: selectedAgencyCode,
        },
      ],
    };
    switch (selectedLob) {
      case 'MCP':
      case 'ME':
        requestBody = mseRequestBody;
        break;
      default:
        requestBody = {
          Area: 'Commercial Lines',
          TypeOfRedirect: 'CL Quote New Business',
          DetailedContext: [
            {
              name: 'businessType',
              value: 'CL',
            },
            {
              name: 'lineOfBusiness',
              value: selectedLob,
            },
            {
              name: 'state',
              value: selectedState,
            },
            {
              name: 'effectiveDate',
              value: selectedNewQuoteEffectiveDate,
            },
            {
              name: 'agencyNumber',
              value: selectedAgencyCode,
            },
          ],
        };
    }
    return redirectUrlFunction(
      requestBody,
      'POST',
      null,
      setQuoteNewBusinessRedirectError,
      setIsQuoteNewBusinessRedirectErrorVisible,
    );
  };

  // --- Event Handlers for Quote/New Business ---
  const productLinesUrl = `${GET_CL_NEW_QUOTE_NEW_BUSINESS_PRODUCT_LINES_URL}`;
  const [{ data: productLinesData, loading: productLinesLoading }] = useAxios({
    url: `${productLinesUrl}`,
  });

  const getProductLines = () => {
    return productLines;
  };

  // Fetch agency codes when we have a dropdown
  const getAgentCodeOptions = async (selectedRiskState) => {
    if (selectedLob === 'ME' || selectedLob === 'MCP') {
      // we know we have a dropdown when not a text input with a model open icon button)
      const modalContainer = document.getElementById('AOIconButtonAgentLookup');
      if (!modalContainer) {
        const agencyCodeMenuItems = await getAllowedAgencyCodeMenuItems(
          selectedNewQuoteEffectiveDate,
          selectedRiskState,
        );
        setAgencyCodes(agencyCodeMenuItems);
        return;
      }
    }

    return Promise.resolve();
  };

  // handle LOB dropdown selection
  const handleChangeNewQuoteLineOfBusiness = useCallback(async (event) => {
    setIsGetAllowedAgenciesAlertVisible(false);
    setIsQuoteNewBusinessRedirectErrorVisible(false);
    setSelectedLob(event.target.value);
    setSelectedNewQuoteEffectiveDate(moment().format('MM/DD/yyyy'));
    setSelectedState('');
    setAgencyCodeVisible(false);
    setSelectedAgencyCode('');
    const riskMenuItems = await getRiskStatemenuItems(event);
    setRiskStates(riskMenuItems);
  });

  // handle State dropdown selection
  const handleChangeRiskState = useCallback(async (event) => {
    setSelectedAgencyCode('');
    getQuoteNewBusinessStatus(selectedNewQuoteEffectiveDate, event.target.value);

    // update dropdown options only when a dropdown (when not a text input with a model open icon button)
    getAgentCodeOptions(event.target.value);

    setSelectedState(event.target.value);
    setIsGetAllowedAgenciesAlertVisible(false);
    setIsQuoteNewBusinessRedirectErrorVisible(false);
  });

  // Get quote new business status for the Date and State
  const getQuoteNewBusinessStatus = async (effectiveDate, riskState) => {
    try {
      const quoteNewBusinessStatusData = await getQuoteNewBusinesStatusData(
        effectiveDate,
        riskState,
      );
      setQuoteNewBusinessStatus(quoteNewBusinessStatusData);
      if (!_.isNil(quoteNewBusinessStatusData.data.message)) {
        setEffectiveDateOrLaterIsVisible(true);
        setEffectiveDateOrLater(quoteNewBusinessStatusData.data.message);
      } else {
        setEffectiveDateOrLaterIsVisible(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // handle Date selection
  const handleChangeNewQuoteNewBusinessEffectiveDate = (value) => {
    const effDate = moment(value).format('MM/DD/YYYY');

    setSelectedAgencyCode('');
    setIsQuoteNewBusinessRedirectErrorVisible(false);
    setSelectedNewQuoteEffectiveDate(effDate);
    setIsQuoteNewBusinessRedirectErrorVisible(false);
    if (selectedState.trim() !== '') {
      getQuoteNewBusinessStatus(effDate, selectedState);

      // update dropdown options only when a dropdown (when not a text input with a model open icon button)
      getAgentCodeOptions(selectedState);
    }
  };

  // Handle onChange event for Agency Code dropdown
  const handleChangeAgencyCode = useCallback(async (event) => {
    setSelectedAgencyCode(event.target.value);
  });

  // hide/show Agency Code field
  const renderAgencyCodeField = () => {
    if (
      quoteNewBusinessStatus?.data &&
      selectedNewQuoteEffectiveDate &&
      selectedState &&
      (selectedLob === 'ME' || selectedLob === 'MCP')
    ) {
      setAgencyCodeVisible(true);
    } else {
      setAgencyCodeVisible(false);
    }
  };

  // hide/show New Quote button
  const renderQuoteNewBusinessButtons = () => {
    if (
      quoteNewBusinessStatus?.data?.flgReadyForBusiness &&
      selectedLob &&
      selectedNewQuoteEffectiveDate &&
      selectedState &&
      ((selectedLob !== 'ME' && selectedLob !== 'MCP') || selectedAgencyCode)
    ) {
      setIsNewQuoteButtonVisible(true);
    } else {
      setIsNewQuoteButtonVisible(false);
    }
  };

  const getQuoteNewBusinesStatusData = async (effectiveDate, selectedRiskStateItem) => {
    const quoteNewBusinessStatusUrl = `${GET_QUOTE_NEW_BUSINESS_STATUS}${GET_COMMERCIAL_LINES}?productLine=${selectedLob}&effectiveDate=${effectiveDate}&riskState=${selectedRiskStateItem}`;
    const quoteNewBusinessStatus = await axios.get(`${quoteNewBusinessStatusUrl}`);
    return quoteNewBusinessStatus;
  };

  const getRiskStatemenuItems = async (event) => {
    const riskStatesUrl = `${GET_RISK_STATES_URL}/${event.target.value}${GET_COMMERCIAL_LINES}`;
    const states = await axios.get(`${riskStatesUrl}`);
    const riskStateMenuItems = states.data.map((el) => {
      return {
        code: el.stateCode,
        value: el.stateName,
      };
    });

    return riskStateMenuItems;
  };

  const getAllowedAgencyCodeMenuItems = async (effectiveDate, selectedRiskStateItem) => {
    const allowedAgencyUrl = `${GET_ALLOWED_AGENCY_CODES}`;

    let allowedAgencyMenuItems;
    let selectedEffectiveDateFormat = moment(effectiveDate).format('yyyy-MM-DD');
    try {
      const allowedAgencies = await axios.get(
        `${allowedAgencyUrl}/${selectedRiskStateItem}/${selectedEffectiveDateFormat}/Main Street Express`,
      );
      if (
        allowedAgencies &&
        !_.isNil(allowedAgencies.data) &&
        !_.isEmpty(allowedAgencies.data.Results)
      ) {
        allowedAgencyMenuItems = allowedAgencies.data.Results.map((el) => {
          return {
            code: el,
            value: el,
          };
        });
      } else {
        setErrorGetAllowedAgencies('No agencies available');
        setIsGetAllowedAgenciesAlertVisible(true);
        setIsQuoteNewBusinessRedirectErrorVisible(false);
      }
    } catch (err) {
      // Handle Error Here
      console.error(err);
      setErrorGetAllowedAgencies(err.message);
      setIsGetAllowedAgenciesAlertVisible(true);
      setIsQuoteNewBusinessRedirectErrorVisible(false);
      allowedAgencyMenuItems = err;
      setIsNewQuoteButtonVisible(false);
    }
    return allowedAgencyMenuItems;
  };

  // TODO: does not appear to be sued by component or screen defn
  const handleClickRiskAppetiteStartQuote = () => {
    console.info('Start Quote');
    return false;
  };

  // TODO: can probably remove this.  It is related to the Beta feature flag
  const handleClickGetResources = (event) => {
    const dataResource = event.currentTarget.getAttribute('data-resource');
    console.info(`Getting resource ${dataResource}`);
    return false;
  };

  // TODO: does not appear to be sued by component or screen defn
  const handleChangeAgentCodeValue = (event) => {
    setSelectedAgencyCode(event.target.value);
  };

  // Open the model button when the icon is clicked
  const handleAgentLookupModalOpen = () => {
    setAgentLookupModalOpen(true);
  };

  const clState = {
    effectiveDateOrLater,
    effectiveDateOrLaterIsVisible,
    isNewQuoteButtonVisible,
    isAgencyCodeVisible,
    agencyCodes,
    productLines,
    riskStates,
    selectedLob,
    selectedNewQuoteEffectiveDate,
    selectedAgencyCode,
    selectedState,
    errorGetAllowedAgencies,
    isGetAllowedAgenciesAlertVisible,
    isQuoteNewBusinessRedirectErrorVisible,
    quoteNewBusinessRedirectError,
    isAgentLookupModalOpen,
  };

  const callBackFunctions = {
    handleClickGetResources,
    handleClickRiskAppetiteStartQuote,
    getProductLines,
    handleChangeNewQuoteLineOfBusiness,
    handleChangeNewQuoteNewBusinessEffectiveDate,
    handleChangeAgencyCode,
    handleChangeRiskState,
    handleClickNewQuote,
    clearAppetiteData: () => clearAppetiteData(),
    handleChangeAgentCodeValue,
    handleAgentLookupModalOpen,
    setAgentLookupModalOpen,
    setSelectedAgencyCode,
  };

  // Tell ScreenSmith to load components it does not know about (components only used in this component)
  const LocalComponents = {
    AgentLookupModal,
    FindProductToQuote,
  };

  useEffect(async () => {
    renderAgencyCodeField();
  }, [selectedLob, UiDefinitionsData, quoteNewBusinessStatus]);

  useEffect(async () => {
    renderQuoteNewBusinessButtons();
  }, [
    selectedLob,
    selectedNewQuoteEffectiveDate,
    selectedState,
    UiDefinitionsData,
    selectedAgencyCode,
    quoteNewBusinessStatus,
  ]);

  useEffect(() => {
    if (!productLinesLoading && !_.isNil(UiDefinitionsData)) {
      const lobs = productLinesData.lobs;
      try {
        const productLinesMenuItems = lobs.map((el) => {
          return {
            code: el.productLineCode,
            value: el.productLineName,
          };
        });
        setProductLines(productLinesMenuItems);
      } catch (error) {
        setProductLines([]);
      }
    }
  }, [productLinesLoading, UiDefinitionsData]);

  if (loadingSd) return <QuoteNewBusinessSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={clState}
          componentMap={LocalComponents}
        />
      )}
    </>
  );
};

export default CLSQuoteNewBusiness;
