import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useState } from 'react';

import { Box, Button, Link, WorkflowViewContainer } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import { UserAdminEditUser, UserAdminSearchUser } from 'components/UserAdmin';
import featureFlags from 'constants/featureFlags';

const EDIT_USER_LABEL = 'Search/Edit User';

const UserAdminEditUserLink = () => {
  const [username, setUsername] = useState('');

  const handleSetUsername = (username) => setUsername(username);

  const handleBackToSearch = () => handleSetUsername('');

  const TriggerButton = (props) => (
    <Link {...props} startIcon={'editOutlined'}>
      {EDIT_USER_LABEL}
    </Link>
  );

  return (
    <Feature allowed={featureFlags.ACCESS_AGENCY_SECURITY_USER_ADMIN}>
      <WorkflowViewContainer appBarTitle={EDIT_USER_LABEL} TriggerButton={TriggerButton} handleResetOnClose={handleBackToSearch}>
        <Box mx={9} my={5}>
          {username.length === 0 ? (
            <UserAdminSearchUser hideReturn handleSetUsername={handleSetUsername} />
          ) : (
            <>
              <UserAdminEditUser title={'Edit User Information'} hideReturn username={username} />
              <Box pb={2}>
                <Button startIcon={<ArrowBackIcon />} onClick={() => handleBackToSearch()} variant="text">
                  Back
                </Button>
              </Box>
            </>
          )}
        </Box>
      </WorkflowViewContainer>
    </Feature>
  );
};

export default UserAdminEditUserLink;
