import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import useAxios from 'axios-hooks';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { FILTER_TYPES, POLICY_PARAM_VALUES } from 'components/AORedesignViews/Clients';
import { GET_DASHBOARD_OVERDUE_PAYMENTS } from 'constants/api';
import { ROUTE_CLIENTS } from 'constants/routes';
import agentContext from 'contexts/agent.context';
import AppDataContext from 'contexts/appData.context';
import DashboardActionCard from './DashboardActionCard';

export const OVERDUE_PAYMENTS_KEY = 'payments';

const PaymentsCard = () => {
  const history = useHistory();
  const { Locations } = useContext(agentContext);
  const { dashboard, updateDashboardCounts } = useContext(AppDataContext);
  const overDuePaymentData = dashboard?.[OVERDUE_PAYMENTS_KEY];
  const [{ data, loading }] = useAxios(GET_DASHBOARD_OVERDUE_PAYMENTS, {
    manual: Locations.length > dashboard?.locationCodeLimit || overDuePaymentData?.count,
  });

  useEffect(() => {
    if (!loading && data) {
      updateDashboardCounts(OVERDUE_PAYMENTS_KEY, data);
    }
  }, [loading]);

  return (
    <DashboardActionCard
      count={overDuePaymentData?.count}
      icon={<PaymentOutlinedIcon />}
      onClick={() => history.push(`${ROUTE_CLIENTS}?${FILTER_TYPES.POLICY}=${POLICY_PARAM_VALUES.OVERDUE_PAYMENTS}`)}
      subtitle={`${overDuePaymentData?.days} DAYS OVERDUE`}
      title="Overdue Payments"
    />
  );
};

export default PaymentsCard;
