import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

import AOButtonLoadingContainer from './AOButtonLoadingContainer';

import AppDataContext from '../../contexts/appData.context';
import {
  ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS,
  ROUTE_BILLING_SERVICES,
} from '../../constants/routes';

const IconComponents = {
  ArrowBack,
};

const PageNames = {
  [ROUTE_BILLING_SERVICES]: 'Billing Services',
  [ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS]: 'Transaction Activity Report',
};

const getPageName = (route) => {
  const routes = Object.keys(PageNames);
  const routeKey = routes.find((_route) => _route.includes(route));
  return PageNames[routeKey] ?? 'Billing Services';
};

const AORouteButton = ({
  children,
  endIcon,
  loading,
  onClick,
  route,
  startIcon,
  ...otherProps
}) => {
  const { setPreviousPageName } = useContext(AppDataContext);

  const history = useHistory();

  const handleClick = (e, _route) => {
    const route = history.location.pathname;
    setPreviousPageName(getPageName(route));

    history.push(`${_route}`);

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <AOButtonLoadingContainer loading={loading}>
      <Button
        onClick={(e) => handleClick(e, route)}
        startIcon={
          startIcon &&
          IconComponents[startIcon] &&
          React.createElement(IconComponents[startIcon], null, null)
        }
        endIcon={
          endIcon &&
          IconComponents[endIcon] &&
          React.createElement(IconComponents[endIcon], null, null)
        }
        {...otherProps}>
        {children}
      </Button>
    </AOButtonLoadingContainer>
  );
};

AORouteButton.propTypes = {
  children: PropTypes.node,
  endIcon: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  route: PropTypes.string,
  startIcon: PropTypes.string,
};

export default AORouteButton;
