import React from 'react';

import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import { ViewContainer, Card } from 'components/AORedesign';
import { PaymentHistory as PaymentHistoryWidget } from 'components/BillingServices/PaymentManagement/PaymentHistory';

const PaymentHistory = () => {
  return (
    <Feature allowed={featureFlags.ACCESS_PAYMENT_HISTORY_AND_CURRENT_PAYMENTS}>
      <ViewContainer hidePolicyViewChange hideStartQuote returnRoute={{ text: 'Payment History' }}>
        <Card>
          <PaymentHistoryWidget hideBackButton />
        </Card>
      </ViewContainer>
    </Feature>
  );
};

export default PaymentHistory;
