import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { currencyStringFormatter } from '../../helpers';

const AOBox = ({ currency, text, ...otherProps }) => {
  return <Box {...otherProps}>{`${(currency ? currencyStringFormatter(text) : text) ?? ''}`}</Box>;
};

AOBox.propTypes = {
  currency: PropTypes.bool,
  text: PropTypes.string,
};

export default AOBox;
