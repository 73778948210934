import { React, useState } from 'react';
import _ from 'lodash';

import { handleFileDownload } from '../CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';

import { ScreenSmith } from '../ScreenSmith';
import BillingServicesRequestsSkeleton from './BillingServicesRequestsSkeleton';

import useScreenDefinition from '../../hooks/useScreenDefinition';

const BillingServicesRequests = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`BillingServicesRequestsPanel`);

  const [loadingFile, setLoadingFile] = useState(false);
  const [downloadingFileError, setDownloadingFileError] = useState(false);
  const [isDownloadingFileErrorVisible, setIsDownloadingFileErrorVisible] = useState(false);

  const [loadingPdf, setLoadingPdf] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);

  const resetAlerts = () => {
    setIsDownloadingPdfErrorVisible(false);
    setIsDownloadingFileErrorVisible(false);
  };

  const handleClickDownloadFile = (event) => {
    resetAlerts();
    const _fileUrl = event.currentTarget.getAttribute('data-file');
    handleFileDownload(
      _fileUrl,
      setLoadingFile,
      setDownloadingFileError,
      setIsDownloadingFileErrorVisible,
    );
  };
  
  const handleClickDownloadPdf = (event) => {
    resetAlerts();
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');
    handlePdfDownload(
      'GET',
      null,
      pdfUrl,
      setLoadingPdf,
      setDownloadingPdfError,
      setIsDownloadingPdfErrorVisible,
      'newWindow',
    );
  };

  const localStateMap = {
    loadingFile,
    downloadingFileError,
    isDownloadingFileErrorVisible,
    loadingPdf,
    downloadingPdfError,
    isDownloadingPdfErrorVisible,
  };

  const callBackFunctions = {
    handleClickDownloadFile,
    handleClickDownloadPdf,
  };

  if (loadingSd) return <BillingServicesRequestsSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default BillingServicesRequests;
