import React from 'react';
import { ViewContainer, Card } from 'components/AORedesign';

import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';
import { CLSRenewals as CLSRenewals_View } from 'components/CLSRenewals';

const CLSRenewals = () => {
  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
      <Card>
        <CLSRenewals_View title={'Upcoming Renewal Reports'} hideReturn />
      </Card>
    </ViewContainer>
  );
};

export default CLSRenewals;
