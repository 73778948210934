import { makeStyles } from '@material-ui/core';
import MuiSvgIcon from '@material-ui/core/SvgIcon';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Icon } from 'components/AORedesign';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    display: 'flex',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    borderRadius: '50%',
    padding: '16px',
  },
}));

const ClientPolicyIcon = ({ policyTypeCode }) => {
  const classes = useStyles();

  const getIcon = () => {
    const renderIcon = (iconName) => (
      <Box className={classes.iconContainer}>
        <Icon iconName={iconName} />
      </Box>
    );

    switch (policyTypeCode) {
      case 10:
      case 11:
      case 12:
        return renderIcon('icons-icon-auto');
      case 13:
      case 14:
      case 15:
        return renderIcon('icons-icon-commercial-auto');
      case 20:
      case 21:
      case 22:
      case 23:
      case 24:
      case 26:
      case 27:
      case 28:
      case 29:
      case 30:
      case 34:
        return renderIcon('icons-icon-identity-protection');
      case 31:
      case 32:
      case 36:
      case 37:
      case 38:
      case 43:
      case 45:
      case 47:
      case 48:
      case 50:
      case 61:
      case 62:
      case 63:
      case 66:
      case 67:
        return (
          <Box className={classes.iconContainer}>
            <MuiSvgIcon>
              <GroupWorkOutlinedIcon />
            </MuiSvgIcon>
          </Box>
        );
      case 25:
      case 41:
      case 44:
      case 46:
        return renderIcon('icons-icon-agri-business');
      case 35:
      case 49:
        return renderIcon('icons-icon-business-owner');
      case 40:
      case 42:
        return renderIcon('icons-icon-home');
      case 60:
        return renderIcon('icons-icon-workers-comp');
      case 64:
      case 65:
        return renderIcon('icons-icon-bonds');
      case 68:
      case 69:
        return renderIcon('icons-icon-umbrella');
      default:
        return renderIcon('');
    }
  };

  return getIcon();
};

ClientPolicyIcon.propTypes = {
  policyTypeCode: PropTypes.number,
};

export default ClientPolicyIcon;
