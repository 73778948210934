import React, { useState } from 'react';
import cuid from 'cuid';
import PropTypes from 'prop-types';

import { AODataGrid } from '../../../../AODataGrid';
import { ManageCurrentPaymentModal } from '../ManageCurrentPaymentModal';

import { detailsType } from '../ManageCurrentPayment.types';

import { getManageCurrentPaymentByDetailId } from './ManageCurrentPaymentDataGrid.api';

import {
  getManageCurrentPaymentsGridRows,
} from '../ManageCurrentPayment.api';

const ManageCurrentPaymentDataGrid = ({ rows, columns, setManageCurrPaymentGridRows }) => {
  const [modalData, setModalData] = useState({
    isOpen: false,
    loading: false,
    details: {},
    alert: {
      active: false,
      severity: null,
      msg: null,
    },
  });

  const handleOnRowClick = async ({ row: { index } }) => {
    setModalData((prevValue) => ({ ...prevValue, loading: true, isOpen: true }));
    // Get index from _params
    console.log('row id for current payment api: ', index);

    try {
      const payload = await getManageCurrentPaymentByDetailId(index);
      setModalData((prevValue) => ({
        ...prevValue,
        loading: false,
        details: { ...payload },
      }));
    } catch (error) {
      console.log(error);
      setModalData((prevValue) => ({
        ...prevValue,
        loading: false,
        alert: { active: true, severity: 'error', msg: `Error: ${error}` },
      }));
    }
  };

  /* Click Event to close modal */
  const handleClickCloseModal = async () => {
    setModalData((prevValue) => ({ ...prevValue, isOpen: false }));
    const currPayments = await getManageCurrentPaymentsGridRows();
    setManageCurrPaymentGridRows(currPayments.map((el) => ({
      ...el, id: cuid()
    })));
  }


  return (
    <>
      <AODataGrid
        onRowClick={(params) => handleOnRowClick(params)}
        rows={rows}
        columns={columns}
        autoHeight
        rowsPerPageOptions={[25, 50, 75, 100]}
        noResultsMessage="No Results"
      />
      <ManageCurrentPaymentModal
        open={modalData.isOpen}
        alertIsActive={modalData.alert.active}
        alertSeverity={modalData.alert.severity}
        alertMsg={modalData.alert.msg}
        loading={modalData.loading}
        handleClose={handleClickCloseModal}
        details={modalData.details}
      />
    </>
  );
};
ManageCurrentPaymentDataGrid.propTypes = {
  columns: PropTypes.arrayOf(detailsType),
  rows: PropTypes.arrayOf(detailsType),
  setManageCurrPaymentGridRows: PropTypes.func
};

export default ManageCurrentPaymentDataGrid;
