import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio } from '@material-ui/core';

const AORadio = ({ value, label, ...props }) => {
  return <FormControlLabel value={value} control={<Radio />} label={label} {...props} />;
};

AORadio.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};

export default AORadio;
