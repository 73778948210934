import { React, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Alert, Box, Button, Card, CardContent, CardHeader, Col, DataGrid, DatePicker, Row, Typography, ViewContainer } from 'components/AORedesign';
import TotalsTable from './TotalsTable';

import { handleDateOnChange, handleOnClickGenerateReport, detailsColumns } from './PaymentBalance.api';

const PaymentBalance = ({ title }) => {
  const rawFromDate = moment().subtract(3, 'days');
  const [formData, setFormData] = useState({
    fromDate: moment(rawFromDate).format('MM/DD/yyyy'),
    toDate: moment().format('MM/DD/yyyy'),
  });
  const [reportContentData, setReportContentData] = useState({
    reportDetails: null,
    reportTotals: [],
    isVisible: false,
    loading: false,
    alert: {
      message: null,
      severity: 'info',
      isVisible: false,
    },
  });

  const totalsFromDate = formData.fromDate;
  const totalsToDate = formData.toDate;
  const totalsData = reportContentData.reportTotals;

  const handleOnChangeFromDate = (date) => handleDateOnChange(date, 'fromDate', formData, setFormData);
  const handleOnChangeToDate = (date) => handleDateOnChange(date, 'toDate', formData, setFormData);
  const handleOnClickGenerateReportHere = () => handleOnClickGenerateReport(formData, setReportContentData);

  return (
    <ViewContainer returnRoute={{ text: title ?? 'Reports' }}>
      <Card id="ReportsPaymentBalance">
        <CardHeader title="Payment Balance Report" />
        <CardContent>
          <Box mb={2} style={{ width: '100%' }}>
            <Row mb={2}>
              <Row mb={2}>
                <Box mb={2}>
                  <Row mb={2}>
                    <Typography>Select a Date Range for Balancing Report</Typography>
                  </Row>
                </Box>
                <Row>
                  <Box mb={2}>
                    <Row spacing={2}>
                      <Col>
                        <DatePicker
                          onChange={handleOnChangeFromDate}
                          value={formData.fromDate}
                          label="Date From"
                          name="fromDate"
                          // minDateMessage="Must be less than or equal to 31 days."
                        />
                      </Col>
                      <Col>
                        <DatePicker
                          onChange={handleOnChangeToDate}
                          value={formData.toDate}
                          label="Date To"
                          name="toDate"
                          // maxDateMessage="Can not be a future date."
                        />
                      </Col>
                    </Row>
                  </Box>
                </Row>
                <Box>
                  <Row>
                    <Button onClick={handleOnClickGenerateReportHere} loading={reportContentData.loading} variant="contained" color="primary">
                      Get Report
                    </Button>
                  </Row>
                </Box>
              </Row>
            </Row>
          </Box>
          <Row>
            {reportContentData.alert?.isVisible && (
              <Alert severity={reportContentData.alert.severity} alertTitleMessage={reportContentData.alert.message}></Alert>
            )}
            {reportContentData.isVisible && reportContentData.reportDetails && (
              <Row>
                <Box mb={2} style={{ width: '100%' }}>
                  <Row>
                    <TotalsTable fromDate={totalsFromDate} toDate={totalsToDate} rows={totalsData} elevaton={0} />
                  </Row>
                </Box>
                <Box mb={2} style={{ width: '100%' }}>
                  <Row>
                    <DataGrid
                      columns={detailsColumns}
                      rows={reportContentData.reportDetails}
                      componentsProps="{'row': {'styles': {'border': '2px solid red'}}}"
                      pageSize={10}
                      exportToCSV={true}
                    />
                  </Row>
                </Box>
              </Row>
            )}
          </Row>
        </CardContent>
      </Card>
    </ViewContainer>
  );
};

PaymentBalance.propTypes = {
  title: PropTypes.string,
};

export default PaymentBalance;
