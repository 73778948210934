import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Paper, Checkbox } from '@material-ui/core';
import axios from 'axios';
import Row from '../../../ScreenSmith/Row';
import Col from '../../../ScreenSmith/Col';
import AOSelect from '../../../AOSelect/AOSelect';
import { AOButton } from '../../../AOButton';
import { Visible } from '../../../Visible';
import {
  USER_ADMIN_CHALLENGE_QUESTIONS,
  USER_ADMIN_SET_NEW_PASSWORD,
  USER_ADMIN_UPDATE_USER,
  USER_ADMIN_GET_USER_INFO,
} from '../../../../constants/api';
import AOAutocomplete from '../../../AOAutocomplete/AOAutocomplete';
import { AOAlert } from '../../../AOAlert';
//import { isNaN } from 'lodash';
import {
  validation,
  initialResponseAlert,
  challengeQuestionsMenuItems,
  validateFirstName,
  validateLastName,
  validateEmail,
  validateAgencyId,
} from '../../UserAdmin.api';

const EditUserInfoPanel = ({ userDetails, setUserDetails }) => {
  const [challengeQuestions, setChallengeQuestions] = useState([]);
  const [formData, setFormData] = useState({});
  const [firstNameError, setFirstNameError] = useState([]);
  const [lastNameError, setLastNameError] = useState([]);
  const [emailError, setEmailError] = useState([]);
  const [agencyIdError, setAgencyIdError] = useState([]);
  const [isLogOnDisabled, setLogOnDisabled] = useState(null);
  const [challengeResponseError, setChallengeResponseError] = useState([]);
  const [responseAlert, setResponseAlert] = useState(initialResponseAlert);

  useEffect(async () => {
    try {
      const getChallengeQuestionsUrl = `${USER_ADMIN_CHALLENGE_QUESTIONS}`;
      const challengeQuestionsList = await axios.get(getChallengeQuestionsUrl);
      setChallengeQuestions(challengeQuestionsList.data);
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    setFormData(userDetails);
  }, [userDetails]);
  useEffect(() => {
    setLogOnDisabled(formData?.accountDisabled);
  }, [formData?.accountDisabled]);

  const validateChallengeResponse = (e) => {
    const value = e?.target.value;
    const pattern = new RegExp(validation.ininetChAns.pattern);
    if (!e) {
      setChallengeResponseError(null);
      return false;
    }
    if (!value) {
      setChallengeResponseError(validation.ininetChAns.required);
    } else if (!pattern.test(value)) {
      setChallengeResponseError(validation.ininetChAns.validationMessage);
    } else {
      setChallengeResponseError(null);
    }
  };

  const handleResetPasswordOnClick = async () => {
    try {
      const resetPasswordResponse = await axios.post(USER_ADMIN_SET_NEW_PASSWORD, formData);
      setResponseAlert({
        ...responseAlert,
        isVisible: true,
        message: `The password for account ${formData?.cn} has been changed. Password: ${resetPasswordResponse?.data.password}`,
        severity: 'success',
      });
    } catch (error) {
      console.error(error);
      setResponseAlert({
        ...responseAlert,
        isVisible: true,
        message: `password reset failed ${error}`,
        severity: 'success',
      });
    }
  };

  const handleResetFormOnClick = () => {
    setFormData(userDetails);
    setResponseAlert(initialResponseAlert);
  };

  const handleSubmitOnClick = async () => {
    try {
      const response = await axios.put(USER_ADMIN_UPDATE_USER, formData);
      console.log('Response: ', response.data);
      if (response.data == 'Success') {
        setResponseAlert({
          ...responseAlert,
          isVisible: true,
          message: `${formData?.cn} updated. Please wait at least one hour before making further updates.`,
          severity: 'success',
        });
        const userName = userDetails?.name;
        const getUserDetailsUrl = `${USER_ADMIN_GET_USER_INFO}/${userName}`;
        const details = await axios.get(getUserDetailsUrl);
        setUserDetails(details.data);
      } else if (response.data == 'DuplicateEmail') {
        setResponseAlert({
          ...responseAlert,
          isVisible: true,
          message: `Unable to proceed, email already exists. If you already have a Username, please try resetting your password from Agent’s Only login page. For additional assistance, please contact your Agency Administrator or Customer Service Department at (877) 9ASKMSA (877-927-5672) or customer@msagroup.com.`,
          severity: 'error',
        });
      }
    } catch (error) {
      console.error(error);
      setResponseAlert({
        ...responseAlert,
        isVisible: true,
        message: `Unable to update user ${formData?.cn}`,
        severity: 'error',
      });
    }
  };
  return (
    <>
      <Row spacing={3}>
        <Col xs={6}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <Box p={3}>
              <Box>
                <Visible isVisible={responseAlert.isVisible}>
                  <AOAlert severity={responseAlert.severity}>{responseAlert.message}</AOAlert>
                </Visible>
              </Box>
              <Box p={1} />
              <Box p={1}>
                <AOAutocomplete
                  id={'first-name'}
                  name={'givenName'}
                  value={formData?.givenName || ''}
                  onChange={(e) => {
                    setFormData({ ...formData, givenName: e.target.value });
                  }}
                  onInputChange={(e) => {
                    validateFirstName(e, setFirstNameError, setFormData);
                  }}
                  label={'First Name'}
                  placeholder={'Enter First Name'}
                  required
                  fullWidth
                  invalidSearch={firstNameError}
                />
              </Box>
              <Box p={1}>
                <AOAutocomplete
                  id={'last-name'}
                  name={'sn'}
                  value={formData?.sn || ''}
                  onChange={(e) => {
                    setFormData({ ...formData, sn: e.target.value });
                  }}
                  onInputChange={(e) => {
                    validateLastName(e, setLastNameError, setFormData);
                  }}
                  label={'Last Name'}
                  placeholder={'Enter Last Name'}
                  required
                  fullWidth
                  invalidSearch={lastNameError}
                />
              </Box>
              <Box p={1}>
                <AOAutocomplete
                  id={'email'}
                  name={'mail'}
                  value={formData?.mail || ''}
                  onChange={(e) => {
                    setFormData({ ...formData, mail: e.target.value });
                  }}
                  onInputChange={(e) => {
                    validateEmail(e, setEmailError, setFormData);
                  }}
                  label={'Email'}
                  placeholder={'Enter Email'}
                  required
                  fullWidth
                  invalidSearch={emailError}
                />
              </Box>
              <Box p={1}>
                <AOAutocomplete
                  id={'amic-agency-number'}
                  name={'artAgentID'}
                  value={formData?.artAgentID || ''}
                  onChange={(e) => {
                    setFormData({ ...formData, artAgentID: e.target.value });
                  }}
                  onInputChange={(e) => {
                    validateAgencyId(e, setAgencyIdError, setFormData);
                  }}
                  label={'AMIC Agency Number'}
                  placeholder={'Enter AMIC Agency Number'}
                  fullWidth
                  invalidSearch={agencyIdError}
                />
              </Box>
              <Box p={1}>
                <AOAutocomplete
                  id={'mod-id'}
                  name={'ininetmodID'}
                  value={formData?.ininetmodID || ''}
                  label={'MOD ID'}
                  placeholder={'Enter MOD ID'}
                  required
                  fullWidth
                  disabled
                />
              </Box>
            </Box>
          </Paper>
        </Col>
        <Col xs={6}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <Box p={3}>
              <Box p={2}></Box>
              <Box p={1}>
                <AOSelect
                  inputLabel={'Challenge Question*'}
                  label="Challenge Question"
                  labelId="challenge-question"
                  id={'challenge-question'}
                  name={'ininetChQues'}
                  displayLabel={formData?.ininetChQues}
                  value={`${challengeQuestions.indexOf(formData?.ininetChQuest)}`}
                  variant="outlined"
                  menuItems={challengeQuestionsMenuItems(challengeQuestions)}>
                  {formData?.ininetChQues}
                </AOSelect>
              </Box>
              <Box p={1}>
                <AOAutocomplete
                  id={'challenge-question-response'}
                  name={'ininetChAns'}
                  value={formData?.ininetChAns || ''}
                  onChange={(e) => {
                    setFormData({ ...formData, ininetChAns: e.target.value });
                  }}
                  onInputChange={(e) => {
                    validateChallengeResponse(e);
                  }}
                  label={'Challenge Response'}
                  placeholder={'Enter Challenge Response'}
                  required
                  fullWidth
                  invalidSearch={challengeResponseError}
                />
              </Box>
            </Box>
          </Paper>
        </Col>
        <Col xs={6}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <Box p={3}>
              <Box p={1}>
                <Typography align="justify" color="textSecondary" display="inline">
                  User Name:{' '}
                </Typography>
                <Typography align="justify" color="primary" display="inline" variant="subtitle">
                  {userDetails?.name}
                </Typography>
              </Box>
              <Box p={1}>
                <Typography align="justify" color="textSecondary" display="inline">
                  Pass. Exp. Date:{' '}
                </Typography>
                <Typography align="justify" color="primary" display="inline" variant="subtitle">
                  {userDetails?.pwdexpdate}
                </Typography>
              </Box>
              <Box p={1}>
                <Typography align="justify" color="textSecondary" display="inline">
                  Locked Out?{' '}
                </Typography>
                <Typography align="justify" color="inherit" display="inline" variant="subtitle">
                  {userDetails?.accountLockedOut ? 'Yes' : 'No'}
                </Typography>
              </Box>
              <Box p={1}>
                <Typography align="justify" color="textSecondary" display="inline">
                  Logon Disabled:{' '}
                </Typography>
                <Checkbox
                  align="justify"
                  name="accountDisabled"
                  checked={isLogOnDisabled}
                  color="primary"
                  display="inline"
                  onChange={(e) => {
                    setFormData({ ...formData, [e.target.name]: e.target.checked });
                  }}
                />
              </Box>
            </Box>
            <Box p={3} align="center">
              <AOButton
                onClick={handleResetPasswordOnClick}
                variant={'contained'}
                color={'primary'}
                id={'reset-password-button'}>
                Reset Password
              </AOButton>
            </Box>
          </Paper>
        </Col>
        <Col xs={6}>
          <Paper
            variant="outlined"
            square
            style={{ boxSizing: 'border-box', height: '100%' }}></Paper>
        </Col>
        <Col align="right">
          <Box display={'inline'}>
            <AOButton
              onClick={handleSubmitOnClick}
              variant={'contained'}
              color={'primary'}
              id={'submit-button'}
              display="inline"
              disabled={
                firstNameError ||
                lastNameError ||
                emailError ||
                agencyIdError ||
                challengeResponseError
              }>
              Submit
            </AOButton>
            <Box p={1} display="inline" />
            <AOButton
              onClick={handleResetFormOnClick}
              variant={'outlined'}
              color={'secondary'}
              id={'reset-button'}
              display="inline">
              Reset Form
            </AOButton>
          </Box>
        </Col>
      </Row>
    </>
  );
};

EditUserInfoPanel.propTypes = {
  userDetails: PropTypes.object,
  setUserDetails: PropTypes.func,
};

export default EditUserInfoPanel;
