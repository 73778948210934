import { Box, styled, Card } from '@material-ui/core';
import { rem } from 'polished';

const StyledHomePage = styled(Box)(() => ({
  padding: `0`,
}));

const StyledCard = styled(Card)({
  minHeight: `${rem(160)}`,
});
export { StyledHomePage, StyledCard };
