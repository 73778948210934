import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import { currencyStringFormatter } from '../../../../helpers';

const PostPaymentValidation = ({ tableRows, paymentTotal }) => {
  const paymentCount = tableRows.length;

  return (
    <Box>
      <Typography
        paragraph>{`You have entered ${paymentCount} payment(s) for an amount of ${currencyStringFormatter(
        paymentTotal,
      )}. This total amount will be swept from your agency account.`}</Typography>
      <Typography
        paragraph>{`Please review the payment information below. If everything is correct, use the Submit button to finalize your transaction. Otherwise, you may cancel the transaction using the appropriate button.`}</Typography>
    </Box>
  );
};

PostPaymentValidation.propTypes = {
  tableRows: PropTypes.node,
  paymentTotal: PropTypes.number,
};

export default PostPaymentValidation;
