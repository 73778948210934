import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { GET_DBC_URL, GET_PERM_AGENCIES } from '../../constants/api';

import { ScreenSmith } from '../ScreenSmith';
import DbcReportSkeleton from './DbcReportSkeleton';

import {
  handleCreateDbcReport,
  handleOnChangeAgencyCode,
  handleAgencyNameOnClick,
  handleOnChangeMonthYear,
  handleOnChangeAgencyNameDD,
  handleOnBlurAgencyCode,
} from './DbcReport.api';

import useScreenDefinition from '../../hooks/useScreenDefinition';

const DbcReportExternal = () => {
  const [agencyNameDDOptions, setAgencyNameDDOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);
  const [formData, setFormData] = useState({
    agencyName: '',
    agencyCode: '',
    year: '',
  });

  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`DbcReportExternal`);

  useEffect(async () => {
    const dateData = await axios.get(`${GET_DBC_URL}/dates`);
    let { data: dates } = dateData;
    dates = dates.map((date) => {
      return { ...date, code: date.dateValue, value: date.dateDisplay.replace('-', ' ') };
    });
    setYearOptions(dates);
  }, []);

  useEffect(async () => {
    const permAgenceisPayload = await axios.get(`${GET_PERM_AGENCIES}`);
    const { data: agencyDataRaw } = permAgenceisPayload;
    const agencyData = agencyDataRaw.map((agency) => {
      return { ...agency, code: agency.agencyCode, value: agency.agencyName };
    });
    setAgencyNameDDOptions(agencyData);
  }, []);

  const localStateMap = {
    agencyName: formData.agencyName,
    agencyNameDDOptions,
    agencyCode: formData.agencyCode,
    yearOptions,
    year: formData.year,
    loadingPdf,
    isDownloadingPdfErrorVisible,
    downloadingPdfError,
  };

  const callBackFunctions = {
    handleOnBlurAgencyCode: (e) => handleOnBlurAgencyCode(e, setFormData),
    handleOnChangeAgencyCode: (e) => handleOnChangeAgencyCode(e, setFormData),
    handleAgencyNameOnClick: () => handleAgencyNameOnClick(formData),
    handleOnChangeAgencyNameDD: (e) => handleOnChangeAgencyNameDD(e, setFormData),
    handleOnChangeMonthYear: (e) => handleOnChangeMonthYear(e, setFormData),
    handleCreateDbcReport: () =>
      handleCreateDbcReport(
        setLoadingPdf,
        setIsDownloadingPdfErrorVisible,
        setDownloadingPdfError,
        formData,
        "pdf",
      ),
      handleCreateDbcReportExcel: () =>
      handleCreateDbcReport(
        setLoadingPdf,
        setIsDownloadingPdfErrorVisible,
        setDownloadingPdfError,
        formData,
        "excel"
      ),
  };
  
  if (loadingSd) return <DbcReportSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default DbcReportExternal;
