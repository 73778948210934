import axios from 'axios';
import _ from 'lodash';

import PLSPolicyDocumentsRenderCell from './PLSPolicyDocumentsRenderCell';
import {
  GET_PL_DOCUMENTS_GET_DOCUMENTS,
  GET_PL_AUTO_COMPLETION_POLICY_ONE_SHIELD,
} from '../../../constants/api';

const getColData = (_setErrorRetrievedDocuments, _setErrorDownloadingPdf) => {
  const plPolicyDocumentsColData = [
    {
      field: 'documentType',
      headerName: 'Document Type',
      width: `25%`,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: true,
      flex: 2,
    },
    {
      field: 'documentDate',
      headerName: 'Date',
      type: 'date',
      sortable: true,
      flex: 1,
    },
    {
      field: 'documentLink',
      headerName: 'Document Link',
      sortable: true,
      flex: 1,
      renderCell: (params) => PLSPolicyDocumentsRenderCell(params, _setErrorDownloadingPdf),
    },
  ];

  return plPolicyDocumentsColData;
};
const plPolicyDocumentsColData = [
  {
    field: 'documentType',
    headerName: 'Document Type',
    width: `25%`,
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: true,
    flex: 2,
  },
  {
    field: 'documentDate',
    headerName: 'Date',
    type: 'date',
    sortable: true,
    flex: 1,
  },
  {
    field: 'documentLink',
    headerName: 'Document Link',
    sortable: true,
    flex: 1,
    renderCell: PLSPolicyDocumentsRenderCell,
  },
];

// --- Event Handlers for PL Policy Documents ---
const handleChangePlPolicyDocumentNumber = (event, value, _setPlPolicyDocumentNumber) => {
  _setPlPolicyDocumentNumber(value);
};

const handleInputChangePlPolicyDocumentNumber = (
  event,
  value,
  _setPlPolicyDocumentNumberInputValue,
  _setDebouncedState,
) => {
  const sanitizedValue = value.toUpperCase().trim();
  _setPlPolicyDocumentNumberInputValue(sanitizedValue);
  _setDebouncedState(value);
};

const fetchSuggestions = async (searchText) => {
  const plPolicyDocumentEndpointUrl = `${GET_PL_AUTO_COMPLETION_POLICY_ONE_SHIELD}${searchText}`;
  const response = await axios.get(plPolicyDocumentEndpointUrl);
  const suggestions = response.data;
  return suggestions;
};

const handleOnBlur = (
  plPolicyDocumentNumber,
  plPolicyDocumentNumberInputValue,
  setPlPolicyDocumentNumber,
) => {
  if (plPolicyDocumentNumber !== plPolicyDocumentNumberInputValue) {
    setPlPolicyDocumentNumber(plPolicyDocumentNumberInputValue);
  }
};

const handleOnClickRetrievePolicyDocuments = (
  setLoadingRetrievedDocuments,
  setNoRetrievedDocuments,
  setRetrievedDocuments,
  plPolicyDocumentNumber,
  setDataGridIsVisible,
  setDocSearchCustomerInfo,
  setErrorRetrievedDocuments,
  setErrorDownloadingPdf,
) => {
  setErrorDownloadingPdf(false);
  setLoadingRetrievedDocuments(true);
  setNoRetrievedDocuments(false);
  setRetrievedDocuments(null);
  axios({ url: `${GET_PL_DOCUMENTS_GET_DOCUMENTS}/${plPolicyDocumentNumber}` })
    .then((response) => {
      const responseData = response.data.data;
      // check to see if response is empty,
      // if there is data, toggle setSearch value
      // to hide the "search" section
      if (_.isEmpty(responseData)) {
        setNoRetrievedDocuments(true);
        setDataGridIsVisible(false);
      } else {
        setDataGridIsVisible(true);
        setDocSearchCustomerInfo(response.data.customerInformation);
        for (let data of responseData) {
          data['policyNumber'] = response.data.customerInformation.policyNumber;
        }
        setRetrievedDocuments(responseData);
      }
      setLoadingRetrievedDocuments(false);
    })
    .catch((error) => {
      console.error(error);
      setDataGridIsVisible(true);
      setErrorRetrievedDocuments(error);
      setLoadingRetrievedDocuments(false);
    });
};

export {
  getColData,
  plPolicyDocumentsColData,
  handleChangePlPolicyDocumentNumber,
  handleInputChangePlPolicyDocumentNumber,
  fetchSuggestions,
  handleOnBlur,
  handleOnClickRetrievePolicyDocuments,
};
