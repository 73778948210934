import React from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const PolicyLossRunsReportSkeleton = ({ ...otherProps }) => {
  return (
    <Box {...otherProps}>
      <Box>
        <Skeleton
          width={175}
          style={{ marginRight: '24px', padding: '6px 8px' }}
          animation="wave"
        />
      </Box>
      <Box mb={2}>
        <Skeleton
          width={250}
          style={{ marginRight: '24px', padding: '6px 8px' }}
          animation="wave"
        />
      </Box>
      <Box>
        <Skeleton
          width={195}
          style={{ marginRight: '24px', padding: '6px 8px' }}
          animation="wave"
        />
      </Box>
      <Box>
        <Skeleton
          width={235}
          style={{ marginRight: '24px', padding: '6px 8px' }}
          animation="wave"
        />
      </Box>
      <Box>
        <Skeleton
          width={175}
          style={{ marginRight: '24px', padding: '6px 8px' }}
          animation="wave"
        />
      </Box>
    </Box>
  );
};

export default PolicyLossRunsReportSkeleton;
