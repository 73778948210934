import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';

import { AOCircularProgress } from '../AOCircularProgress';
import { fetchMidvaleCodes } from './FindProductToQuote.api';
import { AOSelect } from '../AOSelect';
import { AOButton } from '../AOButton';

const AppetiteAgentValidationModalExternal = ({
  agentCodes,
  selectedCode,
  setSelectedCode,
  handleSetAgencyCode,
  handleCloseOnClick,
  state,
  open,
  setAgentCodes,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    try {
      const results = await fetchMidvaleCodes(state);
      setAgentCodes(results);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  const renderContent = () => {
    if (agentCodes.length > 1) {
      return (
        <>
          <Typography variant="h6">Select your agency code</Typography>
          <Box mb={2} />
          <Typography color="textSecondary">Select one of your agency codes below to continue with this quote.</Typography>
          <Box mb={2} />
          <Typography color="textSecondary">{`To continue with this quote, click the "Start Quote" button.`}</Typography>
          <Box mb={2} />
          <AOSelect
            id="appetite-validate-agency-codes"
            labelId="appetite-validate-agency-codes-label"
            inputLabel="Agency Code"
            name="locationCode"
            menuItems={agentCodes.map((code) => ({ value: code, code }))}
            onChange={(e) => setSelectedCode(e.target.value)}
            value={selectedCode}
          />
        </>
      );
    } else if (agentCodes.length === 1) {
      handleSetAgencyCode(agentCodes[0]);
      return null;
    } else {
      return (
        <>
          <Typography variant="h6">No Midvale license found</Typography>
          <Box mb={2} />
          <Typography color="textSecondary">
            Our records cannot find a Midvale license associated to your account based on your State and Product selection. Select a different product to
            continue with this quote
          </Typography>
        </>
      );
    }
  };

  return loading ? (
    <AOCircularProgress visible={loading}>Fetching agent codes...</AOCircularProgress>
  ) : (
    <>
      <Modal
        open={open}
        onClose={handleCloseOnClick}
        aria-labelledby="appetite-agent-code-validation-modal-title"
        aria-describedby="appetite-agent-code-validation-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper elevation={3}>
          <Box p={3} maxWidth={500}>
            {renderContent()}
            <Box mt={2} display="flex" justifyContent={'flex-end'}>
              <AOButton color="secondary" onClick={handleCloseOnClick}>
                Close
              </AOButton>
              <Box mr={1} />
              {agentCodes.length > 1 && (
                <AOButton id="appetite-use-this-code" color="secondary" onClick={() => handleSetAgencyCode(selectedCode)} disabled={!selectedCode}>
                  Use This Code
                </AOButton>
              )}
            </Box>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

AppetiteAgentValidationModalExternal.propTypes = {
  agentCodes: PropTypes.array,
  selectedCode: PropTypes.string,
  state: PropTypes.string,
  open: PropTypes.bool,
  setSelectedCode: PropTypes.func,
  handleSetAgencyCode: PropTypes.func,
  handleCloseOnClick: PropTypes.func,
  setAgentCodes: PropTypes.func,
};

export default AppetiteAgentValidationModalExternal;
