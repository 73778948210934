import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { AOTypography } from '../../../AOTypography';

import { currencyStringFormatter } from '../../../../helpers';

const PaymentConfirmation = ({ paymentValidationData }) => {
  const { originalAmount, adjustedAmount, accountNumber, accountName } = paymentValidationData;
  const formattedAdjustedAmount = currencyStringFormatter(adjustedAmount);
  const formattedOriginalAmount = currencyStringFormatter(originalAmount);
  return (
    <Box>
      <Box mb={2} display="flex" flexWrap="wrap">
        <Typography>{`This acknowledges that you have entered a payment of`}</Typography>
        <AOTypography bold text={formattedAdjustedAmount} inline />
      </Box>
      <Box mb={2} display="flex" flexWrap="wrap">
        <Typography>{`We will bill the insured's account`}</Typography>
        <AOTypography bold text={accountNumber} inline />
        <Typography>{`and credit your agency account for the difference. `}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{`Below is a summary of the payments made. Click on the confirmation number to produce a printable receipt for that payment.`}</Typography>
      </Box>
      <Box mb={2} display="flex" flexWrap="wrap">
        <Typography>{`Today's Date:`}</Typography>
        <AOTypography bold text={moment().format('MMMM Do YYYY')} inline />
      </Box>

      <Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Account Number:</Typography>
          </Box>
          <Typography color="textSecondary">{accountNumber}</Typography>
        </Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Account Name: </Typography>
          </Box>
          <Typography color="textSecondary">{accountName}</Typography>
        </Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Old Payment Amount: </Typography>
          </Box>
          <Typography color="textSecondary">{formattedOriginalAmount}</Typography>
        </Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>New Payment Amount: </Typography>
          </Box>
          <Typography color="textSecondary">{formattedAdjustedAmount}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

PaymentConfirmation.propTypes = {
  paymentValidationData: PropTypes.shape({
    changeDate: PropTypes.string,
    originalAmount: PropTypes.string,
    adjustedAmount: PropTypes.string,
    accountNumber: PropTypes.string,
    accountName: PropTypes.string,
  }),
};

export default PaymentConfirmation;
