import React, { useState, useEffect } from 'react';
import axios from 'axios';

import AnnualMileageValidationReportAgentView from './AnnualMileageValidationReportAgentView';
import AnnualMileageValidationReportInternalUserView from './AnnualMileageValidationReportInternalUserView';

import { handleCreateOnClick } from './AnnualMileageValidationReport.api';
import { getPastYearMonthsMenuItems } from 'helpers/dateHelpers';
import {
  handleOnChange,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
} from 'components/CommonUtilityServices/EventHandlerService';
import { GET_REPORTS_URL } from 'constants/api';
import { useDebounce } from 'hooks/useDebounce';
import { Box, Button, Card, CardContent, CardHeader, Select, Typography, ViewContainer } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';

const AnnualMileageValidationReport = () => {
  // Agent view
  const [agencyCodeOptions, setAgencyCodeOptions] = useState([]);
  // Internal user view
  const [agencyNameOptions, setAgencyNameOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingPdf, setLoadingPdf] = useState(false);

  const [formData, setFormData] = useState({
    AgtNum: '',
    AsOfDate: '',
  });

  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    AgtNum: { value: '' },
  });
  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);

  useEffect(async () => {
    if (autocompleteInputValues.AgtNum.value.length >= 3) {
      axios
        .get(`${GET_REPORTS_URL}/agencies/agency-suggestions?searchValue=${autocompleteInputValues.AgtNum.value}`)
        .then((results) => setAgencyNameOptions(results.data))
        .catch((error) => console.error(error));
    } else {
      setAgencyNameOptions([]);
    }
  }, [debouncedState.AgtNum]);

  const handleOnChange_PROXY = (e) => handleOnChange(e, formData, setFormData);
  const handleAgencyNameOnBlur = () => handleAOAutocompleteOnBlur('AgtNum', formData, setFormData, autocompleteInputValues);
  const handleAgencyNameOnChange = (e, value) => handleAOAutocompleteOnChange('AgtNum', e, value, formData, setFormData);
  const handleAgencyNameOnInputChange = (e, value) =>
    handleAOAutocompleteOnInputChange('AgtNum', e, value, autocompleteInputValues, setAutocompleteInputValues, debouncedState, setDebouncedState);
  const handleCreateOnClick_PROXY = () => handleCreateOnClick(formData, setErrorMessage, setLoadingPdf);

  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
      <Feature allowed="Access_AnnualMileage_Report">
        <Card>
          <CardHeader title="Annual Mileage Validation Report" />
          <CardContent>
            {errorMessage && (
              <Box mb={2}>
                <Typography color="error">{errorMessage}</Typography>
              </Box>
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCreateOnClick_PROXY();
              }}>
              <Box mb={2}>
                <Select
                  onChange={handleOnChange_PROXY}
                  value={formData.AsOfDate}
                  menuItems={getPastYearMonthsMenuItems(false)}
                  id="amvr-policy-effective-date"
                  labelId="amvr-policy-effective-date-label"
                  label="Policy Effective Period"
                  name="AsOfDate"
                />
              </Box>
              <Feature allowed="internalUser">
                <AnnualMileageValidationReportInternalUserView
                  handleAgencyNameOnBlur={handleAgencyNameOnBlur}
                  handleAgencyNameOnChange={handleAgencyNameOnChange}
                  handleAgencyNameOnInputChange={handleAgencyNameOnInputChange}
                  setAgencyNameOptions={setAgencyNameOptions}
                  agencyName={formData.AgtNum}
                  agencyNameInputValue={autocompleteInputValues.AgtNum.value}
                  agencyNameOptions={agencyNameOptions}
                />
              </Feature>
              <Feature allowed="isAgent">
                <AnnualMileageValidationReportAgentView
                  onChange={handleOnChange_PROXY}
                  setAgencyCodeOptions={setAgencyCodeOptions}
                  menuItems={agencyCodeOptions}
                  value={formData.AgtNum}
                />
              </Feature>
              <Box>
                <Button
                  id="amvr-create-button"
                  onClick={handleCreateOnClick_PROXY}
                  disabled={formData.AgtNum.length < 6 || !formData.AsOfDate || loadingPdf}
                  loading={loadingPdf}
                  variant="contained"
                  color="primary">
                  Generate Report
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Feature>
    </ViewContainer>
  );
};

export default AnnualMileageValidationReport;
