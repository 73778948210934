import React from 'react';
import { Card, Box } from 'components/AORedesign';
import { BillingServicesPaymentManagement } from 'components/BillingServices/PaymentManagement';

const PaymentManagement = () => {
  return (
    <Box mb={2}>
      <Card>
        <BillingServicesPaymentManagement />
      </Card>
    </Box>
  );
};

export default PaymentManagement;
