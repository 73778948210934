import axios from 'axios';
import cuid from 'cuid';
import _ from 'lodash';
import { GET_DOWNLOAD_MEDIA } from '../../constants/api';

const convertToBlob = (_data) => {
  function b64toBlob(b64Data) {
    const data = atob(b64Data);
    return Uint8Array.from(data, (b) => b.charCodeAt(0));
  }
  const body = b64toBlob(_data);
  const blob = new Blob([body]);
  return blob;
};

const getApplicationType = (_fileExtension) => {
  let applicationType;
  switch (_fileExtension) {
    case '.xls': {
      applicationType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
      break;
    }
    default: {
      applicationType = 'application/pdf';
      break;
    }
  }
  return applicationType;
};

const DownloadFileService = (_data, _fileName) => {
  const blob = convertToBlob(_data);

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, _fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', _fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const DownloadFileNewWindowService = (_data, _fileName, _fileExtension) => {
  const blob = convertToBlob(_data);
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, _fileName);
  } else {
    const applicationType = getApplicationType(_fileExtension);
    const file = new Blob([blob], { type: applicationType });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
  }
};

const getFileExtension = (_contentType) => {
  let extension;
  switch (_contentType) {
    case 'application/msword':
    case 'application/doc':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      extension = '.doc';
      break;
    }
    case 'application/vnd.ms-excel': {
      extension = '.xls';
      break;
    }
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      extension = '.xlsx';
      break;
    }
    default:
      extension = '.pdf';
      break;
  }
  return extension;
};

const makeAxiosRequest = (_httpMethod, _url, _requestBody, _overrideBaseUrl) => {
  if (_requestBody !== null && _httpMethod === 'POST') {
    return axios.post(_url, _requestBody);
  } else {
    return axios({ url: `${_overrideBaseUrl ? _overrideBaseUrl : GET_DOWNLOAD_MEDIA}${_url}` });
  }
};

const handleFileDownload = (
  _fileUrl,
  _setLoadingFile,
  _setDownloadingFileError,
  _setIsDownloadingFileErrorVisible,
  _newWindow,
  _httpMethod,
  _requestBody,
  _overrideBaseUrl = '',
) => {
  _setIsDownloadingFileErrorVisible && _setIsDownloadingFileErrorVisible(false);
  _setLoadingFile(true);
  _setDownloadingFileError(false);
  makeAxiosRequest(_httpMethod, _fileUrl, _requestBody, _overrideBaseUrl)
    .then((response) => {
      console.info(response);
      if (response.data.size < 1 || response.data === '' || response.data === ' ') {
        _setDownloadingFileError('No Document found');
        console.info('No Document found');
      } else {
        const fileExtension = getFileExtension(response.headers['content-type']);
        const fileName = `${`${cuid()}_document${fileExtension}`}`;

        !_.isNil(_newWindow)
          ? DownloadFileNewWindowService(response.data, fileName, fileExtension)
          : DownloadFileService(response.data, fileName);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 307) {
        window.location.href = error.response.data.url;
      } else {
        console.error(error);
        _setIsDownloadingFileErrorVisible && _setIsDownloadingFileErrorVisible(true);
        _setLoadingFile(false);
        _setDownloadingFileError('Error downloading file.');
      }
    })
    .finally(() => {
      _setLoadingFile(false);
    });
};

// Interim solution while we move the documents to the documents service and fetch them via API.
// This is used to fetch files currently hosted on the in Classic AO repo/file system.
const handleStateXltOnClick = (legacyUrl, path) => {
  const urlDomain = legacyUrl.split('/#')[0];
  window.open(`${urlDomain}/${path}`, '_blank');
};

export { handleFileDownload, DownloadFileService, handleStateXltOnClick };
