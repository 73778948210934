import React from 'react';
import _ from 'lodash';

import { AOButton } from '../AOButton';
import { ROUTE_BILLING_SERVICES, ROUTE_CLAIMS_INQ } from '../../constants/routes';
import { dataGridDateFormatter, dataGridCurrencyFormatter } from '../../helpers';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';
import { GET_REPORTS_URL } from '../../constants/api';

const renderCell = (params, setClaimsInquiry) => {
  const handleClaimsOnClick = () => {
    setClaimsInquiry({ policyNumber: params.value });
  };

  return (
    !_.isNil(params.row) && (
      <AOButton
        aria-label="policy-number-show-more"
        route={ROUTE_CLAIMS_INQ}
        onClick={handleClaimsOnClick}
        color="primary">
        {params.value}
      </AOButton>
    )
  );
};

const getDirectBilledPoliciesColumns = (setClaimsInquiry) => {
  return [
    {
      field: 'policyNumber',
      headerName: 'Policy Number',
      width: 200,
      renderCell: (params) => renderCell(params, setClaimsInquiry),
    },
    {
      field: 'policyEffectiveDate',
      headerName: 'Effective Date',
      width: 200,
      filterable: false,
      type: 'date',
      valueFormatter: dataGridDateFormatter,
    },
    {
      field: 'policyExpirationDate',
      headerName: 'Expiration Date',
      width: 200,
      filterable: false,
      type: 'date',
      valueFormatter: dataGridDateFormatter,
    },
    {
      field: 'policyStatusDescr',
      headerName: 'Status',
      width: 200,
    },
    {
      field: 'minimumDue',
      headerName: 'Min. Amount Due',
      width: 200,
      type: 'number',
      valueFormatter: dataGridCurrencyFormatter,
    },
    {
      field: 'policyBalance',
      headerName: 'Policy Balance',
      width: 200,
      type: 'number',
      valueFormatter: dataGridCurrencyFormatter,
    },
  ];
};

const activitySummaryListColumns = [
  {
    field: 'processDate',
    headerName: 'Process Date',
    width: 200,
    filterable: false,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'activityType',
    headerName: 'Activity',
    width: 200,
  },
  {
    field: 'policyNumber',
    headerName: 'Policy Number',
    width: 200,
  },
  {
    field: 'activityEffectiveDate',
    headerName: 'Effective Date',
    width: 200,
    filterable: false,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'appliedAmount',
    headerName: 'Charges & Credits Applied',
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
  {
    field: 'minimumPayment',
    headerName: 'Minimum Payment Due',
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
  {
    field: 'accountBalance',
    headerName: 'Account Balance',
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
];

const futureBillingsColumns = [
  {
    field: 'billingDate',
    headerName: 'Billing Date',
    width: 200,
    filterable: false,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    width: 200,
    filterable: false,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'billAmount',
    headerName: 'Min. Amount Due',
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
];

const handlePrintPDFOnClick = (
  accountNumber,
  setLoadingPdf,
  setDownloadingPdfError,
  setIsDownloadingPdfErrorVisible,
) => {
  const request_body = {
    Area: 'account-summary',
    DetailedContext: [
      {
        name: 'AccountNumber',
        value: accountNumber,
      },
    ],
    TypeOfRedirect: 'Generate-PDF',
  };

  handlePdfDownload(
    'POST',
    request_body,
    GET_REPORTS_URL,
    setLoadingPdf,
    setDownloadingPdfError,
    setIsDownloadingPdfErrorVisible,
    true,
  );
};

const handleAOTextFieldOnChange = (e, stateSetFunc) => {
  stateSetFunc(e.target.value);
};

const handleContinueOnClick = (
  paymentAmountType,
  paymentNote,
  paymentOtherAmount,
  setPaymentFeedback,
  setShowPaymentModal,
) => {
  if (paymentAmountType === '') {
    setPaymentFeedback(
      "Please select the 'Minimum Amount Due', 'Account Balance' or 'Other' radio button before submitting payment",
    );
  } else if (paymentAmountType === 'other' && paymentOtherAmount === '') {
    setPaymentFeedback('Please enter Other Amount');
  } else if (paymentNote === '') {
    setPaymentFeedback('Please enter Payment Type');
  } else {
    setPaymentFeedback('');
    setShowPaymentModal(true);
  }
};

const getPaymentAmount = (minimumDue, balance, other, paymentAmountType) => {
  switch (paymentAmountType) {
    case 'min':
      return minimumDue;
    case 'bal':
      return balance;
    case 'other':
      return other;
  }
};

const handleCloseModal = (setShowPaymentModal) => setShowPaymentModal(false);

const handleGoBack = (history, pageName) => {
  if (pageName) {
    history.goBack();
  } else {
    history.push(ROUTE_BILLING_SERVICES);
  }
};

export {
  getDirectBilledPoliciesColumns,
  activitySummaryListColumns,
  futureBillingsColumns,
  handlePrintPDFOnClick,
  handleAOTextFieldOnChange,
  handleContinueOnClick,
  getPaymentAmount,
  handleCloseModal,
  handleGoBack,
};
