import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  InputLabel,
  FormLabel,
} from '@material-ui/core';

import { StyledAOTransferList, useStyles } from './AOTransferList.styled';

const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

const AOTransferList = ({
  label,
  left,
  leftLabel,
  right,
  rightLabel,
  checked,
  setLeft,
  setRight,
  setChecked,
  elevation,
  variant,
}) => {
  const classes = useStyles();
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items, elevation, variant) => (
    <Paper className={classes.paper} elevation={elevation} variant={variant}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <StyledAOTransferList>
      <FormLabel className={classes.topLabel}>{label}</FormLabel>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classes.root}>
        <Grid item>
          <InputLabel className={classes.label}>{leftLabel}</InputLabel>
          {customList(left, elevation, variant)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right">
              ≫
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right">
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left">
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left">
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <InputLabel className={classes.label}>{rightLabel}</InputLabel>
          {customList(right, elevation, variant)}
        </Grid>
      </Grid>
    </StyledAOTransferList>
  );
};

AOTransferList.defaultProps = {
  elevation: 1,
  variant: 'elevation',
};

AOTransferList.propTypes = {
  label: PropTypes.string,
  leftLabel: PropTypes.string,
  left: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setLeft: PropTypes.func,
  rightLabel: PropTypes.string,
  right: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setRight: PropTypes.func,
  checked: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setChecked: PropTypes.func,
  elevation: PropTypes.number,
  variant: PropTypes.oneOf(['outlined', 'elevation']),
};

export default AOTransferList;
