import React from 'react';
import PropTypes from 'prop-types';
import MuiBox from '@material-ui/core/Box';
import MuiAlert from '@material-ui/lab/Alert';
import MuiAlertTitle from '@material-ui/lab/AlertTitle';

const Alert = ({ alertTitleMessage, message, children, ...props }) => {
  return (
    <MuiBox my={2}>
      <MuiAlert {...props}>
        {alertTitleMessage && (
          <MuiAlertTitle>
            <strong>{alertTitleMessage}</strong>
            {message && <MuiBox mb={2} />}
          </MuiAlertTitle>
        )}
        {message}
        {children}
      </MuiAlert>
    </MuiBox>
  );
};

Alert.defaultProps = {};

Alert.propTypes = {
  alertTitleMessage: PropTypes.string,
  children: PropTypes.node,
  message: PropTypes.string,
};

export default Alert;
