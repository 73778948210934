import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cuid from 'cuid';

import { ScreenSmith } from '../ScreenSmith';
import ClaimsSkeleton from './ClaimsSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import OneStopClaimReporting from './OneStopClaimReporting';
import { ClaimsInquiryDetails } from '../ClaimsInquiryDetails';
import {
  getDataGridColumns,
  fetchSuggestions,
  getTextFieldLabelAndPlaceholder,
  handleOnChange,
  getAOAutocompleteValue,
  getAOAutocompleteInputValue,
  isSearchButtonDisabled,
  getAutocompleteEndpoint,
  handleSearchOnClick,
  resetResults,
  handleClickPaginationChange,
  fetchResults,
} from './Claims.api';
import {
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
} from '../CommonUtilityServices/EventHandlerService';
import { useDebounce } from '../../hooks/useDebounce';
import { AOAlertExpandable } from '../AOAlert';

const LocalComponents = { OneStopClaimReporting, ClaimsInquiryDetails, AOAlertExpandable };

const ClaimsSearch = ({ claimsInquiry, setClaimsInquiry }) => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`ClaimsSearchPanel`);

  const [claimantNameSearchType, setClaimantNameSearchType] = useState('exact');
  const [searchByType, setSearchByType] = useState('policyNumber');
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    policyNumber: { value: '', invalidText: '' },
    insuredName: { value: '', invalidText: '' },
    claimantName: { value: '', invalidText: '' },
  });
  const [formData, setFormData] = useState({
    policyNumber: '',
    insuredName: '',
    claimantName: '',
  });
  const [suggestionOptions, setSuggestionOptions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [results, setResults] = useState(null);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [error, setError] = useState();

  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);

  useEffect(async () => {
    if (autocompleteInputValues[searchByType].value.length >= 3) {
      const suggestions = await fetchSuggestions(
        getAutocompleteEndpoint(searchByType),
        autocompleteInputValues[searchByType].value,
      );
      setSuggestionOptions(suggestions);
    } else {
      setSuggestionOptions([]);
    }
  }, [debouncedState[searchByType], searchByType]);

  useEffect(() => {
    if (claimsInquiry) {
      setFormData({ ...formData, policyNumber: claimsInquiry.policyNumber });
      fetchResults(
        1,
        setResults,
        searchByType,
        claimantNameSearchType,
        claimsInquiry.policyNumber,
        setLoadingSearchResults,
        () => {},
        setError,
      );
      setClaimsInquiry(null);
    }
  }, [claimsInquiry]);

  const { label, placeholder } = getTextFieldLabelAndPlaceholder(searchByType);

  const localStateMap = {
    pageNumber,
    claimantNameSearchType,
    searchByType,
    searchText: getAOAutocompleteValue(formData, searchByType),
    searchTextInputValue: getAOAutocompleteInputValue(autocompleteInputValues, searchByType),
    showClaimantNameSearchOptions: searchByType === 'claimantName',
    showPolicyNumberSearchMessage: searchByType === 'policyNumber',
    textFieldLabel: label,
    textFieldPlaceholder: placeholder,
    suggestionOptions,
    searchButtonDisabled:
      isSearchButtonDisabled(autocompleteInputValues, searchByType) || loadingSearchResults,
    dataGridColumns: getDataGridColumns(searchByType, setSelectedClaim),
    dataGridRows: results?.results?.map((row) => ({ ...row, id: cuid() })),
    totalCount: results?.totalCount,
    loadingSearchResults,
    selectedClaim,
    error,
  };

  const callBackFunctions = {
    handleClaimantNameSearchTypeOnChange: (e) => handleOnChange(e, setClaimantNameSearchType),
    handleSearchByTypeOnChange: (e) => {
      setSuggestionOptions([]);
      resetResults(setResults, setPageNumber);
      handleOnChange(e, setSearchByType);
    },
    handleSearchTextOnChange: (e, value) =>
      handleAOAutocompleteOnChange(searchByType, e, value, formData, setFormData),
    handleSearchTextInputValueOnChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        searchByType,
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        debouncedState,
        setDebouncedState,
      ),
    handleOnBlur: () =>
      handleAOAutocompleteOnBlur(searchByType, formData, setFormData, autocompleteInputValues),
    handleSearchOnClick: () =>
      handleSearchOnClick(
        setResults,
        setPageNumber,
        searchByType,
        claimantNameSearchType,
        autocompleteInputValues[searchByType].value,
        setLoadingSearchResults,
        undefined,
        setError,
      ),
    handleClickPaginationChange: (page) =>
      handleClickPaginationChange(
        page,
        setResults,
        searchByType,
        claimantNameSearchType,
        autocompleteInputValues[searchByType].value,
        setLoadingSearchResults,
        setError,
      ),
    handleCloseClaimsInquiryDetails: () => setSelectedClaim(null),
  };

  return loadingSd ? (
    <ClaimsSkeleton />
  ) : (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={LocalComponents}
        />
      )}
    </>
  );
};

ClaimsSearch.propTypes = {
  claimsInquiry: PropTypes.object,
  setClaimsInquiry: PropTypes.func,
};

export default ClaimsSearch;
