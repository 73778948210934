import React from 'react';
import PropTypes from 'prop-types';
import MuiTab from '@material-ui/core/Tab';

import { componentIdStringGenerator } from '../../../helpers';

const Tab = (props) => {
  return (
    <MuiTab
      {...props}
      id={componentIdStringGenerator('Tab', props.id ? props.id : props.label)}
      aria-controls={componentIdStringGenerator('Tabpanel', props.id ? props.id : props.label)}
    />
  );
};

Tab.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};

export default Tab;
