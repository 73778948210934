import { rem } from 'polished';

import { Box, styled } from '@material-ui/core';

const StyledAppetiteServiceUnavailable = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(16)}`,
  },
}));

export { StyledAppetiteServiceUnavailable };
