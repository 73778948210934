import { createAsyncThunk, createSlice, isRejected } from '@reduxjs/toolkit';
import AocApiAnnouncementsService from 'services/integration/aoc-api-announcements-service';
import AocApiPolicyService from 'services/integration/aoc-api-policies-service';

const CONFIG = {}; // store any constants here that you want available to the store and views

// SET INITIAL STATE
const initialState = {
  announcements: { data: [], loading: false, error: null }, // all announcements
  appSnackbarProps: null, // null will hide the snackbar
  cachedPolicies: {}, // cached policies from Ent Policy Detail api
  config: CONFIG,
  hiddenDashboardAlerts: {}, // id of hidden dashboard alerts
  selectedPolicyNumber: null, // selected policy on Policy Detail model
};

// EXPORT THUNKS - Asnyc calls

export const getAnnouncementsAsync = createAsyncThunk('getAnnouncementsAsync', async () => {
  const data = await AocApiAnnouncementsService.getAnnouncements();
  return data;
});

export const getPolicyAsync = createAsyncThunk('getPolicyAsync', async (policyNumber, { rejectWithValue, fulfillWithValue, getState }) => {
  const { cachedPolicies } = getState().app;
  // see if the policy is already in the cachedPolicies
  if (cachedPolicies[policyNumber]?.retrieveStatus === 'RETRIEVED') {
    // policy already exists in the cache so just sent that back
    return fulfillWithValue(null, { cacheHit: true });
  } else {
    // policy is not in cache, so get it from the api
    try {
      const policyNumberArr = policyNumber.split('_');
      const policyNum = policyNumberArr[0];
      const inquiryDate = policyNumberArr[policyNumberArr.length - 1];

      const data = await AocApiPolicyService.getPolicy(policyNum, inquiryDate);

      // If the Policy Details api call was successful (http code 200) and the response's inner statusCode is 200, then we are success
      if (data?.status?.code === 200) return data?.response?.policyDetails;

      // Policy Details api returned non-200 error, so treat as an api call error
      return rejectWithValue({
        policyNumber,
        sourceSystemName: data?.response?.policyDetails?.policy.sourceSystemName,
        code: data?.status?.code,
        message: data?.status?.reason,
      });
    } catch (e) {
      // Axios returns the status in response.status for 4xx/5xx errors
      return rejectWithValue({ policyNumber, sourceSystemName: null, code: e?.response?.status, message: e.message });
    }
  }
});

// CREATE SLICE ROUTINE
export const appStore = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // DashboardAlert component
    hideDashboardAlert: (state, action) => {
      state.hiddenDashboardAlerts[action.payload] = true;
    },
    // ClientList component
    setSelectedCachedPolicy: (state, action) => {
      state.selectedPolicyNumber = action.payload;
    },
    setAppSnackbarProps: (state, action) => {
      state.appSnackbarProps = action.payload;
    },
  },

  // THUNK EXTRA REDUCERS
  extraReducers: (builder) => {
    builder
      // Get Accounts
      .addCase(getPolicyAsync.pending, (state, action) => {
        const policyNumber = action.meta.arg;
        // if the policy isn't already retreived, then put it in RETRIEVING status
        if (state.cachedPolicies[policyNumber]?.retrieveStatus !== 'RETRIEVED') {
          state.cachedPolicies[policyNumber] = { retrieveStatus: 'RETRIEVING' };
        }
      })
      .addCase(getPolicyAsync.fulfilled, (state, action) => {
        // ignore if we had a cacheHit
        if (action.meta?.cacheHit) return;
        //  save the policy to the cachedPolicies array
        const policy = action.payload;
        const policyNumber = action.meta.arg;
        state.cachedPolicies[policyNumber] = { retrieveStatus: 'RETRIEVED', policy: policy };
      })
      .addCase(getPolicyAsync.rejected, (state, action) => {
        const policyNumber = action.meta.arg;
        // save the policy number and an error summary to the cachedPolicies array
        state.cachedPolicies[policyNumber] = {
          retrieveStatus: 'ERROR',
          errorDetails: action?.payload,
        };
      })
      // Get Announcements
      .addCase(getAnnouncementsAsync.pending, (state) => {
        state.announcements.loading = true;
      })
      .addCase(getAnnouncementsAsync.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.announcements.data = action.payload;
        } else {
          state.announcements.error = action.payload.error;
        }
        state.announcements.loading = false;
      })
      .addCase(getAnnouncementsAsync.rejected, (state, action) => {
        state.announcements.loading = false;
        state.announcements.error = action.payload.message;
      })
      // Common actions based on result
      // .addMatcher(isPending, (state, action) => {
      // })
      // .addMatcher(isFulfilled, (state, action) => {
      // })
      .addMatcher(isRejected, (_, action) => {
        console.log(`${action.type} - ${action.payload}`);
      });
  },
});

// EXPORT ACTIONS
export const { hideDashboardAlert, setSelectedCachedPolicy, setAppSnackbarProps } = appStore.actions;

// EXPORT REDUX SELECTORS

// Return the announcements in reverse order (latest first) and loading state
export const selectAnnouncements = (state, announcementType = []) => {
  const { data, loading, error } = state.app.announcements;
  const announcementsResult = [];

  for (const article of data) {
    if (announcementType.length > 0 && announcementType.includes(article.newsType)) {
      announcementsResult.push(article);
    }
  }

  return { announcements: announcementsResult, loading, error };
};

// EXPORT CUSTOM SELECTORS

// return selected policy from the cached array
export const selectCachedPolicy = (state) => {
  // Look through the cached policies to see if the policy is in cached
  const cachedPolicies = state.app.cachedPolicies;
  const selectedPolicyNumber = state.app.selectedPolicyNumber;
  if (cachedPolicies && selectedPolicyNumber) return cachedPolicies[selectedPolicyNumber];
  return null;
};

export default appStore;
