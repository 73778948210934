import { Link } from 'components/AORedesign';
import AppConfigContext from 'contexts/appConfig.context';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const LegacyLink = ({ routeLegacy, ...props }) => {
  const { legacyUrl } = useContext(AppConfigContext);

  const handleClick = (_routeLeagacy) => {
    window.open(`${legacyUrl}${_routeLeagacy}`, '_blank');
  };

  return (
    <Link onClick={() => handleClick(routeLegacy)} {...props}>
      {props.children}
    </Link>
  );
};

LegacyLink.propTypes = {
  children: PropTypes.node.isRequired,
  routeLegacy: PropTypes.string,
};

export default LegacyLink;
