import React from 'react';

import { ViewContainer } from 'components/AORedesign';
import { AppAdmin } from 'components/AppAdmin';

const AppAdminContainer = () => {
  return (
    <ViewContainer title="" hidePolicyViewChange hideStartQuote>
      <AppAdmin />
    </ViewContainer>
  );
};

export default AppAdminContainer;
