import { Box, Checkbox, Paper, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from 'axios';
import { Alert, Link } from 'components/AORedesign';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AGENCY_ADMIN_GET_POTENTIAL_AGENCIES,
  USER_ADMIN_CHALLENGE_QUESTIONS,
  USER_ADMIN_CREATE_USER,
  USER_ADMIN_PERMITTED_AGENCY_CODES,
  USER_ADMIN_PERMITTED_GROUPS,
} from '../../constants/api';
import featureFlags from '../../constants/featureFlags';
import agentContext from '../../contexts/agent.context';
import { AOAlert } from '../AOAlert';
import AOAutocomplete from '../AOAutocomplete/AOAutocomplete';
import { AOButton } from '../AOButton';
import { AOCard } from '../AOCard';
import { AODataGrid } from '../AODataGrid';
import AOSelect from '../AOSelect/AOSelect';
import AOTransferList from '../AOTransferList/AOTransferList';
import { Feature } from '../Feature';
import Col from '../ScreenSmith/Col';
import Row from '../ScreenSmith/Row';
import { Visible } from '../Visible';
import {
  agencySelectedNullAlert,
  challengeQuestionsMenuItems,
  initialResponseAlert,
  initialResponseAlert1,
  memberGroupNullAlert,
  validateAgencyId,
  validateEmail,
  validateFirstName,
  validateLastName,
  validateModId,
  validation,
} from './UserAdmin.api';
import UserAdminViewContainer from './UserAdminViewContainer';

// TO DO
const UserAdminAddUser = ({ title, hideReturn }) => {
  const [availableUserGroups, setAvailableUserGroups] = useState([]);
  const [checkedUserGroups, setCheckedUserGroups] = useState([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [potentialAgencies, setPotentialAgencies] = useState(null);
  const [externalUserAgencies, setExternalUserAgencies] = useState(null);
  const permittedGroups = useRef([]);

  const [submitDisable, setSubmitDisable] = useState(true);

  const [formData, setFormData] = useState({
    artAgentID: '',
    assignedAgencies: '',
    challengeAnswer: '',
    challengeQuestion1: '',
    challengeQuestion: '',
    givenName: '',
    mail: '',
    memberGroups: '',
    sn: '',
    ininetViewAll: false,
    modId: '',
  });
  const [agenciesSelected, setagenciesSelected] = useState([]);
  const [agencyNameInfo, setagencyNameInfo] = useState('');
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [agencyIdError, setAgencyIdError] = useState(null);
  const [modIdError, setModIdError] = useState(null);
  const [challengeResponseError, setChallengeResponseError] = useState(null);

  const [selectionModel, setSelectionModel] = useState([]);
  const [challengeQuestion, setChallengeQuestions] = useState(null);
  const potentialAgenciesColumns = [
    {
      field: 'agencyId',
      headerName: 'Agency Id',
      width: 200,
    },
    {
      field: 'agencyName',
      headerName: 'Agency Name',
      width: 450,
    },
    {
      field: 'agencyCity',
      headerName: 'Agency City',
      width: 200,
    },
    {
      field: 'agencyState',
      headerName: 'Agency State',
      width: 200,
    },
  ];
  const UserID = useContext(agentContext).UserID?.split('@')[0];

  const handleUserGroupsSetLeft = (e) => {
    setAvailableUserGroups(e);
  };

  const handleUserGroupsSetRight = (e) => {
    setSelectedUserGroups(e);
    setFormData({ ...formData, memberGroups: e });
  };
  useEffect(async () => {
    try {
      setFirstNameError(null);
      setLastNameError(null);
      setChallengeResponseError(null);
      setEmailError(null);
      setAgencyIdError(null);
      setModIdError(null);
      getPermittedAgencyCodes();
      const getChallengeQuestionsUrl = `${USER_ADMIN_CHALLENGE_QUESTIONS}`;
      const challengeQuestionsList = await axios.get(getChallengeQuestionsUrl);
      setChallengeQuestions(challengeQuestionsMenuItems(challengeQuestionsList.data));
      setFormData({
        ...formData,
        challengeQuestion1: '0',

        challengeQuestion: challengeQuestionsList.data[0],
      });
      const permittedGroupsResponse = await axios.get(USER_ADMIN_PERMITTED_GROUPS);
      permittedGroups.current = permittedGroupsResponse.data ?? [];
      const getSA = permittedGroupsResponse.data.map((o) => o.value);
      setAvailableUserGroups(getSA);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setagenciesSelected(value);
    setFormData({ ...formData, assignedAgencies: value });
  };
  const [responseAlert, setResponseAlert] = useState(initialResponseAlert);

  const [internalUserAlert, setinternalUserAlert] = useState(initialResponseAlert1);

  const [agencySelectedAlert, setagencySelectedAlert] = useState(agencySelectedNullAlert);

  const [memberGroupAlert, setmemberGroupAlert] = useState(memberGroupNullAlert);

  const validateChallengeResponse = (e) => {
    const value = e?.target.value;
    const pattern = new RegExp(validation.ininetChAns.pattern);
    if (!e) {
      setChallengeResponseError(null);
      return false;
    }
    if (!value) {
      setChallengeResponseError(validation.ininetChAns.required);
    } else if (!pattern.test(value)) {
      setChallengeResponseError(validation.ininetChAns.validationMessage);
    } else {
      setChallengeResponseError(null);
    }
    setFormData({ ...formData, challengeAnswer: value });
  };

  const handleClickInternalUser = (e) => {
    const value = e?.target.checked;
    setFormData({ ...formData, ininetViewAll: value });
    if (value) {
      initialResponseAlert1.isVisible = true;
    } else {
      initialResponseAlert1.isVisible = false;
    }
    setinternalUserAlert(initialResponseAlert1);
  };

  const handleClearAgencyNameClick = () => {
    setagencyNameInfo('');
    setPotentialAgencies(null);
  };
  const handleSelectionModelOnChange = (model) => {
    setSelectionModel(model);
    setFormData({ ...formData, assignedAgencies: model });
  };
  const getPermittedAgencyCodes = async () => {
    try {
      const getPermittedAgencyCodesUrl = `${USER_ADMIN_PERMITTED_AGENCY_CODES}`;
      const details = await axios.get(getPermittedAgencyCodesUrl);
      console.log('getPermittedAgencyCodes', details);

      const getSA = [];
      Object.values(details.data).forEach(function (data) {
        getSA.push(data);
      });
      setExternalUserAgencies(getSA);
    } catch (error) {
      console.error(error);
      setResponseAlert({
        ...responseAlert,
        isVisible: true,
        message: `Unable to get agencies.`,
        severity: 'error',
      });
    }
  };

  const handleSearchOnClick = async () => {
    try {
      const searchQueryString = `?userName=${UserID}&agencyName=${agencyNameInfo}`;
      //const searchQueryString = `?userName=rsundaram2&agencyName=test`;
      const getUserDetailsUrl = `${AGENCY_ADMIN_GET_POTENTIAL_AGENCIES}${searchQueryString}`;
      const details = await axios.get(getUserDetailsUrl);
      console.log('Potential Agencies', details);

      setPotentialAgencies(details.data);
      const agenciesInfo = [];
      Object.values(details.data).forEach(function (data) {
        agenciesInfo.push(data.agencyId);
      });
    } catch (error) {
      console.error(error);
      setResponseAlert({
        ...responseAlert,
        isVisible: true,
        message: `Unable to get agencies.`,
        severity: 'error',
      });
    }
  };
  const clearForm = () => {
    setFormData({
      artAgentID: '',
      assignedAgencies: '',
      challengeAnswer: '',
      challengeQuestion1: '0',
      challengeQuestion: challengeQuestion[0].value,
      givenName: '',
      mail: '',
      memberGroups: '',
      sn: '',
      ininetViewAll: false,
      modId: '',
    });
    setSelectionModel([]);
    setSelectedUserGroups([]);
    setagencyNameInfo('');
    setPotentialAgencies(null);
    setinternalUserAlert({ ...initialResponseAlert1, isVisible: false });

    setagencySelectedAlert({ ...agencySelectedNullAlert, isVisible: false });

    setResponseAlert({ ...initialResponseAlert, isVisible: true });
    console.log('formdata Clear', formData);
  };

  useEffect(() => {
    if (
      firstNameError != null ||
      formData.givenName === '' ||
      lastNameError != null ||
      formData.sn === '' ||
      emailError != null ||
      formData.mail === '' ||
      agencyIdError != null ||
      modIdError != null ||
      challengeResponseError != null ||
      formData.challengeAnswer === ''
    ) {
      setSubmitDisable(true);
    } else {
      setSubmitDisable(false);
    }
  }, [
    firstNameError,
    lastNameError,
    emailError,
    agencyIdError,
    modIdError,
    challengeResponseError,
    formData.challengeAnswer,
    formData.artAgentID,
    formData.mail,
    formData.sn,
    formData.givenName,
  ]);

  const handleSubmitOnClick = async () => {
    try {
      console.log('handleSubmitOnClickformData: ', formData);
      if (formData.ininetViewAll === false && formData.assignedAgencies === '') {
        // agencySelectedNullAlert.isVisible = true;

        setagencySelectedAlert({ ...agencySelectedNullAlert, isVisible: true });

        return false;
      } else {
        if (formData.ininetViewAll === true) {
          delete formData.assignedAgencies;
        }
        agencySelectedNullAlert.isVisible = false;
        setagencySelectedAlert(agencySelectedNullAlert);
      }
      if (formData.memberGroups.length < 1) {
        memberGroupNullAlert.isVisible = true;
        setmemberGroupAlert(memberGroupNullAlert);
        return false;
      } else {
        memberGroupNullAlert.isVisible = false;
        setmemberGroupAlert(memberGroupNullAlert);
      }

      delete formData.challengeQuestion1;
      delete formData.menuItems;

      // find group 'key' based on 'value' and send to API
      let formDataUpdated = { ...formData };
      if (formData.memberGroups.length > 0) {
        const groupArray = formData.memberGroups.reduce((groupKeys, element) => {
          const item = permittedGroups.current.find((item) => item.value === element);
          if (item && item.key) {
            return [...groupKeys, item.key];
          } else {
            return groupKeys;
          }
        }, []);
        formDataUpdated = { ...formDataUpdated, memberGroups: groupArray };
      }
      await axios.post(USER_ADMIN_CREATE_USER, formDataUpdated).then((response) => {
        console.log('response:', response);
        if (response.data.cn !== undefined && response.data.cn !== null) {
          clearForm();
          setResponseAlert({
            ...responseAlert,
            isVisible: true,
            message: `New User Created UserName : ${response.data.cn}. Please wait at least one hour before making further updates.`,
            severity: 'success',
          });
        } else if (response.data.duplicateEmail) {
          setResponseAlert({
            ...responseAlert,
            isVisible: true,
            message: `Unable to proceed, email already exists. If you already have a Username, please try resetting your password from Agent’s Only login page. For additional assistance, please contact your Agency Administrator or Customer Service Department at (877) 9ASKMSA (877-927-5672) or customer@msagroup.com.`,
            severity: 'error',
          });
        } else {
          setResponseAlert({
            ...responseAlert,
            isVisible: true,
            message: `Failed due to API Response,please contact your Agency Administrator or Customer Service Department at (877) 9ASKMSA (877-927-5672) or customer@msagroup.com.`,
            severity: 'error',
          });
        }
      });
    } catch (error) {
      console.error(error);
      setResponseAlert({
        ...responseAlert,
        isVisible: true,
        message: `Unable to Add user ${formData?.givenName}`,
        severity: 'error',
      });
    }
  };

  return (
    <Feature allowed={featureFlags.ACCESS_AGENCY_SECURITY_USER_ADMIN}>
      <UserAdminViewContainer hideReturn={hideReturn}>
        <AOCard>
          <Box mb={4}>
            <Typography variant="h5">{title ?? 'Add User'}</Typography>
          </Box>
          <Row spacing={3}>
            <Col xs={6}>
              <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
                <Box p={3}>
                  <Box>
                    <Visible isVisible={responseAlert.isVisible}>
                      <AOAlert severity={responseAlert.severity}>{responseAlert.message}</AOAlert>
                    </Visible>
                    <Visible isVisible={internalUserAlert.isVisible}>
                      <AOAlert severity={internalUserAlert.severity}>{internalUserAlert.message}</AOAlert>
                    </Visible>
                    <Visible isVisible={agencySelectedAlert.isVisible}>
                      <AOAlert severity={agencySelectedAlert.severity}>{agencySelectedAlert.message}</AOAlert>
                    </Visible>
                    <Visible isVisible={memberGroupAlert.isVisible}>
                      <AOAlert severity={memberGroupAlert.severity}>{memberGroupAlert.message}</AOAlert>
                    </Visible>
                  </Box>

                  <Box p={1} />
                  <Box p={1}>
                    <AOAutocomplete
                      id={'first-name'}
                      name={'givenName'}
                      value={formData?.givenName || ''}
                      onChange={(e) => {
                        setFormData({ ...formData, givenName: e.target.value });
                      }}
                      onInputChange={(e) => {
                        validateFirstName(e, setFirstNameError, setFormData);
                      }}
                      label={'First Name'}
                      placeholder={'Enter First Name'}
                      required
                      fullWidth
                      invalidSearch={firstNameError}
                    />
                  </Box>
                  <Box p={1}>
                    <AOAutocomplete
                      id={'last-name'}
                      name={'sn'}
                      value={formData?.sn || ''}
                      onChange={(e) => {
                        setFormData({ ...formData, sn: e.target.value });
                      }}
                      onInputChange={(e) => {
                        validateLastName(e, setLastNameError, setFormData);
                      }}
                      label={'Last Name'}
                      placeholder={'Enter Last Name'}
                      required
                      fullWidth
                      invalidSearch={lastNameError}
                    />
                  </Box>
                  <Box p={1}>
                    <AOAutocomplete
                      id={'email'}
                      name={'mail'}
                      value={formData?.mail || ''}
                      onChange={(e) => {
                        setFormData({ ...formData, mail: e.target.value });
                      }}
                      onInputChange={(e) => {
                        validateEmail(e, setEmailError, setFormData);
                      }}
                      label={'Email'}
                      placeholder={'Enter Email'}
                      required
                      fullWidth
                      invalidSearch={emailError}
                    />
                  </Box>
                  <Box p={1}>
                    <AOAutocomplete
                      id={'amic-agency-number'}
                      name={'artAgentID'}
                      value={formData?.artAgentID || ''}
                      onChange={(e) => {
                        setFormData({ ...formData, artAgentID: e.target.value });
                      }}
                      onInputChange={(e) => {
                        validateAgencyId(e, setAgencyIdError, setFormData);
                      }}
                      label={'AMIC Agency Number'}
                      placeholder={'Enter AMIC Agency Number'}
                      fullWidth
                      invalidSearch={agencyIdError}
                    />
                  </Box>
                  <Feature allowed={featureFlags.ACCESS_SUPER_ADMIN}>
                    <Box p={1}>
                      <AOAutocomplete
                        id={'mod-id'}
                        name={'ininetmodID'}
                        value={formData?.artAgentID || ''}
                        label={'MOD ID'}
                        onChange={(e) => {
                          setFormData({ ...formData, modId: e.target.value });
                        }}
                        onInputChange={(e) => {
                          validateModId(e, setModIdError, setFormData);
                        }}
                        placeholder={'Enter MOD ID'}
                        fullWidth
                        invalidSearch={modIdError}
                      />
                    </Box>
                  </Feature>
                </Box>

                <>
                  {/* <Feature allowed={featureFlags.ACCESS_VIEW_ALL}> */}
                  <Feature allowed={featureFlags.HAS_VIEW_ALL}>
                    <Box p={1}>
                      <FormControlLabel
                        checked={formData?.ininetViewAll}
                        control={<Checkbox color="primary" />}
                        label={'(Internal Only) View All'}
                        onClick={handleClickInternalUser}
                        labelPlacement="start"
                      />
                    </Box>
                    {/* </Feature>
                  <Feature allowed={featureFlags.HAS_VIEW_ALL}> */}
                    <Box p={1}>
                      <AOAutocomplete
                        id={'agency-name'}
                        name={agencyNameInfo}
                        value={agencyNameInfo}
                        onInputChange={(e) => {
                          setagencyNameInfo(e?.target.value);
                        }}
                        disabled={formData.ininetViewAll}
                        label={'Agency Name'}
                        placeholder={'Enter Agency Name'}
                        fullWidth
                      />
                    </Box>

                    <Box p={3} display={'inline'}>
                      <AOButton
                        onClick={handleSearchOnClick}
                        variant={'contained'}
                        color={'primary'}
                        id={'search-button'}
                        display="inline"
                        disabled={formData.ininetViewAll}>
                        Search
                      </AOButton>
                      <Box p={1} display="inline" />
                      <AOButton onClick={handleClearAgencyNameClick} variant={'outlined'} color={'secondary'} id={'clearAGN-button'} display="inline">
                        Clear
                      </AOButton>
                    </Box>
                    <Box p={1}></Box>
                    {potentialAgencies && (
                      <>
                        <Box p={1} mt={4} display={'flex'} justifyContent={'space-between'}>
                          <Typography>
                            <Box fontSize={'1.25rem'} fontWeight={'bold'}>
                              Potential Agencies
                            </Box>
                          </Typography>
                        </Box>
                        <Box p={1} style={{ height: 400, overflow: 'auto' }}>
                          <AODataGrid
                            rows={potentialAgencies.map((agency) => ({
                              ...agency,
                              id: agency.agencyId,
                            }))}
                            columns={potentialAgenciesColumns}
                            pageSize={25}
                            rowsPerPageOptions={[25, 50, 75, 100]}
                            checkboxSelection
                            disableSelectionOnClick
                            selectionModel={selectionModel}
                            onSelectionModelChange={(e) => handleSelectionModelOnChange(e)}
                          />
                        </Box>
                      </>
                    )}
                  </Feature>
                </>

                {externalUserAgencies && (
                  <>
                    <Feature forbidden={featureFlags.ACCESS_VIEW_ALL}>
                      <Box p={1} display="flex">
                        <Box ml={2}>
                          <Typography gutterBottom>
                            <Box textAlign={'right'} mr={2}>
                              Agency Code :
                            </Box>
                          </Typography>
                        </Box>
                        <AOSelect
                          multiple
                          native
                          value={agenciesSelected}
                          onChange={(e) => {
                            handleChangeMultiple(e);
                          }}
                          inputProps={{
                            id: 'user-admin-add-group-externalUser-all-groups',
                            style: { height: '100px' },
                          }}>
                          {externalUserAgencies.map((agencyId) => (
                            <option id={`option-${agencyId}`} key={agencyId} value={agencyId}>
                              {agencyId}
                            </option>
                          ))}
                        </AOSelect>
                      </Box>
                    </Feature>
                  </>
                )}
              </Paper>
            </Col>
            <Col xs={6}>
              <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
                <Box p={3}>
                  <Box p={2}></Box>
                  <Box p={1}>
                    <AOSelect
                      inputLabel={'Challenge Question*'}
                      label="Challenge Question"
                      labelId="challenge-question"
                      id={'challenge-question'}
                      name={''}
                      value={formData?.challengeQuestion1}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          challengeQuestion1: e.target.value,
                          challengeQuestion: challengeQuestion[e.target.value].value,
                          //challengeQuestion: formData.menuItems[e.target.value].value,
                        });
                      }}
                      variant="outlined"
                      menuItems={challengeQuestion}></AOSelect>
                  </Box>
                  <Box p={1}>
                    <AOAutocomplete
                      id={'challenge-question-response'}
                      name={'ininetChAns'}
                      value={formData?.challengeAnswer || ''}
                      onInputChange={(e) => {
                        validateChallengeResponse(e);
                      }}
                      label={'Challenge Response'}
                      placeholder={'Enter Challenge Response'}
                      required
                      fullWidth
                      invalidSearch={challengeResponseError}
                    />
                  </Box>
                </Box>
              </Paper>
            </Col>
            <Col xs={6}>
              <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
                <Box p={3}>
                  <Box p={2}></Box>
                  <Box p={1}>
                    <Typography>The Username and password will be generated by the application when the form is submitted.</Typography>
                  </Box>
                </Box>
              </Paper>
            </Col>
            <Col xs={6}>
              <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%', width: '100%' }}>
                <Box p={3} display={'inline-block'}>
                  <AOTransferList
                    checked={checkedUserGroups}
                    label={'Groups to Add'}
                    left={availableUserGroups}
                    leftLabel={'Available Groups'}
                    right={selectedUserGroups}
                    rightLabel={'Selected Groups'}
                    setLeft={(e) => handleUserGroupsSetLeft(e)}
                    setRight={(e) => handleUserGroupsSetRight(e)}
                    setChecked={setCheckedUserGroups}>
                    required
                  </AOTransferList>
                </Box>
              </Paper>
            </Col>
            <Col xs={12}>
              <Box>
                <Alert severity="info">
                  The Agency acknowledges that the individual being added is licensed and appointed to write business with MSA. If you need to complete the
                  appointment process, please complete <Link href="https://brandfolder.com/s/rrswnrv96bcj9594tj9jw5z">this form</Link> and return to{' '}
                  <Link>FOSCLicensing@MSAGROUP.COM</Link>
                </Alert>
              </Box>
            </Col>
            <Col align="right">
              <Box display={'inline'}>
                <AOButton
                  onClick={handleSubmitOnClick}
                  variant={'contained'}
                  color={'primary'}
                  id={'submit-button'}
                  display="inline"
                  disabled={firstNameError || lastNameError || emailError || agencyIdError || modIdError || challengeResponseError || submitDisable}>
                  Submit
                </AOButton>
                <Box p={1} display="inline" />
                <AOButton onClick={clearForm} variant={'outlined'} color={'secondary'} id={'clear-button'} display="inline">
                  Clear
                </AOButton>
              </Box>
            </Col>
          </Row>
        </AOCard>
      </UserAdminViewContainer>
    </Feature>
  );
};

UserAdminAddUser.propTypes = {
  title: PropTypes.string,
  hideReturn: PropTypes.bool,
};

export default UserAdminAddUser;
