import axios from 'axios';

import { SESSION_URL } from '../../constants/api';

const handleClickLogout = async (oktaAuth, setLoggingOff) => {
  try {
    if (setLoggingOff) {
      setLoggingOff(true);
    }

    const response = await axios.delete(SESSION_URL);
    const { data: responseData } = response;

    localStorage.clear();
    oktaAuth.session.close().then(() => {
      oktaAuth.tokenManager.clear();
      window.location.href = responseData.body.RedirectDestination.url;
    });
  } catch (error) {
    console.error(error);
  }
};

export { handleClickLogout };
