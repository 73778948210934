import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { ScreenSmith } from '../ScreenSmith';
import { CardTabSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { AppetiteServiceUnavailable } from '../AppetiteServiceUnavailable';
import AppDataContext from '../../contexts/appData.context';
import { GET_APPETITE } from '../../constants/api';
import ProductDetailMap from './ProductDetailMap';
import {
  StyledProductDetail,
  ProductDetailMapLegend,
  StyledProductDetailOverview,
  StyledProductDetailAccordionHeader,
  StyledProductDetailAccordionTypography,
} from './ProductDetail.styled';
import {
  ProductDetailAccordionSummary,
  ProductDetailULContentRenderer,
  ProductDetailEligibilityBoxRenderer,
  ProductDetailDocumentContentRenderer,
  ProductDetailText,
  ProductDetailTextBuilder,
  ProductDetailCoverageTypeRenderer,
} from './ProductDetail.components';

const LocalComponents = {
  ProductDetailAccordionSummary,
  StyledProductDetailOverview,
  StyledProductDetailAccordionHeader,
  StyledProductDetailAccordionTypography,
  ProductDetailCoverageTypeRenderer,
  ProductDetailULContentRenderer,
  ProductDetailDocumentContentRenderer,
  ProductDetailEligibilityBoxRenderer,
  ProductDetailText,
  ProductDetailTextBuilder,
  ProductDetailMap,
  ProductDetailMapLegend,
  AppetiteServiceUnavailable,
};

const ProductDetail = () => {
  const [productDetail, setProductDetail] = useState();
  const [serviceUnavailable, setServiceUnavailable] = useState(false);

  const { selectedAppetiteProduct, appetiteData } = useContext(AppDataContext);
  const { productId } = useParams();

  useEffect(async () => {
    const products = appetiteData?.suggestedProducts?.AvailableProducts;
    const matchedProduct = products?.find((p) => p.id === selectedAppetiteProduct);

    if (products && matchedProduct) {
      setProductDetail(matchedProduct);
    } else {
      try {
        const getAppetiteProductUrl = `${GET_APPETITE}/product/${productId}`;
        const product = await axios.get(getAppetiteProductUrl);

        setProductDetail(product.data.ProductsDetails);
      } catch {
        setServiceUnavailable(true);
      }
    }
  }, []);

  const { UiDefinitionsData, loadingSd } = useScreenDefinition('ProductDetail');
  const productDetailState = {
    ...productDetail,
    serviceUnavailable,
    serviceAvailable: !serviceUnavailable,
    showCoverageTypeSection: productDetail?.coverageType?.length > 0,
    showEligibilitySection: productDetail?.highlights?.length > 0,
    showUnderwritingGuidelinesAccordion: productDetail?.guidelines?.length > 0,
    showDocumentsAccordion: productDetail?.attachments?.length > 0,
  };

  return loadingSd ? (
    <CardTabSkeleton />
  ) : (
    <StyledProductDetail>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={LocalComponents}
          stateMap={productDetailState}
        />
      )}
    </StyledProductDetail>
  );
};

export default ProductDetail;
