import { Box, Button, Col, Row, Typography } from 'components/AORedesign';
import CLSPolicyInquiry from 'components/CommercialLinesServices/CLSPolicyInquiry';
import { WorkflowContainer } from 'components/Workflow';
import React from 'react';

const MAIL_TO_LINK =
  'mailto:MSACLProcessing@msagroup.com?subject=Commercial Lines Policy Change Request&body=Please provide the required information below to request a commercial policy change request.%0A%0ANamed Insured:%0APolicy number:%0AAgency code:%0A%0AEffective Date of change:%0ADetails for Changes requested:%0A%0A';

const CLPolicyViewChange = () => {
  return (
    <>
      <Box mb={4}>
        <WorkflowContainer>
          <Row spacing={2} alignItems={'center'}>
            <Col>
              <Typography>Submit change request for all Commercial policies</Typography>
            </Col>
            <Col>
              <Button id={'cls-acord-change-with-email'} href={MAIL_TO_LINK} variant={'outlined'} color={'primary'} style={{ textTransform: 'none' }}>
                MSACLProcessing@msagroup.com
              </Button>
            </Col>
          </Row>
        </WorkflowContainer>
      </Box>
      <Box>
        <WorkflowContainer>
          <CLSPolicyInquiry />
        </WorkflowContainer>
      </Box>
    </>
  );
};

export default CLPolicyViewChange;
