import React from 'react';

import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import { ViewContainer, Card } from 'components/AORedesign';
import { ManageCurrentPayment as ManageCurrentPaymentWidget } from 'components/BillingServices/PaymentManagement/ManageCurrentPayment';

const ManageCurrentPayment = () => {
  return (
    <Feature allowed={featureFlags.ACCESS_PAYMENT_HISTORY_AND_CURRENT_PAYMENTS}>
      <ViewContainer returnRoute={{ text: 'Manage Current Payment' }}>
        <Card>
          <ManageCurrentPaymentWidget hideBackButton />
        </Card>
      </ViewContainer>
    </Feature>
  );
};

export default ManageCurrentPayment;
