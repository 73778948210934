import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { StyledNotFoundPage } from './NotFoundPage.styled';

const NotFoundPage = () => {
  return (
    <StyledNotFoundPage>
      <h1>404 - Not Found!</h1>
      <Link to="/">Go Home</Link>
    </StyledNotFoundPage>
  );
};

NotFoundPage.propTypes = {
  children: PropTypes.node,
};

export default NotFoundPage;
