import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';

import { Box, CircularProgress, styled, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    padding: theme.spacing(0),
    position: 'relative',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      padding: `${rem(0)}`,
    },
    height: props.fullHeight ? '100%' : '',
    width: props.fullWidth ? '100%' : '',
  }),
}));

const StyledAOButton = ({ children, fullHeight, fullWidth }) => {
  const classes = useStyles({ fullHeight, fullWidth });

  return <Box className={classes.root}>{children}</Box>;
};

StyledAOButton.defaultProps = {
  fullHeight: false,
  fullWidth: false,
};

StyledAOButton.propTypes = {
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

const StyledButtonProgress = styled(CircularProgress)({
  color: 'primary',
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});

export { StyledAOButton, StyledButtonProgress };
