import React from 'react';
import cuid from 'cuid';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { currencyStringFormatter } from '../../../../helpers';

const PostPaymentValidationTable = (props) => {
  const { tableRows } = props;
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th">Account #</TableCell>
            <TableCell component="th">Account Name</TableCell>
            <TableCell component="th">Minimum Due</TableCell>
            <TableCell component="th">Payment Amount</TableCell>
            <TableCell component="th">Payment Method</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map(
            ({ accountNumber, accountName, minimumDue, paymentAmount, paymentMethod }) => (
              <TableRow key={cuid()}>
                <TableCell component="td" scope="row">
                  {accountNumber}
                </TableCell>
                <TableCell component="td">{accountName}</TableCell>
                <TableCell component="td">{currencyStringFormatter(minimumDue)}</TableCell>
                <TableCell component="td">{currencyStringFormatter(paymentAmount)}</TableCell>
                <TableCell component="td">{paymentMethod}</TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// PostPaymentValidationTable.propType = {
//   tableRows: PropTypes.arrayOf({
//     accountNumber: PropTypes.string,
//     accountName: PropTypes.string,
//     minimumDue: PropTypes.string,
//     paymentAmount: PropTypes.string,
//     paymentMethod: PropTypes.string,
//   }),
// };

PostPaymentValidationTable.propTypes = {
  tableRows: PropTypes.node,
};

export default PostPaymentValidationTable;
