import React from 'react';
import axios from 'axios';
import { GET_PAYMENTS_DUE_URL, GET_BILLING_SEARCH_PAGE_COUNT, GET_BILLING_EXACT_SEARCH } from 'constants/api';
import { Link } from 'components/AORedesign';
import { ROUTE_BILLING_DETAILS } from 'constants/routes';

const getPaymentsDueRows = async (currentPage) => await axios.get(`${GET_BILLING_EXACT_SEARCH}${GET_PAYMENTS_DUE_URL}${GET_PAYMENTS_DUE_URL}/${currentPage}`);
const getPaymentsDueRowCountData = async () => await axios.get(`${GET_BILLING_SEARCH_PAGE_COUNT}/exact${GET_PAYMENTS_DUE_URL}${GET_PAYMENTS_DUE_URL}`);

const handleAccountClick = (accountNumber, history, setPreviousPageName) => {
  const accountDetailUrl = `${ROUTE_BILLING_DETAILS}/${accountNumber}`;
  setPreviousPageName('Payment Due Report');
  history.push(accountDetailUrl);
};

const renderAccountCell = (accountNumber, history, setPreviousPageName) => {
  return (
    accountNumber && (
      <Link onClick={() => handleAccountClick(accountNumber, history, setPreviousPageName)} color="primary">
        {accountNumber}
      </Link>
    )
  );
};
const getPaymentsDueColumns = (history, setPreviousPageName) => {
  return [
    {
      field: 'name',
      headerName: 'Insured Name',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'currentDueDate',
      headerName: 'Due Date',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'accountNumber',
      headerName: 'Account Number',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (params) => renderAccountCell(params?.row?.accountNumber, history, setPreviousPageName),
    },
    {
      field: 'minimumDue',
      headerName: 'Amount',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'payPaymentAmount',
      headerName: 'Amt Paid',
      editable: false,
      sortable: false,
      flex: 1,
    },
  ];
};

export { getPaymentsDueRows, getPaymentsDueRowCountData, getPaymentsDueColumns };
