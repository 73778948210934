import React from 'react';
import PropTypes from 'prop-types';
import MuiTypography from '@material-ui/core/Typography';

import { componentIdStringGenerator } from '../../../helpers';

const Typography = (props) => {
  const generateTypographyId = () => {
    if (props.variant && !['body1', 'body2', 'caption'].includes(props.variant)) {
      return componentIdStringGenerator(
        `Typography-${props.variant}`,
        props.id ? props.id : props.children,
      );
    } else {
      return props.id;
    }
  };

  return <MuiTypography {...props} id={generateTypographyId()} />;
};

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  variant: PropTypes.string,
};

export default Typography;
