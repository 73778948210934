import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import PolicyLossRunsReportSkeleton from './PolicyLossRunsReportSkeleton';
import { ReportsHeader } from '../ReportsHeader';
import {
  handleSearchByTypeOnChange,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
  fetchSuggestions,
  handleGenerateReportOnClick,
} from './PolicyLossRunsReport.api';

import { useDebounce } from '../../hooks/useDebounce';
import useScreenDefinition from '../../hooks/useScreenDefinition';

const LocalComponents = {
  ReportsHeader,
};

const PolicyLossRunsReport = () => {
  const [searchByType, setSearchByType] = useState('AccountNumber');
  const [searchByOptions, setSearchByOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    searchBy: { value: '' },
  });
  const [formData, setFormData] = useState({
    searchBy: '',
  });

  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`PolicyLossRunsReport`);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);

  useEffect(async () => {
    if (autocompleteInputValues.searchBy.value.length >= 3) {
      const suggestions = await fetchSuggestions(
        searchByType,
        autocompleteInputValues.searchBy.value,
      );
      setSearchByOptions(suggestions);
    } else {
      setSearchByOptions([]);
    }
  }, [debouncedState.searchBy]);

  const localStateMap = {
    searchByType,
    searchByOptions,
    searchBy: formData.searchBy,
    generateReportButtonIsDisabled: autocompleteInputValues.searchBy.value.length < 6 || loadingPdf,
    errorMessage,
    loadingPdf, downloadingPdfError, isDownloadingPdfErrorVisible
  };

  const callBackFunctions = {
    handleSearchByTypeOnChange: (e) =>
      handleSearchByTypeOnChange(e, setSearchByType, setErrorMessage),
    handleSearchByOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'searchBy',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        debouncedState,
        setDebouncedState,
        setErrorMessage,
      ),
    handleSearchByOnChange: (e, value) =>
      handleAOAutocompleteOnChange('searchBy', e, value, formData, setFormData),
    handleSearchByOnBlur: () =>
      handleAOAutocompleteOnBlur('searchBy', formData, setFormData, autocompleteInputValues),
    handleGenerateReportOnClick: () =>
     handleGenerateReportOnClick(searchByType, formData.searchBy, setLoadingPdf, setIsDownloadingPdfErrorVisible, setDownloadingPdfError),
  };

  return loadingSd ? (
    <PolicyLossRunsReportSkeleton />
  ) : (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={LocalComponents}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default PolicyLossRunsReport;
