import React, { useEffect, useState } from 'react';

import { AOCircularProgress } from '../AOCircularProgress';
import { AOAlert } from '../AOAlert';
import { redirectUrlFunctionWithResponseMethod } from '../CommonUtilityServices/RedirectService';
import { REDIRECT_AS_REPLACEMENT } from '../../constants/api';

const isE1P = 'E1P';
const isMSE = 'MSE';
const isPLMSS = 'One';

const External = () => {
  const queryParameters = new URLSearchParams(window.location.search);

  const app = queryParameters.get('app') ?? queryParameters.get('appName');

  const queryParametersArray = queryParameters.toString().split('&');

  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      const requestBody = {
        Area: 'Comprater',
        TypeOfRedirect: app ?? 'UNKNOWN',
        DetailedContext: queryParametersArray.map((query) => {
          const data = query.split('=');

          let name = data[0] ?? '';

          if (name === 'searchValue') {
            name = 'quoteNumber';
          }

          return { name, value: data[1] ?? '' };
        }),
      };

      await redirectUrlFunctionWithResponseMethod(
        requestBody,
        null,
        setError,
        REDIRECT_AS_REPLACEMENT,
      );
    })();
  }, []);

  const getMessage = () => {
    if (app === isE1P) {
      return 'Please wait to be redirected to Main Street Protection+ Personal Lines Page.';
    } else if (app === isMSE) {
      return 'Please wait to be redirected to Main Street Commercial Lines Page.';
    } else if (app === isPLMSS) {
      return 'Please wait to be redirected to Main Street Station Personal Lines Page.';
    } else {
      return 'Please wait to be redirected.';
    }
  };

  return error ? (
    <AOAlert severity="error">{error}</AOAlert>
  ) : (
    <AOCircularProgress visible>{getMessage()}</AOCircularProgress>
  );
};

export default External;
