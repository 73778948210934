import React from 'react';
import PropTypes from 'prop-types';

import { Box, IconButton, Row, TextField } from '../../../AORedesign';
import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment } from '@material-ui/core';
import { AgentLookupModal } from '../../../AgentLookupModal';

const BMReportInternal = ({ setAgentCode, open, setOpen, value, handleSetAgencyCodeOnChangeInternal }) => {
  return (
    <>
      <Box mb={2}>
        <Row alignItems={'center'}>
          <TextField
            id={'bmreport-agency-codes'}
            label={'Enter Agency Code'}
            value={value}
            name={'agencyCodes'}
            onChange={handleSetAgencyCodeOnChangeInternal}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton id={'bmreport-lookup-button'} onClick={() => setOpen(true)}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Row>
        <AgentLookupModal
          open={open}
          setAgentCode={setAgentCode}
          lineOfBusiness={'commercial-lines'}
          id="bmreport-agency-codes-modal"
          label="Agency Name"
          labelId="bmreport-agency-codes-modal-label"
          placeholder="Enter Agent ID"
          setOpen={setOpen}
        />
      </Box>
    </>
  );
};

BMReportInternal.propTypes = {
  value: PropTypes.string,
  setAgentCode: PropTypes.func,
  setOpen: PropTypes.func,
  handleSetAgencyCodeOnChangeInternal: PropTypes.func,
  open: PropTypes.bool,
};

export default BMReportInternal;
