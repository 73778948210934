import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, Link, Typography } from 'components/AORedesign';
import { redirectUrlFunction } from 'components/CommonUtilityServices/RedirectService';

const PersonalLinesLinks = ({ setErrorVisible, setErrorMessage }) => {
  const redirect = (request_body) => {
    return redirectUrlFunction(request_body, 'POST', null, setErrorMessage, setErrorVisible);
  };

  const plsMainStreetStationQuickLink = () => {
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'Main Street Station',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const plsMainStreetPersonalPlusQuickLink = () => {
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'MSP+',
      DetailedContext: [
        {
          name: 'effectiveDate',
          value: moment().format('MM/DD/yyyy'),
        },
      ],
    };
    return redirect(request_body);
  };

  const personalLinesPcr = () => {
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'PCR',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h6">Personal Lines</Typography>
      </Box>
      <Box mt={1}>
        <Link onClick={plsMainStreetStationQuickLink} endIcon="openInNew">
          Main Street Station (PLMSS)
        </Link>
      </Box>
      <Box mt={1}>
        <Link onClick={plsMainStreetPersonalPlusQuickLink} endIcon="openInNew">
          Main Street Protection +
        </Link>
      </Box>
      <Box mt={1}>
        <Link onClick={personalLinesPcr} endIcon="openInNew">
          Personal Lines PCR
        </Link>
      </Box>
    </>
  );
};

PersonalLinesLinks.propTypes = {
  setErrorVisible: PropTypes.func,
  setErrorMessage: PropTypes.func,
};

export default PersonalLinesLinks;
