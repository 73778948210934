import React from 'react';
import PropTypes from 'prop-types';

import { Box, Autocomplete, TextField } from 'components/AORedesign';

import { GET_APNE_URL } from 'constants/api';
import useFetchSelectMenuItems from 'hooks/useFetchSelectMenuItems';

const APnEReportInternalUserView = ({
  customerNumber,
  customerNumberInputValue,
  customerOptions,
  filterOptions,
  getOptionLabel,
  handleCustomerOnBlur,
  handleCustomerOnChange,
  handleCustomerOnInputChange,
  handleLocationOnBlur,
  handleLocationOnChange,
  handleLocationOnInputChange,
  locationNumber,
  locationNumberInputValue,
  locationOptions,
  setCustomerOptions,
  setLocationOptions,
}) => {
  useFetchSelectMenuItems(`${GET_APNE_URL}/customers`, (response) => {
    setCustomerOptions(
      response.data.map((customer) => ({
        value: `${customer.customerName?.trim()} - ${customer.customerNumber}`,
        code: customer.customerNumber,
      })),
    );
  });

  useFetchSelectMenuItems(`${GET_APNE_URL}/locations`, (response) => {
    setLocationOptions(
      response.data.map((location) => ({
        value: `${location.locationName?.trim()} - ${location.locationNumber}`,
        code: location.locationNumber,
      })),
    );
  });

  return (
    <>
      <Box mb={2} x>
        <Autocomplete
          style={{ width: 400 }}
          id="apne-customer"
          value={customerNumber}
          options={customerOptions || []}
          filterOptions={filterOptions}
          inputValue={customerNumberInputValue}
          getOptionLabel={getOptionLabel}
          disableClearable
          onChange={handleCustomerOnChange}
          onInputChange={handleCustomerOnInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              id="apne-customer-label"
              label="Customer"
              placeholder="Select Customer or Start Typing"
              variant="outlined"
              onBlur={handleCustomerOnBlur}
            />
          )}
        />
      </Box>
      <Box mb={2}>
        <Autocomplete
          style={{ width: 400 }}
          id="apne-location"
          value={locationNumber}
          options={locationOptions || []}
          filterOptions={filterOptions}
          inputValue={locationNumberInputValue}
          getOptionLabel={getOptionLabel}
          disableClearable
          onChange={handleLocationOnChange}
          onInputChange={handleLocationOnInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              id="apne-location-label"
              label="Location"
              placeholder="Select Agency Location or Start Typing"
              variant="outlined"
              onBlur={handleLocationOnBlur}
            />
          )}
        />
      </Box>
    </>
  );
};

APnEReportInternalUserView.propTypes = {
  customerNumber: PropTypes.string,
  customerNumberInputValue: PropTypes.string,
  customerOptions: PropTypes.array,
  filterOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  handleCustomerOnBlur: PropTypes.func,
  handleCustomerOnChange: PropTypes.func,
  handleCustomerOnInputChange: PropTypes.func,
  handleLocationOnBlur: PropTypes.func,
  handleLocationOnChange: PropTypes.func,
  handleLocationOnInputChange: PropTypes.func,
  locationNumber: PropTypes.string,
  locationNumberInputValue: PropTypes.string,
  locationOptions: PropTypes.array,
  setCustomerOptions: PropTypes.func,
  setLocationOptions: PropTypes.func,
};

export default APnEReportInternalUserView;
