import React from 'react';

import { ViewContainer } from 'components/AORedesign';
import { ROUTE_REDESIGN_RESOURCES } from 'constants/routes';
import { BrandFolder } from 'components/BrandFolder';

const UnderwritingResources = () => {
  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_RESOURCES, text: 'Resources' }} hideStartQuote hidePolicyViewChange>
      <BrandFolder title="Underwriting Resources" typeOfContainer="Underwriting" />
    </ViewContainer>
  );
};

export default UnderwritingResources;
