import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@material-ui/core/Button';

import { componentIdStringGenerator } from '../../../helpers';

const Button = (props) => {
  return (
    <MuiButton
      color="primary"
      variant="contained"
      disabled={props.loading}
      {...props}
      id={componentIdStringGenerator('Button', props.id ? props.id : props.children)}
    />
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  loading: PropTypes.bool,
};

export default Button;
