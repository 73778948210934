import React, { useState } from 'react';
import { Alert, Box, Card, Link, Typography } from 'components/AORedesign';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';

const QuickLinksForms = () => {
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const handleClickDownloadPdf = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');
    handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
  };

  const resetErrorVisibility = () => {
    setIsDownloadingPdfErrorVisible(false);
  };

  return (
    <Card style={{ height: '100%' }}>
      <Box mb={2}>
        <Typography variant="h6">Common Forms/Requests</Typography>
      </Box>
      {isDownloadingPdfErrorVisible && <Alert color="error" alertTitleMessage={downloadingPdfError} />}
      <Box mt={1}>
        <Link data-pdf="/e-forms/Policyholder EFT Authorization Form.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
          Customer EFT Enrollment
        </Link>
      </Box>
      <Box mt={1}>
        <Link
          onClick={resetErrorVisibility}
          href="mailto:customer@msagroup.com?subject=Request%20Forms,%20Declarations%20or%20ID%20Cards&body=Please%20provide%20the%20required%20information%20to%20request%20the%20applicable%20policy%20forms,%20Declaration%20Page%20or%20Automobile%20Identification%20Card%0A%0APolicyholder%20Information%0APolicy%20number:%0AAccount%20number:%0ANamed%20insured:%0A%0AYour%20Request%0APolicy%20Form%20Request%0AForm%20name:%0AForm%20number:%0A%0ADeclaration%20Page%20Request%0APolicy%20term%20dates:%0A%0AAutomobile%20Identification%20Card%20Request%0AVehicle%20year:%0AVehicle%20make:%0AVehicle%20model:%0AVIN%23:"
          endIcon="mail">
          Forms, Dec Pages, and ID Cards Requests
        </Link>
      </Box>
      <Box mt={1}>
        <Link data-pdf="/news/Statement_of_No_Loss.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
          Statement of No Loss
        </Link>
      </Box>
      <Box mt={1}>
        <Link
          onClick={resetErrorVisibility}
          href="mailto:customer@msagroup.com?subject=Report a Payment&body=Please provide the required information below to report a payment received in your office.  A confirmation email will be sent to you.%0A%0APolicy number:%0AAccount number:%0ANamed insured:%0AAmount of payment:%0ACheck number:%0A%0ATo learn more about Main Street America Group's payment options, please visit the Billing Inquiry and Payments tab."
          endIcon="mail">
          Report a Payment
        </Link>
      </Box>
    </Card>
  );
};

export default QuickLinksForms;
