import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import CCReportSkeleton from './CCReportSkeleton';
import { ReportsHeader } from '../ReportsHeader';
import CCReportAgentView from './CCReportAgentView';
import CCReportInternalUserView from './CCReportInternalUserView';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import useFetchSelectMenuItems from '../../hooks/useFetchSelectMenuItems';
import { handleCreateOnClick, handleOnChange, resetAgencyCode } from './CCReport.api';
import { GET_CC_REPORT_PERIODS } from '../../constants/api';
import {
  fetchAgencyNameSuggestions,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnBlur,
} from '../CommonUtilityServices/EventHandlerService';
import { useDebounce } from '../../hooks/useDebounce';

const LocalComponents = { ReportsHeader, CCReportAgentView, CCReportInternalUserView };

const CCReport = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`CCReport`);

  const [errorMessage, setErrorMessage] = useState('');
  const [pdfLoading, setLoadingPdf] = useState(false);

  const [periodOptions, setPeriodOptions] = useState([]);
  const [permAgenciesOptions, setPermAgenciesOptions] = useState([]);
  const [agencyNameOptions, setAgencyNameOptions] = useState([]);
  const [searchByType, setSearchByType] = useState('name');

  const [formData, setFormData] = useState({ period: '', agencyCode: '' });
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    agencyCode: { value: '' },
  });

  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);

  useFetchSelectMenuItems(GET_CC_REPORT_PERIODS, (response) => {
    if (response.data.length) {
      const periods = response.data;
      setPeriodOptions(
        periods.map((period) => ({ value: period.periodDesc, code: period.periodValue })),
      );
      setFormData({ ...formData, period: periods[0]?.periodValue });
    }
  });

  useEffect(async () => {
    if (autocompleteInputValues.agencyCode.value.length >= 3) {
      const suggestions = await fetchAgencyNameSuggestions(
        autocompleteInputValues.agencyCode.value,
      );
      setAgencyNameOptions(suggestions);
    } else {
      setAgencyNameOptions([]);
    }
  }, [debouncedState.agencyCode]);

  const renderCreateButtonDisabled = () => {
    // button is in loading state
    if (pdfLoading) {
      return true;
    }
    // external user/agent logic
    else if (permAgenciesOptions.length && !formData.agencyCode) {
      return true;
    }
    // internal user logic for search by name
    else if (
      !permAgenciesOptions.length &&
      searchByType === 'name' &&
      autocompleteInputValues?.agencyCode?.value?.length < 3
    ) {
      return true;
    }
    // internal user logic for search by code
    else if (
      !permAgenciesOptions.length &&
      searchByType === 'code' &&
      formData?.agencyCode?.length !== 6
    ) {
      return true;
    }

    return false;
  };

  const localStateMap = {
    errorMessage,
    pdfLoading,
    agencyCode: formData.agencyCode,
    agencyNameInputValue: autocompleteInputValues.agencyCode.value,
    period: formData.period,
    periodOptions,
    permAgenciesOptions,
    agencyNameOptions,
    createButtonDisabled: renderCreateButtonDisabled(),
    searchByType,
  };

  const callBackFunctions = {
    setPeriodOptions,
    setPermAgenciesOptions,
    handleOnChange: (e) => handleOnChange(e, formData, setFormData, setErrorMessage),
    handleCreateOnClick: () => handleCreateOnClick(formData, setLoadingPdf, setErrorMessage),
    setSearchByType,
    handleAgencyNameOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'agencyCode',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        debouncedState,
        setDebouncedState,
      ),
    handleAgencyNameOnChange: (e, value) =>
      handleAOAutocompleteOnChange('agencyCode', e, value, formData, setFormData),
    handleAgencyNameOnBlur: () =>
      handleAOAutocompleteOnBlur('agencyCode', formData, setFormData, autocompleteInputValues),
    resetAgencyCode: () => resetAgencyCode(setFormData, setAutocompleteInputValues),
  };

  return loadingSd ? (
    <CCReportSkeleton />
  ) : (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={LocalComponents}
        />
      )}
    </>
  );
};

export default CCReport;
