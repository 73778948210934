import React from 'react';
import PropTypes from 'prop-types';

import { StyledHeadline, StyledHeadlineWithSubtitle, StyledSubtitle } from './Headline.styled';

const Headline = ({ title, subtitle, ...props }) => {
  return subtitle ? (
    <>
      <StyledHeadlineWithSubtitle variant="h1" {...props}>
        {title}
      </StyledHeadlineWithSubtitle>
      <StyledSubtitle>{subtitle}</StyledSubtitle>
    </>
  ) : (
    <StyledHeadline variant="h1" {...props}>
      {title}
    </StyledHeadline>
  );
};

Headline.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Headline;
