import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Alert, Box, Link, Typography } from 'components/AORedesign';
import { handleFileDownload } from 'components/CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import { redirectUrlFunction } from 'components/CommonUtilityServices/RedirectService';
import { ROUTE_REDESIGN_RESOURCES_PL_UMBRELLA, ROUTE_REDESIGN_RESOURCES_STORM } from 'constants/routes';
import LinksContainer from './LinksContainer';

const PDF_ICON = 'pdf';
const DOCUMENT_ICON = 'document';
const OPEN_IN_NEW_TAB_ICON = 'openInNew';
const CL_COSTIMATOR_REQUEST_BODY = { Area: 'Commercial Lines', DetailedContext: [], TypeOfRedirect: 'Costimator' };
const PL_COSTIMATOR_REQUEST_BODY = { Area: 'Personal Lines', DetailedContext: [], TypeOfRedirect: 'Costimator' };
const BONDS_REDIRECT_REQUEST_BODY = { Area: 'Policy Services Menu', DetailedContext: [], TypeOfRedirect: 'Surety and Fidelity Bonds' };

const AdditionalInformation = () => {
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const history = useHistory();

  const handleClickDownloadFile = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');
    if (pdfUrl) {
      handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
    } else {
      const _fileUrl = event.currentTarget.getAttribute('data-file');
      handleFileDownload(_fileUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible);
    }
  };

  const resetErrorVisibility = () => {
    setIsDownloadingPdfErrorVisible(false);
  };

  const handleGoToRoute = (e) => {
    setIsDownloadingPdfErrorVisible(false);
    const route = e.currentTarget.getAttribute('data-route');
    history.push(route);
  };

  const handleRedirectUrl = (requestBody) => {
    setIsDownloadingPdfErrorVisible(false);
    redirectUrlFunction(
      requestBody,
      'POST',
      null,
      () => {},
      () => {},
    );
  };

  const redirect = (request_body) => {
    return redirectUrlFunction(request_body, 'POST', null, setDownloadingPdfError, setIsDownloadingPdfErrorVisible);
  };

  const underwritingCloud = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const lob = event.currentTarget.getAttribute('data-lob');
    const request_body = {
      Area: lob,
      TypeOfRedirect: 'Coastal Guidelines',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  return (
    <Box mb={2}>
      <Box mt={1} mb={4}>
        <Typography variant="h6">Additional Information</Typography>
      </Box>
      {isDownloadingPdfErrorVisible && (
        <Box mb={2}>
          <Alert color="error" alertTitleMessage={downloadingPdfError} />
        </Box>
      )}
      <Box>
        <LinksContainer heading="personal lines">
          <Link onClick={() => handleRedirectUrl(PL_COSTIMATOR_REQUEST_BODY)} endIcon={OPEN_IN_NEW_TAB_ICON}>
            PL Costimator
          </Link>
          <Link onClick={underwritingCloud} data-lob={'Personal Lines'} endIcon={OPEN_IN_NEW_TAB_ICON}>
            PL Coastal Guideline Tool
          </Link>
          <Link data-route={ROUTE_REDESIGN_RESOURCES_PL_UMBRELLA} onClick={handleGoToRoute}>
            PL Umbrella Rating Tools
          </Link>
          <Link data-route={ROUTE_REDESIGN_RESOURCES_PL_UMBRELLA} onClick={handleGoToRoute}>
            Dwelling Fire Rating Tools
          </Link>
        </LinksContainer>
        <LinksContainer heading="commercial lines">
          <Link onClick={() => handleRedirectUrl(CL_COSTIMATOR_REQUEST_BODY)} endIcon={OPEN_IN_NEW_TAB_ICON}>
            CL Costimator
          </Link>
          <Link onClick={underwritingCloud} data-lob={'Commercial Lines'} endIcon={OPEN_IN_NEW_TAB_ICON}>
            CL Coastal Guideline Tool
          </Link>
          <Link data-pdf={'/agency-automation/ONLINE%20APPLICATION%20GUIDE%2010%2013.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Main Line BOP Entry Guide
          </Link>
        </LinksContainer>
        <LinksContainer heading="Flood Insurance">
          <Link href="https://brandfolder.com/s/jv6673qvn5crqzvtrvqjg54v" endIcon={PDF_ICON}>
            Flood Agency Enrollment Form
          </Link>
          <Link href="https://ngm.agent.odysseynext.com/login" endIcon={OPEN_IN_NEW_TAB_ICON}>
            Flood Policy Processing System
          </Link>
          <Link href="https://brandfolder.com/s/v9cmx7qsbs74kwmmmk85q4s" endIcon={PDF_ICON}>
            National Flood Services (NFS) Resources and Contacts
          </Link>
        </LinksContainer>
        <LinksContainer heading="Receiving lexisnexis Data">
          <Link data-pdf={'/agency-automation/Lexis%20Nexis%20Application.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            LexisNexis Application
          </Link>
          <Link data-file={'/agency-automation/Lexis%20Nexis%20Node%20Requirements.doc'} onClick={handleClickDownloadFile} endIcon={DOCUMENT_ICON}>
            LexisNexis Node Requriments
          </Link>
          <Link data-file={'/agency-automation/LexisNexis%20Contact%20Information.doc'} onClick={handleClickDownloadFile} endIcon={DOCUMENT_ICON}>
            LexisNexis Contact Information
          </Link>
        </LinksContainer>
        <LinksContainer heading="additional products">
          <Link data-route={ROUTE_REDESIGN_RESOURCES_STORM} onClick={handleGoToRoute}>
            Storm Resources
          </Link>
          <Link onClick={() => handleRedirectUrl(BONDS_REDIRECT_REQUEST_BODY)} endIcon={OPEN_IN_NEW_TAB_ICON}>
            Bonds
          </Link>
        </LinksContainer>
        <LinksContainer heading="industry standards">
          <Link onClick={resetErrorVisibility} href={'https://www.independentagent.com/act/pages/default.aspx'} endIcon={OPEN_IN_NEW_TAB_ICON}>
            Agents Council For Technology (ACT)
          </Link>
          <Link onClick={resetErrorVisibility} href={'https://www.augiegroup.org/'} endIcon={OPEN_IN_NEW_TAB_ICON}>
            ACORD Info Exchange
          </Link>
        </LinksContainer>
      </Box>
    </Box>
  );
};

export default AdditionalInformation;
