import React from 'react';
// import cuid from 'cuid';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';

import AOButtonLoadingContainer from './AOButtonLoadingContainer';

const IconComponents = {
  PictureAsPdf,
};

const AOHrefNewTabButton = ({ loading, hrefNewTab, children, endIcon, ...otherProps }) => {
  return (
    <AOButtonLoadingContainer
      loading={loading}
      fullHeight={otherProps.fullHeight}
      fullWidth={otherProps.fullWidth}>
      <Button href={hrefNewTab} target="_blank" rel="noreferrer"
        endIcon={
          endIcon &&
          IconComponents[endIcon] &&
          React.createElement(IconComponents[endIcon], null, null)
        }
        {...otherProps}>
        {children}
      </Button>
    </AOButtonLoadingContainer>
  );
};

AOHrefNewTabButton.propTypes = {
  loading: PropTypes.bool,
  hrefNewTab: PropTypes.string,
  endIcon: PropTypes.string,
  children: PropTypes.node,
};

export default AOHrefNewTabButton;
