import React, { useEffect, useState } from 'react';
import cuid from 'cuid';
import useAxios from 'axios-hooks';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';

import NewsArticlesSkeleton from './NewsArticlesSkeleton';
import NewsArticleModal from './NewsArticleModal';
import ArticlePreview from './ArticlePreview';

import { GET_NEWS, NEWS_ARTICLES } from '../../constants/api';
import { formatNewsArticles } from './NewsArticles.api';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';
import { handleFileDownload } from '../CommonUtilityServices/DownloadFileService';

import { StyledNewsArticles } from './NewsArticles.styled';

const NewsArticles = ({ line, category, newsType, previewSingleLine }) => {
  // axios hook to get category on init
  let newsArticleUrl = `${GET_NEWS}/${category}`;

  if (line && newsType) {
    newsArticleUrl = `${newsArticleUrl}?lineOfBusiness=${line}&newsType=${newsType}`;
  }
  const [{ data, loading, error }] = useAxios(newsArticleUrl);

  // axios hook that is manually executed with
  // executeNewsReview
  const [
    { data: dataNewsReview, loading: loadingNewsReview, error: errorNewsReview },
    executeNewsReview,
  ] = useAxios();

  // state variables to hold formatted articles
  const [formattedNewsArticles, setFormattedNewsArticles] = useState([]);

  const [, setLoadingFile] = useState(false);
  const [, setIsDownloadingFileErrorVisible] = useState(false);
  const [, setDownloadingFileError] = useState(false);

  // state to show/hide dialog
  const [open, setOpen] = useState(false);

  // event handler when opening modal
  const handleOpen = (id, ext) => {
    const trimmedExt = _.trim(ext); // remove whitespace
    if (_.isEmpty(trimmedExt)) {
      setOpen(true);
      executeNewsReview({
        url: `${GET_NEWS}${NEWS_ARTICLES}/${id}`,
      });
    } else {
      const fileURL = `${NEWS_ARTICLES}/${id}`;
      if (trimmedExt === 'pdf') {
        handlePdfDownload(
          'GET',
          null,
          fileURL,
          setLoadingFile,
          setDownloadingFileError,
          setIsDownloadingFileErrorVisible,
          'newWindow',
          `${GET_NEWS}`,
        );
      } else {
        handleFileDownload(
          fileURL,
          setLoadingFile,
          setDownloadingFileError,
          setIsDownloadingFileErrorVisible,
          'newWindow',
          'GET',
          undefined,
          GET_NEWS,
        );
      }
    }
  };

  // event handler to close modal
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    !loading && setFormattedNewsArticles(formatNewsArticles(data));
  }, [loading, data]);

  if (loading) return <NewsArticlesSkeleton />;
  if (error) return <p>Error!</p>;

  const showDate = (date) => date !== 'null' && newsType !== 'yellow-news';
  const hasNewsToShow = !_.isNil(formattedNewsArticles) && formattedNewsArticles.length;

  const renderNews = () => {
    return (
      hasNewsToShow &&
      formattedNewsArticles.map(({ date, articles }) => {
        return (
          <StyledNewsArticles key={cuid()} mb={showDate(date) && 2}>
            {showDate(date) && (
              <Box mb={2}>
                <Typography variant="subtitle1">{date}</Typography>
              </Box>
            )}
            <Box ml={2}>
              {!_.isNil(articles) &&
                articles.map(({ displayStartDate, title, seqId, ext }) => {
                  return (
                    <ArticlePreview
                      displayStartDate={showDate(date) && displayStartDate}
                      handleOpen={() => handleOpen(seqId, ext)}
                      previewSingleLine={previewSingleLine}
                      title={title}
                      key={cuid()}
                      newsType={newsType}
                    />
                  );
                })}
            </Box>
            <Box>
              <NewsArticleModal
                open={open}
                handleClose={handleClose}
                loading={loadingNewsReview}
                error={errorNewsReview}
                data={dataNewsReview}
              />
            </Box>
          </StyledNewsArticles>

        );
      })
    );
  };

  const renderView = () => {
    switch (newsType) {
      case 'inside-news':
        return hasNewsToShow
          ? renderNews()
          : 'Check back soon for news on The Main Street America Group';
      case 'yellow-news':
        return hasNewsToShow ? (
          <Box mb={2}>
            <Alert severity="info">{renderNews()}</Alert>
          </Box>
        ) : null;
      default:
        return null;
    }
  };

  return <Box>{renderView()}</Box>;
};

NewsArticles.defaultProps = {
  category: 'current',
  line: null,
  newsType: null,
  previewSingleLine: false,
};

NewsArticles.propTypes = {
  category: PropTypes.oneOf(['current', 'archived']),
  line: PropTypes.oneOf(['commercial-lines', 'personal-lines', null]),
  newsType: PropTypes.oneOf(['inside-news', 'yellow-news', null]),
  previewSingleLine: PropTypes.bool,
};

export default NewsArticles;
