import MuiAppBar from '@material-ui/core/AppBar';
import MuiBox from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import MuiToolbar from '@material-ui/core/Toolbar';
import MuiArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LegalAgreement } from 'components/LegalAgreement';
import { QuickActions } from '../../QuickActions';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { Link } from '../Link';
import { Typography } from '../Typography';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  header: {
    ...theme.typography.h1Brand,
  },
}));

const ViewContainer = ({ children, hidePolicyViewChange, hideStartQuote, legalAgreementLink, returnRoute, title }) => {
  const classes = useStyles();
  const history = useHistory();

  const [showLegalAgreement, setShowLegalAgreement] = useState(false);

  const openLegal = () => setShowLegalAgreement(true);

  const closeLegal = () => setShowLegalAgreement(false);

  const selectRoute = (route) => {
    if (route) {
      history.push(route);
    } else {
      history.goBack();
    }
  };

  return (
    <>
      {returnRoute && (
        <MuiAppBar position="static">
          <MuiToolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => selectRoute(returnRoute.route)}>
              {returnRoute.close ? <CloseIcon /> : <MuiArrowBackIcon />}
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {returnRoute.text}
            </Typography>
          </MuiToolbar>
        </MuiAppBar>
      )}
      <MuiBox p={5}>
        <MuiBox display="flex" justifyContent="space-between" alignItems="center" pb={3}>
          {title && (
            <MuiBox>
              <Typography variant="h1" className={classes.header}>
                {title}
              </Typography>
            </MuiBox>
          )}
          {!returnRoute && (
            <MuiBox marginLeft="auto">
              <QuickActions hidePolicyViewChange={hidePolicyViewChange} hideStartQuote={hideStartQuote} />
            </MuiBox>
          )}
        </MuiBox>
        {children}
        {legalAgreementLink && (
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Link onClick={openLegal} variant="body2">
              Terms & Conditions
            </Link>

            <Typography color="textSecondary" variant="body2" display="inline" component="div">
              <Box px={1} display="inline">
                |
              </Box>
              © {new Date().getFullYear()} - Agents Only
            </Typography>
          </Box>
        )}
        {showLegalAgreement && <LegalAgreement handleCloseLegal={closeLegal} />}
      </MuiBox>
    </>
  );
};

ViewContainer.propTypes = {
  children: PropTypes.node,
  returnRoute: PropTypes.shape({
    close: PropTypes.bool,
    route: PropTypes.string,
    text: PropTypes.string,
  }),
  hidePolicyViewChange: PropTypes.bool,
  hideStartQuote: PropTypes.bool,
  legalAgreementLink: PropTypes.bool,
  title: PropTypes.string,
};

export default ViewContainer;
