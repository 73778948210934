import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { componentIdStringGenerator } from '../../../helpers';

const AODatePicker = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        inputVariant="outlined"
        variant="inline"
        format="MM/dd/yyyy"
        autoOk
        okLabel="Done"
        cancelLabel={null}
        KeyboardButtonProps={{
          'aria-label': `${props.label}`,
        }}
        {...props}
        id={componentIdStringGenerator('DatePicker', props.id ? props.id : props.label ?? '')}
      />
    </MuiPickersUtilsProvider>
  );
};

AODatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};

export default AODatePicker;
