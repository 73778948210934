import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Box, Paper } from '@material-ui/core';
import Col from '../../../ScreenSmith/Col';
import Row from '../../../ScreenSmith/Row';
import RowCol from '../../../ScreenSmith/RowCol';
import AOTransferList from '../../../AOTransferList/AOTransferList';
import { AOButton } from '../../../AOButton';
import { AOAlert } from '../../../AOAlert';
import { Visible } from '../../../Visible';

import Typography from '@material-ui/core/Typography';
import {
  USER_ADMIN_PERMITTED_GROUPS,
  USER_ADMIN_USER_GROUPS,
  USER_ADMIN_UPDATE_USER_GROUP,
} from '../../../../constants/api';

const EditUserGroupsPanel = ({ userDetails }) => {
  const [availableUserGroups, setAvailableUserGroups] = useState([]);
  const [checkedUserGroups, setCheckedUserGroups] = useState([]);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [isSubmitErrorVisible, setIsSubmitErrorVisible] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(async () => {
    try {
      const userGroupsData = await axios.get(`${USER_ADMIN_USER_GROUPS}/${userDetails?.name}`);
      const userGroups = userGroupsData.data;
      const userGroupNames = userGroups.reduce(
        (names, currentValue) => [...names, currentValue.key],
        [],
      );
      setSelectedUserGroups(userGroupNames);

      const permittedGroupsData = await axios.get(USER_ADMIN_PERMITTED_GROUPS);
      const permittedGroups = permittedGroupsData.data;
      const permittedGroupNames = permittedGroups.reduce((names, currentValue) => {
        const allPermittedGroupNames = [...names, currentValue.key];
        return allPermittedGroupNames.filter((el) => !userGroupNames.includes(el));
      }, []);
      setAvailableUserGroups(permittedGroupNames);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleUserGroupsSetLeft = (e) => {
    setIsSubmitErrorVisible(false);
    setAvailableUserGroups(e);
  };

  const handleUserGroupsSetRight = (e) => {
    setIsSubmitErrorVisible(false);
    setSelectedUserGroups(e);
  };

  const submitUpdateUserGroup = async () => {
    setIsSubmitErrorVisible(false);
    setButtonLoading(true);
    setSuccessMessage('');
    try {
      const request_body = {
        cn: userDetails?.name,
        memberGroups: selectedUserGroups,
      };
      const response = await axios.post(USER_ADMIN_UPDATE_USER_GROUP, request_body);
      setSuccessMessage('User Groups updated successfully.');
      if (!response.data) {
        throw Error();
      }
    } catch (error) {
      console.error(error);
      const errorMessage = `The request to update groups for ${userDetails.name} was unsuccessful.`;
      setIsSubmitErrorVisible(true);
      setSubmitError(errorMessage);
    }
    setButtonLoading(false);
  };
  return (
    <>
      <Visible isVisible={isSubmitErrorVisible}>
        <AOAlert severity={'error'}>{submitError}</AOAlert>
      </Visible>
      {successMessage && <AOAlert severity={'success'}>{successMessage}</AOAlert>}
      <Row spacing={2}>
        <Col xs={5}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <Box p={1}>
              <Typography component="div">
                <Box fontSize={'1.25rem'} fontWeight={'bold'}>
                  Name
                </Box>
              </Typography>
            </Box>
            <Box p={1} display="flex">
              <Box ml={2}>
                <Typography component="div" gutterBottom>
                  <Box textAlign={'right'} mr={2}>
                    First Name:
                  </Box>
                </Typography>
                <Typography component="div" gutterBottom>
                  <Box textAlign={'right'} mr={2}>
                    Last Name:
                  </Box>
                </Typography>
                <Typography component="div" gutterBottom>
                  <Box textAlign={'right'} mr={2}>
                    User Name:
                  </Box>
                </Typography>
              </Box>
              <Box>
                <Typography component="div" gutterBottom>
                  <Box fontWeight={'bold'} color={'primary.main'}>
                    {userDetails?.givenName}
                  </Box>
                </Typography>
                <Typography component="div" gutterBottom>
                  <Box fontWeight={'bold'} color={'primary.main'}>
                    {userDetails?.sn}
                  </Box>
                </Typography>
                <Typography component="div" gutterBottom>
                  <Box fontWeight={'bold'} color={'primary.main'}>
                    {userDetails?.name}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Col>
        <Col xs={7}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <RowCol>
              <Box p={1}>
                <Typography component="div">
                  <Box fontSize={'1.25rem'} fontWeight={'bold'}>
                    Add or Remove Groups
                  </Box>
                </Typography>
              </Box>
              <Box p={1}>
                <Typography>
                  The list box at left shows the groups you may grant access to. To add a group,
                  move the group name from the left hand box to the right hand box using the buttons
                  at center.
                </Typography>
              </Box>
            </RowCol>
          </Paper>
        </Col>
      </Row>
      <Row spacing={2} mb={2}>
        <Box p={1} display="flex">
          <Col xs={12}>
            <AOTransferList
              checked={checkedUserGroups}
              label={'Groups to Add'}
              left={availableUserGroups}
              leftLabel={'Available Groups'}
              right={selectedUserGroups}
              rightLabel={'Selected Groups'}
              setLeft={(e) => handleUserGroupsSetLeft(e)}
              setRight={(e) => handleUserGroupsSetRight(e)}
              setChecked={setCheckedUserGroups}></AOTransferList>
          </Col>
        </Box>
      </Row>
      <Row justifyContent="space-between" alignItems="center" mb={4}>
        <Box>
          <AOButton
            variant={'contained'}
            color={'primary'}
            id={'edit-user-groups-submit-button'}
            onClick={() => submitUpdateUserGroup()}
            loading={buttonLoading}
            disabled={buttonLoading}>
            Submit
          </AOButton>
          <Box display={'inline'} mr={2} />
          <AOButton
            variant={'outlined'}
            color={'primary'}
            id={'edit-user-groups-cancel-button'}
            route={'/user-admin'}>
            Cancel
          </AOButton>
          <Box display={'inline'} mr={2} />
        </Box>
      </Row>
    </>
  );
};

EditUserGroupsPanel.propTypes = {
  userDetails: PropTypes.object,
};

export default EditUserGroupsPanel;
