import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper, Box, Grid } from '@material-ui/core';

import { RowCol } from '../../../ScreenSmith';
import { AOButton } from '../../../AOButton';
import { AOSelect } from '../../../AOSelect';
import { AODatePicker } from '../../../AODatePicker';
import { AOAutocomplete } from '../../../AOAutocomplete';
import { filterOptions, getOptionLabel } from '../../../CommonUtilityServices/EventHandlerService';

import { menuItemsTypes, autocompleteInputValues } from './PaymentHistory.types';

const PaymentHistoryFilter = ({
  autocompleteInputValues,
  formData,
  handleOnBlurAutocomplete,
  handleOnChange,
  handleOnChangeAutocomplete,
  handleOnChangeFromDate,
  handleOnChangeToDate,
  handleOnClickFilterResults,
  handleOnClickResetFilters,
  handleOnInputChangeAutocomplete,
  menuItems,
}) => {
  return (
    <Paper variant="outlined" square>
      <Box p={2}>
        <RowCol mb={2}>
          <Typography>Use Selections to filter the payment management history</Typography>
        </RowCol>
        <RowCol mb={4}>
          <Grid container spacing={2}>
            <Grid item>
              <AOSelect
                inputLabel="Admin"
                id="payment-history-admin-dd"
                menuItems={menuItems.adminList.map((e) => ({ value: e, code: e }))}
                name="admin"
                value={formData.admin}
                onChange={(e) => handleOnChange(e)}
              />
            </Grid>
            <Grid item>
              <AOAutocomplete
                filterOptions={filterOptions}
                getOptionLabel={getOptionLabel}
                label="Agency"
                placeholder="Select Agency"
                value={formData.agency}
                inputValue={autocompleteInputValues.agency.value}
                options={menuItems.agencyList}
                invalidSearch={autocompleteInputValues.agency.invalidText}
                handleOnChange={(e, newValue) => handleOnChangeAutocomplete(e, newValue, 'agency')}
                handleOnInputChange={(e, newValue) =>
                  handleOnInputChangeAutocomplete(e, newValue, 'agency')
                }
                onBlur={() => handleOnBlurAutocomplete('agency')}
                style={{ width: '279px' }}
              />
            </Grid>
            <Grid item>
              <AODatePicker
                id={`payment-history-from-datepicker`}
                name={`paymentHistoryFromDate`}
                label={`Date Range Start`}
                value={formData.paymentHistoryFromDate}
                onChange={(date) => handleOnChangeFromDate(date)}
              />
            </Grid>
          </Grid>
        </RowCol>
        <RowCol mb={4}>
          <Grid container spacing={2}>
            <Grid item>
              <AOAutocomplete
                filterOptions={filterOptions}
                getOptionLabel={getOptionLabel}
                label="Account"
                placeholder="Select Account"
                value={formData.account}
                inputValue={autocompleteInputValues.account.value}
                options={menuItems.accountList}
                invalidSearch={autocompleteInputValues.account.invalidText}
                handleOnChange={(e, newValue) => handleOnChangeAutocomplete(e, newValue, 'account')}
                handleOnInputChange={(e, newValue) =>
                  handleOnInputChangeAutocomplete(e, newValue, 'account')
                }
                onBlur={() => handleOnBlurAutocomplete('account')}
                style={{ width: '279px' }}
              />
            </Grid>
            <Grid item>
              <AOSelect
                inputLabel="Tran Type"
                id="payment-history-tran-type-dd"
                menuItems={menuItems.tranTypeList.map((e) => ({ value: e, code: e }))}
                name="tranType"
                value={formData.tranType}
                onChange={(e) => handleOnChange(e)}
              />
            </Grid>
            <Grid item>
              <AODatePicker
                id={`payment-history-to-datepicker`}
                name={`paymentHistoryToDate`}
                label={`Date Range End`}
                value={formData.paymentHistoryToDate}
                onChange={(date) => handleOnChangeToDate(date)}
              />
            </Grid>
          </Grid>
        </RowCol>
        <RowCol>
          <Box display="flex" justifyContent="flex-start">
            <Box mr={2}>
              <AOButton variant="contained" color="primary" onClick={handleOnClickFilterResults}>
                Filter
              </AOButton>
            </Box>
            <Box mx={2}>
              <AOButton variant="contained" onClick={handleOnClickResetFilters}>
                Reset
              </AOButton>
            </Box>
          </Box>
        </RowCol>
      </Box>
    </Paper>
  );
};

PaymentHistoryFilter.propTypes = {
  autocompleteInputValues: PropTypes.shape({
    account: autocompleteInputValues,
    agency: autocompleteInputValues,
  }),
  formData: PropTypes.shape({
    account: PropTypes.string,
    admin: PropTypes.string,
    agency: PropTypes.string,
    paymentHistoryFromDate: PropTypes.string,
    paymentHistoryToDate: PropTypes.string,
    tranType: PropTypes.string,
  }),
  handleOnBlurAutocomplete: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleOnChangeAutocomplete: PropTypes.func,
  handleOnChangeFromDate: PropTypes.func,
  handleOnChangeToDate: PropTypes.func,
  handleOnClickFilterResults: PropTypes.func,
  handleOnClickResetFilters: PropTypes.func,
  handleOnInputChangeAutocomplete: PropTypes.func,
  menuItems: PropTypes.shape({
    accountList: menuItemsTypes,
    adminList: menuItemsTypes,
    agencyList: menuItemsTypes,
    tranTypeList: menuItemsTypes,
  }),
};

export default PaymentHistoryFilter;
