import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import AOButtonLoadingContainer from './AOButtonLoadingContainer';

import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';

const AORedirectUrlButton = ({ loading, redirectUrl, children, ...otherProps }) => {
  /* TODO need to see how to globalise the error handling */
  const [, setIsRedirectErrorVisible] = useState(false);
  const [, setRedirectError] = useState(false);

  const handleClick = (_redirectUrl) => {
    const { requestBody, httpMethod } = _redirectUrl;
    return redirectUrlFunction(
      requestBody,
      httpMethod,
      null,
      setRedirectError,
      setIsRedirectErrorVisible,
    );
  };
  return (
    <AOButtonLoadingContainer loading={loading}>
      <Button onClick={() => handleClick(redirectUrl)} {...otherProps}>
        {children}
      </Button>
    </AOButtonLoadingContainer>
  );
};

AORedirectUrlButton.propTypes = {
  loading: PropTypes.bool,
  redirectUrl: PropTypes.shape({
    requestBody: PropTypes.object,
    httpMethod: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default AORedirectUrlButton;
