import React from 'react';
import axios from 'axios';
import cuid from 'cuid';
import moment from 'moment';
import { Box, Typography } from '@material-ui/core';
import {
  ALL_NEWS_ARTICLES,
  NEWS_ARTICLE_BY_ID,
  UPDATE_ARTICLE,
  DELETE_ARTICLE,
} from '../../../constants/api';

const renderDisplayDates = (params) => {
  const { startDate, endDate } = params.row;
  const formattedStartDate = moment(startDate).format('MM-DD-YYYY');
  const formattedEndDate = moment(endDate).format('MM-DD-YYYY');
  const displayStartDate = `Start: ${formattedStartDate}`;
  const displayEndDate = `Stop: ${formattedEndDate}`;
  return (
    <Box>
      {' '}
      <Typography>{displayStartDate}</Typography>
      <Typography>{displayEndDate}</Typography>
    </Box>
  );
};

const renderDisplayStatus = (params) => {
  const { status } = params.row;
  if (status === 'D') {
    return 'Display';
  } else if (status === 'H') {
    return 'Hide';
  } else if (status === 'A') {
    return 'Archive';
  }
};

const getNewsArticlesGridRows = async () => {
  const { data } = await axios.get(`${ALL_NEWS_ARTICLES}`);
  console.log(data);
  const srIds = data.map((sr) => ({ id: cuid(), ...sr }));
  return srIds;
};

const getNewsArticleById = async (_id) => {
  const { data } = await axios.get(`${NEWS_ARTICLE_BY_ID}?Id=${_id}`);
  return data;
};

const updateNewsArticle = async (formData) => await axios.post(`${UPDATE_ARTICLE}`, formData);

const deleteNewsArticle = async (id) => await axios.delete(`${DELETE_ARTICLE}/${id}`);

export {
  getNewsArticlesGridRows,
  getNewsArticleById,
  updateNewsArticle,
  renderDisplayDates,
  renderDisplayStatus,
  deleteNewsArticle,
};
