import cuid from 'cuid';

// Remap the agents to remove blank agent codes and trim the name.  They should be sorted by name already.
const mapAgentsResponse = (response) => {
  if (!response?.Results) return []
  // alert(response.Results.length)
  const mappedAgents = []
  response.Results.forEach(agent => {
    // skip agents w/out an agencyCode
    if (agent?.agencyCode) {
      mappedAgents.push({
        "id": cuid(), // add id for the DataGrid
        "agencyCode": agent.agencyCode,
        "agencyName": agent.agencyName.trim() // trim whitespace
      })
    }
  })
  return mappedAgents;
};

export { mapAgentsResponse };
