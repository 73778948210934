import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardMedia, CardActionArea } from '@material-ui/core';

import { StyledQuickReferenceCardTitle } from './QuickReferenceCard.styled';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';

const QuickReferenceCard = ({ title, route, imgSrc, imgAlt, redirectUrl }) => {
  const handleOnClick = () => {
    if (redirectUrl) {
      const { requestBody, httpMethod } = redirectUrl;

      redirectUrlFunction(
        requestBody,
        httpMethod,
        null,
        () => {},
        () => {},
      );
    }
  };

  return (
    <Card style={{ maxWidth: '400px' }}>
      <CardActionArea href={route} onClick={handleOnClick}>
        <CardMedia
          component="img"
          alt={imgAlt}
          image={`${process.env.PUBLIC_URL}/assets/images/${imgSrc}`}
          title={title}
        />
        <Box p={1}>
          <StyledQuickReferenceCardTitle>{title}</StyledQuickReferenceCardTitle>
        </Box>
      </CardActionArea>
    </Card>
  );
};

QuickReferenceCard.propTypes = {
  title: PropTypes.string,
  route: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  redirectUrl: PropTypes.object,
};

export default QuickReferenceCard;
