import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import useAxios from 'axios-hooks';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { GET_DASHBOARD_OPEN_CLAIMS } from 'constants/api';
import { ROUTE_CLAIMS } from 'constants/routes';
import agentContext from 'contexts/agent.context';
import AppDataContext from 'contexts/appData.context';
import DashboardActionCard from './DashboardActionCard';

const OPEN_CLAIMS_KEY = 'claims';

const ClaimsCard = () => {
  const history = useHistory();
  const { Locations } = useContext(agentContext);
  const { dashboard, updateDashboardCounts } = useContext(AppDataContext);
  const openClaimsData = dashboard?.[OPEN_CLAIMS_KEY];
  const [{ data, loading }] = useAxios(GET_DASHBOARD_OPEN_CLAIMS, { manual: Locations.length > dashboard?.locationCodeLimit || openClaimsData?.count });

  useEffect(() => {
    if (!loading && data) {
      updateDashboardCounts(OPEN_CLAIMS_KEY, data);
    }
  }, [loading]);

  return (
    <DashboardActionCard
      count={openClaimsData?.count}
      icon={<AssignmentOutlinedIcon />}
      onClick={() => history.push(`${ROUTE_CLAIMS}#open-claims-status`)}
      subtitle={`LAST ${openClaimsData?.days} DAYS`}
      title="Open Claims"
    />
  );
};

export default ClaimsCard;
