import React, { useState } from 'react';
import cuid from 'cuid';
import { Box, Card } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const CardTabSkeleton = ({ ...otherProps }) => {
  const [skeletonItems] = useState(Array.from(Array(5).keys()));
  return (
    <Box style={{ height: `470px` }} py={4} {...otherProps}>
      <Box
        my={4}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        style={{ height: `6.125rem` }}>
        <Box display="flex" justifyContent="flex-start" py={2}>
          <Skeleton
            width={175}
            style={{ marginRight: `24px`, padding: `6px 8px` }}
            animation="wave"
          />
        </Box>
        <Box py={2}>
          <Card>
            <Box p={4} display="flex">
              {skeletonItems.map(() => (
                <Skeleton
                  key={cuid()}
                  width={75}
                  style={{ marginRight: `24px`, padding: `6px 8px` }}
                  animation="wave"
                />
              ))}
            </Box>
            <Box p={4}>
              <Skeleton
                width={275}
                style={{ marginRight: `24px`, padding: `6px 8px` }}
                animation="wave"
              />
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default CardTabSkeleton;
