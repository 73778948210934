import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AOSelect } from '../../AOSelect';
import useInitializeFilter from './useInitializeFilter';
import { getEnumSearchCriteria } from './filterHelpers';

const ALL_STATES = 'ALL STATES';

const State = ({ handleSetSearchCriteria, setInitialQuery, setResetFunc, typeOfContainer }) => {
  const [selectedState, setSelectedState] = useState('');

  const resetComponent = () => {
    if (filterSpec?.searchTerm) {
      setSelectedState(filterSpec.values[0]);
    }
  };

  // onChange handle for dropdown. If 'ALL STATES' is selected, we need to concatenate a string that contains all possible states.
  const handleOnChange = (e) => {
    const { value } = e.target;
    setSelectedState(value);

    let newValue = [];
    if (value === ALL_STATES) {
      newValue = getEnumSearchCriteria(filterSpec?.values);
    } else {
      newValue = getEnumSearchCriteria([value, ALL_STATES]);
    }
    handleSetSearchCriteria(filterSpec?.searchTerm, newValue);
  };

  const { filterSpec } = useInitializeFilter(typeOfContainer, 'State', setInitialQuery);

  // on initialization, assign the reset pointer to ref and send initial search criteria
  useEffect(() => {
    if (filterSpec?.searchTerm?.length > 0) {
      resetComponent();
      setResetFunc(filterSpec?.searchTerm, resetComponent);
      handleSetSearchCriteria(filterSpec?.searchTerm, getEnumSearchCriteria(filterSpec?.values));
    }
  }, [filterSpec]);

  return (
    <AOSelect
      id="brandfolder-state-select"
      disabled={!filterSpec?.searchTerm}
      labelId="brandfolder-state-select-label"
      inputLabel="State"
      name={filterSpec?.searchTerm}
      menuItems={filterSpec?.values?.map((state) => ({ value: state, code: state }))}
      onChange={handleOnChange}
      value={selectedState}
    />
  );
};

State.propTypes = {
  handleSetSearchCriteria: PropTypes.func,
  setInitialQuery: PropTypes.func,
  setResetFunc: PropTypes.func,
  typeOfContainer: PropTypes.oneOf(['Underwriting', 'Marketing', 'Training']),
};

export default State;
