import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const RowItem = ({ children, ...otherProps }) => {
  return (
    <Grid item {...otherProps}>
      {children}
    </Grid>
  );
};

RowItem.propTypes = {
  children: PropTypes.node,
};

export default RowItem;
