import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GET_DBC_URL } from 'constants/api';

import { handleCreateDbcReport, handleAgencyCodeOnClick, handleAgencyNameOnClick, handleOnChangeMonthYear } from './DbcReport.api';
import {
  fetchAgencyNameSuggestions,
  handleAOAutocompleteOnBlur,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
} from 'components/CommonUtilityServices/EventHandlerService';

import { Autocomplete, Box, Button, Col, Row, Select, TextField } from 'components/AORedesign';
import { AOAlert } from 'components/AOAlert';
import { useDebounce } from 'hooks/useDebounce';

const DbcReportInternal = () => {
  const [agencyNameOptions, setAgencyNameOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    agencyName: { value: '' },
  });
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);
  const [formData, setFormData] = useState({
    agencyName: '',
    agencyCode: '',
    year: '',
  });

  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);

  useEffect(async () => {
    setFormData({ ...formData, agencyCode: '' });
    if (autocompleteInputValues.agencyName.value.length >= 3) {
      if (formData.agencyName.length > 0) {
        const agencyCode = formData.agencyName.slice(-6);
        setFormData({ ...formData, agencyCode: agencyCode });
        setIsDownloadingPdfErrorVisible(false);
        setLoadingPdf(false);
        setDownloadingPdfError('');
      }
      const suggestions = await fetchAgencyNameSuggestions(autocompleteInputValues.agencyName.value);
      setAgencyNameOptions(suggestions);
    } else {
      setAgencyNameOptions([]);
    }
  }, [debouncedState.agencyName, debouncedState.agencyCode]);

  useEffect(async () => {
    try {
      const dateData = await axios.get(`${GET_DBC_URL}/dates`);
      let { data: dates } = dateData;
      dates = dates.map((date) => {
        return { ...date, value: date.dateValue, label: date.dateDisplay.replace('-', ' ') };
      });
      setYearOptions(dates);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <>
      <Box mb={2}>{isDownloadingPdfErrorVisible && <AOAlert message={downloadingPdfError} severity="error" />}</Box>
      <Box mb={2}>
        <Autocomplete
          id="dbc-agency-name"
          style={{ width: 400 }}
          onChange={(e, value) => handleAOAutocompleteOnChange('agencyName', e, value, formData, setFormData)}
          onInputChange={(e, value) =>
            handleAOAutocompleteOnInputChange('agencyName', e, value, autocompleteInputValues, setAutocompleteInputValues, debouncedState, setDebouncedState)
          }
          onClick={() => handleAgencyNameOnClick(formData)}
          value={formData.agencyName}
          inputValue={autocompleteInputValues.agencyName.value}
          options={agencyNameOptions ?? []}
          renderInput={(params) => (
            <TextField
              {...params}
              id="apne-customer-label"
              label="Agency Name"
              placeholder="Enter Search Input"
              variant="outlined"
              onBlur={() => handleAOAutocompleteOnBlur('agencyName', formData, setFormData, autocompleteInputValues)}
            />
          )}
        />
      </Box>
      <Box mb={2}>
        <TextField
          id="dbc-agency-code"
          onChange={(e) => handleAgencyCodeOnClick(e, autocompleteInputValues, setAutocompleteInputValues, setFormData, setAgencyNameOptions)}
          value={formData.agencyCode}
          label="Agency Code"
          placeholder="Enter Search Input"
        />
      </Box>
      <Box mb={2}>
        <Select
          onChange={(e) => handleOnChangeMonthYear(e, setFormData)}
          value={formData.year}
          menuItems={yearOptions}
          id="dbc-year"
          labelId="dbc-year-label"
          label="Month/Year"
          name="year"
        />
      </Box>
      <Row spacing="2">
        <Col>
          <Button
            id="dbc-generate-report-button"
            onClick={() => handleCreateDbcReport(setLoadingPdf, setIsDownloadingPdfErrorVisible, setDownloadingPdfError, formData, 'pdf')}
            loading={loadingPdf}
            variant="contained"
            color="primary">
            Generate Pdf
          </Button>
        </Col>
        <Col>
          <Button
            id="dbc-generate-excel-report-button"
            onClick={() => handleCreateDbcReport(setLoadingPdf, setIsDownloadingPdfErrorVisible, setDownloadingPdfError, formData, 'excel')}
            loading={loadingPdf}
            variant="outlined"
            color="primary">
            Generate Excel
          </Button>
        </Col>
      </Row>
      <Box mb={4} />
    </>
  );
};

export default DbcReportInternal;
