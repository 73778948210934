import React from 'react';

import PropTypes from 'prop-types';
import { Box, Card } from '@material-ui/core';

import CustomerInfoRow from './CustomerInfoRow';

const DocSearchCustomerInfo = ({ customerInformation }) => {
  const {
    insuredName,
    policyNumber,
    effectiveDate,
    expirationDate,
    riskState,
    agencyName,
    agentNumber,
  } = customerInformation;
  return (
    <Box>
      <Card variant="outlined">
        <Box>
          <CustomerInfoRow title="Insured Name:" data={insuredName} />
          <CustomerInfoRow title="Policy Number:" data={policyNumber} />
          <CustomerInfoRow title="Effective Date:" data={effectiveDate} />
          <CustomerInfoRow title="Expiration Date:" data={expirationDate} />
          <CustomerInfoRow title="Risk State:" data={riskState} />
          <CustomerInfoRow title="Agency Name:" data={agencyName} />
          <CustomerInfoRow title="Agent Number:" data={agentNumber} />
        </Box>
      </Card>
    </Box>
  );
};
DocSearchCustomerInfo.propTypes = {
  customerInformation: PropTypes.shape({
    insuredName: PropTypes.string,
    policyNumber: PropTypes.string,
    effectiveDate: PropTypes.string,
    expirationDate: PropTypes.string,
    riskState: PropTypes.string,
    agencyName: PropTypes.string,
    agentNumber: PropTypes.string,
  }),
};

export default DocSearchCustomerInfo;
