import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';

import { ScreenSmith } from '../ScreenSmith';
import useScreenDefinition from '../../hooks/useScreenDefinition';

const ClaimsReportingModal = ({ claimsReportingModalContent, handleCloseClaimsReportingModal }) => {
  const { UiDefinitionsData } = useScreenDefinition(claimsReportingModalContent, [
    claimsReportingModalContent,
  ]);

  return (
    <Modal
      open={claimsReportingModalContent?.length ? true : false}
      onClose={handleCloseClaimsReportingModal}
      aria-labelledby="claims-reporting-modal-title"
      aria-describedby="claims-reporting-modal-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box width="85vw" maxWidth="600px">
        {!_.isNil(UiDefinitionsData) && <ScreenSmith definition={UiDefinitionsData} />}
      </Box>
    </Modal>
  );
};

ClaimsReportingModal.propTypes = {
  claimsReportingModalContent: PropTypes.string,
  handleCloseClaimsReportingModal: PropTypes.func,
};

export default ClaimsReportingModal;
