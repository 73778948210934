import React, { useState } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import ABSReportSkeleton from './ABSReportSkeleton';
import { ReportsHeader } from '../ReportsHeader';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import useFetchSelectMenuItems from '../../hooks/useFetchSelectMenuItems';
import {
  handleOnChange,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  getOptionLabel,
  filterOptions,
} from '../../components/CommonUtilityServices/EventHandlerService';
import { handleCreateOnClick } from './ABSReport.api';
import { GET_ABS_REPORT_AGENTS, GET_ABS_REPORT_DATES } from '../../constants/api';
import { getMonthName } from '../../helpers';

const LocalComponents = { ReportsHeader };

const ABSReport = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`ABSReport`);

  const [errorMessage, setErrorMessage] = useState('');
  const [pdfLoading, setLoadingPdf] = useState(false);

  const [periodOptions, setPeriodOptions] = useState([]);
  const [permAgenciesOptions, setPermAgenciesOptions] = useState([]);

  const [formData, setFormData] = useState({ period: '', agencyCode: '' });
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    agencyCode: { value: '', invalidText: '' },
  });

  useFetchSelectMenuItems(GET_ABS_REPORT_AGENTS, (response) => {
    if (response.data.length) {
      const agencies = response.data;
      setPermAgenciesOptions(
        agencies.map((agency) => ({
          value: agency.agencyDisplay,
          code: agency.agencyValue,
        })),
      );
    }
  });

  useFetchSelectMenuItems(GET_ABS_REPORT_DATES, (response) => {
    if (response.data.length) {
      const periods = response.data;
      setPeriodOptions(
        periods.map((period) => {
          const date = new Date(period.reportDate);
          const dateLabel = `${getMonthName(date.getMonth() + 1)} ${date.getFullYear()} `;
          return { value: dateLabel, code: period.reportDate };
        }),
      );
    }
  });

  const localStateMap = {
    errorMessage,
    pdfLoading,
    agencyCode: formData.agencyCode,
    period: formData.period,
    periodOptions,
    permAgenciesOptions,
    generateReportButtonDisabled: pdfLoading || !formData.agencyCode || !formData.period,
  };

  const callBackFunctions = {
    handleOnChange: (e) => handleOnChange(e, formData, setFormData),
    handleCreateOnClick: () => handleCreateOnClick(formData, setLoadingPdf, setErrorMessage),
    handleOnChangeAgentCode: (e, value) =>
      handleAOAutocompleteOnChange('agencyCode', e, value.code, formData, setFormData),
    handleOnInputChangeAgentCode: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'agencyCode',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        {},
        () => {},
      ),
    getOptionLabel,
    filterOptions,
  };

  return loadingSd ? (
    <ABSReportSkeleton />
  ) : (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={LocalComponents}
        />
      )}
    </>
  );
};

export default ABSReport;
