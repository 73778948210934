import React, { useState } from 'react';
import _ from 'lodash';
import cuid from 'cuid';

import { ScreenSmith } from '../ScreenSmith';
import ClaimsSkeleton from './ClaimsSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import OneStopClaimReporting from './OneStopClaimReporting';
import { ClaimsInquiryDetails } from '../ClaimsInquiryDetails';
import { getDataGridColumns, handleSearchOnClick, handleClickPaginationChange } from './Claims.api';

const LocalComponents = { OneStopClaimReporting, ClaimsInquiryDetails };

const ClaimsStatus = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`ClaimsStatusPanel`);

  const [pageNumber, setPageNumber] = useState(1);
  const [results, setResults] = useState(null);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);
  const [currentRange, setCurrentRange] = useState(null);
  const [buttonLoading, setButtonLoading] = useState({ 30: false, 60: false, 90: false, 0: false });
  const [error, setError] = useState();

  const localStateMap = {
    pageNumber,
    dataGridColumns: getDataGridColumns('openClaims', setSelectedClaim),
    dataGridRows: results?.results?.map((row) => ({ ...row, id: cuid() })),
    totalCount: results?.totalCount,
    loadingSearchResults,
    selectedClaim,
    button30Loading: buttonLoading['30'],
    button60Loading: buttonLoading['60'],
    button90Loading: buttonLoading['90'],
    button0Loading: buttonLoading['0'],
    error,
  };

  const callBackFunctions = {
    handleFetchClaims: (e) => {
      const searchByAmount = e.currentTarget.value;
      setButtonLoading((prevState) => ({ ...prevState, [searchByAmount]: true }));
      setCurrentRange(searchByAmount);
      handleSearchOnClick(
        setResults,
        setPageNumber,
        'openClaims',
        'openClaims',
        searchByAmount,
        setLoadingSearchResults,
        () => setButtonLoading((prevState) => ({ ...prevState, [searchByAmount]: false })),
        setError,
      );
    },
    handleClickPaginationChange: (page) =>
      handleClickPaginationChange(
        page,
        setResults,
        'openClaims',
        'openClaims',
        currentRange,
        setLoadingSearchResults,
        setError,
      ),
    handleCloseClaimsInquiryDetails: () => setSelectedClaim(null),
  };

  return loadingSd ? (
    <ClaimsSkeleton />
  ) : (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={LocalComponents}
        />
      )}
    </>
  );
};

export default ClaimsStatus;
