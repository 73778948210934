import moment from 'moment';

import { currencyStringFormatter } from '../helpers';

const dataGridDateFormatter = (params) => {
  return params.value ? moment(params.value).format('MM/DD/YYYY') : '';
};

const dataGridCurrencyFormatter = (params) => {
  return currencyStringFormatter(params.value);
};

export { dataGridDateFormatter, dataGridCurrencyFormatter };
