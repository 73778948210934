import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { AOTypography } from '../../../AOTypography';

import { currencyStringFormatter } from '../../../../helpers';

const PaymentValidation = ({ paymentValidationData }) => {
  const { changeDate, originalAmount, accountNumber, accountName } = paymentValidationData;
  const formattedChangeDate = moment(changeDate).format('MM/DD/YYYY');
  const formattedOriginalAmount = currencyStringFormatter(originalAmount);

  return (
    <Box>
      <Box mb={2} display="flex" flexWrap="wrap">
        <Typography>{`You have requested to reverse the`}</Typography>
        <AOTypography bold text={formattedChangeDate} inline />

        <Typography>{` payment of `}</Typography>
        <AOTypography bold text={formattedOriginalAmount} inline />
      </Box>

      <Box mb={2} display="flex" flexWrap="wrap">
        <Typography>{`We will bill back the insured for the above amount plus a returned check fee and deposit the payment amount plus $10.00 into your agency account.`}</Typography>
      </Box>

      <Box mb={2}>
        <Typography>{`Please review the payment information below. If everything is correct, use the Submit button to finalize your transaction. Otherwise, you may cancel the transaction using the appropriate button.`}</Typography>
      </Box>

      <Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Account Number:</Typography>
          </Box>
          <Typography color="textSecondary">{accountNumber}</Typography>
        </Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Account Name: </Typography>
          </Box>
          <Typography color="textSecondary">{accountName}</Typography>
        </Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Amount: </Typography>
          </Box>
          <Typography color="textSecondary">{formattedOriginalAmount}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

PaymentValidation.propTypes = {
  paymentValidationData: PropTypes.shape({
    changeDate: PropTypes.string,
    originalAmount: PropTypes.string,
    accountNumber: PropTypes.string,
    accountName: PropTypes.string,
  }),
};

export default PaymentValidation;
