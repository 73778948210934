import React, { useState } from 'react';
import cuid from 'cuid';
import { rem } from 'polished';
import { Box, Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const NavigationSkeleton = ({ ...otherProps }) => {
  const [skeletonItems] = useState(Array.from(Array(8).keys()));
  return (
    <Box {...otherProps}>
      <Divider />
      <Box px={`${rem(24)}`} style={{ height: `${rem(50)}` }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{ height: `3.125rem` }}>
          <Box display="flex" justifyContent="flex-start">
            {skeletonItems.map(() => (
              <Skeleton
                key={cuid()}
                width={75}
                style={{ marginRight: `24px`, padding: `6px 8px` }}
                animation="wave"
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationSkeleton;
