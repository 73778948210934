import PropTypes from 'prop-types';

const detailsType = PropTypes.shape({
  index: PropTypes.number,
  dateCreated: PropTypes.string,
  accountNumber: PropTypes.string,
  agentNumber: PropTypes.string,
  name: PropTypes.string,
  paymentAmount: PropTypes.number,
  userId: PropTypes.string,
  method: PropTypes.string,
  cwaPolicy: PropTypes.string,
  source: PropTypes.string,
});

export { detailsType };
