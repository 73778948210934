import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';
import { dataGridCurrencyFormatter } from '../../helpers';
import { GET_REPORTS_URL } from '../../constants/api';

const transactionHistoryColumns = [
  {
    field: 'claimantName1',
    headerName: 'Claimant',
    width: 200,
  },
  {
    field: 'lossTypeDescr',
    headerName: 'Loss Type',
    width: 200,
  },
  {
    field: 'transactionDescr',
    headerName: 'Transaction',
    width: 200,
  },
  {
    field: 'issueDate',
    headerName: 'Issue Date',
    width: 200,
    filterable: false,
    type: 'date',
  },
  {
    field: 'payeeName',
    headerName: 'Payee Name',
    width: 200,
  },
  {
    field: 'checkNumber',
    headerName: 'Check #',
    width: 200,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 200,
    valueFormatter: dataGridCurrencyFormatter,
  },
];

const handleExportToPDFOnClick = (
  policyNumber,
  occurrenceNumber,
  setLoadingPdf,
  setDownloadingPdfError,
  setIsDownloadingPdfErrorVisible,
) => {
  const request_body = {
    Area: 'claims-details',
    DetailedContext: [
      {
        name: 'policyNumber',
        value: policyNumber,
      },
      {
        name: 'occurrence',
        value: occurrenceNumber,
      },
    ],
    TypeOfRedirect: 'Generate-PDF',
  };

  handlePdfDownload(
    'POST',
    request_body,
    GET_REPORTS_URL,
    setLoadingPdf,
    setDownloadingPdfError,
    setIsDownloadingPdfErrorVisible,
    true,
  );
};

export { transactionHistoryColumns, handleExportToPDFOnClick };
