import React from 'react';

import { ViewContainer } from 'components/AORedesign';
import { ROUTE_REDESIGN_RESOURCES } from 'constants/routes';
import { PLUmbrella } from 'components/PLUmbrella';

const PLUmbrellaResources = () => {
  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_RESOURCES, text: 'Resources' }} hideStartQuote hidePolicyViewChange>
      <PLUmbrella />
    </ViewContainer>
  );
};

export default PLUmbrellaResources;
