import React, { useState, useRef } from 'react';

import useFetchSelectMenuItems from 'hooks/useFetchSelectMenuItems';
import APnEReportAgentView from './APnEReportAgentView';
import APnEReportInternalUserView from './APnEReportInternalUserView';

import { GET_APNE_URL } from 'constants/api';
import { filterOptions, getOptionLabel } from 'components/CommonUtilityServices/EventHandlerService';
import {
  defaultYearMonthOption,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
  handleOnChange,
  handleGenerateReportOnClick,
} from './APnEReport.api';
import { getMonthName } from 'helpers';
import { Box, Button, Card, CardContent, CardHeader, Select, Typography, ViewContainer } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';

const APnEReport = () => {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [permAgenciesOptions, setPermAgenciesOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [setIsDownloadingPdfErrorVisible] = useState(false);

  const [formData, setFormData] = useState({
    customerNumber: '',
    locationNumber: '',
    year: '',
  });

  const generateReportFormDataRef = useRef({});

  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    customerNumber: { value: '' },
    locationNumber: { value: '' },
  });

  useFetchSelectMenuItems(`${GET_APNE_URL}/maxDate`, (response) => {
    const { year, month } = response.data;
    const yearOption = { label: `${getMonthName(month)} ${year}`, value: 0 };
    setYearOptions([yearOption, defaultYearMonthOption]);
    setFormData({ ...formData, year: yearOption.value });
    generateReportFormDataRef.current.year = yearOption.value;
  });

  const handleCustomerOnChange = (e, value) => handleAOAutocompleteOnChange('customerNumber', e, value, formData, setFormData, generateReportFormDataRef);
  const handleCustomerOnInputChange = (e, value) =>
    handleAOAutocompleteOnInputChange('customerNumber', e, value, autocompleteInputValues, setAutocompleteInputValues, generateReportFormDataRef);
  const handleCustomerOnBlur = () => handleAOAutocompleteOnBlur('customerNumber', formData, setFormData, autocompleteInputValues);
  const handleLocationOnChange = (e, value) => handleAOAutocompleteOnChange('locationNumber', e, value, formData, setFormData, generateReportFormDataRef);
  const handleLocationOnInputChange = (e, value) =>
    handleAOAutocompleteOnInputChange('locationNumber', e, value, autocompleteInputValues, setAutocompleteInputValues, generateReportFormDataRef);
  const handleLocationOnBlur = () => handleAOAutocompleteOnBlur('locationNumber', formData, setFormData, autocompleteInputValues);
  const handleOnChangeProxy = (e) => handleOnChange(e, formData, setFormData, generateReportFormDataRef);
  const handleGenerateReportOnClickProxy = () =>
    handleGenerateReportOnClick(generateReportFormDataRef, setErrorMessage, setLoadingPdf, setIsDownloadingPdfErrorVisible, permAgenciesOptions);

  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
      <Feature allowed="Access_AP&E_Report">
        <Card>
          <CardHeader title="AP&E Report" />
          <CardContent>
            {errorMessage && (
              <Box mb={2}>
                <Typography color="error">{errorMessage}</Typography>
              </Box>
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleGenerateReportOnClickProxy();
              }}>
              <Feature allowed="internalUser">
                <APnEReportInternalUserView
                  filterOptions={filterOptions}
                  getOptionLabel={getOptionLabel}
                  handleCustomerOnBlur={handleCustomerOnBlur}
                  handleCustomerOnChange={handleCustomerOnChange}
                  handleCustomerOnInputChange={handleCustomerOnInputChange}
                  handleLocationOnBlur={handleLocationOnBlur}
                  handleLocationOnChange={handleLocationOnChange}
                  handleLocationOnInputChange={handleLocationOnInputChange}
                  setCustomerOptions={setCustomerOptions}
                  setLocationOptions={setLocationOptions}
                  customerNumber={formData.customerNumber}
                  customerNumberInputValue={autocompleteInputValues.customerNumber.value}
                  customerOptions={customerOptions}
                  locationNumber={formData.locationNumber}
                  locationNumberInputValue={autocompleteInputValues.locationNumber.value}
                  locationOptions={locationOptions}
                />
              </Feature>
              <Feature allowed="isAgent">
                <APnEReportAgentView
                  onChange={handleOnChangeProxy}
                  setPermAgenciesOptions={setPermAgenciesOptions}
                  menuItems={permAgenciesOptions}
                  value={formData.locationNumber}
                />
              </Feature>
              <Box mb={2}>
                <Select
                  onChange={handleOnChangeProxy}
                  value={formData.year}
                  menuItems={yearOptions}
                  id="apne-year"
                  labelId="apne-year-label"
                  label="Month/Year"
                  name="year"
                />
              </Box>
              <Box mb={4}>
                <Button
                  id="apne-generate-report-button"
                  onClick={handleGenerateReportOnClickProxy}
                  disabled={formData.year === '' || (!formData.locationNumber && permAgenciesOptions.length) || loadingPdf}
                  loading={loadingPdf}
                  variant="contained"
                  color="primary">
                  Generate Report
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Feature>
    </ViewContainer>
  );
};

export default APnEReport;
