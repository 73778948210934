import React from 'react';
import PropTypes from 'prop-types';

import RowCol from '../ScreenSmith/RowCol';
import { AOSelect } from '../AOSelect';
import { GET_PERM_AGENCIES } from '../../constants/api';
import useFetchSelectMenuItems from '../../hooks/useFetchSelectMenuItems';

const CCReportAgentView = ({ menuItems, onChange, setPermAgenciesOptions, value }) => {
  useFetchSelectMenuItems(GET_PERM_AGENCIES, (response) => {
    if (response.data.length) {
      setPermAgenciesOptions([
        ...response.data.map((agency) => ({
          value: `${agency.agencyName}-${agency.agencyCode}`,
          code: agency.agencyCode,
        })),
      ]);
    }
  });

  return (
    <RowCol mb={2}>
      <AOSelect
        id="cc-agency-code-id"
        labelId="cc-agency-code-id-label"
        inputLabel="Agency Code"
        name="agencyCode"
        menuItems={menuItems}
        onChange={onChange}
        value={value}
      />
    </RowCol>
  );
};

CCReportAgentView.propTypes = {
  menuItems: PropTypes.array,
  onChange: PropTypes.func,
  setPermAgenciesOptions: PropTypes.func,
  value: PropTypes.string,
};

export default CCReportAgentView;
