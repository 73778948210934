import React from 'react';

import { Typography } from '@material-ui/core';
import { AOCard } from 'components/AOCard';
import { LegacyLink } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import { ViewContainer } from 'components/Layout';
import { Col, Row, RowCol } from 'components/ScreenSmith';
import featureFlags from 'constants/featureFlags';

const AppAdmin = () => {
  return (
    <Feature allowed={featureFlags.ACCESS_APP_ADMIN}>
      <ViewContainer title={'Application Administration'}>
        <RowCol mb={2}>
          <AOCard>
            <Row spacing={4}>
              <Col xs={4}>
                <Typography color="textSecondary">
                  The links to the right offer access to administrative resources needed to update various application information.
                </Typography>
              </Col>
              <Col xs={8}>
                <Feature allowed={featureFlags.ACCESS_CONTENT_MGMT_ADMIN}>
                  <RowCol mb={4}>
                    <LegacyLink routeLegacy={'/news_article_adm'} style={{ fontSize: '1rem' }} endIcon="openInNew">
                      MSA News Articles
                    </LegacyLink>
                    <Typography color="textSecondary">Manage news articles found on MSA Group.</Typography>
                  </RowCol>
                </Feature>
                <Feature allowed={featureFlags.ACCESS_FIND_AGENT}>
                  <RowCol mb={4}>
                    <LegacyLink routeLegacy={'/agent_mgmt'} style={{ fontSize: '1rem' }} endIcon="openInNew">
                      Find an Agent
                    </LegacyLink>
                    <Typography color="textSecondary">From this screen you may update contact and location information for agents.</Typography>
                  </RowCol>
                </Feature>
                <Feature allowed={featureFlags.ACCESS_RESOURCE_MGMT_ADMIN}>
                  <RowCol mb={4}>
                    <LegacyLink routeLegacy={'/resourceMgmt'} style={{ fontSize: '1rem' }} endIcon="openInNew">
                      Resource Mgmt
                    </LegacyLink>
                    <Typography color="textSecondary">Control access to Agents Only site resources/applications.</Typography>
                  </RowCol>
                </Feature>
                <Feature allowed={featureFlags.ACCESS_STATE_ROLLOUT_ADMIN}>
                  <RowCol mb={4}>
                    <LegacyLink routeLegacy={'/app_navigation_control'} style={{ fontSize: '1rem' }} endIcon="openInNew">
                      State Rollout Administration
                    </LegacyLink>
                    <Typography color="textSecondary">
                      Control the navigation to various applications from this page. You will be able to manage application rollout by Line of Business, State
                      or Location.
                    </Typography>
                  </RowCol>
                </Feature>
              </Col>
            </Row>
          </AOCard>
        </RowCol>
      </ViewContainer>
    </Feature>
  );
};

export default AppAdmin;
