/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import cuid from 'cuid';

import BMReportForm from './BMReportForm';
import BMReportExternal from './BMReportExternal';
import BMReportInternal from './BMReportInternal';
import { useParams } from 'react-router-dom';

import { defaultLOB } from './BMReportForm';
import { Feature } from '../../../Feature';

import { Box, Button, Card, CardHeader, Col, DataGrid, Row, Select, Typography, ViewContainer } from 'components/AORedesign';
import { dataGridCurrencyFormatter, dataGridDateFormatter } from '../../../../helpers';
import { handlePdfDownload } from '../../../CommonUtilityServices/DownloadPdfService';
import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';
import featureFlags from 'constants/featureFlags';
import { handleFromDateOnChange, handleOnChange, handleToDateOnChange } from '../../../CommonUtilityServices/EventHandlerService';
import { AOAlert } from '../../../AOAlert';
import { GET_BMR_URL, GET_REPORTS_URL } from 'constants/api';

const reportResultsColumns = [
  {
    field: 'ProcessDate',
    headerName: 'Process Date',
    sortable: true,
    width: 200,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'Company',
    headerName: 'Company',
    sortable: true,
    width: 200,
  },
  {
    field: 'AgentNumber',
    headerName: 'Agent Number',
    sortable: true,
    width: 200,
  },
  {
    field: 'PolicyNumber',
    headerName: 'Policy Number',
    sortable: true,
    width: 200,
  },
  {
    field: 'LastName',
    headerName: 'Last Name',
    sortable: true,
    width: 200,
  },
  {
    field: 'Name1',
    headerName: 'Insured Name',
    sortable: true,
    width: 200,
  },
  {
    field: 'Name2',
    headerName: 'Insured Name 2',
    sortable: true,
    width: 200,
  },
  {
    field: 'Phone',
    headerName: 'Phone Number',
    sortable: true,
    width: 200,
  },
  {
    field: 'Addr1',
    headerName: 'Address 1',
    sortable: true,
    width: 200,
  },
  {
    field: 'Addr2',
    headerName: 'Address 2',
    sortable: true,
    width: 200,
  },
  {
    field: 'Addr_City',
    headerName: 'City',
    sortable: true,
    width: 200,
  },
  {
    field: 'Addr_State',
    headerName: 'State',
    sortable: true,
    width: 200,
  },
  {
    field: 'Addr_ZIP',
    headerName: 'Zip',
    sortable: true,
    width: 200,
  },
  {
    field: 'AccountNumber',
    headerName: 'Account Number',
    sortable: true,
    width: 200,
  },
  {
    field: 'PolicyTypeDescr',
    headerName: 'Line of Business',
    sortable: true,
    width: 200,
  },
  {
    field: 'LinesInAccount',
    headerName: 'All Lines',
    sortable: true,
    width: 200,
  },
  {
    field: 'RenewalPremium',
    headerName: 'New Written Premium',
    sortable: true,
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
  {
    field: 'WrittenPremium',
    headerName: 'Current Written Premium',
    sortable: true,
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
  {
    field: 'PremiumChange',
    headerName: 'Premium Change',
    sortable: true,
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
  {
    field: 'PolicyEffectiveDate',
    headerName: 'Effective Date',
    sortable: true,
    width: 200,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'PolicyExpirationDate',
    headerName: 'Expiration Date',
    sortable: true,
    width: 200,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'CancelDate',
    headerName: 'Cancel Date',
    sortable: true,
    width: 200,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'CancReasonDescr',
    headerName: 'Cancel Reason',
    sortable: true,
    width: 200,
  },
  {
    field: 'MinimumDue',
    headerName: 'Payment Due',
    sortable: true,
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
  {
    field: 'PayMethodDescr',
    headerName: 'Payment Method',
    sortable: true,
    width: 200,
  },
  {
    field: 'RenewedIndicator',
    headerName: 'Renewed?',
    sortable: true,
    width: 200,
  },
  {
    field: 'BusinessInd',
    headerName: 'New Gen',
    sortable: true,
    width: 200,
  },
  {
    field: 'OldTier',
    headerName: 'Old Prog/.Tier',
    sortable: true,
    width: 200,
  },
  {
    field: 'NewTier',
    headerName: 'New Prog/.Tier',
    sortable: true,
    width: 200,
  },
  {
    field: 'ActionInd',
    headerName: 'Adverse Action Indicator',
    sortable: true,
    width: 200,
  },
  {
    field: 'Reason',
    headerName: 'Reason for Adverse Action',
    sortable: true,
    width: 200,
  },
];

const BookMgmtReports = () => {
  const { reportType } = useParams();
  const [agencyCodeOptions, setAgencyCodeOptions] = useState([]);
  const [reportResultsData, setReportResultsData] = useState(null);
  const [isAgentLookupModalOpen, setIsAgentLookupModalOpen] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [exportRangeMenuItems, setExportRangeMenuItems] = useState([]);
  const [exportRangeValue, setExportRangeValue] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    lob: defaultLOB,
    fromDate: null,
    toDate: null,
    showAddress: false,
    agencyCodes: '',
    eftChoice: '0',
    stateNumericCode: '',
    region: '',
    onlyNonRenew: 'false',
  });

  const reportTitles = {
    bookmgmtnonpaypolicy: 'Non-Pay Notice Due',
    bookmgmtcancelledpolicy: 'Canceled Policies',
    bookmgmtexpiringpolicy: 'Expiring Policies',
    bookmgmtinstallmentdue: 'Installment Due',
    bookmgmtpaymentmethod: 'Payment Method',
    bookmgmtpolicystatus: 'Policy Status',
  };

  const reportTitle = reportTitles[reportType.replace(/-/g, '')];
  const generateReportFormDataRef = useRef({});

  useEffect(() => {
    resetGrid();
    generateReportFormDataRef.current = {};
  }, [formData]);

  const resetGrid = () => {
    setReportResultsData(null);
    setErrorMessage(null);
  };

  const handleCreateReport = async (pageNumber = 1) => {
    const getDataUrl = `${GET_BMR_URL}/get-data`;
    setSubmitButtonLoading(true);

    try {
      const bMReportData = await axios.post(getDataUrl, {
        ...formData,
        pageNumber,
        reportType,
        lob: formData.lob === 'All' ? '' : formData.lob,
        stateNumericCode: formData.stateNumericCode === '' ? null : formData.stateNumericCode,
      });
      if (bMReportData.data.status) {
        setReportResultsData(bMReportData.data.data ? bMReportData.data : []);

        const pageSize = 500;
        const exportPages = Math.ceil(bMReportData.data.total / pageSize);
        const exportRangeMenuItems = [];
        for (let i = 1; i <= exportPages; i++) {
          const endRange = Math.min(bMReportData.data.total, i * pageSize);
          exportRangeMenuItems.push({ label: `${(i - 1) * pageSize + 1}-${endRange}`, value: i });
        }
        setExportRangeMenuItems(exportRangeMenuItems);
        setExportRangeValue(1);
      } else {
        setErrorMessage(bMReportData.data.message);
      }
    } catch (e) {
      console.error(e);
      setReportResultsData(null);
      setErrorMessage(e.message);
    }
    setSubmitButtonLoading(false);
  };

  const handleClickPaginationChange = (page) => {
    handleCreateReport(page + 1);
  };

  const handleOnSortModelChange = (sortData) => {
    generateReportFormDataRef.current.sortColumn = sortData[0]?.field;
    generateReportFormDataRef.current.sortOrder = sortData[0]?.sort;
  };

  const getGenerateReportRequestBody = (requestBody) => {
    if (requestBody.sortColumn === undefined) {
      requestBody.sortColumn = '';
    }
    if (requestBody.sortOrder === undefined) {
      requestBody.sortOrder = '';
    }
    let detailedContext = [];
    for (const key in requestBody) {
      detailedContext.push({
        name: key,
        value: requestBody[key],
      });
    }
    return {
      Area: 'book-management',
      DetailedContext: detailedContext,
      TypeOfRedirect: 'Generate-PDF',
    };
  };

  const handleGenerateReportFile = async (exportType) => {
    const requestBody = {
      ...formData,
      pageNumber: exportRangeValue,
      reportType,
      lob: formData.lob === 'All' ? '' : formData.lob,
      stateNumericCode: formData.stateNumericCode === '' ? null : formData.stateNumericCode,
      ...generateReportFormDataRef.current,
      exportType,
    };

    if (exportType === 'pdf') {
      handlePdfDownload(
        'POST',
        getGenerateReportRequestBody(requestBody),
        GET_REPORTS_URL,
        () => {},
        () => {},
        () => {},
        'newWindow',
      );
    } else {
      const getReportUrl = `${GET_BMR_URL}/get-data`;
      try {
        const bMReportData = await axios.post(getReportUrl, requestBody);
        if (bMReportData?.data?.status) {
          const columnHeaders = [...reportResultsColumns.map((col) => col.headerName)];
          const csvString = [
            columnHeaders,
            ...bMReportData.data.data.map((item) => {
              const rowItem = [];
              for (const column of reportResultsColumns) {
                rowItem.push(item[column.field]);
              }
              return rowItem;
            }),
          ];

          const csvContent = csvString.map((e) => e.join(',')).join('\n');
          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);

          const downloadLink = document.createElement('a');
          downloadLink.setAttribute('href', url);
          downloadLink.download = `${cuid()}.csv`;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleGeneratePDFOnClick = () => {
    handleGenerateReportFile('pdf');
  };
  const handleGenerateExcelOnClick = () => {
    handleGenerateReportFile('jsonForReport');
  };

  return (
    <>
      {reportTitle && (
        <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
          <Feature allowed={featureFlags.ACCESS_MANAGE_MSA_BOOK_REPORT}>
            <Box>
              <Card>
                <CardHeader title={reportTitle} />
                <Box p={2}>
                  <BMReportForm
                    handleLOBOnChange={(e) => handleOnChange(e, formData, setFormData)}
                    handleFromDateOnChange={(date) => handleFromDateOnChange(date, formData, setFormData)}
                    handleToDateOnChange={(date) => handleToDateOnChange(date, formData, setFormData)}
                    handleShowAddressOnChange={(e) => setFormData((prevState) => ({ ...prevState, showAddress: e.target.checked }))}
                    handleEftChoiceOnChange={(e) => handleOnChange(e, formData, setFormData)}
                    handleStateNumericCodeOnChange={(e) => handleOnChange(e, formData, setFormData)}
                    handleRegionOnChange={(e) => handleOnChange(e, formData, setFormData)}
                    handleOnlyNonRenewOnChange={(e) => handleOnChange(e, formData, setFormData)}
                    reportType={reportType}
                    lobValue={formData.lob}
                    fromDateValue={formData.fromDate}
                    toDateValue={formData.toDate}
                    showAddress={formData.showAddress}
                    eftChoiceValue={formData.eftChoice}
                    onlyNonRenewValue={formData.onlyNonRenew}
                    stateNumericCodeValue={formData.stateNumericCode}
                    regionValue={formData.region}
                  />
                  {reportType !== '' && (
                    <>
                      <Feature allowed={featureFlags.ACCESS_USER_IS_AGENT}>
                        <Box>
                          <BMReportExternal
                            onChange={(e) => handleOnChange(e, formData, setFormData)}
                            setAgencyCodeOptions={setAgencyCodeOptions}
                            menuItems={agencyCodeOptions}
                            value={formData.agencyCodes}
                          />
                        </Box>
                      </Feature>
                      <Feature allowed={featureFlags.ACCESS_USER_INTERNAL}>
                        <Box>
                          <BMReportInternal
                            handleSetAgencyCodeOnChangeInternal={(e) => handleOnChange(e, formData, setFormData)}
                            setAgentCode={(value) => setFormData((prevState) => ({ ...prevState, agencyCodes: value }))}
                            setOpen={setIsAgentLookupModalOpen}
                            open={isAgentLookupModalOpen}
                            value={formData?.agencyCodes}
                          />
                        </Box>
                      </Feature>
                      <Box mt={3}>
                        <Button onClick={() => handleCreateReport()} loading={submitButtonLoading} color="primary" variant="contained">
                          Submit
                        </Button>
                      </Box>
                    </>
                  )}
                  {errorMessage && <AOAlert message={errorMessage} severity="error" />}
                  {reportResultsData?.total > 0 && (
                    <Box mt={3} mb={2}>
                      <Row spacing={2} alignItems="center">
                        <Col>
                          <Select
                            onChange={(e) => setExportRangeValue(e.target.value)}
                            value={exportRangeValue}
                            menuItems={exportRangeMenuItems}
                            id="book-management-export-select"
                            labelId="book-management-export-select-label"
                            label="Export Range"
                            name="export-range"
                          />
                        </Col>
                        <Col>
                          <Button id="book-management-export-pdf-report" onClick={handleGeneratePDFOnClick} variant="contained" color="primary">
                            Generate PDF Report
                          </Button>
                        </Col>
                        <Col>
                          <Button id="book-management-export-excel-report" onClick={handleGenerateExcelOnClick} variant="contained" color="primary">
                            Generate Excel Report
                          </Button>
                        </Col>
                      </Row>
                    </Box>
                  )}
                  <Box mt={3}>
                    <DataGrid
                      onSortModelChange={handleOnSortModelChange}
                      onPageChange={handleClickPaginationChange}
                      rows={reportResultsData?.data?.map((el) => ({
                        id: cuid(),
                        ...el,
                      }))}
                      columns={reportResultsColumns}
                      rowCount={reportResultsData?.total}
                      loading={submitButtonLoading}
                      pageSize={20}
                      rowsPerPageOptions="[]"
                      paginationMode="server"
                    />
                  </Box>
                </Box>
              </Card>
            </Box>
          </Feature>
        </ViewContainer>
      )}
    </>
  );
};

export default BookMgmtReports;
