import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import cuid from 'cuid';

import { ScreenSmith } from '../../ScreenSmith';
import BillingServicesReportsPaymentsMadeSkeleton from './BillingServicesReportsPaymentsMadeSkeleton';

import useScreenDefinition from '../../../hooks/useScreenDefinition';
import useFetchSelectMenuItems from '../../../hooks/useFetchSelectMenuItems';

import {
  GET_BILL_PAYMENT_REPORT_DATE_SELECTIONS,
  GET_BILL_PAYMENT_REPORT_AMOUNT_SELECTIONS,
  GET_BILL_PAYMENT_REPORT_TRANSACTION_TYPES,
  GET_BILL_PAYMENT_REPORT_EFT,
} from '../../../constants/api';

import {
  handleChangeFormData,
  fetchAgentCodeSuggestions,
  fetchReportContent,
  fetchEnteredBySuggestions,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
  handleDateOnChange,
  handleTextFieldOnChange,
  reportContentColumns,
  handleClickChangeReport,
} from './BillingServicesReports.api';

const BillingServicesReportsPaymentsMade = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(
    `BillingServicesReportsPaymentsMade`,
  );
  const [paymentsDateRangeOptions, setPaymentsDateRangeOptions] = useState(['']);
  const [amountTypeOptions, setAmountTypeOptions] = useState(['']);
  const [transactionTypeOptions, setTransactionTypeOptions] = useState(['']);
  const [eftTypeOptions, setEftTypeOptions] = useState(['']);
  const [agentCodeOptions, setAgentCodeOptions] = useState([]);
  const [enteredByOptions, setEnteredByOptions] = useState([]);
  const [errorDownloadingPdf, setErrorDownloadingPdf] = useState(false);
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    agentCode: { value: '', invalidText: '' },
    enteredBy: { value: '', invalidText: '' },
  });
  const [formData, setFormData] = useState({
    agentCode: 'All',
    amountFrom: '',
    amountTo: '',
    amountType: '0',
    eftType: '0',
    enteredBy: 'All',
    fromDate: null,
    paymentsDateRange: 'Today',
    toDate: null,
    transactionType: '0',
  });
  const [reportContentData, setReportContentData] = useState({
    isVisible: true,
    rows: null,
    columns: reportContentColumns(setErrorDownloadingPdf),
    loading: false,
  });
  const [alertData, setAlertData] = useState({
    message: 'No Payments were made in the specified date range.',
    severity: 'info',
    isVisible: false,
  });

  const rawCustomRangeMinDate = moment().subtract(1, 'month').calendar();
  const customRangeMinDate = moment(rawCustomRangeMinDate).format('YYYY-MM-DD');
  const customRangeMaxDate = moment().format('YYYY-MM-DD');

  const localStateMap = {
    agentCode: formData.agentCode,
    agentCodeInputValue: autocompleteInputValues.agentCode.value,
    agentCodeInvalidText: autocompleteInputValues.agentCode.invalidText,
    agentCodeOptions,
    enteredBy: formData.enteredBy,
    enteredByInputValue: autocompleteInputValues.enteredBy.value,
    enteredByInvalidText: autocompleteInputValues.enteredBy.invalidText,
    enteredByOptions,
    amountFrom: formData.amountFrom,
    amountTo: formData.amountTo,
    amountType: formData.amountType,
    amountTypeOptions,
    eftType: formData.eftType,
    eftTypeOptions,
    errorDownloadingPdf,
    fromDate: formData.fromDate,
    fromDateMin: customRangeMinDate,
    fromDateMax: customRangeMaxDate,
    toDateMin: customRangeMinDate,
    toDateMax: customRangeMaxDate,
    paymentsDateRange: formData.paymentsDateRange,
    paymentsDateRangeOptions,
    toDate: formData.toDate,
    transactionType: formData.transactionType,
    transactionTypeOptions,
    customRangeIsVisible: formData.paymentsDateRange === 'Custom+Range',
    reportContent: reportContentData.rows,
    reportContentColumns: reportContentData.columns,
    reportContentIsVisible: reportContentData.isVisible,
    reportContentLoading: reportContentData.loading,
    alertIsVisible: alertData.isVisible,
    alertMessage: alertData.message,
    alertSeverity: alertData.severity,
  };

  const callBackFunctions = {
    handleOnChangeEftType: (e) => handleChangeFormData(e, 'eftType', formData, setFormData),
    handleOnChangePaymentsDateRange: (e) =>
      handleChangeFormData(e, 'paymentsDateRange', formData, setFormData),
    handleOnChangeAmountType: (e) => handleChangeFormData(e, 'amountType', formData, setFormData),
    handleOnChangeTransactionType: (e) =>
      handleChangeFormData(e, 'transactionType', formData, setFormData),
    handleOnChangeAgentCode: (e, value) =>
      handleAOAutocompleteOnChange('agentCode', e, value, formData, setFormData),
    handleOnChangeEnteredBy: (e, value) =>
      handleAOAutocompleteOnChange('enteredBy', e, value, formData, setFormData),
    handleOnInputChangeAgentCode: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'agentCode',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
      ),
    handleOnInputChangeEnteredBy: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'enteredBy',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
      ),
    handleOnBlurAgentCode: () =>
      handleAOAutocompleteOnBlur('agentCode', formData, setFormData, autocompleteInputValues),
    handleOnBlurEnteredBy: () =>
      handleAOAutocompleteOnBlur('enteredBy', formData, setFormData, autocompleteInputValues),
    handleOnChangeFromDate: (date) => handleDateOnChange(date, 'fromDate', formData, setFormData),
    handleOnChangeToDate: (date) => handleDateOnChange(date, 'toDate', formData, setFormData),
    handleOnChangeAmountFrom: (e) => handleTextFieldOnChange('amountFrom', e, setFormData),
    handleOnChangeAmountTo: (e) => handleTextFieldOnChange('amountTo', e, setFormData),
    handleClickChangeReport: () =>
      handleClickChangeReport(formData, setReportContentData, setAlertData),
  };

  // fetch options for date range dropdown
  useFetchSelectMenuItems(GET_BILL_PAYMENT_REPORT_DATE_SELECTIONS, (response) => {
    setPaymentsDateRangeOptions(
      response.data.map((option) => ({
        id: cuid(),
        value: option,
        code: setPaymentsDateRangeCode(option),
      })),
    );
  });

  const setPaymentsDateRangeCode = (option) => {
    return option.split(' ').join('+');
  };

  // fetch options for amount type dropdown
  useFetchSelectMenuItems(GET_BILL_PAYMENT_REPORT_AMOUNT_SELECTIONS, (response) => {
    setAmountTypeOptions(
      response.data.map(({ name, value }) => ({
        id: cuid(),
        value: name,
        code: value,
      })),
    );
  });

  // fetch options for transaction types dropdown
  useFetchSelectMenuItems(GET_BILL_PAYMENT_REPORT_TRANSACTION_TYPES, (response) => {
    setTransactionTypeOptions(
      response.data.map(({ name, value }) => ({
        id: cuid(),
        value: name,
        code: value,
      })),
    );
  });

  // fetch options for agent codes autocomplete
  useEffect(async () => {
    const suggestions = await fetchAgentCodeSuggestions().catch((err) => {
      console.error(err);
    });
    setAgentCodeOptions(_.isNil(suggestions) ? [] : suggestions);
  }, []);

  // fetch options for entered by autocomplete
  useEffect(async () => {
    const suggestions = await fetchEnteredBySuggestions().catch((err) => {
      console.error(err);
    });
    setEnteredByOptions(_.isNil(suggestions) ? [] : suggestions);
  }, []);

  // fetch options for EFT dropdown
  useFetchSelectMenuItems(GET_BILL_PAYMENT_REPORT_EFT, (response) => {
    setEftTypeOptions(
      response.data.map(({ name, value }) => ({
        id: cuid(),
        value: name,
        code: value,
      })),
    );
  });

  // fetch report content
  useEffect(async () => {
    const data = await fetchReportContent(null, true).catch((err) => {
      console.error(err);
    });
    const dataGridRows = _.isNil(data) ? [] : data;
    if (!_.isNil(dataGridRows) && dataGridRows.length < 1) {
      setAlertData((prevVal) => ({ ...prevVal, isVisible: true }));
      setReportContentData((prevVal) => ({ ...prevVal, rows: null, isVisible: false }));
    } else {
      setReportContentData((prevVal) => ({ ...prevVal, isVisible: true, rows: dataGridRows }));
    }
  }, []);

  if (loadingSd) return <BillingServicesReportsPaymentsMadeSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default BillingServicesReportsPaymentsMade;
