import { Link } from 'components/AORedesign';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

const RouteLink = ({ route, ...props }) => {
  const history = useHistory();

  const handleGoToRoute = () => {
    history.push(route);
  };

  return (
    <Link onClick={handleGoToRoute} {...props}>
      {props.children}
    </Link>
  );
};

RouteLink.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.string,
};

export default RouteLink;
