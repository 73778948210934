import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';

import { componentIdStringGenerator } from '../../../helpers';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      bottom: '-1.5rem',
    },
  },
}));

const TextField = (props) => {
  const classes = useStyles();

  return (
    <MuiTextField
      variant="outlined"
      className={classes.root}
      {...props}
      id={componentIdStringGenerator('TextField', props.id ? props.id : props.label ?? '')}
    />
  );
};

TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};

export default TextField;
