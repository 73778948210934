import React from 'react';
import PropTypes from 'prop-types';

import { StyledAOLinkButton } from './AOLinkButton.styled';

const AOLinkButton = ({ id, ...props }) => {
  return (
    <StyledAOLinkButton
      id={`ao-link-button-${props.children.toString().toLowerCase().split(' ').join('-')}${
        id?.length ? `-${id}` : ''
      }`}
      {...props}
    />
  );
};

AOLinkButton.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};

export default AOLinkButton;
