import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Typography, RadioGroup, Button } from '@material-ui/core';

import { RowCol, Row, Col } from '../../../../ScreenSmith';
import { AORadio } from '../../../../AORadio';
import { AOTextField } from '../../../../AOTextField';
import { adjustCurrentPayment, removeCurrentPayment } from './ActionOnPayment.api';
import { currencyStringFormatter } from '../../../../../helpers';

const ActionOnPayment = ({ amount, index, handleClose }) => {
  const [radioData, setRadioData] = useState({
    selectedValue: '',
    alert: {
      isActive: false,
      severity: 'error',
      message: '',
    },
  });
  const [adjustData, setAdjustData] = useState({
    id: index,
    amount: amount,
    reason: '',
  });


  const handleOnChangeRadio = (e) => {
    const {
      target: { value },
    } = e;
    setRadioData((prevValue) => ({
      ...prevValue,
      selectedValue: value,
    }));
  };

  const handleOnChangeAdjust = (e) => {
    const {
      target: { name, value },
    } = e;
    setAdjustData((prevValue) => ({
      ...prevValue,
      [`${name}`]: value,
    }));
  };

  const handleClickUpdateAdjust = async () => {
    console.log({ adjustData });
    const adjustConfirmMessage = "You are about to change this payment.\n\nAre you sure you want to proceed?"
    const removeConfirmMessage = "You are about to remove this payment.\n\nThis action cannot be undone.\n\nAre you sure you want to proceed?"
    var confirmMsg = radioData.selectedValue == 'adjust' ? adjustConfirmMessage : removeConfirmMessage;
    var selecteAnswer = confirm(confirmMsg);
    try {
      if (selecteAnswer) {

        if (radioData.selectedValue == 'adjust') {
          await adjustCurrentPayment(adjustData);
        } else {
          await removeCurrentPayment(adjustData)
        }
      }
      handleClose();
    } catch (error) {
      window.alert(error)
    }
  };
  const formattedAdjustedAmount = currencyStringFormatter(adjustData.amount);
  return (
    <>
      <Row mb={2}>
        <Col xs={12} md={2}></Col>
        <Col xs={12} md={10}>
          <RowCol>
            <Typography>
              <strong>Action on Payment</strong>
            </Typography>
          </RowCol>
          <RowCol>
            <FormControl>
              <RadioGroup row value={radioData.selectedValue} onChange={handleOnChangeRadio}>
                <AORadio id="adjust-action-on-payment" value="adjust" label="Adjust" />
                <AORadio id="remove-action-on-payment" value="remove" label="Remove" />
              </RadioGroup>
            </FormControl>
          </RowCol>
        </Col>
      </Row>
      {!radioData.selectedValue && <Row>
        <Col xs={12} md={2}></Col>
        <Col xs={12} md={10}>
          <Typography>{
            `Amount: ${formattedAdjustedAmount}`}</Typography>
        </Col>
        <Col xs={12} md={2}></Col>
      </Row>
      }
      <Row>
        <Col xs={12} md={2}></Col>
        <Col xs={12} md={10}>
          {radioData.selectedValue === 'adjust' && (
            <RowCol>
              <RowCol mb={2}>
                <AOTextField
                  id="adjust-amount"
                  label="Amount"
                  name="amount"
                  value={adjustData.amount}
                  onChange={handleOnChangeAdjust}
                  size="small"
                  data-testid="adjust-amount"
                />
              </RowCol>
              <RowCol mb={2}>
                <AOTextField
                  id="adjust-reason"
                  label="Reason"
                  name="reason"
                  value={adjustData.reason}
                  onChange={handleOnChangeAdjust}
                  size="small"
                  multiline
                  minRows={4}
                  helperText="Limit reason to 100 chars"
                />
              </RowCol>
              <RowCol>
                <Button variant="contained" color="primary" onClick={handleClickUpdateAdjust}>
                  Update
                </Button>
              </RowCol>
            </RowCol>
          )}
          {radioData.selectedValue === 'remove' && (
            <RowCol>
              <RowCol mb={2}>
                <AOTextField
                  id="remove-amount"
                  label="Amount"
                  name="amount"
                  value={adjustData.amount}
                  onChange={handleOnChangeAdjust}
                  size="small"
                  data-testid="remove-amount"
                  disabled
                />
              </RowCol>
              <RowCol mb={2}>
                <AOTextField
                  id="remove-reason"
                  label="Reason"
                  name="reason"
                  value={adjustData.reason}
                  onChange={handleOnChangeAdjust}
                  size="small"
                  multiline
                  minRows={4}
                  helperText="Limit reason to 100 chars"
                />
              </RowCol>
              <RowCol>
                <Button variant="contained" color="primary" onClick={handleClickUpdateAdjust}>
                  Update
                </Button>
              </RowCol>
            </RowCol>
          )}
        </Col>
      </Row>
    </>
  );
};

ActionOnPayment.propTypes = {
  amount: PropTypes.number,
  index: PropTypes.number,
  handleClose: PropTypes.func
};

export default ActionOnPayment;
