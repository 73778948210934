import React, { useState } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import { QuickReferenceCard } from '../QuickReferenceCard';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';

import { StyledHomePage } from './HomePage.styled';

const HomePageComponents = {
  QuickReferenceCard,
};

const HomePage = () => {
  // Screen def
  const { UiDefinitionsData } = useScreenDefinition('HomePage');

  const [errorMessage, setErrorMessage] = useState('');
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);

  const localStateMap = {
    errorMessage,
    loadingPdf,
    isDownloadingPdfErrorVisible,
  };

  const callBackFunctions = {
    handleOnClickOpenPDFForm: (e) => {
      const pdfUrl = e.currentTarget.value;

      handlePdfDownload(
        'GET',
        null,
        pdfUrl,
        setLoadingPdf,
        setErrorMessage,
        setIsDownloadingPdfErrorVisible,
        true,
      );
    },
  };

  return (
    !_.isNil(UiDefinitionsData) && (
      <StyledHomePage>
        <ScreenSmith
          componentMap={HomePageComponents}
          definition={UiDefinitionsData}
          stateMap={localStateMap}
          functionMap={callBackFunctions}
        />
      </StyledHomePage>
    )
  );
};

export default HomePage;
