// Node Modules
import NewWindow from 'react-new-window';

// MUI components
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Link,
  Modal,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DataGrid } from '@mui/x-data-grid';

// AO Custom components
import { AOAlert } from '../AOAlert';
import { AOAutocomplete, AOAppetiteAutocomplete } from '../AOAutocomplete';
import { AOBox } from '../AOBox';
import { AOButton } from '../AOButton';
import { AOCard } from '../AOCard';
import { AOCheckbox } from '../AOCheckbox';
import { AOCircularProgress } from '../AOCircularProgress';
import { AODataGrid } from '../AODataGrid';
import { AODatePicker } from '../AODatePicker';
import { AOGroupedSelect } from '../AOGroupedSelect';
import { AOIconButton } from '../AOIconButton';
import { AOLinkButton } from '../AOLinkButton';
import { AOProductCard } from '../AOProductCard';
import { AORadio } from '../AORadio';
import { AOSelect } from '../AOSelect';
import { AOTextField } from '../AOTextField';
import { AOTransferList } from '../AOTransferList';
import { AOTypography } from '../AOTypography';
import { APIRequestHandler } from '../APIRequestHandler';
import { AppetiteServiceUnavailable } from '../AppetiteServiceUnavailable';
import { CompoundLinkAndText } from '../CompoundLinkAndText';
import { DocSearchCustomerInfo } from '../DocSearchCustomerInfo';
import { FlexWrap, FlexWrapElementSpacer } from '../FlexWrap';
import { Headline } from '../Headline';
import { Navigation } from '../Navigation';
import { NewsArticles } from '../NewsArticles';
import { TabPanel } from '../TabPanel';
import { Visible } from '../Visible';
import Col from './Col';
import Row from './Row';
import RowCol from './RowCol';
import RowItem from './RowItem';

const frameworkComponents = {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  AOAlert,
  AOAppetiteAutocomplete,
  AOAutocomplete,
  AOBox,
  AOButton,
  AOCard,
  AOCheckbox,
  AOCircularProgress,
  AODataGrid,
  AODatePicker,
  AOGroupedSelect,
  AOIconButton,
  AOLinkButton,
  AOProductCard,
  AORadio,
  AOSelect,
  AOTextField,
  AOTransferList,
  AOTypography,
  APIRequestHandler,
  AppetiteServiceUnavailable,
  Box,
  Button,
  Col,
  CompoundLinkAndText,
  DataGrid,
  Divider,
  DocSearchCustomerInfo,
  FlexWrap,
  FlexWrapElementSpacer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Headline,
  IconButton,
  Link,
  MenuItem,
  Modal,
  Navigation,
  NewsArticles,
  NewWindow,
  Paper,
  Radio,
  RadioGroup,
  Row,
  RowCol,
  RowItem,
  Tab,
  TabPanel,
  Tabs,
  Typography,
  Visible,
};

export { frameworkComponents };
