import _ from 'lodash';

const formatNewsArticles = (rawNewsArticles) => {
  let newsGroupArr = [];
  const newsGroup = _.groupBy(rawNewsArticles, (article) => article['monthYear']);
  for (const [key, value] of Object.entries(newsGroup)) {
    let el = { date: key, articles: value };
    newsGroupArr.push(el);
  }
  return newsGroupArr;
};

export { formatNewsArticles };
