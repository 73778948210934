import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'components/AORedesign';
import { selectCachedPolicy } from 'redux/app-store';
import PolicyDetailsCardContainer from './PolicyDetailsCardContainer';
import PolicyDetailsCardSkeleton from './PolicyDetailsCardSkeleton';

const DiscountsCard = () => {
  const cachedPolicy = useSelector(selectCachedPolicy);

  const renderContent = () => {
    // pointer to policy
    const policy = cachedPolicy.policy;

    if (cachedPolicy.retrieveStatus === 'RETRIEVING') {
      return <PolicyDetailsCardSkeleton />;
    } else if (cachedPolicy.retrieveStatus === 'RETRIEVED' && policy.discounts) {
      return (
        <PolicyDetailsCardContainer title="Discounts Applied">
          {policy?.discounts?.length > 0 ? (
            <ul>
              {policy.discounts.map((item) => (
                <li key={item}>
                  <Typography>{item}</Typography>
                </li>
              ))}
            </ul>
          ) : (
            <Typography color="textSecondary">No discounts applied to policy.</Typography>
          )}
        </PolicyDetailsCardContainer>
      );
    } else {
      return null;
    }
  };

  return renderContent();
};

export default DiscountsCard;
