import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import packageJson from '../../../package.json';
import AppDataContext from '../../contexts/appData.context';
import { AOCircularProgress } from '../AOCircularProgress';
import { Snackbar } from '../AORedesign';
import { MSAThemeProvider } from '../MSAThemeProvider/src';
import './App.css';
import AppAxiosInterceptor from './AppAxiosInterceptor';
import AppSnackbar from './AppSnackbar';
import RenderedApp from './RenderedApp';

const App = () => {
  const [appData, setAppData] = useState();
  const [appOnModernBrowser, setAppOnModernBrowser] = useState(true);
  const [browserName, setBrowserName] = useState('');
  const [snackbarData, setSnackbarData] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [serviceUnavailable, setServiceUnavailable] = useState(false);

  useEffect(() => {
    // Detect browser
    // https://codepedia.info/detect-browser-in-javascript#:~:text=To%20detect%20user%20browser%20information,to%20identify%20the%20user%20browser.&text=Now%20call%20this%20JS%20function,browser%20name%20on%20page%20load.

    const browserName = ((agent) => {
      switch (true) {
        case agent.indexOf('edge') > -1:
          return 'MS Edge';
        case agent.indexOf('edg/') > -1:
          return 'Edge ( chromium based)';
        case agent.indexOf('opr') > -1 && !!window.opr:
          return 'Opera';
        case agent.indexOf('chrome') > -1 && !!window.chrome:
          return 'Chrome';
        case agent.indexOf('trident') > -1:
          return 'MS Internet Explorer';
        case agent.indexOf('firefox') > -1:
          return 'Mozilla Firefox';
        case agent.indexOf('safari') > -1:
          return 'Safari';
        default:
          return 'this browser';
      }
    })(window.navigator.userAgent.toLowerCase());

    setBrowserName(browserName);

    const unsupportedBrowsers = ['MS Internet Explorer'];

    if (unsupportedBrowsers.indexOf(browserName) > -1) {
      setAppOnModernBrowser(false);
    }

    console.info('Agents Only Cloud | browser:', browserName);
    console.info('Agents Only Cloud | package.json version:', packageJson.version);
  }, []);

  const setSelectedAppetiteProduct = (selectedAppetiteProduct) => {
    setAppData({ ...appData, selectedAppetiteProduct });
  };

  const setAppetiteData = (appetiteData) => {
    setAppData({ ...appData, appetiteData });
  };

  const clearAppetiteData = () => {
    const appDataCopy = { ...appData };
    delete appDataCopy.appetiteData;
    setAppData({ ...appDataCopy });
  };

  const setPreviousPageName = (previousPageName) => {
    setAppData({ ...appData, previousPageName });
  };

  const setClaimsInquiry = (claimsInquiry) => {
    setAppData({ ...appData, claimsInquiry });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    handleSetSnackbarData(null);
  };

  const handleSetSnackbarData = (data) => {
    setSnackbarData(data);
  };

  const setShowServiceUnavailable = (show) => {
    setServiceUnavailable(show);
  };

  const updateDashboardCounts = (key, data) => {
    setAppData((prevState) => ({ ...prevState, dashboard: { ...prevState?.dashboard, [key]: data } }));
  };

  return appOnModernBrowser ? (
    <MSAThemeProvider>
      <AppAxiosInterceptor />

      <AppDataContext.Provider
        value={{
          ...appData,
          serviceUnavailable,
          setAppetiteData,
          clearAppetiteData,
          setPreviousPageName,
          setSelectedAppetiteProduct,
          setClaimsInquiry,
          handleSetSnackbarData,
          setLoadingMessage,
          setShowServiceUnavailable,
          updateDashboardCounts,
        }}>
        <Helmet>
          <title>Main Street America | Agents Only</title>
          <meta
            name="description"
            content="Main Street America Insurance offers property and casualty insurance products for individuals, families, and businesses. Learn more about our offerings here."
          />
        </Helmet>
        <RenderedApp />

        {/* Deprecate this Snackbar and leverage AppSnackbar below */}
        <Snackbar
          open={snackbarData?.message?.length}
          onClose={handleCloseSnackbar}
          message={!snackbarData?.severity?.length && snackbarData?.message}
          action={
            !snackbarData?.severity?.length && (
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small" />
              </IconButton>
            )
          }>
          {snackbarData?.severity?.length && (
            <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackbarData?.severity}>
              {snackbarData?.message}
            </Alert>
          )}
        </Snackbar>

        <AOCircularProgress visible={loadingMessage?.length?.length > 0}>{loadingMessage}</AOCircularProgress>
      </AppDataContext.Provider>

      <AppSnackbar />
    </MSAThemeProvider>
  ) : (
    <Alert severity="info">{`This application does not support ${browserName}, please use a modern browser.`}</Alert>
  );
};

export default App;
