import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';
import cuid from 'cuid';

import { ViewContainer } from '../Layout';
import { ScreenSmith } from '../ScreenSmith';
import BMReportsSkeleton from './BMReportsSkeleton';
import BMReportForm from './BMReportForm';
import BMReportExternal from './BMReportExternal';
import BMReportInternal from './BMReportInternal';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import {
  handleOnChange,
  handleFromDateOnChange,
  handleToDateOnChange,
} from '../CommonUtilityServices/EventHandlerService';
import { defaultLOB } from './BMReportForm';
import { GET_BMR_URL, GET_REPORTS_URL } from '../../constants/api';
import { dataGridDateFormatter, dataGridCurrencyFormatter } from '../../helpers';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';

const LocalComponents = { BMReportForm, BMReportInternal, BMReportExternal };

const reportResultsColumns = [
  {
    field: 'ProcessDate',
    headerName: 'Process Date',
    sortable: true,
    width: 200,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'Company',
    headerName: 'Company',
    sortable: true,
    width: 200,
  },
  {
    field: 'AgentNumber',
    headerName: 'Agent Number',
    sortable: true,
    width: 200,
  },
  {
    field: 'PolicyNumber',
    headerName: 'Policy Number',
    sortable: true,
    width: 200,
  },
  {
    field: 'LastName',
    headerName: 'Last Name',
    sortable: true,
    width: 200,
  },
  {
    field: 'Name1',
    headerName: 'Insured Name',
    sortable: true,
    width: 200,
  },
  {
    field: 'Name2',
    headerName: 'Insured Name 2',
    sortable: true,
    width: 200,
  },
  {
    field: 'Phone',
    headerName: 'Phone Number',
    sortable: true,
    width: 200,
  },
  {
    field: 'Addr1',
    headerName: 'Address 1',
    sortable: true,
    width: 200,
  },
  {
    field: 'Addr2',
    headerName: 'Address 2',
    sortable: true,
    width: 200,
  },
  {
    field: 'Addr_City',
    headerName: 'City',
    sortable: true,
    width: 200,
  },
  {
    field: 'Addr_State',
    headerName: 'State',
    sortable: true,
    width: 200,
  },
  {
    field: 'Addr_ZIP',
    headerName: 'Zip',
    sortable: true,
    width: 200,
  },
  {
    field: 'AccountNumber',
    headerName: 'Account Number',
    sortable: true,
    width: 200,
  },
  {
    field: 'PolicyTypeDescr',
    headerName: 'Line of Business',
    sortable: true,
    width: 200,
  },
  {
    field: 'LinesInAccount',
    headerName: 'All Lines',
    sortable: true,
    width: 200,
  },
  {
    field: 'RenewalPremium',
    headerName: 'New Written Premium',
    sortable: true,
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
  {
    field: 'WrittenPremium',
    headerName: 'Current Written Premium',
    sortable: true,
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
  {
    field: 'PremiumChange',
    headerName: 'Premium Change',
    sortable: true,
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
  {
    field: 'PolicyEffectiveDate',
    headerName: 'Effective Date',
    sortable: true,
    width: 200,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'PolicyExpirationDate',
    headerName: 'Expiration Date',
    sortable: true,
    width: 200,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'CancelDate',
    headerName: 'Cancel Date',
    sortable: true,
    width: 200,
    type: 'date',
    valueFormatter: dataGridDateFormatter,
  },
  {
    field: 'CancReasonDescr',
    headerName: 'Cancel Reason',
    sortable: true,
    width: 200,
  },
  {
    field: 'MinimumDue',
    headerName: 'Payment Due',
    sortable: true,
    width: 200,
    type: 'number',
    valueFormatter: dataGridCurrencyFormatter,
  },
  {
    field: 'PayMethodDescr',
    headerName: 'Payment Method',
    sortable: true,
    width: 200,
  },
  {
    field: 'RenewedIndicator',
    headerName: 'Renewed?',
    sortable: true,
    width: 200,
  },
  {
    field: 'BusinessInd',
    headerName: 'New Gen',
    sortable: true,
    width: 200,
  },
  {
    field: 'OldTier',
    headerName: 'Old Prog/.Tier',
    sortable: true,
    width: 200,
  },
  {
    field: 'NewTier',
    headerName: 'New Prog/.Tier',
    sortable: true,
    width: 200,
  },
  {
    field: 'ActionInd',
    headerName: 'Adverse Action Indicator',
    sortable: true,
    width: 200,
  },
  {
    field: 'Reason',
    headerName: 'Reason for Adverse Action',
    sortable: true,
    width: 200,
  },
];

const BMReports = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`BMReports`);

  const [reportType, setReportType] = useState('');
  const [agencyCodeOptions, setAgencyCodeOptions] = useState([]);
  const [reportResultsData, setReportResultsData] = useState(null);
  const [isAgentLookupModalOpen, setIsAgentLookupModalOpen] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [exportRangeMenuItems, setExportRangeMenuItems] = useState([]);
  const [exportRangeValue, setExportRangeValue] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    lob: defaultLOB,
    fromDate: null,
    toDate: null,
    showAddress: false,
    agencyCodes: '',
    eftChoice: '0',
    stateNumericCode: '',
    region: '',
    onlyNonRenew: 'false',
  });

  const generateReportFormDataRef = useRef({});

  useEffect(() => {
    setFormData((prevState) => ({ ...prevState, lob: defaultLOB }));
    resetGrid();
    generateReportFormDataRef.current = {};
  }, [reportType]);

  useEffect(() => {
    resetGrid();
    generateReportFormDataRef.current = {};
  }, [formData]);

  const resetGrid = () => {
    setReportResultsData(null);
    setErrorMessage(null);
  };

  const handleCreateReport = async (pageNumber = 1) => {
    const getDataUrl = `${GET_BMR_URL}/get-data`;
    setSubmitButtonLoading(true);

    try {
      const bMReportData = await axios.post(getDataUrl, {
        ...formData,
        pageNumber,
        reportType,
        lob: formData.lob === 'All' ? '' : formData.lob,
        stateNumericCode: formData.stateNumericCode === '' ? null : formData.stateNumericCode,
      });
      if (bMReportData.data.status) {
        setReportResultsData(bMReportData.data.data ? bMReportData.data : []);

        const pageSize = 500;
        const exportPages = Math.ceil(bMReportData.data.total / pageSize);
        const exportRangeMenuItems = [];
        for (let i = 1; i <= exportPages; i++) {
          const endRange = Math.min(bMReportData.data.total, i * pageSize);
          exportRangeMenuItems.push({ value: `${(i - 1) * pageSize + 1}-${endRange}`, code: i });
        }
        setExportRangeMenuItems(exportRangeMenuItems);
        setExportRangeValue(1);
      } else {
        setErrorMessage(bMReportData.data.message);
      }
    } catch (e) {
      console.error(e);
      setReportResultsData(null);
      setErrorMessage(e.message);
    }
    setSubmitButtonLoading(false);
  };

  const handleClickPaginationChange = (page) => {
    handleCreateReport(page + 1);
  };

  const handleOnSortModelChange = (sortData) => {
    generateReportFormDataRef.current.sortColumn = sortData[0]?.field;
    generateReportFormDataRef.current.sortOrder = sortData[0]?.sort;
  };

  const getGenerateReportRequestBody = (requestBody) => {
    if (requestBody.sortColumn === undefined) {
      requestBody.sortColumn = '';
    }
    if (requestBody.sortOrder === undefined) {
      requestBody.sortOrder = '';
    }
    let detailedContext = [];
    for (const key in requestBody) {
      detailedContext.push({
        name: key,
        value: requestBody[key],
      });
    }
    return {
      Area: 'book-management',
      DetailedContext: detailedContext,
      TypeOfRedirect: 'Generate-PDF',
    };
  };

  const handleGenerateReportFile = async (exportType) => {
    const requestBody = {
      ...formData,
      pageNumber: exportRangeValue,
      reportType,
      lob: formData.lob === 'All' ? '' : formData.lob,
      stateNumericCode: formData.stateNumericCode === '' ? null : formData.stateNumericCode,
      ...generateReportFormDataRef.current,
      exportType,
    };

    if (exportType === 'pdf') {
      handlePdfDownload(
        'POST',
        getGenerateReportRequestBody(requestBody),
        GET_REPORTS_URL,
        () => {},
        () => {},
        () => {},
        'newWindow',
      );
    } else {
      const getReportUrl = `${GET_BMR_URL}/get-data`;
      try {
        const bMReportData = await axios.post(getReportUrl, requestBody);
        if (bMReportData?.data?.status) {
          const columnHeaders = [...reportResultsColumns.map((col) => col.headerName)];
          const csvString = [
            columnHeaders,
            ...bMReportData.data.data.map((item) => {
              const rowItem = [];
              for (const column of reportResultsColumns) {
                rowItem.push(item[column.field]);
              }
              return rowItem;
            }),
          ];

          const csvContent = csvString.map((e) => e.join(',')).join('\n');
          const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);

          const downloadLink = document.createElement('a');
          downloadLink.setAttribute('href', url);
          downloadLink.download = `${cuid()}.csv`;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleGeneratePDFOnClick = () => {
    handleGenerateReportFile('pdf');
  };
  const handleGenerateExcelOnClick = () => {
    handleGenerateReportFile('jsonForReport');
  };

  const localStateMap = {
    reportType,
    showSubmitButton: reportType !== '',
    agencyCodeOptions,
    isAgentLookupModalOpen,
    lobValue: formData.lob,
    fromDateValue: formData.fromDate,
    toDateValue: formData.toDate,
    showAddress: formData.showAddress,
    agencyCodes: formData.agencyCodes,
    onlyNonRenewValue: formData.onlyNonRenew,
    eftChoiceValue: formData.eftChoice,
    stateNumericCodeValue: formData.stateNumericCode,
    regionValue: formData.region,
    reportResultsData: reportResultsData?.data?.map((el) => ({
      id: cuid(),
      ...el,
    })),
    reportResultsColumns,
    paginationRowCount: reportResultsData?.total,
    submitButtonLoading,
    showDataGrid: reportResultsData?.total > 0,
    exportRangeMenuItems,
    exportRangeValue,
    errorMessage,
  };

  const callBackFunctions = {
    handleChangeReportType: (e) => setReportType(e.target.value),
    handleSubmitOnClick: () => handleCreateReport(),
    setAgencyCodeOptions,
    setIsAgentLookupModalOpen,
    handleLOBOnChange: (e) => handleOnChange(e, formData, setFormData),
    handleFromDateOnChange: (date) => handleFromDateOnChange(date, formData, setFormData),
    handleToDateOnChange: (date) => handleToDateOnChange(date, formData, setFormData),
    handleShowAddressOnChange: (e) =>
      setFormData((prevState) => ({ ...prevState, showAddress: e.target.checked })),
    handleAgencyCodesOnChange: (e) => handleOnChange(e, formData, setFormData),
    handleSetAgencyCodeOnChangeInternal: (e) => handleOnChange(e, formData, setFormData),
    handleAgencyCodesOnChangeInternal: (value) =>
      setFormData((prevState) => ({ ...prevState, agencyCodes: value })),
    handleEftChoiceOnChange: (e) => handleOnChange(e, formData, setFormData),
    handleOnlyNonRenewOnChange: (e) => handleOnChange(e, formData, setFormData),
    handleStateNumericCodeOnChange: (e) => handleOnChange(e, formData, setFormData),
    handleRegionOnChange: (e) => handleOnChange(e, formData, setFormData),
    handleClickPaginationChange: (page) => handleClickPaginationChange(page),
    handleOnSortModelChange: (sortData) => handleOnSortModelChange(sortData),
    handleExportDropdownOnChange: (e) => setExportRangeValue(e.target.value),
    handleGeneratePDFOnClick,
    handleGenerateExcelOnClick,
  };

  return loadingSd ? (
    <BMReportsSkeleton />
  ) : (
    <ViewContainer title={'Book Management Reports'}>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={LocalComponents}
        />
      )}
    </ViewContainer>
  );
};

export default BMReports;
