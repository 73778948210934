import React from 'react';
import PropTypes from 'prop-types';

import RowCol from '../ScreenSmith/RowCol';
import { AgentLookupModal } from '../AgentLookupModal';
import Row from '../ScreenSmith/Row';
import { AOTextField } from '../AOTextField';
import { AOIconButton } from '../AOIconButton';
import { Box } from '@material-ui/core';

const BMReportInternal = ({
  setAgentCode,
  open,
  setOpen,
  value,
  handleSetAgencyCodeOnChangeInternal,
}) => {
  return (
    <>
      <RowCol mb={2}>
        <Row alignItems={'center'}>
          <AOTextField
            id={'bmreport-agency-codes'}
            label={'Enter Agency Code'}
            value={value}
            name={'agencyCodes'}
            onChange={handleSetAgencyCodeOnChangeInternal}
          />
          <Box ml={0.25}>
            <AOIconButton id={'bmreport-lookup-button'} onClick={() => setOpen(true)} />
          </Box>
        </Row>
        <AgentLookupModal
          open={open}
          setAgentCode={setAgentCode}
          lineOfBusiness={'commercial-lines'}
          id="bmreport-agency-codes-modal"
          label="Agency Name"
          labelId="bmreport-agency-codes-modal-label"
          placeholder="Enter Agent ID"
          setOpen={setOpen}
        />
      </RowCol>
    </>
  );
};

BMReportInternal.propTypes = {
  value: PropTypes.string,
  setAgentCode: PropTypes.func,
  setOpen: PropTypes.func,
  handleSetAgencyCodeOnChangeInternal: PropTypes.func,
  open: PropTypes.bool,
};

export default BMReportInternal;
