import React from 'react';
import PropTypes from 'prop-types';
import MuiPaper from '@material-ui/core/Paper';
import MuiBox from '@material-ui/core/Box';

const Paper = (props) => {
  return (
    <MuiPaper variant="outlined" square>
      <MuiBox p={2} {...props}>
        {props.children}
      </MuiBox>
    </MuiPaper>
  );
};

Paper.propTypes = {
  children: PropTypes.node,
};

export default Paper;
