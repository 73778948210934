import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CircularProgress from '@material-ui/core/CircularProgress';

import { handlePdfDownload } from '../../CommonUtilityServices/DownloadPdfService';

const PLSMaAutoDecReprintRenderCell = ({ row: { docPageId } }, setDownloadingPdfError) => {
  const [loadingPdf, setLoadingPdf] = useState(false);

  const handleClickPdfDownload = () => {
    const pdfUrl = `/ma-auto-pdf/${docPageId}`;

    handlePdfDownload(
      'GET',
      null,
      pdfUrl,
      setLoadingPdf,
      setDownloadingPdfError,
      setDownloadingPdfError,
      null,
    );
  };

  if (loadingPdf)
    return (
      <Box py={2}>
        <CircularProgress color="inherit" size={20} />
      </Box>
    );

  return (
    <IconButton
      aria-label="pdf"
      onClick={() => {
        handleClickPdfDownload();
      }}>
      <PictureAsPdfIcon />
    </IconButton>
  );
};

PLSMaAutoDecReprintRenderCell.propTypes = {
  row: PropTypes.shape({
    docPageId: PropTypes.string,
  }),
  id: PropTypes.string,
  downloadingErrorControlFunc: PropTypes.func,
};

export default PLSMaAutoDecReprintRenderCell;
