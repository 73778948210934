import React from 'react';
import _ from 'lodash';

import UserAdminViewContainer from './UserAdminViewContainer';
import { ScreenSmith } from '../ScreenSmith';
import UserAdminSkeleton from './UserAdminSkeleton';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import ViewAgencyInfo from './views/ViewAgencyInfo';

const LocalComponents = { ViewAgencyInfo };

const UserAdminViewAgencyInfo = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`UserAdmViewAgencyInfo`);

  const localStateMap = {};

  const callBackFunctions = {};

  return loadingSd ? (
    <UserAdminSkeleton />
  ) : (
    <UserAdminViewContainer title={'Search for Agency'}>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={LocalComponents}
        />
      )}
    </UserAdminViewContainer>
  );
};

export default UserAdminViewAgencyInfo;
