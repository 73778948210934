import { rem } from 'polished';

import { FormControl, styled } from '@material-ui/core';

const StyledAOSelect = styled(FormControl)(({ theme }) => ({
  minWidth: `${rem(279)}`,

  [theme.breakpoints.down('sm')]: {
    minWidth: `${rem(250)}`,
  },
}));

export { StyledAOSelect };
