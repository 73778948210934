const featureFlags = {
  ACCESS_AGENCY_BILLED_STATEMENTS_REPORT: 'Access_AgencyBilledStatements_Report',
  ACCESS_AGENCY_COMPENSATION_SCHEDULES: 'Access_Agency_Compensation_Schedules',
  ACCESS_AGENCY_SECURITY_USER_ADMIN: 'agencySecurityUserAdministration',
  ACCESS_AGENT_MANAGEMENT: 'Access_Agent_Management',
  ACCESS_ANNUAL_MILAGE_REPORT: 'Access_AnnualMileage_Report',
  ACCESS_APNE_REPORT: 'Access_AP&E_Report',
  ACCESS_APP_ADMIN: 'Access_App_Admin',
  ACCESS_COMMERCIAL_LINES_SERVICES: 'Access_CommercialLinesServices',
  ACCESS_CONTENT_MGMT_ADMIN: 'Acccess_Content_Management',
  ACCESS_DIRECT_BILL_COMMISSION_REPORT: 'Access_DirectBillCommissionStatements_Report',
  ACCESS_FARM_RANCH: 'Access_Farm_Ranch',
  ACCESS_FIND_AGENT: 'Access_Find_Agent',
  ACCESS_HEALTH_CHECKS: 'Access_HealthChecks',
  ACCESS_LOSSES_BY_AGENT_REPORT: 'Access_LossByAgent_Report',
  ACCESS_MANAGE_MSA_BOOK_REPORT: 'Access_ManageMSABook_Report',
  ACCESS_NEWS_ADMIN: 'newsAdministration',
  ACCESS_PAYMENT_BALANCE_REPORT: 'Access_PaymentBalance_Report',
  ACCESS_PAYMENT_HISTORY_AND_CURRENT_PAYMENTS: 'Access_PaymentHistory_And_Current_Payments',
  ACCESS_PAYMENT_MANAGEMT: 'Access_PaymentManagement',
  ACCESS_PERSONAL_LINES_SERVICES: 'Access_PersonalLinesServices',
  ACCESS_POLICY_LOSS_RUN_REPORT: 'Access_PolicyLossRuns_Report',
  ACCESS_RESOURCE_MGMT_ADMIN: 'Access_Resource_Management',
  ACCESS_STATE_ROLLOUT_ADMIN: 'Access_State_Rollout_Admin',
  ACCESS_SUPER_ADMIN: 'superAdmin',
  ACCESS_TAR_REPORT: 'Access_TransactionActivity_Report',
  ACCESS_USER_ADMIN: 'userAdministration',
  ACCESS_USER_INTERNAL: 'internalUser',
  ACCESS_USER_IS_AGENT: 'isAgent',
  ACCESS_VIEW_ALL: 'Access_View_All',
  ACKNOWLEDGED_LEGAL_NOTICE: 'Acknowledged_Legal_Notice',
  APPETITE_ENABLED: 'Appetite_Enabled',
  BONDS_TRAINING_PERMITTED: 'Bonds_Training_Permitted',
  ENABLE_REDESIGN: 'Enable_Redesign_Toggle',
  HAS_VIEW_ALL: 'hasViewAll',
  PRODUCTION_ONLY: 'Production_Only',
};

export default featureFlags;
