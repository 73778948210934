import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cuid from 'cuid';

import { ScreenSmith } from '../../../ScreenSmith';
import BillingServicesPaymentManagementSkeleton from '../BillingServicesPaymentManagementSkeleton';
import PaymentValidation from './PaymentValidation';
import PaymentConfirmation from './PaymentConfirmation';

import useScreenDefinition from '../../../../hooks/useScreenDefinition';

import {
  adjustPaymentCols,
  fetchCurrentPayments,
  handleOnClickEnterCorrectPayment,
  onSelectionModelChange,
  handleOnChangePayAmountCorrection,
  handleClickSubmitAdjustPayment,
  handleClickGoToAdjustPayment,
  handleOnClickAdjustPayment,
} from './AdjustPayment.api';

import { GET_BILLING_PAYMENT_ADJUSTABLE_PAYMENTS } from '../../../../constants/api';

const localComponents = {
  PaymentValidation,
  PaymentConfirmation,
};

const screenMappings = {
  start: `BillingServicesPaymentManagementAdjustPaymentPanel`,
  enterCorrectPayment: `BillingServicesPaymentManagementAdjustPaymentPanelEnterCorrectPayment`,
  paymentValidation: `BillingServicesPaymentManagementAdjustPaymentPanelPaymentValidation`,
  paymentConfirmation: `BillingServicesPaymentManagementAdjustPaymentPanelPaymentConfirmation`,
};

const BillingServicesPaymentManagementAdjustPayment = ({ resetFlow }) => {
  // Screen def
  const [currentScreen, setCurrentScreen] = useState(screenMappings.start);
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(currentScreen, [currentScreen]);
  const [paymentListData, setPaymentListData] = useState({
    loading: true,
    paymentList: [{ id: cuid() }],
    selectedRow: {},
    alert: {
      message: null,
      severity: 'error',
      isVisible: false,
    },
    alertAdjustPayment: {
      isVisible: false,
    },
    adjustedPaymentData: {
      paymentAmountCorrection: null,
    },
    acknowledgedSubmission: {},
  });

  const [selectionModel, setSelectionModel] = useState([]);

  const localStateMap = {
    adjustPaymentCols,
    paymentListLoading: paymentListData.loading,
    paymentList: paymentListData.paymentList,
    paymentListAlertMessage: paymentListData.alert.message,
    paymentListAlertSeverity: paymentListData.alert.severity,
    paymentListAlertIsVisible: paymentListData.alert.isVisible,
    adjustPaymentAlertIsVisible: paymentListData.alertAdjustPayment.isVisible,
    selectionModel,
    accountNumberCorrectPayment: paymentListData.selectedRow.accountNumber,
    accountNameCorrectPayment: paymentListData.selectedRow.accountName,
    paymentAmountCorrection: paymentListData.adjustedPaymentData.paymentAmountCorrection,
    paymentValidationData: {
      changeDate: paymentListData.selectedRow.payDateCreated,
      originalAmount: paymentListData.selectedRow.originalAmount,
      adjustedAmount: paymentListData.adjustedPaymentData.paymentAmountCorrection,
      accountNumber: paymentListData.selectedRow.accountNumber,
      accountName: paymentListData.selectedRow.accountName,
    },
  };

  const callBackFunctions = {
    handleClickBackToPaymentManagement: () => resetFlow(), // function from parent to reset flow
    onSelectionModelChange: (selection) =>
      onSelectionModelChange(
        paymentListData.paymentList,
        selection,
        selectionModel,
        setSelectionModel,
        setPaymentListData,
      ),
    handleOnClickEnterCorrectPayment: () =>
      handleOnClickEnterCorrectPayment(
        paymentListData,
        setPaymentListData,
        screenMappings,
        setCurrentScreen,
      ),
    handleClickGoToAdjustPayment: () =>
      handleClickGoToAdjustPayment(
        setPaymentListData,
        screenMappings,
        setCurrentScreen,
        setSelectionModel,
      ),
    handleOnChangePayAmountCorrection: (event) =>
      handleOnChangePayAmountCorrection(event, setPaymentListData),
    handleOnClickAdjustPayment: () =>
      handleOnClickAdjustPayment(
        paymentListData,
        setPaymentListData,
        screenMappings,
        setCurrentScreen,
      ),
    handleClickSubmitAdjustPayment: () =>
      handleClickSubmitAdjustPayment(
        paymentListData,
        setPaymentListData,
        screenMappings,
        setCurrentScreen,
      ),
  };

  useEffect(() => {
    if (currentScreen === screenMappings.start) {
      fetchCurrentPayments(setPaymentListData, GET_BILLING_PAYMENT_ADJUSTABLE_PAYMENTS);
    }
  }, [currentScreen]);

  if (loadingSd) return <BillingServicesPaymentManagementSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={localComponents}
        />
      )}
    </>
  );
};

BillingServicesPaymentManagementAdjustPayment.propTypes = {
  resetFlow: PropTypes.func, // accept a fn as a prop to go back to parent screen
};

export default BillingServicesPaymentManagementAdjustPayment;
