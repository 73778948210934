import { rem } from 'polished';

import { styled, TableCell, TableRow } from '@material-ui/core';

const StyledTitleRow = styled(TableRow)(({ theme }) => ({
  padding: theme.spacing(0),
  border: 0,
  backgroundColor: '#e9e3e2',
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(16)}`,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: theme.spacing(0),
  border: 0,
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(16)}`,
  },
}));

const StyledSpacerRow = styled(TableRow)(({ theme }) => ({
  height: theme.spacing(2),
  border: 0,
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(16)}`,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0),
  paddingLeft: theme.spacing(0.5),
  border: 0,
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(16)}`,
  },
}));

const StyledTitleCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0),
  textAlign: 'left',
  backgroundColor: '#e9e3e2',
  color: '#1b1bfb',
  fontWeight: theme.typography.fontWeightBold,
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(16)}`,
  },
}));

const StyledLableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0),
  border: 0,
  textAlign: 'right',
  fontWeight: theme.typography.fontWeightBold,
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(16)}`,
  },
}));

export {
  StyledTitleRow,
  StyledTableRow,
  StyledSpacerRow,
  StyledTableCell,
  StyledTitleCell,
  StyledLableCell,
};
