import React from 'react';
import _ from 'lodash';
import { ScreenSmith } from '../ScreenSmith';
import { CardTabSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';

const AgencyToolkitReportsStatements = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('AgencyToolkitReportsStatements');

  const agencyToolkitReportsStatementsState = {};
  const agencyToolkitReportsStatementsSCallbackFunctions = {};

  return loadingSd ? (
    <CardTabSkeleton />
  ) : (
    <div>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={agencyToolkitReportsStatementsSCallbackFunctions}
          stateMap={agencyToolkitReportsStatementsState}
        />
      )}
    </div>
  );
};

export default AgencyToolkitReportsStatements;
