import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Box, Button, Card, Col, Row, Typography } from 'components/AORedesign';
import PropTypes from 'prop-types';
import React from 'react';

const PolicyDetailsCardContainer = ({ title, children, redirectButtonText, handleOnClick }) => (
  <Box mb={3}>
    <Card elevation={0} variant="outlined">
      <Box p={1}>
        <Box mb={3}>
          {redirectButtonText ? (
            <Row alignItems="center">
              <Col xs={4}>
                <Typography variant="h5">{title}</Typography>
              </Col>
              <Col xs={8} container justifyContent="flex-end">
                <Button variant="text" color="secondary" onClick={handleOnClick} endIcon={<OpenInNewIcon />}>
                  {redirectButtonText}
                </Button>
              </Col>
            </Row>
          ) : (
            <Typography variant="h5">{title}</Typography>
          )}
        </Box>
        {children}
      </Box>
    </Card>
  </Box>
);

PolicyDetailsCardContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  redirectButtonText: PropTypes.string,
  handleOnClick: PropTypes.func,
};

export default PolicyDetailsCardContainer;
