import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { GET_APPETITE } from '../../constants/api';
import AppDataContext from '../../contexts/appData.context';
import { useDebounce } from '../../hooks/useDebounce';
import useFetchSelectMenuItems from '../../hooks/useFetchSelectMenuItems';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { AOAlert } from '../AOAlert';
import { CardTabSkeleton } from '../AOSkeleton';
import { AgentLookupModal } from '../AgentLookupModal';
// to do: check why named import caused unit test failures
import AppetiteServiceUnavailable from '../AppetiteServiceUnavailable/AppetiteServiceUnavailable';
import { ScreenSmith } from '../ScreenSmith';
import AppetiteAgentValidationModal from './AppetiteAgentValidationModal';
import {
  businessLocationMenuItemGroups as defaultBusinessLocationMenuItemGroups,
  employeeMenuItems,
  fetchFormSuggestions,
  getOptionLabel,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleBackOnClickFromForm,
  handleBackOnClickFromProducts,
  handleHealthCheck,
  handleLearnMoreOnClick,
  handleNextOnClick,
  handleOnChange,
  handleSelectAgentLocationCode,
  handleSetSelectedProduct,
  handleStartQuoteOnClick,
  screenMappings,
  startQuote,
  yesNoMenuItems,
} from './FindProductToQuote.api';
import { StyledFindProductToQuote } from './FindProductToQuote.styled';
import FindProductToQuoteNAICSCodeDialog from './FindProductToQuoteNAICSCodeDialog';

const errorText = 'Something went wrong. Please try again or contact our Customer Service Department at (877) 927-5672.';

const LocalComponents = {
  AppetiteServiceUnavailable,
  FindProductToQuoteNAICSCodeDialog,
  AgentLookupModal,
};

const FindProductToQuote = ({ handleCommercialCardOnClick, handleCommercialFindProductsOnClick }) => {
  const { setSelectedAppetiteProduct, appetiteData, setAppetiteData } = useContext(AppDataContext);

  const [currentScreen, setCurrentScreen] = useState(screenMappings.start);
  const [businessTypeKeywordsOptions, setBusinessTypeKeywordsOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [serviceUnavailable, setServiceUnavailable] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [showNAICSCodeDialog, setShowNAICSCodeDialog] = useState([]);
  const [error, setError] = useState();
  const [isErrorVisible, setIsErrorVisible] = useState();
  const [selectedClassificationCode, setSelectedClassificationCode] = useState('');
  const [buttonLoading, setButtonLoading] = useState({ next: false });
  const [showAgentCodeValidation, setShowAgentCodeValidation] = useState(false);
  const [businessLocationMenuItemGroups, setBusinessLocationMenuItemGroups] = useState(defaultBusinessLocationMenuItemGroups);

  const [formInputMetadata, setFormInputMetadata] = useState({
    businessTypeKeywords: { value: '', invalidText: '' },
    state: { invalidText: '' },
    zipCode: { invalidText: '' },
    businessLocationType: { invalidText: '' },
    employees: { invalidText: '' },
    vehicles: { invalidText: '' },
    selectedProduct: { invalidText: '' },
    locationCode: { invalidText: '' },
  });
  const [formData, setFormData] = useState({
    businessTypeKeywords: null,
    state: '',
    zipCode: '',
    businessLocationType: '',
    employees: '',
    vehicles: '',
    locationCode: '',
  });

  const history = useHistory();
  const [debouncedState, setDebouncedState] = useDebounce(formInputMetadata);
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(currentScreen, [currentScreen]);

  useEffect(() => {
    handleHealthCheck(setServiceUnavailable);

    (() => {
      if (appetiteData) {
        if (appetiteData.formData) {
          setFormData(appetiteData.formData);
        }
        if (appetiteData.formInputMetadata) {
          setFormInputMetadata(appetiteData.formInputMetadata);
        }
        if (appetiteData.suggestedProducts) {
          setSuggestedProducts(appetiteData.suggestedProducts);
          setCurrentScreen(screenMappings.products);
        }
      }
    })();
  }, []);

  useFetchSelectMenuItems(
    `${GET_APPETITE}/states`,
    (response) => {
      setStateOptions(
        response.data.StateList.map((state) => ({
          value: state.state_name,
          code: state.geos_code,
        })),
      );
    },
    () => {
      setError(errorText);
    },
  );

  useEffect(async () => {
    if (formInputMetadata.businessTypeKeywords.value.length) {
      try {
        const suggestions = await fetchFormSuggestions(formInputMetadata.businessTypeKeywords.value);
        const suggestionsMenuItems = suggestions.AvailableCodes.map((item) => ({
          value: `${item.description} (${item.code})`,
          code: item.hash,
          naicCode: item.code,
        }));

        setBusinessTypeKeywordsOptions(suggestionsMenuItems);
      } catch (err) {
        console.error(err);
        setError(errorText);
      }
    } else {
      setBusinessTypeKeywordsOptions([]);
    }
  }, [debouncedState.businessTypeKeywords]);

  const findProductToQuoteState = {
    error,
    isErrorVisible,
    businessTypeKeywords: formData.businessTypeKeywords?.value,
    businessTypeKeywordsNaicCode: formData?.businessTypeKeywords?.naicCode,
    businessTypeKeywordsOptions,
    businessTypeKeywordsInputValue: formInputMetadata.businessTypeKeywords?.value,
    businessTypeKeywordsInvalidText: formInputMetadata.businessTypeKeywords.invalidText,
    state: formData.state,
    stateOptions,
    businessLocationType: formData.businessLocationType,
    businessLocationMenuItemGroups: businessLocationMenuItemGroups,
    employeeMenuItems,
    yesNoMenuItems,
    stateInvalidText: formInputMetadata.state.invalidText,
    zipCode: formData.zipCode,
    zipCodeInvalidText: formInputMetadata.zipCode.invalidText,
    businessLocationTypeInvalidText: formInputMetadata.businessLocationType.invalidText,
    employeesInvalidText: formInputMetadata.employees.invalidText,
    vehiclesInvalidText: formInputMetadata.vehicles.invalidText,
    suggestedProducts: suggestedProducts?.AvailableProducts,
    employees: formData.employees,
    vehicles: formData.vehicles,
    displayProductCardsInError: formInputMetadata.selectedProduct.invalidText.length,
    productsAvailable: suggestedProducts?.AvailableProducts?.length > 0 && !serviceUnavailable,
    noProductsAvailable: suggestedProducts?.AvailableProducts?.length === 0,
    serviceUnavailable,
    serviceAvailable: !serviceUnavailable,
    showLoader,
    locationCode: formData.locationCode,
    showNAICSCodeDialog,
    naicsCodeText: formData?.businessTypeKeywords?.value,
    selectedClassificationCode,
    nextButtonLoading: buttonLoading.next,
    showAgentCodeValidation,
    selectedProduct,
  };

  const handleBusinessTypeKeywordsOnChange = (e, value) => {
    handleAOAutocompleteOnChange('businessTypeKeywords', e, value, formData, setFormData);
    // feature/E1PMAO-11042 - Anytime the Business Type Keywords change, then clear Business Location Type for user to reselect
    setFormData((prevState) => ({ ...prevState, businessLocationType: '' }));
    // filter options based on type, only show LANDLORD ONLY options if the nacis code starts with 5311
    const code = value?.naicCode;
    const isLROOptionEligible = code?.startsWith('5311');
    const updatedDefaultBusinessLocationMenuItemGroups = { ...defaultBusinessLocationMenuItemGroups };
    if (isLROOptionEligible === true) {
      delete updatedDefaultBusinessLocationMenuItemGroups['TENANT ONLY'];
      delete updatedDefaultBusinessLocationMenuItemGroups['OWNER OCCUPIED'];
    } else if (isLROOptionEligible === false) {
      delete updatedDefaultBusinessLocationMenuItemGroups['LANDLORD ONLY'];
    }
    setBusinessLocationMenuItemGroups(updatedDefaultBusinessLocationMenuItemGroups);
  };

  const findProductToQuoteCallbackFunctions = {
    handleBusinessTypeKeywordsOnChange: handleBusinessTypeKeywordsOnChange,
    handleBusinessTypeKeywordsOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'businessTypeKeywords',
        e,
        value,
        formInputMetadata,
        setFormInputMetadata,
        debouncedState,
        setDebouncedState,
        setFormInputMetadata,
      ),
    handleOnChange: (e) => handleOnChange(e, formData, setFormData, setFormInputMetadata),
    getOptionLabel: (option) => getOptionLabel(option),
    handleNextOnClick: () => {
      handleNextOnClick(
        formData,
        formInputMetadata,
        setFormInputMetadata,
        setSuggestedProducts,
        setCurrentScreen,
        setServiceUnavailable,
        setAppetiteData,
        setButtonLoading,
      );

      // call method if passed/defined
      if (typeof handleCommercialFindProductsOnClick === 'function') {
        handleCommercialFindProductsOnClick();
      }
    },
    handleSetSelectedProduct: (product) => handleSetSelectedProduct(product, setSelectedProduct, setSelectedAppetiteProduct, setFormInputMetadata),
    handleBackOnClickFromForm: () => handleBackOnClickFromForm(history, setAppetiteData),
    handleBackOnClickFromProducts: () => {
      handleBackOnClickFromProducts(
        setCurrentScreen,
        formInputMetadata,
        setFormInputMetadata,
        setSelectedProduct,
        setSelectedAppetiteProduct,
        appetiteData,
        setAppetiteData,
        setError,
        setIsErrorVisible,
        setFormData,
      );

      // call method if passed/defined
      if (typeof handleCommercialCardOnClick === 'function') {
        handleCommercialCardOnClick();
      }
    },
    handleStartQuoteOnClick: () =>
      handleStartQuoteOnClick(
        history,
        selectedProduct,
        formInputMetadata,
        setFormInputMetadata,
        setShowLoader,
        formData,
        setError,
        setIsErrorVisible,
        setShowNAICSCodeDialog,
        setShowAgentCodeValidation,
        formData.locationCode,
      ),
    handleLearnMoreOnClick: (e, value) => handleLearnMoreOnClick(e, value),
    setSelectedClassificationCode,
    handleCancelOnClick: () => setShowNAICSCodeDialog([]),
    handleMapCodeOnClick: () =>
      startQuote(history, JSON.parse(selectedProduct), formData, setError, setIsErrorVisible, setShowLoader, selectedClassificationCode),
    handleSelectAgentLocationCode: (value) => handleSelectAgentLocationCode(value, setFormData, setFormInputMetadata),
  };

  const handleCloseOnClick = () => {
    setShowAgentCodeValidation(false);
    setSelectedProduct('');
    setSelectedAppetiteProduct('');
  };

  const handleSetAgencyCode = (code) => {
    setFormData((prevState) => ({ ...prevState, locationCode: code }));
    const appetiteDataCopy = { ...appetiteData };
    appetiteDataCopy.formData.locationCode = code;
    setAppetiteData(appetiteDataCopy);
    setShowAgentCodeValidation(false);
  };

  return loadingSd ? (
    <CardTabSkeleton />
  ) : (
    <StyledFindProductToQuote>
      {error && <AOAlert severity="error">{error}</AOAlert>}
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={LocalComponents}
          functionMap={findProductToQuoteCallbackFunctions}
          stateMap={findProductToQuoteState}
        />
      )}
      {!formData.locationCode && showAgentCodeValidation && (
        <AppetiteAgentValidationModal
          state={formData?.state?.split('-')[1]}
          open={showAgentCodeValidation}
          handleCloseOnClick={handleCloseOnClick}
          handleSetAgencyCode={handleSetAgencyCode}
        />
      )}
    </StyledFindProductToQuote>
  );
};

FindProductToQuote.propTypes = {
  handleCommercialCardOnClick: PropTypes.func,
  handleCommercialFindProductsOnClick: PropTypes.func,
};

export default FindProductToQuote;
