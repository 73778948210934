import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import cuid from 'cuid';
import React, { useState } from 'react';

const ClientCardSkeleton = () => {
  const [skeletonItems] = useState(Array.from(Array(5).keys()));

  return (
    <Box>
      {skeletonItems.map(() => (
        <Box key={cuid()} my={-3}>
          <Skeleton height={100} animation="wave" />
        </Box>
      ))}
    </Box>
  );
};

export default ClientCardSkeleton;
