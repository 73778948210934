import React from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import { CardTabSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { StyledAppetiteServiceUnavailable } from './AppetiteServiceUnavailable.styled';

const AppetiteServiceUnavailable = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('AppetiteServiceUnavailable');
  return loadingSd ? (
    <CardTabSkeleton />
  ) : (
    <StyledAppetiteServiceUnavailable>
      {!_.isNil(UiDefinitionsData) && <ScreenSmith definition={UiDefinitionsData} />}
    </StyledAppetiteServiceUnavailable>
  );
};

export default AppetiteServiceUnavailable;
