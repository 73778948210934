import React from 'react';
import PropTypes from 'prop-types';

import WorkflowCard from './WorkflowCard';

const WorkflowCardCommercial = ({ onClick }) => (
  <WorkflowCard
    onClick={onClick}
    icon={'icons-icon-business-owner'}
    title="Commercial"
    description="Provides small business insurance solutions designed with your client’s needs in mind."
  />
);

WorkflowCardCommercial.propTypes = {
  onClick: PropTypes.func,
};

export default WorkflowCardCommercial;
