import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Typography } from '@material-ui/core/';
import { NewsArticles } from '../NewsArticles';

const ServicesNewsArticles = ({ line }) => {
  const [category, setCategory] = useState('current');

  const handleActiveOnClick = () => {
    setCategory('current');
  };

  const handleArchivedOnClick = () => {
    setCategory('archived');
  };

  return (
    <>
      <Box mb={1}>
        <Typography variant={'h6'}>{`Agent News - ${
          category === 'current' ? 'Current' : 'Archived'
        }`}</Typography>
      </Box>
      <Box>
        <NewsArticles line={line} category={category} newsType="inside-news" />
      </Box>
      <Box mt={6}>
        <Button color={'secondary'} onClick={handleActiveOnClick}>
          All Active Articles
        </Button>
        <Button color={'secondary'} onClick={handleArchivedOnClick}>
          All Archived Articles
        </Button>
      </Box>
    </>
  );
};

ServicesNewsArticles.propTypes = {
  line: PropTypes.string,
};

export default ServicesNewsArticles;
