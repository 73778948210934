import React, { useState, useEffect, useRef } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import _ from 'lodash';
import axios from 'axios';
import { GET_DBC_URL } from '../../constants/api';

import { ScreenSmith } from '../ScreenSmith';
import DbcReportSkeleton from './DbcReportSkeleton';
import { ReportsHeader } from '../ReportsHeader';
import {
  handleCreateDbcReport,
  handleAgencyCodeOnClick,
  handleAgencyNameOnClick,
  handleOnChangeMonthYear,
} from './DbcReport.api';
import {
  fetchAgencyNameSuggestions,
  handleAOAutocompleteOnBlur,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleOnChange,
} from '../CommonUtilityServices/EventHandlerService';

import useScreenDefinition from '../../hooks/useScreenDefinition';

const LocalComponents = {
  ReportsHeader,
};

const DbcReportInternal = () => {
  const [agencyNameOptions, setAgencyNameOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    agencyName: { value: '' },
  });
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);
  const [formData, setFormData] = useState({
    agencyName: '',
    agencyCode: '',
    year: '',
  });

  const generateReportFormDataRef = useRef({});
  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);

  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`DbcReportInternal`);

  useEffect(async () => {
    setFormData({ ...formData, agencyCode: '' });
    if (autocompleteInputValues.agencyName.value.length >= 3) {
      if (formData.agencyName.length > 0) {
        const agencyCode = formData.agencyName.slice(-6);
        setFormData({ ...formData, agencyCode: agencyCode });
        setIsDownloadingPdfErrorVisible(false);
        setLoadingPdf(false);
        setDownloadingPdfError('');
      }
      const suggestions = await fetchAgencyNameSuggestions(
        autocompleteInputValues.agencyName.value,
      );
      setAgencyNameOptions(suggestions);
    } else {
      setAgencyNameOptions([]);
    }
  }, [debouncedState.agencyName, debouncedState.agencyCode]);

  useEffect(async () => {
    const dateData = await axios.get(`${GET_DBC_URL}/dates`);
    let { data: dates } = dateData;
    dates = dates.map((date) => {
      return { ...date, code: date.dateValue, value: date.dateDisplay.replace('-', ' ') };
    });
    setYearOptions(dates);
  }, []);

  const localStateMap = {
    agencyName: formData.agencyName,
    agencyNameOptions,
    agencyNameInputValue: autocompleteInputValues.agencyName.value,
    agencyCode: formData.agencyCode,
    yearOptions,
    year: formData.year,
    loadingPdf,
    isDownloadingPdfErrorVisible,
    downloadingPdfError,
  };

  const callBackFunctions = {
    handleAgencyNameOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'agencyName',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        debouncedState,
        setDebouncedState,
      ),
    handleAgencyNameOnChange: (e, value) =>
      handleAOAutocompleteOnChange('agencyName', e, value, formData, setFormData),
    handleAgencyNameOnBlur: () =>
      handleAOAutocompleteOnBlur('agencyName', formData, setFormData, autocompleteInputValues),
    handleAgencyCodeOnClick: (e) =>
      handleAgencyCodeOnClick(
        e,
        autocompleteInputValues,
        setAutocompleteInputValues,
        setFormData,
        setAgencyNameOptions,
      ),
    handleAgencyNameOnClick: () => handleAgencyNameOnClick(formData),
    handleOnChange: (e) => handleOnChange(e, formData, setFormData, generateReportFormDataRef),
    handleOnChangeMonthYear: (e) => handleOnChangeMonthYear(e, setFormData),
    handleCreateDbcReport: () =>
      handleCreateDbcReport(
        setLoadingPdf,
        setIsDownloadingPdfErrorVisible,
        setDownloadingPdfError,
        formData,
        'pdf',
      ),
      handleCreateDbcReportExcel: () =>
      handleCreateDbcReport(
        setLoadingPdf,
        setIsDownloadingPdfErrorVisible,
        setDownloadingPdfError,
        formData,
        'excel',
      ),
  };

  if (loadingSd) return <DbcReportSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={LocalComponents}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default DbcReportInternal;
