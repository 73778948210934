import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';

import { StyledAOAutocomplete, useStyles } from './AOAutocomplete.styled';

const AOAppetiteAutocomplete = ({
  disableClearable = true,
  freeSolo = true,
  handleOnChange,
  handleOnInputChange,
  id,
  inputValue,
  invalidSearch,
  label,
  loading,
  options,
  placeholder,
  required,
  value,
  ...params
}) => {
  const classes = useStyles();

  return (
    <StyledAOAutocomplete>
      <Autocomplete
        freeSolo={freeSolo}
        disableClearable={disableClearable}
        value={value}
        inputValue={inputValue}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        id={id}
        options={options ?? []}
        loading={loading}
        data-testid={id}
        filterOptions={(options) => options}
        popupIcon={!value?.length && <SearchIcon />}
        classes={{
          root: classes.root,
          popupIndicatorOpen: classes.popupIndicatorOpen,
        }}
        {...params}
        renderInput={(params) => (
          <TextField
            {...params}
            error={invalidSearch}
            placeholder={placeholder}
            label={label}
            variant="outlined"
            helperText={invalidSearch}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </StyledAOAutocomplete>
  );
};

AOAppetiteAutocomplete.propTypes = {
  disableClearable: PropTypes.bool,
  freeSolo: PropTypes.bool,
  handleOnChange: PropTypes.func,
  handleOnInputChange: PropTypes.func,
  id: PropTypes.string,
  inputValue: PropTypes.string,
  invalidSearch: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export default AOAppetiteAutocomplete;
