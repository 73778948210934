import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const AOAlert = ({ alertTitleMessage, message, children, ...props }) => {
  return (
    <Box my={2}>
      <Alert {...props}>
        {alertTitleMessage && (
          <AlertTitle>
            <strong>{alertTitleMessage}</strong>
            {message && <Box mb={2} />}
          </AlertTitle>
        )}
        {message}
        {children}
      </Alert>
    </Box>
  );
};

AOAlert.defaultProps = {};

AOAlert.propTypes = {
  alertTitleMessage: PropTypes.string,
  children: PropTypes.node,
  message: PropTypes.string,
};

export default AOAlert;
