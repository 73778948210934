import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';

import { Box, Paper } from '@material-ui/core';
import Col from '../../../ScreenSmith/Col';
import Row from '../../../ScreenSmith/Row';
import RowCol from '../../../ScreenSmith/RowCol';
import Typography from '@material-ui/core/Typography';
import { AODataGrid } from '../../../AODataGrid';
import axios from 'axios';
import { USER_ADMIN_HISTORY } from '../../../../constants/api';

const viewHistoryInfoResultColumns = [
  {
    field: 'userName',
    headerName: 'User Name',
    flex: 1,
  },
  {
    field: 'adminName',
    headerName: 'Admin',
    flex: 1,
  },
  {
    field: 'newValue',
    headerName: 'Change',
    flex: 1,
  },
  {
    field: 'item',
    headerName: 'Item',
    flex: 1,
  },
  {
    field: 'changeDateTime',
    headerName: 'Date',
    flex: 1,
  },
];

const EditUserHistoryPanel = ({ userDetails }) => {
  const [pageSize, setPageSize] = React.useState(25);
  let [viewUserAdminHistoryResults, setUserAdminHistory] = useState(null);
  useEffect(async () => {
    try {
      const getUserAdminHistoryUrl = USER_ADMIN_HISTORY + '/' + userDetails?.name;
      const response = await axios.get(getUserAdminHistoryUrl);
      console.log('response.data-->' + JSON.stringify(response.data));
      setUserAdminHistory(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <>
      <Row spacing={2}>
        <Col xs={5}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <Box p={1}>
              <Typography>
                <Box fontSize={'1.25rem'} fontWeight={'bold'}>
                  Name
                </Box>
              </Typography>
            </Box>
            <Box p={1} display="flex">
              <Box ml={2}>
                <Typography gutterBottom>
                  <Box textAlign={'right'} mr={2}>
                    First Name:
                  </Box>
                </Typography>
                <Typography gutterBottom>
                  <Box textAlign={'right'} mr={2}>
                    Last Name:
                  </Box>
                </Typography>
                <Typography gutterBottom>
                  <Box textAlign={'right'} mr={2}>
                    User Name:
                  </Box>
                </Typography>
              </Box>
              <Box>
                <Typography gutterBottom>
                  <Box fontWeight={'bold'} color={'primary.main'}>
                    {userDetails?.givenName}
                  </Box>
                </Typography>
                <Typography gutterBottom>
                  <Box fontWeight={'bold'} color={'primary.main'}>
                    {userDetails?.sn}
                  </Box>
                </Typography>
                <Typography gutterBottom>
                  <Box fontWeight={'bold'} color={'primary.main'}>
                    {userDetails?.name}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Col>
        <Col xs={7}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <RowCol>
              <Box p={1}>
                <Typography>
                  <Box fontSize={'1.25rem'} fontWeight={'bold'}>
                    View User History
                  </Box>
                </Typography>
              </Box>
              <Box p={1}>
                <Typography>
                  The list below shows all events pertaining to the User displayed at the left.
                  Click on any column header, then <></>A to Z or Z to A to sort.
                </Typography>
              </Box>
            </RowCol>
          </Paper>
        </Col>
      </Row>

      {viewUserAdminHistoryResults && (
        <Box>
          <Typography variant="h6">Search Results </Typography>
          <AODataGrid
            autoHeight={true}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            pagination
            rows={viewUserAdminHistoryResults.map((row) => ({ ...row, id: cuid() }))}
            columns={viewHistoryInfoResultColumns}
          />
        </Box>
      )}
    </>
  );
};

EditUserHistoryPanel.propTypes = {
  userDetails: PropTypes.object,
};

export default EditUserHistoryPanel;
