import axios from 'axios';

const AOC_API_POLICIES_URL = `${process.env.REACT_APP_API_URL}/policies`;

export const getPolicy = async (policyNumber, inquiryDate) => {
  const { data } = await axios.get(`${AOC_API_POLICIES_URL}/${policyNumber}?inquiryDate=${inquiryDate}`);
  return data;
};

export default { getPolicy };
