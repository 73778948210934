import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { GET_LOSSES_BY_AGENT_URL } from 'constants/api';

import { handleOnChangeMonthYear } from './LossesByAgentReport.api';
import { handleCreateLbaReport, handleAgencyCodeOnClick } from './LossesByAgentReport.api';
import LossesByAgentReportAgentView from './LossesByAgentReportAgentView';
import { handleOnChange } from 'components/CommonUtilityServices/EventHandlerService';

import { Alert, Box, Button, Card, CardContent, CardHeader, Col, Row, Select, TextField, ViewContainer } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';

const LossesByAgentReport = () => {
  const [periodOptions, setPeriodOptions] = useState([]);
  const [permAgenciesOptions, setPermAgenciesOptions] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [isDownloadingFileErrorVisible, setIsDownloadingFileErrorVisible] = useState(false);
  const [downloadingFileError, setDownloadingFileError] = useState(false);
  const [formData, setFormData] = useState({
    agencyCode: '',
    period: '',
  });

  useEffect(async () => {
    const dateData = await axios.get(`${GET_LOSSES_BY_AGENT_URL}/dates`);
    let { data: dates } = dateData;
    dates = dates.map((date) => {
      const dateValue = date.dateValue.split(' ');
      return { ...date, value: dateValue[0], label: date.dateDisplay };
    });
    console.log('Dates: ' + dates);
    setPeriodOptions(dates);
  }, []);

  const generateReportFormDataRef = useRef({});

  const handleAgencyCodeOnClick_PROXY = (e) => handleAgencyCodeOnClick(e, formData, setFormData);
  const handleOnChangeMonthYear_PROXY = (e) => handleOnChangeMonthYear(e, formData, setFormData);
  const handleCreateLbaReport_PROXY = (e) =>
    handleCreateLbaReport(e, formData, setLoadingFile, setIsDownloadingFileErrorVisible, setDownloadingFileError, permAgenciesOptions);
  const handleOnChange_PROXY = (e) => handleOnChange(e, formData, setFormData, generateReportFormDataRef);

  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
      <Feature allowed="Access_LossByAgent_Report">
        <Card>
          <CardHeader title="Losses by Agent Reports" />
          <CardContent>
            <Box color="text.secondary">
              <Box>
                <Box p={2}>
                  <Row>
                    <Col>{isDownloadingFileErrorVisible && <Alert message={downloadingFileError} severity="error" />}</Col>
                  </Row>
                  <Feature allowed="isAgent">
                    <LossesByAgentReportAgentView
                      onChange={handleOnChange_PROXY}
                      setPermAgenciesOptions={setPermAgenciesOptions}
                      menuItems={permAgenciesOptions}
                      value={formData.agencyCode}
                    />
                  </Feature>
                  <Box mb={2}>
                    <Select
                      // onChange={handleOnChange_PROXY} TODO ANDRE WHICH ONE
                      onChange={handleOnChangeMonthYear_PROXY}
                      value={formData.period}
                      menuItems={periodOptions}
                      id="lba-period"
                      labelId="lba-period-label"
                      label="Month/Year"
                      name="period"
                      style={{ minWidth: '435px' }}
                    />
                  </Box>
                  <Feature allowed="internalUser">
                    <Box mb={2}>
                      <TextField
                        onChange={handleAgencyCodeOnClick_PROXY}
                        value={formData.agencyCode}
                        label="Agent Id"
                        placeholder="Enter Search Input"
                        style={{ minWidth: '435px' }}
                      />
                    </Box>
                  </Feature>
                  <Box mb={2}>
                    <Row spacing={2} alignItems="center">
                      <Col>
                        <Button
                          id="lba-generate-report-pdf-button"
                          onClick={handleCreateLbaReport_PROXY}
                          loading={loadingFile}
                          variant="contained"
                          color="primary"
                          data-export-type="pdf">
                          Generate Pdf Report
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          id="lba-generate-report-csv-button"
                          onClick={handleCreateLbaReport_PROXY}
                          loading={loadingFile}
                          variant="contained"
                          color="primary"
                          data-export-type="excel">
                          Generate Excel Report
                        </Button>
                      </Col>
                    </Row>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Feature>
    </ViewContainer>
  );
};
export default LossesByAgentReport;
