import React from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import ClaimsSkeleton from './ClaimsSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';

const OneStopClaimReporting = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`OneStopClaimReporting`);

  const localStateMap = {};

  const callBackFunctions = {};

  return loadingSd ? (
    <ClaimsSkeleton />
  ) : (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default OneStopClaimReporting;
