import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Typography, Box, CircularProgress, Divider, Modal, Paper, FormControlLabel, Switch } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import { AOButton } from '../AOButton';
import BrandFolderGridCard from './BrandFolderCard';
import { Row, Col } from '../ScreenSmith';
import { BRANDFOLDER_DOCS_PER_PAGE, BRANDFOLDER_SEARCH } from '../../constants/api';
import { LAMBDA_TIMEOUT_504_ERROR } from '../../constants/messages';
import AppDataContext from '../../contexts/appData.context';
import { SupplyOrdering } from '../AgencyToolkit/SupplyOrdering';

const BrandFolderGrid = ({ searchCriteria, showViewMultipleButton, showSupplyOrderButton }) => {
  const [loading, setLoading] = useState(true);
  const [gridSize, setGridSize] = useState({ rows: 1, columns: 1 });
  const [currentPage, setCurrentPage] = useState(1);
  const [documents, setDocuments] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState({});
  const [showSupplyOrderModal, setShowSupplyOrderModal] = useState(false);
  const [thumbnailView, setThumbnailView] = useState(false);

  const { handleSetSnackbarData } = useContext(AppDataContext);

  const selectedDocumentCount = Object.keys(selectedDocuments).length;
  const MAX_LIMIT_ORDER_ASSETS = 10;

  // Initial load get rows and columns per page from config
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${BRANDFOLDER_DOCS_PER_PAGE}`, {
          params: { page: 'Underwriting' },
        });
        setGridSize(response.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  // When search criteria changes, fetch documents
  useEffect(() => {
    if (searchCriteria.length > 0) {
      const abortController = new AbortController();

      (async () => {
        try {
          setLoading(true);
          setCurrentPage(1);
          setSelectedDocuments({});
          const response = await axios.get(BRANDFOLDER_SEARCH, {
            params: { search_criteria: searchCriteria },
            signal: abortController.signal,
          });

          if (abortController.signal.aborted) {
            return;
          }

          setDocuments(response.data);
        } catch (error) {
          console.error(error);

          let message = error.message;
          // The API doesn't return a proper error code in the response when it errors out.
          // As a work around, we will use the same logic as WIP when the result set is too large.
          if (error.code === 504 || error.message === 'Network Error') {
            message = LAMBDA_TIMEOUT_504_ERROR;
          }
          // Show error if the error is not due to canceled API call (i.e. changing criteria that triggers a fetch mid call)
          if (error.code !== 'ERR_CANCELED') {
            handleSetSnackbarData({
              message,
              severity: 'error',
            });
          }
        } finally {
          setLoading(false);
        }
      })();

      return () => {
        abortController.abort();
      };
    }
  }, [searchCriteria]);

  const handleDownloadOnClick = () => {
    if (selectedDocumentCount > 0) {
      const download = (path, filename) => {
        // Create a new link
        const anchor = document.createElement('a');
        anchor.href = path;
        anchor.target = '_blank';
        anchor.rel = 'noreferrer';
        anchor.download = filename;
        // Append to the DOM
        document.body.appendChild(anchor);
        // Trigger `click` event
        anchor.click();
        // Remove element from DOM
        document.body.removeChild(anchor);
      };

      for (const docKey in selectedDocuments) {
        download(selectedDocuments[docKey].docUrl);
      }

      setSelectedDocuments({});
    } else {
      handleSetSnackbarData({ message: 'Select document(s) to download.' });
    }
  };

  const handlePaginationOnChange = (e, page) => {
    window.scrollTo(0, 0);
    setCurrentPage(page);
  };

  const handleToggleChecked = (id, url, docName) => {
    const newSelectedDocuments = { ...selectedDocuments };
    if (newSelectedDocuments[id]) {
      delete newSelectedDocuments[id];
    } else {
      newSelectedDocuments[id] = { docName: docName, docUrl: url };
    }
    setSelectedDocuments(newSelectedDocuments);
  };

  const renderResults = () => {
    if (documents?.length > 0) {
      const renderItems = () => {
        return documents?.map((doc, index) => {
          const renderCard = () => (
            <BrandFolderGridCard
              key={`${docId}-${index}-item`}
              document={doc}
              checked={selectedDocuments[docId] ? true : false}
              handleToggleChecked={handleToggleChecked}
              thumbnailView={thumbnailView}
            />
          );

          const docId = doc?.asset_id;
          return (
            index >= gridSize.rows * gridSize.columns * (currentPage - 1) &&
            index < gridSize.rows * gridSize.columns * currentPage &&
            (thumbnailView ? (
              <Col xs={12} sm={6} md={12 / gridSize.columns} key={`${docId}-${index}`}>
                {renderCard()}
              </Col>
            ) : (
              renderCard()
            ))
          );
        });
      };

      return thumbnailView ? <Row spacing={4}>{renderItems()}</Row> : renderItems();
    } else if (documents?.length === 0) {
      return <Typography color="textSecondary">No results found. Adjust your search or filters.</Typography>;
    } else {
      return null;
    }
  };

  const handleAddToSupplyOrderOnClick = () => {
    if (selectedDocumentCount > 0) {
      if (selectedDocumentCount > MAX_LIMIT_ORDER_ASSETS) {
        handleSetSnackbarData({ message: 'Supply order must be limited to 10 documents' });
      } else {
        setShowSupplyOrderModal(true);
      }
    } else {
      handleSetSnackbarData({ message: 'Select document(s) to order.' });
    }
  };

  const handleThumbnailViewOnChange = (e) => {
    setThumbnailView(e.target.checked);
  };

  return loading ? (
    <Box display="flex" justifyContent="center" height="100%" pt={5}>
      <CircularProgress color="primary" />
    </Box>
  ) : (
    <>
      <Box mb={2} display="flex" justifyContent="flex-end" position="absolute" right={16} top={-64}>
        <Box display="flex" alignItems="center">
          <Box mr={3} display="flex">
            <Typography component="div" color="textSecondary">
              <Box mr={3}>
                {selectedDocumentCount > 0
                  ? `${selectedDocumentCount} ITEM${selectedDocumentCount > 1 ? 'S' : ''} OF ${documents?.length} SELECTED`
                  : `${documents?.length ?? 0} ITEM${documents?.length !== 1 ? 'S' : ''}`}
              </Box>
            </Typography>
            <Divider orientation="vertical" flexItem />
          </Box>
          {showSupplyOrderButton && (
            <Box mr={2}>
              <AOButton
                id="show-supply-order-modal-button"
                name="showSupplyOrderModalButton"
                variant="text"
                color="secondary"
                onClick={handleAddToSupplyOrderOnClick}>
                Add to supply order
              </AOButton>
            </Box>
          )}
          <Box mr={3} display="flex">
            <FormControlLabel
              control={<Switch checked={thumbnailView} onChange={handleThumbnailViewOnChange} name="thumbnailView" color="primary" />}
              label="Thumbnail View"
            />
            <Box mr={1} />
            <Divider orientation="vertical" flexItem />
          </Box>
          {showViewMultipleButton && (
            <AOButton color={'primary'} id="brand-folder-container-button-download" onClick={handleDownloadOnClick} variant="contained">
              View Multiple
            </AOButton>
          )}
        </Box>
      </Box>
      {renderResults()}
      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Pagination count={documents?.length > 0 ? Math.ceil(documents?.length / (gridSize.columns * gridSize.rows)) : 1} onChange={handlePaginationOnChange} />
      </Box>
      <Modal
        open={showSupplyOrderModal}
        onClose={() => setShowSupplyOrderModal(false)}
        aria-labelledby="supply-order-modal"
        aria-describedby="supply-order-modal-des"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper>
          <Box p={3} sx={{ overflow: 'auto', maxHeight: '80vh' }}>
            <SupplyOrdering
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              setShowSupplyOrderModal={setShowSupplyOrderModal}
            />
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

BrandFolderGrid.defaultProps = {
  showViewMultipleButton: true,
};

BrandFolderGrid.propTypes = {
  searchCriteria: PropTypes.string,
  showViewMultipleButton: PropTypes.bool,
  showSupplyOrderButton: PropTypes.bool,
};

export default BrandFolderGrid;
