import React from 'react';
import PropTypes from 'prop-types';

import RowCol from '../ScreenSmith/RowCol';
import { AOSelect } from '../AOSelect';
import { GET_PERM_AGENCIES } from '../../constants/api';
import useFetchSelectMenuItems from '../../hooks/useFetchSelectMenuItems';

const LossesByAgentReportAgentView = ({ menuItems, onChange, setPermAgenciesOptions, value }) => {
  useFetchSelectMenuItems(GET_PERM_AGENCIES, (response) => {
    if (response.data.length) {
      setPermAgenciesOptions([
        ...response.data.map((agency) => ({
          value: `${
            agency.agencyCode
          } - ${agency.agencyCity?.trim()}, ${agency.agencyState?.trim()}`,
          code: agency.agencyCode,
        })),
      ]);
    }
  });

  return (
    <RowCol mb={2}>
      <AOSelect
        id="lba-agent-id"
        labelId="lba-agent-id-label"
        inputLabel="Agent ID"
        name="agencyCode"
        menuItems={menuItems}
        onChange={onChange}
        value={value}
        style={{ minWidth: '435px' }}
      />
    </RowCol>
  );
};

LossesByAgentReportAgentView.propTypes = {
  menuItems: PropTypes.array,
  onChange: PropTypes.func,
  setPermAgenciesOptions: PropTypes.func,
  value: PropTypes.string,
};

export default LossesByAgentReportAgentView;
