import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, FormControlLabel, Checkbox, Divider } from '@material-ui/core';

import { AODatePicker } from '../AODatePicker';
import { AOSelect } from '../AOSelect';

export const defaultLOB = 'All';

const defaultOptions = [
  { value: 'All Lines', code: defaultLOB },
  { value: 'All Personal Lines', code: 'All Personal Lines' },
  { value: 'All Commercial Lines', code: 'All Commercial Lines' },
];

const extendedOptions = [
  { value: 'HomeOwners', code: 'HomeOwners' },
  { value: 'Personal Automobile', code: 'Personal Automobile' },
];

// To do: Move this to an API call after FastAPI migration is complete (agreed by Jeff D and Brent E)
const policyStates = [
  {
    code: '01',
    value: 'Alabama',
  },
  {
    code: '54',
    value: 'Alaska',
  },
  {
    code: '02',
    value: 'Arizona',
  },
  {
    code: '03',
    value: 'Arkansas',
  },
  {
    code: '04',
    value: 'California',
  },
  {
    code: '05',
    value: 'Colorado',
  },
  {
    code: '06',
    value: 'Connecticut',
  },
  {
    code: '07',
    value: 'Delaware',
  },
  {
    code: '09',
    value: 'Florida',
  },
  {
    code: '10',
    value: 'Georgia',
  },
  {
    code: '52',
    value: 'Hawaii',
  },
  {
    code: '11',
    value: 'Idaho',
  },
  {
    code: '12',
    value: 'Illinois',
  },
  {
    code: '13',
    value: 'Indiana',
  },
  {
    code: '14',
    value: 'Iowa',
  },
  {
    code: '15',
    value: 'Kansas',
  },
  {
    code: '16',
    value: 'Kentucky',
  },
  {
    code: '17',
    value: 'Louisiana',
  },
  {
    code: '18',
    value: 'Maine',
  },
  {
    code: '19',
    value: 'Maryland',
  },
  {
    code: '20',
    value: 'Massachusetts',
  },
  {
    code: '21',
    value: 'Michigan',
  },
  {
    code: '22',
    value: 'Minnesota',
  },
  {
    code: '23',
    value: 'Mississippi',
  },
  {
    code: '24',
    value: 'Missouri',
  },
  {
    code: '25',
    value: 'Montana',
  },
  {
    code: '26',
    value: 'Nebraska',
  },
  {
    code: '27',
    value: 'Nevada',
  },
  {
    code: '28',
    value: 'New Hampshire',
  },
  {
    code: '29',
    value: 'New Jersey',
  },
  {
    code: '30',
    value: 'New Mexico',
  },
  {
    code: '31',
    value: 'New York',
  },
  {
    code: '32',
    value: 'North Carolina',
  },
  {
    code: '33',
    value: 'North Dakota',
  },
  {
    code: '34',
    value: 'Ohio',
  },
  {
    code: '35',
    value: 'Oklahoma',
  },
  {
    code: '36',
    value: 'Oregon',
  },
  {
    code: '37',
    value: 'Pennsylvania',
  },
  {
    code: '38',
    value: 'Rhode Island',
  },
  {
    code: '39',
    value: 'South Carolina',
  },
  {
    code: '40',
    value: 'South Dakota',
  },
  {
    code: '41',
    value: 'Tennessee',
  },
  {
    code: '42',
    value: 'Texas',
  },
  {
    code: '43',
    value: 'Utah',
  },
  {
    code: '44',
    value: 'Vermont',
  },
  {
    code: '45',
    value: 'Virginia',
  },
  {
    code: '46',
    value: 'Washington',
  },
  {
    code: '47',
    value: 'West Virginia',
  },
  {
    code: '48',
    value: 'Wisconsin',
  },
  {
    code: '49',
    value: 'Wyoming',
  },
];

const policyRegions = [
  {
    code: 'NE',
    value: 'New England Region',
  },
  {
    code: 'NO',
    value: 'Northeast Region',
  },
  {
    code: 'SE',
    value: 'Southeast Region',
  },
  {
    code: 'WE',
    value: 'Western Region',
  },
];

const BMReportForm = ({
  eftChoiceValue,
  fromDateValue,
  onlyNonRenewValue,
  handleEftChoiceOnChange,
  handleFromDateOnChange,
  handleLOBOnChange,
  handleOnlyNonRenewOnChange,
  handleRegionOnChange,
  handleShowAddressOnChange,
  handleStateNumericCodeOnChange,
  handleToDateOnChange,
  lobValue,
  regionValue,
  reportType,
  showAddress,
  stateNumericCodeValue,
  toDateValue,
}) => {
  const getLOBMenuItems = (lobMenuOptions) => {
    switch (lobMenuOptions) {
      case 0:
        return [...defaultOptions, ...extendedOptions];
      case 1:
        return [{ value: 'Both', code: defaultLOB }, ...extendedOptions];
      default:
        return [...defaultOptions];
    }
  };

  const renderReportForm = ({
    lobMenuOptions,
    dateText,
    hideAddress,
    hideDate,
    showExpiringPolicies,
    showPaymentMethod,
    showPolicyStatus,
  }) => (
    <>
      <Box mb={3}>
        <AOSelect
          id={'bmreport-lob-select'}
          labelId={'bmreport-lob-select-label'}
          inputLabel={'Select Line of Business'}
          value={lobValue}
          onChange={handleLOBOnChange}
          menuItems={getLOBMenuItems(lobMenuOptions)}
          name={'lob'}
        />
      </Box>

      {showExpiringPolicies && (
        <Box mb={3}>
          <AOSelect
            id={'bmreport-onlyNonRenew-select'}
            labelId={'bmreport-onlyNonRenew-select-label'}
            inputLabel={'Show report of'}
            name={'onlyNonRenew'}
            value={onlyNonRenewValue}
            onChange={handleOnlyNonRenewOnChange}
            menuItems={[
              { value: 'All expiring policies', code: 'false' },
              { value: 'Expiring policies not renewed', code: 'true' },
            ]}
          />
        </Box>
      )}

      {showPaymentMethod && (
        <Box mb={3}>
          <AOSelect
            id={'bmreport-eftChoice-select'}
            labelId={'bmreport-eftChoice-select-label'}
            inputLabel={'Select EFT Report Choice'}
            name={'eftChoice'}
            value={eftChoiceValue}
            onChange={handleEftChoiceOnChange}
            menuItems={[
              { value: 'Insured Using EFT', code: '0' },
              { value: 'Insured Not Using EFT', code: '1' },
              { value: 'Both', code: '2' },
            ]}
          />
        </Box>
      )}

      {dateText && <Typography>{dateText}</Typography>}
      {!hideDate && (
        <Box mt={2} mb={3}>
          <AODatePicker
            id={'bmreport-from-datepicker'}
            name={'fromDate'}
            label={'From'}
            value={fromDateValue}
            onChange={handleFromDateOnChange}
          />
          <Box mr={2} display={'inline'} />
          <AODatePicker
            id={'bmreport-to-datepicker'}
            name={'toDate'}
            label={'To'}
            value={toDateValue}
            onChange={handleToDateOnChange}
          />
        </Box>
      )}

      {!hideAddress && (
        <Box mb={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAddress}
                onChange={handleShowAddressOnChange}
                name={'showAddress'}
                color="primary"
              />
            }
            label="Insured Address"
          />
        </Box>
      )}

      {showPolicyStatus && (
        <>
          <Typography>
            To narrow your search, select a state or region from the options below
          </Typography>
          <Box mt={2} mb={3}>
            <AOSelect
              id={'bmreport-stateNumericCode-select'}
              labelId={'bmreport-stateNumericCode-select-label'}
              inputLabel={'State'}
              name={'stateNumericCode'}
              value={stateNumericCodeValue}
              onChange={handleStateNumericCodeOnChange}
              menuItems={policyStates}
            />
            <Box mr={2} display={'inline'} />
            <AOSelect
              id={'bmreport-region-select'}
              labelId={'bmreport-region-select-label'}
              inputLabel={'Region'}
              name={'region'}
              value={regionValue}
              onChange={handleRegionOnChange}
              menuItems={policyRegions}
            />
          </Box>
        </>
      )}
    </>
  );

  const renderReport = () => {
    switch (reportType) {
      case 'book-mgmt-non-pay-policy':
        return renderReportForm({
          dateText: 'Range of Notice Effective Dates',
        });
      case 'book-mgmt-cancelled-policy':
        return renderReportForm({
          lobMenuOptions: 0,
          dateText: 'Range of Cancel Effective Dates',
        });
      case 'book-mgmt-expiring-policy':
        return renderReportForm({
          dateText: 'Range of Expiration Dates',
          showExpiringPolicies: true,
          lobMenuOptions: 0,
        });
      case 'book-mgmt-installment-due':
        return renderReportForm({});
      case 'book-mgmt-payment-method':
        return renderReportForm({ hideDate: true, showPaymentMethod: true });
      case 'book-mgmt-policy-status':
        return renderReportForm({
          hideDate: true,
          hideAddress: true,
          showPolicyStatus: true,
          lobMenuOptions: 1,
        });
      default:
        return null;
    }
  };

  return (
    reportType && (
      <>
        <Divider />
        <Box mb={3} />
        {renderReport()}
      </>
    )
  );
};

BMReportForm.propTypes = {
  reportType: PropTypes.string,
  lobValue: PropTypes.string,
  eftChoiceValue: PropTypes.string,
  fromDateValue: PropTypes.string,
  onlyNonRenewValue: PropTypes.string,
  toDateValue: PropTypes.string,
  showAddress: PropTypes.bool,
  handleLOBOnChange: PropTypes.func,
  handleFromDateOnChange: PropTypes.func,
  handleToDateOnChange: PropTypes.func,
  handleEftChoiceOnChange: PropTypes.func,
  handleShowAddressOnChange: PropTypes.func,
  handleOnlyNonRenewOnChange: PropTypes.func,
  stateNumericCodeValue: PropTypes.string,
  handleStateNumericCodeOnChange: PropTypes.func,
  regionValue: PropTypes.string,
  handleRegionOnChange: PropTypes.func,
};

export default BMReportForm;
