import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';

import { componentIdStringGenerator } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: `${rem(280)}`,

    [theme.breakpoints.down('sm')]: {
      minWidth: `${rem(250)}`,
    },
  },
}));

const Autocomplete = (props) => {
  const classes = useStyles();

  return (
    <MuiAutocomplete
      className={classes.root}
      freeSolo
      {...props}
      id={componentIdStringGenerator('Autocomplete', props.id ?? '')}
    />
  );
};

Autocomplete.propTypes = {
  id: PropTypes.string,
};

export default Autocomplete;
