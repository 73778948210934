import React, { useState, useEffect, useRef, useContext } from 'react';
import _ from 'lodash';
import cuid from 'cuid';
import { useHistory } from 'react-router-dom';

import { ScreenSmith } from '../ScreenSmith';
import { CardTabSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import useFetchSelectMenuItems from '../../hooks/useFetchSelectMenuItems';
import { ReportsHeader } from '../ReportsHeader';
import { ClaimsInquiryDetails } from '../ClaimsInquiryDetails';
import AppDataContext from '../../contexts/appData.context';
import { useDebounce } from '../../hooks/useDebounce';

import { StyledTransactionActivityReport } from './TransactionActivityReports.styled';
import { fetchFormSuggestions } from 'components/AORedesignViews/Reports/BillingReports/TransactionActivityReports/TransactionActivityReports.api';
import {
  fetchTAReportsFormData,
  handleAOTransferListSetLeft,
  handleAOTransferListSetRight,
  handleCreateTransactionActivityReport,
  handleLOBOnChange,
  handleOnSortModelChange,
  handleGenerateOnClick,
  periodMenuItems,
  getReportResultsColumns,
  handleClickPaginationChange,
  handleExportDropdownOnChange,
  handleOnRowDoubleClick,
} from './TransactionActivityReports.api';
import { aODatePickerMinDate } from '../../helpers';
import { GET_PERM_AGENCIES } from '../../constants/api';
import {
  fetchAgencyNameSuggestions,
  handleAOAutocompleteOnBlur,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleOnChange,
  handleFromDateOnChange,
  handleToDateOnChange,
} from '../CommonUtilityServices/EventHandlerService';

const LocalComponents = {
  ReportsHeader,
  ClaimsInquiryDetails,
};

const TransactionActivityReports = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('TransactionActivityReports');
  const [lOBs, setLOBs] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [transactionSubtypes, setTransactionSubtypes] = useState([]);
  const [checkedTransferListItems, setCheckedTransferListItems] = useState([]);
  const [policyNumberOptions, setPolicyNumberOptions] = useState([]);
  const [agencyNameOptions, setAgencyNameOptions] = useState([]);
  const [accountNumberOptions, setAccountNumberOptions] = useState([]);
  const [permAgenciesOptions, setPermAgenciesOptions] = useState([]);
  const [partialInsuredNameOptions, setPartialInsuredNameOptions] = useState([]);
  const [reportResults, setReportResults] = useState({});
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);
  const [exportRangeMenuItems, setExportRangeMenuItems] = useState([]);
  const [exportRangeValue, setExportRangeValue] = useState(1);
  const [createButtonLoading, setCreateButtonLoading] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);

  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    policyNumber: { value: '', invalidText: '' },
    agencyCodes: { value: '' },
    accountNumber: { value: '', invalidText: '' },
    partialInsuredName: { value: '', invalidText: '' },
  });
  const [formData, setFormData] = useState({
    accountNumber: '',
    agencyCodes: '',
    pageNumber: 1,
    fromDate: null,
    lob: 'ALL',
    lobGroup: 'ALL',
    partialInsuredName: '',
    period: periodMenuItems[0].code,
    policyNumber: '',
    toDate: null,
    transactionSubTypes: [],
    transactionType: 'ALL',
  });

  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);

  const generateReportFormDataRef = useRef({});

  const history = useHistory();
  const { setPreviousPageName } = useContext(AppDataContext);

  useFetchSelectMenuItems(GET_PERM_AGENCIES, (response) => {
    if (response.data.length) {
      // External users will get data in from this call. Internal will return empty array.
      const allAgencies = { value: `ALL - 000000`, code: '000000' };
      setFormData({ ...formData, agencyCodes: allAgencies.code });
      setPermAgenciesOptions([
        allAgencies,
        ...response.data.map(
          (agency) => (
            { value: `ALL - 000000`, code: '000000' },
            {
              value: `${agency.agencyName?.trim()} - ${agency.agencyCode}`,
              code: agency.agencyCode,
            }
          ),
        ),
      ]);

      // If external user, generate the Grid
      handleCreateTransactionActivityReport(
        formData,
        setReportResults,
        generateReportFormDataRef,
        setExportRangeMenuItems,
        setExportRangeValue,
        setCreateButtonLoading,
      );
    }
  });

  useEffect(async () => {
    if (!_.isNil(UiDefinitionsData)) {
      const tAReportsLOBs = await fetchTAReportsFormData('LOBs');
      setLOBs(tAReportsLOBs);
      const tAReportsTransactionTypes = await fetchTAReportsFormData('transaction-types');
      setTransactionTypes(tAReportsTransactionTypes);
      const tAReportsTransactionSubtypes = await fetchTAReportsFormData('transaction-subtypes');
      setTransactionSubtypes(tAReportsTransactionSubtypes);
    }
  }, [UiDefinitionsData]);

  useEffect(async () => {
    if (autocompleteInputValues.policyNumber.value.length >= 3) {
      const suggestions = await fetchFormSuggestions('policy-suggestions', autocompleteInputValues.policyNumber.value);
      setPolicyNumberOptions(suggestions);
    } else {
      setPolicyNumberOptions([]);
    }
  }, [debouncedState.policyNumber]);

  useEffect(async () => {
    if (autocompleteInputValues.agencyCodes.value.length >= 3) {
      const suggestions = await fetchAgencyNameSuggestions(autocompleteInputValues.agencyCodes.value);
      setAgencyNameOptions(suggestions);
    } else {
      setAgencyNameOptions([]);
    }
  }, [debouncedState.agencyCodes]);

  useEffect(async () => {
    if (autocompleteInputValues.accountNumber.value.length >= 3) {
      const suggestions = await fetchFormSuggestions('account-suggestions', autocompleteInputValues.accountNumber.value);
      setAccountNumberOptions(suggestions);
    } else {
      setAccountNumberOptions([]);
    }
  }, [debouncedState.accountNumber]);

  useEffect(async () => {
    if (autocompleteInputValues.accountNumber.value.length >= 3) {
      const suggestions = await fetchFormSuggestions('account-suggestions', autocompleteInputValues.partialInsuredName.value);
      setPartialInsuredNameOptions(suggestions);
    } else {
      setPartialInsuredNameOptions([]);
    }
  }, [debouncedState.partialInsuredName]);

  const transactionActivityReportsState = {
    period: formData.period,
    periodMenuItems,
    fromDate: formData.fromDate,
    toDate: formData.toDate,
    policyNumber: formData.policyNumber,
    agencyCodes: formData.agencyCodes,
    lobGroup: formData.lobGroup,
    lob: formData.lob,
    transactionType: formData.transactionType,
    accountNumber: formData.accountNumber,
    partialInsuredName: formData.partialInsuredName,
    selectedTransactionSubtypes: formData.transactionSubTypes,
    lOBs: lOBs.map((lob) => ({ group: lob.lobGroupName, value: lob.lobName, code: lob.lobName })),
    transactionTypes: transactionTypes.map((tt) => ({ value: tt, code: tt })),
    availableTransactionSubtypes: transactionSubtypes,
    checkedTransferListItems,
    policyNumberOptions,
    policyNumberInputValue: autocompleteInputValues.policyNumber.value,
    policyNumberInvalidText: autocompleteInputValues.policyNumber.invalidText,
    agencyNameOptions,
    agencyNameInputValue: autocompleteInputValues.agencyCodes.value,
    accountNumberOptions,
    accountNumberInputValue: autocompleteInputValues.accountNumber.value,
    accountNumberInvalidText: autocompleteInputValues.accountNumber.invalidText,
    partialInsuredNameOptions,
    partialInsuredNameInputValue: autocompleteInputValues.partialInsuredName.value,
    partialInsuredNameInvalidText: autocompleteInputValues.partialInsuredName.invalidText,
    showDataGrid: reportResults.total > 0,
    reportResultsData: reportResults.data?.map((el) => ({
      id: cuid(),
      ...el,
    })),
    reportResultsColumns: getReportResultsColumns(setSelectedClaim),
    exportRangeValue: exportRangeValue,
    exportRangeMenuItems: exportRangeMenuItems,
    loadingPdf,
    downloadingPdfError,
    isDownloadingPdfErrorVisible,
    showCustomDateRange: formData?.period === -1,
    permAgenciesOptions,
    paginationRowCount: reportResults.total,
    createButtonLoading,
    selectedClaim,
  };

  const transactionActivityReportsCallbackFunctions = {
    handleOnChange: (e) => handleOnChange(e, formData, setFormData),
    handleLOBOnChange: (e) => handleLOBOnChange(e, lOBs, formData, setFormData),
    handleFromDateOnChange: (date) => handleFromDateOnChange(date, formData, setFormData),
    handleToDateOnChange: (date) => handleToDateOnChange(date, formData, setFormData),
    setCheckedTransferListItems,
    handleAOTransferListSetLeft: (data) => handleAOTransferListSetLeft(data, setTransactionSubtypes),
    handleAOTransferListSetRight: (data) => handleAOTransferListSetRight(data, formData, setFormData),
    handleCreateTransactionActivityReport: () =>
      handleCreateTransactionActivityReport(
        formData,
        setReportResults,
        generateReportFormDataRef,
        setExportRangeMenuItems,
        setExportRangeValue,
        setCreateButtonLoading,
      ),
    handlePolicyNumberOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange('policyNumber', e, value, autocompleteInputValues, setAutocompleteInputValues, debouncedState, setDebouncedState),
    handlePolicyNumberOnChange: (e, value) => handleAOAutocompleteOnChange('policyNumber', e, value, formData, setFormData),
    handlePolicyNumberOnBlur: () => handleAOAutocompleteOnBlur('policyNumber', formData, setFormData, autocompleteInputValues),
    handleAgencyNameOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange('agencyCodes', e, value, autocompleteInputValues, setAutocompleteInputValues, debouncedState, setDebouncedState),
    handleAgencyNameOnChange: (e, value) => handleAOAutocompleteOnChange('agencyCodes', e, value, formData, setFormData),
    handleAgencyNameOnBlur: () => handleAOAutocompleteOnBlur('agencyCodes', formData, setFormData, autocompleteInputValues),
    handleAccountNumberOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange('accountNumber', e, value, autocompleteInputValues, setAutocompleteInputValues, debouncedState, setDebouncedState),
    handleAccountNumberOnChange: (e, value) => handleAOAutocompleteOnChange('accountNumber', e, value, formData, setFormData),
    handleAccountNumberOnBlur: () => handleAOAutocompleteOnBlur('accountNumber', formData, setFormData, autocompleteInputValues),
    handlePartialInsuredNameOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange('partialInsuredName', e, value, autocompleteInputValues, setAutocompleteInputValues, debouncedState, setDebouncedState),
    handlePartialInsuredNameOnChange: (e, value) => handleAOAutocompleteOnChange('partialInsuredName', e, value, formData, setFormData),
    handlePartialInsuredNameOnBlur: () => handleAOAutocompleteOnBlur('partialInsuredName', formData, setFormData, autocompleteInputValues),
    handleGenerateOnClick: () =>
      handleGenerateOnClick(generateReportFormDataRef, setLoadingPdf, setIsDownloadingPdfErrorVisible, setDownloadingPdfError, exportRangeValue),
    handleOnSortModelChange: (sortData) => handleOnSortModelChange(sortData, generateReportFormDataRef),
    datePickerMinDate: aODatePickerMinDate(undefined, undefined, 1),
    handleClickPaginationChange: (page) => handleClickPaginationChange(page, formData, setReportResults, setCreateButtonLoading),
    handleExportDropdownOnChange: (e) => handleExportDropdownOnChange(e, setExportRangeValue),
    handleOnRowDoubleClick: (params) => handleOnRowDoubleClick(params, history, setPreviousPageName, setSelectedClaim),
    handleCloseClaimsInquiryDetails: () => setSelectedClaim(null),
  };

  return loadingSd ? (
    <CardTabSkeleton />
  ) : (
    <StyledTransactionActivityReport>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={LocalComponents}
          functionMap={transactionActivityReportsCallbackFunctions}
          stateMap={transactionActivityReportsState}
        />
      )}
    </StyledTransactionActivityReport>
  );
};

export default TransactionActivityReports;
