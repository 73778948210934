import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { StyledAOAutocomplete } from './AOAutocomplete.styled';

const AOAutocomplete = ({
  disableClearable = true,
  freeSolo = true,
  handleOnChange,
  handleOnInputChange,
  id,
  inputValue,
  invalidSearch,
  label,
  loading,
  name,
  options,
  placeholder,
  required,
  value,
  ...params
}) => {
  return (
    <StyledAOAutocomplete>
      <Autocomplete
        freeSolo={freeSolo}
        disableClearable={disableClearable}
        value={value}
        inputValue={inputValue}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        id={id}
        options={options ?? []}
        style={{ width: 300 }}
        loading={loading}
        data-testid={id}
        filterOptions={(options) => options}
        {...params}
        renderInput={(params) => (
          <TextField
            {...params}
            error={invalidSearch}
            placeholder={placeholder}
            label={label}
            variant="outlined"
            name={name}
            helperText={invalidSearch}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </StyledAOAutocomplete>
  );
};

AOAutocomplete.propTypes = {
  disableClearable: PropTypes.bool,
  freeSolo: PropTypes.bool,
  handleOnChange: PropTypes.func,
  handleOnInputChange: PropTypes.func,
  id: PropTypes.string,
  inputValue: PropTypes.string,
  invalidSearch: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
};

export default AOAutocomplete;
