import React from 'react';
import PropTypes from 'prop-types';

import WorkflowCard from './WorkflowCard';
const WorkflowCardPersonal = ({ onClick }) => (
  <WorkflowCard
    onClick={onClick}
    icon={'icons-icon-home'}
    title="Personal"
    description="Provides a variety of coverage options to meet your client's unique needs for the individual and family."
  />
);

WorkflowCardPersonal.propTypes = {
  onClick: PropTypes.func,
};

export default WorkflowCardPersonal;
