import React from 'react';
import axios from 'axios';
import _ from 'lodash';

import { AOButton } from '../AOButton';
import { POST_CLAIMS_AUTOCOMPLETE, POST_CLAIMS_SEARCH } from '../../constants/api';

const renderCell = (params, setSelectedClaim) => {
  const handleOnClick = () => {
    const { policyNumber, occurrenceNumber } = params.row;
    setSelectedClaim({ policyNumber, occurrenceNumber });
  };

  return (
    !_.isNil(params.row) && (
      <AOButton aria-label="claims-inquiry-details" color="primary" onClick={handleOnClick}>
        {params.value}
      </AOButton>
    )
  );
};

const getDataGridColumns = (searchByType, setSelectedClaim) => [
  {
    field: 'name',
    headerName: `${
      searchByType === 'policyNumber' || searchByType === 'insuredName'
        ? 'Insured Name'
        : 'Claimant Name'
    }`,
    sortable: true,
    width: 400,
    renderCell: (params) => renderCell(params, setSelectedClaim),
  },
  {
    field: 'policyNumber',
    headerName: 'Policy Number',
    sortable: true,
    width: 200,
  },
  {
    field: 'dateOfLoss',
    headerName: `${
      searchByType === 'openClaims' 
      ? 'Date Reported' 
      : 'Date of Loss'
    }`,
    sortable: true,
    width: 200,
    type: 'date',
  },
  {
    field: 'occurrenceNumber',
    headerName: 'Occurrence Number',
    sortable: true,
    width: 200,
  },
];

const fetchSuggestions = async (endpoint, searchText) => {
  const claimsEndpointUrl = `${POST_CLAIMS_AUTOCOMPLETE}/${endpoint}`;
  const claimsEndpointData = await axios.post(`${claimsEndpointUrl}`, searchText);
  const claimsEndpointSuggestion = claimsEndpointData.data;
  return claimsEndpointSuggestion;
};

const getTextFieldLabelAndPlaceholder = (searchByType) => {
  switch (searchByType) {
    case 'policyNumber':
      return { label: 'Policy Number', placeholder: 'Enter Policy Number' };
    case 'insuredName':
      return { label: 'Name', placeholder: 'Enter Insured Name' };
    case 'claimantName':
      return { label: 'Last Name', placeholder: 'Enter Claimant Last Name' };
  }
};

const handleOnChange = (e, stateSetter) => {
  stateSetter(e.target.value);
};

const getAOAutocompleteValue = (formData, searchByType) => {
  return formData[searchByType];
};

const getAOAutocompleteInputValue = (formData, searchByType) => {
  return formData[searchByType].value;
};

const isSearchButtonDisabled = (autocompleteInputValues, searchByType) => {
  switch (searchByType) {
    case 'policyNumber':
      return autocompleteInputValues[searchByType].value.length < 6;
    case 'insuredName':
    case 'claimantName':
      return autocompleteInputValues[searchByType].value.length < 3;
  }
};

const getAutocompleteEndpoint = (searchByType) => {
  switch (searchByType) {
    case 'policyNumber':
      return 'policy-number';
    case 'insuredName':
      return 'insured-last-name';
    case 'claimantName':
      return 'claimant-last-name';
    case 'openClaims':
      return 'open-claims';
  }
};

const getSearchByType = (searchByType, claimantNameSearchType) => {
  switch (searchByType) {
    case 'policyNumber':
    case 'insuredName':
    case 'openClaims':
      return 'exact';
    case 'claimantName':
      return claimantNameSearchType;
  }
};

const fetchResults = async (
  pageNumber,
  setResults,
  searchByType,
  claimantNameSearchType,
  searchText,
  setLoadingSearchResults,
  clearButtonLoading,
  setError,
) => {
  try {
    setError();
    setLoadingSearchResults(true);
    const searchUrl = `${POST_CLAIMS_SEARCH}/${getSearchByType(
      searchByType,
      claimantNameSearchType,
    )}/${getAutocompleteEndpoint(searchByType)}/${pageNumber}`;
    const results = await axios.post(searchUrl, searchText);
    setResults(results.data);
  } catch (error) {
    setError(
      'There was a problem processing this request. We are sorry for the inconvenience. Please contact the Help Desk or try again later.',
    );
    console.error('Claims: ', error);
  }
  setLoadingSearchResults(false);
  clearButtonLoading();
};

const handleSearchOnClick = async (
  setResults,
  setPageNumber,
  searchByType,
  claimantNameSearchType,
  searchText,
  setLoadingSearchResults,
  clearButtonLoading = () => {},
  setError,
) => {
  resetResults(setResults, setPageNumber);

  fetchResults(
    1,
    setResults,
    searchByType,
    claimantNameSearchType,
    searchText,
    setLoadingSearchResults,
    clearButtonLoading,
    setError,
  );
};

const resetResults = (setResults, setPageNumber) => {
  setResults(null);
  setPageNumber(1);
};

const handleClickPaginationChange = (
  page,
  setResults,
  searchByType,
  claimantNameSearchType,
  searchText,
  setLoadingSearchResults,
  setError,
) => {
  fetchResults(
    page + 1,
    setResults,
    searchByType,
    claimantNameSearchType,
    searchText,
    setLoadingSearchResults,
    () => {},
    setError,
  );
};

export {
  getDataGridColumns,
  fetchSuggestions,
  getAOAutocompleteValue,
  getAOAutocompleteInputValue,
  handleOnChange,
  getTextFieldLabelAndPlaceholder,
  isSearchButtonDisabled,
  getAutocompleteEndpoint,
  handleSearchOnClick,
  resetResults,
  handleClickPaginationChange,
  fetchResults,
};
