import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import useInitializeFilter from './useInitializeFilter';
import { getEnumSearchCriteria } from './filterHelpers';

const DocumentType = ({
  handleSetSearchCriteria,
  setInitialQuery,
  setResetFunc,
  typeOfContainer,
}) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState({});

  const resetComponent = () => {
    if (filterSpec?.searchTerm) {
      const newSelectedDocumentType = {};
      for (const product of filterSpec.values) {
        newSelectedDocumentType[product] = true;
      }
      setSelectedDocumentType(newSelectedDocumentType);
    }
  };

  const handleOnChange = (e) => {
    const { name, checked } = e.target;
    const newSelectedDocumentType = { ...selectedDocumentType, [name]: checked };
    setSelectedDocumentType(newSelectedDocumentType);

    const productKeys = Object.keys(newSelectedDocumentType);
    const criteria = [];
    for (const key of productKeys) {
      if (newSelectedDocumentType[key]) {
        criteria.push(key);
      }
    }

    handleSetSearchCriteria(filterSpec?.searchTerm, getEnumSearchCriteria(criteria));
  };

  const { filterSpec } = useInitializeFilter(typeOfContainer, 'DocumentType', setInitialQuery);

  // on initialization, assign the reset pointer to ref and send initial search criteria
  useEffect(() => {
    if (filterSpec?.searchTerm?.length > 0) {
      resetComponent();
      setResetFunc(filterSpec?.searchTerm, resetComponent);
      handleSetSearchCriteria(filterSpec?.searchTerm, getEnumSearchCriteria(filterSpec?.values));
    }
  }, [filterSpec]);

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Document Type</FormLabel>
      <FormGroup>
        {filterSpec?.values?.map((option) => (
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                id={`brandfolder-documentType-checkbox-${option}`}
                checked={selectedDocumentType[option] ?? false}
                onChange={handleOnChange}
                disabled={!filterSpec?.searchTerm}
                name={option}
              />
            }
            label={option}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

DocumentType.propTypes = {
  handleSetSearchCriteria: PropTypes.func,
  setInitialQuery: PropTypes.func,
  setResetFunc: PropTypes.func,
  typeOfContainer: PropTypes.oneOf(['Underwriting', 'Marketing', 'Training']),
};

export default DocumentType;
