import axios from 'axios';
import { POST_BILLING_SEARCH_SUGGESTIONS, ACCOUNT_NUMBER_URL, INSURED_LAST_NAME_URL, POLICY_NUMBER_URL } from '../../../constants/api';

const SEARCH_BY_POLICY_NUMBER = 'policyNumber';
const SEARCH_BY_ACCOUNT_NUMBER = 'accountNumber';
const SEARCH_BY_INSURED_NAME = 'insuredName';

const billingSearchCriteriaMenuItems = [
  {
    value: SEARCH_BY_POLICY_NUMBER,
    label: 'Policy Number',
  },
  {
    value: SEARCH_BY_ACCOUNT_NUMBER,
    label: 'Account Number',
  },
  {
    value: SEARCH_BY_INSURED_NAME,
    label: 'Insured Name',
  },
];

const insuredNameSearchRadioOptions = [
  {
    value: 'exact',
    label: 'Exact',
  },
  {
    value: 'contains',
    label: 'Contains',
  },
];

const fetchPolicyNumberFormSuggestions = async (params) => {
  const formsData = await axios.post(`${POST_BILLING_SEARCH_SUGGESTIONS}${POLICY_NUMBER_URL}`, params);
  const formsSuggestion = formsData.data;
  return formsSuggestion;
};

const fetchAccountNumberFormSuggestions = async (params) => {
  const formsData = await axios.post(`${POST_BILLING_SEARCH_SUGGESTIONS}${ACCOUNT_NUMBER_URL}`, params);
  const formsSuggestion = formsData.data;
  return formsSuggestion;
};

const fetchInsuredNameFormSuggestions = async (params) => {
  const formsData = await axios.post(`${POST_BILLING_SEARCH_SUGGESTIONS}${INSURED_LAST_NAME_URL}`, params);
  const formsSuggestion = formsData.data;
  return formsSuggestion;
};

const getInvalidText = (property, value) => {
  const trimmedValue = value.trim();
  const trimmedValueLength = trimmedValue.length;
  switch (property) {
    case SEARCH_BY_POLICY_NUMBER:
      return trimmedValueLength >= 3 ? '' : 'Full policy number is required';
    case SEARCH_BY_ACCOUNT_NUMBER:
      return trimmedValueLength >= 3 ? '' : 'Full account number is required';
    case SEARCH_BY_INSURED_NAME:
      return trimmedValueLength > 0 ? '' : 'Insured name is required';
    default:
      return '';
  }
};

export {
  SEARCH_BY_ACCOUNT_NUMBER,
  SEARCH_BY_INSURED_NAME,
  SEARCH_BY_POLICY_NUMBER,
  billingSearchCriteriaMenuItems,
  fetchAccountNumberFormSuggestions,
  fetchInsuredNameFormSuggestions,
  fetchPolicyNumberFormSuggestions,
  getInvalidText,
  insuredNameSearchRadioOptions,
};
