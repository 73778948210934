import React from 'react';
import { Box, Typography, Table } from '@material-ui/core';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../PaymentHistory/PaymentManagementHistoryTransactionDetailModal/PaymentManagementHistoryTransactionDetailModal.styled';

import { detailsType } from '../ManageCurrentPayment.types';

const AdjustPaymentDetails = ({
  details: { dateCreated, accountNumber, cwaPolicy, name, agentNumber, userId, method, source },
}) => {
  return (
    <Box display="flex">
      <Table>
        <StyledTableRow>
          <StyledTableCell align="right">
            <Typography>
              <strong>Date Paid:</strong>
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box px={2}>
              <Typography>{dateCreated}</Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell>
            <Typography align="right">
              <strong>Account No.:</strong>
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box mx={2}>
              <Typography>{accountNumber}</Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell align="right">
            <Typography>
              <strong>Policy No.:</strong>
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box mx={2}>
              <Typography>{cwaPolicy}</Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell align="right">
            <Typography>
              <strong>Ins. Name:</strong>
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box mx={2}>
              <Typography>{name}</Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell align="right">
            <Typography>
              <strong>Agent Code:</strong>
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box mx={2}>
              <Typography>{agentNumber}</Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell align="right">
            <Typography>
              <strong>User ID:</strong>
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box mx={2}>
              <Typography>{userId}</Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell align="right">
            <Typography>
              <strong>Pay Method:</strong>
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box mx={2}>
              <Typography>{method}</Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell align="right">
            <Typography>
              <strong>Tran. Source:</strong>
            </Typography>
          </StyledTableCell>
          <StyledTableCell>
            <Box mx={2}>
              <Typography>{source}</Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
      </Table>
    </Box>
  );
};

AdjustPaymentDetails.propTypes = {
  details: detailsType,
};

export default AdjustPaymentDetails;
