import {
  ClaimsCard,
  DashboardAlerts,
  PaymentsCard,
  QuickLinksForms,
  QuickLinksReports,
  QuickLinksTools,
  QuotesCard,
  RenewalsCard,
  WantTo,
} from 'components/AORedesignViews/Dashboard';
import { DashboardAnnouncements } from 'components/Announcements';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import React, { useContext } from 'react';
import agentContext from '../../contexts/agent.context';
import { Box, Col, Row, ViewContainer } from '../AORedesign';

const Dashboard = () => {
  const { Name } = useContext(agentContext);

  return (
    <ViewContainer title={`Welcome, ${Name?.First}`} legalAgreementLink>
      <Row>
        <Col xs={12} md={6}>
          <DashboardAlerts />
        </Col>
      </Row>
      <Feature allowed={featureFlags.ACCESS_USER_IS_AGENT}>
        <Box mb={4}>
          <Row spacing={2}>
            <Col xs={6} lg={3}>
              <QuotesCard />
            </Col>
            <Col xs={6} lg={3}>
              <RenewalsCard />
            </Col>
            <Col xs={6} lg={3}>
              <PaymentsCard />
            </Col>
            <Col xs={6} lg={3}>
              <ClaimsCard />
            </Col>
          </Row>
        </Box>
      </Feature>
      <Row spacing={2}>
        <Col xs={12} md={8}>
          <Box mb={2}>
            <DashboardAnnouncements />
          </Box>
        </Col>
        <Col md={4}>
          <WantTo />
        </Col>
      </Row>
      <Row spacing={2}>
        <Col xs={8} lg={4}>
          <QuickLinksReports />
        </Col>
        <Col xs={8} lg={4}>
          <QuickLinksForms />
        </Col>
        <Col xs={8} lg={4}>
          <QuickLinksTools />
        </Col>
      </Row>
    </ViewContainer>
  );
};

export default Dashboard;
