import React from 'react';

import DbcInternal from './DbcInternal';
import DbcExternal from './DbcExternal';
import { Feature } from 'components/Feature';
import { Box, Card, CardHeader, ViewContainer } from 'components/AORedesign';
import featureFlags from 'constants/featureFlags';
import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';

const DbcReport = () => {
  return (
    <>
      <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
        <Feature allowed={featureFlags.ACCESS_DIRECT_BILL_COMMISSION_REPORT}>
          <Box id="DbcReport" mb={2}>
            <Box mb={2}>
              <Card>
                <CardHeader title="Direct Bill Commission Statements" />
                <Box mb={2} p={2}>
                  <Box>
                    <Feature allowed={featureFlags.ACCESS_USER_INTERNAL}>
                      <DbcInternal />
                    </Feature>
                    <Feature allowed={featureFlags.ACCESS_USER_IS_AGENT}>
                      <DbcExternal />
                    </Feature>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>
        </Feature>
      </ViewContainer>
    </>
  );
};

export default DbcReport;
