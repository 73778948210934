import axios from 'axios';

import {
  PAYMENT_MANAGEMENT_UPDATE_CURRENT_PAYMENT,
  PAYMENT_MANAGEMENT_DELETE_CURRENT_PAYMENT
} from '../../../../../constants/api';

const adjustCurrentPayment = async (_payload) => {
  let post;
  try {
    post = await axios.post(PAYMENT_MANAGEMENT_UPDATE_CURRENT_PAYMENT, { ..._payload });
  } catch (error) {
    post = error;
  }
  return post;
};

const removeCurrentPayment = async (_payload) => {
  let post;
  try {
    post = await axios.delete(PAYMENT_MANAGEMENT_DELETE_CURRENT_PAYMENT, {
      data: {
        ..._payload
      }
    });
  } catch (error) {
    post = error;
  }
  return post;
};

export { adjustCurrentPayment, removeCurrentPayment };
