import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Box from '@material-ui/core/Box';

const AODataGrid = ({
  exportToCSV,
  fileName,
  noResultsMessage = 'No results returned for this search. Please refine your search and try again.',
  ...props
}) => {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ fileName }} />
      </GridToolbarContainer>
    );
  };

  const rowsLength = props?.rows?.length;
  if (rowsLength > 0) {
    return (
      <DataGrid
        disableSelectionOnClick
        components={{
          Toolbar: exportToCSV ? CustomToolbar : undefined,
        }}
        {...props}
      />
    );
  } else if (rowsLength === 0) {
    return (
      <Box color="error.main" my={1}>
        {noResultsMessage}
      </Box>
    );
  } else {
    return null;
  }
};

AODataGrid.propTypes = {
  columns: PropTypes.array,
  error: PropTypes.string,
  exportToCSV: PropTypes.bool,
  fileName: PropTypes.string,
  loading: PropTypes.bool,
  noResultsMessage: PropTypes.string,
  rows: PropTypes.array,
};

export default AODataGrid;
