import React from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { StyledProductDetailText, StyledProductDetailAttachmentButton } from './ProductDetail.styled';

const ProductDetailAccordionSummary = (props) => <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />;

const ProductDetailULContentRenderer = ({ content }) => {
  return (
    (
      <ul>
        {content?.map((item) => (
          <li key={cuid()} style={{ 'list-style': 'none' }}>
            <Box fontSize="16px" lineHight="24px" color="text.secondary" mb={2}>
              &#8226; {item}
            </Box>
          </li>
        ))}
      </ul>
    ) ?? null
  );
};

ProductDetailULContentRenderer.propTypes = {
  content: PropTypes.array,
};

const ProductDetailEligibilityBoxRenderer = ({ title, item, currency }) => {
  return (
    (
      <Box width={369} height={59} boxSizing="border-box" bgcolor="grey.100" display="flex" flexDirection="column" justifyContent="center">
        <Box textAlign="center" fontWeight="500" fontSize={10} color="text.secondary" lineHeight="31px">
          {title?.toUpperCase()}
        </Box>
        <Box textAlign="center" fontWeight="500" fontSize={16} lineHeight="24px">
          {`${currency ? '$' : ''}${item?.min} - ${currency ? '$' : ''}${item?.max}`}
        </Box>
      </Box>
    ) ?? null
  );
};

ProductDetailEligibilityBoxRenderer.propTypes = {
  currency: PropTypes.bool,
  title: PropTypes.string,
  item: PropTypes.object,
};

const ProductDetailDocumentContentRenderer = ({ content }) => {
  return (
    content?.map((item) => (
      <StyledProductDetailAttachmentButton key={cuid()} hrefNewTab={item.link}>
        <PictureAsPdfIcon fontSize="large">{item}</PictureAsPdfIcon>
        {item.description}
      </StyledProductDetailAttachmentButton>
    )) ?? null
  );
};

ProductDetailDocumentContentRenderer.propTypes = {
  content: PropTypes.array,
};

const ProductDetailText = ({ text }) => <StyledProductDetailText>{text}</StyledProductDetailText>;

ProductDetailText.propTypes = {
  text: PropTypes.string,
};

const ProductDetailTextBuilder = ({ start, middle, end }) => <StyledProductDetailText>{`${start} ${middle} ${end}`}</StyledProductDetailText>;

ProductDetailTextBuilder.propTypes = {
  start: PropTypes.string,
  middle: PropTypes.string,
  end: PropTypes.string,
};

const ProductDetailCoverageTypeRenderer = ({ content }) => {
  return (
    (
      <Grid container spacing={1}>
        {content?.map((item) => (
          <Grid key={cuid()} item>
            <Box p={'18px 16px'} bgcolor="grey.100" width={260} height={56} boxSizing="border-box" fontSize={'14px'} lineHeight={'20px'} borderRadius="4px">
              {item.description}
            </Box>
          </Grid>
        ))}
      </Grid>
    ) ?? null
  );
};

ProductDetailCoverageTypeRenderer.propTypes = {
  content: PropTypes.array,
};

export {
  ProductDetailAccordionSummary,
  ProductDetailULContentRenderer,
  ProductDetailEligibilityBoxRenderer,
  ProductDetailDocumentContentRenderer,
  ProductDetailText,
  ProductDetailTextBuilder,
  ProductDetailCoverageTypeRenderer,
};
