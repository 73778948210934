import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import {
  PAYMENT_MANAGEMENT_HISTORY,
  PAYMENT_MANAGEMENT_HISTORY_BY_DETAIL_ID,
} from '../../../../../constants/api';

import { currencyStringFormatter } from '../../../../../helpers';

const paymentHistoryGridColDef = [
  {
    field: 'id',
    width: 75,
    flex: 1,
  },
  {
    field: 'transDate',
    headerName: 'Date',
    flex: 1,
  },
  {
    field: 'userId',
    headerName: 'Admin',
    flex: 1,
  },
  {
    field: 'tranType',
    headerName: 'Trn. Type',
    flex: 1,
  },
  {
    field: 'tranDesc',
    headerName: 'Trn. Desc.',
    hide: true,
    flex: 1,
  },
  {
    field: 'agency',
    headerName: 'Agency',
    flex: 1,
  },
  {
    field: 'account',
    headerName: 'Account',
    flex: 1,
  },
  {
    field: 'policy',
    headerName: 'Policy',
    flex: 1,
  },
  {
    field: 'modifyAmount',
    headerName: 'Mod. Amt.',
    flex: 1,
    renderCell: ({ value }) => {
      const renderedVal = _.isNil(value) ? 'n/a' : currencyStringFormatter(value);
      return renderedVal;
    },
  },
  {
    field: 'origAmount',
    headerName: 'Orig. Amt',
    flex: 1,
    renderCell: ({ value }) => {
      const formatted = currencyStringFormatter(value);
      return formatted;
    },
  },
];

const getPaymentHistoryGridRows = async () => {
  const startDate = moment().subtract(90, 'days').calendar();
  const endDate = moment();
  const { data } = await axios.get(
    `${PAYMENT_MANAGEMENT_HISTORY}?startDate=${moment(startDate).format(
      'YYYY/MM/DD',
    )}&endDate=${moment(endDate).format('YYYY/MM/DD')}`,
  );
  return data;
};

const getPaymentHistorybyDetailId = async (_id) => {
  const { data } = await axios.get(`${PAYMENT_MANAGEMENT_HISTORY_BY_DETAIL_ID}/${_id}`);
  return data;
};

export { paymentHistoryGridColDef, getPaymentHistoryGridRows, getPaymentHistorybyDetailId };
