import React from 'react';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { Button } from '@material-ui/core';

import { AOButton } from '../AOButton';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';

import { GET_TAR_FORMS_URL, GET_TAR_URL, GET_REPORTS_URL } from '../../constants/api';
import { ROUTE_ACCOUNT_DETAIL } from '../../constants/routes';

import { sendGetPolicyInquiry } from '../CommercialLinesServices/CLSPolicyInquiry';
import {
  isE1pPolicy,
  isFarmRanchPolicy,
  isMsePolicy,
  handleRedirectToMSE,
} from 'components/AORedesignViews/Reports/BillingReports/TransactionActivityReports/TransactionActivityReports.api';
import { handleFarmRanchRedirect } from 'components/Workflow/WorkflowCardFarmRanch';

const periodMenuItems = [
  { value: 'Previous Day', code: 0 },
  { value: 'Previous Week', code: 6 },
  { value: 'Previous Month', code: 30 },
  { value: 'Custom Dates', code: -1 },
];

const handleClickOpenPolicy = (params) => {
  if (params.row.PolicySystem === 'PL MSS' || isE1pPolicy(params.row)) {
    // if it is E1P policy, make effective date to current date
    const effDate = isE1pPolicy(params.row) ? new Date() : new Date(params.row.EffectiveDate);
    const effDateFormatted = moment(effDate).format('MM/DD/YYYY');
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'View / Change Policy',

      DetailedContext: [
        {
          name: 'effectiveDate',
          value: effDateFormatted,
        },
        {
          name: 'policyNumber',
          value: params.row.PolicyNumber,
        },
        {
          name: 'vendorSystem',
          value: false,
        },
      ],
    };
    return redirectUrlFunction(
      request_body,
      'POST',
      null,
      () => {},
      () => {},
    );
  } else if (params.row.PolicySystem === 'CL MSS') {
    const request_body_for_policy_inquiry = {
      // inquiryDate: viewPolicyAsOfDate,
      inquiryType: 'Policy Inquiry',
      policyNumber: params.row.PolicyNumber,
    };
    // Call Proxy Policy Inquiry to get data for request body
    sendGetPolicyInquiry(
      request_body_for_policy_inquiry,
      () => {},
      () => {},
      () => {},
    ).then((policyInquiryPayload) => {
      console.log(policyInquiryPayload);
      if (!_.isNil(policyInquiryPayload.data.policyNumber) & !_.isNil(policyInquiryPayload.data.acctType)) {
        const effDate = new Date(params.row.EffectiveDate);
        const effDateFormatted = moment(effDate).format('MM/DD/YYYY');
        const request_body = {
          Area: 'Commercial Lines',
          TypeOfRedirect: 'MSS Policy Inquiry',
          DetailedContext: [
            {
              name: 'transactionId',
              value: policyInquiryPayload.data.policyInquiryInfo.systemAssignId,
            },
            {
              name: 'accountType',
              value: 'SEARCHVP',
            },
            {
              name: 'effectiveDate',
              value: effDateFormatted,
            },
          ],
        };
        return redirectUrlFunction(
          request_body,
          'POST',
          null,
          () => {},
          () => {},
        );
      }
    });
  }
};

const renderCell = (params, setSelectedClaim) => {
  if (!_.isNil(params.row)) {
    // if it is a policy transaction, redirect to policy admin system
    if (params.row.ActivityType === 'Policy' && (params.row.PolicySystem === 'CL MSS' || params.row.PolicySystem === 'PL MSS' || isE1pPolicy(params.row))) {
      return (
        <Button
          aria-label="policy-number-show-more"
          onClick={() => {
            handleClickOpenPolicy(params);
          }}
          color="primary">
          {params.value}
        </Button>
      );
    } else if (params.row.ActivityType === 'Policy' && isMsePolicy(params.row)) {
      return (
        <AOButton onClick={() => handleRedirectToMSE(params)} color="primary">
          {params.value}
        </AOButton>
      );
    } else if (params.row.ActivityType === 'Policy' && isFarmRanchPolicy(params.row)) {
      return (
        <AOButton onClick={handleFarmRanchRedirect} color="primary">
          {params.value}
        </AOButton>
      );
    }
    // if it is a claim transaction, go to claims inquiry
    else if (params.row.ActivityType === 'Claim') {
      const { PolicyNumber, ClaimsOccurrence } = params.row;

      return (
        <AOButton onClick={() => setSelectedClaim({ policyNumber: PolicyNumber, occurrenceNumber: ClaimsOccurrence })} color="primary">
          {params.value}
        </AOButton>
      );
    }
    // all others go to billing details
    else {
      return (
        <AOButton route={`${ROUTE_ACCOUNT_DETAIL}/${params.row.AccountNumber}`} color="primary">
          {params.value}
        </AOButton>
      );
    }
  }
};

const handleOnRowDoubleClick = (params, history, setPreviousPageName, setSelectedClaim) => {
  if (params.row.ActivityType === 'Policy' && (params.row.PolicySystem === 'CL MSS' || params.row.PolicySystem === 'PL MSS' || isE1pPolicy(params.row))) {
    handleClickOpenPolicy(params);
  } else if (params.row.ActivityType === 'Policy' && isMsePolicy(params.row)) {
    handleRedirectToMSE(params);
  } else if (params.row.ActivityType === 'Policy' && isFarmRanchPolicy(params.row)) {
    handleFarmRanchRedirect();
  } else if (params.row.ActivityType === 'Claim') {
    const { PolicyNumber, ClaimsOccurrence } = params.row;
    setSelectedClaim({ policyNumber: PolicyNumber, occurrenceNumber: ClaimsOccurrence });
  } else {
    const accountDetailUrl = `${ROUTE_ACCOUNT_DETAIL}/${params.row.AccountNumber}`;
    setPreviousPageName('Transaction Activity Report');
    history.push(accountDetailUrl);
  }
};

const getReportResultsColumns = (setSelectedClaim) => {
  return [
    {
      field: 'NamedInsured',
      headerName: 'Name Insured',
      sortable: true,
      width: 200,
    },
    {
      field: 'PolicyNumber',
      headerName: 'Policy Number',
      sortable: true,
      width: 200,
      renderCell: (params) => renderCell(params, setSelectedClaim),
    },
    {
      field: 'AccountNumber',
      headerName: 'Account Number',
      sortable: true,
      width: 200,
    },
    {
      field: 'LOBName',
      headerName: 'Line of Business',
      sortable: true,
      width: 200,
    },
    {
      field: 'PolicySystem',
      headerName: 'System',
      sortable: true,
      width: 200,
    },
    {
      field: 'ActivityType',
      headerName: 'Transaction Type',
      sortable: true,
      width: 200,
    },
    {
      field: 'TransactionSubType',
      headerName: 'Transaction SubType',
      sortable: true,
      width: 200,
    },
    {
      field: 'ProcessDate',
      headerName: 'Transaction Processed Date',
      sortable: true,
      width: 200,
    },
    {
      field: 'EffectiveDate',
      headerName: 'Transaction Effective Date',
      sortable: true,
      width: 200,
    },
    {
      field: 'Amt',
      headerName: 'Transaction Amount (marked credit/debit)',
      sortable: true,
      width: 200,
    },
    {
      field: 'AgencyCode',
      headerName: 'Agency Code',
      sortable: true,
      width: 200,
    },
    {
      field: 'AgencyName',
      headerName: 'Agency Name',
      sortable: true,
      width: 200,
    },
  ];
};

const fetchTAReportsFormData = async (endpoint) => {
  const tAReportsFormsUrl = `${GET_TAR_FORMS_URL}/${endpoint}`;
  const tAReportsFormsData = await axios.get(`${tAReportsFormsUrl}`);
  const tAReportsFormsMenuItems = tAReportsFormsData.data;
  return tAReportsFormsMenuItems;
};

const handleLOBOnChange = (e, lOBs, formData, setFormData) => {
  const target = e.target;
  const value = target.value;
  const name = target.name;
  const lobGroup = lOBs.find((lob) => lob.lobName === value).lobGroupName;
  setFormData({ ...formData, [name]: value, lobGroup });
};

const handleFromDateOnChange = (date, formData, setFormData) => {
  const formattedDate = moment(date).format('MM/DD/YYYY');
  setFormData({ ...formData, fromDate: date ? formattedDate : null });
};

const handleToDateOnChange = (date, formData, setFormData) => {
  const formattedDate = moment(date).format('MM/DD/YYYY');
  setFormData({ ...formData, toDate: date ? formattedDate : null });
};

const handleAOTransferListSetLeft = (data, setTransactionSubtypes) => {
  setTransactionSubtypes(data);
};

const handleAOTransferListSetRight = (data, formData, setFormData) => {
  setFormData({
    ...formData,
    transactionType: !data.length ? 'ALL' : '...CUSTOM SELECTIONS...',
    transactionSubTypes: data,
  });
};

const sanitizeRequestPayload = (requestBody) => {
  for (const key in requestBody) {
    if (key === 'period') {
      if (requestBody[key] === -1) {
        // Custom date
        requestBody[key] = 0;
      } else {
        // Ignore to and from date if not custom date period selected
        delete requestBody.fromDate;
        delete requestBody.toDate;
      }
    }

    // Delete empty or null values from formData
    if (requestBody[key] === null || requestBody[key] === '') {
      delete requestBody[key];
    }

    // Agency codes only takes last 6 characters (like legacy)
    if (key === 'agencyCodes' && requestBody[key]) {
      requestBody[key] = requestBody[key].slice(-6);
    }
  }
};

const handleCreateTransactionActivityReport = async (
  formData,
  setReportResults,
  generateReportFormDataRef,
  setExportRangeMenuItems,
  setExportRangeValue,
  setCreateButtonLoading,
) => {
  setCreateButtonLoading(true);
  if (generateReportFormDataRef) {
    generateReportFormDataRef.current = formData;
  }
  const requestBody = { ...formData };
  sanitizeRequestPayload(requestBody);
  const tAReportsUrl = `${GET_TAR_URL}/get-data`;
  try {
    const tAReportsData = await axios.post(`${tAReportsUrl}`, requestBody);
    setReportResults(tAReportsData.data.data ? tAReportsData.data : []);
    const pageSize = 500;
    const exportPages = Math.ceil(tAReportsData.data.total / pageSize);
    const exportRangeMenuItems = [];
    for (let i = 1; i <= exportPages; i++) {
      const endRange = Math.min(tAReportsData.data.total, i * pageSize);
      exportRangeMenuItems.push({ value: `${(i - 1) * pageSize + 1}-${endRange}`, code: i });
    }
    if (setExportRangeMenuItems) setExportRangeMenuItems(exportRangeMenuItems);
    if (setExportRangeValue) setExportRangeValue(1);
  } catch {
    setReportResults({ data: [] });
  }
  setCreateButtonLoading(false);
};

const handleOnSortModelChange = (sortData, generateReportFormDataRef) => {
  generateReportFormDataRef.current.sortColumn = sortData[0]?.field;
  generateReportFormDataRef.current.sortOrder = sortData[0]?.sort;
};

const getGenerateReportRequestBody = (requestBody) => {
  if (requestBody.sortColumn === undefined) {
    requestBody.sortColumn = '';
  }
  if (requestBody.sortOrder === undefined) {
    requestBody.sortOrder = '';
  }
  let detailedContext = [];
  for (const key in requestBody) {
    detailedContext.push({
      name: key,
      value: requestBody[key],
    });
  }
  return {
    Area: 'transaction-activity',
    DetailedContext: detailedContext,
    TypeOfRedirect: 'Generate-PDF',
  };
};

const handleGenerateOnClick = (generateReportFormDataRef, setLoadingPdf, setIsDownloadingPdfErrorVisible, setDownloadingPdfError, exportRangeValue) => {
  const requestBody = { ...generateReportFormDataRef.current, pageNumber: exportRangeValue };
  sanitizeRequestPayload(requestBody);
  let reportRequestBody = getGenerateReportRequestBody(requestBody);
  const tAReportsGenerateUrl = GET_REPORTS_URL;
  handlePdfDownload('POST', reportRequestBody, tAReportsGenerateUrl, setLoadingPdf, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
};

const handleClickPaginationChange = (page, formData, setReportResults, setCreateButtonLoading) => {
  const formDataCopy = { ...formData, pageNumber: page + 1 };
  handleCreateTransactionActivityReport(formDataCopy, setReportResults, undefined, undefined, undefined, setCreateButtonLoading);
};

const handleExportDropdownOnChange = (e, setExportRangeValue) => {
  setExportRangeValue(e.target.value);
};

export {
  fetchTAReportsFormData,
  handleAOTransferListSetLeft,
  handleAOTransferListSetRight,
  handleCreateTransactionActivityReport,
  handleFromDateOnChange,
  handleGenerateOnClick,
  handleLOBOnChange,
  handleOnSortModelChange,
  handleToDateOnChange,
  periodMenuItems,
  getReportResultsColumns,
  handleClickPaginationChange,
  handleExportDropdownOnChange,
  handleOnRowDoubleClick,
};
