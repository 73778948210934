import React from 'react';
// import cuid from 'cuid';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import AOButtonLoadingContainer from './AOButtonLoadingContainer';

import OpenWindowService from '../CommonUtilityServices/OpenWindowService';

const AOHrefNewWindowButton = ({ loading, hrefNewWindow, children, ...otherProps }) => {
  const handleClick = (_hrefNewWindow) => {
    OpenWindowService(_hrefNewWindow, 'POST', null, null);
  };
  return (
    <AOButtonLoadingContainer loading={loading}>
      <Button onClick={() => handleClick(hrefNewWindow)} {...otherProps}>
        {children}
      </Button>
    </AOButtonLoadingContainer>
  );
};

AOHrefNewWindowButton.propTypes = {
  loading: PropTypes.bool,
  hrefNewWindow: PropTypes.string,
  children: PropTypes.node,
};

export default AOHrefNewWindowButton;
