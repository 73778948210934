import React from 'react';
import PropTypes from 'prop-types';

 import NewWindow from 'react-new-window';
import Alert from '@material-ui/lab/Alert';

import { APIRequestHandler } from '../APIRequestHandler';
// import OpenWindowService from '../CommonUtilityServices/OpenWindowService';

const PLSPolicyChangeViewRedirectResponse = ({
  loading,
  error,
  redirectResponse,
  statusMessage,
}) => {
  return ( 
    <>
      <APIRequestHandler loading={loading} error={error} />  
      {redirectResponse && <NewWindow url={redirectResponse} />}
      {statusMessage && <Alert severity="info">{statusMessage}</Alert>}
    </>
  );
};

PLSPolicyChangeViewRedirectResponse.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.node,
  redirectResponse: PropTypes.node,
  statusMessage: PropTypes.string,
};

export default PLSPolicyChangeViewRedirectResponse;
