import SyncProblemOutlinedIcon from '@material-ui/icons/SyncProblemOutlined';
import useAxios from 'axios-hooks';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { FILTER_TYPES, POLICY_PARAM_VALUES } from 'components/AORedesignViews/Clients';
import { GET_DASHBOARD_RENEWALS } from 'constants/api';
import { ROUTE_CLIENTS } from 'constants/routes';
import agentContext from 'contexts/agent.context';
import AppDataContext from 'contexts/appData.context';
import DashboardActionCard from './DashboardActionCard';

export const RENEWALS_KEY = 'renewals';

const RenewalsCard = () => {
  const history = useHistory();
  const { Locations } = useContext(agentContext);
  const { dashboard, updateDashboardCounts } = useContext(AppDataContext);
  const renewalData = dashboard?.[RENEWALS_KEY];
  const [{ data, loading }] = useAxios(GET_DASHBOARD_RENEWALS, { manual: Locations.length > dashboard?.locationCodeLimit || renewalData?.count });

  useEffect(() => {
    if (!loading && data) {
      updateDashboardCounts(RENEWALS_KEY, data);
    }
  }, [loading]);

  return (
    <DashboardActionCard
      count={renewalData?.count}
      icon={<SyncProblemOutlinedIcon />}
      onClick={() => history.push(`${ROUTE_CLIENTS}?${FILTER_TYPES.POLICY}=${POLICY_PARAM_VALUES.EXPIRING_POLICIES}`)}
      subtitle={`NEXT ${renewalData?.days} DAYS`}
      title="Expiring Policies"
    />
  );
};

export default RenewalsCard;
