import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import AOButtonLoadingContainer from './AOButtonLoadingContainer';

import AppConfigContext from '../../contexts/appConfig.context';

const AORouteLegacyButton = ({ loading, routeLegacy, children, ...otherProps }) => {
  const { legacyUrl } = useContext(AppConfigContext);

  const handleClick = (_routeLeagacy) => {
    window.open(`${legacyUrl}${_routeLeagacy}`, '_blank');
  };
  return (
    <AOButtonLoadingContainer loading={loading}>
      <Button onClick={() => handleClick(routeLegacy)} {...otherProps}>
        {children}
      </Button>
    </AOButtonLoadingContainer>
  );
};

AORouteLegacyButton.propTypes = {
  loading: PropTypes.bool,
  routeLegacy: PropTypes.string,
  children: PropTypes.node,
};

export default AORouteLegacyButton;
