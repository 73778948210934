import PropTypes from 'prop-types';

const menuItemsTypes = {
  value: PropTypes.string,
  code: PropTypes.string,
};

const autocompleteInputValues = {
  value: PropTypes.string,
  invalidText: PropTypes.string,
};

export { menuItemsTypes, autocompleteInputValues };
