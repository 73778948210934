import React from 'react';
import PropTypes from 'prop-types';

import { Box, Autocomplete, TextField } from 'components/AORedesign';

const AnnualMileageValidationReportInternalUserView = ({
  agencyName,
  agencyNameInputValue,
  agencyNameOptions,
  handleAgencyNameOnBlur,
  handleAgencyNameOnChange,
  handleAgencyNameOnInputChange,
}) => {
  return (
    <>
      <Box mb={2}>
        <Autocomplete
          style={{ width: 400 }}
          id="amvr-agency-name"
          value={agencyName}
          options={agencyNameOptions || []}
          inputValue={agencyNameInputValue}
          onChange={handleAgencyNameOnChange}
          onInputChange={handleAgencyNameOnInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              id="amvr-agency-name-label"
              label="Agency Name"
              placeholder="Enter Search Input"
              variant="outlined"
              onBlur={handleAgencyNameOnBlur}
            />
          )}
        />
      </Box>
    </>
  );
};

AnnualMileageValidationReportInternalUserView.propTypes = {
  agencyName: PropTypes.string,
  agencyNameInputValue: PropTypes.string,
  agencyNameOptions: PropTypes.array,
  handleAgencyNameOnBlur: PropTypes.func,
  handleAgencyNameOnChange: PropTypes.func,
  handleAgencyNameOnInputChange: PropTypes.func,
  setAgencyNameOptions: PropTypes.func,
};

export default AnnualMileageValidationReportInternalUserView;
