import { useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { AOAlert } from '../AOAlert';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '../AORedesign';
import { handleAgreeOnClick, handleDeclineOnClick } from './LegalAgreement.api';

const LegalAgreement = ({ hideLegalAgreementModal, handleCloseLegal }) => {
  const { oktaAuth } = useOktaAuth();

  const [agreeButtonLoading, setAgreeButtonLoading] = useState(false);
  const [error, setError] = useState('');

  return (
    <Dialog fullScreen open>
      <DialogContent>
        <Typography component="div" variant="h5" id="legal-agreement-header">
          <Box textAlign="center" mt={2} mb={3}>
            Legal Agreement
          </Box>
        </Typography>

        <iframe
          src="Terms and Conditions for Agents Only 2024-07-17 LETTERHEAD.pdf"
          width="100%"
          height="700px"
          loading="lazy"
          title="Terms and Conditions"></iframe>

        {error && <AOAlert severity="error">{error}</AOAlert>}
      </DialogContent>

      <DialogActions style={{ justifyContent: 'center' }}>
        {hideLegalAgreementModal && (
          <>
            <Button onClick={() => handleDeclineOnClick(setError, oktaAuth)} color="primary" variant="outlined">
              I Decline
            </Button>

            <Button
              loading={agreeButtonLoading}
              onClick={() => handleAgreeOnClick(setAgreeButtonLoading, setError, hideLegalAgreementModal)}
              color="primary"
              variant="contained">
              I Agree
            </Button>
          </>
        )}
        {handleCloseLegal && <Button onClick={handleCloseLegal}>Close</Button>}
      </DialogActions>
    </Dialog>
  );
};

LegalAgreement.propTypes = {
  handleCloseLegal: PropTypes.func,
  hideLegalAgreementModal: PropTypes.func,
};

export default LegalAgreement;
