import React from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../../ScreenSmith';
import useScreenDefinition from '../../../hooks/useScreenDefinition';
import EditUserAgenciesInternal from './EditUserAgenciesInternal';
import EditUserAgenciesAgent from './EditUserAgenciesAgent';

const LocalComponents = { EditUserAgenciesInternal, EditUserAgenciesAgent };

const EditUserAgenciesAgentSD = () => {
  const { UiDefinitionsData } = useScreenDefinition(`EditUserAgencies`);

  const localStateMap = {};

  const callBackFunctions = {};

  return (
    !_.isNil(UiDefinitionsData) && (
      <ScreenSmith
        definition={UiDefinitionsData}
        functionMap={callBackFunctions}
        stateMap={localStateMap}
        componentMap={LocalComponents}
      />
    )
  );
};

export default EditUserAgenciesAgentSD;
