import React from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, DatePicker, FormControlLabel, Select, Typography } from '../../../AORedesign';

export const defaultLOB = 'All';

const defaultOptions = [
  { label: 'All Lines', value: defaultLOB },
  { label: 'All Personal Lines', value: 'All Personal Lines' },
  { label: 'All Commercial Lines', value: 'All Commercial Lines' },
];

const extendedOptions = [
  { label: 'HomeOwners', value: 'HomeOwners' },
  { label: 'Personal Automobile', value: 'Personal Automobile' },
];

// To do: Move this to an API call after FastAPI migration is complete (agreed by Jeff D and Brent E)
const policyStates = [
  {
    value: '01',
    label: 'Alabama',
  },
  {
    value: '54',
    label: 'Alaska',
  },
  {
    value: '02',
    label: 'Arizona',
  },
  {
    value: '03',
    label: 'Arkansas',
  },
  {
    value: '04',
    label: 'California',
  },
  {
    value: '05',
    label: 'Colorado',
  },
  {
    value: '06',
    label: 'Connecticut',
  },
  {
    value: '07',
    label: 'Delaware',
  },
  {
    value: '09',
    label: 'Florida',
  },
  {
    value: '10',
    label: 'Georgia',
  },
  {
    value: '52',
    label: 'Hawaii',
  },
  {
    value: '11',
    label: 'Idaho',
  },
  {
    value: '12',
    label: 'Illinois',
  },
  {
    value: '13',
    label: 'Indiana',
  },
  {
    value: '14',
    label: 'Iowa',
  },
  {
    value: '15',
    label: 'Kansas',
  },
  {
    value: '16',
    label: 'Kentucky',
  },
  {
    value: '17',
    label: 'Louisiana',
  },
  {
    value: '18',
    label: 'Maine',
  },
  {
    value: '19',
    label: 'Maryland',
  },
  {
    value: '20',
    label: 'Massachusetts',
  },
  {
    value: '21',
    label: 'Michigan',
  },
  {
    value: '22',
    label: 'Minnesota',
  },
  {
    value: '23',
    label: 'Mississippi',
  },
  {
    value: '24',
    label: 'Missouri',
  },
  {
    value: '25',
    label: 'Montana',
  },
  {
    value: '26',
    label: 'Nebraska',
  },
  {
    value: '27',
    label: 'Nevada',
  },
  {
    value: '28',
    label: 'New Hampshire',
  },
  {
    value: '29',
    label: 'New Jersey',
  },
  {
    value: '30',
    label: 'New Mexico',
  },
  {
    value: '31',
    label: 'New York',
  },
  {
    value: '32',
    label: 'North Carolina',
  },
  {
    value: '33',
    label: 'North Dakota',
  },
  {
    value: '34',
    label: 'Ohio',
  },
  {
    value: '35',
    label: 'Oklahoma',
  },
  {
    value: '36',
    label: 'Oregon',
  },
  {
    value: '37',
    label: 'Pennsylvania',
  },
  {
    value: '38',
    label: 'Rhode Island',
  },
  {
    value: '39',
    label: 'South Carolina',
  },
  {
    value: '40',
    label: 'South Dakota',
  },
  {
    value: '41',
    label: 'Tennessee',
  },
  {
    value: '42',
    label: 'Texas',
  },
  {
    value: '43',
    label: 'Utah',
  },
  {
    value: '44',
    label: 'Vermont',
  },
  {
    value: '45',
    label: 'Virginia',
  },
  {
    value: '46',
    label: 'Washington',
  },
  {
    value: '47',
    label: 'West Virginia',
  },
  {
    value: '48',
    label: 'Wisconsin',
  },
  {
    value: '49',
    label: 'Wyoming',
  },
];

const policyRegions = [
  {
    value: 'NE',
    label: 'New England Region',
  },
  {
    value: 'NO',
    label: 'Northeast Region',
  },
  {
    value: 'SE',
    label: 'Southeast Region',
  },
  {
    value: 'WE',
    label: 'Western Region',
  },
];

const BMReportForm = ({
  eftChoiceValue,
  fromDateValue,
  onlyNonRenewValue,
  handleEftChoiceOnChange,
  handleFromDateOnChange,
  handleLOBOnChange,
  handleOnlyNonRenewOnChange,
  handleRegionOnChange,
  handleShowAddressOnChange,
  handleStateNumericCodeOnChange,
  handleToDateOnChange,
  lobValue,
  regionValue,
  reportType,
  showAddress,
  stateNumericCodeValue,
  toDateValue,
}) => {
  const getLOBMenuItems = (lobMenuOptions) => {
    switch (lobMenuOptions) {
      case 0:
        return [...defaultOptions, ...extendedOptions];
      case 1:
        return [{ label: 'Both', value: defaultLOB }, ...extendedOptions];
      default:
        return [...defaultOptions];
    }
  };

  const onlyNonRenewMenuItems = [
    { label: 'All expiring policies', value: 'false' },
    { label: 'Expiring policies not renewed', value: 'true' },
  ];

  const eftChoiceMenuItems = [
    { label: 'Insured Using EFT', value: '0' },
    { label: 'Insured Not Using EFT', value: '1' },
    { label: 'Both', value: '2' },
  ];

  const renderReportForm = ({ lobMenuOptions, dateText, hideAddress, hideDate, showExpiringPolicies, showPaymentMethod, showPolicyStatus }) => (
    <>
      <Box my={3}>
        <Select
          id={'bmreport-lob-select'}
          labelId={'bmreport-lob-select-label'}
          label={'Select Line of Business'}
          value={lobValue}
          onChange={handleLOBOnChange}
          menuItems={getLOBMenuItems(lobMenuOptions)}
          name={'lob'}
        />
      </Box>

      {showExpiringPolicies && (
        <Box mb={3}>
          <Select
            id={'bmreport-onlyNonRenew-select'}
            labelId={'bmreport-onlyNonRenew-select-label'}
            label={'Show report of'}
            name={'onlyNonRenew'}
            value={onlyNonRenewValue}
            onChange={handleOnlyNonRenewOnChange}
            menuItems={onlyNonRenewMenuItems}
          />
        </Box>
      )}

      {showPaymentMethod && (
        <Box mb={3}>
          <Select
            id={'bmreport-eftChoice-select'}
            labelId={'bmreport-eftChoice-select-label'}
            label={'Select EFT Report Choice'}
            name={'eftChoice'}
            value={eftChoiceValue}
            onChange={handleEftChoiceOnChange}
            menuItems={eftChoiceMenuItems}
          />
        </Box>
      )}

      {dateText && <Typography>{dateText}</Typography>}
      {!hideDate && (
        <Box mt={2} mb={3}>
          <DatePicker id={'bmreport-from-datepicker'} name={'fromDate'} label={'From'} value={fromDateValue} onChange={handleFromDateOnChange} />
          <Box mr={2} display={'inline'} />
          <DatePicker id={'bmreport-to-datepicker'} name={'toDate'} label={'To'} value={toDateValue} onChange={handleToDateOnChange} />
        </Box>
      )}

      {!hideAddress && (
        <Box mb={3}>
          <FormControlLabel
            control={<Checkbox checked={showAddress} onChange={handleShowAddressOnChange} name={'showAddress'} color="primary" />}
            label="Insured Address"
          />
        </Box>
      )}

      {showPolicyStatus && (
        <>
          <Typography>To narrow your search, select a state or region from the options below</Typography>
          <Box mt={2} mb={3}>
            <Select
              id={'bmreport-stateNumericCode-select'}
              labelId={'bmreport-stateNumericCode-select-label'}
              label={'State'}
              name={'stateNumericCode'}
              value={stateNumericCodeValue}
              onChange={handleStateNumericCodeOnChange}
              menuItems={policyStates}
            />
            <Box mr={2} display={'inline'} />
            <Select
              id={'bmreport-region-select'}
              labelId={'bmreport-region-select-label'}
              label={'Region'}
              name={'region'}
              value={regionValue}
              onChange={handleRegionOnChange}
              menuItems={policyRegions}
            />
          </Box>
        </>
      )}
    </>
  );

  const renderReport = () => {
    switch (reportType) {
      case 'book-mgmt-non-pay-policy':
        return renderReportForm({
          dateText: 'Range of Notice Effective Dates',
        });
      case 'book-mgmt-cancelled-policy':
        return renderReportForm({
          lobMenuOptions: 0,
          dateText: 'Range of Cancel Effective Dates',
        });
      case 'book-mgmt-expiring-policy':
        return renderReportForm({
          dateText: 'Range of Expiration Dates',
          showExpiringPolicies: true,
          lobMenuOptions: 0,
        });
      case 'book-mgmt-installment-due':
        return renderReportForm({});
      case 'book-mgmt-payment-method':
        return renderReportForm({ hideDate: true, showPaymentMethod: true });
      case 'book-mgmt-policy-status':
        return renderReportForm({
          hideDate: true,
          hideAddress: true,
          showPolicyStatus: true,
          lobMenuOptions: 1,
        });
      default:
        return null;
    }
  };

  return reportType && <>{renderReport()}</>;
};

BMReportForm.propTypes = {
  reportType: PropTypes.string,
  lobValue: PropTypes.string,
  eftChoiceValue: PropTypes.string,
  fromDateValue: PropTypes.string,
  onlyNonRenewValue: PropTypes.string,
  toDateValue: PropTypes.string,
  showAddress: PropTypes.bool,
  handleLOBOnChange: PropTypes.func,
  handleFromDateOnChange: PropTypes.func,
  handleToDateOnChange: PropTypes.func,
  handleEftChoiceOnChange: PropTypes.func,
  handleShowAddressOnChange: PropTypes.func,
  handleOnlyNonRenewOnChange: PropTypes.func,
  stateNumericCodeValue: PropTypes.string,
  handleStateNumericCodeOnChange: PropTypes.func,
  regionValue: PropTypes.string,
  handleRegionOnChange: PropTypes.func,
};

export default BMReportForm;
