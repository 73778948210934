import { makeStyles } from '@material-ui/core';
import React from 'react';

import { Box, Button, Icon, Typography } from 'components/AORedesign';
import { PolicyViewChange } from 'components/PolicyViewChange';
import { StartQuote } from 'components/StartQuote';

const useStyles = makeStyles((theme) => ({
  policyViewChangeButton: {
    width: '100%',
  },
  startQuoteButton: {
    backgroundColor: theme.palette.success.main,
    width: '100%',

    '&:hover': { backgroundColor: theme.palette.success.dark },
  },
}));

const WantTo = () => {
  const classes = useStyles();

  const StartQuoteButton = (props) => (
    <Button className={classes.startQuoteButton} size="large" startIcon={<Icon iconName="icons-icon-request-quote" />} {...props}>
      Start Quote
    </Button>
  );
  const PolicyViewChangeButton = (props) => (
    <Button className={classes.policyViewChangeButton} size="large" startIcon={<Icon iconName="icons-icon-published-with-changes" />} {...props}>
      Policy View/Change
    </Button>
  );

  return (
    <Box mx={3}>
      <Box mb={2}>
        <Typography variant="h6">I want to ...</Typography>
      </Box>
      <Box mb={2}>
        <StartQuote TriggerButton={StartQuoteButton} />
      </Box>
      <Box mb={2}>
        <PolicyViewChange TriggerButton={PolicyViewChangeButton} />
      </Box>
    </Box>
  );
};

export default WantTo;
