import { AppBar, Box, Divider, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';
import React, { useContext } from 'react';

import { BellAlerts } from 'components/AORedesignViews/BellAlerts';
import { FeedbackForm } from 'components/AORedesignViews/FeedbackForm';
import { ROUTE_DASH } from 'constants/routes';
import agentContext from '../../contexts/agent.context';
import { SvgLogo } from '../SvgLogo';
import AgentDisplay from './AgentDisplay';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
  },
  toolBar: { display: 'flex', justifyContent: 'space-between' },
  logo: { display: 'flex', alignItems: 'center' },
  header: {
    fontFamily: `'Montserrat', sans-serif`,
    fontSize: rem(28),
    fontWeight: 700,
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
}));

const Header = () => {
  const classes = useStyles();
  const agentContextObj = useContext(agentContext);

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.logo}>
            <SvgLogo route={ROUTE_DASH} />
            <Box pr={2} />
            <Divider orientation="vertical" flexItem></Divider>
            <Box pr={2} />
            <Typography color="primary" className={classes.header}>
              Agents Only
            </Typography>
          </div>
          <div className={classes.name}>
            <FeedbackForm />
            <BellAlerts />
            <AgentDisplay
              agentFirstName={agentContextObj?.Name?.First ?? 'Agents'}
              agentLastName={agentContextObj?.Name?.Last ?? 'Only'}
              agentDisplayName={agentContextObj?.Name ? `${agentContextObj.Name.First} ${agentContextObj.Name.Last}` : 'Agents Only'}
              agentEmail={agentContextObj?.EmailAddress ?? 'agent@agentaccess.local'}
            />
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
