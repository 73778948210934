const validation = {
  givenName: {
    required: 'First Name is a required field. Please enter it now.',
    validationMessage: 'Special characters are NOT allowed in First Name',
    pattern: '^[A-Za-z0-9]+$',
  },
  sn: {
    required: 'Last name is a required field. Please enter it now.',
    validationMessage: 'Special characters are NOT allowed in Last Name',
    pattern: '^[A-Za-z0-9]+$',
  },
  mail: {
    required: 'Email is a required field. Please enter it now.',
    validationMessage: 'Please enter a valid email address in the form of user@domain.com',
    pattern:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  artAgentID: 'Please enter 7 digit AMIC Agency Number',
  ModID: 'Please enter 3 alphanumeric characters for Mod Id',
  ininetChAns: {
    required: 'Challenge Response is a required field. Please enter it now.',
    validationMessage: 'Special characters are NOT allowed in Challenge Response',
    pattern: '[A-Za-z0-9\\?\\-\\.\x27\x20]+$',
  },
};

const initialResponseAlert1 = {
  isVisible: false,
  message: 'Allows access to ALL agency codes',
  severity: 'info',
};

const agencySelectedNullAlert = {
  isVisible: false,
  message:
    'Either View All needs to be checked or at least one Agency must be selected before proceeding.',
  severity: 'error',
};

const memberGroupNullAlert = {
  isVisible: false,
  message: 'Group Membership is a required filed. Please enter it now.',
  severity: 'error',
};

const initialResponseAlert = {
  isVisible: true,
  message: 'Special Characters are NOT allowed in First and Last Name fields',
  severity: 'info',
};
const challengeQuestionsMenuItems = (challengeQuestions) =>
  challengeQuestions.map((question) => {
    return { code: `${challengeQuestions.indexOf(question)}`, value: question };
  });
const namePattern = new RegExp(validation.givenName.pattern);
const validateFirstName = (e, setFirstNameError, setFormData) => {
  const value = e?.target.value;
  if (!e) {
    setFirstNameError(null);
    return false;
  }
  if (!value) {
    setFirstNameError(validation.givenName.required);
  } else if (!namePattern.test(value)) {
    setFirstNameError(validation.givenName.validationMessage);
  } else {
    setFirstNameError(null);
  }
  setFormData((prevState) => ({ ...prevState, givenName: value }));
};

const validateLastName = (e, setLastNameError, setFormData) => {
  const value = e?.target.value;
  if (!e) {
    setLastNameError(null);
    return false;
  }
  if (!value) {
    setLastNameError(validation.sn.required);
  } else if (!namePattern.test(value)) {
    setLastNameError(validation.sn.validationMessage);
  } else {
    setLastNameError(null);
  }
  setFormData((prevState) => ({ ...prevState, sn: value }));
};

const validateEmail = (e, setEmailError, setFormData) => {
  const value = e?.target.value;

  if (!e) {
    setEmailError(null);
    return false;
  }
  if (!value) {
    setEmailError(validation.mail.required);
  } else if (!value.toLowerCase().match(validation.mail.pattern)) {
    setEmailError(validation.mail.validationMessage);
  } else {
    setEmailError(null);
  }
  setFormData((prevState) => ({ ...prevState, mail: value }));
};
const validateAgencyId = (e, setAgencyIdError, setFormData) => {
  const value = e?.target.value;
  if (!e) {
    setAgencyIdError(null);
    return false;
  }
  if (value.length !== 0 && (value.length !== 7 || isNaN(+value))) {
    setAgencyIdError(validation.artAgentID);
  } else {
    setAgencyIdError(null);
  }
  setFormData((prevState) => ({ ...prevState, artAgentID: value }));
};

const validateModId = (e, setModIdError, setFormData) => {
  const value = e?.target.value;
  if (!e) {
    setModIdError(null);
    return false;
  }
  if (value.length !== 0 && (value.length !== 3 || !isNaN(+value))) {
    setModIdError(validation.ModID);
  } else {
    setModIdError(null);
  }
  setFormData((prevState) => ({ ...prevState, modId: value }));
};

export {
  validation,
  initialResponseAlert1,
  agencySelectedNullAlert,
  memberGroupNullAlert,
  initialResponseAlert,
  challengeQuestionsMenuItems,
  namePattern,
  validateFirstName,
  validateLastName,
  validateEmail,
  validateAgencyId,
  validateModId,
};
