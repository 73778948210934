// Helper to join values in array in format for BrandFolder API to parse search criteria
export const getEnumSearchCriteria = (values) => values.map((value) => `"${value}"`).join(' OR ');

// Helper to join words in string in format for BrandFolder API to parse search criteria
// All words must we wrapped in double quotes. If no words as passed, return an empty string
export const getSplitStringSearchCriteria = (string, searchCriteriaKey) => {
  if (searchCriteriaKey === 'terms') {
    return string.length > 0 ? string.split(' ').join(' OR ') : '';
  } else {
    return string.length > 0
      ? string
          .split(' ')
          .map((value) => `"${value}"`)
          .join(' AND ')
      : '';
  }
};

export const TYPE_OF_CONTAINER_FILTER_MAPPING = {
  Underwriting: ['showState', 'showLineOfBusiness', 'showProduct', 'showDocumentType'],
  Marketing: ['showState', 'showLineOfBusiness', 'showProduct', 'showDocumentType'],
  Training: ['showState', 'showLineOfBusiness', 'showDocumentType'],
};
