import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Typography,
  Box,
  Paper,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  LinearProgress,
} from '@material-ui/core';

import { AGENCY_ADMIN_GET_CURRENT_AGENCIES, AGENCY_ADMIN_ADD } from '../../../constants/api';
import { EditUserAgenciesContext } from '../context/userAdmin.context';
import Col from '../../ScreenSmith/Col';
import { AOButton } from '../../AOButton';
import { AOTextField } from '../../AOTextField';
import { AODataGrid } from '../../AODataGrid';

const potentialAgenciesColumns = [
  {
    field: 'agencyId',
    headerName: 'Agency Id',
    width: 200,
  },
  {
    field: 'agencyName',
    headerName: 'Agency Name',
    width: 450,
  },
  {
    field: 'agencyCity',
    headerName: 'Agency City',
    width: 200,
  },
  {
    field: 'agencyState',
    headerName: 'Agency State',
    width: 200,
  },
];

const EditUserAgenciesInternal = () => {
  const [pageSize, setPageSize] = useState(25);
  const [addAgenciesButtonLoading, setAddAgenciesButtonLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    await fetchCurrentAgencies();
    setLoading(false);
  }, []);

  const {
    userDetails,
    currentAgencies,
    setCurrentAgencies,
    agencyNameValue,
    potentialAgencies,
    handleCurrentAgenciesCheckedStateOnChange,
    handleDeleteOnClick,
    handleAgencyNameOnChange,
    handleSearchOnClick,
    handleClearOnClick,
    currentAgenciesCheckedState,
    searchButtonLoading,
  } = useContext(EditUserAgenciesContext);

  const fetchCurrentAgencies = async () => {
    try {
      const userNameQueryString = `?userName=${userDetails.name}`;
      const getUserDetailsUrl = `${AGENCY_ADMIN_GET_CURRENT_AGENCIES}${userNameQueryString}`;
      const details = await axios.get(getUserDetailsUrl);
      setCurrentAgencies(Array.isArray(details.data) ? details.data : []);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageSizeChange = (pageSize) => setPageSize(pageSize);

  const handleAddAgencies = async () => {
    setAddAgenciesButtonLoading(true);
    try {
      const getUserDetailsUrl = `${AGENCY_ADMIN_ADD}`;
      const { status } = await axios.put(getUserDetailsUrl, {
        agencies: selectionModel,
        userName: userDetails.name,
      });

      if (status === 200) {
        fetchCurrentAgencies();
        handleSearchOnClick();
        setSelectionModel([]);
      }
    } catch (error) {
      console.error(error);
    }
    setAddAgenciesButtonLoading(false);
  };

  const handleSelectionModelOnChange = (model) => {
    setSelectionModel(model);
  };

  const handleDeleteAgencies = async () => {
    await handleDeleteOnClick();
    fetchCurrentAgencies();
  };

  return (
    <>
      <Col xs={7}>
        {loading ? (
          <LinearProgress />
        ) : (
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <Box p={1} display={'flex'} justifyContent={'space-between'}>
              <Typography>
                <Box fontSize={'1.25rem'} fontWeight={'bold'}>
                  Current Agencies
                </Box>
              </Typography>
              <AOButton
                variant={'contained'}
                color={'secondary'}
                onClick={() => handleDeleteAgencies()}
                disabled={!currentAgenciesCheckedState.length}>
                Delete
              </AOButton>
            </Box>
            <Box p={1} style={{ maxHeight: '15rem', overflow: 'auto' }} ml={2}>
              {userDetails?.ininetViewAll === 'true' ? (
                <Typography color="textSecondary">**Access to all groups and Agencies**</Typography>
              ) : (
                <FormControl component={'fieldset'}>
                  <FormGroup>
                    {currentAgencies.map((agency) => (
                      <FormControlLabel
                        key={agency}
                        control={
                          <Checkbox
                            checked={currentAgenciesCheckedState.includes(agency)}
                            onChange={(e) => handleCurrentAgenciesCheckedStateOnChange(e)}
                            name={agency}
                            value={agency}
                          />
                        }
                        label={agency}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              )}
            </Box>
          </Paper>
        )}
      </Col>
      <Col xs={12}>
        <Paper variant="outlined" square>
          <Box p={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <AOTextField
              label={'Agency Name'}
              placeholder={'Enter Agency Name'}
              onChange={handleAgencyNameOnChange}
              value={agencyNameValue}
              id={'search-user-agency-name-search-textfield'}
            />
            <Box>
              <AOButton
                id={'search-user-agency-name-search-button'}
                variant={'contained'}
                color={'secondary'}
                onClick={handleSearchOnClick}
                disabled={!agencyNameValue.length || searchButtonLoading}
                loading={searchButtonLoading}>
                Search
              </AOButton>
              <Box display={'inline'} mr={1} />
              <AOButton
                id={'search-user-agency-name-clear-button'}
                variant={'outlined'}
                onClick={handleClearOnClick}
                color={'secondary'}>
                Clear
              </AOButton>
            </Box>
          </Box>
          {potentialAgencies && (
            <>
              <Box p={1} mt={4} display={'flex'} justifyContent={'space-between'}>
                <Typography>
                  <Box fontSize={'1.25rem'} fontWeight={'bold'}>
                    Potential Agencies
                  </Box>
                </Typography>
                <AOButton
                  variant={'contained'}
                  color={'secondary'}
                  onClick={handleAddAgencies}
                  disabled={addAgenciesButtonLoading || !selectionModel.length}>
                  {`Add Agenc${selectionModel.length > 1 ? 'ies' : 'y'}`}
                </AOButton>
              </Box>
              <Box p={1} style={{ height: 400, overflow: 'auto' }}>
                <AODataGrid
                  rows={potentialAgencies.map((agency) => ({ ...agency, id: agency.agencyId }))}
                  columns={potentialAgenciesColumns}
                  pageSize={pageSize}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                  checkboxSelection
                  disableSelectionOnClick
                  selectionModel={selectionModel}
                  onSelectionModelChange={handleSelectionModelOnChange}
                  onPageSizeChange={handlePageSizeChange}
                  loading={searchButtonLoading}
                />
              </Box>
            </>
          )}
        </Paper>
      </Col>
    </>
  );
};

export default EditUserAgenciesInternal;
