import useAxios from 'axios-hooks';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAnnouncements } from 'redux/app-store';

import {
  Box,
  Checkbox,
  DataGrid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Paper,
  Radio,
  RadioGroup,
  ViewContainer,
  defaultPaginationData,
} from 'components/AORedesign';
import { SelectStates } from 'components/AORedesign/SelectStates';
import { handleFileDownload } from 'components/CommonUtilityServices/DownloadFileService.js';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import NewsArticleModal from 'components/NewsArticles/NewsArticleModal';
import { GET_NEWS, NEWS_ARTICLES } from 'constants/api';
import { ANNOUNCEMENT_TYPES } from 'constants/static';

const Announcements = () => {
  let noResultsMessage = 'No results found. Adjust your filters.';

  const announcementsRef = useRef([]);
  const [selectedDateRangeOption, setSelectedDateRangeOption] = React.useState('180_DAYS');
  const [paginationData, setPaginationData] = useState({ ...defaultPaginationData, pageSize: 50 });
  const [selectedAnnouncementType, setSelectedAnnouncementType] = useState({ msa: true, cl: true, pl: true });
  const [selectedState, setSelectedState] = useState('ALL');
  const [gridResults, setGridResults] = useState([]);
  const [, setLoadingFile] = useState(false);
  const [, setIsDownloadingFileErrorVisible] = useState(false);
  const [, setDownloadingFileError] = useState(false);
  const [open, setOpen] = useState(false);
  const announcementsObj = useSelector((state) => state.app.announcements);

  // pull announcements from the store
  const { announcements } = useSelector((state) =>
    selectAnnouncements(state, [ANNOUNCEMENT_TYPES.ANNOUNCEMENT_HOME_PAGE, ANNOUNCEMENT_TYPES.ANNOUNCEMENT_PL, ANNOUNCEMENT_TYPES.ANNOUNCEMENT_CL]),
  );

  // on page load
  useEffect(() => {
    // add additional fields to the array items
    let prepAnnouncements = announcements.map((announcement, i) => ({
      ...announcement,
      id: i,
      publishedDate: new Date(announcement.StartDate).setHours(0, 0, 0, 0), // Custom field used for date comparison because StartDate is returned a string. We convert it to a Date object and store as publishedDate for comparison with dateRange
    }));

    announcementsRef.current = prepAnnouncements;

    // apply page filters
    applyFilters();
  }, [announcementsObj]);

  const applyFilters = () => {
    // get a dateRange object of start and end dates based on selected date range option
    const dateRange = _getDateRange(selectedDateRangeOption);

    // create an array of selected announcement types
    const categoryArray = [];
    if (selectedAnnouncementType.msa) categoryArray.push(ANNOUNCEMENT_TYPES.ANNOUNCEMENT_HOME_PAGE);
    if (selectedAnnouncementType.cl) categoryArray.push(ANNOUNCEMENT_TYPES.ANNOUNCEMENT_CL);
    if (selectedAnnouncementType.pl) categoryArray.push(ANNOUNCEMENT_TYPES.ANNOUNCEMENT_PL);

    // filter based on Announcement Type & Date Range
    let results = announcementsRef.current
      .filter((item) => categoryArray.includes(item.newsType)) // filter by news type such as HPNA,CLNA,PLNA
      .filter((item) => item.publishedDate >= dateRange.startDate && item.publishedDate <= dateRange.endDate); // filter by date published (custom field - StartDate at 00:00:00)

    // filter by state code
    if (selectedState !== 'ALL') {
      results = results.filter((item) => {
        const applicableStates = item.stateCodes.toUpperCase().split(',');
        // filter by state
        return applicableStates.includes(selectedState.toUpperCase());
      });
    }

    // update paging componment
    setPaginationData({ ...paginationData, totalCount: results.length, currentPage: 0 });

    // update the grid
    setGridResults(results);
  };

  // filter announcements when filters change
  useEffect(() => {
    applyFilters();
  }, [selectedState, selectedDateRangeOption, selectedAnnouncementType]);

  // used when displaying the announcement modal
  const [{ data: dataNewsReview, loading: loadingNewsReview, error: errorNewsReview }, executeNewsReview] = useAxios();
  const handleClose = () => {
    setOpen(false);
  };

  // handle pagination
  const handleOnPageChange = (page) => {
    setPaginationData((prevState) => ({ ...prevState, currentPage: page }));
  };
  const handleOnPageSizeChange = (pageSize) => {
    setPaginationData({ pageSize: pageSize });
  };

  // handle filter changes
  const handleDatePublishFilterChange = (event) => {
    setSelectedDateRangeOption(event.target.value);
  };
  const handleCategoryChange = (event) => {
    setSelectedAnnouncementType({ ...selectedAnnouncementType, [event.target.name]: event.target.checked });
  };
  const handleStateChange = (e) => {
    const { value } = e.target;
    setSelectedState(value);
  };

  // handle click of announcement in grid cell
  const renderCell = (params) => {
    const handleOnClick = (ext, seqId) => {
      const trimmedExt = _.trim(ext); // remove whitespace
      if (_.isEmpty(trimmedExt)) {
        setOpen(true);
        executeNewsReview({
          url: `${GET_NEWS}${NEWS_ARTICLES}/${seqId}`,
        });
      } else {
        const fileURL = `${NEWS_ARTICLES}/${seqId}`;
        if (trimmedExt === 'pdf') {
          handlePdfDownload('GET', null, fileURL, setLoadingFile, setDownloadingFileError, setIsDownloadingFileErrorVisible, 'newWindow', `${GET_NEWS}`);
        } else {
          handleFileDownload(fileURL, setLoadingFile, setDownloadingFileError, setIsDownloadingFileErrorVisible, 'newWindow', 'GET', undefined, GET_NEWS);
        }
      }
    };
    var endIconName = params.row.ext === 'pdf' ? 'pdf' : 'document';

    return (
      params.row.title && (
        <Link onClick={() => handleOnClick(params.row.ext, params.row.seqId)} endIcon={endIconName}>
          {params.row.title?.trim()}
        </Link>
      )
    );
  };

  // set grid columns
  const gridColumns = [
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 400,
      flex: 1,
      renderCell,
    },
    {
      field: 'typeDescr',
      headerName: 'Category',
      flex: 0.5,
    },
    {
      field: 'stateCodes',
      headerName: 'State',
      flex: 0.25,
    },
    {
      field: 'StartDate',
      headerName: 'Published Date',
      flex: 0.5,
    },
  ];

  return (
    <ViewContainer returnRoute={{ text: 'All Announcements' }}>
      <Box display="flex">
        <Box display="inline" bgcolor="background.paper" variant="outlined" p={1} height="fit-content">
          <Box p={2} fontSize="h6.fontSize">
            Filter
          </Box>
          <Box p={2}>
            <SelectStates
              label="States"
              name="states"
              value={selectedState}
              onChange={handleStateChange}
              includeAllOption={true}
              onlyUsersAssignedStates={true}
            />
            {/* <Select label="States" name="states" value={selectedState} menuItems={stateOptions} onChange={handleStateChange} /> */}
          </Box>
          <Box p={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Date Published</FormLabel>
              <RadioGroup aria-label="dateRangeFilter" name="dateRangeFilter" value={selectedDateRangeOption} onChange={handleDatePublishFilterChange}>
                <Radio label="Past 30 days" value={'30_DAYS'} />
                <Radio label="Past 90 days" value={'90_DAYS'} />
                <Radio label="Past 180 days" value={'180_DAYS'} />
                <Radio label="This year" value={'CURRENT_YEAR'} />
                <Radio label="Past 2 years" value={'LAST_2_YEARS'} />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box p={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Category</FormLabel>
              <FormControlLabel
                control={<Checkbox checked={selectedAnnouncementType.msa} name="msa" onChange={handleCategoryChange} />}
                label="Main Street America"
              />
              <FormControlLabel
                control={<Checkbox checked={selectedAnnouncementType.cl} name="cl" onChange={handleCategoryChange} />}
                label="Commercial Lines"
              />
              <FormControlLabel control={<Checkbox checked={selectedAnnouncementType.pl} name="pl" onChange={handleCategoryChange} />} label="Personal Lines" />
            </FormControl>
          </Box>
        </Box>
        <Box p={1} />
        <Box flex={1} height="fit-content">
          <Paper>
            <DataGrid
              columns={gridColumns}
              loading={announcementsObj.loading}
              onPageChange={handleOnPageChange}
              onPageSizeChange={handleOnPageSizeChange}
              page={paginationData.currentPage}
              pageSize={paginationData.pageSize}
              paginationMode="client"
              rowCount={paginationData.totalCount}
              rows={gridResults}
              noResultsMessage={noResultsMessage}
              autoHeight
            />
          </Paper>
        </Box>
      </Box>
      <Box>
        <NewsArticleModal open={open} handleClose={handleClose} loading={loadingNewsReview} error={errorNewsReview} data={dataNewsReview} />
      </Box>
    </ViewContainer>
  );
};

export default Announcements;

// set start and end date values based on days
const _getDateRange = (dateRangeOption) => {
  const currentDate = new Date();
  const dateRange = { startDate: '', endDate: currentDate };

  switch (dateRangeOption) {
    case '30_DAYS':
      dateRange.startDate = new Date(new Date().setDate(currentDate.getDate() - 30));
      break;
    case '90_DAYS':
      dateRange.startDate = new Date(new Date().setDate(currentDate.getDate() - 90));
      break;
    case '180_DAYS':
      dateRange.startDate = new Date(new Date().setDate(currentDate.getDate() - 180));
      break;
    case 'CURRENT_YEAR':
      dateRange.startDate = new Date(currentDate.getFullYear(), 0, 1);
      break;
    case 'LAST_2_YEARS':
      dateRange.startDate = new Date(currentDate.getFullYear() - 2, currentDate.getMonth(), currentDate.getDate());
      break;
    default:
      console.log('Invalid Range Selected');
  }
  // Set hours to 00:00:00 for date comparison without time
  dateRange.startDate.setHours(0, 0, 0, 0);
  dateRange.endDate.setHours(0, 0, 0, 0);
  return dateRange;
};
