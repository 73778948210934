import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { store } from 'redux/store';
import './fonts/Montserrat/Montserrat-VariableFont_wght.ttf';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ACCOUNT,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <script>
        window.dataLayer = window.dataLayer || [];
      </script>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
