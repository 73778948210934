import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { AOBox } from '../AOBox';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';
import { GET_REPORTS_URL, POST_BILLING_PAYMENT } from '../../constants/api';
import {
  StyledBillingInquiryDetailsPDFButton,
  StyledModalContainer,
  StyledConfirmationButton,
} from './BillingInquiryDetails.styled';

const BillingInquiryDetailsPDFButton = ({ buttonText, ...props }) => {
  return (
    <StyledBillingInquiryDetailsPDFButton {...props}>
      {buttonText} &nbsp;
      <PictureAsPdfIcon fontSize="small" />
    </StyledBillingInquiryDetailsPDFButton>
  );
};

const BillingInquiryDetailsPaymentModal = ({
  open,
  handleClose,
  accountNumber,
  accountName,
  paymentAmount,
  paymentNote,
  agentNumber,
  paymentType,
  nonPayValue,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [paymentInformation, setPaymentConfirmationInformation] = useState('');

  const resetState = () => {
    setShowConfirmation(false);
  };

  const handleCloseOnClick = () => {
    resetState();
    handleClose();
    setPaymentConfirmationInformation('');
  };

  const handleSubmitOnClick = async () => {
    const requestBody = {
      processType: paymentType,
      bankType: '',
      rout: '',
      acct: '',
      payType: paymentNote,
      txtAcctNum: accountNumber,
      txtAgtNum: agentNumber,
      txtPayAmount: paymentAmount,
      nonPay: nonPayValue,
      pvSubmitted: 'S',
    };

    try {
      setPaymentConfirmationInformation('Payment is in progress, please wait....');
      const { data } = await axios.post(POST_BILLING_PAYMENT, requestBody);
      setShowConfirmation(true);
      setPaymentConfirmationInformation(data.id);
    } catch (error) {
      console.error('Error processing payment:', error);
    }
  };

  const handlePrintConfirmation = () => {
    const request_body = {
      Area: 'billing-payment',
      DetailedContext: [
        {
          name: 'accountNumber',
          value: accountNumber,
        },
        {
          name: 'payIndex',
          value: paymentInformation,
        },
      ],
      TypeOfRedirect: 'Generate-PDF',
    };

    handlePdfDownload(
      'POST',
      request_body,
      GET_REPORTS_URL,
      () => {},
      () => {},
      () => {},
      true,
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseOnClick}
      aria-labelledby="billing-details-payment"
      aria-describedby="billing-details-payment-confirmation">
      <StyledModalContainer>
        <Paper variant="outlined" square>
          <Box p={3}>
            <Typography variant="h5">
              Payment {showConfirmation ? 'Confirmation' : 'Validation'}
            </Typography>
          </Box>
          <Box p={3} color="text.secondary">
            <Box mb={3}>
              {showConfirmation ? 'This acknowledges that you have entered' : 'You have entered'}{' '}
              <Box color="text.primary" display="inline">
                1
              </Box>{' '}
              {showConfirmation
                ? 'payment(s) for a total amount of'
                : 'payment(s) for an amount of'}{' '}
              <AOBox color="text.primary" display="inline" currency text={paymentAmount} />. This
              total amount will be swept from your agency account.
            </Box>
            <Box mb={3}>
              {showConfirmation
                ? 'Below is a summary of the payments made. Click on the confirmation number to produce a printable receipt for that payment.'
                : 'Please review the payment information below. If everything is correct, use the Submit button to finalize your transaction. Otherwise, you may cancel the transaction using the appropriate button.'}
            </Box>
            {showConfirmation && (
              <Box>
                <Box fontWeight={500} display="inline">{`Today's Date: `}</Box>
                {moment().format('dddd, MMMM Do, YYYY')}
              </Box>
            )}
          </Box>
          <Box p={3}>
            {showConfirmation ? (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Box textAlign="end">
                      <Box fontWeight={500} display="inline" pr={1}>
                        Confirmation #
                      </Box>
                      <StyledConfirmationButton
                        display="inline"
                        onClick={handlePrintConfirmation}
                        color="primary.main">
                        {paymentInformation}
                      </StyledConfirmationButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Box fontWeight={500} display="inline" pr={1}>
                        Account Name:
                      </Box>
                      <Box display="inline">{accountName}</Box>
                    </Box>
                    <Box>
                      <Box fontWeight={500} display="inline" pr={1}>
                        Account #:
                      </Box>
                      <Box display="inline">{accountNumber}</Box>
                    </Box>
                    <Box>
                      <Box fontWeight={500} display="inline" pr={1}>
                        Payment Amount:
                      </Box>
                      <Box display="inline">{paymentAmount}</Box>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={2}>
                    <Box fontWeight={500}>Account #</Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box fontWeight={500}>Account Name</Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box fontWeight={500}>Payment Amount</Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box fontWeight={500}>Payment Method</Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2}>
                    <Box>{accountNumber}</Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>{accountName}</Box>
                  </Grid>
                  <Grid item xs={2}>
                    <AOBox currency text={paymentAmount} />
                  </Grid>
                  <Grid item xs={2}>
                    <Box>{paymentNote}</Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
          <Box mx={3} my={2}>
            {!showConfirmation && (
              <Box color="error.main" py={2}>
                {paymentInformation}
              </Box>
            )}
            <Grid container spacing={2}>
              {!showConfirmation && (
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleSubmitOnClick}>
                    Submit
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button onClick={handleCloseOnClick} variant="outlined" color="primary">
                  {showConfirmation ? 'Close' : 'Cancel'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </StyledModalContainer>
    </Modal>
  );
};

const BillingInquiryEftIndicatorModal = ({ open, handleClose, handleYes }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="billing-details-payment"
      aria-describedby="billing-details-payment-confirmation">
      <StyledModalContainer>
        <Paper variant="outlined" square>
          <Box p={3}>
            <Typography>
              Policy is set-up for automatic payments. Would you still like to proceed to Make
              Payment?{' '}
            </Typography>
          </Box>
          <Box mx={1} my={1}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleYes}>
                  Yes
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleClose} variant="outlined" color="primary">
                  {' '}
                  No
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </StyledModalContainer>
    </Modal>
  );
};

BillingInquiryDetailsPaymentModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  accountNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accountName: PropTypes.string,
  paymentAmount: PropTypes.string,
  paymentNote: PropTypes.string,
  agentNumber: PropTypes.string,
  paymentType: PropTypes.string,
  nonPayValue: PropTypes.string,
};

BillingInquiryEftIndicatorModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleYes: PropTypes.func,
};

BillingInquiryDetailsPDFButton.propTypes = {
  buttonText: PropTypes.string,
};

export {
  BillingInquiryDetailsPDFButton,
  BillingInquiryDetailsPaymentModal,
  BillingInquiryEftIndicatorModal,
};
