import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { Autocomplete, Select, TextField } from 'components/AORedesign';
import { FILTER_TYPES, POLICY_FILTER_VALUES } from 'components/AORedesignViews/Clients';
import { GET_ALL_AGENTS } from 'constants/api';
import featureFlags from 'constants/featureFlags';
import agentContext from 'contexts/agent.context';
import AppDataContext from 'contexts/appData.context';

const AGENCY_CODE_LABEL = 'Agency Code';

const ClientAgencyCodeFilter = ({ filters, handleSetFilter }) => {
  const [agencyCodeOptions, setAgencyCodeOptions] = useState(null);

  const { Locations, FeatureFlags } = useContext(agentContext);
  const { dashboard } = useContext(AppDataContext);

  const agencyCode = filters?.[FILTER_TYPES.AGENCY_CODES];

  const LESS_THAN_EQUAL_LOCATION_LIMIT = Locations?.length <= dashboard?.locationCodeLimit; // small agency case is if it has less than or equal to 6 locations
  const AGENT_USER = FeatureFlags?.[featureFlags.ACCESS_USER_IS_AGENT];
  const CASE_LESS_THAN_EQUAL_LIMIT = Locations?.length > 0 && LESS_THAN_EQUAL_LOCATION_LIMIT;
  const AGENT_USER_AND_LESS_THAN_LIMIT = AGENT_USER && CASE_LESS_THAN_EQUAL_LIMIT;

  useEffect(() => {
    (async () => {
      // if agent with less than or equal to limit and top level filter is all clients, default to first agency code in dropdown
      if (AGENT_USER_AND_LESS_THAN_LIMIT && filters[FILTER_TYPES.POLICY] === POLICY_FILTER_VALUES.ALL) {
        const firstAgencyCode = Locations[0];
        handleSetFilter(FILTER_TYPES.AGENCY_CODES, firstAgencyCode);
      }
      // if agent with less than or equal to limit and top level filter is NOT all clients, default selecting all agencies
      else if (AGENT_USER_AND_LESS_THAN_LIMIT && filters[FILTER_TYPES.POLICY] !== POLICY_FILTER_VALUES.ALL) {
        handleSetFilter(FILTER_TYPES.AGENCY_CODES, Locations);
      }
    })();
  }, [filters[FILTER_TYPES.POLICY]]);

  useEffect(() => {
    (async () => {
      // if agent, populate agency code options from value returned in session
      if (FeatureFlags?.[featureFlags.ACCESS_USER_IS_AGENT] && Locations?.length > 0) {
        setAgencyCodeOptions(Locations.map((location) => ({ label: location, value: location })));
      }
      // else if internal user, populate from api call
      else if (FeatureFlags?.[featureFlags.ACCESS_USER_INTERNAL]) {
        // Calling commercial lines because it returns the same results regardless of personal or commercial for internal users
        try {
          const { data } = await axios.get(`${GET_ALL_AGENTS}/commercial-lines`);
          setAgencyCodeOptions(
            data.Results.reduce((filtered, location) => {
              if (location?.agencyCode?.length > 0) {
                const someNewValue = { label: location.agencyCode, value: location.agencyCode };
                filtered.push(someNewValue);
              }
              return filtered;
            }, []),
          );
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, []);

  const handleAgencyCodeOnChangeAutocomplete = (e, newValue) => {
    const value = newValue.value;
    handleSetFilter([FILTER_TYPES.AGENCY_CODES], value); // (Brent) This is cool.  This adds a value to an array?  Never see this.
  };

  const handleAgencyCodeOnChangeSelect = (e) => {
    const { name, value } = e.target;
    handleSetFilter([name], value);
  };

  return AGENT_USER_AND_LESS_THAN_LIMIT ? (
    <Select
      multiple={Array.isArray(agencyCode)}
      label={AGENCY_CODE_LABEL}
      onChange={handleAgencyCodeOnChangeSelect}
      menuItems={agencyCodeOptions ?? []}
      name={FILTER_TYPES.AGENCY_CODES}
      value={agencyCode}
      disabled={agencyCodeOptions === null}
    />
  ) : (
    <Autocomplete
      freeSolo={false}
      disableClearable
      disabled={agencyCodeOptions === null}
      options={agencyCodeOptions ?? []}
      getOptionLabel={(option) => option.label}
      onChange={handleAgencyCodeOnChangeAutocomplete}
      filterOptions={createFilterOptions({
        matchFrom: 'any',
        limit: 100,
      })}
      renderInput={(params) => <TextField {...params} label={AGENCY_CODE_LABEL} placeholder={`Enter ${AGENCY_CODE_LABEL}`} variant="outlined" />}
    />
  );
};

ClientAgencyCodeFilter.propTypes = {
  filters: PropTypes.object,
  handleSetFilter: PropTypes.func,
};

export default ClientAgencyCodeFilter;
