import { Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import AppDataContext from '../../contexts/appData.context';
import Header from '../Header/Header';
import RedesignNavigation from '../Navigation/RedesignNavigation';
import { ServiceUnavailable } from '../ServiceUnavailable';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  content: {
    padding: theme.spacing(0),
  },
}));

const UIShell = ({ children }) => {
  const { serviceUnavailable } = useContext(AppDataContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      {serviceUnavailable ? (
        <ServiceUnavailable />
      ) : (
        <>
          <RedesignNavigation />
          <div className={classes.container}>
            <main className={classes.content}>
              <Toolbar />
              {children}
            </main>
          </div>
        </>
      )}
    </div>
  );
};

UIShell.propTypes = {
  children: PropTypes.node,
};

export default UIShell;
