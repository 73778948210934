import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import { Box, Typography, Card, CardContent, CardActions } from '@material-ui/core';

const AOCard = ({
  cardTitle,
  cardTitleRoute,
  bodyComponent,
  footComponent,
  children,
  ...props
}) => {
  return (
    <Card {...props}>
      <CardContent>
        {cardTitle && (
          <Box mb={`${rem(24)}`}>
            {cardTitleRoute ? (
              <Link to={cardTitleRoute} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="h6">{cardTitle}</Typography>
              </Link>
            ) : (
              <Typography variant="h6">{cardTitle}</Typography>
            )}
          </Box>
        )}
        {bodyComponent && bodyComponent}
        {children}
      </CardContent>
      {footComponent && <CardActions>{footComponent}</CardActions>}
    </Card>
  );
};

AOCard.propTypes = {
  cardTitleRoute: PropTypes.string,
  cardTitle: PropTypes.string,
  bodyComponent: PropTypes.object,
  footComponent: PropTypes.object,
  children: PropTypes.node,
};

export default AOCard;
