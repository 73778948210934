import React from 'react';
import _ from 'lodash';

import { ViewContainer } from '../Layout';
import { ScreenSmith } from '../ScreenSmith';
import UserAdminSkeleton from './UserAdminSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';

const LocalComponents = {};

const UserAdmin = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`UserAdm`);

  const localStateMap = {};

  const callBackFunctions = {};

  return loadingSd ? (
    <UserAdminSkeleton />
  ) : (
    <ViewContainer title={'Administration Home Page'}>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={LocalComponents}
        />
      )}
    </ViewContainer>
  );
};

export default UserAdmin;
