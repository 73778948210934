import React, { useState } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Alert from './Alert';
import { IconButton } from '../IconButton';

const AlertExpandable = ({ initialOpen, message, ...props }) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  return (
    <Alert style={{ position: 'relative' }} message={isOpen ? message : undefined} {...props}>
      {message && (
        <IconButton
          onClick={() => setIsOpen(!isOpen)}
          style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )}
    </Alert>
  );
};

AlertExpandable.defaultProps = {
  initialOpen: false,
};

AlertExpandable.propTypes = {
  initialOpen: PropTypes.bool,
  message: PropTypes.string,
};

export default AlertExpandable;
