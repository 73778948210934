import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core'
import { useHistory } from 'react-router-dom';

import { StyledSvgLogo } from './SvgLogo.styled';

import { getSvgLogoColor } from './SvgLogo.api';

/**
 * Renders MSA log with ability to
 * render a white and black variant
 *
 */
const SvgLogo = ({ id, variant, height, onClick, route }) => {
  const history = useHistory();
  const variantLowerCase = variant.toLowerCase();
  const svgColor = getSvgLogoColor(variantLowerCase);
  const { msaTxtColor, insTxtColor, handColor } = svgColor;
  const handleClick = (e, _route) => {
    history.push(`${_route}`);

    if (onClick) {
      onClick(e);
    }
  };
  return (
    <ButtonBase onClick={(e) => handleClick(e, route)}>
      <StyledSvgLogo
        component="svg"
        xmlns="http://www.w3.org/2000/svg"
        id={id}
        x="0"
        y="0"
        version="1.1"
        viewBox="0 0 120 45"
        height={`${height}px`}>
        <path
          id="Path_35"
          d="M56 .3h2.9l2.3 6 2.4-6h2.9l1.7 11.3h-2.9l-.8-6.5-2.7 6.5h-1.2L58 5.1l-.9 6.5h-2.9L56 .3z"
          fill={msaTxtColor}
        />
        <path
          id="Path_36"
          d="M73.6 3.8l-1.3 3.6h2.4l-1.1-3.6zm-2 5.8l-.8 2h-3.1L72 .3h3.2l4.2 11.3h-3.1l-.7-2h-4z"
          fill={msaTxtColor}
        />
        <path id="Rectangle_168" d="M79.1.3H82v11.3h-2.9z" fill={msaTxtColor} />
        <path
          id="Path_37"
          d="M82.7.3h2.9L91 7.2V.3h3v11.3h-3l-5.4-6.9v6.9h-2.9V.3z"
          fill={msaTxtColor}
        />
        <path
          id="Path_38"
          d="M61 16.2c-.5-.5-1.2-.7-1.9-.7-.5 0-1.2.3-1.2.9 0 .7.8.9 1.3 1.1l.7.2c1.5.5 2.7 1.2 2.7 3 0 1.1-.3 2.2-1.1 2.9-.9.7-2 1.1-3.1 1-1.4 0-2.7-.5-3.9-1.3l1.2-2.3c.7.7 1.6 1.1 2.5 1.1.7 0 1.4-.3 1.4-1.1s-1.1-1.1-1.7-1.3c-1.8-.5-3-1-3-3.1 0-2.2 1.6-3.7 3.8-3.7 1.2 0 2.4.3 3.4.9L61 16.2z"
          fill={msaTxtColor}
        />
        <path id="Path_39" d="M67.9 24.5H65v-8.8h-2.4v-2.5h7.8v2.5H68v8.8z" fill={msaTxtColor} />
        <path
          id="Path_40"
          d="M74 18.3h.3c.9 0 2-.2 2-1.4s-1.1-1.4-2-1.4H74v2.8zm6.3 6.1h-3.5L74 20.1v4.2h-2.8v-11h4.3c2.2 0 3.8 1 3.8 3.4 0 1.5-.8 2.8-2.4 3.1l3.4 4.6z"
          fill={msaTxtColor}
        />
        <path
          id="Path_41"
          d="M83.7 15.7v1.9H87v2.5h-3.3v2h3.5v2.5h-6.4V13.3h6.4v2.5h-3.5z"
          fill={msaTxtColor}
        />
        <path
          id="Path_42"
          d="M91 15.7v1.9h3.3v2.5H91v2h3.5v2.5H88V13.3h6.4v2.5H91z"
          fill={msaTxtColor}
        />
        <path id="Path_43" d="M100.1 24.5h-2.9v-8.8h-2.4v-2.5h7.8v2.5h-2.4v8.8z" fill={msaTxtColor} />
        <path
          id="Path_44"
          d="M59.8 29.7l-1.3 3.6h2.4l-1.1-3.6zm-2.1 5.8l-.8 2h-3.1l4.3-11.3h3.2l4.2 11.3h-3.1l-.7-2h-4z"
          fill={msaTxtColor}
        />
        <path
          id="Path_45"
          d="M66.9 26.2h2.9l2.3 6 2.4-6h2.9l1.7 11.3h-2.9l-.8-6.5-2.7 6.5h-1.2L68.9 31l-.9 6.5h-3l1.9-11.3z"
          fill={msaTxtColor}
        />
        <path
          id="Path_46"
          d="M82.3 28.7v1.9h3.3v2.5h-3.3v2h3.5v2.5h-6.4V26.2h6.4v2.5h-3.5z"
          fill={msaTxtColor}
        />
        <path
          id="Path_47"
          d="M89.3 31.4h.3c.9 0 2-.2 2-1.4s-1.1-1.4-2-1.4h-.3v2.8zm6.3 6h-3.5l-2.7-4.2v4.2h-2.8V26.5h4.3c2.2 0 3.8 1 3.8 3.4 0 1.5-.8 2.8-2.4 3.1l3.3 4.4z"
          fill={msaTxtColor}
        />
        <path id="Rectangle_172" d="M95.9 26.2h2.9v11.3h-2.9z" fill={msaTxtColor} />
        <path
          id="Path_48"
          d="M107.8 30.1c-.6-.8-1.5-1.2-2.5-1.2-1.6 0-2.9 1.3-2.9 2.8v.2c-.1 1.6 1.1 2.9 2.7 3h.3c.9 0 1.9-.4 2.5-1.2v3.4c-.8.3-1.7.5-2.5.5-1.5 0-3-.6-4.1-1.6a5.54 5.54 0 01-1.8-4.1c0-1.5.6-2.9 1.6-4 1.1-1.1 2.6-1.8 4.2-1.8.9 0 1.8.2 2.6.6l-.1 3.4z"
          fill={msaTxtColor}
        />
        <path
          id="Path_49"
          d="M113.5 29.7l-1.3 3.6h2.4l-1.1-3.6zm-2 5.8l-.8 2h-3.1l4.3-11.3h3.2l4.2 11.3h-3.1l-.7-2h-4z"
          fill={msaTxtColor}
        />
        <path id="Path_57" d="M84.8 44.7h-.5v-4.9h.6l-.1 4.9z" fill={insTxtColor} />
        <path id="Path_58" d="M85.9 39.6l3.8 4v-3.8h.6V45l-3.8-4v3.8h-.6v-5.2z" fill={insTxtColor} />
        <path
          id="Path_59"
          d="M93.5 40.8c-.1-.3-.5-.6-.8-.5-.4 0-.8.3-.8.7 0 .4.4.6.7.7l.3.1c.7.3 1.2.6 1.2 1.4 0 .8-.7 1.5-1.6 1.5-.7 0-1.4-.6-1.5-1.3l.5-.1c0 .5.5.9 1 .9.6 0 1-.4 1-.9 0-.6-.5-.7-.9-1l-.3-.1c-.6-.3-1.1-.6-1.1-1.3 0-.7.6-1.3 1.3-1.3.5 0 1 .3 1.3.7l-.3.5z"
          fill={insTxtColor}
        />
        <path
          id="Path_60"
          d="M95.4 42.7c0 .4 0 .7.2 1.1.2.3.6.5 1 .5s.7-.1 1-.4c.2-.3.3-.7.2-1.1v-2.9h.6V43c0 .5-.1 1-.4 1.4-.3.4-.8.6-1.3.6s-.9-.2-1.3-.5c-.4-.4-.6-.9-.5-1.4V40h.6l-.1 2.7z"
          fill={insTxtColor}
        />
        <path
          id="Path_61"
          d="M99.9 44.7h-.6v-4.9h.7c.9 0 1.8.2 1.8 1.4 0 .7-.5 1.3-1.2 1.4l1.5 2.2h-.7l-1.5-2.1h-.1l.1 2zm0-2.6h.2c.6 0 1.3-.1 1.3-.9s-.6-.9-1.3-.9h-.1l-.1 1.8z"
          fill={insTxtColor}
        />
        <path
          id="Path_62"
          d="M103.6 43.3l-.6 1.4h-.6l2.2-5.1 2.2 5.1h-.6l-.6-1.4h-2zm1-2.4l-.8 2h1.7l-.9-2z"
          fill={insTxtColor}
        />
        <path id="Path_63" d="M107.4 39.6l3.8 4v-3.8h.6V45l-3.8-4v3.8h-.6v-5.2z" fill={insTxtColor} />
        <path
          id="Path_64"
          d="M116.6 40.9c-.4-.4-.9-.7-1.5-.7-1.1 0-2 .9-2 2s.9 2 2 2c.6 0 1.1-.2 1.5-.7v.7c-.4.3-.9.5-1.4.5-1.4 0-2.6-1.1-2.6-2.5s1.1-2.6 2.5-2.6c.5 0 1 .1 1.4.5l.1.8z"
          fill={insTxtColor}
        />
        <path
          id="Path_65"
          d="M117.5 39.8h2.5v.5h-2v1.4h2v.5h-2v1.9h2v.5h-2.5v-4.8z"
          fill={insTxtColor}
        />
        <path
          d="M37.2 6.1C35.7 5 25.6 2.7 23.3 4.9c-3.2 3-7 5.3-11.1 6.6.2.9.8 1.8 1.7 2.2 1.6.9 4.9 1.2 12-2.6l.7-.4c.1-.1.3-.1.5 0 .1 0 .2 0 .3.1l1.7 1.4c2.1 1.8 5.6 4.8 7.7 3.9l.2-.1c.2-.1.5 0 .7.1v.1s.1 0 .2.1l1.6 2.5c1 1.5 2.8 4.4 3.4 4.8 1.9-.5 2.3-3.5 2.3-3.5 0-.1.1-.3.2-.4l3.2-2.2.7-.4.7-.5V.3c-.3.1-11.3 7.1-12.8 5.8z"
          fill={handColor}
        />
        <path
          d="M37.8 19.6c-3.3-2.3-10.2-6.3-10.9-6.5-.6-.1-1.1-.3-1.6-.5-5.5 2.8-9.5 3.5-12.1 2-1.3-.7-2.2-2.1-2.3-3.6 0-.3.2-.5.4-.6 4.1-1.2 8-3.4 11.1-6.4 1.8-1.7 6.4-1.4 10.1-.6.2-.4.3-.9.3-1.4v-.5c-1.9.2-8.1-.1-9.8-.8-.7-.3-1.4.2-1.9.5l-.3.2c-1.2 1-2.5 1.8-3.9 2.5-1.5.7-3.3.8-4.8.8-.8 0-1.6.2-2 .2-2.5 0-7.2-3-9.1-4.2C.7.5 0 0 0 0v18h1.4s.6 3.7 6.7 8.9c.9-1.3 2-2.2 3.3-1.6 1.2.6 1.9 1.6 2 2.9.2-.3.6-.5.9-.6.5-.2 1.1-.2 1.6 0 1.3.5 2.2 1.7 2.5 3 .6-.4 1.4-.5 2-.2 1.6.5 3 3.7 3.4 4.8l2.1.6h.4l.2.2c.4.4 1.6 1.4 2.6 1.3.7-.1 1.4-.5 1.8-1-1.6-1.1-4.8-3.9-6.4-5.4l-.1.1c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8l1-1.1c.2-.2.6-.2.8 0 .2.2.2.6 0 .8v.1c2.8 2.6 6.1 5.3 6.6 5.5.2 0 1.9.3 3-.4.5-.3.7-.8.8-1.4-2.1-1.4-6.6-5.6-8.4-7.3l-.1.1c-.2.2-.6.3-.8 0-.2-.2-.3-.6 0-.8l.9-1.1c.2-.2.6-.3.8-.1.2.2.3.6.1.8v.1c3.7 3.5 7.8 7.1 8.7 7.5.1 0 .2 0 .3.1.7.2 1.5.4 2-.1 1.2-1.1.7-1.9.6-2.1l-3.7-4.4c-.4-.5-3.5-2.8-5.7-4.5l-.1.1c-.2.2-.6.3-.8 0-.2-.2-.3-.6 0-.8l.9-1.1c.2-.2.6-.3.8 0 .2.2.3.6 0 .8 1.5 1.1 5.3 4 5.8 4.7l3.5 4.2c.4.2 2 .7 2.6-.4.1-.2.1-.4.1-.6.1-1.8-2.6-7.1-5.5-9.2z"
          fill={handColor}
        />
        <path
          d="M22.8 35.9c-.7-1.7-1.9-4.1-2.8-4.4-.4-.1-.7-.1-1.1.1-.4.3-.6.8-.6 1.3v.1c-.1.7-.2 1.4-.2 2 .2.7.7 1.4 1.3 1.8 1.8 1.2 3.6.4 4.6-.4l-1-.3-.2-.2zM15.3 28.6c-.2-.1-.5-.1-.7 0-.6.2-1.2 1.2-1.4 1.7l-.3 1.5c0 .1-.1.3-.1.4v.4c0 .8.4 1.5 1.1 2 .9.7 2 .7 3 0 0-.9.2-1.8.4-2.7v-.3c0-1.2-.8-2.4-2-3zM4 24.6c-.9 1.1-.8 1.9-.1 2.8.2.3.6.6 1 .6.7.2 1.4 0 2.1-.4-1.1-.9-2.1-1.9-3-3zM10.7 26.3c-.2-.1-.8-.4-2.2 1.8-.3.4-1.1 1.8-.9 2.8.1.5.4.8.8 1.1 1.1.7 2.5.5 3.4-.5 0-.5.2-.9.3-1.4.1-.4.1-.8.1-1.2.1-1.1-.5-2.1-1.5-2.6z"
          fill={handColor}
        />
      </StyledSvgLogo>
    </ButtonBase>
  );
};

SvgLogo.defaultProps = {
  id: 'MSA Logo',
  variant: 'default',
  height: 45,
  route: "/"
};

SvgLogo.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'white', 'black']),
  height: PropTypes.number,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

export default SvgLogo;
