import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ScreenSmith } from '../../../ScreenSmith';
import BillingServicesPaymentManagementSkeleton from '../BillingServicesPaymentManagementSkeleton';
import PaymentValidation from './PaymentValidation';
import PaymentConfirmation from './PaymentConfirmation';

import useScreenDefinition from '../../../../hooks/useScreenDefinition';

import {
  reportInsufficientFundsCols,
  handleOnClickReset,
  fetchCurrentPayments,
  handleOnClickEnterPaymentValidation,
  onSelectionModelChange,
  handleEditOnClickFromValidation,
  handleOnClickSubmitPayment,
} from './ReportInsufficientFunds.api';

import { GET_BILLING_PAYMENT_NSF_PAYMENTS } from '../../../../constants/api';

const localComponents = {
  PaymentValidation,
  PaymentConfirmation,
};

const screenMappings = {
  start: `BillingServicesPaymentManagementReportInsufficientFundsPanel`,
  paymentValidation: `BillingServicesPaymentManagementReportInsufficientFundsPanelPaymentValidation`,
  paymentConfirmation: `BillingServicesPaymentManagementReportInsufficientFundsPanelPaymentConfirmation`,
};

const BillingServicesPaymentManagementReportInsufficientFunds = ({
  resetFlow,
  handleClickGoToAdjustPayment,
  handleClickGoToPostPayment,
  handleClickGoToNSFPayment,
}) => {
  const [currentScreen, setCurrentScreen] = useState(screenMappings.start);
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(currentScreen, [currentScreen]);

  const [selectionModel, setSelectionModel] = useState([]);
  const [paymentListData, setPaymentListData] = useState({
    loading: true,
    paymentList: null,
    selectedRow: {},
    alert: {
      message: null,
      severity: 'error',
      isVisible: false,
    },
  });

  useEffect(() => {
    if (currentScreen === screenMappings.start) {
      fetchCurrentPayments(setPaymentListData, GET_BILLING_PAYMENT_NSF_PAYMENTS);
    }
  }, [currentScreen]);

  const localStateMap = {
    reportInsufficientFundsCols,
    selectButtonDisabled: selectionModel.length !== 1,
    paymentListLoading: paymentListData.loading,
    paymentList: paymentListData.paymentList,
    paymentListAlertMessage: paymentListData.alert.message,
    paymentListAlertSeverity: paymentListData.alert.severity,
    paymentListAlertIsVisible: paymentListData.alert.isVisible,
    selectionModel,
    paymentValidationData: {
      changeDate: paymentListData.selectedRow.payDateCreated,
      originalAmount: paymentListData.selectedRow.originalAmount,
      accountNumber: paymentListData.selectedRow.accountNumber,
      accountName: paymentListData.selectedRow.accountName,
      payIndex: paymentListData.selectedRow.payIndex,
    },
  };

  const callBackFunctions = {
    handleClickBackToPaymentManagement: () => resetFlow(),
    onSelectionModelChange: (selection) =>
      onSelectionModelChange(
        paymentListData.paymentList,
        selection,
        selectionModel,
        setSelectionModel,
        setPaymentListData,
      ),
    handleOnClickReset: () => handleOnClickReset(setSelectionModel),
    handleOnClickEnterPaymentValidation: () =>
      handleOnClickEnterPaymentValidation(screenMappings, setCurrentScreen),
    handleEditOnClickFromValidation: () =>
      handleEditOnClickFromValidation(screenMappings, setCurrentScreen, setSelectionModel),
    handleOnClickSubmitPayment: () =>
      handleOnClickSubmitPayment(
        paymentListData.selectedRow,
        setPaymentListData,
        screenMappings,
        setCurrentScreen,
      ),
    handleClickGoToAdjustPayment,
    handleClickGoToPostPayment,
    handleClickGoToNSFPayment,
  };

  if (loadingSd) return <BillingServicesPaymentManagementSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          stateMap={localStateMap}
          functionMap={callBackFunctions}
          componentMap={localComponents}
        />
      )}
    </>
  );
};

BillingServicesPaymentManagementReportInsufficientFunds.propTypes = {
  handleClickGoToAdjustPayment: PropTypes.func,
  handleClickGoToPostPayment: PropTypes.func,
  handleClickGoToNSFPayment: PropTypes.func,
  resetFlow: PropTypes.func,
};

export default BillingServicesPaymentManagementReportInsufficientFunds;
