import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cuid from 'cuid';
import _ from 'lodash';
import { GET_BILLING_EXACT_SEARCH, GET_BILLING_SEARCH_PAGE_COUNT } from '../BillingServices.api';
import { GET_PAYMENTS_DUE_URL } from '../../../constants/api';
import { paymentsDueColumnData } from './PaymentsDue';

import { ScreenSmith } from '../../ScreenSmith';
import BillingServicesReportsSkeleton from './BillingServicesReportsSkeleton';

import useScreenDefinition from '../../../hooks/useScreenDefinition';

const BillingServicesReports = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`BillingServicesReportsPanel`);
  const [paymentsDueRows, setPaymentsDueRows] = useState();
  const [currentReport, setCurrentReport] = useState('');
  const [paginationData, setPaginationData] = useState({
    totalCount: null,
    currentPage: 1,
  });

  const handleReportsOptionsClick = async (event) => {
    setCurrentReport(event.currentTarget.value);
  };

  useEffect(async () => {
    try {
      const currentPageData = await axios.get(
        `${GET_BILLING_EXACT_SEARCH}${GET_PAYMENTS_DUE_URL}${GET_PAYMENTS_DUE_URL}/${paginationData.currentPage}`,
      );
      const { data: currentPage } = currentPageData;
      setPaymentsDueRows(currentPage);
      if (!paginationData.totalCount) {
        const rowCountData = await axios.get(
          `${GET_BILLING_SEARCH_PAGE_COUNT}/exact${GET_PAYMENTS_DUE_URL}${GET_PAYMENTS_DUE_URL}`,
        );
        const { data: rowCount } = rowCountData;
        setPaginationData((prevVal) => ({ ...prevVal, totalCount: rowCount }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [currentReport, paginationData.currentPage]);

  const handleClickPaymentsDuePaginationChange = (page) => {
    setPaginationData((prevVal) => ({ ...prevVal, currentPage: page + 1 }));
  };

  const [paymentsDueColumns] = useState(paymentsDueColumnData);

  const showPaymentsDue = currentReport === 'payments-due';
  const showPaymentsMade = currentReport === 'payments-made';

  const localStateMap = {
    showPaymentsDue,
    paymentsDueButtonVariant: showPaymentsDue ? 'contained' : 'outlined',
    showPaymentsMade,
    paymentsMadeButtonVariant: showPaymentsMade ? 'contained' : 'outlined',
    paymentsDueColumns,
    paymentsDueRows: paymentsDueRows?.map((el) => ({
      id: cuid(),
      ...el,
    })),
    paginationRowCount: paginationData.totalCount,
  };

  const callBackFunctions = {
    handleClickPaymentsDuePaginationChange,
    handleReportsOptionsClick,
  };

  if (loadingSd) return <BillingServicesReportsSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default BillingServicesReports;
