import axios from 'axios';
import axiosRetry from 'axios-retry';
import { GET_PLR_URL, GET_REPORTS_URL } from '../../constants/api';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';

axiosRetry(axios, {
  retries: 1,
  retryCondition: (error) => {
    return error.code === 'ERR_NETWORK';
  },
});
const handleSearchByTypeOnChange = (e, setSearchByType, setErrorMessage) => {
  setSearchByType(e.target.value);
  setErrorMessage('');
};

const handleAOAutocompleteOnChange = (property, e, value, formData, setFormData) => {
  setFormData({ ...formData, [property]: value });
};

const handleAOAutocompleteOnInputChange = (
  property,
  e,
  value,
  autocompleteInputValues,
  setAutocompleteInputValues,
  debouncedState,
  setDebouncedState,
  setErrorMessage,
) => {
  setAutocompleteInputValues({
    ...autocompleteInputValues,
    [property]: {
      ...autocompleteInputValues[property],
      value,
    },
  });
  setDebouncedState({ ...debouncedState, [property]: { ...debouncedState[property], value } });
  setErrorMessage('');
};

const handleAOAutocompleteOnBlur = (property, formData, setFormData, autocompleteInputValues) => {
  if (formData[property] !== autocompleteInputValues[property].value) {
    setFormData({ ...formData, [property]: autocompleteInputValues[property].value });
  }
};

const fetchSuggestions = async (endpoint, searchQuery) => {
  const plrReportsUrl = `${GET_PLR_URL}/auto-completion/${endpoint}/${searchQuery}`;
  const plrReportsData = await axios.get(`${plrReportsUrl}`);
  const plrReportsSuggestion = plrReportsData.data;
  return plrReportsSuggestion;
};

const getGenerateReportRequestBody = (searchByType, searchBy) => {
  let request_body = {
    Area: 'policy-loss-run',
    DetailedContext: [
      {
        name: searchByType,
        value: searchBy,
      },
    ],
    TypeOfRedirect: 'Generate-PDF',
  };
  return request_body;
};

const handleGenerateReportOnClick = (
  searchByType,
  searchBy,
  setLoadingPdf,
  setIsDownloadingPdfErrorVisible,
  setDownloadingPdfError,
) => {
  let reportRequestBody = getGenerateReportRequestBody(searchByType, searchBy);
  const reportsGenerateUrl = GET_REPORTS_URL;
  handlePdfDownload(
    'POST',
    reportRequestBody,
    reportsGenerateUrl,
    setLoadingPdf,
    setDownloadingPdfError,
    setIsDownloadingPdfErrorVisible,
    'newWindow',
  );
};

export {
  handleSearchByTypeOnChange,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
  fetchSuggestions,
  handleGenerateReportOnClick,
};
