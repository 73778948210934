import { Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import { useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Avatar, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from 'components/AORedesign';
import { redirectUrlFunction } from 'components/CommonUtilityServices/RedirectService';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import { ROUTE_ACCOUNT_ADMIN, ROUTE_REDESIGN_APP_ADMIN, ROUTE_REDESIGN_HEALTH_CHECKS, ROUTE_REDESIGN_USER_ADMIN } from 'constants/routes';
import AppDataContext from 'contexts/appData.context';
import { handleClickLogout } from './Header.api';

const useStyles = makeStyles((theme) => ({
  menu: { zIndex: '1400 !important' },
  avatarContainer: {
    cursor: 'pointer',
  },
  listItemIcon: { minWidth: 'unset', marginLeft: '2rem', color: theme.palette.text.secondary },
}));

const AgentDisplay = ({ agentDisplayName, agentFirstName, agentLastName, agentEmail }) => {
  const agentsOnlySessionUUID = localStorage.getItem('afe-session-id');

  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { setLoadingMessage } = useContext(AppDataContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const routeToPath = (path) => {
    handleClose();
    history.push(path);
  };

  const handleChangePasswordOnClick = () => {
    handleClose();
    redirectUrlFunction(
      {
        Area: 'Session Management',
        DetailedContext: [],
        TypeOfRedirect: 'Reset Password',
      },
      'POST',
      null,
      () => {},
      () => {},
    );
  };

  const handleLogout = () => {
    handleClose();
    handleClickLogout(oktaAuth, setLoadingMessage);
  };

  return (
    <>
      <Box className={classes.avatarContainer}>
        <Avatar id="agent-display" onClick={handleClick}>{`${agentFirstName.substring(0, 1)}${agentLastName.substring(0, 1)}`}</Avatar>
      </Box>
      <Menu className={classes.menu} id="agent-avatar" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <Box px={2} mb={1}>
          <Typography variant="h6" gutterBottom>
            {agentDisplayName}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {agentEmail}
          </Typography>
        </Box>
        <Divider />

        <Feature allowed={featureFlags.ACCESS_USER_IS_AGENT}>
          <MenuItem id="ao-account-admin-link" onClick={() => routeToPath(ROUTE_ACCOUNT_ADMIN)}>
            <ListItemText primary="Agency Info" />
            <ListItemIcon className={classes.listItemIcon}>
              <AccountBoxOutlinedIcon />
            </ListItemIcon>
          </MenuItem>
        </Feature>
        <Feature allowed={featureFlags.ACCESS_USER_ADMIN}>
          <MenuItem id="ao-user-admin-link" onClick={() => routeToPath(ROUTE_REDESIGN_USER_ADMIN)}>
            <ListItemText primary="User Administration" />
            <ListItemIcon className={classes.listItemIcon}>
              <AccountBoxOutlinedIcon />
            </ListItemIcon>
          </MenuItem>
        </Feature>
        <Feature allowed={featureFlags.ACCESS_APP_ADMIN}>
          <MenuItem id="ao-app-admin-link" onClick={() => routeToPath(ROUTE_REDESIGN_APP_ADMIN)}>
            <ListItemText primary="Application Administration" />
            <ListItemIcon className={classes.listItemIcon}>
              <SettingsApplicationsOutlinedIcon />
            </ListItemIcon>
          </MenuItem>
        </Feature>
        <MenuItem onClick={handleChangePasswordOnClick}>
          <ListItemText primary="Change Password" />
          <ListItemIcon className={classes.listItemIcon}>
            <VpnKeyOutlinedIcon />
          </ListItemIcon>
        </MenuItem>
        <Feature allowed={featureFlags.ACCESS_HEALTH_CHECKS}>
          <MenuItem id="ao-health_check-link" onClick={() => routeToPath(ROUTE_REDESIGN_HEALTH_CHECKS)}>
            <ListItemText primary="Health Checks" />
            <ListItemIcon className={classes.listItemIcon}>
              <AccountBoxOutlinedIcon />
            </ListItemIcon>
          </MenuItem>
        </Feature>
        <MenuItem onClick={handleLogout}>
          <ListItemText primary="Sign Out" />
          <ListItemIcon className={classes.listItemIcon}>
            <ExitToAppOutlinedIcon />
          </ListItemIcon>
        </MenuItem>

        <Divider />
        <Box px={2} mt={1}>
          <Typography color="textSecondary" variant="body2">
            Session ID: {agentsOnlySessionUUID}
          </Typography>
        </Box>
      </Menu>
    </>
  );
};

AgentDisplay.propTypes = {
  agentDisplayName: PropTypes.string, // used by existing implementation
  agentFirstName: PropTypes.string,
  agentLastName: PropTypes.string,
  agentEmail: PropTypes.string,
};

export default AgentDisplay;
