import React from 'react';
import PropTypes from 'prop-types';
import MuiSnackbar from '@material-ui/core/Snackbar';

import { componentIdStringGenerator } from '../../../helpers';

const Snackbar = (props) => {
  return (
    <MuiSnackbar
      autoHideDuration={6000}
      {...props}
      id={componentIdStringGenerator('Snackbar', props.id ? props.id : props.children)}
    />
  );
};

Snackbar.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

export default Snackbar;
