import { rem } from 'polished';

import { Box, styled } from '@material-ui/core';

const StyledCommercialLinesServices = styled(Box)(({ theme }) => ({
  padding: `${rem(theme.spacing(0))}`,
}));

const StyledPLLinks = styled(Box)(() => ({
  padding: `0 ${rem(24)}`,
  height: `${rem(50)}`,
}));
export { StyledCommercialLinesServices, StyledPLLinks };
