import MuiBox from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import AddOutlined from '@material-ui/icons/AddOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import ExcelLogo from './CustomIcons/ExcelLogo';

import { ExpandLess } from '@material-ui/icons';
import { componentIdStringGenerator } from '../../../helpers';

const IconMap = {
  pdf: PictureAsPdfOutlinedIcon,
  file: InsertDriveFileOutlinedIcon,
  document: DescriptionOutlinedIcon,
  mail: MailOutlinedIcon,
  excel: ExcelLogo,
  openInNew: OpenInNewOutlinedIcon,
  addOutlined: AddOutlined,
  editOutlined: EditOutlined,
  showMore: ExpandMore,
  showLess: ExpandLess,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  startIconContainer: {
    display: 'inherit',
    marginRight: theme.spacing(0.5),
  },
  endIconContainer: {
    display: 'inherit',
    marginLeft: theme.spacing(0.5),
  },
}));

const Link = ({ endIcon, startIcon, ...props }) => {
  const classes = useStyles();

  return (
    <MuiLink
      className={classes.root}
      color="secondary"
      target={props.href && '_blank'}
      rel={props.href && 'noopener noreferrer'}
      {...props}
      id={componentIdStringGenerator('Link', props.id ? props.id : props.children)}>
      {startIcon && <MuiBox className={classes.startIconContainer}>{React.createElement(IconMap[startIcon], { fontSize: 'small' })}</MuiBox>}
      {props.children}
      {endIcon && <MuiBox className={classes.endIconContainer}>{React.createElement(IconMap[endIcon], { fontSize: 'small' })}</MuiBox>}
    </MuiLink>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  endIcon: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  startIcon: PropTypes.string,
};

export default Link;
