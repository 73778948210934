import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from 'components/AORedesign';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

const LinksContainer = ({ heading, children }) => {
  const classes = useStyles();
  return (
    <Box mt={4}>
      <Box>
        <Typography variant="button" display="block" gutterBottom>
          {heading}
        </Typography>
      </Box>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

LinksContainer.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node,
};

export default LinksContainer;
