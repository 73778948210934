import { Typography } from '@material-ui/core';
import React from 'react';

import { AOCard } from 'components/AOCard';
import { Link, ViewContainer } from 'components/AORedesign';
import {
  UserAdminAddUserLink,
  UserAdminEditUserLink,
  UserAdminGroupInfoLink,
  UserAdminViewAgencyInfoLink,
  UserAdminViewChangeHistoryLink,
} from 'components/AORedesignViews/UserAdmin';
import { Feature } from 'components/Feature';
import { Col, Row, RowCol } from 'components/ScreenSmith';

const UserAdminHome = () => {
  return (
    <Feature name="agencySecurityUserAdministration">
      <ViewContainer title="User Administration" hidePolicyViewChange hideStartQuote>
        <RowCol mb={2}>
          <AOCard>
            <Row spacing={4}>
              <Col xs={4}>
                <Typography color="textSecondary">
                  Welcome to the MSA Web Security Administration Home Page. The links to the right offer access to all of the resources you need to review or
                  edit user information.
                </Typography>
              </Col>
              <Col xs={4}>
                <RowCol mb={4}>
                  <UserAdminAddUserLink />
                  <Typography color="textSecondary">From this screen you may add users to your agency.</Typography>
                </RowCol>
                <RowCol mb={4}>
                  <UserAdminEditUserLink />
                  <Typography color="textSecondary">
                    From this page you may find the users in your agency and edit their agencies, groups, or individual information.
                  </Typography>
                </RowCol>
                {/* Hiding links under https://amfament.atlassian.net/browse/E1PMAO-13450 while training team works on getting new links. No timeframe */}
                <Feature allowed={[]}>
                  <RowCol>
                    <Link href="" endIcon="openInNew">
                      Website Security Guide
                    </Link>
                  </RowCol>
                </Feature>
              </Col>
              <Feature name="userAdministration">
                <Col xs={4}>
                  <RowCol mb={4}>
                    <UserAdminViewAgencyInfoLink />
                    <Typography color="textSecondary">From this screen you may view agency information.</Typography>
                  </RowCol>
                  <RowCol mb={4}>
                    <UserAdminGroupInfoLink />
                    <Typography color="textSecondary">From this page you may view information about groups.</Typography>
                  </RowCol>
                  <RowCol mb={4}>
                    <UserAdminViewChangeHistoryLink />
                    <Typography color="textSecondary">From this screen you may view the changes to a user&apos;s account.</Typography>
                  </RowCol>
                </Col>
              </Feature>
            </Row>
          </AOCard>
        </RowCol>
      </ViewContainer>
    </Feature>
  );
};

export default UserAdminHome;
