import React, { useState, useEffect } from 'react';
import _ from 'lodash';
// import cuid from 'cuid';
//app components
import { ScreenSmith } from '../ScreenSmith';
import BillingServicesSearchSkeleton from './BillingServicesSearchSkeleton';
// hooks
import useScreenDefinition from '../../hooks/useScreenDefinition';
// import useFetchSelectMenuItems from '../../hooks/useFetchSelectMenuItems';
import { useDebounce } from '../../hooks/useDebounce';
// api
import {
  handleChangeSearchCriteriaRadioValue,
  fetchPolicyNumberFormSuggestions,
  fetchAccountNumberFormSuggestions,
  fetchInsuredNameFormSuggestions,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
  handleOnClickSearch,
  policyNumberCols,
  handleChangeInsuredLastNameRadioValue,
  handleClickPaginationChange,
} from './BillingServices.api';

const BillingServicesSearch = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`BillingServicesSearchPanel`); // Search Radio Buttons
  const [searchCriteriaRadioValue, setSearchCriteriaRadioValue] = useState('policyNumber');
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    policyNumber: { value: '', invalidText: '' },
    accountNumber: { value: '', invalidText: '' },
    insuredName: { value: '', invalidText: '' },
  });
  const [formData, setFormData] = useState({
    policyNumber: '',
    accountNumber: '',
    insuredName: '',
  });
  const [insuredLastNameRadioValue, setInsuredLastNameRadioValue] = useState('exact');
  const [policyNumberOptions, setPolicyNumberOptions] = useState([]);
  const [accountNumberOptions, setAccountNumberOptions] = useState([]);
  const [insuredNameOptions, setInsuredNameOptions] = useState([]);
  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);
  const [searchResults, setSearchResults] = useState(null);
  const [searchResultsVisible, setSearchResultsVisible] = useState(false);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [searchResultsAlertData, setSearchResultsAlertData] = useState({
    message: '',
    severity: 'error',
    isVisible: false,
  });
  const [paginationData, setPaginationData] = useState({
    totalCount: null,
    currentPage: 0,
  });
  // Policy Number debounce
  useEffect(async () => {
    if (autocompleteInputValues.policyNumber.value.length >= 3) {
      const suggestions = await fetchPolicyNumberFormSuggestions({
        searchValue: autocompleteInputValues.policyNumber.value,
      }).catch((err) => {
        console.error(err);
      });
      setPolicyNumberOptions(suggestions);
    } else {
      setPolicyNumberOptions([]);
    }
  }, [debouncedState.policyNumber]);
  // Account Number debounce
  useEffect(async () => {
    if (autocompleteInputValues.accountNumber.value.length >= 3) {
      const suggestions = await fetchAccountNumberFormSuggestions({
        searchValue: autocompleteInputValues.accountNumber.value,
      }).catch((err) => {
        console.error(err);
      });
      setAccountNumberOptions(suggestions);
    } else {
      setAccountNumberOptions([]);
    }
  }, [debouncedState.accountNumber]);
  // Insured Name debounce
  useEffect(async () => {
    if (autocompleteInputValues.insuredName.value.length >= 3) {
      const suggestions = await fetchInsuredNameFormSuggestions({
        searchValue: autocompleteInputValues.insuredName.value,
      }).catch((err) => {
        console.error(err);
      });
      setInsuredNameOptions(suggestions);
    } else {
      setInsuredNameOptions([]);
    }
  }, [debouncedState.insuredName]);
  const localStateMap = {
    searchCriteriaRadioValue,
    policyNumber: formData.policyNumber,
    policyNumberOptions,
    policyNumberInputValue: autocompleteInputValues.policyNumber.value,
    policyNumberInvalidText: autocompleteInputValues.policyNumber.invalidText,
    accountNumber: formData.accountNumber,
    accountNumberOptions,
    accountNumberInputValue: autocompleteInputValues.accountNumber.value,
    accountNumberInvalidText: autocompleteInputValues.accountNumber.invalidText,
    insuredName: formData.insuredName,
    insuredNameOptions,
    insuredNameInputValue: autocompleteInputValues.insuredName.value,
    insuredNameInvalidText: autocompleteInputValues.insuredName.invalidText,
    policyNumberCols,
    searchResults,
    searchResultsVisible,
    searchResultsAlertMessageVisible: searchResultsAlertData.isVisible,
    searchResultsAlertMessage: searchResultsAlertData.message,
    searchResultsAlertMessageSeverity: searchResultsAlertData.severity,
    loadingSearchResults,
    insuredLastNameRadioValue,
    paginationRowCount: paginationData.totalCount,
  };
  const callBackFunctions = {
    handleChangeInsuredLastNameRadioValue: (e) =>
      handleChangeInsuredLastNameRadioValue(e, setInsuredLastNameRadioValue),
    handleChangeSearchCriteriaRadioValue: (e) =>
      handleChangeSearchCriteriaRadioValue(
        e,
        setSearchCriteriaRadioValue,
        setSearchResultsVisible,
        setSearchResults,
      ),
    handlePolicyNumberOnChange: (e, value) =>
      handleAOAutocompleteOnChange('policyNumber', e, value, formData, setFormData),
    handleAccountNumberOnChange: (e, value) =>
      handleAOAutocompleteOnChange('accountNumber', e, value, formData, setFormData),
    handleInsuredNameOnChange: (e, value) =>
      handleAOAutocompleteOnChange('insuredName', e, value, formData, setFormData),
    handlePolicyNumberOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'policyNumber',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        debouncedState,
        setDebouncedState,
      ),
    handleAccountNumberOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'accountNumber',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        debouncedState,
        setDebouncedState,
      ),
    handleInsuredNameOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'insuredName',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        debouncedState,
        setDebouncedState,
      ),
    handlePolicyNumberOnBlur: () =>
      handleAOAutocompleteOnBlur('policyNumber', formData, setFormData, autocompleteInputValues),
    handleAccountNumberOnBlur: () =>
      handleAOAutocompleteOnBlur('accountNumber', formData, setFormData, autocompleteInputValues),
    handleInsuredNameOnBlur: () =>
      handleAOAutocompleteOnBlur('insuredName', formData, setFormData, autocompleteInputValues),
    handleOnClickSearch: () =>
      handleOnClickSearch(
        searchCriteriaRadioValue,
        formData[searchCriteriaRadioValue],
        setSearchResults,
        setSearchResultsVisible,
        insuredLastNameRadioValue,
        setLoadingSearchResults,
        setSearchResultsAlertData,
        paginationData,
        setPaginationData,
      ),
    handleClickPaginationChange: (page) =>
      handleClickPaginationChange(
        page, // pass internal state page variable from MUI Datgrid
        paginationData,
        setPaginationData,
        setLoadingSearchResults,
        setSearchResults,
        insuredLastNameRadioValue,
        formData[searchCriteriaRadioValue],
      ),
  };
  if (loadingSd) return <BillingServicesSearchSkeleton />;
  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};
export default BillingServicesSearch;
