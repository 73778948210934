import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';
import USAMap from '../USAMap';

const ProductDetailMap = ({ states, ...props }) => {
  const theme = useTheme();

  const statesCustomConfig = () => {
    const statesConfig = {};
    for (const state in states) {
      const stateNameParts = state.split('-');
      const stateName = stateNameParts[stateNameParts.length - 1];

      statesConfig[stateName] = { fill: theme.palette.primary.main };
    }
    return statesConfig;
  };

  return states ? <USAMap customize={statesCustomConfig()} {...props} /> : null;
};

ProductDetailMap.propTypes = {
  states: PropTypes.object,
};

export default ProductDetailMap;
