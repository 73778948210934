import React from 'react';

import { ViewContainer } from 'components/AORedesign';
import { ROUTE_REDESIGN_RESOURCES } from 'constants/routes';
import { BrandFolder } from 'components/BrandFolder';

const TrainingResources = () => {
  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_RESOURCES, text: 'Resources' }} hideStartQuote hidePolicyViewChange>
      <BrandFolder title="Training Materials" typeOfContainer="Training" />
    </ViewContainer>
  );
};

export default TrainingResources;
