import React, { useState } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import BillingServicesFormsSkeleton from './BillingServicesFormsSkeleton';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';

const BillingServicesForms = () => {
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);

  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`BillingServicesFormsPanel`);

  const localStateMap = { loadingPdf, downloadingPdfError, isDownloadingPdfErrorVisible };

  const callBackFunctions = {
    handleOnClickOpenPDFForm: (e) => {
      const pdfUrl = e.currentTarget.value;

      handlePdfDownload(
        'GET',
        null,
        pdfUrl,
        setLoadingPdf,
        setDownloadingPdfError,
        setIsDownloadingPdfErrorVisible,
        true,
      );
    },
  };

  if (loadingSd) return <BillingServicesFormsSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default BillingServicesForms;
