import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Select } from 'components/AORedesign';
import { FILTER_TYPES, SELECT_ALL_VALUE } from 'components/AORedesignViews/Clients';
import { GET_CLIENTS_LOBS } from 'constants/api';

const ClientLOBFilter = ({ filters, handleSetFilter }) => {
  const [lobMenuItems, setLobMenuItems] = useState([]);

  const lobValue = filters?.[FILTER_TYPES.LOB];

  // call api to get LOBs dropdown values
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(GET_CLIENTS_LOBS);
        setLobMenuItems([{ label: 'All Lines of Business', value: SELECT_ALL_VALUE }, ...data]);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleLobOnChange = (e) => {
    const { name, value } = e.target;
    handleSetFilter(name, value);
  };

  return (
    <Select
      label="Line of Business"
      onChange={handleLobOnChange}
      menuItems={lobMenuItems}
      name={FILTER_TYPES.LOB}
      value={lobValue}
      disabled={lobMenuItems === null}
    />
  );
};

ClientLOBFilter.propTypes = {
  filters: PropTypes.object,
  handleSetFilter: PropTypes.func,
};

export default ClientLOBFilter;
