import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import { AOCard } from '../AOCard';
import RowCol from '../ScreenSmith/RowCol';
import { ViewContainer } from '../Layout';
import Row from '../ScreenSmith/Row';
import RowItem from '../ScreenSmith/RowItem';

const Flood = () => {
  return (
    <ViewContainer title={'Flood Insurance'}>
      <RowCol mb={2}>
        <AOCard>
          <RowCol mb={2}>
            <Typography color={'textSecondary'}>
              According to the Federal Emergency Management Agency (FEMA), floods have caused more
              than $25 billion in insured losses in the U.S. in the last 10 years. Yet more than 50
              percent of properties in high-risk areas remain unprotected by flood insurance.
            </Typography>
          </RowCol>

          <RowCol mb={2}>
            <Paper variant={'outlined'} square>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant={'h6'}>Risk Protection</Typography>
                </Box>
                <Typography color={'textSecondary'}>
                  <Box display={'inline'}>
                    The problem is widespread and risk protection is simple and very cost-effective.
                    The Main Street America Group offers affordable personal and commercial flood
                    insurance backed by the federal government&apos;s National Flood Insurance
                    Program. For more information, please read{' '}
                  </Box>
                  <Box display={'inline'}>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        'https://agentsonly.msagroup.com/agentsonly/docs/FloodInsuranceMadeSimple!.12.06.10.pdf'
                      }>
                      Insurance Made Simple! flier
                    </Link>
                  </Box>
                  .
                </Typography>
              </Box>
            </Paper>
          </RowCol>

          <RowCol mb={2}>
            <Paper variant={'outlined'} square>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant={'h6'}>Enroll Today!</Typography>
                </Box>
                <Typography color={'textSecondary'}>
                  <Box display={'inline'}>
                    In order to write flood business with Main Street America, please print and
                    complete the{' '}
                  </Box>
                  <Box display={'inline'}>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        'https://agentsonly.msagroup.com/agentsonly/docs/WriteYourOwnFloodPolicyEnrollment.pdf'
                      }>
                      Flood Agency Enrollment Form
                    </Link>
                  </Box>
                  . Note: Once premium has been paid, there is a mandatory 30-day waiting period
                  before a new flood policy goes into effect.
                </Typography>
              </Box>
            </Paper>
          </RowCol>

          <RowCol mb={2}>
            <Paper variant={'outlined'} square>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant={'h6'}>Tools and Resources</Typography>
                </Box>
                <Typography color={'textSecondary'}>
                  <Box display={'inline'}>
                    To quote and submit applications, simply use of our intuitive{' '}
                  </Box>
                  <Box display={'inline'}>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={'https://msacc.floodpro.net/skins/023/index.asp'}>
                      online flood policy processing system.
                    </Link>
                  </Box>
                  .
                </Typography>
              </Box>
            </Paper>
          </RowCol>

          <RowCol mb={2}>
            <Paper variant={'outlined'} square>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant={'h6'}>Report a Loss</Typography>
                </Box>
                <Typography>
                  <Box fontSize={'0.875rem'}>Call (800) 759-8656</Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'} display={'inline'}>
                    E-mail <Link href={'mailto:Claims@floodpro.net'}>Claims@floodpro.net</Link>
                  </Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>Fax ACORD Property Loss Notices to (406) 257-1629</Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>
                    Mail ACORD Property Loss Notices to P.O. Box 2057, Kalispell, MT 59903
                  </Box>
                </Typography>
              </Box>
            </Paper>
          </RowCol>

          <RowCol mb={2}>
            <Paper variant={'outlined'} square>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant={'h6'}>Update an Existing Policy</Typography>
                </Box>
                <Typography>
                  <Box fontSize={'0.875rem'}>Call (800) 637-3846</Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'} display={'inline'}>
                    E-mail <Link href={'mailto:CSR@floodpro.net'}>CSR@floodpro.net</Link>
                  </Box>
                </Typography>
              </Box>
            </Paper>
          </RowCol>

          <RowCol mb={2}>
            <Paper variant={'outlined'} square>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant={'h6'}>Helpful Links</Typography>
                </Box>
                <Row spacing={2}>
                  <RowItem xs={4}>
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={'https://agents.floodsmart.gov/Agents/'}>
                        <Box
                          component="img"
                          sx={{
                            maxWidth: 200,
                          }}
                          alt="The house from the offer."
                          src={`${process.env.PUBLIC_URL}/assets/images/nfipAgents.gif`}
                          mb={2}
                        />
                      </Link>
                      <Box>
                        {`FloodSmart's `}
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href={'https://agents.floodsmart.gov/Agents/'}>
                          agent site
                        </Link>{' '}
                        offers free resources to help you sell flood insurance.
                      </Box>
                    </Box>
                  </RowItem>
                  <RowItem xs={4}>
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={'http://www.floodsmart.gov/floodsmart/'}>
                        <Box
                          component="img"
                          sx={{
                            maxWidth: 150,
                          }}
                          alt="The house from the offer."
                          src={`${process.env.PUBLIC_URL}/assets/images/nfip.gif`}
                          mb={2}
                        />
                      </Link>
                      <Box>
                        {`Direct your customers to `}
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href={'http://www.floodsmart.gov/floodsmart/'}>
                          {`FloodSmart's site`}
                        </Link>{' '}
                        for more information about the importance of flood insurance.
                      </Box>
                    </Box>
                  </RowItem>
                  <RowItem xs={4}>
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      flexDirection={'column'}>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={'http://www.fema.gov/hazard/flood/index.shtm'}>
                        <Box
                          component="img"
                          sx={{
                            maxWidth: 100,
                          }}
                          alt="The house from the offer."
                          src={`${process.env.PUBLIC_URL}/assets/images/fema.gif`}
                          mb={2}
                        />
                      </Link>
                      <Box>
                        {`Learn more about `}
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href={'http://www.fema.gov/hazard/flood/index.shtm'}>
                          {`FEMA`}
                        </Link>{' '}
                        and the National Flood Insurance Program.
                      </Box>
                    </Box>
                  </RowItem>
                </Row>
              </Box>
            </Paper>
          </RowCol>
        </AOCard>
      </RowCol>
    </ViewContainer>
  );
};

export default Flood;
