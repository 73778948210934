import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Paper, Typography } from '@material-ui/core';

import { AOButton } from '../AOButton';
import Search from './Filters/Search';
import State from './Filters/State';
import LineOfBusiness from './Filters/LineOfBusiness';
import Product from './Filters/Product';
import DocumentType from './Filters/DocumentType';
import { TYPE_OF_CONTAINER_FILTER_MAPPING } from './Filters/filterHelpers';

const BrandFolderFilters = ({
  typeOfContainer,
  handleSetSearchCriteria,
  handleResetOnClick,
  setInitialQuery,
  setResetFunc,
}) => {
  const filters = TYPE_OF_CONTAINER_FILTER_MAPPING[typeOfContainer];

  return (
    <Paper variant="outlined">
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Filter Documents</Typography>
        <AOButton color="secondary" onClick={handleResetOnClick}>
          Reset
        </AOButton>
      </Box>
      <Divider />
      <Box p={2} pt={3}>
        <Search
          typeOfContainer={typeOfContainer}
          handleSetSearchCriteria={handleSetSearchCriteria}
          setResetFunc={setResetFunc}
          setInitialQuery={setInitialQuery}
        />
      </Box>
      <Box px={2} py={2}>
        <Divider />
      </Box>

      {filters.length > 0 && (
        <Box p={2}>
          {filters.includes('showState') && (
            <Box>
              <State
                typeOfContainer={typeOfContainer}
                handleSetSearchCriteria={handleSetSearchCriteria}
                setResetFunc={setResetFunc}
                setInitialQuery={setInitialQuery}
              />
            </Box>
          )}

          {filters.includes('showLineOfBusiness') && (
            <Box pt={4}>
              <LineOfBusiness
                typeOfContainer={typeOfContainer}
                handleSetSearchCriteria={handleSetSearchCriteria}
                setResetFunc={setResetFunc}
                setInitialQuery={setInitialQuery}
              />
            </Box>
          )}

          {filters.includes('showProduct') && (
            <Box pt={4}>
              <Product
                typeOfContainer={typeOfContainer}
                handleSetSearchCriteria={handleSetSearchCriteria}
                setResetFunc={setResetFunc}
                setInitialQuery={setInitialQuery}
              />
            </Box>
          )}

          {filters.includes('showDocumentType') && (
            <Box pt={4}>
              <DocumentType
                typeOfContainer={typeOfContainer}
                handleSetSearchCriteria={handleSetSearchCriteria}
                setResetFunc={setResetFunc}
                setInitialQuery={setInitialQuery}
              />
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
};

BrandFolderFilters.propTypes = {
  typeOfContainer: PropTypes.oneOf(['Underwriting', 'Marketing', 'Training']),
  handleResetOnClick: PropTypes.func,
  handleSetSearchCriteria: PropTypes.func,
  setInitialQuery: PropTypes.func,
  setResetFunc: PropTypes.func,
};

export default BrandFolderFilters;
