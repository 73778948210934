import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { InputLabel, Select, MenuItem } from '@material-ui/core';

import { StyledAOSelect } from '../AOSelect/AOSelect.styled';

const AOGroupedSelect = ({
  children,
  disabled,
  id,
  inputLabel,
  labelId,
  menuItems,
  value,
  ...props
}) => {
  const renderedMenuItems = () => {
    const menuItemsDict = {};
    const itemsToRender = [];

    for (const item of menuItems) {
      if (!menuItemsDict[item.group]) {
        menuItemsDict[item.group] = [item];
      } else {
        menuItemsDict[item.group] = [...menuItemsDict[item.group], item];
      }
    }

    Object.keys(menuItemsDict).map((group) => {
      itemsToRender.push({ value: group, code: group, isGroup: true });
      for (const menuItem of menuItemsDict[group]) {
        itemsToRender.push({ value: menuItem.value, code: menuItem.code, isGroup: false });
      }
    });

    return itemsToRender.map((item, index) => {
      const groupHeader = item.isGroup;
      return (
        <MenuItem
          value={groupHeader ? '' : item.code}
          disabled={groupHeader}
          key={`${item.value}-menu-item-${index}-${groupHeader ? 'group' : ''}`}
          name={item.value}>
          {groupHeader ? <em>{item.value}</em> : item.value}
        </MenuItem>
      );
    });
  };

  return (
    <StyledAOSelect variant="outlined" disabled={disabled}>
      <InputLabel id={labelId} variant="outlined">
        {inputLabel}
      </InputLabel>
      <Select labelId={labelId} label={inputLabel} id={id} value={value} autoWidth {...props}>
        {!_.isNil(menuItems) ? renderedMenuItems() : children}
      </Select>
    </StyledAOSelect>
  );
};

AOGroupedSelect.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputLabel: PropTypes.string,
  labelId: PropTypes.string,
  menuItems: PropTypes.array,
  value: PropTypes.string,
};

export default AOGroupedSelect;
