import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { AOTypography } from '../../../AOTypography';

import { currencyStringFormatter } from '../../../../helpers';

const PaymentConfirmation = ({ paymentValidationData }) => {
  const { originalAmount, accountNumber, accountName, payIndex } = paymentValidationData;
  const formattedOriginalAmount = currencyStringFormatter(originalAmount);

  return (
    <Box>
      <Box mb={2} display="flex" flexWrap="wrap">
        <Typography>{`This acknowledges that you have entered a payment of`}</Typography>
        <AOTypography bold text={formattedOriginalAmount} inline />
        <Typography>{`on account`}</Typography>
        <AOTypography bold text={`#${accountNumber}.`} inline />
      </Box>

      <Box mb={2}>
        <Typography>{`We will bill back the insured for the above amount plus a returned check fee and deposit the payment amount plus $10.00 into your agency account.`}</Typography>
      </Box>

      <Box mb={2}>
        <Typography>{`Below is a summary of the payments made. Click on the confirmation number to produce a printable receipt for that payment.`}</Typography>
      </Box>

      <Box mb={2} display="flex" flexWrap="wrap">
        <Typography>{`Today's Date:`}</Typography>
        <AOTypography bold text={moment().format('MMMM Do YYYY')} inline />
      </Box>

      <Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Confirmation #: </Typography>
          </Box>
          <Typography color="textSecondary">{payIndex}</Typography>
        </Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Account Name: </Typography>
          </Box>
          <Typography color="textSecondary">{accountName}</Typography>
        </Box>
        <Box display="flex" my={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Account #:</Typography>
          </Box>
          <Typography color="textSecondary">{accountNumber}</Typography>
        </Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Payment Amount:</Typography>
          </Box>
          <Typography color="textSecondary">{formattedOriginalAmount}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

PaymentConfirmation.propTypes = {
  paymentValidationData: PropTypes.shape({
    changeDate: PropTypes.string,
    originalAmount: PropTypes.string,
    accountNumber: PropTypes.string,
    accountName: PropTypes.string,
    payIndex: PropTypes.number,
  }),
};

export default PaymentConfirmation;
