import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const FlexWrap = ({ children, ...otherProps }) => {
  return <Box {...otherProps}>{children}</Box>;
};

FlexWrap.defaultProps = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
};

FlexWrap.propTypes = {
  children: PropTypes.node,
};

export default FlexWrap;
