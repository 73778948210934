import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Col, Link, Row, Typography } from 'components/AORedesign';
import ClientChips from './ClientChips';
import ClientPolicyIcon from './ClientPolicyIcon';

const useStyles = makeStyles(() => ({
  policyGrid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  policyLink: {
    fontWeight: 500,
    flexWrap: 'wrap',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

const ClientCardPolicySummary = ({ accountNumber, policy, handlePolicyOnClick }) => {
  const classes = useStyles();

  return (
    <Row spacing={3} key={`${accountNumber}-${policy.PolicyNumber}`} direction="row" alignItems="center">
      <Col xs={3} display="flex">
        {handlePolicyOnClick ? (
          <Link className={classes.policyLink} underline="hover" onClick={() => handlePolicyOnClick(policy)}>
            <Row direction="row" alignItems="center" spacing={2}>
              <Col>
                <ClientPolicyIcon policyTypeCode={policy.PolicyTypeCode} />
              </Col>
              <Col>{`Policy #${policy.PolicyNumber}`}</Col>
            </Row>
          </Link>
        ) : (
          <Row direction="row" alignItems="center" spacing={2}>
            <Col>
              <ClientPolicyIcon policyTypeCode={policy.PolicyTypeCode} />
            </Col>
            <Col>
              <Typography variant="h5">{`Policy #${policy.PolicyNumber}`}</Typography>
            </Col>
          </Row>
        )}
      </Col>
      <Col xs={3} flexDirection="column" minHeight="100vh">
        <Typography color="textPrimary" variant="body1">
          {policy?.Product}
        </Typography>
      </Col>
      <Col xs={3}>
        <Row direction="row" spacing={2} alignItems="center">
          <Col>
            <Typography color="textSecondary" style={{ fontSize: '0.75rem', fontStyle: 'regular' }}>
              EFFECTIVE DATE
            </Typography>
            <Typography>{policy.PolicyEffectiveDate}</Typography>
          </Col>
          <Col>
            <Typography color="textSecondary" style={{ fontSize: '0.75rem', fontStyle: 'regular' }}>
              EXPIRATION DATE
            </Typography>
            <Typography>{policy.PolicyExpirationDate}</Typography>
          </Col>
        </Row>
      </Col>
      <Col xs={3} className={classes.policyGrid}>
        {policy.IsPolicyExpiring && (
          <>
            <ClientChips chipType="expiring" />
            <Box p={1} />
          </>
        )}
        {policy.HasOverduePayment && <ClientChips chipType="overdue" />}
      </Col>
    </Row>
  );
};

ClientCardPolicySummary.propTypes = {
  accountNumber: PropTypes.object,
  handlePolicyOnClick: PropTypes.func,
  policy: PropTypes.object,
};

export default ClientCardPolicySummary;
