const handleActivePanelChange = (_event, _setActivePanel) => {
  const panelId = _event.currentTarget.getAttribute('data-panelid');
  _setActivePanel(panelId);
};

const handleClickBackToPaymentManagement = (_setActivePanel) => {
  _setActivePanel(null);
};

const handleClickGoToPanelById = (setActivePanel, id) => {
  setActivePanel(id);
};

export { handleActivePanelChange, handleClickBackToPaymentManagement, handleClickGoToPanelById };
