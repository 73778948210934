import React, { useState } from 'react';

import { Alert, Box, Link, Typography } from 'components/AORedesign';
import { handleFileDownload } from 'components/CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import { Feature } from 'components/Feature';
import LinksContainer from './LinksContainer';

const PDF_ICON = 'pdf';
const DOCUMENT_ICON = 'document';
const EXCEL_ICON = 'excel';
const OPEN_IN_NEW_TAB_ICON = 'openInNew';

const MsaResources = () => {
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const handleClickDownloadFile = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');
    if (pdfUrl) {
      handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
    } else {
      const _fileUrl = event.currentTarget.getAttribute('data-file');
      handleFileDownload(_fileUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible);
    }
  };

  const resetErrorVisibility = () => {
    setIsDownloadingPdfErrorVisible(false);
  };
  return (
    <Box mb={2}>
      <Box mt={1} mb={4}>
        <Typography variant="h6">Main Street America</Typography>
      </Box>
      {isDownloadingPdfErrorVisible && (
        <Box mb={2}>
          <Alert color="error" alertTitleMessage={downloadingPdfError} />
        </Box>
      )}
      <Box>
        <LinksContainer heading="about us">
          <Link onClick={resetErrorVisibility} href={'https://msainsurance.com/'} endIcon={OPEN_IN_NEW_TAB_ICON}>
            Company Website
          </Link>
        </LinksContainer>
        <LinksContainer heading="Massachusetts power of attorney (poa)">
          <Link data-pdf={'/agency-automation/MA%20POA%20Form%20NGM%20%203.31.22.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Authorization To Sign Motor Veh. Reg. Certificate (NGM)
          </Link>
          <Link data-pdf={'/agency-automation/MA%20POA%20Form-MSAPIC%203.31.22.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Authorization To Sign Motor Veh. Reg. Certificate (MSP)
          </Link>
        </LinksContainer>
        <LinksContainer heading="security & policies">
          {/* Hiding links under https://amfament.atlassian.net/browse/E1PMAO-13450 while training team works on getting new links. No timeframe */}
          <Feature allowed={[]}>
            <Link onClick={resetErrorVisibility} href={''} endIcon={OPEN_IN_NEW_TAB_ICON}>
              Website Security Guide
            </Link>
          </Feature>
          <Link data-file={'/agency-automation/Agent%20Security%20Form.xls'} onClick={handleClickDownloadFile} endIcon={EXCEL_ICON}>
            Agent Security Form
          </Link>
          <Link
            onClick={resetErrorVisibility}
            href={`${process.env.PUBLIC_URL}/assets/documents/agency-automation/security_risk_assessment.pdf`}
            endIcon={OPEN_IN_NEW_TAB_ICON}>
            IA Risk Assessment
          </Link>
          <Link href={'https://brandfolder.com/s/rrswnrv96bcj9594tj9jw5z'} endIcon={PDF_ICON}>
            Licensing Questionnaire
          </Link>
          <Link data-pdf={'/agency-automation/Electronic%20Signature%20Policy.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Electronic Signature Policy
          </Link>
        </LinksContainer>
        <LinksContainer heading="automation forms">
          <Link data-pdf={'/agency-automation/Agency%20EFT%20Commission%20Deposit%20Form.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Agency EFT Form
          </Link>
          <Link
            data-file={'/agency-automation/CL%20Paper%20form%20request%20to%20turn%20paper%20back%20on.doc'}
            onClick={handleClickDownloadFile}
            endIcon={DOCUMENT_ICON}>
            Request To Continue CL Paper Copies
          </Link>
        </LinksContainer>
        <LinksContainer heading="contacts">
          <Link data-pdf={'/news/flier_MSACS.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Customer Services Overview
          </Link>
          <Link data-pdf={'/news/CustServQuickRef%20C.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Customer Services Phone Line Quick Reference
          </Link>
          <Link data-pdf={'/news/flier_ServiceContactList.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Mailing & Contact Information
          </Link>
        </LinksContainer>
      </Box>
    </Box>
  );
};

export default MsaResources;
