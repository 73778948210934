import React from 'react';
import axios from 'axios';
import cuid from 'cuid';
import _ from 'lodash';
import moment from 'moment';

import { GET_BILL_PAYMENT_REPORT_PAYMENT_BALANCE_DETAILS, GET_BILL_PAYMENT_REPORT_PAYMENT_BALANCE_TOTALS } from 'constants/api';

import DetailsDataTableRow from './DetailsDataTableRow';
import DetailsDataTableExpandableRow from './DetailsDataTableExpandableRow';

const detailsColumns = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    field: 'user',
    headerName: 'User',
    flex: 1,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    field: 'accountNumber',
    headerName: 'Account Number',
    flex: 1,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    flex: 1,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    field: 'agentNumber',
    headerName: 'Agent Number',
    flex: 1,
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    field: 'payType',
    headerName: 'Payment Type',
    flex: 1,
    options: {
      filter: true,
      sort: true,
    },
  },
];

const detailsOptions = {
  filter: true,
  filterType: 'dropdown',
  responsive: 'standard',
  expandableRows: true,
  expandableRowsHeader: false,
  expandableRowsOnClick: true,
  rowsExpanded: [0, 10],
  customRowRender: (data, dataIndex, rowIndex) => {
    return <DetailsDataTableRow {...{ data, dataIndex, rowIndex }} />;
  },
  renderExpandableRow: (rowData, rowMeta) => {
    return <DetailsDataTableExpandableRow {...{ rowData, rowMeta }} />;
  },
};

const handleDateOnChange = (date, _property, formData, setFormData) => {
  const formattedDate = moment(date).format('MM/DD/YYYY');
  setFormData({ ...formData, [_property]: date ? formattedDate : null });
};

const fetchPaymentDetails = async (_params) => {
  const params = `?fromDate=${_params.fromDate}&toDate=${_params.toDate}`;
  const { data } = await axios.get(GET_BILL_PAYMENT_REPORT_PAYMENT_BALANCE_DETAILS + params);
  return data;
};
const fetchPaymentTotals = async (_params) => {
  const params = `?fromDate=${_params.fromDate}&toDate=${_params.toDate}`;
  const { data } = await axios.get(GET_BILL_PAYMENT_REPORT_PAYMENT_BALANCE_TOTALS + params);
  return data;
};

const handleOnClickGenerateReport = async (_formData, _setReportContentData) => {
  const { fromDate, toDate } = _formData;
  const reqParams = {
    fromDate,
    toDate,
  };
  _setReportContentData((prevValue) => ({
    ...prevValue,
    alert: {
      ...prevValue.alert,
      isVisible: false,
    },
    loading: true,
  }));
  try {
    const response = await fetchPaymentDetails(reqParams);
    const responseTotals = await fetchPaymentTotals(reqParams);
    if (!_.isNil(response) && response.length < 1) {
      _setReportContentData((prevValue) => ({
        ...prevValue,
        alert: {
          message: 'No results returned for this search. Please refine your search and try again.',
          severity: 'info',
          isVisible: true,
        },
        loading: false,
        reportDetails: [],
        reportTotals: [],
        isVisible: false,
      }));
    } else {
      const detailsRaw = response.map(({ totalLine, balanceLines, totalLineAmount, runningTotalAmount }) => {
        const rows = balanceLines.map((el) => ({
          id: cuid(),
          totalLine,
          totalLineAmount,
          runningTotalAmount,
          ...el,
        }));
        return rows;
      });
      const details = _.flatten(detailsRaw);
      _setReportContentData((prevValue) => ({
        ...prevValue,
        loading: false,
        reportDetails: details,
        reportTotals: responseTotals,
        isVisible: true,
      }));
    }
  } catch (error) {
    _setReportContentData((prevValue) => ({
      ...prevValue,
      alert: {
        message: error.message,
        severity: 'error',
        isVisible: true,
      },
      loading: false,
    }));
    console.error(error);
  }
};

export { handleDateOnChange, handleOnClickGenerateReport, detailsColumns, detailsOptions };
