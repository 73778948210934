import React from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import DbcReportSkeleton from './DbcReportSkeleton';
import { ReportsHeader } from '../ReportsHeader';

import DbcInternal from './DbcInternal';
import DbcExternal from './DbcExternal';

import useScreenDefinition from '../../hooks/useScreenDefinition';

const LocalComponents = {
  ReportsHeader,
  DbcInternal,
  DbcExternal,
};

const DbcReport = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`DbcReport`);

  const localStateMap = {};

  const callBackFunctions = {};

  if (loadingSd) return <DbcReportSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          componentMap={LocalComponents}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default DbcReport;
