import React from 'react';

import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import WorkflowCard from './WorkflowCard';

export const handleFarmRanchRedirect = () =>
  redirectUrlFunction(
    {
      Area: 'Farm Ranch',
      DetailedContext: [
        {
          name: 'businessType',
          value: 'FR',
        },
      ],
      TypeOfRedirect: 'Quote Policy',
    },
    'POST',
    null,
    () => {},
    () => {},
  );

const WorkflowCardFarmRanch = () => {
  //TO DO - Remove this line and use handleFarmRanchRedirect on line#30
  const handleOnClick = handleFarmRanchRedirect;

  return (
    <WorkflowCard
      onClick={handleOnClick}
      icon={'icons-icon-agri-business'}
      title="Farm & Ranch"
      description="Provides property and liability coverage for farms and ranches."
    />
  );
};

export default WorkflowCardFarmRanch;
