import React from 'react';
import PropTypes from 'prop-types';
import MuiBox from '@material-ui/core/Box';

import { componentIdStringGenerator } from '../../../helpers';

const TabPanel = (props) => {
  return (
    <MuiBox
      role="tabpanel"
      hidden={props.value !== props.index}
      id={componentIdStringGenerator('Tabpanel', props.id ? props.id : props.index)}
      aria-labelledby={componentIdStringGenerator('Tab', props.id ? props.id : props.index)}
      {...props}>
      {props.value === props.index && <MuiBox p={2}>{props.children}</MuiBox>}
    </MuiBox>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  index: PropTypes.string,
  value: PropTypes.string,
};

export default TabPanel;
