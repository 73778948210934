import OpenWindowService from './OpenWindowService';
import { useHistory } from 'react-router-dom';

const handleRoute = (event) => {
  const history = useHistory();
  const routeUrl = event.currentTarget.getAttribute('data-route');
  return history.push(`${routeUrl}`);
};
const handleRouteAndOpenInNewWindow = (event) => {
  const routeUrl = event.currentTarget.getAttribute('data-route');
  return OpenWindowService(routeUrl, 'POST', null, null);
};

export { handleRoute, handleRouteAndOpenInNewWindow };
