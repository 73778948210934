import axios from 'axios';
import moment from 'moment';
import {
  PAYMENT_MANAGEMENT_HISTORY,
  PAYMENT_MANAGEMENT_HISTORY_SELECTIONS,
} from '../../../../constants/api';

const menuItemsInit = {
  adminList: [],
  agencyList: [],
  accountList: [],
  tranTypeList: [],
};
const formDataInit = {
  account: '',
  admin: '',
  agency: '',
  tranType: '',
  paymentHistoryFromDate: moment().subtract(90, 'days').calendar(), // 12/10/2022,
};

const autocompleteInputValuesInit = {
  agency: { value: '', invalidText: '' },
  account: { value: '', invalidText: '' },
};

const getPaymentHistoryMenuItems = async () => {
  const { data } = await axios.get(PAYMENT_MANAGEMENT_HISTORY_SELECTIONS);
  return data;
};

const getPaymentHistoryGridRowsFilter = async ({
  account,
  admin,
  agency,
  paymentHistoryToDate,
  paymentHistoryFromDate,
  tranType,
}) => {
  const { data } = await axios.get(
    `${PAYMENT_MANAGEMENT_HISTORY}?account=${account}&adminUser=${admin}&agency=${agency}&endDate=${moment(
      paymentHistoryToDate,
    ).format('YYYY/MM/DD')}&startDate=${moment(paymentHistoryFromDate).format(
      'YYYY/MM/DD',
    )}&tranType=${tranType}`,
  );
  return data;
};

export {
  formDataInit,
  menuItemsInit,
  autocompleteInputValuesInit,
  getPaymentHistoryGridRowsFilter,
  getPaymentHistoryMenuItems,
};
