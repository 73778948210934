import React from 'react';

const ExcelLogo = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.75 9.75V7.5L11.25 2.25H3.00225C2.17275 2.25 1.5 2.9235 1.5 3.75525V20.994C1.5 21.8258 2.16825 22.4993 2.99775 22.4993H14.2515C15.0788 22.4993 15.7493 21.8258 15.7493 21.006V19.4993H21.744C22.9897 19.4993 23.9993 18.4928 23.9993 17.25V11.9985C23.9993 10.7565 22.9972 9.74925 21.744 9.74925H15.7493L15.75 9.75ZM15 19.5V21.0052C15 21.4163 14.664 21.75 14.25 21.75H3C2.59125 21.75 2.25 21.4155 2.25 21.0037V3.747C2.25 3.342 2.5845 3.00075 2.997 3.00075H10.5V6.74625C10.5 7.5855 11.1713 8.25075 11.9985 8.25075H15V9.75075H9.00525C7.7595 9.75075 6.75 10.7572 6.75 12V17.2515C6.75 18.4935 7.752 19.5007 9.00525 19.5007L15 19.5ZM11.25 3.375L14.775 7.5H11.9977C11.5883 7.5 11.25 7.161 11.25 6.74325V3.375ZM8.99625 10.5H21.7545C22.5735 10.5 23.2507 11.169 23.2507 11.994V17.256C23.2507 18.075 22.581 18.75 21.7545 18.75H8.99625C8.17725 18.75 7.5 18.081 7.5 17.256V11.994C7.5 11.175 8.16975 10.5 8.99625 10.5ZM10.5 14.625L9 12H9.75L10.875 13.9688L12 12H12.75L11.25 14.625L12.75 17.25H12L10.875 15.2812L9.75 17.25H9L10.5 14.625ZM17.25 16.5V17.25H13.5V12H14.25V16.5H17.25ZM19.5037 12C18.6735 12 18 12.666 18 13.5C18 14.3287 18.666 15 19.5 15H20.2432C20.661 15 21 15.333 21 15.75C21 16.164 20.6677 16.5 20.2507 16.5H19.4993C19.0853 16.5 18.75 16.158 18.75 15.753V15.7448H18V15.7493C18 16.578 18.6705 17.25 19.5037 17.25H20.2463C21.0765 17.25 21.75 16.584 21.75 15.75C21.75 14.9213 21.084 14.25 20.25 14.25H19.5068C19.089 14.25 18.75 13.917 18.75 13.5C18.75 13.086 19.0823 12.75 19.4993 12.75H20.2507C20.6647 12.75 21 13.0898 21 13.5H21.75C21.75 12.6713 21.0795 12 20.2463 12H19.5037Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ExcelLogo;
