import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Box, Col, Divider, Link, Paper, Row, Typography } from 'components/AORedesign';
import WorkflowDialog from 'components/AORedesign/ViewContainer/WorkflowDialog';
import { useDispatch, useSelector } from 'react-redux';
import { selectCachedPolicy, setAppSnackbarProps, setSelectedCachedPolicy } from 'redux/app-store';
import ClientCardPolicySummary from '../Clients/ClientCardPolicySummary';
import { handleRedirectFromEnterprisePolicy } from '../Home/PolicySearch';
import CoveragesCard from './CoveragesCard';
import DiscountsCard from './DiscountsCard';
import DocumentsCard from './DocumentsCard';
import PolicyDetailsCard from './PolicyDetailsCard';
import PolicyDetailsSkeleton from './PolicyDetailsSkeleton';
import PropertiesCard from './PropertiesCard';
import VehiclesCard from './VehiclesCard';
const PolicyDetails = ({ policySummary }) => {
  const { primaryInsured, accountNumber, phoneNumber, policyNumber } = policySummary;
  const cachedPolicy = useSelector(selectCachedPolicy);

  const dispatch = useDispatch();

  useEffect(() => {
    // upon unmount, clear the selected policy number which will also the Policy Details modal
    return () => {
      dispatch(setSelectedCachedPolicy(null));
    };
  }, []);

  const handleDialogOnClose = () => {
    // clear the selected policy number which will also hide the Policy Details modal
    dispatch(setSelectedCachedPolicy(null));
  };

  const handlePolicySystemRedirect = async (policyNumber, sourceSystemName) => {
    const redirectData = handleRedirectFromEnterprisePolicy(policyNumber, sourceSystemName, (isError) => {
      // on error, show the red snackbar
      if (isError) {
        dispatch(
          setAppSnackbarProps({
            message: 'The service is currently not available. Please try again later.',
            severity: 'error',
          }),
        );
      }
    });
    await redirectData.onClick();
  };

  const policySummaryObject = {
    PolicyNumber: policyNumber,
    Product: policySummary.product,
    HasOverduePayment: policySummary.overdue,
    IsPolicyExpiring: policySummary.expiring,
    PolicyEffectiveDate: policySummary.startDate,
    PolicyExpirationDate: policySummary.endDate,
    PolicyTypeCode: policySummary.policyType,
  };

  const renderPolicyDetails = () => {
    return (
      <Box pb={5}>
        <Paper p={0}>
          <Box p={3}>
            <ClientCardPolicySummary accountNumber={accountNumber} policy={policySummaryObject} />
          </Box>
          <Divider />
          <Box p={3}>
            <Row spacing={3}>
              <Col xs={6}>
                <PolicyDetailsCard phoneNumber={phoneNumber} />
                <VehiclesCard />
                <PropertiesCard />
                <DiscountsCard />
                <DocumentsCard />
              </Col>
              <Col xs={6}>
                <CoveragesCard />
              </Col>
            </Row>
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderErrorMessage = () => {
    return (
      <Box pb={5}>
        <Paper p={0}>
          <Box p={3}>
            <ClientCardPolicySummary accountNumber={accountNumber} policy={policySummaryObject} />
          </Box>
          <Divider />
          <Box p={3}>
            {cachedPolicy?.errorDetails?.sourceSystemName ? (
              <Typography variant="body1" style={{ fontSize: '1rem', fontStyle: 'regular' }}>
                Unable to load policy data, refresh page or view information through{' '}
                <Link onClick={() => handlePolicySystemRedirect(policyNumber, cachedPolicy?.errorDetails?.sourceSystemName)} endIcon="openInNew">
                  Policy System
                </Link>
              </Typography>
            ) : (
              // If there is a 4xx/5xx errors from aoc-api we will not have the source system
              <Typography variant="body1" style={{ fontSize: '1rem', fontStyle: 'regular' }}>
                Unable to load policy data
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderContent = () => {
    let content = null;
    if (cachedPolicy?.retrieveStatus === 'RETRIEVING') {
      content = <PolicyDetailsSkeleton />;
    } else if (cachedPolicy?.retrieveStatus === 'RETRIEVED') {
      content = renderPolicyDetails();
    } else {
      content = renderErrorMessage();
    }
    return (
      <WorkflowDialog
        appBarTitle={`${primaryInsured} | Billing Account #${accountNumber}`}
        handleClose={handleDialogOnClose}
        open
        contained
        showStartQuote
        showPolicyViewChange>
        <Box px={5} py={3} sx={{ width: '100%' }}>
          {content}
        </Box>
      </WorkflowDialog>
    );
  };

  return renderContent();
};

PolicyDetails.propTypes = {
  policySummary: PropTypes.object,
};

export default PolicyDetails;
