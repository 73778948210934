import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton, InputAdornment, TextField } from 'components/AORedesign';
import { FILTER_TYPES, NAME_SEARCH_MINIMUM } from 'components/AORedesignViews/Clients';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ClientNameFilter = ({ handleSetFilter }) => {
  const [nameValue, setNameValue] = useState('');

  const SHOW_ERROR = nameValue.length > 0 && nameValue.length < NAME_SEARCH_MINIMUM;

  const handleOnSubmit = (e) => {
    e.preventDefault();
    handleSetFilter([FILTER_TYPES.NAME_SEARCH], nameValue);
  };

  const handleClearOnClick = () => {
    setNameValue('');
    handleSetFilter([FILTER_TYPES.NAME_SEARCH], '');
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <TextField
        label="Insured Search"
        placeholder="Name Insured Search"
        fullWidth
        value={nameValue}
        onChange={(e) => setNameValue(e.target.value)}
        error={SHOW_ERROR}
        helperText={SHOW_ERROR && `Need at least ${NAME_SEARCH_MINIMUM} characters to search.`}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {nameValue.length > 0 ? (
                <IconButton aria-label="clear field" onClick={handleClearOnClick}>
                  <CloseIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="search by name">
                  <SearchIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

ClientNameFilter.propTypes = {
  handleSetFilter: PropTypes.func,
};

export default ClientNameFilter;
