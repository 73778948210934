import React from 'react';
import PropTypes from 'prop-types';

import RowCol from '../ScreenSmith/RowCol';
import { AOSelect } from '../AOSelect';
import { GET_PERM_AGENCIES } from '../../constants/api';
import useFetchSelectMenuItems from '../../hooks/useFetchSelectMenuItems';

const BMReportExternal = ({ menuItems, onChange, setAgencyCodeOptions, value }) => {
  useFetchSelectMenuItems(GET_PERM_AGENCIES, (response) => {
    setAgencyCodeOptions(
      response.data.map((location) => ({
        value: `${location.agencyCode} - ${location.agencyCity}, ${location.agencyState}`,
        code: location.agencyCode,
      })),
    );
  });

  return (
    <RowCol mb={2}>
      <AOSelect
        id="bmreport-agency-codes"
        labelId="bmreport-agency-codes-label"
        inputLabel="Select Agency Codes"
        name="agencyCodes"
        menuItems={menuItems}
        onChange={onChange}
        value={value}
      />
    </RowCol>
  );
};

BMReportExternal.propTypes = {
  menuItems: PropTypes.array,
  onChange: PropTypes.func,
  setAgencyCodeOptions: PropTypes.func,
  value: PropTypes.string,
};

export default BMReportExternal;
