import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiTabs from '@material-ui/core/Tabs';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const Tabs = (props) => {
  const classes = useStyles();
  return <MuiTabs TabIndicatorProps={{ className: classes.indicator }} {...props} />;
};

export default Tabs;
