import React, { useState } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import ClaimsSkeleton from './ClaimsSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import OneStopClaimReporting from './OneStopClaimReporting';
import ClaimsReportingModal from './ClaimsReportingModal';

const LocalComponents = { OneStopClaimReporting };

const ClaimsReport = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`ClaimsReportPanel`);

  const [claimsReportingModalContent, setClaimsReportingModalContent] = useState(null);

  const handleCloseClaimsReportingModal = () => setClaimsReportingModalContent(null);

  const localStateMap = {};

  const callBackFunctions = {
    handleButtonOnClick: (e) => setClaimsReportingModalContent(e.currentTarget.value),
  };

  return loadingSd ? (
    <ClaimsSkeleton />
  ) : (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={LocalComponents}
        />
      )}
      {claimsReportingModalContent && (
        <ClaimsReportingModal
          claimsReportingModalContent={claimsReportingModalContent}
          handleCloseClaimsReportingModal={handleCloseClaimsReportingModal}
        />
      )}
    </>
  );
};

export default ClaimsReport;
