import MuiPaper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTE_DASH } from '../../constants/routes';
import AppDataContext from '../../contexts/appData.context';
import { Box, Button, Typography } from '../AORedesign';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 65,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  container: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  image: {
    width: '200px',
    height: '200px',
  },
}));

const ServiceUnavailable = () => {
  const classes = useStyles();
  const history = useHistory();

  const { setShowServiceUnavailable } = useContext(AppDataContext);

  const handleOnClick = () => {
    setShowServiceUnavailable(false);
    history.push(ROUTE_DASH);
  };

  return (
    <Box className={classes.root}>
      <MuiPaper className={classes.container}>
        <Box mt={14}>
          <Typography variant="h4">Service Unavailable</Typography>
        </Box>
        <Box mt={4}>
          <CloudOffIcon color="action" className={classes.image} />
        </Box>
        <Box mt={4}>
          <Typography color="textSecondary">The service is currently not available. Please try again later.</Typography>
        </Box>
        <Box mt={4}>
          <Button onClick={handleOnClick}>Go Home</Button>
        </Box>
      </MuiPaper>
    </Box>
  );
};

export default ServiceUnavailable;
