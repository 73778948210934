import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import AppetiteAgentValidationModalExternal from './AppetiteAgentValidationModalExternal';
import AppetiteAgentValidationModalInternal from './AppetiteAgentValidationModalInternal';

const LocalComponents = {
  AppetiteAgentValidationModalExternal,
  AppetiteAgentValidationModalInternal,
};

const AppetiteAgentValidationModal = ({ open, handleCloseOnClick, state, handleSetAgencyCode }) => {
  const [agentCodes, setAgentCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState('');

  const { UiDefinitionsData } = useScreenDefinition('AppetiteAgentValidationModal');

  const stateMap = {
    agentCodes,
    selectedCode,
    state,
    open,
  };

  const functionMap = { setSelectedCode, handleSetAgencyCode, setAgentCodes, handleCloseOnClick };

  return (
    !_.isNil(UiDefinitionsData) && (
      <ScreenSmith
        definition={UiDefinitionsData}
        stateMap={stateMap}
        functionMap={functionMap}
        componentMap={LocalComponents}
      />
    )
  );
};

AppetiteAgentValidationModal.propTypes = {
  state: PropTypes.string,
  open: PropTypes.bool,
  handleCloseOnClick: PropTypes.func,
  handleSetAgencyCode: PropTypes.func,
};

export default AppetiteAgentValidationModal;
