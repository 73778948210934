import React from 'react';
import Box from '@material-ui/core/Box';

import NewsArticles from './NewsArticles';
import { AOCard } from '../AOCard';
import RowCol from '../ScreenSmith/RowCol';
import { Headline } from '../Headline';
import { AOButton } from '../AOButton';
import { ROUTE_HOME } from '../../constants/routes';

const NewsArticlesArchived = () => {
  return (
    <RowCol>
      <Box mb={2}>
        <AOButton color="secondary" route={ROUTE_HOME} startIcon="ArrowBack">
          Back to Home
        </AOButton>
      </Box>
      <Headline title="News" variant="h1" />
      <AOCard cardTitle="Agent News - Archived">
        <NewsArticles category="archive" newsType="inside-news" />
      </AOCard>
    </RowCol>
  );
};

export default NewsArticlesArchived;
