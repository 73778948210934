import React, { useContext } from 'react';
import { Button, Box } from 'components/AORedesign';
import OpenWindowService from 'components/CommonUtilityServices/OpenWindowService';
import {REDIRECT_TO_NEW_TAB} from 'constants/api';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';

import agentContext from 'contexts/agent.context';


const FeedbackForm = () => {
  const agentContextObj = useContext(agentContext);
  
  // event handler to close modal
  const handleFeedbackOnClick = () => {
    let agentsOnlySessionUUID = localStorage.getItem('afe-session-id');
    let agentEmail = agentContextObj?.EmailAddress ?? 'agent@agentaccess.local';
    let userId = agentContextObj?.UserID ?? 'agent@agentaccess.local';

    let url = "https://survey.az1.qualtrics.com/jfe/form/SV_1Mk2l0lJgxsrHL0" +
              "?session_id=" + agentsOnlySessionUUID +
              "&username=" + userId +
              "&user_email=" + agentEmail;

    
    return OpenWindowService(
      url,
      "GET",
      null,
      null,
      REDIRECT_TO_NEW_TAB,
      null,
    );
  };

  return (
    <Box>
      <Button
        id="qualtrics-feedback"
        variant="outlined"
        color="secondary"
        onClick={handleFeedbackOnClick}
        startIcon={<FeedbackOutlinedIcon color="secondary" />} >
        Send Feedback
      </Button>
    </Box>
  );
};

export default FeedbackForm;
