import React, { useState, useEffect } from 'react';

import {
  handleSearchByTypeOnChange,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
  fetchSuggestions,
  handleGenerateReportOnClick,
} from './PolicyLossRunsReport.api';

import { Feature } from 'components/Feature';
import { useDebounce } from 'hooks/useDebounce';
import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  ViewContainer,
} from 'components/AORedesign';

const PolicyLossRunsReport = () => {
  const [searchByType, setSearchByType] = useState('AccountNumber');
  const [searchByOptions, setSearchByOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    searchBy: { value: '' },
  });
  const [formData, setFormData] = useState({ searchBy: '' });

  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);

  useEffect(async () => {
    if (autocompleteInputValues.searchBy.value.length >= 3) {
      const suggestions = await fetchSuggestions(searchByType, autocompleteInputValues.searchBy.value);
      setSearchByOptions(suggestions);
    } else {
      setSearchByOptions([]);
    }
  }, [debouncedState.searchBy]);

  const handleSearchByTypeOnChange_PROXY = (e) => handleSearchByTypeOnChange(e, setSearchByType, setErrorMessage);
  const handleSearchByOnInputChange_PROXY = (e, value) =>
    handleAOAutocompleteOnInputChange(
      'searchBy',
      e,
      value,
      autocompleteInputValues,
      setAutocompleteInputValues,
      debouncedState,
      setDebouncedState,
      setErrorMessage,
    );
  const handleSearchByOnChange = (e, value) => handleAOAutocompleteOnChange('searchBy', e, value, formData, setFormData);
  const handleSearchByOnBlur = () => handleAOAutocompleteOnBlur('searchBy', formData, setFormData, autocompleteInputValues);
  const handleGenerateReportOnClick_PROXY = () =>
    handleGenerateReportOnClick(searchByType, formData.searchBy, setLoadingPdf, setIsDownloadingPdfErrorVisible, setDownloadingPdfError);

  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
      <Feature allowed="Access_PolicyLossRuns_Report">
        <Card>
          <CardHeader title="Policy Loss Runs" />
          <CardContent>
            {errorMessage && (
              <Box mb={2}>
                <Typography color="error">{errorMessage}</Typography>
              </Box>
            )}
            <Box mb={2} display="flex" alignItems="center">
              <Typography style={{ marginRight: '10px' }}>Search By:</Typography>
              <RadioGroup
                onChange={handleSearchByTypeOnChange_PROXY}
                value={searchByType}
                aria-label="plr-report-search-by"
                name="plr-report-search-by"
                row={true}>
                <Radio id="policy-loss-runs-account-radio" value="AccountNumber" label="Account" />
                <Radio id="policy-loss-runs-policy-radio" value="PolicyNumber" label="Policy" />
              </RadioGroup>
            </Box>
            <Box mb={2}>
              <Autocomplete
                style={{ width: 400 }}
                id="policy-loss-runs-account-policy"
                value={formData.searchBy}
                options={searchByOptions || []}
                disableClearable
                onChange={handleSearchByOnChange}
                onInputChange={handleSearchByOnInputChange_PROXY}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="agent-code-label"
                    label="Account/Policy"
                    placeholder="Enter Account/Policy"
                    variant="outlined"
                    onBlur={handleSearchByOnBlur}
                  />
                )}
              />
            </Box>
            <Box>
              <Button
                onClick={handleGenerateReportOnClick_PROXY}
                loading={loadingPdf}
                disabled={autocompleteInputValues.searchBy.value.length < 6 || loadingPdf}
                id="policy-loss-runs-generate-report"
                color="primary"
                variant="contained">
                Generate Report
              </Button>
            </Box>
            {isDownloadingPdfErrorVisible && <Alert message={downloadingPdfError} severity="error" />}
          </CardContent>
        </Card>
      </Feature>
    </ViewContainer>
  );
};

export default PolicyLossRunsReport;
