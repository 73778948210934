import React from 'react'
import cuid from 'cuid'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const TotalsTable = ({ fromDate, toDate, rows }) => {
  return (
    <TableContainer component={Paper} size="small">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell colSpan={3} align="center">
              {` Payment Balances for Period ${fromDate} to ${toDate}`}
            </TableCell>
          </TableRow>
          {rows?.map(({ description, type, amount }) => (
            <TableRow key={cuid()}>
              <TableCell align="right" width="50%">{description}</TableCell>
              <TableCell align="right" width="10%">{`${type}:`}</TableCell>
              <TableCell align="left">{amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}


TotalsTable.propTypes = {
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.string)
}

export default TotalsTable