import React from 'react';
import { Box, Link, Typography } from '../AORedesign';
import PropTypes from 'prop-types';

const CLSRenewalsResults = ({ results, filterType, displayMessage }) => {
  const handleDocumentLinkClick = (event) => {
    event.preventDefault();
    window.open(event?.target?.href, '_blank');
  };
  function renderDocumentList(documentResults) {
    return documentResults.map((item) => (
      <Box p={1} key={item.s3PresignedUrl}>
        <Link
          href={item.s3PresignedUrl}
          variant="body2"
          color="primary"
          onClick={handleDocumentLinkClick}
          endIcon={'excel'}>
          {item?.documentName}
        </Link>
      </Box>
    ));
  }
  function renderDoucmentResult(results, filterType, displayMessage) {
    let updatedFilterType = filterType === 'current' ? 'Current' : 'Previous 5';
    if (displayMessage !== null && displayMessage !== '') {
      return (
        <Box display="flex" height="100%" justifyContent={'center'} alignItems={'center'}>
          {displayMessage}
        </Box>
      );
    } else {
      if (results === null || Object.keys(results).length === 0) {
        return (
          <>
            <Typography variant={'subtitle1'} component="div">
              <Box fontWeight={'bold'}>{updatedFilterType} Month Reports</Box>
            </Typography>
            <Box m={2} display={'flex'}>
              <Typography variant={'body2'}>There are no {filterType} reports.</Typography>
            </Box>
          </>
        );
      } else {
        return (
          <>
            <Typography variant={'subtitle1'} component="div">
              <Box fontWeight={'bold'}>{updatedFilterType} Month Reports</Box>
            </Typography>
            <Box m={2}>{renderDocumentList(results)}</Box>
          </>
        );
      }
    }
  }
  return renderDoucmentResult(results, filterType, displayMessage);
};

CLSRenewalsResults.defaultProps = {};

CLSRenewalsResults.propTypes = {
  results: PropTypes.array,
  filterType: PropTypes.string,
  displayMessage: PropTypes.string,
};

export default CLSRenewalsResults;
