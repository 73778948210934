import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const FlexWrapElementSpacer = ({ children }) => {
  return (
    <Box display="flex" justifyContent="center" mb={2}>
      {children}
    </Box>
  );
};

FlexWrapElementSpacer.propTypes = {
  children: PropTypes.node,
};

export default FlexWrapElementSpacer;
