import { Box, Col, Row, Typography } from 'components/AORedesign';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCachedPolicy } from 'redux/app-store';
import PolicyDetailsCardContainer from './PolicyDetailsCardContainer';
import PolicyDetailsCardSkeleton from './PolicyDetailsCardSkeleton';
import ShowMoreOrLessButton from './ShowMoreOrLessButton';

const PropertiesCard = () => {
  const cachedPolicy = useSelector(selectCachedPolicy);

  // pointer to policy
  const policyDetails = cachedPolicy.policy;
  const [expanded, setExpanded] = useState(false);
  const properties = policyDetails.dwellings ?? policyDetails.properties;

  const handleToggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };

  const getAddressLine1 = (address) => {
    return [address.line1, address.line2]
      .filter((element) => {
        return element !== null && element !== undefined;
      })
      .join(' ')
      .trim();
  };

  const getAddressLine2 = (address) => {
    return [address.city ? `${address.city},` : '', address.state, address.zipcode]
      .filter((element) => {
        return element !== null && element !== undefined;
      })
      .join(' ')
      .trim();
  };

  const renderPropertyRecords = (property, index) => {
    return (
      <Col xs={6} key={index}>
        <Box>
          <Typography color="textPrimary" style={{ fontSize: '1rem', fontStyle: 'medium' }} variant="body1">{`Property ${index + 1}`}</Typography>
        </Box>
        <Box mt={1}>
          <Typography color="textSecondary" variant="body1">
            {getAddressLine1(property)}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {getAddressLine2(property)}
          </Typography>
        </Box>
      </Col>
    );
  };

  const renderContent = () => {
    if (cachedPolicy.retrieveStatus === 'RETRIEVING') {
      return <PolicyDetailsCardSkeleton />;
    } else if (cachedPolicy.retrieveStatus === 'RETRIEVED' && properties) {
      return (
        <PolicyDetailsCardContainer title="Properties">
          {properties?.length > 0 ? (
            <>
              <Box>
                <Row spacing={2}>
                  {/* filter and render first 4 items from properties list */}
                  {properties
                    ?.filter((el, i) => i < 4)
                    .map((property, index) => {
                      return renderPropertyRecords(property, index);
                    })}
                  {/* after clicking  `show more` button, filter and render properties list if propeties list contains more than 4 items  */}
                  {expanded &&
                    properties
                      ?.filter((el, i) => i > 3)
                      .map((property, index) => {
                        return renderPropertyRecords(property, index + 4);
                      })}
                </Row>
              </Box>
              {/* show button when properties list contains more than 4 items */}
              {properties?.length > 4 && <ShowMoreOrLessButton expanded={expanded} handleOnClick={handleToggleExpand} />}
            </>
          ) : (
            <Typography color="textSecondary">No information found.</Typography>
          )}
        </PolicyDetailsCardContainer>
      );
    } else {
      return null;
    }
  };

  return renderContent();
};

export default PropertiesCard;
