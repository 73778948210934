import { Box, styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const StyledAOAutocomplete = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '24rem',
    },
    [theme.breakpoints.up('md')]: {
      width: '34rem',
    },
  },
  popupIndicatorOpen: {
    transform: 'unset',
  },
}));

export { StyledAOAutocomplete, useStyles };
