import React from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';

import useScreenDefinition from '../../hooks/useScreenDefinition';

const QuotingOnePoc = () => {
  // Screen def
  const { UiDefinitionsData } = useScreenDefinition(`QuotingOnePocFrame`);

  const localStateMap = {};
  ``;

  const callBackFunctions = {};

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default QuotingOnePoc;
