import React from 'react';

import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import WorkflowCard from './WorkflowCard';

export const handleBondsRedirect = () =>
  redirectUrlFunction(
    {
      Area: 'Policy Services Menu',
      DetailedContext: [],
      TypeOfRedirect: 'Surety and Fidelity Bonds',
    },
    'POST',
    null,
    () => {},
    () => {},
  );

const WorkflowCardBonds = () => {
  return (
    <WorkflowCard
      onClick={handleBondsRedirect}
      icon={'icons-icon-bonds'}
      title="Bonds"
      description="Provides a range of contract surety and fidelity products to fit your client's business needs."
    />
  );
};

export default WorkflowCardBonds;
