// import { rem } from 'polished';
import { styled } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const FullHeightMuiPaper = styled(Paper)(() => ({
  boxSizing: 'border-box',
  height: '100%',
}));

export { FullHeightMuiPaper };
