import { GET_REPORTS_URL } from '../../constants/api';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';

const handleCreateOnClick = (formData, setErrorMessage, setLoadingPdf) => {
  setErrorMessage('');
  try {
    const reportRequestBody = {
      Area: 'annual-milage',
      DetailedContext: [
        { name: 'AgtNum', value: formData.AgtNum.slice(-6) },
        { name: 'AsOfDate', value: formData.AsOfDate },
      ],
      TypeOfRedirect: 'Generate-Report',
    };

    handlePdfDownload(
      'POST',
      reportRequestBody,
      GET_REPORTS_URL,
      setLoadingPdf,
      setErrorMessage,
      setErrorMessage,
      'newWindow',
    );
  } catch (error) {
    console.error(error);
    setErrorMessage(error.message);
  }
};

export { handleCreateOnClick };
