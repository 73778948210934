import React from 'react';
import _ from 'lodash';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const Visible = ({ isVisible, visibilityKey, childrenVisibilityId, children, ...props }) => {
  if (!_.isNil(isVisible)) {
    return isVisible && children;
  } else if (
    // We check if visibilityKey & childrenVisibilityId is defined before we compare.
    // Ran into edge case where isVisible was undefined as expected (false),
    // but since visibilityKey & childrenVisibilityId were both undefined, this else if was truthy
    !_.isNil(visibilityKey) &&
    !_.isNil(childrenVisibilityId) &&
    visibilityKey === childrenVisibilityId
  ) {
    return <Box {...props}>{children}</Box>;
  } else {
    return false;
  }
};

Visible.propTypes = {
  isVisible: PropTypes.bool,
  visibilityKey: PropTypes.string,
  childrenVisibilityId: PropTypes.string,
  children: PropTypes.node,
};

export default Visible;
