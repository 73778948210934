import React, { useState } from 'react';
import cuid from 'cuid';
import { Box, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const RadioSkeleton = () => (
  <Box>
    <Box display="flex">
      <Skeleton
        width={5}
        style={{ marginRight: `5px`, padding: `6px 8px` }}
        animation="wave"
        key={cuid}
      />
      <Skeleton
        width={100}
        style={{ marginRight: `24px`, padding: `6px 8px` }}
        animation="wave"
        key={cuid}
      />
    </Box>
  </Box>
);

const PLSWipSkeleton = () => {
  const [skeletonItems] = useState(Array.from(Array(5).keys()));
  return (
    <Box>
      <Box>
        <Skeleton
          width={175}
          style={{ marginRight: `24px`, padding: `6px 8px` }}
          animation="wave"
        />
      </Box>
      <Paper variant="outlined">
        <Box p={2}>
          <Skeleton
            width={145}
            style={{ marginRight: `24px`, padding: `6px 8px` }}
            animation="wave"
          />
          <Box display="flex" mb={4}>
            {skeletonItems.map(() => (
              <RadioSkeleton key={cuid()} />
            ))}
          </Box>
          <Skeleton
            width={145}
            style={{ marginRight: `24px`, padding: `6px 8px` }}
            animation="wave"
          />
          <Box display="flex">
            <Skeleton
              width={175}
              style={{ marginRight: `24px`, padding: `6px 8px` }}
              animation="wave"
            />
            <Skeleton
              width={175}
              style={{ marginRight: `24px`, padding: `6px 8px` }}
              animation="wave"
            />
          </Box>
        </Box>
      </Paper>
      <Box mb={2} />
      <Paper variant="outlined" p={2} mb={2}>
        <Box p={2}>
          <Box mb={2}>
            <Skeleton
              width={145}
              style={{ marginRight: `24px`, padding: `6px 8px` }}
              animation="wave"
            />
          </Box>
          <Box display="flex">
            <Skeleton
              width={175}
              style={{ marginRight: `24px`, padding: `6px 8px` }}
              animation="wave"
            />
            <Skeleton
              width={175}
              style={{ marginRight: `24px`, padding: `6px 8px` }}
              animation="wave"
            />
          </Box>
        </Box>
      </Paper>
      <Box display="flex">
        <Skeleton width={95} style={{ marginRight: `24px`, padding: `6px 8px` }} animation="wave" />
        <Skeleton width={95} style={{ marginRight: `24px`, padding: `6px 8px` }} animation="wave" />
      </Box>
    </Box>
  );
};

export default PLSWipSkeleton;
