import { ViewContainer } from 'components/AORedesign';
import {
  ClientFilters,
  ClientResults,
  DEFAULT_PARAMS,
  FILTER_TYPES,
  POLICY_FILTER_VALUES,
  POLICY_PARAM_VALUES,
  SELECT_ALL_VALUE,
} from 'components/AORedesignViews/Clients';
import React, { useEffect, useState } from 'react';

const Clients = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const policyFilter = queryParameters.get(FILTER_TYPES.POLICY);

  const [filters, setFilters] = useState({
    [FILTER_TYPES.ITEM_PER_PAGE]: DEFAULT_PARAMS[FILTER_TYPES.ITEM_PER_PAGE],
    [FILTER_TYPES.POLICY]: POLICY_FILTER_VALUES.ALL,
    [FILTER_TYPES.PAGE_NUMBER]: DEFAULT_PARAMS[FILTER_TYPES.PAGE_NUMBER],
    [FILTER_TYPES.NAME_SEARCH]: '',
    [FILTER_TYPES.AGENCY_CODES]: '',
    [FILTER_TYPES.LOB]: SELECT_ALL_VALUE,
    [FILTER_TYPES.PRODUCT]: SELECT_ALL_VALUE,
  });

  useEffect(() => {
    (async () => {
      // set initial filters based on querystring of the Client List
      if (policyFilter === POLICY_PARAM_VALUES.EXPIRING_POLICIES) {
        handleSetFilter(FILTER_TYPES.POLICY, POLICY_FILTER_VALUES.EXPIRING);
      } else if (policyFilter === POLICY_PARAM_VALUES.OVERDUE_PAYMENTS) {
        handleSetFilter(FILTER_TYPES.POLICY, POLICY_FILTER_VALUES.OVERDUE);
      }
    })();
  }, []);

  // generic function to set a filter value
  const handleSetFilter = (key, value, scroll) => {
    const newFilters = { ...filters, [key]: value };
    newFilters[FILTER_TYPES.PAGE_NUMBER] = scroll ? newFilters[FILTER_TYPES.PAGE_NUMBER] + 1 : DEFAULT_PARAMS[FILTER_TYPES.PAGE_NUMBER];

    setFilters(newFilters);
  };

  // build url w/query strings based on assigned filter values
  // - itemsPerPage: number (default 20)
  // - pageNumber: number (start at 0)
  // - locations: array (array with no more than 6)                       AGENCY_CODES
  // - lob: string                                                        LOB
  // - searchName: string                                                 NAME_SEARCH
  // - product: string                                                    PRODUCT
  // - policyFilter: string (ALL | EXPIRING_POLICIES | OVERDUE_PAYMENTS)  POLICY
  const buildQueryParameters = (requestFilters) =>
    Object.keys(requestFilters).reduce((filtered, key) => {
      const paramValue = requestFilters[key];
      if (typeof paramValue === 'number' || (paramValue.length > 0 && paramValue !== SELECT_ALL_VALUE)) {
        filtered[key] = paramValue;
      }
      return filtered;
    }, {});

  return (
    <ViewContainer title="Clients">
      <ClientFilters filters={filters} handleSetFilter={handleSetFilter} />
      <ClientResults filterString={JSON.stringify(buildQueryParameters(filters))} handleSetFilter={handleSetFilter} />
    </ViewContainer>
  );
};

export default Clients;
