import React from 'react';
import { useLocation } from 'react-router-dom';
import { dataGridCurrencyFormatter, dataGridDateFormatter } from './dataGridHelpers';
import { MOMENT_DATE_FORMAT_MMDDYYYY, aODatePickerMinDate, getMonthName } from './dateHelpers';
import { isRealNumber } from './numberHelpers';
import { ALPHA_NUMERIC_REGEX, componentIdStringGenerator, currencyStringFormatter } from './stringHelpers';

function QueryStrings() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

// use to mock a response until an api is built
function mockAsyncApiResponse(response, timeoutInSeconds, isSuccess) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (isSuccess) {
        resolve(response);
      } else {
        reject('Error retreiving data');
      }
    }, timeoutInSeconds * 1000); // Convert seconds to milliseconds
  });
}

export {
  ALPHA_NUMERIC_REGEX,
  MOMENT_DATE_FORMAT_MMDDYYYY,
  QueryStrings,
  aODatePickerMinDate,
  componentIdStringGenerator,
  currencyStringFormatter,
  dataGridCurrencyFormatter,
  dataGridDateFormatter,
  getMonthName,
  isRealNumber,
  mockAsyncApiResponse,
};
