import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  FormGroup,
  Checkbox,
  Modal,
  Paper,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Feature } from '../../Feature';
import NewsAdminViewContainer from '../../NewsArticles/NewsAdminViewContainer';
import featureFlags from '../../../constants/featureFlags';
import { RowCol, Row, Col } from '../../ScreenSmith';
import { AOCard } from '../../AOCard';
import { AOButton } from '../../AOButton';
import { AOTextField } from '../../AOTextField';
import { AODatePicker } from '../../AODatePicker';
import { AOAlert } from '../../AOAlert';
import { AOTransferList } from '../../AOTransferList';
import { AODocUploader } from '../../AODocUploader';

import { getStates, submitTextNews } from './AddOrEditNewsArticle.api';
import {
  getNewsArticleById,
  updateNewsArticle,
  deleteNewsArticle,
} from '../SearchNewsArticles/SearchNewsArticlesDataGrid.api';

import {
  handleFromDateOnChange,
  handleOnChange,
  handleToDateOnChange,
} from '../../CommonUtilityServices/EventHandlerService';
import {
  ROUTE_NEWS_ARTICLE_ADMIN,
  ROUTE_NEWS_ARTICLE_ADMIN_SEARCH,
} from '../../../constants/routes';

const initFormData = {
  title: '',
  startDate: moment().format('MM/DD/YYYY'),
  endDate: null,
  status: 'D',
  type: '',
};

const initDisplaySection = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
};

const initAlert = { severity: '', message: '' };

const AddOrEditNewsArticle = ({ searchEdit }) => {
  const [formData, setFormData] = useState(initFormData);
  const [displaySection, setDisplaySection] = useState(initDisplaySection);
  const [availableStates, setAvailableStates] = useState([]);
  const [checkedStates, setCheckedStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [alert, setAlert] = useState(initAlert);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const initState = useRef({
    availableStates: [],
    selectedStates: [],
    formData: initFormData,
    displaySection: initDisplaySection,
  });

  const history = useHistory();

  const queryParameters = new URLSearchParams(window.location.search);
  const newsId = queryParameters.get('id');

  useEffect(() => {
    (async () => {
      if (searchEdit && newsId) {
        const newsArticle = await getNewsArticleById(newsId);
        const formDataById = {
          seqId: newsArticle.seqId,
          title: newsArticle.title,
          startDate: newsArticle.startDate,
          endDate: newsArticle.endDate,
          status: newsArticle.status,
          type: newsArticle.type,
          text: newsArticle.text,
          sectionsString: newsArticle.sectionsString,
          selectedStatesString: newsArticle.selectedStatesString,
        };
        setFormData(formDataById);
        initState.current.formData = formDataById;

        const newDisplaySection = { ...initDisplaySection };
        for (const section of newsArticle.sections) {
          newDisplaySection[section] = true;
        }
        setDisplaySection(newDisplaySection);
        initState.current.displaySection = newDisplaySection;

        setAvailableStates(newsArticle.availableStates);
        initState.current.availableStates = newsArticle.availableStates;
        setSelectedStates(newsArticle.selectedStates);
        initState.current.selectedStates = newsArticle.selectedStates;
      } else {
        const getStatesForNews = await getStates();
        setAvailableStates(getStatesForNews);
        initState.current.availableStates = getStatesForNews;
      }
    })();
  }, []);

  const handleOnChangeCheckbox = (e) => {
    const {
      target: { checked, name },
    } = e;
    const newDisplaySection = { ...displaySection };
    newDisplaySection[name] = checked;
    setDisplaySection(newDisplaySection);
  };

  /* Callback fired when the From Date value changes. */
  const handleOnChangeStartDate = (date) => {
    handleFromDateOnChange(date, formData, setFormData, 'startDate');
  };

  /* Callback fired when the To Date value changes. */
  const handleOnChangeEndDate = (date) => {
    handleToDateOnChange(date, formData, setFormData, 'endDate');
  };

  const resetForm = () => {
    setCheckedStates([]);
    setAvailableStates(initState.current.availableStates);
    setSelectedStates(initState.current.selectedStates);
    setFormData(initState.current.formData);
    setDisplaySection(initState.current.displaySection);
  };

  const handleClickSubmitText = async () => {
    setAlert(initAlert);
    try {
      const keys = Object.keys(displaySection);
      const filteredSection = keys.filter((key) => {
        return displaySection[key];
      });

      const requestBody = {
        title: formData.title,
        type: formData.type,
        startDate: formData.startDate,
        endDate: formData.endDate ?? '',
        status: formData.status,
        sectionsString: filteredSection.join(','),
        selectedStatesString: selectedStates.join(','),
        text: formData.text,
        docContent: formData.docContent,
        extension: formData.extension,
      };

      if (newsId) {
        requestBody.seqId = formData.seqId;
        requestBody.selectedStates = selectedStates;
        requestBody.sections = filteredSection;

        const { data, status } = await updateNewsArticle(requestBody);

        if (data === 'Success' && status === 200) {
          setAlert({ severity: 'success', message: 'News item successfully updated.' });
          initState.current = {
            formData: formData,
            availableStates: availableStates,
            selectedStates: selectedStates,
            displaySection: displaySection,
          };
        }
      } else {
        const { data, status } = await submitTextNews(requestBody);

        if (data === 'Success' && status === 200) {
          setAlert({ severity: 'success', message: 'News item successfully created.' });
          resetForm();
        }
      }
    } catch (error) {
      setAlert({ severity: 'error', message: error.message });
    }
  };

  /* set document extension and base64Data for document news article*/
  const setDocumentDetails = (documentDetails) => {
    setFormData((prevState) => ({
      ...prevState,
      docContent: documentDetails.docContent,
      extension: documentDetails.extension,
    }));
  };

  const handleDeleteOnClick = async () => {
    const { data, status } = await deleteNewsArticle(newsId);

    if (data === 'Success' && status === 200) {
      history.push(ROUTE_NEWS_ARTICLE_ADMIN_SEARCH);
    }
  };

  const isSubmitDisabled = () =>
    !formData?.title?.length ||
    !formData?.startDate?.length ||
    !Object?.values(displaySection)?.includes(true) ||
    !selectedStates?.length ||
    !formData?.type?.length ||
    (formData?.type === 'T' && !formData?.text?.length) ||
    (!searchEdit &&
      formData?.type === 'D' &&
      !formData?.docContent?.length &&
      !formData?.extension?.length);

  return (
    <Feature allowed={featureFlags.ACCESS_NEWS_ADMIN}>
      <NewsAdminViewContainer
        title={searchEdit ? 'Modify News Article' : 'Add News'}></NewsAdminViewContainer>
      <RowCol>
        <RowCol mb={2}>
          <AOCard>
            <Row mb={2}>
              <Col xs={12}>
                <Typography variant="h6">News Category Agents</Typography>
              </Col>
            </Row>
            <Row mb={4}>
              <Col xs={12} md={6}>
                <RowCol mb={4}>
                  <AOTextField
                    id={'title'}
                    label={'Title'}
                    value={formData.title}
                    name={'title'}
                    required
                    onChange={(e) => handleOnChange(e, formData, setFormData)}
                  />
                </RowCol>
                <RowCol mb={4}>
                  <AODatePicker
                    id={`start-datepicker`}
                    name={`startDate`}
                    label={`Display Start Date`}
                    value={formData.startDate}
                    onChange={(date) => handleOnChangeStartDate(date)}
                    required
                  />
                </RowCol>
                <RowCol mb={4}>
                  <AODatePicker
                    id={`end-datepicker`}
                    name={`endDate`}
                    label={`Display End Date`}
                    value={formData.endDate}
                    onChange={(date) => handleOnChangeEndDate(date)}
                  />
                </RowCol>
              </Col>
              <Col xs={12} md={6}>
                <FormControl required component="fieldset">
                  <FormLabel component="legend">Display Section</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={displaySection[0]}
                          onChange={(e) => handleOnChangeCheckbox(e)}
                          name="0"
                        />
                      }
                      label="Home Page"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={displaySection[1]}
                          onChange={(e) => handleOnChangeCheckbox(e)}
                          name="1"
                        />
                      }
                      label="Personal Lines Services - Yellow Bar"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={displaySection[2]}
                          onChange={(e) => handleOnChangeCheckbox(e)}
                          name="2"
                        />
                      }
                      label="Commercial Lines Services - Yellow Bar"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={displaySection[3]}
                          onChange={(e) => handleOnChangeCheckbox(e)}
                          name="3"
                        />
                      }
                      label="Personal Line Services - News Articles"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={displaySection[4]}
                          onChange={(e) => handleOnChangeCheckbox(e)}
                          name="4"
                        />
                      }
                      label="Commercial Line Services - News Articles"
                    />
                  </FormGroup>
                </FormControl>
              </Col>
            </Row>
            <RowCol mb={8}>
              <RowCol>
                <AOTransferList
                  required
                  checked={checkedStates}
                  left={availableStates}
                  leftLabel={'Available States'}
                  right={selectedStates}
                  rightLabel={'* Selected States'}
                  setLeft={(e) => setAvailableStates(e)}
                  setRight={(e) => setSelectedStates(e)}
                  setChecked={setCheckedStates}
                />
                {selectedStates.length === 0 && (
                  <Typography component="div" variant="body1">
                    <Box color="text.secondary" fontSize={'0.75rem'} mt={4}>
                      You must select a State
                    </Box>
                  </Typography>
                )}
              </RowCol>
            </RowCol>
            <RowCol mb={6}>
              <FormControl component="fieldset" required>
                <FormLabel component="legend">Display Status</FormLabel>
                <RadioGroup
                  aria-label="displayStatus"
                  name="status"
                  value={formData.status}
                  onChange={(e) => handleOnChange(e, formData, setFormData)}>
                  <FormControlLabel value="D" control={<Radio />} label="Display" />
                  <FormControlLabel value="A" control={<Radio />} label="Archive" />
                  <FormControlLabel value="H" control={<Radio />} label="Hide" />
                </RadioGroup>
              </FormControl>
            </RowCol>
            <RowCol>
              {!searchEdit && (
                <RowCol mb={2}>
                  <FormControl component="fieldset" required>
                    <RadioGroup
                      aria-label="displayStatus"
                      name="type"
                      value={formData.type}
                      onChange={(e) => handleOnChange(e, formData, setFormData)}
                      row>
                      <FormControlLabel value="D" control={<Radio />} label="Link to Document" />
                      <FormControlLabel value="T" control={<Radio />} label="Text" />
                    </RadioGroup>
                  </FormControl>
                </RowCol>
              )}
              <RowCol>
                {formData.type === 'D' && (
                  <AODocUploader
                    label={searchEdit ? 'Upload Doc.?' : 'Document *'}
                    acceptableFileTypes=".doc, .pdf"
                    invalidFileTypeMsg="Please select an Adobe Acrobat (*.pdf) or Microsoft Word (*.doc) file to upload."
                    setDocumentDetails={setDocumentDetails}
                  />
                )}
                {formData.type === 'T' && (
                  <AOTextField
                    multiline
                    name="text"
                    value={formData.text}
                    onChange={(e) => handleOnChange(e, formData, setFormData)}
                    label="Article Body"
                    minRows={4}
                  />
                )}
              </RowCol>
            </RowCol>
            {alert.message.length > 0 && (
              <RowCol mb={2}>
                <AOAlert severity={alert.severity} message={alert.message} />
              </RowCol>
            )}
            <RowCol mb={2}>
              <Box display="flex" justifyContent="flex-end">
                <Box>
                  <Box display="flex">
                    <Box>
                      <AOButton
                        color="primary"
                        variant="contained"
                        disabled={isSubmitDisabled()}
                        onClick={handleClickSubmitText}>
                        Submit
                      </AOButton>
                    </Box>
                    <Box mx={2}>
                      <AOButton type="reset" variant="contained" onClick={resetForm}>
                        Reset
                      </AOButton>
                    </Box>
                    {searchEdit && newsId && (
                      <Box mr={2}>
                        <AOButton
                          color="primary"
                          variant="outlined"
                          onClick={() => setShowDeleteModal(true)}>
                          Delete
                        </AOButton>
                      </Box>
                    )}
                    <Box>
                      <AOButton variant="outlined" route={ROUTE_NEWS_ARTICLE_ADMIN}>
                        Cancel
                      </AOButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </RowCol>
          </AOCard>
        </RowCol>
      </RowCol>
      <Modal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        aria-labelledby="search-edit-news-article-delete"
        aria-describedby="search-edit-news-article-delete-modal"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper style={{ padding: `1rem` }}>
          <Box p={3} mb={4}>
            You are about to delete this news item. Continue?
          </Box>
          <Box mt={2} display="flex" justifyContent={'flex-end'}>
            <AOButton
              id="search-edit-news-article-delete-modal-no-button"
              onClick={() => setShowDeleteModal(false)}
              variant="outlined">
              No
            </AOButton>
            <Box mr={1} />
            <AOButton
              color="primary"
              id="search-edit-news-article-delete-modal-yes-button"
              onClick={handleDeleteOnClick}
              variant="contained">
              Yes
            </AOButton>
          </Box>
        </Paper>
      </Modal>
    </Feature>
  );
};

AddOrEditNewsArticle.defaultProps = {
  searchEdit: false,
};

AddOrEditNewsArticle.propTypes = {
  searchEdit: PropTypes.bool,
};

export default AddOrEditNewsArticle;
