  import { renderCell } from '../BillingServices.api'
  const paymentsDueColumnData = [
    {
      field: 'name',
      headerName: 'Insured Name',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'currentDueDate',
      headerName: 'Due Date',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'accountNumber',
      headerName: 'Account Number',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell
    },
    {
      field: 'minimumDue',
      headerName: 'Amount',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'payPaymentAmount',
      headerName: 'Amt Paid',
      editable: false,
      sortable: false,
      flex: 1,
    },
  ]

  export {
    paymentsDueColumnData,
  }