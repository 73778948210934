import React from 'react';
import _ from 'lodash';
import useAxios from 'axios-hooks';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { GET_OKTA_LOGIN } from '../../constants/api';
import { StyledAPIRequestHandler } from './APIRequestHandler.styled';
import { useOktaAuth } from '@okta/okta-react';

const APIRequestHandler = ({ loading, error, children }) => {
  const { oktaAuth } = useOktaAuth();

  const [
    { data: reirectLoginUrl, loading: redirectLoginUrlLoading, error: redirectLoginUrlError },
  ] = useAxios({
    method: 'get',
    url: `${GET_OKTA_LOGIN}`,
  });

  if (loading)
    return (
      <Box py={2}>
        <CircularProgress color="inherit" size={20} />
      </Box>
    );
  if (!_.isNil(error)) {
    console.info(redirectLoginUrlLoading);
    if (error.message && error.message.toLowerCase().includes('Network Error'.toLowerCase())) {
      if (!redirectLoginUrlLoading && _.isNil(redirectLoginUrlError)) {
        oktaAuth.tokenManager.clear();
        window.location.href = reirectLoginUrl.loginUrl;
      } else {
        return (
          <Box py={2}>
            <Alert severity="error">{`Oops!  Something went wrong - ${error.message}.`}</Alert>
          </Box>
        );
      }
    } else {
      return (
        <Box py={2}>
          <Alert severity="error">{`Oops!  Something went wrong - ${error.message}.`}</Alert>
        </Box>
      );
    }
  } else {
    return (
      <StyledAPIRequestHandler>
        <>{children}</>
      </StyledAPIRequestHandler>
    );
  }
};

APIRequestHandler.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  emptyResponse: PropTypes.bool,

  children: PropTypes.node,
};

export default APIRequestHandler;
