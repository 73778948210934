import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Button } from 'components/AORedesign';
import PropTypes from 'prop-types';
import React from 'react';

const ShowMoreOrLessButton = ({ expanded, handleOnClick }) => {
  return (
    <Box mt={2} display="flex" justifyContent="center" alignItems="center">
      <Button color="secondary" variant="text" onClick={handleOnClick} endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
        {expanded ? `show less` : `show more`}
      </Button>
    </Box>
  );
};

ShowMoreOrLessButton.propTypes = {
  expanded: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

export default ShowMoreOrLessButton;
