import React, { useState, useRef } from 'react';
import { Box, Typography } from '../AORedesign';

import CLSRenewalsFilter from './CLSRenewalsFilters';
import CLSRenewalsResults from './CLSRenewalsResults';
import {
  CL_RENEWAL_AGENCY_CODE,
  CL_RENEWAL_MONTH,
  CURRENT_MONTH_RADIO_VALUE,
} from './CLSRenewalsFilters';

const CLSRenewals = () => {
  const [formData, setFormData] = useState({
    [CL_RENEWAL_AGENCY_CODE]: {},
    [CL_RENEWAL_MONTH]: CURRENT_MONTH_RADIO_VALUE,
  });
  const [documents, setDocuments] = useState(null);

  const documentsRef = useRef({});

  const handleMonthOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    if (documentsRef.current && Object.keys(documentsRef.current).length > 0) {
      const newDocuments = documentsRef.current.filter(
        (document) =>
          document.filterType === value && formData[CL_RENEWAL_AGENCY_CODE][document.locationCode],
      );
      setDocuments(newDocuments);
    }
  };

  const handleAutocompleteAgencyOnChange = (e, newValue) => {
    setFormData((prevState) => ({
      ...prevState,
      [CL_RENEWAL_AGENCY_CODE]: newValue ? { [newValue]: true } : {},
    }));
  };

  const handleCheckboxAgencyOnChange = (e) => {
    const { name, checked } = e.target;
    const newCheckedState = { ...formData.agencyCode, [name]: checked };
    setFormData((prevState) => ({
      ...prevState,
      [CL_RENEWAL_AGENCY_CODE]: newCheckedState,
    }));

    const newDocuments = documentsRef.current.filter(
      (document) =>
        newCheckedState[document.locationCode] &&
        document.filterType === formData[CL_RENEWAL_MONTH],
    );
    setDocuments(newDocuments);
  };

  const handleSetDocuments = (documents, filter) => {
    const newDocuments = filter ? documents.filter((doc) => doc.filterType === filter) : documents;
    setDocuments(newDocuments);
    documentsRef.current = documents;
  };

  const initializeCheckboxes = (locationCodes) => {
    setFormData((prevState) => ({
      ...prevState,
      [CL_RENEWAL_AGENCY_CODE]: locationCodes,
    }));
  };

  const handleResetDocuments = (reset) => {
    setDocuments(
      reset
        ? documentsRef.current.filter((doc) => doc?.filterType === formData[CL_RENEWAL_MONTH])
        : [],
    );
  };

  const NO_DOCUMENTS_FOR_AGENCIES =
    documents?.length === 0 && formData[CL_RENEWAL_AGENCY_CODE] === null;

  const generateDisplayString = () => {
    if (NO_DOCUMENTS_FOR_AGENCIES) {
      return 'There are no agency codes with renewal transition reports.';
    } else if (documents === null) {
      return 'Search agency code to view existing reports';
    } else if (
      Object.keys(formData?.[CL_RENEWAL_AGENCY_CODE]).length > 0 &&
      !Object.values(formData?.[CL_RENEWAL_AGENCY_CODE]).includes(true)
    ) {
      return 'Select an agency code to view existing reports';
    } else {
      return null;
    }
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h5" gutterBottom>
          Upcoming Renewal Reports
        </Typography>
        <Typography color="textSecondary">For policies transitioning from CLMSS to MSC+</Typography>
      </Box>
      <Box display="flex" p={1}>
        {!NO_DOCUMENTS_FOR_AGENCIES && (
          <Box mr={3} sx={{ minWidth: '346px' }}>
            <CLSRenewalsFilter
              formData={formData}
              handleAutocompleteAgencyOnChange={handleAutocompleteAgencyOnChange}
              handleCheckboxAgencyOnChange={handleCheckboxAgencyOnChange}
              handleMonthOnChange={handleMonthOnChange}
              handleResetDocuments={handleResetDocuments}
              handleSetDocuments={handleSetDocuments}
              initializeCheckboxes={initializeCheckboxes}
            />
          </Box>
        )}

        <Box sx={{ width: '100%' }}>
          <CLSRenewalsResults
            results={documents}
            filterType={formData?.month}
            displayMessage={generateDisplayString()}
          />
        </Box>
      </Box>
    </>
  );
};

export default CLSRenewals;
