import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '../AORedesign';
import agentContext from '../../contexts/agent.context';
import AppDataContext from '../../contexts/appData.context';
import {
  GET_ALL_AGENTS,
  GET_CL_RENEWALS_DOCUMENTS,
  GET_CL_RENEWALS_LOCATION_THRESHOLD,
} from '../../constants/api';
import featureFlags from '../../constants/featureFlags';

const LINE_OF_BUSINESS = 'commercial-lines';
export const CL_RENEWAL_AGENCY_CODE = 'agencyCode';
export const CL_RENEWAL_MONTH = 'month';
export const CURRENT_MONTH_RADIO_VALUE = 'current';
export const PREVIOUS_MONTHS_RADIO_VALUE = 'previous';

const CLSRenewalsFilters = ({
  formData,
  handleAutocompleteAgencyOnChange,
  handleCheckboxAgencyOnChange,
  handleMonthOnChange,
  handleResetDocuments,
  handleSetDocuments,
  initializeCheckboxes,
}) => {
  const [agencyCodeDisplayLimit, setAgencyCodeDisplayLimit] = useState(0);
  const [agencyCodes, setAgencyCodes] = useState([]);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const { Locations, FeatureFlags } = useContext(agentContext);
  const { setShowServiceUnavailable } = useContext(AppDataContext);

  const AGENCY_CODE_DISPLAY_THRESHOLD = agencyCodes.length <= agencyCodeDisplayLimit;
  const CHECKED_RENEWAL_MONTH = formData?.[CL_RENEWAL_MONTH];
  const CHECKED_AGENCY_CODE = formData?.[CL_RENEWAL_AGENCY_CODE];

  useEffect(() => {
    // Get display agency code display limit
    (async () => {
      try {
        // get location threshold (use to show checkboxes or autocomplete)
        const { data: limit } = await axios.get(GET_CL_RENEWALS_LOCATION_THRESHOLD);
        const { threshold } = limit;
        setAgencyCodeDisplayLimit(threshold);

        // Internal users agency codes list comes from common web service call
        if (FeatureFlags?.[featureFlags.ACCESS_USER_INTERNAL]) {
          setLoading(true);
          const { data: agencyCodes } = await axios.get(`${GET_ALL_AGENTS}/${LINE_OF_BUSINESS}`);
          setAgencyCodes(
            agencyCodes.Results.filter((agency) => agency.agencyCode).map(
              (agency) => agency.agencyCode,
            ),
          );
          setLoading(false);
        }
        // Agent users agency codes list comes from Location property returned in session call
        else if (FeatureFlags?.[featureFlags.ACCESS_USER_IS_AGENT]) {
          if (Locations.length === 0) {
            handleSetDocuments([]);
            initializeCheckboxes(null);
          } else if (Locations.length <= threshold) {
            const documents = await fetchDocuments(Locations);
            handleSetDocuments(documents, CURRENT_MONTH_RADIO_VALUE);

            const locationCodes = {};
            for (const doc of documents) {
              locationCodes[doc.locationCode] = true;
            }
            initializeCheckboxes(locationCodes);
            const filteredLocations = Locations.filter((location) => locationCodes[location]);
            setAgencyCodes(filteredLocations);
            // set documents to empty if no documents are available for a user's agency codes
            if (filteredLocations.length === 0) {
              handleSetDocuments([]);
              initializeCheckboxes(null);
            }
          } else {
            setAgencyCodes(Locations);
          }
        }
      } catch (error) {
        console.error(error);
        setShowServiceUnavailable(true);
      }
    })();
  }, []);

  const fetchDocuments = async (agencyCodes) => {
    if (agencyCodes.length > 0) {
      try {
        const agencyCodesQueryString = agencyCodes.map((code) => `location=${code}`).join('&');
        const { data } = await axios.get(`${GET_CL_RENEWALS_DOCUMENTS}?${agencyCodesQueryString}`);
        return data;
      } catch (error) {
        console.error(error);
        setShowServiceUnavailable(true);
        return null;
      }
    }
  };

  const renderAutocomplete = () => (
    <Autocomplete
      options={agencyCodes}
      freeSolo={false}
      inputValue={autocompleteInputValue}
      renderInput={(params) => <TextField {...params} label="Agency Code" />}
      value={Object.keys(formData?.[CL_RENEWAL_AGENCY_CODE])[0] ?? null}
      onChange={async (e, newValue) => {
        if (newValue) {
          const documents = await fetchDocuments([newValue]);
          handleSetDocuments(documents, formData[CL_RENEWAL_MONTH]);
        }
        handleAutocompleteAgencyOnChange(e, newValue);
      }}
      onInputChange={(e, newValue) => setAutocompleteInputValue(newValue)}
      disabled={loading}
      filterOptions={createFilterOptions({
        matchFrom: 'any',
        limit: 100,
      })}
    />
  );

  const handleSelectDeselectAllOnClick = () => {
    const selectAll = Object.values(CHECKED_AGENCY_CODE).includes(false);
    const newCheckboxState = agencyCodes.reduce((acc, curr) => ((acc[curr] = selectAll), acc), {});
    initializeCheckboxes(newCheckboxState);
    handleResetDocuments(selectAll);
  };

  const handleResetOnClick = () => {
    handleSetDocuments(null);
    handleMonthOnChange({ target: { name: CL_RENEWAL_MONTH, value: CURRENT_MONTH_RADIO_VALUE } });
    handleAutocompleteAgencyOnChange(undefined, null);
    setAutocompleteInputValue('');
  };

  return (
    <Paper p={0}>
      <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Filter Reports</Typography>
        {AGENCY_CODE_DISPLAY_THRESHOLD && FeatureFlags?.[featureFlags.ACCESS_USER_IS_AGENT] ? (
          agencyCodes.length > 0 && (
            <Button color="secondary" onClick={handleSelectDeselectAllOnClick} variant="text">
              {`${Object.values(CHECKED_AGENCY_CODE).includes(false) ? '' : 'De'}`}select All
            </Button>
          )
        ) : (
          <Button color="secondary" onClick={handleResetOnClick} variant="text">
            Reset
          </Button>
        )}
      </Box>
      <Divider />
      <Box p={2} pt={3}>
        <Box mb={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Month</FormLabel>
            <RadioGroup
              aria-label="commercial lines renewal document month period"
              name={CL_RENEWAL_MONTH}
              value={CHECKED_RENEWAL_MONTH}
              onChange={handleMonthOnChange}>
              <Radio label="Current Month" value={CURRENT_MONTH_RADIO_VALUE} />
              <Radio label="Previous 5 Months" value={PREVIOUS_MONTHS_RADIO_VALUE} />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          {AGENCY_CODE_DISPLAY_THRESHOLD && FeatureFlags?.[featureFlags.ACCESS_USER_IS_AGENT]
            ? agencyCodes.length > 0 && (
                <FormControl component="fieldset">
                  <FormLabel component="legend">Agency Code</FormLabel>
                  <FormGroup>
                    {agencyCodes.map((agency) => (
                      <FormControlLabel
                        key={agency}
                        control={
                          <Checkbox
                            checked={CHECKED_AGENCY_CODE[agency] ?? false}
                            onChange={handleCheckboxAgencyOnChange}
                            name={agency}
                          />
                        }
                        label={agency}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              )
            : renderAutocomplete()}
        </Box>
      </Box>
    </Paper>
  );
};

CLSRenewalsFilters.propTypes = {
  formData: PropTypes.object,
  handleAutocompleteAgencyOnChange: PropTypes.func,
  handleCheckboxAgencyOnChange: PropTypes.func,
  handleMonthOnChange: PropTypes.func,
  handleResetDocuments: PropTypes.func,
  handleSetDocuments: PropTypes.func,
  initializeCheckboxes: PropTypes.func,
};

export default CLSRenewalsFilters;
