import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import PaymentIcon from '@material-ui/icons/Payment';
import { alpha } from '@material-ui/core/styles/colorManipulator';
const useStyles = makeStyles((theme) => ({
  expiringChip: {
    borderRadius: '8px',
    backgroundColor: alpha(theme.palette.aquamarine[50], 0.3),
    fontSize: theme.typography.body2,
    color: theme.palette.aquamarine[700],
  },

  expiringChipIcon: {
    color: theme.palette.aquamarine[700],
  },
  overdueChip: {
    borderRadius: '8px',
    backgroundColor: theme.palette.orange[50],
    color: theme.palette.orange[900],
    fontSize: theme.typography.body2,
  },
  overdueChipIcon: {
    color: theme.palette.orange[900],
  },
}));
const ClientChips = ({ chipType }) => {
  const classes = useStyles();
  const chip = () => {
    if (chipType.toLowerCase() === 'expiring') {
      return (
        <>
          <Chip className={classes.expiringChip} size="medium" label="Expiring Policies" icon={<SyncProblemIcon className={classes.expiringChipIcon} />} />
        </>
      );
    } else if (chipType.toLowerCase() === 'overdue') {
      return (
        <>
          <Chip className={classes.overdueChip} size="medium" label="Overdue Payment" icon={<PaymentIcon className={classes.overdueChipIcon} />} />
        </>
      );
    }
  };
  return <>{chip()}</>;
};

ClientChips.propTypes = {
  chipType: PropTypes.string,
};
export default ClientChips;
