import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Avatar, Box, Card, CardContent, Typography } from 'components/AORedesign';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 0,
    boxSizing: 'border-box',
    height: '160px',
  },
  title: { color: theme.palette.common.white },
  iconContainer: { height: '75px', width: '77px', backgroundColor: theme.palette.primary.dark },
}));
const CardButton = ({ icon, title, route }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoToRoute = () => {
    history.push(route);
  };

  return (
    <Card className={classes.root} onClick={handleGoToRoute}>
      <CardContent className={classes.content}>
        <Box>
          <Avatar className={classes.iconContainer}>{icon}</Avatar>
        </Box>
        <Box>
          <Typography variant={'h5'} className={classes.title}>
            {title}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

CardButton.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  route: PropTypes.string,
};

export default CardButton;
