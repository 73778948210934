import React from 'react';
import PropTypes from 'prop-types';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadio from '@material-ui/core/Radio';

import { componentIdStringGenerator } from '../../../helpers';

const Radio = (props) => (
  <MuiFormControlLabel
    control={<MuiRadio />}
    {...props}
    id={componentIdStringGenerator('Radio-Option', props.id ?? props.label)}
  />
);

Radio.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};

export default Radio;
