import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Box, Divider, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import cuid from 'cuid';

import { Feature } from '../Feature';
import featureFlags from '../../constants/featureFlags';
import UserAdminViewContainer from './UserAdminViewContainer';
import { AOCard } from '../AOCard';
import {
  USER_ADMIN_ALL_GROUPS,
  USER_ADMIN_GROUP_MEMBERS_DETAILS,
  ADMIN_MANAGE_RESOURCES,
  USER_ADMIN_GET_PERMISSION,
} from '../../constants/api';
import { AOButton } from '../AOButton';
import { AODataGrid } from '../AODataGrid';
import Row from '../ScreenSmith/Row';
import Col from '../ScreenSmith/Col';

const DEFAULT_PAGE_SIZE = 25;

const groupMembersDetailsColumns = [
  {
    field: 'index',
    width: 75,
  },
  {
    field: 'cn',
    headerName: 'User Name',
    width: 250,
  },
  {
    field: 'givenName',
    headerName: 'First Name',
    width: 250,
  },
  {
    field: 'sn',
    headerName: 'Last Name',
    width: 250,
  },
];

const screenMappings = {
  start: 'selectionMembership',
  permissions: 'permissions',
};

const UserAdminGroupInformation = () => {
  const [currentScreen, setCurrentScreen] = useState(screenMappings.start);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [selectedTreeItem, setSelectedTreeItem] = useState('');
  const [expandedTreeItems, setExpandedTreeItems] = useState([]);
  const [nodeTreeItems, setNodeTreeItems] = useState([]);
  const [permissionDetails, setPermissionDetails] = useState('');
  const [allGroups, setAllGroups] = useState([]);
  const [searchButtonLoading, setSearchButtonLoading] = useState(true);
  const [groupMembers, setGroupMembers] = useState(null);

  const nodeTreeRef = useRef({});

  useEffect(async () => {
    try {
      const { data: allGroupsData } = await axios.get(USER_ADMIN_ALL_GROUPS);
      setAllGroups(allGroupsData);

      const { data: manageResourcesData } = await axios.get(ADMIN_MANAGE_RESOURCES);
      const manageResourcesDataCopy = structuredClone(manageResourcesData);
      const traverseNodes = () => {
        const ids = [];
        for (const tree of manageResourcesDataCopy) {
          const queue = [tree];
          while (queue.length) {
            const item = queue.shift();
            const nodeId = cuid();
            item.id = nodeId;
            nodeTreeRef.current[nodeId] = item.xpath;
            ids.push(nodeId);
            if (item.children) {
              queue.push(...item.children);
            }
          }
        }

        return ids;
      };

      const expandedItems = traverseNodes();
      setExpandedTreeItems(expandedItems);
      setNodeTreeItems(manageResourcesDataCopy);
    } catch (error) {
      console.error(error);
    } finally {
      setSearchButtonLoading(false);
    }
  }, []);

  const handleSearchOnClick = async () => {
    setSearchButtonLoading(true);
    setPageSize(DEFAULT_PAGE_SIZE);
    setGroupMembers(null);
    try {
      const { data } = await axios.get(
        `${USER_ADMIN_GROUP_MEMBERS_DETAILS}?groupName=${selectedGroup}`,
      );
      setGroupMembers(data);
    } catch (error) {
      console.error(error);
    }
    setSearchButtonLoading(false);
  };

  const handleOnNodeSelect = async (event, nodeId) => {
    setPermissionDetails('Loading...');
    setSelectedTreeItem(nodeId);
    if (nodeId !== selectedTreeItem) {
      try {
        const { data } = await axios.get(
          `${USER_ADMIN_GET_PERMISSION}?memberID=${selectedGroup}&memberType=G&resources=${nodeTreeRef.current[nodeId]}`,
        );
        setPermissionDetails(data.join(', '));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleGoBack = () => {
    setPermissionDetails('');
    setSelectedTreeItem('');
    setCurrentScreen(screenMappings.start);
  };

  const renderTree = (nodes) => {
    return nodes.map((node) => {
      return (
        <TreeItem key={node.id} nodeId={node.id} label={node.resourceName}>
          {node.children && renderTree(node.children)}
        </TreeItem>
      );
    });
  };

  const renderScreen = () => {
    if (currentScreen === screenMappings.start) {
      return (
        <>
          <AOCard cardTitle="Group Selection" variant="outlined">
            <Box mb={2} sx={{ width: 350 }}>
              <Autocomplete
                options={allGroups}
                id="group-information-select-all-groups"
                value={selectedGroup}
                loading={searchButtonLoading}
                onChange={(event, newValue) => {
                  setGroupMembers(null);
                  setSelectedGroup(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Group Name" variant="outlined" />
                )}
              />
            </Box>

            <Box>
              <AOButton
                color="primary"
                disabled={!selectedGroup || searchButtonLoading}
                id="group-information-button-search"
                loading={searchButtonLoading}
                onClick={handleSearchOnClick}
                variant="contained">
                Search
              </AOButton>
            </Box>
          </AOCard>

          {groupMembers && (
            <Box mt={6}>
              <AOCard cardTitle="Group Membership List" variant="outlined">
                <Box display={'flex'} justifyContent="flex-end" mb={2} mt={-7}>
                  <AOButton
                    color="secondary"
                    disabled={searchButtonLoading}
                    onClick={() => setCurrentScreen(screenMappings.permissions)}>
                    View Group Permissions
                  </AOButton>
                </Box>
                <AODataGrid
                  rows={groupMembers.map((member, index) => ({
                    ...member,
                    id: member.cn,
                    index: index + 1,
                  }))}
                  columns={groupMembersDetailsColumns}
                  autoHeight
                  onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                  pageSize={pageSize}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                  noResultsMessage="No Group Members for selected group."
                />
              </AOCard>
            </Box>
          )}
        </>
      );
    } else if (screenMappings.permissions) {
      return (
        <>
          <Box mb={2} position="relative">
            <Box position="absolute" top="-3.25rem">
              <AOButton startIcon="ArrowBack" onClick={handleGoBack} color="secondary">
                Back to Groups
              </AOButton>
            </Box>
          </Box>
          <AOCard cardTitle={`${selectedGroup} Group Permissions`} variant="outlined">
            <Row spacing={2}>
              <Col xs={6}>
                <AOCard cardTitle="Select a Resource" variant="outlined">
                  <Box mb={2}>
                    <Divider />
                  </Box>
                  <TreeView
                    expanded={expandedTreeItems}
                    selected={selectedTreeItem}
                    onNodeSelect={handleOnNodeSelect}>
                    {renderTree(nodeTreeItems)}
                  </TreeView>
                </AOCard>
              </Col>
              <Col xs={6}>
                <AOCard cardTitle="Permissions Associated with Resource" variant="outlined">
                  <Box mb={2}>
                    <Divider />
                  </Box>
                  {selectedTreeItem && (
                    <>
                      <Box mb={3}>
                        <Typography component="div">
                          <Box fontWeight={'bold'}>Resource</Box>
                        </Typography>
                        <Typography color="textSecondary">
                          {nodeTreeRef.current[selectedTreeItem]}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography component="div">
                          <Box fontWeight={'bold'}>Permissions</Box>
                        </Typography>
                        <Typography color="textSecondary">{permissionDetails}</Typography>
                      </Box>
                    </>
                  )}
                </AOCard>
              </Col>
            </Row>
          </AOCard>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Feature allowed={featureFlags.ACCESS_USER_ADMIN}>
      <UserAdminViewContainer
        title={currentScreen === screenMappings.start ? 'Groups' : 'Group Permissions'}>
        {renderScreen()}
      </UserAdminViewContainer>
    </Feature>
  );
};

export default UserAdminGroupInformation;
