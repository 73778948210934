import { GET_REPORTS_URL } from 'constants/api';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';

const defaultYearMonthOption = { label: 'Prior Year End', value: 1 };

const handleAOAutocompleteOnChange = (property, e, value, formData, setFormData, generateReportFormDataRef) => {
  setFormData({ ...formData, [property]: value });
  generateReportFormDataRef.current[property] = value.code;
};

const handleAOAutocompleteOnInputChange = (property, e, value, autocompleteInputValues, setAutocompleteInputValues, generateReportFormDataRef) => {
  setAutocompleteInputValues({
    ...autocompleteInputValues,
    [property]: {
      ...autocompleteInputValues[property],
      value,
    },
  });
  generateReportFormDataRef.current[property] = value;
};

const handleAOAutocompleteOnBlur = (property, formData, setFormData, autocompleteInputValues) => {
  if (formData[property] !== autocompleteInputValues[property].value) {
    setFormData({ ...formData, [property]: autocompleteInputValues[property].value });
  }
};

const handleOnChange = (e, formData, setFormData, generateReportFormDataRef) => {
  const target = e.target;
  const value = target.value;
  const name = target.name;
  setFormData({ ...formData, [name]: value });
  generateReportFormDataRef.current[name] = value;
};

const getGenerateReportRequestBody = (DetailedContext) => {
  let requestBody = {
    Area: 'ape',
    DetailedContext,
    TypeOfRedirect: 'Generate-PDF',
  };
  return requestBody;
};

const setReportIdDetailedContext = (current) => {
  let detailedContext = [];
  for (const key in current) {
    let map = {};
    if (key === 'locationNumber') {
      const firstChar = current[key][0];
      let keyName = firstChar === 'c' ? 'customerNumber' : 'locationNumber';
      map.name = keyName;
      map.value = current[key].substring(1);
    } else {
      map.name = key;
      map.value = current[key];
    }
    detailedContext.push(map);
  }
  return detailedContext;
};

const setDetailedContext = (current) => {
  let detailedContext = [];
  for (const key in current) {
    let map = {};
    map.name = key;
    map.value = current[key];
    detailedContext.push(map);
  }
  return detailedContext;
};

const handleGenerateReportOnClick = (generateReportFormDataRef, setErrorMessage, setLoadingPdf, setIsDownloadingPdfErrorVisible, permAgenciesOptions) => {
  if (generateReportFormDataRef.current.customerNumber?.trim()?.length && generateReportFormDataRef.current.locationNumber?.trim()?.length) {
    setErrorMessage('Cannot select both agency types, please select only a customer or location agency');
    return;
  } else if (!generateReportFormDataRef.current.customerNumber?.trim()?.length && !generateReportFormDataRef.current.locationNumber?.trim()?.length) {
    setErrorMessage('Please Select either a customer or location agency');
    return;
  }
  try {
    const detailedContext = permAgenciesOptions.length
      ? setReportIdDetailedContext(generateReportFormDataRef.current)
      : setDetailedContext(generateReportFormDataRef.current);
    let reportRequestBody = getGenerateReportRequestBody(detailedContext);
    const reportsGenerateUrl = GET_REPORTS_URL;
    handlePdfDownload('POST', reportRequestBody, reportsGenerateUrl, setLoadingPdf, setErrorMessage, setIsDownloadingPdfErrorVisible, 'newWindow');
  } catch (error) {
    console.error(error);
  }
};

export {
  defaultYearMonthOption,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
  handleOnChange,
  handleGenerateReportOnClick,
};
