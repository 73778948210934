import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

const AOTypography = ({ text, bold, inline, ...otherProps }) => {
  return (
    <Box px={inline ? 1 : 'inherit'} display="inline">
      <Typography {...otherProps} style={{ fontWeight: bold ? '800' : 'inherit' }}>
        {text}
      </Typography>
    </Box>
  );
};

AOTypography.propTypes = {
  text: PropTypes.string,
  bold: PropTypes.bool,
  inline: PropTypes.bool,
};

export default AOTypography;
