import React from 'react';

import { Box, Link, WorkflowViewContainer } from 'components/AORedesign';
import { UserAdminViewChangeHistory } from 'components/AORedesignViews/UserAdmin';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';

const UserAdminViewChangeHistoryLink = () => {
  const TriggerButton = (props) => <Link {...props}>View Change History</Link>;

  return (
    <Feature allowed={featureFlags.ACCESS_USER_ADMIN}>
      <WorkflowViewContainer appBarTitle={'View Change History'} TriggerButton={TriggerButton} block>
        <Box mx={9} my={5}>
          <UserAdminViewChangeHistory />
        </Box>
      </WorkflowViewContainer>
    </Feature>
  );
};

export default UserAdminViewChangeHistoryLink;
