import React from 'react';
import axios from 'axios';
import cuid from 'cuid';
import moment from 'moment';
import _ from 'lodash';

import { AOBox } from '../../../AOBox';

import { POST_BILLING_PAYMENT_ADJUST_PAYMENT } from '../../../../constants/api';

const renderCellAmountPaid = (params) => {
  const { formattedValue } = params;
  return <AOBox currency text={formattedValue} />;
};

const renderCellDatePaid = (params) => {
  const { formattedValue } = params;
  const formattedDate = moment(formattedValue).format('MM/DD/YYYY');
  return <AOBox text={formattedDate} />;
};

const adjustPaymentCols = [
  {
    field: 'accountNumber',
    headerName: 'Account #',
    sortable: true,
    flex: 1,
  },
  {
    field: 'accountName',
    headerName: 'Account Name',
    sortable: true,
    flex: 2,
  },
  {
    field: 'address1',
    headerName: 'Street Address',
    sortable: true,
    flex: 2,
  },
  {
    field: 'originalAmount',
    headerName: 'Amount Paid',
    sortable: true,
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    renderCell: renderCellAmountPaid,
  },
  {
    field: 'payDateCreated',
    headerName: 'Date Paid',
    sortable: true,
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    renderCell: renderCellDatePaid,
  },
];

const fetchCurrentPayments = async (_setPaymentListData, url) => {
  _setPaymentListData((prevValue) => ({ ...prevValue, loading: true }));
  try {
    const { data } = await axios.get(`${url}`);
    const currentPayments = data.map((el) => ({ id: cuid(), ...el }));
    _setPaymentListData((prevValue) => ({
      ...prevValue,
      loading: false,
      paymentList: currentPayments,
    }));
  } catch (error) {
    _setPaymentListData((prevValue) => ({ ...prevValue, loading: false }));
    _setPaymentListData((prevValue) => ({
      ...prevValue,
      alert: { ...prevValue.alert, message: error.message },
    }));
  }
};

const getSelectedRowData = (_selectionSet, _paymentList) => {
  // const selectedRow = _paymentList.filter((row) => _selectionSet.has(row.id.toString()));
  const selectedRow = _.filter(_paymentList, { id: _selectionSet[0] });
  return selectedRow[0];
};

const onSelectionModelChange = (
  _paymentList,
  _selection,
  _selectionModel,
  _setSelectionModel,
  _setPaymentListData,
) => {
  const newSelectionModel = _selection;
  let selectedRow;

  if (newSelectionModel.length > 1) {
    const selectionSet = new Set(_selectionModel);
    const result = newSelectionModel.filter((s) => !selectionSet.has(s));
    selectedRow = getSelectedRowData(result, _paymentList);
    _setSelectionModel(result);
    _setPaymentListData((prevValue) => ({
      ...prevValue,
      alert: { ...prevValue.alert },
      selectedRow: selectedRow,
      adjustedPaymentData: {
        ...prevValue.adjustedPaymentData,
        paymentAmountCorrection: selectedRow.originalAmount,
      },
    }));
  } else if (newSelectionModel.length === 0) {
    _setPaymentListData((prevValue) => ({
      ...prevValue,
      alert: { ...prevValue.alert },
      selectedRow: {},
      adjustedPaymentData: {
        paymentAmountCorrection: null,
      },
    }));

    _setSelectionModel([]);
  } else {
    selectedRow = getSelectedRowData(newSelectionModel, _paymentList);
    _setPaymentListData((prevValue) => ({
      ...prevValue,
      alert: { ...prevValue.alert },
      selectedRow: selectedRow,
      adjustedPaymentData: {
        ...prevValue.adjustedPaymentData,
        paymentAmountCorrection: selectedRow.originalAmount,
      },
    }));

    _setSelectionModel(newSelectionModel);
  }
};

const handleOnClickEnterCorrectPayment = (
  _paymentListData,
  _setPaymentListData,
  _screenMappings,
  _setCurrentScreen,
) => {
  const { selectedRow } = _paymentListData;

  if (_.isEmpty(selectedRow)) {
    _setPaymentListData((prevValue) => ({
      ...prevValue,
      adjustedPaymentData: {
        ...prevValue.adjustedPamentData,
      },
      alert: {
        message: 'Please make a selection to adjust a payment',
        severity: 'warning',
        isVisible: true,
      },
    }));
  } else {
    _setPaymentListData((prevValue) => ({
      ...prevValue,
      alertAdjustPayment: {
        isVisible: false,
      },
    }));
    _setCurrentScreen(_screenMappings.enterCorrectPayment);
  }
};

const handleOnChangePayAmountCorrection = (e, _setPaymentListData) => {
  const decimal = parseFloat(e.target.value).toFixed(2);
  _setPaymentListData((prevValue) => ({
    ...prevValue,
    alert: { ...prevValue.alert },
    adjustedPaymentData: {
      ...prevValue.adjustedPaymentData,
      paymentAmountCorrection: decimal,
    },
  }));
};

const sendPostAdjustPayment = async (_payload) => {
  let post;
  try {
    post = await axios.post(POST_BILLING_PAYMENT_ADJUST_PAYMENT, { ..._payload });
  } catch (error) {
    post = error;
  }
  return post;
};

const handleClickSubmitAdjustPayment = (
  _paymentList,
  _setPaymentListData,
  _screenMappings,
  _setCurrentScreen,
) => {
  const {
    selectedRow,
    adjustedPaymentData: { paymentAmountCorrection },
  } = _paymentList;

  // remove id property as api does not accept id
  const selectedRowNoId = _.omit(selectedRow, ['id']);

  const payload = {
    ...selectedRowNoId,
    originalAmount: selectedRow.originalAmount,
    paymentAmount: paymentAmountCorrection,
  };

  sendPostAdjustPayment(payload)
    .then((response) => {
      // update state object
      _setPaymentListData((prevValue) => ({
        ...prevValue,
        alert: { ...prevValue.alert },
        adjustedPaymentData: { ...prevValue.adjustedPaymentData },
        acknowledgedSbmission: response,
      }));

      // update screen def
      _setCurrentScreen(_screenMappings.paymentConfirmation);
    })
    .catch((error) => {
      console.error(error);
    });
};

const handleClickGoToAdjustPayment = (
  _setPaymentListData,
  _screenMappings,
  _setCurrentScreen,
  _setSelectionModel,
) => {
  _setSelectionModel([]);
  _setCurrentScreen(_screenMappings.start);
  _setPaymentListData((prevValue) => ({
    ...prevValue,
    loading: false,
    selectedRow: {},
    alert: {
      message: null,
      severity: 'error',
      isVisible: false,
    },
    adjustedPaymentData: {
      paymentAmountCorrection: null,
    },
    acknowledgedSubmission: {},
  }));
};

const handleOnClickAdjustPayment = (
  _paymentListData,
  _setPaymentListData,
  _screenMappings,
  _setCurrentScreen,
) => {
  const {
    selectedRow: { originalAmount },
    adjustedPaymentData: { paymentAmountCorrection },
  } = _paymentListData;

  if (_.toNumber(originalAmount) === _.toNumber(paymentAmountCorrection)) {
    _setPaymentListData((prevValue) => ({
      ...prevValue,
      alertAdjustPayment: {
        isVisible: true,
      },
    }));
  } else {
    _setCurrentScreen(_screenMappings.paymentValidation);
  }
};

export {
  adjustPaymentCols,
  fetchCurrentPayments,
  handleOnClickEnterCorrectPayment,
  onSelectionModelChange,
  handleOnChangePayAmountCorrection,
  handleClickSubmitAdjustPayment,
  handleClickGoToAdjustPayment,
  handleOnClickAdjustPayment,
};
