import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import { RowCol } from '../../ScreenSmith';
import { AOIconButton } from '../../AOIconButton';
import { AOSelect } from '../../AOSelect';

const MAX_ORDER_QUANTITY = 25;

const DocumentRow = ({
  docName,
  docId,
  handleDeleteDoc,
  error,
  helperText,
  inputRef,
  ...props
}) => {
  return (
    <>
      <RowCol mb={2}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography>{docName}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box ml={2} />
            <AOSelect
              {...props}
              inputRef={inputRef}
              id={docId + '-quantity-select'}
              labelId={docId + '-quantity-select-label'}
              inputLabel="Quantity"
              name={docId}
              error={error}
              invalidSearch={helperText}
              menuItems={Array.from(Array(MAX_ORDER_QUANTITY)).map((_, index) => ({
                value: index + 1,
                code: index + 1,
              }))}
              size="small"
            />
            <Box mr={1} />
            <AOIconButton
              id={docId + '-document-delete-button'}
              icon={'DeleteOutline'}
              onClick={() => handleDeleteDoc(docId)}
              color="secondary"
            />
          </Box>
        </Box>
      </RowCol>
    </>
  );
};

DocumentRow.propTypes = {
  docName: PropTypes.string,
  docId: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  error: PropTypes.bool,
  handleDeleteDoc: PropTypes.func,
  helperText: PropTypes.string,
};

export default DocumentRow;
