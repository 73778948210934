import React, { useState, useEffect } from 'react';
import CCReportAgentView from './CCReportAgentView';
import CCReportInternalUserView from './CCReportInternalUserView';

import useFetchSelectMenuItems from 'hooks/useFetchSelectMenuItems';
import { handleCreateOnClick, handleOnChange, resetAgencyCode } from './CCReport.api';
import { GET_CC_REPORT_PERIODS } from 'constants/api';
import {
  fetchAgencyNameSuggestions,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnBlur,
} from 'components/CommonUtilityServices/EventHandlerService';
import { useDebounce } from 'hooks/useDebounce';
import { Box, Button, Card, CardContent, CardHeader, Select, Typography, ViewContainer } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';

const CCReport = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [pdfLoading, setLoadingPdf] = useState(false);

  const [periodOptions, setPeriodOptions] = useState([]);
  const [permAgenciesOptions, setPermAgenciesOptions] = useState([]);
  const [agencyNameOptions, setAgencyNameOptions] = useState([]);
  const [searchByType, setSearchByType] = useState('name');

  const [formData, setFormData] = useState({ period: '', agencyCode: '' });
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    agencyCode: { value: '' },
  });

  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);

  useFetchSelectMenuItems(GET_CC_REPORT_PERIODS, (response) => {
    if (response.data.length) {
      const periods = response.data;
      setPeriodOptions(periods.map((period) => ({ label: period.periodDesc, value: period.periodValue })));
      setFormData({ ...formData, period: periods[0]?.periodValue });
    }
  });

  useEffect(async () => {
    if (autocompleteInputValues.agencyCode.value.length >= 3) {
      const suggestions = await fetchAgencyNameSuggestions(autocompleteInputValues.agencyCode.value);
      setAgencyNameOptions(suggestions);
    } else {
      setAgencyNameOptions([]);
    }
  }, [debouncedState.agencyCode]);

  const renderCreateButtonDisabled = () => {
    // button is in loading state
    if (pdfLoading) {
      return true;
    }
    // external user/agent logic
    else if (permAgenciesOptions.length && !formData.agencyCode) {
      return true;
    }
    // internal user logic for search by name
    else if (!permAgenciesOptions.length && searchByType === 'name' && autocompleteInputValues?.agencyCode?.value?.length < 3) {
      return true;
    }
    // internal user logic for search by code
    else if (!permAgenciesOptions.length && searchByType === 'code' && formData?.agencyCode?.length !== 6) {
      return true;
    }

    return false;
  };

  const handleOnChange_PROXY = (e) => handleOnChange(e, formData, setFormData, setErrorMessage);
  const handleCreateOnClick_PROXY = () => handleCreateOnClick(formData, setLoadingPdf, setErrorMessage);
  const handleAgencyNameOnInputChange = (e, value) =>
    handleAOAutocompleteOnInputChange('agencyCode', e, value, autocompleteInputValues, setAutocompleteInputValues, debouncedState, setDebouncedState);
  const handleAgencyNameOnChange = (e, value) => handleAOAutocompleteOnChange('agencyCode', e, value, formData, setFormData);
  const handleAgencyNameOnBlur = () => handleAOAutocompleteOnBlur('agencyCode', formData, setFormData, autocompleteInputValues);
  const resetAgencyCode_PROXY = () => resetAgencyCode(setFormData, setAutocompleteInputValues);

  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
      <Feature allowed="Access_MvrChargeback_Report">
        <Card>
          <CardHeader title="Chargeback Calculation Report" />
          <CardContent>
            {errorMessage && (
              <Box mb={2}>
                <Typography color="error">{errorMessage}</Typography>
              </Box>
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCreateOnClick_PROXY();
              }}>
              <Box mb={2}>
                <Select
                  onChange={handleOnChange_PROXY}
                  value={formData.period}
                  menuItems={periodOptions}
                  id="cc-period"
                  labelId="cc-period-label"
                  label="Period"
                  name="period"
                />
              </Box>
              <Feature allowed="isAgent">
                <CCReportAgentView
                  onChange={handleOnChange_PROXY}
                  setPermAgenciesOptions={setPermAgenciesOptions}
                  menuItems={permAgenciesOptions}
                  value={formData.agencyCode}
                />
              </Feature>
              <Feature allowed="internalUser">
                <CCReportInternalUserView
                  resetAgencyCode={resetAgencyCode_PROXY}
                  onChange={handleOnChange_PROXY}
                  handleOnChange={handleAgencyNameOnChange}
                  handleOnInputChange={handleAgencyNameOnInputChange}
                  onBlur={handleAgencyNameOnBlur}
                  setSearchByType={setSearchByType}
                  value={formData.agencyCode}
                  inputValue={autocompleteInputValues.agencyCode.value}
                  searchByType={searchByType}
                  options={agencyNameOptions}
                />
              </Feature>
              <Box mb={2}>
                <Button onClick={handleCreateOnClick_PROXY} loading={pdfLoading} disabled={renderCreateButtonDisabled()} color="primary" variant="contained">
                  Generate Report
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Feature>
    </ViewContainer>
  );
};

export default CCReport;
