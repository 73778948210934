import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AODataGrid } from '../../../../AODataGrid';
import { PaymentManagementHistoryTransactionDetailModal } from '../PaymentManagementHistoryTransactionDetailModal';
import { getPaymentHistorybyDetailId } from './PaymentHistoryDataGrid.api';

const PaymentHistoryDataGrid = ({ rows, columns }) => {
  const [modalData, setModalData] = useState({
    loading: false,
    isOpen: false,
    historyDetails: {},
    alert: {
      active: false,
      severity: null,
      msg: null,
    },
  });

  /* Click Event to capture row data */
  const handleOnRowClick = async ({ row: { id } }) => {
    setModalData((prevValue) => ({ ...prevValue, loading: true, isOpen: true }));
    // Get ID from _params
    console.log('row id for detail api: ', id);

    try {
      const payload = await getPaymentHistorybyDetailId(id);
      setModalData((prevValue) => ({
        ...prevValue,
        loading: false,
        historyDetails: { ...payload },
      }));
    } catch (error) {
      console.log(error);
      setModalData((prevValue) => ({
        ...prevValue,
        loading: false,
        alert: { active: true, severity: 'error', msg: `Error: ${error}` },
      }));
    }

    // TODO: On success, pass data to modal component
  };

  /* Click Event to close modal */
  const handleClickCloseModal = () =>
    setModalData((prevValue) => ({ ...prevValue, isOpen: false }));
  return (
    <>
      <AODataGrid
        onRowClick={(params) => handleOnRowClick(params)}
        rows={rows}
        columns={columns}
        autoHeight
        rowsPerPageOptions={[25, 50, 75, 100]}
        noResultsMessage="No Results"
      />
      <PaymentManagementHistoryTransactionDetailModal
        open={modalData.isOpen}
        alertIsActive={modalData.alert.active}
        alertSeverity={modalData.alert.severity}
        alertMsg={modalData.alert.msg}
        loading={modalData.loading}
        handleClose={handleClickCloseModal}
        details={modalData.historyDetails}
      />
    </>
  );
};
PaymentHistoryDataGrid.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      width: PropTypes.number,
      hide: PropTypes.bool,
      flex: PropTypes.number,
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      transDate: PropTypes.string,
      userId: PropTypes.string,
      tranType: PropTypes.string,
      tranDesc: PropTypes.string,
      agency: PropTypes.string,
      policy: PropTypes.string,
      account: PropTypes.string,
      modifyAmount: PropTypes.number,
      origAmount: PropTypes.number,
    }),
  ),
};

export default PaymentHistoryDataGrid;
