import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import UserAdminViewContainer from './UserAdminViewContainer';
import { ScreenSmith } from '../ScreenSmith';
import UserAdminSkeleton from './UserAdminSkeleton';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import SearchUser from './views/SearchUser';

const LocalComponents = { SearchUser };

const UserAdminSearchUser = ({ handleSetUsername, hideReturn }) => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`UserAdmSearchUser`);

  const localStateMap = {};

  const callBackFunctions = { handleSetUsername };

  return loadingSd ? (
    <UserAdminSkeleton />
  ) : (
    <UserAdminViewContainer hideReturn={hideReturn}>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith definition={UiDefinitionsData} functionMap={callBackFunctions} stateMap={localStateMap} componentMap={LocalComponents} />
      )}
    </UserAdminViewContainer>
  );
};

UserAdminSearchUser.propTypes = {
  handleSetUsername: PropTypes.func,
  hideReturn: PropTypes.bool,
};

export default UserAdminSearchUser;
