import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { AOTypography } from '../../../AOTypography';

import { currencyStringFormatter } from '../../../../helpers';

const PaymentValidation = ({ paymentValidationData }) => {
  const { changeDate, originalAmount, adjustedAmount, accountNumber, accountName } =
    paymentValidationData;
  const formattedChangeDate = moment(changeDate).format('MM/DD/YYYY');
  const formattedOriginalAmount = currencyStringFormatter(originalAmount);
  const formattedAdjustedAmount = currencyStringFormatter(adjustedAmount);
  return (
    <Box>
      <Box mb={2} display="flex" flexWrap="wrap">
        <Typography>{`You have requested to change the`}</Typography>
        <AOTypography bold text={formattedChangeDate} inline />

        <Typography>{` payment of `}</Typography>
        <AOTypography bold text={formattedOriginalAmount} inline />

        <Typography>{` to `}</Typography>
        <AOTypography bold text={formattedAdjustedAmount} inline />
        <Typography>{`.`}</Typography>
      </Box>
      <Box mb={2} display="flex" flexWrap="wrap">
        <Typography>{`We will credit the insured’s account`}</Typography>
        <AOTypography bold text={accountNumber} inline />
        <Typography>{`and sweep your agency account for the difference. `}</Typography>
      </Box>
      <Box mb={2}>
        <Typography>{`Please review the payment information below. If everything is correct, use the Submit button to finalize your transaction. Otherwise, you may cancel the transaction using the appropiate button.`}</Typography>
      </Box>
      <Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Account Number:</Typography>
          </Box>
          <Typography color="textSecondary">{accountNumber}</Typography>
        </Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Account Name: </Typography>
          </Box>
          <Typography color="textSecondary">{accountName}</Typography>
        </Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>Old Amount: </Typography>
          </Box>
          <Typography color="textSecondary">{formattedOriginalAmount}</Typography>
        </Box>
        <Box display="flex" mb={2}>
          <Box style={{ minWidth: '150px' }}>
            <Typography>New Amount: </Typography>
          </Box>
          <Typography color="textSecondary">{formattedAdjustedAmount}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

PaymentValidation.propTypes = {
  paymentValidationData: PropTypes.shape({
    changeDate: PropTypes.string,
    originalAmount: PropTypes.string,
    adjustedAmount: PropTypes.string,
    accountNumber: PropTypes.string,
    accountName: PropTypes.string,
  }),
};

export default PaymentValidation;
