import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Box, Button, Card, CardActions, Divider, Link, Typography } from 'components/AORedesign';
import { useHistory } from 'react-router-dom';
import {
  ROUTE_REDESIGN_REPORTS_BILLING_TAR,
  ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_DUE,
  ROUTE_REDESIGN_REPORTS_AGENT_PLRREPORTS,
  ROUTE_REDESIGN_REPORTS,
} from 'constants/routes';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'center',
  },
}));

const QuickLinksReports = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoToRoute = (e) => {
    const route = e.currentTarget.getAttribute('data-route');
    history.push(route);
  };

  const handleViewAllReportsOnClick = () => history.push(ROUTE_REDESIGN_REPORTS);

  return (
    <Card style={{ height: '100%' }}>
      <Box m={-2}>
        <Box m={2}>
          <Typography variant="h6">Common Reports</Typography>
          <Box mt={3}>
            <Link data-route={ROUTE_REDESIGN_REPORTS_BILLING_TAR} onClick={handleGoToRoute}>
              Transaction Activity Report
            </Link>
          </Box>
          <Box mt={1}>
            <Link data-route={ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_DUE} onClick={handleGoToRoute}>
              Payment Due (10 Days or Less)
            </Link>
          </Box>
          <Box mt={1} mb={2}>
            <Link data-route={ROUTE_REDESIGN_REPORTS_AGENT_PLRREPORTS} onClick={handleGoToRoute}>
              Policy Loss Run
            </Link>
          </Box>
        </Box>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button color="secondary" variant="text" onClick={handleViewAllReportsOnClick}>
            View All Reports
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};

export default QuickLinksReports;
