import { makeStyles } from '@material-ui/core/styles';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import useAxios from 'axios-hooks';
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Badge, Box, Divider, Menu, MenuItem, Typography } from 'components/AORedesign';
import { handleFileDownload } from 'components/CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import NewsArticleModal from 'components/NewsArticles/NewsArticleModal';
import { GET_NEWS, NEWS_ARTICLES } from 'constants/api';
import { ANNOUNCEMENT_TYPES } from 'constants/static';
import { selectAnnouncements } from 'redux/app-store';

const useStyles = makeStyles(() => ({
  iconContainer: {
    cursor: 'pointer',
  },
  menu: { maxHeight: '80%', width: '500px' },
  menuText: { whiteSpace: 'normal' },
  menuContent: { width: '400px' },
}));

const BellAlerts = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  // state to show/hide dialog
  const [open, setOpen] = useState(false);

  const { announcements: alerts } = useSelector((state) => selectAnnouncements(state, [ANNOUNCEMENT_TYPES.ALERT_REGULAR, ANNOUNCEMENT_TYPES.ALERT_URGENT]));

  // axios hook that is manually executed with
  // executeNewsReview
  const [{ data: dataNewsReview, loading: loadingNewsReview, error: errorNewsReview }, executeNewsReview] = useAxios();

  const handleEventClick = (event) => {
    if (alerts.length > 0) setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuPopup = () => {
    setAnchorEl(null);
  };

  const handleOpenNewsItem = (id, ext) => {
    handleCloseMenuPopup();
    handleOpenDialog(id, ext);
  };

  // event handler when opening modal
  const handleOpenDialog = (id, ext) => {
    const trimmedExt = _.trim(ext); // remove whitespace
    if (_.isEmpty(trimmedExt)) {
      setOpen(true);
      executeNewsReview({
        url: `${GET_NEWS}${NEWS_ARTICLES}/${id}`,
      });
    } else {
      const fileURL = `${NEWS_ARTICLES}/${id}`;
      if (trimmedExt === 'pdf') {
        handlePdfDownload(
          'GET',
          null,
          fileURL,
          () => {},
          () => {},
          () => {},
          'newWindow',
          `${GET_NEWS}`,
        );
      } else {
        handleFileDownload(
          fileURL,
          () => {},
          () => {},
          () => {},
          'newWindow',
          'GET',
          undefined,
          `${GET_NEWS}`,
        );
      }
    }
  };

  // event handler to close modal
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      {alerts && (
        <Box p={0.5} className={alerts.length > 0 ? classes.iconContainer : ''} onClick={(event) => handleEventClick(event)}>
          {alerts.length > 0 && (
            <Badge
              color="error"
              variant="dot"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <NotificationsOutlinedIcon color="primary" />
            </Badge>
          )}
          {alerts.length < 1 && <NotificationsOutlinedIcon color="primary" />}
        </Box>
      )}
      <Menu
        id="bell-alerts"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenuPopup}
        className={classes.menu}
        anchorOrigin={{
          vertical: 55,
          horizontal: 'left',
        }}
        getContentAnchorEl={null}>
        <Box px={2} my={2}>
          <Typography variant="h6">Alerts</Typography>
        </Box>
        {alerts &&
          alerts.map((alert, index) => {
            return (
              <Box key={`${alert.seqId}-${index}`}>
                <Divider />
                <MenuItem onClick={() => handleOpenNewsItem(alert.seqId, alert.ext)} className={classes.menuText}>
                  <Box className={classes.menuContent} py={1}>
                    <Typography>{alert.title}</Typography>
                  </Box>
                </MenuItem>
              </Box>
            );
          })}
      </Menu>
      <NewsArticleModal open={open} handleClose={handleCloseModal} loading={loadingNewsReview} error={errorNewsReview} data={dataNewsReview} />
    </>
  );
};

export default BellAlerts;
