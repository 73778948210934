import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { RowCol } from '../ScreenSmith';
import { Visible } from '../Visible';
import { AOAlert } from '../AOAlert';

const AODocUploader = ({ label, acceptableFileTypes, invalidFileTypeMsg, setDocumentDetails }) => {
  const [errorAlert, setErrorAlert] = useState({
    isVisible: false,
    severity: 'error',
    message: invalidFileTypeMsg,
  });

  const handleChange = async (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
      const fileTypes = acceptableFileTypes.split(',').map((item) => item.trim().replace('.', ''));
      if (fileTypes.includes(ext)) {
        setErrorAlert({ ...errorAlert, isVisible: false });
        setDocumentDetails({
          extension: ext,
          docContent: await getBase64Data(file),
        });
      } else {
        console.log('extension -- ' + ext);
        setErrorAlert({ ...errorAlert, isVisible: true });
      }
    }
  };

  const getBase64Data = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        if (reader.result) {
          resolve(Buffer.from(reader.result, 'binary').toString('base64'));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <>
      <RowCol>
        <label htmlFor="news-doc-file" style={{ marginRight: 15 }}>
          {label}
        </label>
        <input
          id="news-doc-file"
          accept={acceptableFileTypes}
          type="file"
          onChange={handleChange}
        />
        <Visible isVisible={errorAlert.isVisible}>
          <RowCol mb={2}>
            <AOAlert
              isVisible={errorAlert.isVisible}
              severity={errorAlert.severity}
              message={errorAlert.message}
            />
          </RowCol>
        </Visible>
      </RowCol>
    </>
  );
};

AODocUploader.propTypes = {
  label: PropTypes.string,
  acceptableFileTypes: PropTypes.string,
  invalidFileTypeMsg: PropTypes.string,
  setDocumentDetails: PropTypes.func,
};

export default AODocUploader;
