import React, { useContext, useEffect } from 'react';

import { Box, Card, Link, Tab, TabPanel, Tabs, Typography, ViewContainer } from 'components/AORedesign';
import CLSWip from 'components/CommercialLinesServices/CLSWip';
import { farmRanchRedirect } from 'components/FarmRanch';
import { Feature } from 'components/Feature';
import PLSWip from 'components/PersonalLinesServices/PLSWip';
import featureFlags from 'constants/featureFlags';
import AppDataContext from 'contexts/appData.context';
import { QueryStrings } from 'helpers';
import agentContext from '../../contexts/agent.context';

const UNSOLD_QUOTES_KEY = 'unsold_quotes';
const FARM_RANCH_LABEL = 'Farm and Ranch';

const Quotes = () => {
  // initialTab can be CL or PL
  const queryStrings = QueryStrings();
  const navSource = queryStrings.get('navSource');
  const initialTab = queryStrings.get('initialTab') || 'CL';
  const initialTabIdx = initialTab == 'CL' ? 0 : 1;

  const [tabIndex, setTabIndex] = React.useState(initialTabIdx);
  const { updateDashboardCounts } = useContext(AppDataContext);
  const { FeatureFlags } = useContext(agentContext);

  // When a user enters the Quotes page, we will clear the Dashboard's Unsold Quotes count
  useEffect(() => {
    updateDashboardCounts(UNSOLD_QUOTES_KEY, {});
  }, []);

  const handleTabChange = (event, newTabValue) => {
    setTabIndex(newTabValue);
  };

  return (
    <ViewContainer title="Quotes">
      <Card>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label={'Commercial Lines'} />
          <Tab label="Personal Lines" />
          {FeatureFlags?.[featureFlags.ACCESS_FARM_RANCH] && <Tab label={FARM_RANCH_LABEL} />}
        </Tabs>
        <TabPanel label="Commercial Lines" value={tabIndex} index={0}>
          <CLSWip navSource={navSource} />
        </TabPanel>
        <TabPanel label="Personal Lines" value={tabIndex} index={1}>
          <PLSWip navSource={navSource} />
        </TabPanel>
        <Feature allowed={[featureFlags.ACCESS_FARM_RANCH]}>
          <TabPanel label={FARM_RANCH_LABEL} value={tabIndex} index={2}>
            <Box mb={3}>
              <Typography variant="h6">Work in Progress</Typography>
            </Box>
            <Link
              onClick={() =>
                farmRanchRedirect(
                  () => {},
                  () => {},
                )
              }
              endIcon="openInNew">
              View your Farm and Ranch Quotes
            </Link>
          </TabPanel>
        </Feature>
      </Card>
    </ViewContainer>
  );
};

export default Quotes;
