import React, { useState } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import { CLSAuditFormsSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';

import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';

const CLSAuditForms = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`CLSAuditFormsFrame`);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);

  const handleClickDownloadPdf = (event) => {
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');

    handlePdfDownload(
      'GET',
      null,
      pdfUrl,
      setLoadingPdf,
      setDownloadingPdfError,
      setIsDownloadingPdfErrorVisible,
      'newWindow',
    );
  };

  const localStateMap = { loadingPdf, downloadingPdfError, isDownloadingPdfErrorVisible };

  const callBackFunctions = { handleClickDownloadPdf };

  if (loadingSd) return <CLSAuditFormsSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default CLSAuditForms;
