import React from 'react';
import PropTypes from 'prop-types';
import MuiIconButton from '@material-ui/core/IconButton';

import { componentIdStringGenerator } from '../../../helpers';

const IconButton = (props) => {
  return (
    <MuiIconButton
      color="inherit"
      size="small"
      disabled={props.loading}
      {...props}
      id={componentIdStringGenerator('IconButton', props.id ? props.id : props.children)}
    />
  );
};

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  loading: PropTypes.bool,
};

export default IconButton;
