import { Box, Col, Row, Typography } from 'components/AORedesign';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCachedPolicy } from 'redux/app-store';
import PolicyDetailsCardContainer from './PolicyDetailsCardContainer';
import PolicyDetailsCardSkeleton from './PolicyDetailsCardSkeleton';
import ShowMoreOrLessButton from './ShowMoreOrLessButton';

const VehiclesCard = () => {
  const cachedPolicy = useSelector(selectCachedPolicy);

  // pointer to policy
  const policy = cachedPolicy.policy;

  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded((prevState) => !prevState);
  };

  const renderVehicleRecords = (vehicle, index) => {
    return (
      <Col xs={6} key={index}>
        <Box>
          <Typography
            color="textPrimary"
            style={{ fontSize: '1rem', fontStyle: 'medium' }}
            variant="body1">{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Typography>
        </Box>
        <Box mt={1}>
          <Typography color="textSecondary" variant="body1">
            VIN #{vehicle.vin}
          </Typography>
        </Box>
      </Col>
    );
  };

  const renderContent = () => {
    if (cachedPolicy.retrieveStatus === 'RETRIEVING') {
      return <PolicyDetailsCardSkeleton />;
    } else if (cachedPolicy.retrieveStatus === 'RETRIEVED' && policy.vehicles) {
      return (
        <PolicyDetailsCardContainer title="Vehicles">
          {policy?.vehicles?.length > 0 ? (
            <>
              <Box>
                <Row spacing={3}>
                  {/* filter and render first 4 items from vehicles list */}
                  {policy.vehicles
                    ?.filter((el, i) => i < 4)
                    .map((vehicle, index) => {
                      return renderVehicleRecords(vehicle, index);
                    })}
                  {/* after clicking  `show more` button, filter and render vehicles list if vehicles list contains more than 4 items  */}
                  {expanded &&
                    policy.vehicles
                      ?.filter((el, i) => i > 3)
                      .map((vehicle, index) => {
                        return renderVehicleRecords(vehicle, index + 4);
                      })}
                </Row>
              </Box>
              <>
                {/* show button when vehicles list contains more than 4 items */}
                {policy?.vehicles?.length > 4 && <ShowMoreOrLessButton expanded={expanded} handleOnClick={handleToggleExpand} />}
              </>
            </>
          ) : (
            <Typography color="textSecondary">No information found.</Typography>
          )}
        </PolicyDetailsCardContainer>
      );
    } else {
      return null;
    }
  };

  return renderContent();
};

export default VehiclesCard;
