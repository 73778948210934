/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useContext } from 'react';
import cuid from 'cuid';
import { useHistory } from 'react-router-dom';

import useFetchSelectMenuItems from 'hooks/useFetchSelectMenuItems';
import { ClaimsInquiryDetails } from 'components/ClaimsInquiryDetails';
import AppDataContext from 'contexts/appData.context';
import { useDebounce } from 'hooks/useDebounce';

import {
  fetchFormSuggestions,
  fetchTAReportsFormData,
  handleAOTransferListSetLeft,
  handleAOTransferListSetRight,
  handleCreateTransactionActivityReport,
  handleLOBOnChange,
  handleOnSortModelChange,
  handleGenerateOnClick,
  periodMenuItems,
  getReportResultsColumns,
  handleClickPaginationChange,
  handleExportDropdownOnChange,
  handleOnRowDoubleClick,
} from './TransactionActivityReports.api';
import { aODatePickerMinDate } from 'helpers';
import { GET_PERM_AGENCIES } from 'constants/api';
import {
  fetchAgencyNameSuggestions,
  handleAOAutocompleteOnBlur,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleOnChange,
  handleFromDateOnChange,
  handleToDateOnChange,
} from 'components/CommonUtilityServices/EventHandlerService';
import {
  Alert,
  DataGrid,
  DatePicker,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Select,
  Paper,
  ViewContainer,
  Autocomplete,
  TextField,
  Row,
  Col,
} from 'components/AORedesign';
import { Feature } from 'components/Feature';
import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';
import { AOGroupedSelect } from 'components/AOGroupedSelect';
import { AOTransferList } from 'components/AOTransferList';

const TransactionActivityReports = () => {
  const [lOBs, setLOBs] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [transactionSubtypes, setTransactionSubtypes] = useState([]);
  const [checkedTransferListItems, setCheckedTransferListItems] = useState([]);
  const [policyNumberOptions, setPolicyNumberOptions] = useState([]);
  const [agencyNameOptions, setAgencyNameOptions] = useState([]);
  const [accountNumberOptions, setAccountNumberOptions] = useState([]);
  const [permAgenciesOptions, setPermAgenciesOptions] = useState([]);
  const [partialInsuredNameOptions, setPartialInsuredNameOptions] = useState([]);
  const [reportResults, setReportResults] = useState({});
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);
  const [exportRangeMenuItems, setExportRangeMenuItems] = useState([]);
  const [exportRangeValue, setExportRangeValue] = useState(1);
  const [createButtonLoading, setCreateButtonLoading] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(null);

  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    policyNumber: { value: '', invalidText: '' },
    agencyCodes: { value: '' },
    accountNumber: { value: '', invalidText: '' },
    partialInsuredName: { value: '', invalidText: '' },
  });
  const [formData, setFormData] = useState({
    accountNumber: '',
    agencyCodes: '',
    pageNumber: 1,
    fromDate: null,
    lob: 'ALL',
    lobGroup: 'ALL',
    partialInsuredName: '',
    period: periodMenuItems[0].value,
    policyNumber: '',
    toDate: null,
    transactionSubTypes: [],
    transactionType: 'ALL',
  });

  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);

  const generateReportFormDataRef = useRef({});

  const history = useHistory();
  const { setPreviousPageName } = useContext(AppDataContext);

  useFetchSelectMenuItems(GET_PERM_AGENCIES, (response) => {
    if (response.data.length) {
      // External users will get data in from this call. Internal will return empty array.
      const allAgencies = { label: `ALL - 000000`, value: '000000' };
      setFormData({ ...formData, agencyCodes: allAgencies.value });
      setPermAgenciesOptions([
        allAgencies,
        ...response.data.map(
          (agency) => (
            { label: `ALL - 000000`, value: '000000' },
            {
              label: `${agency.agencyName?.trim()} - ${agency.agencyCode}`,
              value: agency.agencyCode,
            }
          ),
        ),
      ]);

      // If external user, generate the Grid
      handleCreateTransactionActivityReport(
        formData,
        setReportResults,
        generateReportFormDataRef,
        setExportRangeMenuItems,
        setExportRangeValue,
        setCreateButtonLoading,
      );
    }
  });

  useEffect(async () => {
    const tAReportsTransactionSubtypes = await fetchTAReportsFormData('transaction-subtypes');
    setTransactionSubtypes(tAReportsTransactionSubtypes);
    const tAReportsLOBs = await fetchTAReportsFormData('LOBs');
    setLOBs(tAReportsLOBs);
    const tAReportsTransactionTypes = await fetchTAReportsFormData('transaction-types');
    const transactionTypeMenuItems = tAReportsTransactionTypes.map((item) => ({
      value: item,
      label: item,
    }));
    setTransactionTypes(transactionTypeMenuItems);
  }, []);

  useEffect(async () => {
    if (autocompleteInputValues.policyNumber.value.length >= 3) {
      const suggestions = await fetchFormSuggestions('policy-suggestions', autocompleteInputValues.policyNumber.value);
      setPolicyNumberOptions(suggestions);
    } else {
      setPolicyNumberOptions([]);
    }
  }, [debouncedState.policyNumber]);

  useEffect(async () => {
    if (autocompleteInputValues.agencyCodes.value.length >= 3) {
      const suggestions = await fetchAgencyNameSuggestions(autocompleteInputValues.agencyCodes.value);
      setAgencyNameOptions(suggestions);
    } else {
      setAgencyNameOptions([]);
    }
  }, [debouncedState.agencyCodes]);

  useEffect(async () => {
    if (autocompleteInputValues.accountNumber.value.length >= 3) {
      const suggestions = await fetchFormSuggestions('account-suggestions', autocompleteInputValues.accountNumber.value);
      setAccountNumberOptions(suggestions);
    } else {
      setAccountNumberOptions([]);
    }
  }, [debouncedState.accountNumber]);

  useEffect(async () => {
    if (autocompleteInputValues.accountNumber.value.length >= 3) {
      const suggestions = await fetchFormSuggestions('account-suggestions', autocompleteInputValues.partialInsuredName.value);
      setPartialInsuredNameOptions(suggestions);
    } else {
      setPartialInsuredNameOptions([]);
    }
  }, [debouncedState.partialInsuredName]);

  const handleOnChange_PROXY = (e) => handleOnChange(e, formData, setFormData);
  const handleLOBOnChange_PROXY = (e) => handleLOBOnChange(e, lOBs, formData, setFormData);
  const handleFromDateOnChange_PROXY = (date) => handleFromDateOnChange(date, formData, setFormData);
  const handleToDateOnChange_PROXY = (date) => handleToDateOnChange(date, formData, setFormData);
  const handleAOTransferListSetLeft_PROXY = (data) => handleAOTransferListSetLeft(data, setTransactionSubtypes);
  const handleAOTransferListSetRight_PROXY = (data) => handleAOTransferListSetRight(data, formData, setFormData);
  const handleCreateTransactionActivityReport_PROXY = () =>
    handleCreateTransactionActivityReport(
      formData,
      setReportResults,
      generateReportFormDataRef,
      setExportRangeMenuItems,
      setExportRangeValue,
      setCreateButtonLoading,
    );
  const handlePolicyNumberOnInputChange_PROXY = (e, value) =>
    handleAOAutocompleteOnInputChange('policyNumber', e, value, autocompleteInputValues, setAutocompleteInputValues, debouncedState, setDebouncedState);
  const handlePolicyNumberOnChange = (e, value) => handleAOAutocompleteOnChange('policyNumber', e, value, formData, setFormData);
  const handlePolicyNumberOnBlur = () => handleAOAutocompleteOnBlur('policyNumber', formData, setFormData, autocompleteInputValues);
  const handleAgencyNameOnInputChange = (e, value) =>
    handleAOAutocompleteOnInputChange('agencyCodes', e, value, autocompleteInputValues, setAutocompleteInputValues, debouncedState, setDebouncedState);
  const handleAgencyNameOnChange = (e, value) => handleAOAutocompleteOnChange('agencyCodes', e, value, formData, setFormData);
  const handleAgencyNameOnBlur = () => handleAOAutocompleteOnBlur('agencyCodes', formData, setFormData, autocompleteInputValues);
  const handleAccountNumberOnInputChange = (e, value) =>
    handleAOAutocompleteOnInputChange('accountNumber', e, value, autocompleteInputValues, setAutocompleteInputValues, debouncedState, setDebouncedState);
  const handleAccountNumberOnChange = (e, value) => handleAOAutocompleteOnChange('accountNumber', e, value, formData, setFormData);
  const handleAccountNumberOnBlur = () => handleAOAutocompleteOnBlur('accountNumber', formData, setFormData, autocompleteInputValues);
  const handlePartialInsuredNameOnInputChange = (e, value) =>
    handleAOAutocompleteOnInputChange('partialInsuredName', e, value, autocompleteInputValues, setAutocompleteInputValues, debouncedState, setDebouncedState);
  const handlePartialInsuredNameOnChange = (e, value) => handleAOAutocompleteOnChange('partialInsuredName', e, value, formData, setFormData);
  const handlePartialInsuredNameOnBlur = () => handleAOAutocompleteOnBlur('partialInsuredName', formData, setFormData, autocompleteInputValues);
  const handleGenerateOnClick_PROXY = () =>
    handleGenerateOnClick(generateReportFormDataRef, setLoadingPdf, setIsDownloadingPdfErrorVisible, setDownloadingPdfError, exportRangeValue);
  const handleOnSortModelChange_PROXY = (sortData) => handleOnSortModelChange(sortData, generateReportFormDataRef);
  const datePickerMinDate = aODatePickerMinDate(undefined, undefined, 1);
  const handleClickPaginationChange_PROXY = (page) => handleClickPaginationChange(page, formData, setReportResults, setCreateButtonLoading);
  const handleExportDropdownOnChange_PROXY = (e) => handleExportDropdownOnChange(e, setExportRangeValue);
  const handleOnRowDoubleClick_PROXY = (params) => handleOnRowDoubleClick(params, history, setPreviousPageName, setSelectedClaim);
  const handleCloseClaimsInquiryDetails = () => setSelectedClaim(null);

  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
      <Card>
        <CardHeader title="Transaction Activity Report" />
        <CardContent>
          <Box mb={2}>
            <Alert
              severity="info"
              mb={2}
              message="Due to system batch processing, transaction dates on the report may appear to be later than dates entered into the system."
            />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleGenerateOnClick_PROXY();
              }}>
              <Box mb={3}>
                <Row spacing={2} alignItems="center">
                  <Col>
                    <Select
                      onChange={handleOnChange_PROXY}
                      value={formData.period}
                      menuItems={periodMenuItems}
                      id="transaction-activity-report-period"
                      labelId="transaction-activity-report-period-label"
                      label="Period"
                      name="period"
                    />
                  </Col>
                  {formData?.period === -1 && (
                    <>
                      <Col mb={3}>
                        <DatePicker
                          id="transaction-activity-report-from"
                          onChange={handleFromDateOnChange_PROXY}
                          minDate={datePickerMinDate}
                          value={formData.fromDate}
                          label="From"
                          name="fromDate"
                          minDateMessage="Results only return activity for past 12 months"
                          disableFuture={true}
                        />
                      </Col>
                      <Col mb={3}>
                        <DatePicker
                          id="transaction-activity-report-to"
                          onChange={handleToDateOnChange_PROXY}
                          minDate={datePickerMinDate}
                          value={formData.toDate}
                          label="To"
                          name="toDate"
                          minDateMessage="Results only return activity for past 12 months"
                          disableFuture={true}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </Box>

              <Box mb={3}>
                <Feature allowed="isAgent">
                  <Box mb={3}>
                    <Select
                      onChange={handleOnChange_PROXY}
                      value={formData.agencyCodes}
                      menuItems={permAgenciesOptions}
                      id="transaction-activity-report-agency-name"
                      labelId="transaction-activity-report-agency-name-label"
                      label="Agency Name"
                      name="agencyCodes"
                    />
                  </Box>
                </Feature>
                <Box mb={3}>
                  <Autocomplete
                    style={{ width: 400 }}
                    id="transaction-activity-report-policy-number"
                    value={formData.policyNumber}
                    inputValue={autocompleteInputValues.policyNumber.value}
                    options={policyNumberOptions || []}
                    disableClearable
                    onChange={handlePolicyNumberOnChange}
                    onInputChange={handlePolicyNumberOnInputChange_PROXY}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={autocompleteInputValues.policyNumber.invalidText.length > 0}
                        helperText={autocompleteInputValues.policyNumber.invalidText}
                        label="Policy Number"
                        placeholder="Enter Policy Number"
                        variant="outlined"
                        onBlur={handlePolicyNumberOnBlur}
                      />
                    )}
                  />
                </Box>
                <Feature allowed="internalUser">
                  <Autocomplete
                    style={{ width: 400 }}
                    id="transaction-activity-report-agency-name"
                    value={formData.agencyCodes}
                    inputValue={autocompleteInputValues.agencyCodes.value}
                    options={agencyNameOptions || []}
                    disableClearable
                    required={true}
                    onChange={handleAgencyNameOnChange}
                    onInputChange={handleAgencyNameOnInputChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Agency Name" placeholder="Enter Agency Name" variant="outlined" onBlur={handleAgencyNameOnBlur} />
                    )}
                  />
                </Feature>
              </Box>
              <Box mb={3}>
                <Autocomplete
                  style={{ width: 400 }}
                  id="transaction-activity-report-account-number"
                  value={formData.accountNumber}
                  inputValue={autocompleteInputValues.accountNumber.value}
                  options={accountNumberOptions || []}
                  disableClearable
                  onChange={handleAccountNumberOnChange}
                  onInputChange={handleAccountNumberOnInputChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={autocompleteInputValues.accountNumber.invalidText.length > 0}
                      helperText={autocompleteInputValues.accountNumber.invalidText}
                      label="Account Number"
                      placeholder="Enter Account Number"
                      variant="outlined"
                      onBlur={handleAccountNumberOnBlur}
                    />
                  )}
                />
              </Box>
              <Box mb={3}>
                <Autocomplete
                  style={{ width: 400 }}
                  id="transaction-activity-report-name-insured"
                  value={formData.partialInsuredName}
                  inputValue={autocompleteInputValues.partialInsuredName.value}
                  options={partialInsuredNameOptions || []}
                  disableClearable
                  onChange={handlePartialInsuredNameOnChange}
                  onInputChange={handlePartialInsuredNameOnInputChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={autocompleteInputValues.partialInsuredName.invalidText.length > 0}
                      helperText={autocompleteInputValues.partialInsuredName.invalidText}
                      label="Name Insured"
                      placeholder="Enter Name Insured"
                      variant="outlined"
                      onBlur={handlePartialInsuredNameOnBlur}
                    />
                  )}
                />
              </Box>
              <Box mb={3}>
                <Row spacing={2} alignItems="center">
                  <Col>
                    <AOGroupedSelect
                      onChange={handleLOBOnChange_PROXY}
                      value={formData.lob}
                      menuItems={lOBs.map((lob) => ({ group: lob.lobGroupName, value: lob.lobName, code: lob.lobName }))}
                      id="transaction-activity-report-lob"
                      labelId="transaction-activity-report-lob-label"
                      inputLabel="Line of Business"
                      name="lob"
                    />
                  </Col>
                  <Col>
                    <Select
                      onChange={handleOnChange_PROXY}
                      value={formData.transactionType}
                      menuItems={transactionTypes}
                      id="transaction-activity-report-transaction-type"
                      labelId="transaction-activity-report-transaction-type-label"
                      label="Transaction Type"
                      name="transactionType"
                    />
                  </Col>
                </Row>
              </Box>
              <Box mb={3}>
                <AOTransferList
                  setLeft={handleAOTransferListSetLeft_PROXY}
                  setRight={handleAOTransferListSetRight_PROXY}
                  setChecked={setCheckedTransferListItems}
                  left={transactionSubtypes}
                  right={formData.transactionSubTypes}
                  checked={checkedTransferListItems}
                  label="* Please select at least one Transaction Subtype"
                  leftLabel="Available Transaction Subtypes"
                  rightLabel="Selected Transaction Subtypes"
                />
              </Box>
              <Box mb={3}>
                <Button
                  id="transaction-activity-report-create-button"
                  onClick={handleCreateTransactionActivityReport_PROXY}
                  loading={createButtonLoading}
                  variant="contained"
                  color="primary">
                  Create
                </Button>
              </Box>
              {isDownloadingPdfErrorVisible && <Alert message={downloadingPdfError} severity="error" />}
              {reportResults.total > 0 && (
                <Box mb={3}>
                  <Row spacing={2} alignItems="center">
                    <Col>
                      <Select
                        onChange={handleExportDropdownOnChange_PROXY}
                        value={exportRangeValue}
                        menuItems={exportRangeMenuItems}
                        id="transaction-activity-export-select"
                        labelId="transaction-activity-export-select-label"
                        label="Export Range"
                        name="export-range"
                      />
                    </Col>
                    <Col>
                      <Button onClick={handleGenerateOnClick_PROXY} variant="contained" color="primary">
                        Generate
                      </Button>
                    </Col>
                  </Row>
                </Box>
              )}
            </form>
          </Box>

          <Box mb={2}>
            <Paper variant="outlined" square={true}>
              <DataGrid
                onSortModelChange={handleOnSortModelChange_PROXY}
                onPageChange={handleClickPaginationChange_PROXY}
                onRowDoubleClick={handleOnRowDoubleClick_PROXY}
                rows={reportResults.data?.map((el) => ({
                  id: cuid(),
                  ...el,
                }))}
                columns={getReportResultsColumns(setSelectedClaim, history, setPreviousPageName)}
                rowCount={reportResults.total}
                loading={createButtonLoading}
                pageSize={25}
                rowsPerPageOptions="[]"
                paginationMode="server"
              />
            </Paper>
          </Box>
          <Box>
            <ClaimsInquiryDetails handleCloseClaimsInquiryDetails={handleCloseClaimsInquiryDetails} selectedClaim={selectedClaim} />
          </Box>
        </CardContent>
      </Card>
    </ViewContainer>
  );
};

export default TransactionActivityReports;
