import { React, useState, useContext } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../../ScreenSmith';
import BillingServicesGuideFaqsSkeleton from './BillingServicesGuideFaqsSkeleton';
import useScreenDefinition from '../../../hooks/useScreenDefinition';

import { handleClickDownloadPdf } from './BillingServicesGuideFaqs.api'
import AppConfigContext from '../../../contexts/appConfig.context';


const BillingServicesGuideFaqs = () => {

  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`BillingServicesGuideFaqsPanel`);

  const { legacyWebUrlDomain } = useContext(AppConfigContext);

  const [loadingPdf, setLoadingPdf] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);

  const resetAlerts = () => {
    setIsDownloadingPdfErrorVisible(false);
  };

  const handleClickOnlineBillingGuide = async (event) => {
    resetAlerts();
    const onlineBillingGuideUrl = event.currentTarget.getAttribute('data-route');
    window.open(`${legacyWebUrlDomain}${onlineBillingGuideUrl}`, '_blank');

  };

  const localStateMap = {
    loadingPdf,
    downloadingPdfError,
    isDownloadingPdfErrorVisible,
  };

  const callBackFunctions = {
    handleClickDownloadPdf: (e) => handleClickDownloadPdf(e, setIsDownloadingPdfErrorVisible, setLoadingPdf, setDownloadingPdfError),
    handleClickOnlineBillingGuide
  };

  if (loadingSd) return <BillingServicesGuideFaqsSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default BillingServicesGuideFaqs;
