import axios from 'axios';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { GET_PL_WIP_BASE_URL } from '../../constants/api';

import OpenWindowService from '../CommonUtilityServices/OpenWindowService';

const API_PLS_WIP_BASE_URL = GET_PL_WIP_BASE_URL;

const plWipLobMenuItemsData = [
  {
    code: 'ALL',
    value: 'All',
  },
  {
    code: 'AU',
    value: 'Automobile',
  },
  {
    code: 'HO',
    value: 'HomeOwner',
  },
];

const plWipSearchResultsColumnsData = [
  {
    field: 'reference',
    headerName: 'Policy/QT/Ref #',
    editable: true,
    flex: 1,
  },
  {
    field: 'insName',
    headerName: 'Named Insured/Description',
    flex: 1,
    editable: true,
  },
  {
    field: 'lob',
    headerName: 'LOB',
    type: 'number',
    flex: 1,
    editable: true,
  },
  {
    field: 'effDate',
    headerName: 'Effective Date',
    type: 'number',
    flex: 1,
    editable: true,
  },
  {
    field: 'entDate',
    headerName: 'Entered Date',
    type: 'number',
    flex: 1,
    editable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'number',
    flex: 1,
    editable: true,
  },
  {
    field: 'userID',
    headerName: 'User ID',
    type: 'number',
    flex: 1,
    editable: true,
  },
  {
    field: 'agentNum',
    headerName: 'Agent Code',
    type: 'number',
    flex: 1,
    editable: true,
  },
];

const plPolicyChangeViewSearchTypeMenuItemsData = [
  {
    code: '',
    value: 'Select Search Type',
  },
  {
    code: 'polNum',
    value: 'Policy Number',
  },
  {
    code: 'lastName',
    value: 'Policy Holder Last Name',
  },
];

const fetchPlWipAutoCompletion = async (_text, _autoCompletionUrl) => {
  const formsData = await axios.get(`${_autoCompletionUrl}${_text}`);
  const formsSuggestion = formsData.data;
  return formsSuggestion;
};

const handleRouteAndOpenInNewWindow = (event) => {
  const routeUrl = event.currentTarget.getAttribute('data-route');
  const httpMethod = event.currentTarget.getAttribute('data-http-method') || 'POST';
  return OpenWindowService(routeUrl, httpMethod, null, null);
};

const handleAOAutocompleteOnBlur = (
  property,
  plWipSearchTypeValue,
  setPlWipSearchTypeValue,
  autocompleteInputValues,
) => {
  if (plWipSearchTypeValue !== autocompleteInputValues[property].value) {
    setPlWipSearchTypeValue(autocompleteInputValues[property].value);
  }
};

const handleAOAutocompleteOnInputChange = (
  property,
  e,
  value,
  autocompleteInputValues,
  setAutocompleteInputValues,
  setPlWipSearchTypeValue,
) => {
  setAutocompleteInputValues({
    ...autocompleteInputValues,
    [property]: {
      ...autocompleteInputValues[property],
      value,
    },
  });
  setPlWipSearchTypeValue(value);
};

const getOptionLabel = (option) => option.value ?? option;

const filterOptions = createFilterOptions();

export {
  API_PLS_WIP_BASE_URL,
  plPolicyChangeViewSearchTypeMenuItemsData,
  plWipLobMenuItemsData,
  plWipSearchResultsColumnsData,
  fetchPlWipAutoCompletion,
  handleRouteAndOpenInNewWindow,
  getOptionLabel,
  filterOptions,
  handleAOAutocompleteOnBlur,
  handleAOAutocompleteOnInputChange,
};
