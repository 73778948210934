import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import { GET_REPORTS_URL } from 'constants/api';

const getGenerateReportRequestBody = (formData, exportType) => {
  let requestBody = {
    Area: 'direct-bill-commission',
    DetailedContext: [
      {
        name: 'period',
        value: formData.year,
      },
      {
        name: 'agencyCode',
        value: formData.agencyCode,
      },
      {
        name: 'exportType',
        value: exportType,
      },
    ],
    TypeOfRedirect: 'Generate-Report',
  };
  return requestBody;
};

const handleAgencyCodeOnClick = (e, autocompleteInputValues, setAutocompleteInputValues, setFormData, setAgencyNameOptions) => {
  setAutocompleteInputValues({ ...autocompleteInputValues, agencyName: { value: '' } });
  setFormData({ agencyName: '', agencyCode: e.target.value });
  setAgencyNameOptions([]);
};

const handleAgencyNameOnClick = (formData, setFormData) => {
  setFormData({ ...formData, agencyCode: '' });
};

const handleOnChangeMonthYear = (e, setFormData) => {
  const year = e.target.value;
  setFormData((prevValue) => ({ ...prevValue, year }));
};

const handleCreateDbcReport = async (setLoadingPdf, setIsDownloadingPdfErrorVisible, setDownloadingPdfError, formData, exportType) => {
  setLoadingPdf(true);
  const params = {
    period: formData.year,
    agencyCode: formData.agencyCode,
  };
  if (!params.period) {
    setIsDownloadingPdfErrorVisible(true);
    setLoadingPdf(false);
    setDownloadingPdfError('Please select a Month/Year');
    return;
  }
  if (!params.agencyCode) {
    setIsDownloadingPdfErrorVisible(true);
    setLoadingPdf(false);
    setDownloadingPdfError('Please select an agency name or enter agency code');
    return;
  }
  try {
    setDownloadingPdfError('');
    let reportRequestBody = getGenerateReportRequestBody(formData, exportType);
    const reportsGenerateUrl = GET_REPORTS_URL;
    handlePdfDownload('POST', reportRequestBody, reportsGenerateUrl, setLoadingPdf, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
    setLoadingPdf(false);
  } catch (error) {
    console.error(error);
    setLoadingPdf(false);
  }
};
const handleOnChangeAgencyNameDD = (e, setFormData) => {
  const agencyCode = e.target.value;
  setFormData((prevValue) => ({ ...prevValue, agencyName: agencyCode, agencyCode }));
};

const handleOnChangeAgencyCode = (e, setFormData) => {
  const agencyCode = e.target.value;
  setFormData((prevValue) => ({ ...prevValue, agencyCode }));
};

const handleOnBlurAgencyCode = (e, setFormData) => {
  const defaultValue = e.target.defaultValue;
  setFormData((prevValue) => ({ ...prevValue, agencyName: defaultValue }));
};

export {
  handleAgencyCodeOnClick,
  handleAgencyNameOnClick,
  handleCreateDbcReport,
  handleOnChangeMonthYear,
  handleOnChangeAgencyNameDD,
  handleOnChangeAgencyCode,
  handleOnBlurAgencyCode,
};
