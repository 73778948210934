export { default as WorkflowContainer } from './WorkflowContainer';
export { default as WorkflowCardPersonal } from './WorkflowCardPersonal';
export { default as WorkflowCardCommercial } from './WorkflowCardCommercial';
export { default as WorkflowCardBonds } from './WorkflowCardBonds';
export { default as WorkflowCardFarmRanch } from './WorkflowCardFarmRanch';
export { default as WorkflowNoAccess } from './WorkflowNoAccess';

export const PERSONAL_LOB_VIEW = 'personal';
export const COMMERCIAL_LOB_VIEW = 'commercial';
export const COMMERCIAL_PRODUCTS_VIEW = 'commercial-products';
