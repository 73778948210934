import React from 'react';
import PropTypes from 'prop-types';
import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { componentIdStringGenerator } from '../../../helpers';
import { iconMap } from './iconMap';

const Icon = ({ iconName, ...props }) => {
  return (
    <MuiSvgIcon {...props} id={componentIdStringGenerator('Icon', props.id ? props.id : iconName ?? '')}>
      {iconMap[iconName]}
    </MuiSvgIcon>
  );
};

Icon.propTypes = {
  iconName: PropTypes.string,
  id: PropTypes.string,
};

export default Icon;
