import React, { useState } from 'react';

import { Alert, Box, Link, Typography } from 'components/AORedesign';
import LinksContainer from './LinksContainer';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import { handleFileDownload } from 'components/CommonUtilityServices/DownloadFileService';

const PDF_ICON = 'pdf';
const DOCUMENT_ICON = 'document';

const AgencyFormRequests = () => {
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const handleClickDownloadFile = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');
    if (pdfUrl) {
      handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
    } else {
      const _fileUrl = event.currentTarget.getAttribute('data-file');
      handleFileDownload(_fileUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible);
    }
  };

  return (
    <Box mb={2}>
      <Box mt={1} mb={4}>
        <Typography variant="h6">Forms & Requests for Your Agency</Typography>
      </Box>
      {isDownloadingPdfErrorVisible && (
        <Box mb={2}>
          <Alert color="error" alertTitleMessage={downloadingPdfError} />
        </Box>
      )}
      <Box>
        <LinksContainer heading="FORMS">
          <Link data-pdf={'/agency-automation/Agency%20EFT%20Commission%20Deposit%20Form.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Electronic Commission & Agency Sweep Authorization Form
          </Link>
        </LinksContainer>
        <LinksContainer heading="commercial lines audit forms">
          <Link data-pdf={'/agency-automation/Sales%20Based%20Self-Audit%20Form.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Sales Based Self-Audit Form
          </Link>
          <Link data-pdf={'/agency-automation/Payroll%20Based%20Self-Audit%20Form-newest.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Payroll Based Self-Audit Form
          </Link>
          <Link data-pdf={'/agency-automation/Audit_Dispute_Form.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Audit Dispute Form
          </Link>
          <Link data-pdf={'/agency-automation/Audit_Information_Release_Authorization.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Audit Information Release Authorization Form
          </Link>
          <Link data-pdf={'/agency-automation/Sedgwick%20Basic%20Information.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Sedgwick Audit Basic Information
          </Link>
          <Link data-pdf={'/agency-automation/Sedgwick%20Preparing%20for%20an%20Audit.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Sedgwick Audit Preparing for an Audit
          </Link>
          <Link data-pdf={'/agency-automation/Sedgwick%20FAQs.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Sedgwick Audit FAQS
          </Link>
        </LinksContainer>
        <LinksContainer heading="requests">
          <Link data-file={'/agency-automation/Change%20of%20Agency%20Information%20Request.doc'} onClick={handleClickDownloadFile} endIcon={DOCUMENT_ICON}>
            Change of Agency Information Request
          </Link>
        </LinksContainer>
      </Box>
    </Box>
  );
};

export default AgencyFormRequests;
