import React from 'react';
import PropTypes from 'prop-types';

import RowCol from '../ScreenSmith/RowCol';
import { AOSelect } from '../AOSelect';
import { GET_PERM_AGENCIES } from '../../constants/api';
import useFetchSelectMenuItems from '../../hooks/useFetchSelectMenuItems';

const AnnualMileageValidationReportAgentView = ({
  menuItems,
  onChange,
  setAgencyCodeOptions,
  value,
}) => {
  useFetchSelectMenuItems(GET_PERM_AGENCIES, (response) => {
    setAgencyCodeOptions(
      response.data.map((location) => ({
        value: `${location.agencyName}-${location.agencyCode}`,
        code: location.agencyCode,
      })),
    );
  });

  return (
    <RowCol mb={2}>
      <AOSelect
        id="amvr-agency-codes"
        labelId="amvr-agency-codes-label"
        inputLabel="Agency Codes"
        name="AgtNum"
        menuItems={menuItems}
        onChange={onChange}
        value={value}
      />
    </RowCol>
  );
};

AnnualMileageValidationReportAgentView.propTypes = {
  menuItems: PropTypes.array,
  onChange: PropTypes.func,
  setAgencyCodeOptions: PropTypes.func,
  value: PropTypes.string,
};

export default AnnualMileageValidationReportAgentView;
