import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ScreenSmith } from '../../../ScreenSmith';
import BillingServicesPaymentManagementSkeleton from '../BillingServicesPaymentManagementSkeleton';
import PostPaymentValidation from './PostPaymentValidation';
import PostPaymentValidationTable from './PostPaymentValidationTable';
import PostPaymentConfirmation from './PostPaymentConfirmation';

import useScreenDefinition from '../../../../hooks/useScreenDefinition';

import {
  rowInit,
  paymentAccCol,
  fetchPaymentAccountsList,
  onSelectionModelChange,
  handleClickPaymentList,
  handleClickEnterPayments,
  handleClickResetEnterPayment,
  enterPaymentCol,
  handleOnCellEditCommit,
  handleClickPaymentValidation,
  paymentValidationCol,
  handleClickPaymentConfirmation,
  handleClickSubmitPostPayment,
} from './PostPayment.api';

const screenMappings = {
  start: `BillingServicesPaymentManagementPostPaymentPanel`,
  enterPayments: `BillingServicesPaymentManagementPostPaymentPanelEnterPayments`,
  paymentValidation: `BillingServicesPaymentManagementPostPaymentPanelPaymentValidation`,
  paymentConfirmation: `BillingServicesPaymentManagementPostPaymentPanelPaymentConfirmation`,
};

const LocalComponents = {
  PostPaymentValidation,
  PostPaymentValidationTable,
  PostPaymentConfirmation,
};

const BillingServicesPaymentManagementPostPayment = ({ resetFlow }) => {
  const [currentScreen, setCurrentScreen] = useState(screenMappings.start);
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(currentScreen, [currentScreen]);
  const [postPaymentData, setPostPaymentData] = useState({
    paymentAccountsList: [rowInit],
    paymentAccountsListToEdit: [rowInit],
    paymentAccountsListLoading: true,
    alert: {
      isVisible: false,
      message: null,
      severity: null,
    },
    enterPaymentTotal: null,
    enterPaymentContinueDisabled: true,
  });
  const [selectionModel, setSelectionModel] = useState([]);

  const localStateMap = {
    paymentAccList: postPaymentData.paymentAccountsList,
    paymentAccCol,
    paymentAccListLoading: postPaymentData.paymentAccountsListLoading,
    alertIsVisible: postPaymentData.alert.isVisible,
    alertMessage: postPaymentData.alert.message,
    alertSeverity: postPaymentData.alert.severity,
    selectionModel,
    enterPaymentCol,
    enterPaymentList: postPaymentData.paymentAccountsListToEdit,
    enterPaymentTotal: postPaymentData.enterPaymentTotal,
    enterPaymentContinueDisabled: postPaymentData.enterPaymentContinueDisabled,
    paymentValidationCol,
    paymentValidationList: postPaymentData.paymentAccountsListToEdit,
  };

  const callBackFunctions = {
    handleClickBackToPaymentManagement: () => resetFlow(), // function from parent to reset flow
    onSelectionModelChange: (selection) =>
      onSelectionModelChange(selection, postPaymentData, setSelectionModel, setPostPaymentData),
    handleClickPaymentList: (e) =>
      handleClickPaymentList(e, screenMappings, setCurrentScreen, setPostPaymentData),
    handleClickEnterPayments: () =>
      handleClickEnterPayments(
        screenMappings,
        setCurrentScreen,
        postPaymentData,
        setPostPaymentData,
      ),
    handleClickResetEnterPayment: (e) =>
      handleClickResetEnterPayment(e, postPaymentData, setPostPaymentData),
    handleOnCellEditCommit: (params) =>
      handleOnCellEditCommit(params, postPaymentData, setPostPaymentData),
    handleClickPaymentValidation: (e) =>
      handleClickPaymentValidation(e, screenMappings, setCurrentScreen, setPostPaymentData),
    handleClickPaymentConfirmation: (e) =>
      handleClickPaymentConfirmation(e, screenMappings, setCurrentScreen, setPostPaymentData),
    handleClickSubmitPostPayment: () =>
      handleClickSubmitPostPayment(
        screenMappings,
        setCurrentScreen,
        postPaymentData,
        setPostPaymentData,
      ),
  };

  useEffect(() => {
    if (currentScreen === screenMappings.start) {
      fetchPaymentAccountsList(setPostPaymentData);
    }
  }, [currentScreen]);

  if (loadingSd) return <BillingServicesPaymentManagementSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={LocalComponents}
        />
      )}
    </>
  );
};

BillingServicesPaymentManagementPostPayment.propTypes = {
  resetFlow: PropTypes.func,
};

export default BillingServicesPaymentManagementPostPayment;
