import React from 'react';

import { ViewContainer } from '../AORedesign';
import ClaimsComponent from './Claims';

const Claims = () => {
  return (
    <ViewContainer>
      <ClaimsComponent />
    </ViewContainer>
  );
};

export default Claims;
