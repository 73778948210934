import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import { useReactToPrint } from 'react-to-print';
import { currencyStringFormatter } from '../../../../../helpers';

import {
  StyledLableCell,
  StyledSpacerRow,
  StyledTableCell,
  StyledTableRow,
  StyledTitleCell,
  StyledTitleRow,
} from './PaymentManagementHistoryTransactionDetailModal.styled';
import { Box, Modal, Table, TableBody, Typography, Paper } from '@material-ui/core';
import { AOButton } from '../../../../AOButton';
import { AOAlert } from '../../../../AOAlert';

const PaymentManagementHistoryTransactionDetailModal = ({
  details: {
    transDate,
    userId,
    changeType,
    changeReason,
    accountNumber,
    payUserId,
    agentNumber,
    source,
    dateCreated,
    originalAmount,
    cwaPolicy,
    eftType,
    application,
    tool,
    host,
    tranDesc,
    modifyAmount,
    payBmcUniqueId,
  },
  open,
  handleClose,
  alertIsActive,
  alertSeverity,
  alertMsg,
  loading,
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="payment-management-history-transaction-detail-modal-title"
      aria-describedby="payment-management-history-transaction-detail-modal-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper style={{ padding: `1rem` }}>
        <Box sx={{ width: 800, height: 450 }}>
          {loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <Box>
              {alertIsActive ? (
                <AOAlert severity={alertSeverity} message={alertMsg} />
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}>
                    <Box m={2}>
                      <AOButton color="primary" variant="contained" onClick={() => handlePrint()}>
                        Print
                      </AOButton>
                    </Box>
                    <Box m={2}>
                      <AOButton
                        color="secondary"
                        variant="contained"
                        onClick={() => handleClose(false)}>
                        Cancel
                      </AOButton>
                    </Box>
                  </Box>
                  <Box p={1} ref={componentRef}>
                    <Box mb={2}>
                      <Typography variant="h6" align="center">
                        Payment Management History Transaction Detail
                      </Typography>
                    </Box>
                    <Table>
                      <TableBody>
                        <StyledTitleRow>
                          <StyledTitleCell colSpan={4}>Transaction Change Details</StyledTitleCell>
                        </StyledTitleRow>
                        <StyledTableRow>
                          <StyledLableCell>Date :</StyledLableCell>
                          <StyledTableCell>{transDate}</StyledTableCell>
                          <StyledLableCell>Type :</StyledLableCell>
                          <StyledTableCell>
                            {changeType === 'M' ? (
                              <>
                                {tranDesc} amount to {currencyStringFormatter(modifyAmount)}
                              </>
                            ) : (
                              <>{tranDesc} transaction</>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledLableCell>Admin :</StyledLableCell>
                          <StyledTableCell>{userId}</StyledTableCell>
                          <StyledLableCell>Reason :</StyledLableCell>
                          <StyledTableCell>{changeReason}</StyledTableCell>
                        </StyledTableRow>
                        <StyledSpacerRow />
                        <StyledTitleRow>
                          <StyledTitleCell colSpan={4}>Original Payment Details</StyledTitleCell>
                        </StyledTitleRow>
                        <StyledTableRow>
                          <StyledLableCell>Account :</StyledLableCell>
                          <StyledTableCell>{accountNumber}</StyledTableCell>
                          <StyledLableCell>Source :</StyledLableCell>
                          <StyledTableCell>
                            {payBmcUniqueId &&
                            !_.isEmpty(payBmcUniqueId) &&
                            source &&
                            !_.isEmpty(source) ? (
                              <>
                                {source} [ID - {payBmcUniqueId}]
                              </>
                            ) : (
                              <>n/a</>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledLableCell>Agency :</StyledLableCell>
                          <StyledTableCell>{agentNumber}</StyledTableCell>
                          <StyledLableCell>Pay Date :</StyledLableCell>
                          <StyledTableCell>{dateCreated}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledLableCell>Agency ID :</StyledLableCell>
                          <StyledTableCell>{payUserId}</StyledTableCell>
                          <StyledLableCell>Orig. Amt :</StyledLableCell>
                          <StyledTableCell>
                            {currencyStringFormatter(originalAmount)}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledLableCell colSpan={3}>CWA Policy :</StyledLableCell>
                          <StyledTableCell>{cwaPolicy}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledLableCell colSpan={3}>EFT Type :</StyledLableCell>
                          <StyledTableCell>{eftType}</StyledTableCell>
                        </StyledTableRow>
                        <StyledSpacerRow />
                        <StyledTitleRow>
                          <StyledTitleCell colSpan={4}>System Details</StyledTitleCell>
                        </StyledTitleRow>
                        <StyledTableRow>
                          <StyledLableCell>Calling App :</StyledLableCell>
                          <StyledTableCell>{application}</StyledTableCell>
                          <StyledLableCell>Server IP :</StyledLableCell>
                          <StyledTableCell>{host}</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledLableCell>App Tool :</StyledLableCell>
                          <StyledTableCell colSpan={3}>{tool}</StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Paper>
    </Modal>
  );
};

PaymentManagementHistoryTransactionDetailModal.defaultProps = {
  transDate: '',
  userId: null,
  changeType: null,
  changeReason: null,
  accountNumber: null,
  agency: null,
  agentNumber: null,
  source: null,
  dateCreated: null,
  originalAmount: null,
  cwaPolicy: null,
  eftType: null,
  application: null,
  tool: null,
  host: null,
  tranDesc: null,
  modifyAmount: null,
  payBmcUniqueId: null,
};

PaymentManagementHistoryTransactionDetailModal.propTypes = {
  details: PropTypes.shape({
    transDate: PropTypes.node,
    userId: PropTypes.string,
    changeType: PropTypes.string,
    changeReason: PropTypes.string,
    accountNumber: PropTypes.string,
    payUserId: PropTypes.string,
    agentNumber: PropTypes.string,
    source: PropTypes.string,
    dateCreated: PropTypes.node,
    originalAmount: PropTypes.node,
    cwaPolicy: PropTypes.string,
    eftType: PropTypes.string,
    application: PropTypes.string,
    tool: PropTypes.string,
    host: PropTypes.string,
    tranDesc: PropTypes.string,
    modifyAmount: PropTypes.node,
    payBmcUniqueId: PropTypes.string,
  }),
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  alertIsActive: PropTypes.bool,
  alertSeverity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  alertMsg: PropTypes.string,
  loading: PropTypes.bool,
};

export default PaymentManagementHistoryTransactionDetailModal;
