import { redirectUrlFunction } from 'components/CommonUtilityServices/RedirectService';

const farmRanchRedirect = (setErrorMessage, setIsErrorVisible) => {
  const requestBody = {
    Area: 'Farm Ranch',
    DetailedContext: [
      {
        name: 'businessType',
        value: 'FR',
      },
    ],
    TypeOfRedirect: 'Quote Policy',
  };

  redirectUrlFunction(requestBody, 'POST', null, setErrorMessage, setIsErrorVisible);
};

export { farmRanchRedirect };
