import React from 'react';
import PropTypes from 'prop-types';


import {FormControlLabel, Checkbox} from '@material-ui/core';

const AOCheckbox = ({labelPlacement, label, value, ...props}) => {
  return (
    <FormControlLabel
      value={value}
      control={<Checkbox color="primary" />}
      label={label}
      labelPlacement={labelPlacement}
      {...props}
    />
  );
};

AOCheckbox.defaultProps = {
    labelPlacement: "end"
    
};

AOCheckbox.propTypes = {
    labelPlacement: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
};

export default AOCheckbox;
