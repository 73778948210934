import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../../ScreenSmith';
import useScreenDefinition from '../../../hooks/useScreenDefinition';
import { PLSPolicyDocumentsSkeleton } from '../../AOSkeleton';
import { useDebounce } from '../../../hooks/useDebounce';

import {
  getColData,
  handleChangePlPolicyDocumentNumber,
  handleInputChangePlPolicyDocumentNumber,
  handleOnClickRetrievePolicyDocuments,
  fetchSuggestions,
  handleOnBlur,
} from './PLSPolicyDocuments.api';

const PLSPolicyDocuments = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('PLSPolicyDocumentsPanel');

  const [plPolicyDocumentNumber, setPlPolicyDocumentNumber] = useState('');
  const [plPolicyDocumentNumberInputValue, setPlPolicyDocumentNumberInputValue] = useState('');
  const [loadingRetrievedDocuments, setLoadingRetrievedDocuments] = useState(false);
  const [dataGridIsVisible, setDataGridIsVisible] = useState(false);
  const [retrievedDocuments, setRetrievedDocuments] = useState(null);
  const [errorRetrievedDocuments, setErrorRetrievedDocuments] = useState(null);
  const [noRetrievedDocuments, setNoRetrievedDocuments] = useState(false);
  const [errorDownloadingPdf, setErrorDownloadingPdf] = useState(false);
  const [suggestionOptions, setSuggestionOptions] = useState([]);
  const [docSearchCustomerInfo, setDocSearchCustomerInfo] = useState({
    insuredName: '',
    policyNumber: '',
    effectiveDate: '',
    expirationDate: '',
    riskState: '',
    agencyName: '',
    agentNumber: '',
  });

  const [debouncedState, setDebouncedState] = useDebounce(plPolicyDocumentNumberInputValue);

  useEffect(async () => {
    if (plPolicyDocumentNumberInputValue.length >= 3) {
      const suggestions = await fetchSuggestions(plPolicyDocumentNumberInputValue);
      setSuggestionOptions(suggestions);
    } else {
      setSuggestionOptions([]);
    }
  }, [debouncedState]);

  const plState = {
    dataGridIsVisible,
    docSearchCustomerInfo,
    errorRetrievedDocuments,
    loadingRetrievedDocuments,
    noRetrievedDocuments,
    plPolicyDocumentNumber,
    plPolicyDocumentsColData: getColData(setErrorRetrievedDocuments, setErrorDownloadingPdf),
    retrievedDocuments,
    retrievedDocumentsButtonDisabled:
      plPolicyDocumentNumber.length < 3 || loadingRetrievedDocuments,
    errorDownloadingPdf,
    suggestionOptions,
    plPolicyDocumentNumberInputValue,
  };

  const callBackFunctions = {
    handleChangePlPolicyDocumentNumber: (e, value) =>
      handleChangePlPolicyDocumentNumber(e, value, setPlPolicyDocumentNumber),
    handleInputChangePlPolicyDocumentNumber: (e, value) =>
      handleInputChangePlPolicyDocumentNumber(
        e,
        value,
        setPlPolicyDocumentNumberInputValue,
        setDebouncedState,
      ),
    handleOnClickRetrievePolicyDocuments: () =>
      handleOnClickRetrievePolicyDocuments(
        setLoadingRetrievedDocuments,
        setNoRetrievedDocuments,
        setRetrievedDocuments,
        plPolicyDocumentNumber,
        setDataGridIsVisible,
        setDocSearchCustomerInfo,
        setErrorRetrievedDocuments,
        setErrorDownloadingPdf,
      ),
    handleOnBlur: () =>
      handleOnBlur(
        plPolicyDocumentNumber,
        plPolicyDocumentNumberInputValue,
        setPlPolicyDocumentNumber,
      ),
  };
  if (loadingSd) return <PLSPolicyDocumentsSkeleton />;

  return (
    !_.isNil(UiDefinitionsData) && (
      <ScreenSmith
        definition={UiDefinitionsData}
        functionMap={callBackFunctions}
        stateMap={plState}
      />
    )
  );
};

export default PLSPolicyDocuments;
