import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import axios from 'axios';
import cuid from 'cuid';

import Col from '../../ScreenSmith/Col';
import Row from '../../ScreenSmith/Row';
import { AOButton } from '../../AOButton';
import { AODataGrid } from '../../AODataGrid';
import { AOTextField } from '../../AOTextField';
import Typography from '@material-ui/core/Typography';

import { handleOnChange } from '../../CommonUtilityServices/EventHandlerService';

import { AGENCY_ADMIN_SEARCH } from '../../../constants/api';

const defaultFormData = { agencyName: '' };

const viewAgencyInfoResultColumns = [
  {
    field: 'agencyId',
    headerName: 'Agency Id',
    flex: 1,
  },
  {
    field: 'agencyName',
    headerName: 'Agency Name',
    flex: 1,
  },
  {
    field: 'agencyCity',
    headerName: 'Agency City',
    flex: 1,
  },
  {
    field: 'agencyState',
    headerName: 'Agency State',
    flex: 1,
  },
];

const ViewAgencyInfo = () => {
  const [formData, setFormData] = useState(defaultFormData);
  const [viewAgencyInfoResults, setViewAgencyInfoResults] = useState(null);
  const [viewAgencyInfoLoading, setViewAgencyInfoLoading] = useState(false);

  const handleViewAgencyInfoOnClick = async () => {
    setViewAgencyInfoLoading(true);
    setViewAgencyInfoResults(null);
    try {
      const response = await axios.get(AGENCY_ADMIN_SEARCH, {
        params: formData,
      });
      setViewAgencyInfoResults(response.data);
    } catch (error) {
      console.error(error);
    }
    setViewAgencyInfoLoading(false);
  };

  const handleClearOnClick = () => {
    setViewAgencyInfoResults(null);
    setFormData(defaultFormData);
  };

  return (
    <>
      <Row spacing={2}>
        <Col xs={4}>
          <Box mb={2}>
            <AOTextField
              id={'view-agency-info-agency-name'}
              name={'agencyName'}
              value={formData.agencyName}
              onChange={(e) => handleOnChange(e, formData, setFormData)}
              label={'Agency Name'}
              placeholder={'Enter Agency Name'}
            />
          </Box>
        </Col>
      </Row>

      <Row justifyContent="space-between" alignItems="center" mb={4}>
        <Box>
          <AOButton
            variant={'contained'}
            color={'primary'}
            id={'view-agency-info-search-button'}
            onClick={handleViewAgencyInfoOnClick}
            loading={viewAgencyInfoLoading}
            disabled={viewAgencyInfoLoading || formData.agencyName.length < 2}>
            Search
          </AOButton>
          <Box display={'inline'} mr={2} />
          <AOButton
            variant={'outlined'}
            color={'primary'}
            onClick={handleClearOnClick}
            id={'view-agency-info-clear-button'}>
            Clear
          </AOButton>
        </Box>
      </Row>

      {viewAgencyInfoResults && (
        <Box>
          <Typography variant="h6">Search Results </Typography>
          <AODataGrid
            autoHeight={true}
            pageSize={25}
            rows={viewAgencyInfoResults.map((row) => ({ ...row, id: cuid() }))}
            columns={viewAgencyInfoResultColumns}
          />
        </Box>
      )}
    </>
  );
};

export default ViewAgencyInfo;
