import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import { GET_INQUIRE_MSE_POLICY, GET_INQUIRE_MSS_POLICY } from '../../constants/api';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import { CLSPolicyInquirySkeleton } from '../AOSkeleton';
import { ScreenSmith } from '../ScreenSmith';

import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';

export const MSS_POLICY_INQUIRY = 'Policy Inquiry';
const MSS_VIEW_POLICY_DOCUMENTS = 'View Policy';
const AO_DATEPICKER_INVALID_DATE_VALUE = 'Invalid date';

export const sendGetPolicyInquiry = async (
  _request_body,
  setErrorGetPolicyInquiry,
  setIsGetPolicyInquiryAlertVisible,
  setPolicyInquiryLoading,
  setViewPolicyDocsLoading,
) => {
  let getPolicyInquiry;
  try {
    getPolicyInquiry = await axios.post(`${GET_INQUIRE_MSS_POLICY}`, _request_body);
  } catch (err) {
    // Handle Error Here
    setErrorGetPolicyInquiry(err.message);
    setIsGetPolicyInquiryAlertVisible(true);
    setPolicyInquiryLoading(false);
    setViewPolicyDocsLoading(false);
    getPolicyInquiry = err;
  }
  return getPolicyInquiry;
};

export const sendGetMSEMSCPlusPolicyInquiry = async (
  selectedPolicyNumber,
  setErrorGetPolicyInquiry,
  setIsGetPolicyInquiryAlertVisible,
  setPolicyInquiryLoading,
) => {
  let policyNumber;
  try {
    policyNumber = await axios.get(`${GET_INQUIRE_MSE_POLICY}/${selectedPolicyNumber}`);
  } catch (err) {
    setErrorGetPolicyInquiry(err.message);
    setIsGetPolicyInquiryAlertVisible(true);
    setPolicyInquiryLoading(false);
    policyNumber = err;
  }
  return policyNumber;
};

export const getMSSPolicyInquiryRequestBody = (policyAsOfDate, inquiryType, selectedPolicyNumber) => ({
  inquiryDate: policyAsOfDate,
  inquiryType: inquiryType,
  policyNumber: selectedPolicyNumber,
});

export const getMSSRedirectRequestBody = (policyInquiryPayload, policyAsOfDate) => ({
  Area: 'Commercial Lines',
  TypeOfRedirect: 'MSS Policy Inquiry',
  DetailedContext: [
    {
      name: 'transactionId',
      value: policyInquiryPayload?.data?.policyInquiryInfo?.systemAssignId,
    },
    {
      name: 'accountType',
      value: policyInquiryPayload?.data?.acctType,
    },
    {
      name: 'effectiveDate',
      value: policyAsOfDate,
    },
  ],
});

export const getMSEMSCPRedirectRequestBody = (clmsePolicyPayload) => ({
  Area: 'Commercial Lines',
  TypeOfRedirect: 'MSE Policy Inquiry',
  DetailedContext: [
    {
      name: 'system',
      value: 'SBSS',
    },
    {
      name: 'policyNumber',
      value: clmsePolicyPayload?.data[0]?.policyNumber,
    },
    {
      name: 'agencyNumber',
      value: clmsePolicyPayload?.data[0]?.agencyId,
    },
    {
      name: 'path',
      value: 'details',
    },
  ],
});

const CLSPolicyInquiry = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('CLSPolicyInquiryPanel');
  const [selectedPolicySystem, setselectedPolicySystem] = useState('');
  const [selectedPolicyNumber, setselectedPolicyNumber] = useState('');
  const [errorGetPolicyInquiry, setErrorGetPolicyInquiry] = useState(null);
  const [isGetPolicyInquiryAlertVisible, setIsGetPolicyInquiryAlertVisible] = useState(false);
  const [policyNumberNotFound, setPolicyNumberNotFound] = useState(null);
  const [isPolicyNumberNotFoundAlertVisible, setIsPolicyNumberNotFoundAlertVisible] = useState(false);
  const [policyInquiryLoading, setPolicyInquiryLoading] = useState(false);
  const [viewPolicyDocsLoading, setViewPolicyDocsLoading] = useState(false);
  const [isPolicyInquiryRedirectErrorVisible, setIsPolicyInquiryRedirectErrorVisible] = useState(false);
  const [policyInquiryRedirectError, setPolicyInquiryRedirectError] = useState(false);
  const [policyAsOfDate, setPolicyAsOfDate] = useState(null);

  const handleChangeSelectedPolicySystem = (event) => {
    setselectedPolicySystem(event.target.value);
  };

  const handleChangeSelectedPolicyNumber = (event) => {
    setselectedPolicyNumber(event.target.value);
  };

  const handleOnChangePolicyAsOfDate = (date) => {
    setPolicyAsOfDate(moment(date).format('MM/DD/YYYY'));
  };

  const resetAlerts = () => {
    setIsGetPolicyInquiryAlertVisible(false);
    setPolicyNumberNotFound(null);
    setIsPolicyNumberNotFoundAlertVisible(false);
    setIsPolicyInquiryRedirectErrorVisible(false);
  };

  const getMSSPolicyInfoAndRedirect = (inquiryType) => {
    if (!policyAsOfDate || !selectedPolicyNumber || policyAsOfDate === AO_DATEPICKER_INVALID_DATE_VALUE) {
      let message = '';

      if (!policyAsOfDate && !selectedPolicyNumber) {
        message = 'Enter a policy number and date to view policy.';
      } else if (!selectedPolicyNumber) {
        message = 'Enter policy number and try again.';
      } else if (!policyAsOfDate) {
        message = 'Enter a date and try again.';
      } else if (policyAsOfDate === AO_DATEPICKER_INVALID_DATE_VALUE) {
        message = 'Date is invalid. Check to ensure policy number and date was entered correctly and try again.';
      }
      setPolicyNumberNotFound(message);
      setIsPolicyNumberNotFoundAlertVisible(true);
      setPolicyInquiryLoading(false);
      setViewPolicyDocsLoading(false);
      return;
    }

    const request_body_for_policy_inquiry = getMSSPolicyInquiryRequestBody(policyAsOfDate, inquiryType, selectedPolicyNumber);

    // Call Proxy Policy Inquiry to get data for request body
    sendGetPolicyInquiry(
      request_body_for_policy_inquiry,
      setErrorGetPolicyInquiry,
      setIsGetPolicyInquiryAlertVisible,
      setPolicyInquiryLoading,
      setViewPolicyDocsLoading,
    ).then((policyInquiryPayload) => {
      // reset laoding filter
      setPolicyInquiryLoading(false);
      setViewPolicyDocsLoading(false);
      // if data is undefined (bad request) break out
      if (!_.isNil(policyInquiryPayload.data)) {
        // if 200 and policyNumber is null or undefined
        if (_.isNil(policyInquiryPayload.data.policyNumber) || _.isNil(policyInquiryPayload.data.acctType)) {
          setPolicyNumberNotFound(policyInquiryPayload.data.details);
          setIsPolicyNumberNotFoundAlertVisible(true);
        } else {
          const redirect_request_body = getMSSRedirectRequestBody(policyInquiryPayload, policyAsOfDate);

          redirectUrlFunction(redirect_request_body, 'POST', null, setPolicyInquiryRedirectError, setIsPolicyInquiryRedirectErrorVisible);
        }
      }
    });
  };

  const getMSEMSCPlusPolicyInfoAndRedirect = (policy_system) => {
    sendGetMSEMSCPlusPolicyInquiry(selectedPolicyNumber, setErrorGetPolicyInquiry, setIsGetPolicyInquiryAlertVisible, setPolicyInquiryLoading).then(
      (clmsePolicyPayload) => {
        // check to make sure there is data
        if (!_.isNil(clmsePolicyPayload) && !_.isNil(clmsePolicyPayload.data) && clmsePolicyPayload.data.length > 0) {
          // login to sbss
          const redirect_request_body = getMSEMSCPRedirectRequestBody(clmsePolicyPayload);

          redirectUrlFunction(redirect_request_body, 'POST', null, setPolicyInquiryRedirectError, setIsPolicyInquiryRedirectErrorVisible);
          setPolicyInquiryLoading(false);
          setViewPolicyDocsLoading(false);
        } else {
          // set warning message
          if (policy_system == 'policy-inquiry-main-street-express') {
            setPolicyNumberNotFound('Policy is not available in Main Street Express. Please modify your search and try again.');
          } else {
            setPolicyNumberNotFound('Policy is not available in Main Street Commercial+. Please modify your search and try again.');
          }
          setIsPolicyNumberNotFoundAlertVisible(true);
          setPolicyInquiryLoading(false);
          setViewPolicyDocsLoading(false);
        }
      },
    );
  };

  const handleClickSearch = () => {
    // reset any alerts
    resetAlerts();
    setPolicyInquiryLoading(true);
    if (selectedPolicySystem === 'policy-inquiry-main-street-station') {
      getMSSPolicyInfoAndRedirect(MSS_POLICY_INQUIRY);
    } else if (selectedPolicySystem === 'policy-inquiry-main-street-express') {
      getMSEMSCPlusPolicyInfoAndRedirect(selectedPolicySystem);
    } else if (selectedPolicySystem === 'policy-inquiry-main-street-commercial+') {
      getMSEMSCPlusPolicyInfoAndRedirect(selectedPolicySystem);
    } else {
      setPolicyNumberNotFound('Please select a Policy System to continue with a search.');
      setIsPolicyNumberNotFoundAlertVisible(true);
      setPolicyInquiryLoading(false);
    }
  };

  const handleClickViewPolicyDocuments = () => {
    setViewPolicyDocsLoading(true);
    getMSSPolicyInfoAndRedirect(MSS_VIEW_POLICY_DOCUMENTS);
  };

  const localStateMap = {
    selectedPolicySystem,
    selectedPolicyNumber,
    errorGetPolicyInquiry,
    isGetPolicyInquiryAlertVisible,
    isPolicyNumberNotFoundAlertVisible,
    policyInquiryLoading,
    policyNumberNotFound,
    policyInquiryRedirectError,
    isPolicyInquiryRedirectErrorVisible,
    isMSSPolicySystem: selectedPolicySystem === 'policy-inquiry-main-street-station',
    policyAsOfDate,
    viewPolicyDocsLoading,
  };

  const callBackFunctions = {
    handleChangeSelectedPolicySystem,
    handleChangeSelectedPolicyNumber,
    handleClickSearch,
    handleOnChangePolicyAsOfDate,
    handleClickViewPolicyDocuments,
  };

  if (loadingSd) return <CLSPolicyInquirySkeleton data-testid="skeleton" />;

  return <>{!_.isNil(UiDefinitionsData) && <ScreenSmith definition={UiDefinitionsData} functionMap={callBackFunctions} stateMap={localStateMap} />}</>;
};
export default CLSPolicyInquiry;
