import { makeStyles } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';

import { Alert, Box, Button, Card, Col, DataGrid, Divider, Link, Row, TextField, Typography } from 'components/AORedesign';
import {
  MSS_POLICY_INQUIRY,
  getMSEMSCPRedirectRequestBody,
  getMSSPolicyInquiryRequestBody,
  getMSSRedirectRequestBody,
  sendGetMSEMSCPlusPolicyInquiry,
  sendGetPolicyInquiry,
} from 'components/CommercialLinesServices/CLSPolicyInquiry';
import { redirectUrlFunction } from 'components/CommonUtilityServices/RedirectService';
import { handleFarmRanchRedirect } from 'components/Workflow/WorkflowCardFarmRanch';
import { GET_POLICY_NUMBER_SEARCH } from 'constants/api';

const DEFAULT_RESULTS = { data: null, loading: false };
const FARM_RANCH_EMAIL = 'FarmCustomer@msagroup.com';
const FARM_RANCH_PHONE = '833-561-3126';

const PAS_CLMSS = 'Main Street Station (CLMSS)';
const PAS_MSE = 'Main Street Express (MSE)';
const PAS_MSCP = 'Main Street Commercial+ (MSC+)';
const PAS_PLMSS = 'Main Street Station (PLMSS)';
const PAS_MSP = 'Main Street Protection+ (MSP+)';
const PAS_PCR = 'Personal Lines PCR';
const PAS_FR = 'Farm Ranch';

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}));

export const handleRedirectFromEnterprisePolicy = (policyNumber, PAS, setError) => {
  const todayDate = moment().format('MM/DD/yyyy');

  const handleCLMSEMSCPOnClickRedirect = async () => {
    // function to handle redirect for CL MSE and MSC+
    try {
      sendGetMSEMSCPlusPolicyInquiry(
        policyNumber,
        setError,
        () => {},
        () => {},
      ).then((clmsePolicyPayload) => {
        const redirect_request_body = getMSEMSCPRedirectRequestBody(clmsePolicyPayload);

        redirectUrlFunction(redirect_request_body, 'POST', null, setError);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleCLMSSOnClickRedirect = () => {
    // function to handle redirect for CL MSS
    const request_body_for_policy_inquiry = getMSSPolicyInquiryRequestBody(todayDate, MSS_POLICY_INQUIRY, policyNumber);
    sendGetPolicyInquiry(
      request_body_for_policy_inquiry,
      setError,
      () => {},
      () => {},
      () => {},
    )
      .then((policyInquiryPayload) => {
        const redirect_request_body = getMSSRedirectRequestBody(policyInquiryPayload, todayDate);
        redirectUrlFunction(redirect_request_body, 'POST', null, setError);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPLRequestPayload = () => ({
    Area: 'Personal Lines',
    TypeOfRedirect: 'View / Change Policy',
    DetailedContext: [
      {
        name: 'effectiveDate',
        value: todayDate,
      },
      {
        name: 'policyNumber',
        value: policyNumber,
      },
      {
        name: 'vendorSystem',
        value: false,
      },
    ],
  });

  const handlePLOnClickRedirect = (payload) => {
    redirectUrlFunction(payload, 'POST', null, setError);
  };

  const getPolicySystem = () => {
    // Function to map and render PAS type with source system to display text for in DataGrid results
    const policyData = {};
    let requestPayload = {};

    switch (PAS) {
      case 'msa_insurity_cl_auto': // CLMSS
      case 'MSA_WORKERS_COMP': // CLMSS
      case 'msa_bop': // CLMSS
        policyData.onClick = () => handleCLMSSOnClickRedirect();
        policyData.text = PAS_CLMSS;
        break;

      case 'homesite_majesco_v4': // MSE
        policyData.onClick = () => handleCLMSEMSCPOnClickRedirect();
        policyData.text = PAS_MSE;
        break;

      case 'mic': // MSC+
        policyData.onClick = () => handleCLMSEMSCPOnClickRedirect();
        policyData.text = PAS_MSCP;
        break;

      case 'msa_oneshield_pl': // PLMSS
        requestPayload = getPLRequestPayload();
        policyData.onClick = () => handlePLOnClickRedirect(requestPayload);
        policyData.text = PAS_PLMSS;
        break;

      case 'e1p_policy_center': // MSP+
        requestPayload = getPLRequestPayload();
        policyData.onClick = () => handlePLOnClickRedirect(requestPayload);
        policyData.text = PAS_MSP;
        break;

      case 'msa_legacy_pl_property': // Personal Lines PCR
      case 'msa_legacy_pl_auto': // Personal Lines PCR
      case 'msa_legacy_pl_umbrella': // Personal Lines PCR
        requestPayload = getPLRequestPayload();
        policyData.onClick = () => handlePLOnClickRedirect(requestPayload);
        policyData.text = PAS_PCR;
        break;

      case 'clpolicy_center': // Farm & Ranch
        policyData.text = PAS_FR;
        policyData.onClick = () => handleFarmRanchRedirect();
        break;

      default:
        break;
    }

    return policyData;
  };

  const policySystemLink = getPolicySystem();
  return policySystemLink;
};

const PolicySearch = () => {
  const [policyNumber, setPolicyNumber] = useState('');
  const [searchResults, setSearchResults] = useState(DEFAULT_RESULTS);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const classes = useStyles();

  const handlePolicyNumberOnChange = (e) => {
    setPolicyNumber(e.target.value);
  };

  const handleSearchOnClick = async () => {
    setLoading(true);
    setError(null);
    const sanitizedPolicyNumber = policyNumber.trim();

    if (sanitizedPolicyNumber.length) {
      setSearchResults({ ...DEFAULT_RESULTS, loading: true });
      try {
        const { data } = await axios.get(`${GET_POLICY_NUMBER_SEARCH}/${policyNumber}`);
        // Generate id for each row for MUI DataGrid rendering
        const rowsWithIds = data.policy.map((row) => ({ ...row, id: JSON.stringify(row) }));
        setSearchResults({ data: rowsWithIds, loading: false });
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
    }
    setLoading(false);
  };

  const gridColumns = [
    {
      field: 'policyNumber',
      minWidth: 200,
      headerName: 'Policy Number',
    },
    {
      field: 'policyHolder',
      minWidth: 250,
      headerName: 'Policyholder',
    },
    {
      field: 'PAS',
      headerName: 'Policy System',
      minWidth: 500,
      renderCell: (params) => {
        const { policyNumber, PAS } = params.row;
        const policySystemLink = handleRedirectFromEnterprisePolicy(policyNumber, PAS, setError);
        // If FarmRanch policy, do not render the standard redirect Link, but show to contact Farm and Ranch message
        if (policySystemLink.text === PAS_FR) {
          return (
            policySystemLink && (
              <Typography component="div">
                Contact
                <Box component="a" m={0.5} href={`mailto:${FARM_RANCH_EMAIL}`} color="secondary.main">
                  {FARM_RANCH_EMAIL}
                </Box>
                or call
                <Box component="a" m={0.5} href={`tel:${FARM_RANCH_PHONE}`} color="secondary.main">
                  {FARM_RANCH_PHONE}
                </Box>
              </Typography>
            )
          );
        } else {
          return (
            policySystemLink?.text && (
              <Link onClick={policySystemLink.onClick} endIcon={'openInNew'}>
                {policySystemLink.text}
              </Link>
            )
          );
        }
      },
    },
  ];

  return (
    <Card className={classes.fullHeight}>
      {error && (
        <Box mb={6}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Box m={-2}>
        <Box m={2}>
          <Box mb={3}>
            <Typography variant="h6">Policy Search</Typography>
          </Box>

          <Row spacing={2} alignItems="center">
            <Col>
              <TextField label="Policy Number" placeholder="Enter Policy Number" value={policyNumber} onChange={handlePolicyNumberOnChange} />
            </Col>
            <Col>
              <Box>
                <Button onClick={handleSearchOnClick} disabled={loading}>
                  Search
                </Button>
              </Box>
            </Col>
          </Row>
        </Box>

        {searchResults.data && (
          <>
            <Divider />
            <Box m={2}>
              <DataGrid
                autoHeight
                columns={gridColumns}
                loading={searchResults.loading}
                rows={searchResults.data}
                noResultsMessage="No results returned for this policy number. Please refine your search and try again."
              />
            </Box>
          </>
        )}
      </Box>
    </Card>
  );
};

export default PolicySearch;
