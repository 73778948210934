import React from 'react';
import PropTypes from 'prop-types';

import { Box, Select } from 'components/AORedesign';
import { GET_PERM_AGENCIES } from 'constants/api';
import useFetchSelectMenuItems from 'hooks/useFetchSelectMenuItems';

const AnnualMileageValidationReportAgentView = ({ menuItems, onChange, setAgencyCodeOptions, value }) => {
  useFetchSelectMenuItems(GET_PERM_AGENCIES, (response) => {
    setAgencyCodeOptions(
      response.data.map((location) => ({
        label: `${location.agencyName}-${location.agencyCode}`,
        value: location.agencyCode,
      })),
    );
  });

  return (
    <Box mb={2}>
      <Select
        id="amvr-agency-codes"
        labelId="amvr-agency-codes-label"
        label="Agency Codes"
        name="AgtNum"
        menuItems={menuItems || []}
        onChange={onChange}
        value={value}
      />
    </Box>
  );
};

AnnualMileageValidationReportAgentView.propTypes = {
  menuItems: PropTypes.array,
  onChange: PropTypes.func,
  setAgencyCodeOptions: PropTypes.func,
  value: PropTypes.string,
};

export default AnnualMileageValidationReportAgentView;
