import React from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';
import { Box, Divider, useTheme, useMediaQuery } from '@material-ui/core';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import { NavigationHoverMenu } from '../NavigationHoverMenu';
import { NavigationSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';

import { StyledNavigation } from './Navigation.styled';

// /NavigationMenu
const Navigation = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('NavigationMenu');

  if (loadingSd && !isSmall) return <NavigationSkeleton />;

  // if (error) return <p>Error!</p>;

  return (
    <>
      <Divider />
      {!isSmall && (
        <StyledNavigation
          display="flex"
          justifyContent="center"
          flexDirection="column"
          id="navigation-menu-container">
          <Box display="flex">
            {!_.isNil(UiDefinitionsData) &&
              UiDefinitionsData[0]?.Children?.map((menu) => {
                if (menu.ComponentType === 'MenuButton') {
                  const menuSd = [menu];
                  return <ScreenSmith definition={menuSd} key={cuid()} />;
                } else {
                  return (
                    <NavigationHoverMenu
                      key={cuid()}
                      id={menu.UiElementId}
                      title={menu.Representation}
                      subMenu={menu.Children}
                    />
                  );
                }
              })}
          </Box>
        </StyledNavigation>
      )}
    </>
  );
};

Navigation.propTypes = {
  children: PropTypes.node,
};

export default Navigation;
