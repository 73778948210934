import axios from 'axios';
import { ALL_STATES_FOR_NEWS, ADD_TEXT_NEWS } from '../../../constants/api';

const getStates = async () => {
  const { data } = await axios.get(`${ALL_STATES_FOR_NEWS}`);
  return data;
};

const submitTextNews = async (_payload) => await axios.post(ADD_TEXT_NEWS, { ..._payload });

export { getStates, submitTextNews };
