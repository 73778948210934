import React from 'react';

import { ViewContainer } from 'components/AORedesign';
import { ROUTE_REDESIGN_RESOURCES } from 'constants/routes';
import { StormReference } from 'components/StormReference';

const StormResources = () => {
  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_RESOURCES, text: 'Resources' }} hideStartQuote hidePolicyViewChange>
      <StormReference />
    </ViewContainer>
  );
};

export default StormResources;
