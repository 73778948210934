import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import useAxios from 'axios-hooks';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Typography } from 'components/AORedesign';
import { handleFileDownload } from 'components/CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import NewsArticleModal from 'components/NewsArticles/NewsArticleModal';
import { GET_NEWS, NEWS_ARTICLES } from 'constants/api';
import { ANNOUNCEMENT_TYPES } from 'constants/static';
import { hideDashboardAlert, selectAnnouncements } from 'redux/app-store';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: 0,
    flexDirection: 'column',
    gap: '8px',
  },
}));

const DashboardAlerts = () => {
  const { announcements: dashboardAlerts } = useSelector((state) => selectAnnouncements(state, [ANNOUNCEMENT_TYPES.ALERT_URGENT]));
  const { hiddenDashboardAlerts } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const classes = useStyles();
  // state to show/hide dialog
  const [open, setOpen] = useState(false);
  // axios hook that is manually executed with
  // executeNewsReview
  const [{ data: dataNewsReview, loading: loadingNewsReview, error: errorNewsReview }, executeNewsReview] = useAxios();

  const handleAlertOnClose = (seqId) => {
    // Once the user closes this alert we do not want to show it until their next session
    dispatch(hideDashboardAlert(seqId));
  };

  // event handler when opening modal
  const handleOpenNewsArticle = (id, ext) => {
    const trimmedExt = _.trim(ext); // remove whitespace
    if (_.isEmpty(trimmedExt)) {
      setOpen(true);
      executeNewsReview({
        url: `${GET_NEWS}${NEWS_ARTICLES}/${id}`,
      });
    } else {
      const fileURL = `${NEWS_ARTICLES}/${id}`;
      if (trimmedExt === 'pdf') {
        handlePdfDownload(
          'GET',
          null,
          fileURL,
          () => {},
          () => {},
          () => {},
          'newWindow',
          `${GET_NEWS}`,
        );
      } else {
        handleFileDownload(
          fileURL,
          () => {},
          () => {},
          () => {},
          'newWindow',
          'GET',
          undefined,
          `${GET_NEWS}`,
        );
      }
    }
  };

  // event handler to close modal
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      {dashboardAlerts && (
        <Box className={classes.container} mb={3}>
          {dashboardAlerts.map((alert, index) => {
            const showAlert = !hiddenDashboardAlerts[alert.seqId];
            return (
              showAlert && (
                <Box key={`${alert.seqId}-${index}`} my={-2}>
                  <Alert onClose={() => handleAlertOnClose(alert.seqId)} severity="warning" icon={<ErrorOutlineIcon />}>
                    <Box onClick={() => handleOpenNewsArticle(alert.seqId, alert.ext)} style={{ cursor: 'pointer' }}>
                      <Typography>{alert.title}</Typography>
                    </Box>
                  </Alert>
                </Box>
              )
            );
          })}
        </Box>
      )}
      <NewsArticleModal open={open} handleClose={handleCloseModal} loading={loadingNewsReview} error={errorNewsReview} data={dataNewsReview} />
    </>
  );
};

export default DashboardAlerts;
