import React from 'react';
import PropTypes from 'prop-types';
import MuiCheckbox from '@material-ui/core/Checkbox';

import { componentIdStringGenerator } from '../../../helpers';

const Checkbox = (props) => {
  return (
    <MuiCheckbox
      {...props}
      id={componentIdStringGenerator('Checkbox', props.id ? props.id : props.label ?? '')}
    />
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};

export default Checkbox;
