import React from 'react';
import cuid from 'cuid';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import moment from 'moment';

import { currencyStringFormatter } from '../../../../helpers';

const PostPaymentConfirmation = ({ tableRows, paymentTotal }) => {
  const paymentCount = tableRows.length;
  return (
    <Box>
      <Box>
        <Typography
          paragraph>{`This acknowledges that you have entered ${paymentCount} payment(s) for a total amount of ${currencyStringFormatter(
          paymentTotal,
        )}. This amount will be swept from your agency account.`}</Typography>
        <Typography
          paragraph>{`Below is a summary of the payments made. Click on the confirmation number to produce a printable receipt for that payment.`}</Typography>
        <Typography paragraph>{`Today's Date: ${moment().format('MMMM Do YYYY')}`}</Typography>
      </Box>
      <br />
      <hr />
      <br />
      <Box>
        {tableRows.map(({ payIndex, accountName, accountNumber, paymentAmount }) => (
          <Grid container key={cuid()} spacing={2}>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="flex-end">
                <Typography>{`Confirmation # ${payIndex}`}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography>{`Account Name: ${accountName}`}</Typography>
                <Typography>{`Account #: ${accountNumber}`}</Typography>

                <Typography>{`Payment Amount: ${currencyStringFormatter(
                  paymentAmount,
                )}`}</Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

PostPaymentConfirmation.propTypes = {
  paymentTotal: PropTypes.string,
  tableRows: PropTypes.node,
};

export default PostPaymentConfirmation;
