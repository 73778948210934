import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Typography, Box, Paper } from '@material-ui/core';
import _ from 'lodash';

import {
  AGENCY_ADMIN_GET_POTENTIAL_AGENCIES,
  USER_ADMIN_UPDATE_USER,
  USER_ADMIN_GET_USER_INFO,
  AGENCY_ADMIN_REMOVE,
} from '../../../../constants/api';
import { EditUserAgenciesContext } from '../../context/userAdmin.context';
import Row from '../../../ScreenSmith/Row';
import Col from '../../../ScreenSmith/Col';
import useScreenDefinition from '../../../../hooks/useScreenDefinition';
import { ScreenSmith } from '../../../ScreenSmith';
import EditUserAgencies from '../../components/EditUserAgencies';
import { AOAlert } from '../../../AOAlert';

const EditUserAgenciesPanel = ({ userDetails, setUserDetails }) => {
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [currentAgenciesCheckedState, setCurrentAgenciesCheckedState] = useState([]);
  const [agencyNameValue, setAgencyNameValue] = useState('');
  const [potentialAgencies, setPotentialAgencies] = useState(null);
  const [searchButtonLoading, setSearchButtonLoading] = useState(false);
  const [viewAllChecked, setViewAllChecked] = useState(false);
  const [infoMessage, setInfoMessage] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { UiDefinitionsData } = useScreenDefinition(`EditUserAgenciesInternalViewAll`);

  useEffect(() => {
    if (userDetails?.ininetViewAll === 'true') {
      setViewAllChecked(true);
    }
  }, [userDetails?.ininetViewAll]);

  const handleCurrentAgenciesCheckedStateOnChange = (e) => {
    const { value, checked } = e.target;

    let newState = [...currentAgenciesCheckedState];
    if (checked) {
      newState.push(value);
    } else {
      newState = newState.filter((i) => i !== value);
    }
    setCurrentAgenciesCheckedState(newState);
  };

  const handleDeleteOnClick = async () => {
    setInfoMessage(null);

    try {
      const getUserDetailsUrl = `${AGENCY_ADMIN_REMOVE}`;
      const { status } = await axios.put(getUserDetailsUrl, {
        agencies: currentAgenciesCheckedState,
        userName: userDetails.name,
      });

      if (status === 200) {
        setCurrentAgenciesCheckedState([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAgencyNameOnChange = (e) => {
    setAgencyNameValue(e.target.value);
  };

  const handleSearchOnClick = async () => {
    setInfoMessage(null);
    setSearchButtonLoading(true);
    try {
      const searchQueryString = `?userName=${userDetails.name}&agencyName=${agencyNameValue}`;
      const getUserDetailsUrl = `${AGENCY_ADMIN_GET_POTENTIAL_AGENCIES}${searchQueryString}`;
      const details = await axios.get(getUserDetailsUrl);
      setPotentialAgencies(details.data);
    } catch (error) {
      console.error(error);
    }
    setSearchButtonLoading(false);
  };

  const handleClearOnClick = () => {
    setAgencyNameValue('');
    setPotentialAgencies(null);
    setInfoMessage(null);
  };

  const handleViewAllOnChange = (e) => {
    setViewAllChecked(e.target.checked);
  };

  const handleSaveOnClick = async () => {
    const userDetailsCopy = { ...userDetails };
    userDetailsCopy.ininetViewAll = viewAllChecked ? 'true' : 'false';
    setInfoMessage(null);
    setButtonLoading(true);

    try {
      const { status } = await axios.put(USER_ADMIN_UPDATE_USER, userDetailsCopy);

      if (status === 200) {
        setInfoMessage({
          severity: 'info',
          message: 'Updated. Please wait at least one hour before making further updates.',
        });

        const userName = userDetails?.name;
        const getUserDetailsUrl = `${USER_ADMIN_GET_USER_INFO}/${userName}`;
        const details = await axios.get(getUserDetailsUrl);
        setUserDetails(details.data);
      }
    } catch (error) {
      console.error(error);
      setInfoMessage({
        severity: 'error',
        message: error.message,
      });
    }
    setButtonLoading(false);
  };

  const callBackFunctions = { handleSaveOnClick, handleViewAllOnChange };

  const localStateMap = { buttonLoading, viewAllChecked };

  return (
    <Box minWidth={800}>
      {infoMessage && <AOAlert severity={infoMessage?.severity} message={infoMessage?.message} />}
      <Row spacing={2}>
        <Col xs={5}>
          <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
            <Box p={1}>
              <Typography>
                <Box fontSize={'1.25rem'} fontWeight={'bold'}>
                  Name
                </Box>
              </Typography>
            </Box>
            <Box p={1} display="flex">
              <Box ml={2}>
                <Typography gutterBottom>
                  <Box textAlign={'right'} mr={2}>
                    First Name:
                  </Box>
                </Typography>
                <Typography gutterBottom>
                  <Box textAlign={'right'} mr={2}>
                    Last Name:
                  </Box>
                </Typography>
                <Typography gutterBottom>
                  <Box textAlign={'right'} mr={2}>
                    User Name:
                  </Box>
                </Typography>
              </Box>
              <Box>
                <Typography gutterBottom>
                  <Box fontWeight={'bold'} color={'primary.main'}>
                    {userDetails?.givenName}
                  </Box>
                </Typography>
                <Typography gutterBottom>
                  <Box fontWeight={'bold'} color={'primary.main'}>
                    {userDetails?.sn}
                  </Box>
                </Typography>
                <Typography gutterBottom>
                  <Box fontWeight={'bold'} color={'primary.main'}>
                    {userDetails?.name ?? 'To do'}
                  </Box>
                </Typography>
              </Box>
            </Box>

            <Box px={1} pb={1} display="flex">
              {
                <>
                  {!_.isNil(UiDefinitionsData) && (
                    <ScreenSmith
                      definition={UiDefinitionsData}
                      functionMap={callBackFunctions}
                      stateMap={localStateMap}
                    />
                  )}
                </>
              }
            </Box>
          </Paper>
        </Col>
        <EditUserAgenciesContext.Provider
          value={{
            userDetails,
            currentAgencies,
            setCurrentAgencies,
            agencyNameValue,
            potentialAgencies,
            handleCurrentAgenciesCheckedStateOnChange,
            handleDeleteOnClick,
            handleAgencyNameOnChange,
            handleSearchOnClick,
            handleClearOnClick,
            currentAgenciesCheckedState,
            searchButtonLoading,
          }}>
          <EditUserAgencies />
        </EditUserAgenciesContext.Provider>
      </Row>
    </Box>
  );
};

EditUserAgenciesPanel.propTypes = {
  userDetails: PropTypes.object,
  setUserDetails: PropTypes.func,
};

export default EditUserAgenciesPanel;
