import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import { AOTextField } from '../../AOTextField';
import useInitializeFilter from './useInitializeFilter';
import { getSplitStringSearchCriteria } from './filterHelpers';

const INITIAL_VALUE = '';

const Search = ({ handleSetSearchCriteria, setInitialQuery, setResetFunc, typeOfContainer }) => {
  const [searchString, setSearchString] = useState(INITIAL_VALUE);

  const resetComponent = () => setSearchString(INITIAL_VALUE);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    handleSetSearchCriteria(searchCriteriaKey, getSplitStringSearchCriteria(searchString, searchCriteriaKey));
  };

  const handleClearOnClick = () => {
    resetComponent();
    handleSetSearchCriteria(searchCriteriaKey, INITIAL_VALUE);
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    // disallow certain characters
    if (value.match(/^[^><(){}[\]"*:!?+-/\\~^=]*$/)) {
      setSearchString(e.target.value);
    }
  };

  const { filterSpec } = useInitializeFilter(typeOfContainer, 'Title', setInitialQuery);

  // on initialization, assign the reset pointer to ref
  useEffect(() => {
    if (searchCriteriaKey?.length > 0) {
      setResetFunc(searchCriteriaKey, resetComponent);
    }
  }, [filterSpec]);

  const searchCriteriaKey = filterSpec?.searchTerm;

  return (
    <form onSubmit={handleOnSubmit}>
      <AOTextField
        sx={{ paddingRight: '0px' }}
        id="brandfolder-search-textfield"
        label="Document Search"
        name={searchCriteriaKey}
        disabled={!searchCriteriaKey}
        value={searchString}
        onChange={handleOnChange}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton variant="outlined" onClick={handleClearOnClick} edge="end">
                {searchString.length > 0 ? <ClearIcon /> : <SearchIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

Search.propTypes = {
  handleSetSearchCriteria: PropTypes.func,
  setInitialQuery: PropTypes.func,
  setResetFunc: PropTypes.func,
  typeOfContainer: PropTypes.oneOf(['Underwriting', 'Marketing', 'Training']),
};

export default Search;
