import axios from 'axios';
import { GET_ANNOUNCEMENTS_ITEMS } from 'constants/api';
import { ANNOUNCEMENT_TYPES } from 'constants/static';

const AOC_API_ANNOUNCEMENTS_URL = `${GET_ANNOUNCEMENTS_ITEMS}?newsSection=${ANNOUNCEMENT_TYPES.ANNOUNCEMENT_HOME_PAGE}&newsSection=${ANNOUNCEMENT_TYPES.ANNOUNCEMENT_PL}&newsSection=${ANNOUNCEMENT_TYPES.ANNOUNCEMENT_CL}&newsSection=${ANNOUNCEMENT_TYPES.ALERT_URGENT}&newsSection=${ANNOUNCEMENT_TYPES.ALERT_REGULAR}`;

export const getAnnouncements = async () => {
  const { data } = await axios.get(AOC_API_ANNOUNCEMENTS_URL);
  return data;
};

export default { getAnnouncements };
