import React from 'react';
import PropTypes from 'prop-types';

import RowCol from '../ScreenSmith/RowCol';
import { AOAutocomplete } from '../AOAutocomplete';
import { GET_APNE_URL } from '../../constants/api';
import useFetchSelectMenuItems from '../../hooks/useFetchSelectMenuItems';

const APnEReportInternalUserView = ({
  customerNumber,
  customerNumberInputValue,
  customerOptions,
  filterOptions,
  getOptionLabel,
  handleCustomerOnBlur,
  handleCustomerOnChange,
  handleCustomerOnInputChange,
  handleLocationOnBlur,
  handleLocationOnChange,
  handleLocationOnInputChange,
  locationNumber,
  locationNumberInputValue,
  locationOptions,
  setCustomerOptions,
  setLocationOptions,
}) => {
  useFetchSelectMenuItems(`${GET_APNE_URL}/customers`, (response) => {
    setCustomerOptions(
      response.data.map((customer) => ({
        value: `${customer.customerName?.trim()} - ${customer.customerNumber}`,
        code: customer.customerNumber,
      })),
    );
  });

  useFetchSelectMenuItems(`${GET_APNE_URL}/locations`, (response) => {
    setLocationOptions(
      response.data.map((location) => ({
        value: `${location.locationName?.trim()} - ${location.locationNumber}`,
        code: location.locationNumber,
      })),
    );
  });

  return (
    <>
      <RowCol mb={2}>
        <AOAutocomplete
          filterOptions={filterOptions}
          getOptionLabel={getOptionLabel}
          handleOnChange={handleCustomerOnChange}
          handleOnInputChange={handleCustomerOnInputChange}
          id="apne-customer"
          inputValue={customerNumberInputValue}
          label="Customer"
          labelId="apne-customer-label"
          onBlur={handleCustomerOnBlur}
          options={customerOptions}
          placeholder="Select Customer or Start Typing"
          value={customerNumber}
        />
      </RowCol>
      <RowCol mb={2}>
        <AOAutocomplete
          filterOptions={filterOptions}
          getOptionLabel={getOptionLabel}
          handleOnChange={handleLocationOnChange}
          handleOnInputChange={handleLocationOnInputChange}
          id="apne-location"
          inputValue={locationNumberInputValue}
          label="Location"
          labelId="apne-location-label"
          onBlur={handleLocationOnBlur}
          options={locationOptions}
          placeholder="Select Agency Location or Start Typing"
          value={locationNumber}
        />
      </RowCol>
    </>
  );
};

APnEReportInternalUserView.propTypes = {
  customerNumber: PropTypes.string,
  customerNumberInputValue: PropTypes.string,
  customerOptions: PropTypes.array,
  filterOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  handleCustomerOnBlur: PropTypes.func,
  handleCustomerOnChange: PropTypes.func,
  handleCustomerOnInputChange: PropTypes.func,
  handleLocationOnBlur: PropTypes.func,
  handleLocationOnChange: PropTypes.func,
  handleLocationOnInputChange: PropTypes.func,
  locationNumber: PropTypes.string,
  locationNumberInputValue: PropTypes.string,
  locationOptions: PropTypes.array,
  setCustomerOptions: PropTypes.func,
  setLocationOptions: PropTypes.func,
};

export default APnEReportInternalUserView;
