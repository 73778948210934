import { styled } from '@material-ui/core';
import { AOButton } from '../AOButton';

const StyledAOLinkButton = styled(AOButton)(({ theme }) => ({
  padding: theme.spacing(0),
  textTransform: 'none',
  color: theme.palette.secondary.main,
}));

export { StyledAOLinkButton };
