import { Skeleton } from '@material-ui/lab';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Box, Typography } from 'components/AORedesign';
import { ClientCard, ClientCardSkeleton, DEFAULT_PARAMS, FILTER_TYPES, NAME_SEARCH_MINIMUM } from 'components/AORedesignViews/Clients';
import { GET_CLIENTS_ACCOUNTS } from 'constants/api';
import featureFlags from 'constants/featureFlags';
import agentContext from 'contexts/agent.context';
import AppDataContext from 'contexts/appData.context';

const ClientResults = ({ filterString, handleSetFilter }) => {
  const { Locations, FeatureFlags } = useContext(agentContext);
  const { dashboard } = useContext(AppDataContext);

  const LESS_THAN_EQUAL_LOCATION_LIMIT = Locations?.length <= dashboard?.locationCodeLimit; // small agency case is if it has less than or equal to 6 locations
  const INTERNAL_USER = FeatureFlags?.[featureFlags.ACCESS_USER_INTERNAL];
  const AGENT_USER = FeatureFlags?.[featureFlags.ACCESS_USER_IS_AGENT];

  const [clientList, setClientList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    // useEffect dependency array relies on filterString, which is the stringified version of filters object because
    // watching a string for changes guarantees consistency instead of and object which is a pointer in memory
    const filters = JSON.parse(filterString);

    // Must have agency/location code or name present to fetch results
    if (filters[FILTER_TYPES.AGENCY_CODES]?.length > 0 || filters[FILTER_TYPES.NAME_SEARCH]?.length >= NAME_SEARCH_MINIMUM) {
      const abortController = new AbortController();

      (async () => {
        try {
          let newClientList = clientList;
          if (filters[FILTER_TYPES.PAGE_NUMBER] === DEFAULT_PARAMS[FILTER_TYPES.PAGE_NUMBER]) {
            setLoading(true);
            newClientList = [];
            setClientList(null);
          }

          const { data } = await axios.get(GET_CLIENTS_ACCOUNTS, {
            params: filters,
            signal: abortController.signal,
            paramsSerializer: { indexes: null },
          });

          const results = data.Clients;

          if (abortController.signal.aborted) {
            return;
          }

          setClientList([...newClientList, ...results]);
          setHasMore(results.length === DEFAULT_PARAMS[FILTER_TYPES.ITEM_PER_PAGE]);
          setLoading(false);
        } catch (error) {
          console.error(error);
          // if AWS API Gateway Timeout Error
          if (error.message === 'Network Error') {
            setClientList([]);
          }

          // if error is not due to cancelation, set loading to false
          if (error.code !== 'ERR_CANCELED') {
            setLoading(false);
          }
        }
      })();

      return () => {
        abortController.abort();
      };
    }
  }, [filterString]);

  const scrollToFetch = async () => {
    handleSetFilter('', '', true);
  };

  const renderResults = () => {
    if (clientList?.length > 0) {
      return (
        <InfiniteScroll dataLength={clientList?.length ?? 0} next={scrollToFetch} hasMore={hasMore} loader={<Skeleton height={100} animation="wave" />}>
          {clientList?.map((account, index) => (
            <ClientCard key={`${index}-${account.AccountNumber}`} account={account} />
          ))}
        </InfiniteScroll>
      );
    } else if (clientList?.length === 0) {
      return (
        <Box mt={15} display="flex" justifyContent="center">
          <Typography>No results found. Adjust your search or filters.</Typography>
        </Box>
      );
    } else if ((INTERNAL_USER || (AGENT_USER && !LESS_THAN_EQUAL_LOCATION_LIMIT)) && clientList === null) {
      return (
        <Box mt={15} display="flex" justifyContent="center">
          <Typography>Search by agency code or named insured.</Typography>
        </Box>
      );
    } else {
      return null;
    }
  };

  return loading ? <ClientCardSkeleton /> : renderResults();
};

ClientResults.propTypes = {
  filterString: PropTypes.string,
  handleSetFilter: PropTypes.func,
};

export default ClientResults;
