import { Box, Link, RouteLink, Typography } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import { ROUTE_REDESIGN_APP_ADMIN, ROUTE_REDESIGN_USER_ADMIN } from 'constants/routes';
import React from 'react';

/**
 * Tools component provides links to various tools and administration pages.
 */
const Tools = () => {
  return (
    <>
      {/* Section heading */}
      <Box mb={2}>
        <Typography variant="h6">Tools</Typography>
      </Box>

      {/* Link to Brandfolder only visible to employees */}
      <Box mt={1}>
        <Link href="https://brandfolder.com/main-street-america-insurance/msa-employee-collection" endIcon="openInNew">
          Brandfolder
        </Link>
      </Box>

      {/* Link to User Administration (visible based on feature flag) */}
      <Feature allowed={featureFlags.ACCESS_USER_ADMIN}>
        <Box mt={1}>
          <RouteLink route={ROUTE_REDESIGN_USER_ADMIN}>User Administration</RouteLink>
        </Box>
      </Feature>

      {/* Link to Application Administration (visible based on feature flag) */}
      <Feature allowed={featureFlags.ACCESS_APP_ADMIN}>
        <Box mt={1}>
          <RouteLink route={ROUTE_REDESIGN_APP_ADMIN}>Application Administration</RouteLink>
        </Box>
      </Feature>
    </>
  );
};

export default Tools;
