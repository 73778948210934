import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Box, Autocomplete, TextField, Radio, Typography } from 'components/AORedesign';

const CCReportInternalUserView = ({
  options,
  handleOnChange,
  handleOnInputChange,
  inputValue,
  onBlur,
  onChange,
  resetAgencyCode,
  searchByType,
  setSearchByType,
  value,
}) => {
  const handleRadioOnChange = (e) => {
    setSearchByType(e.target.value);
    resetAgencyCode();
  };

  return (
    <>
      <Box mb={2}>
        <Typography>Search By:</Typography>
        <FormControl>
          <RadioGroup onChange={handleRadioOnChange} value={searchByType} aria-label="search-by-type" name="search-by-type" row>
            <Radio id="cc-chargeback-report-radio-name" value="name" label="Agency Name" />
            <Radio id="cc-chargeback-report-radio-code" value="code" label="Agency Code" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box mb={2}>
        {searchByType === 'name' ? (
          <Autocomplete
            style={{ width: 400 }}
            value={value}
            options={options || []}
            inputValue={inputValue}
            onChange={handleOnChange}
            onInputChange={handleOnInputChange}
            renderInput={(
              params, // ADDED / MODIFIED
            ) => <TextField {...params} label="Agency Name" placeholder="Enter Agency Name" variant="outlined" onBlur={onBlur} />}
          />
        ) : (
          <TextField label="Agency Code" name="agencyCode" onChange={onChange} placeholder="Enter Agency Code" value={value} />
        )}
      </Box>
    </>
  );
};

CCReportInternalUserView.propTypes = {
  options: PropTypes.array,
  handleOnChange: PropTypes.func,
  handleOnInputChange: PropTypes.func,
  inputValue: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  resetAgencyCode: PropTypes.func,
  searchByType: PropTypes.string,
  setSearchByType: PropTypes.func,
  value: PropTypes.string,
};

export default CCReportInternalUserView;
