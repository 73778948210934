import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Select } from 'components/AORedesign';
import { FILTER_TYPES, SELECT_ALL_VALUE } from 'components/AORedesignViews/Clients';
import { GET_CLIENTS_PRODUCTS } from 'constants/api';

const ClientProductFilter = ({ filters, handleSetFilter }) => {
  const [productMenuItems, setProductMenuItems] = useState([]);

  const productValue = filters?.[FILTER_TYPES.PRODUCT];

  // call api to get Product dropdown values
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(GET_CLIENTS_PRODUCTS);
        setProductMenuItems([{ label: 'All Products', value: SELECT_ALL_VALUE }, ...data]);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleProductOnChange = (e) => {
    const { name, value } = e.target;
    handleSetFilter(name, value);
  };

  return (
    <Select
      label="Product"
      onChange={handleProductOnChange}
      menuItems={productMenuItems}
      name={FILTER_TYPES.PRODUCT}
      value={productValue}
      disabled={productMenuItems === null}
    />
  );
};

ClientProductFilter.propTypes = {
  filters: PropTypes.object,
  handleSetFilter: PropTypes.func,
};

export default ClientProductFilter;
