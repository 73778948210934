import React from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';
import { Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import {
  StyledArticlePreviewContainer,
  SingleLinePreview,
  StyledButtonBase,
} from './NewsArticles.styled';

const ArticlePreview = ({ displayStartDate, handleOpen, newsType, title, previewSingleLine }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StyledArticlePreviewContainer
      display={isSmall ? 'block' : 'flex'}
      mb={isSmall ? 2 : 'inherit'}
      key={cuid()}>
      {displayStartDate && (
        <Box mr={2}>
          <Typography>{displayStartDate}</Typography>
        </Box>
      )}
      <StyledButtonBase onClick={handleOpen} title={title}>
        <Typography
          color={newsType === 'yellow-news' ? 'initial' : 'secondary'}
          align={isSmall ? 'left' : 'inherit'}>
          {previewSingleLine ? (
            <SingleLinePreview dangerouslySetInnerHTML={{ __html: title }} />
          ) : (
            <Box dangerouslySetInnerHTML={{ __html: title }} />
          )}
        </Typography>
      </StyledButtonBase>
    </StyledArticlePreviewContainer>
  );
};

ArticlePreview.propTypes = {
  displayStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleOpen: PropTypes.func,
  title: PropTypes.string,
  newsType: PropTypes.oneOf(['inside-news', 'yellow-news', null]),
  previewSingleLine: PropTypes.bool,
};

export default ArticlePreview;
