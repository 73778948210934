import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { ViewContainer } from '../Layout';
import { AOButton } from '../AOButton';

const AgentAdminViewContainer = ({ children, title, returnButtonRoute, returnButtonText }) => {
  return (
    <ViewContainer title={title}>
      <Box mb={2} display={'flex'} justifyContent={'flex-end'}>
        <AOButton
          color={'secondary'}
          route={returnButtonRoute}
          id="agent-admin-container-button-back">
          {returnButtonText}
        </AOButton>
      </Box>
      {children}
    </ViewContainer>
  );
};

AgentAdminViewContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  returnButtonRoute: PropTypes.string,
  returnButtonText: PropTypes.string,
};

export default AgentAdminViewContainer;
