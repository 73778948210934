import React, { useContext } from 'react';
import cuid from 'cuid';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  ListSubheader,
} from '@material-ui/core';

import { StyledAOSelect } from './AOSelect.styled';
import agentContext from '../../contexts/agent.context';

const AOSelect = ({
  children,
  disabled,
  displayLabel,
  id,
  inputLabel,
  invalidSearch,
  labelId,
  menuItems,
  menuItemGroups,
  value,
  ...props
}) => {
  const { FeatureFlags } = useContext(agentContext);

  const renderedMenuItems = (_displayLabel, _menuItems) => {
    return _menuItems.map(({ value, code, featureFlag }) => {
      const renderMenuItem = (
        <MenuItem key={cuid()} name={value} value={code} style={{ minHeight: '2rem' }}>
          {value}
        </MenuItem>
      );
      return featureFlag ? (FeatureFlags[featureFlag] && renderMenuItem) ?? null : renderMenuItem;
    });
  };

  const renderedMenuItemGroups = (_menuItemGroups) => {
    let itemGroups = [];
    Object.entries(_menuItemGroups).map(([key, val]) => {
      const menuItems = renderedMenuItems('', val.menuItems);
      const renderListSubheader = <ListSubheader key={key}>{key}</ListSubheader>;
      return val.headerFeatureFlag
        ? (FeatureFlags[val.headerFeatureFlag] &&
            itemGroups.push([renderListSubheader, menuItems])) ??
            null
        : itemGroups.push([renderListSubheader, menuItems]);
    });
    return itemGroups;
  };

  return (
    <StyledAOSelect disabled={disabled}>
      <FormControl variant="outlined" error={invalidSearch ? true : false}>
        <InputLabel id={labelId}>{inputLabel}</InputLabel>
        <Select
          labelId={labelId}
          disabled={disabled}
          label={inputLabel}
          id={id}
          value={value}
          autoWidth
          {...props}>
          {displayLabel && (
            <MenuItem value="" disabled>
              <em>{displayLabel}</em>
            </MenuItem>
          )}
          {!_.isNil(menuItems) && renderedMenuItems(displayLabel, menuItems)}
          {!_.isNil(menuItemGroups) && renderedMenuItemGroups(menuItemGroups)}
          {_.isNil(menuItems) && _.isNil(menuItemGroups) && children}
        </Select>
        {invalidSearch && <FormHelperText>{invalidSearch}</FormHelperText>}
      </FormControl>
    </StyledAOSelect>
  );
};

AOSelect.propTypes = {
  Action: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  displayLabel: PropTypes.string,
  id: PropTypes.string,
  inputLabel: PropTypes.string,
  invalidSearch: PropTypes.string,
  labelId: PropTypes.string,
  menuItems: PropTypes.array,
  menuItemGroups: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default AOSelect;
