import React from 'react';

import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import { PaymentBalance as PaymentBalanceWidget } from 'components/AORedesignViews/Reports/BillingReports/PaymentBalance';

const PaymentBalance = () => {
  return (
    <Feature allowed={featureFlags.ACCESS_PAYMENT_BALANCE_REPORT}>
      <PaymentBalanceWidget title="Payment Balance" />
    </Feature>
  );
};

export default PaymentBalance;
