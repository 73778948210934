import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCachedPolicy, setAppSnackbarProps } from 'redux/app-store';

import { Box, Col, Divider, Row, Typography } from 'components/AORedesign';
import { handleRedirectFromEnterprisePolicy } from '../Home/PolicySearch';
import PolicyDetailsCardContainer from './PolicyDetailsCardContainer';
import PolicyDetailsCardSkeleton from './PolicyDetailsCardSkeleton';

const CoveragesCard = () => {
  const cachedPolicy = useSelector(selectCachedPolicy);
  const dispatch = useDispatch();
  // pointer to policy
  const policy = cachedPolicy.policy;

  const handleOnClick = () => {
    // redirect to policy source system
    const redirectData = handleRedirectFromEnterprisePolicy(policy.policy.policyNumber, policy.policy.sourceSystemName, (isError) => {
      // on error, show the red snackbar
      if (isError) {
        dispatch(
          setAppSnackbarProps({
            message: 'The service is currently not available. Please try again later.',
            severity: 'error',
          }),
        );
      }
    });
    redirectData.onClick();
  };

  const renderCoverages = (coverages) => {
    return coverages.map(
      (item, index) =>
        item.name && (
          <>
            <Box key={JSON.stringify(item)} py={2} pt={index === 0 ? 0 : 2}>
              <Row spacing={2}>
                <Col xs={6}>
                  <Typography>{item.name}</Typography>
                </Col>
                {item.limitValue && item.valueLabel && (
                  <Col xs={6}>
                    <Typography color="textSecondary">{`${item.limitValue} ${item.valueLabel}`}</Typography>
                  </Col>
                )}
              </Row>
            </Box>
            {index < coverages?.length - 1 && <Divider />}
          </>
        ),
    );
  };

  const renderContent = () => {
    if (cachedPolicy.retrieveStatus === 'RETRIEVING') {
      return <PolicyDetailsCardSkeleton />;
    } else if (cachedPolicy.retrieveStatus === 'RETRIEVED' && policy.coverages) {
      let hasCoverages = false;
      let hasMultiCoverages = false;
      if (policy?.coverages?.length > 0) {
        hasCoverages = true;
      }
      if (policy?.stateCoverages?.length > 0) {
        policy.stateCoverages.forEach((element) => {
          if (element?.coverages?.length > 0) {
            hasCoverages = true;
            hasMultiCoverages = true;
          }
        });
      }
      if (policy?.properties?.length > 0) {
        policy.properties.forEach((element) => {
          if (element?.coverages?.length > 0) {
            hasCoverages = true;
            hasMultiCoverages = true;
          }
        });
      }
      if (policy?.dwellings?.length > 0) {
        policy.dwellings.forEach((element) => {
          if (element?.coverages?.length > 0) {
            hasCoverages = true;
            hasMultiCoverages = true;
          }
        });
      }
      if (policy?.vehicles?.length > 0) {
        policy.vehicles.forEach((element) => {
          if (element?.coverages?.length > 0) {
            hasCoverages = true;
            hasMultiCoverages = true;
          }
        });
      }
      let displayPolicyLevelTitle = false;
      let policyLevelIndent = 0;
      if (hasCoverages && hasMultiCoverages && policy?.coverages?.length > 0) {
        displayPolicyLevelTitle = true;
        policyLevelIndent = 2;
      }
      console.log(hasCoverages, hasMultiCoverages, policyLevelIndent);
      return (
        <PolicyDetailsCardContainer title="Coverages" redirectButtonText="View Additional Coverages" handleOnClick={handleOnClick}>
          {displayPolicyLevelTitle && (
            <Box mb={2}>
              <Typography variant="overline" display="block" color="textSecondary">
                Policy Level Coverages
              </Typography>
            </Box>
          )}
          {hasCoverages ? (
            <>
              <Box ml={policyLevelIndent}>{renderCoverages(policy.coverages)}</Box>
              {policy?.stateCoverages?.map(
                (stateInfo, index) =>
                  stateInfo.state && (
                    <Box mt={5} key={`${stateInfo.state}-${index}`}>
                      <Box mb={2}>
                        <Typography color="textSecondary">{`${stateInfo.state}`}</Typography>
                      </Box>
                      <Box ml={2}>{renderCoverages(stateInfo.coverages)}</Box>
                    </Box>
                  ),
              )}
              {policy?.vehicles?.map(
                (vehicleInfo, index) =>
                  vehicleInfo.coverages.length > 0 && (
                    <Box mt={5} key={`${vehicleInfo.make}-${index}`}>
                      <Box mb={2}>
                        <Typography variant="overline" color="textSecondary">{`${vehicleInfo.year} ${vehicleInfo.make} ${vehicleInfo.model}`}</Typography>
                      </Box>
                      <Box ml={2}>{renderCoverages(vehicleInfo.coverages)}</Box>
                    </Box>
                  ),
              )}
              {policy?.properties?.map(
                (propertyInfo, index) =>
                  propertyInfo.coverages.length > 0 && (
                    <Box mt={5} key={`${propertyInfo.state}-${index}`}>
                      <Box mb={2}>
                        <Typography color="textSecondary">{`${propertyInfo.line1} ${propertyInfo.city} ${propertyInfo.state}`}</Typography>
                      </Box>
                      <Box ml={2}>{renderCoverages(propertyInfo.coverages)}</Box>
                    </Box>
                  ),
              )}
              {policy?.dwellings?.map(
                (dwellInfo, index) =>
                  dwellInfo.coverages.length > 0 && (
                    <Box mt={5} key={`${dwellInfo.state}-${index}`}>
                      <Box mb={2}>
                        <Typography color="textSecondary">{`${dwellInfo.line1} ${dwellInfo.city} ${dwellInfo.state}`}</Typography>
                      </Box>
                      <Box ml={2}>{renderCoverages(dwellInfo.coverages)}</Box>
                    </Box>
                  ),
              )}
            </>
          ) : (
            <Typography color="textSecondary">No information found.</Typography>
          )}
        </PolicyDetailsCardContainer>
      );
    } else {
      return null;
    }
  };

  return renderContent();
};

export default CoveragesCard;
