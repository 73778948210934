import { LoginCallback } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';

import { NotFoundPage } from '../NotFoundPage';
import { SecurityProvider } from '../SecurityProvider';
import { UIShell } from '../UIShell';

import RouteWrapper from './RouteWrapper';

// *** REDESIGN BELOW ***
import { AppAdminContainer } from 'components/AORedesignViews/AppAdmin';
import { BookMgmtReports } from 'components/AORedesignViews/Reports/BookMgmtReports';
import {
  MarketingResources,
  PLUmbrellaResources,
  Resources,
  StormResources,
  TrainingResources,
  UnderwritingResources,
} from 'components/AORedesignViews/Resources';
import { UserAdminHome } from 'components/AORedesignViews/UserAdmin';
import {
  ROUTE_ACCOUNT_ADMIN,
  ROUTE_ACCOUNT_ADMIN_REGISTRATION_MATERIALS,
  ROUTE_BILLING,
  ROUTE_DASHBOARD,
  ROUTE_EMPLOYEE_HOME,
  ROUTE_QUOTES,
  ROUTE_REDESIGN_HOME_MANAGE_CURRENT_PAYMENT,
  ROUTE_REDESIGN_HOME_PAYMENT_BALANCE,
  ROUTE_REDESIGN_HOME_PAYMENT_HISTORY,
  ROUTE_REDESIGN_REPORTS,
  ROUTE_REDESIGN_REPORTS_AGENT_ABSREPORTS,
  ROUTE_REDESIGN_REPORTS_AGENT_ANNUALMILEAGEVALIDATIONREPORT,
  ROUTE_REDESIGN_REPORTS_AGENT_APNEREPORT,
  ROUTE_REDESIGN_REPORTS_AGENT_CCREPORTS,
  ROUTE_REDESIGN_REPORTS_AGENT_LOSSESBYAGENTREPORTS,
  ROUTE_REDESIGN_REPORTS_AGENT_PLRREPORTS,
  ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_DUE,
  ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_MADE_REPORT,
  ROUTE_REDESIGN_REPORTS_BILLING_PAYMENT_BALANCE_REPORT,
  ROUTE_REDESIGN_REPORTS_BILLING_TAR,
  ROUTE_REDESIGN_REPORTS_BOOK_MANAGEMENT,
  ROUTE_REDESIGN_REPORTS_CL_RENEWALS,
  ROUTE_REDESIGN_REPORTS_DIRECT_BILL_COMISSION,
  ROUTE_REDESIGN_RESOURCES,
  ROUTE_REDESIGN_RESOURCES_MARKETING,
  ROUTE_REDESIGN_RESOURCES_PL_UMBRELLA,
  ROUTE_REDESIGN_RESOURCES_STORM,
  ROUTE_REDESIGN_RESOURCES_TRAINING,
  ROUTE_REDESIGN_RESOURCES_UNDERWRITING,
  ROUTE_WILDCARD,
} from 'constants/routes';
import { AccountAdmin } from '../AORedesignViews/AccountAdmin';
import RegistrationMaterials from '../AORedesignViews/AccountAdmin/RegistrationMaterials';
import { Announcements as Announcements_R } from '../AORedesignViews/Announcements';
import { Billing, BillingDetails } from '../AORedesignViews/Billing';
import { Reports } from '../AORedesignViews/Reports';
import { ABSReport as ABSReport_REDESIGN } from '../AORedesignViews/Reports/AgentReports/ABSReport';
import { APnEReport as APnEReport_REDESIGN } from '../AORedesignViews/Reports/AgentReports/APnEReport';
import { AnnualMileageValidationReport as AnnualMileageValidationReport_REDESIGN } from '../AORedesignViews/Reports/AgentReports/AnnualMileageValidationReport';
import { CCReport as CCReport_REDESIGN } from '../AORedesignViews/Reports/AgentReports/CCReport';
import { LossesByAgentReport as LossesByAgentReport_REDESIGN } from '../AORedesignViews/Reports/AgentReports/LossesByAgentReport';
import { PolicyLossRunsReport as PolicyLossRunsReport_REDESIGN } from '../AORedesignViews/Reports/AgentReports/PolicyLossRunsReport';
import { DbcReport as DbcReport_REDESIGN } from '../AORedesignViews/Reports/BillingReports/DbcReport';
import { PaymentBalance } from '../AORedesignViews/Reports/BillingReports/PaymentBalance';
import { PaymentsDue } from '../AORedesignViews/Reports/BillingReports/PaymentsDue';
import { PaymentsMade } from '../AORedesignViews/Reports/BillingReports/PaymentsMade';
import { TransactionActivityReports as TransactionActivityReports_REDESIGN } from '../AORedesignViews/Reports/BillingReports/TransactionActivityReports';
import { CLSRenewals } from '../AORedesignViews/Reports/CLSRenewals';
import { Home } from '../Home';
import { Quotes } from '../Quotes';

// *** LEGACY BELOW ***
import { ABSReport } from '../ABSReport';
import { Clients } from '../AORedesignViews/Clients';
import { APnEReport } from '../APnEReport';
import { AgencyToolkit } from '../AgencyToolkit';
import { AgentAdmin, AgentAdminAddAgent, AgentAdminSearchAgent } from '../AgentAdmin';
import { Announcements } from '../Announcements';
import { AnnualMileageValidationReport } from '../AnnualMileageValidationReport';
import { AppAdmin } from '../AppAdmin';
import { BMReports } from '../BMReports';
import { BillingInquiryDetails } from '../BillingInquiryDetails';
import { BillingServices, BillingServicesReportsPaymentsMade } from '../BillingServices';
import { CCReport } from '../CCReport';
import { Claims, RedesignClaims } from '../Claims';
import { CLSAuditForms, CommercialLinesServices } from '../CommercialLinesServices';
import { Dashboard } from '../Dashboard';
import { DbcReport } from '../DbcReport';
import { External } from '../External';
import { FarmRanch } from '../FarmRanch';
import { Flood } from '../Flood';
import { HealthChecks } from '../AORedesignViews/HealthChecks';
import { HomePage } from '../HomePage';
import { LossesByAgentReport } from '../LossesByAgentReport';
import { NewsAdmin } from '../NewsAdmin';
import { AddNewsArticle, EditNewsArticle } from '../NewsArticleAdministration/AddOrEditNewsArticle';
import { SearchNewsArticles } from '../NewsArticleAdministration/SearchNewsArticles';
import { NewsArticlesArchived } from '../NewsArticles';
import { PLUmbrella } from '../PLUmbrella';
import { QuotingOnePoc } from '../POC';
import { PersonalLinesServices } from '../PersonalLinesServices';
import { PolicyLossRunsReport } from '../PolicyLossRunsReport';
import { ProductDetail } from '../ProductDetail';
import { StormReference } from '../StormReference';
import { TransactionActivityReports } from '../TransactionActivityReports';
import { Underwriting } from '../Underwriting';
import {
  UserAdmin,
  UserAdminAddGroup,
  UserAdminAddUser,
  UserAdminEditUser,
  UserAdminGroupInformation,
  UserAdminSearchUser,
  UserAdminViewAgencyInfo,
  UserAdminViewChangeHistory,
} from '../UserAdmin';

import { PaymentBalance as HomePaymentBalance, ManageCurrentPayment, PaymentHistory } from 'components/AORedesignViews/Home';
import {
  ROUTE_ACCOUNT_DETAIL,
  ROUTE_AGENCY_TOOLKIT,
  ROUTE_AGENCY_TOOLKIT_ABSREPORTS,
  ROUTE_AGENCY_TOOLKIT_ANNUALMILEAGEVALIDATIONREPORT,
  ROUTE_AGENCY_TOOLKIT_APNEREPORT,
  ROUTE_AGENCY_TOOLKIT_BMREPORTS,
  ROUTE_AGENCY_TOOLKIT_CCREPORTS,
  ROUTE_AGENCY_TOOLKIT_DBCREPORT,
  ROUTE_AGENCY_TOOLKIT_LOSSESBYAGENTREPORTS,
  ROUTE_AGENCY_TOOLKIT_PLRREPORTS,
  ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS,
  ROUTE_AGENT_MANAGEMENT,
  ROUTE_AGENT_MANAGEMENT_ADD_AGENT,
  ROUTE_AGENT_MANAGEMENT_SEARCH_AGENT,
  ROUTE_ANNOUNCEMENTS,
  ROUTE_APPLICATION_ADMIN,
  ROUTE_BILLING_DETAILS,
  ROUTE_BILLING_SERVICES,
  ROUTE_BILLING_SERVICES_REPORTS_PAYMENTS_MADE,
  ROUTE_CLAIMS,
  ROUTE_CLAIMS_INQ,
  ROUTE_CLIENTS,
  ROUTE_CLIX,
  ROUTE_CL_PRODUCT_DETAIL,
  ROUTE_COMMERCIAL_LINES_AUDIT_FORMS,
  ROUTE_COMMERCIAL_LINES_SERVICES,
  ROUTE_DASH,
  ROUTE_EXTERNAL,
  ROUTE_FARMRANCH,
  ROUTE_FLOOD,
  ROUTE_HOME,
  ROUTE_NEWS_ADD,
  ROUTE_NEWS_ARCHIVED,
  ROUTE_NEWS_ARTICLE_ADMIN,
  ROUTE_NEWS_ARTICLE_ADMIN_SEARCH,
  ROUTE_NEWS_EDIT,
  ROUTE_PERSONAL_LINES_SERVICES,
  ROUTE_PERSONAL_LINES_UMBRELLA_RATING_TOOLS_FORM,
  ROUTE_PL_UMBRELLA,
  ROUTE_QUOTING_ONE_POC,
  ROUTE_REDESIGN_APP_ADMIN,
  ROUTE_REDESIGN_USER_ADMIN,
  ROUTE_R_ANNOUNCEMENTS,
  ROUTE_STORM_REFERENCE,
  ROUTE_UNDERWRITING,
  ROUTE_USER_ADMIN,
  ROUTE_USER_ADMIN_ADD_GROUP,
  ROUTE_USER_ADMIN_ADD_USER,
  ROUTE_USER_ADMIN_EDIT_USER,
  ROUTE_USER_ADMIN_GROUP_INFORMATION,
  ROUTE_USER_ADMIN_SEARCH_USER,
  ROUTE_USER_ADMIN_VIEW_AGENCY_INFO,
  ROUTE_USER_ADMIN_VIEW_CHANGE_HISTORY,
  ROUTE_REDESIGN_HEALTH_CHECKS,
} from '../../constants/routes';

// For scrolling to top after router completes route change
// https://v5.reactrouter.com/web/guides/scroll-restoration
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const RenderedApp = () => (
  <Router>
    <ScrollToTop />
    <SecurityProvider>
      <Switch>
        <RouteWrapper path={ROUTE_HOME} exact component={HomePage} layout={UIShell} />
        <RouteWrapper path={ROUTE_DASH} exact component={Dashboard} layout={UIShell} />
        <RouteWrapper path={ROUTE_PERSONAL_LINES_UMBRELLA_RATING_TOOLS_FORM} exact component={NotFoundPage} layout={UIShell} />
        <RouteWrapper path={ROUTE_PERSONAL_LINES_SERVICES} exact component={PersonalLinesServices} layout={UIShell} />
        <RouteWrapper path={ROUTE_COMMERCIAL_LINES_SERVICES} exact component={CommercialLinesServices} layout={UIShell} />
        <RouteWrapper path={ROUTE_R_ANNOUNCEMENTS} exact component={Announcements_R} layout={UIShell} />
        <RouteWrapper path={`${ROUTE_ANNOUNCEMENTS}/:lineOfBusiness`} exact component={Announcements} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS} exact component={TransactionActivityReports} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENT_MANAGEMENT} exact component={AgentAdmin} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENT_MANAGEMENT_ADD_AGENT} exact component={AgentAdminAddAgent} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENT_MANAGEMENT_SEARCH_AGENT} exact component={AgentAdminSearchAgent} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENCY_TOOLKIT_ABSREPORTS} exact component={ABSReport} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENCY_TOOLKIT_ANNUALMILEAGEVALIDATIONREPORT} exact component={AnnualMileageValidationReport} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENCY_TOOLKIT_APNEREPORT} exact component={APnEReport} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENCY_TOOLKIT_BMREPORTS} exact component={BMReports} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENCY_TOOLKIT_CCREPORTS} exact component={CCReport} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENCY_TOOLKIT_DBCREPORT} exact component={DbcReport} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENCY_TOOLKIT_LOSSESBYAGENTREPORTS} exact component={LossesByAgentReport} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENCY_TOOLKIT_PLRREPORTS} exact component={PolicyLossRunsReport} layout={UIShell} />
        <RouteWrapper path={`${ROUTE_CL_PRODUCT_DETAIL}/:productId`} exact component={ProductDetail} layout={UIShell} />
        <RouteWrapper path={`${ROUTE_ACCOUNT_DETAIL}/:accountNumber`} exact component={BillingInquiryDetails} layout={UIShell} />
        <RouteWrapper path={`${ROUTE_BILLING_DETAILS}/:accountNumber`} exact component={BillingDetails} layout={UIShell} />
        <RouteWrapper path={ROUTE_AGENCY_TOOLKIT} exact component={AgencyToolkit} layout={UIShell} />
        <RouteWrapper path={ROUTE_COMMERCIAL_LINES_AUDIT_FORMS} exact component={CLSAuditForms} layout={UIShell} />
        <RouteWrapper path={ROUTE_UNDERWRITING} exact component={Underwriting} layout={UIShell} />
        <RouteWrapper path={ROUTE_BILLING_SERVICES} exact component={BillingServices} layout={UIShell} />
        <RouteWrapper path={ROUTE_BILLING_SERVICES_REPORTS_PAYMENTS_MADE} exact component={BillingServicesReportsPaymentsMade} layout={UIShell} />
        <RouteWrapper path={ROUTE_QUOTING_ONE_POC} exact component={QuotingOnePoc} layout={UIShell} />
        <RouteWrapper path={ROUTE_NEWS_ARCHIVED} exact component={NewsArticlesArchived} layout={UIShell} />
        <RouteWrapper path={ROUTE_NEWS_ARTICLE_ADMIN} exact component={NewsAdmin} layout={UIShell} />
        <RouteWrapper path={ROUTE_BILLING_SERVICES} exact component={BillingServices} layout={UIShell} />
        <RouteWrapper path={ROUTE_BILLING_SERVICES_REPORTS_PAYMENTS_MADE} exact component={BillingServicesReportsPaymentsMade} layout={UIShell} />
        <RouteWrapper path={ROUTE_QUOTING_ONE_POC} exact component={QuotingOnePoc} layout={UIShell} />
        <RouteWrapper path={ROUTE_NEWS_ARCHIVED} exact component={NewsArticlesArchived} layout={UIShell} />
        <RouteWrapper path={ROUTE_NEWS_ARTICLE_ADMIN} exact component={NewsAdmin} layout={UIShell} />
        <RouteWrapper path={ROUTE_PL_UMBRELLA} exact component={PLUmbrella} layout={UIShell} />
        <RouteWrapper path={ROUTE_FARMRANCH} exact component={FarmRanch} layout={UIShell} />
        <RouteWrapper path={ROUTE_CLAIMS} exact component={RedesignClaims} layout={UIShell} />
        <RouteWrapper path={ROUTE_CLAIMS_INQ} exact component={Claims} layout={UIShell} />
        <RouteWrapper path={ROUTE_STORM_REFERENCE} exact component={StormReference} layout={UIShell} />
        <RouteWrapper path={ROUTE_FLOOD} exact component={Flood} layout={UIShell} />
        <RouteWrapper path={ROUTE_USER_ADMIN} exact component={UserAdmin} layout={UIShell} />
        <RouteWrapper path={ROUTE_USER_ADMIN_ADD_GROUP} exact component={UserAdminAddGroup} layout={UIShell} />
        <RouteWrapper path={ROUTE_USER_ADMIN_ADD_USER} exact component={UserAdminAddUser} layout={UIShell} />
        <RouteWrapper path={ROUTE_USER_ADMIN_GROUP_INFORMATION} exact component={UserAdminGroupInformation} layout={UIShell} />
        <RouteWrapper path={ROUTE_USER_ADMIN_EDIT_USER} exact component={UserAdminEditUser} layout={UIShell} />
        <RouteWrapper path={ROUTE_USER_ADMIN_SEARCH_USER} exact component={UserAdminSearchUser} layout={UIShell} />
        <RouteWrapper path={ROUTE_USER_ADMIN_VIEW_AGENCY_INFO} exact component={UserAdminViewAgencyInfo} layout={UIShell} />
        <RouteWrapper path={ROUTE_USER_ADMIN_VIEW_CHANGE_HISTORY} exact component={UserAdminViewChangeHistory} layout={UIShell} />
        <RouteWrapper path={ROUTE_EXTERNAL} exact component={External} layout={UIShell} />
        <RouteWrapper path={ROUTE_NEWS_ADD} exact component={AddNewsArticle} layout={UIShell} />
        <RouteWrapper path={ROUTE_NEWS_EDIT} exact component={EditNewsArticle} layout={UIShell} />
        <RouteWrapper path={ROUTE_EMPLOYEE_HOME} exact component={Home} layout={UIShell} />
        <RouteWrapper path={ROUTE_CLIENTS} exact component={Clients} layout={UIShell} />
        <RouteWrapper path={ROUTE_QUOTES} exact component={Quotes} layout={UIShell} />
        <RouteWrapper path={ROUTE_BILLING} exact component={Billing} layout={UIShell} />
        <RouteWrapper path={ROUTE_APPLICATION_ADMIN} exact component={AppAdmin} layout={UIShell} />
        <RouteWrapper path={ROUTE_NEWS_ARTICLE_ADMIN_SEARCH} exact component={SearchNewsArticles} layout={UIShell} />
        <RouteWrapper path={ROUTE_CLIX} exact component={BillingServices} layout={UIShell} />
        {/* REDESIGN BELOW */}
        <RouteWrapper path={ROUTE_ACCOUNT_ADMIN} exact component={AccountAdmin} layout={UIShell} />
        <RouteWrapper path={ROUTE_ACCOUNT_ADMIN_REGISTRATION_MATERIALS} exact component={RegistrationMaterials} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_APP_ADMIN} exact component={AppAdminContainer} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_USER_ADMIN} exact component={UserAdminHome} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_HEALTH_CHECKS} exact component={HealthChecks} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_HOME_PAYMENT_BALANCE} exact component={HomePaymentBalance} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_HOME_PAYMENT_HISTORY} exact component={PaymentHistory} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_HOME_MANAGE_CURRENT_PAYMENT} exact component={ManageCurrentPayment} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS} exact component={Reports} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS_AGENT_ABSREPORTS} exact component={ABSReport_REDESIGN} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS_AGENT_APNEREPORT} exact component={APnEReport_REDESIGN} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS_AGENT_LOSSESBYAGENTREPORTS} exact component={LossesByAgentReport_REDESIGN} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS_AGENT_PLRREPORTS} exact component={PolicyLossRunsReport_REDESIGN} layout={UIShell} />
        <RouteWrapper
          path={ROUTE_REDESIGN_REPORTS_AGENT_ANNUALMILEAGEVALIDATIONREPORT}
          exact
          component={AnnualMileageValidationReport_REDESIGN}
          layout={UIShell}
        />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS_AGENT_CCREPORTS} exact component={CCReport_REDESIGN} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS_BILLING_PAYMENT_BALANCE_REPORT} exact component={PaymentBalance} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_MADE_REPORT} exact component={PaymentsMade} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_DUE} exact component={PaymentsDue} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS_BILLING_TAR} exact component={TransactionActivityReports_REDESIGN} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS_CL_RENEWALS} exact component={CLSRenewals} layout={UIShell} />
        <RouteWrapper path={`${ROUTE_REDESIGN_REPORTS_BOOK_MANAGEMENT}/:reportType`} exact component={BookMgmtReports} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_REPORTS_DIRECT_BILL_COMISSION} exact component={DbcReport_REDESIGN} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_RESOURCES} exact component={Resources} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_RESOURCES_MARKETING} exact component={MarketingResources} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_RESOURCES_TRAINING} exact component={TrainingResources} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_RESOURCES_UNDERWRITING} exact component={UnderwritingResources} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_RESOURCES_STORM} exact component={StormResources} layout={UIShell} />
        <RouteWrapper path={ROUTE_REDESIGN_RESOURCES_PL_UMBRELLA} exact component={PLUmbrellaResources} layout={UIShell} />
        {/* order of rendering routes matters */}
        <Route path={`${ROUTE_DASHBOARD}`} component={LoginCallback} />
        <RouteWrapper path={`${ROUTE_WILDCARD}`} component={NotFoundPage} layout={UIShell} />
      </Switch>
    </SecurityProvider>
  </Router>
);

export default RenderedApp;
