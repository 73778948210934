import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { AOButton } from '../../AOButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET_NEWS, NEWS_ARTICLES } from '../../../constants/api';
import { handlePdfDownload } from '../../CommonUtilityServices/DownloadPdfService';
import { handleFileDownload } from '../../CommonUtilityServices/DownloadFileService';

const SearchNewsArticlesTypeRenderCell = ({ type, extension, seqId, handleOpenForNewsReview }) => {
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [, setDownloadingPdfError] = useState(false);
  const [, setIsDownloadingFileErrorVisible] = useState(false);
  const [, setDownloadingFileError] = useState(false);
  const [, setLoadingFile] = useState(false);

  const handleClickPdfDownload = (id) => {
    handlePdfDownload(
      'GET',
      null,
      `${NEWS_ARTICLES}/${id}`,
      setLoadingPdf,
      setDownloadingPdfError,
      setDownloadingPdfError,
      'newWindow',
      `${GET_NEWS}`,
    );
  };
  const handleClickFileDownload = (id) => {
    handleFileDownload(
      `${NEWS_ARTICLES}/${id}`,
      setLoadingFile,
      setDownloadingFileError,
      setIsDownloadingFileErrorVisible,
      'newWindow',
      'GET',
      undefined,
      GET_NEWS,
    );
  };

  if (loadingPdf)
    return (
      <Box py={2}>
        <CircularProgress color="inherit" size={20} />
      </Box>
    );

  const renderIcon = () => {
    if (type === 'T') {
      return (
        <AOButton
          endIcon={'Note'}
          onClick={() => {
            handleOpenForNewsReview();
          }}
        />
      );
    } else if (type === 'D' && extension === 'doc') {
      return (
        <AOButton
          endIcon={'Description'}
          onClick={() => {
            handleClickFileDownload(seqId);
          }}
        />
      );
    } else if (type === 'D' && extension === 'pdf') {
      return (
        <AOButton
          endIcon={'PictureAsPdf'}
          onClick={() => {
            handleClickPdfDownload(seqId);
          }}
        />
      );
    } else {
      return <AOButton endIcon={'Note'} />;
    }
  };

  return renderIcon();
};

SearchNewsArticlesTypeRenderCell.propTypes = {
  type: PropTypes.string,
  extension: PropTypes.string,
  seqId: PropTypes.string,
  handleOpenForNewsReview: PropTypes.func,
};

export default SearchNewsArticlesTypeRenderCell;
