import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Box, Col, Divider, Paper, Row, ToggleButton, ToggleButtonGroup } from 'components/AORedesign';
import {
  ClientAgencyCodeFilter,
  ClientLOBFilter,
  ClientNameFilter,
  ClientProductFilter,
  FILTER_TYPES,
  POLICY_FILTER_VALUES,
} from 'components/AORedesignViews/Clients';
import { OVERDUE_PAYMENTS_KEY } from 'components/AORedesignViews/Dashboard/PaymentsCard';
import { RENEWALS_KEY } from 'components/AORedesignViews/Dashboard/RenewalsCard';
import AppDataContext from 'contexts/appData.context';

const useStyles = makeStyles((theme) => ({
  // badge button count style
  count: {
    padding: '4px 20px',
    marginLeft: '-0.5rem',
    marginRight: '10px',
    backgroundColor: theme.palette.grey[200],
    borderRadius: '25px',
  },

  // badge button style
  button: {
    marginRight: '1.25rem',
    borderRadius: '25px',
    color: theme.palette.text.primary,
    padding: '0.5rem 1rem',
    border: 'none',
    textTransform: 'none',
    fontSize: '1rem',

    '&:last-child': {
      borderRadius: '25px',
      marginRight: 0,
    },

    '&:not(:last-child)': {
      borderRadius: '25px',
    },

    // badge button selected style
    '&.Mui-selected': {
      backgroundColor: '#e1e7eb',
      color: theme.palette.text.primary,
    },

    // badge button count selected style
    '&.Mui-selected .MuiBox-root': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },

  buttonGroup: {},
}));

// badge count component for Renewal and Overdue Payments filter buttons
const CountBadge = ({ count }) => {
  const classes = useStyles();
  return (
    <Box component="span" className={classes.count}>
      {count ?? '--'}
    </Box>
  );
};
CountBadge.propTypes = {
  count: PropTypes.string,
};

const ClientFilters = ({ filters, handleSetFilter }) => {
  const classes = useStyles();

  // Grab Renewal and Overdue Payments counts from App Context which where set in Dashboard view
  const { dashboard } = useContext(AppDataContext);

  const { count: renewalsCount } = dashboard?.[RENEWALS_KEY] ?? '--';
  const { count: overdueCount } = dashboard?.[OVERDUE_PAYMENTS_KEY] ?? '--';

  const handleSetTopLevelFilter = (event, newValue) => {
    if (newValue) {
      handleSetFilter(FILTER_TYPES.POLICY, newValue);
    }
  };

  return (
    <Box mb={3}>
      <Paper p={3}>
        <ToggleButtonGroup value={filters[FILTER_TYPES.POLICY]} exclusive onChange={handleSetTopLevelFilter} aria-label="text alignment">
          <ToggleButton value={POLICY_FILTER_VALUES.ALL} aria-label="all clients" className={classes.button}>
            All Clients
          </ToggleButton>
          <ToggleButton value={POLICY_FILTER_VALUES.EXPIRING} aria-label="expiring accounts" className={classes.button}>
            <CountBadge count={renewalsCount} />
            Expiring Policies
          </ToggleButton>
          <ToggleButton value={POLICY_FILTER_VALUES.OVERDUE} aria-label="overdue accounts" className={classes.button}>
            <CountBadge count={overdueCount} />
            Overdue Payments
          </ToggleButton>
        </ToggleButtonGroup>

        <Box py={2.5}>
          <Divider />
        </Box>

        <Box py={1}>
          <Row spacing={2}>
            <Col xs>
              <ClientNameFilter filters={filters} handleSetFilter={handleSetFilter} />
            </Col>
            <Col>
              <Divider orientation="vertical" />
            </Col>
            <Col>
              <ClientAgencyCodeFilter filters={filters} handleSetFilter={handleSetFilter} />
            </Col>
            <Col>
              <ClientLOBFilter filters={filters} handleSetFilter={handleSetFilter} />
            </Col>
            <Col>
              <ClientProductFilter filters={filters} handleSetFilter={handleSetFilter} />
            </Col>
          </Row>
        </Box>
      </Paper>
    </Box>
  );
};

ClientFilters.propTypes = {
  filters: PropTypes.object,

  handleSetFilter: PropTypes.func,
};

export default ClientFilters;
