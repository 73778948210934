import React from 'react';
import PropTypes from 'prop-types';
import MuiMenuItem from '@material-ui/core/MenuItem';

import { componentIdStringGenerator } from '../../../helpers';

const MenuItem = (props) => {
  return (
    <MuiMenuItem
      {...props}
      id={componentIdStringGenerator('MenuItem', props.id ? props.id : props.children)}>
      {props.children}
    </MuiMenuItem>
  );
};

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

export default MenuItem;
