import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Paper } from '@material-ui/core';
import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';

import AgentLookupResultsSkeleton from './AgentLookupResultsSkeleton';
import { AODataGrid } from '../AODataGrid';
import { AOAlert } from '../AOAlert';
import { AOButton } from '../AOButton';
import { GET_ALL_AGENTS } from '../../constants/api';
import { mapAgentsResponse } from './AgentLookupModal.api';

const AgentLookupModal = ({ open, lineOfBusiness, setOpen, setAgentCode, dataGridProps }) => {
  // Agents state variables
  const [agents, setAgents] = useState(null);
  // const [gridColumns] = useState();
  const gridColumns = [
    {
      field: 'agencyCode',
      headerName: 'Location',
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: (cellValues) => {
        const agencyCode = cellValues.row?.agencyCode;
        return (
          <AOButton
            variant="text"
            color="primary"
            onClick={() => {
              handleAgentLookupModalClose(cellValues.row.agencyCode);
            }}>
            {agencyCode}
          </AOButton>
        );
      },
    },
    {
      field: 'agencyName',
      headerName: 'Location Name',
      editable: false,
      sortable: false,
      flex: 1,
    },
  ];

  // Get all agents
  const getAllAgentsUrl = `${GET_ALL_AGENTS}/${lineOfBusiness}`;
  const [{ data: response, loading, error }] = useAxios(getAllAgentsUrl);

  // Remap agents response
  useEffect(() => {
    if (!loading) {
      setAgents(mapAgentsResponse(response));
    }
  }, [loading, response]);

  // TODO (Brent): Maybe iterate to pull screen defn from json. I may not be able to do this with the skeleton, loading and error conditional displays
  // useEffect(() => {
  //   axios
  //     .get(`${API_BASE_URL}${GET_UI_DEFINITION}/AgentLookupModal`)
  //     .then((response) => {
  //       const { UiDefinitions } = response.data;

  //       const agentModalDefs = [UiDefinitions];
  //       setUiDefinitionsData(agentModalDefs);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // }, []);

  // DataGrid custom toolbar to only display the filter icon
  const CustomDataGridToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  };

  const handleAgentLookupModalClose = (selectedAgentCode) => {
    if (selectedAgentCode) {
      setAgentCode(selectedAgentCode);
    }
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => handleAgentLookupModalClose()}
      disableEnforceFocus
      aria-label="agent-lookup-modal"
      aria-labelledby="agent-lookup-modal-title"
      aria-describedby="agent-lookup-modal-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper style={{ padding: `1rem` }}>
        <Box sx={{ width: 800, height: 650 }}>
          <Box mb={2}>
            <Typography variant="h6">Agent Codes</Typography>
          </Box>
          <Box sx={{ height: 550 }} mb={2}>
            {loading ? (
              <AgentLookupResultsSkeleton />
            ) : error ? (
              <AOAlert severity="error" message={error.message} />
            ) : (
              <AODataGrid
                rows={agents}
                columns={gridColumns}
                loading={loading}
                error={error}
                density="compact"
                disableColumnSelector
                autoPageSize
                components={{
                  Toolbar: CustomDataGridToolbar,
                }}
                {...dataGridProps}
              />
            )}
          </Box>
          <Box>
            <AOButton
              id="closeButton"
              variant="contained"
              color="primary"
              onClick={() => handleAgentLookupModalClose()}>
              Close
            </AOButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

AgentLookupModal.propTypes = {
  lineOfBusiness: PropTypes.string,
  open: PropTypes.bool,
  setAgentCode: PropTypes.func,
  setOpen: PropTypes.func,
  dataGridProps: PropTypes.object,
};

export default AgentLookupModal;
