import React from 'react';
import { useHistory } from 'react-router-dom';

import { ViewContainer, Row, Col, Card, Typography, Box, Link } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import {
  ROUTE_REDESIGN_REPORTS_AGENT_ABSREPORTS,
  ROUTE_REDESIGN_REPORTS_AGENT_ANNUALMILEAGEVALIDATIONREPORT,
  ROUTE_REDESIGN_REPORTS_AGENT_APNEREPORT,
  ROUTE_REDESIGN_REPORTS_AGENT_CCREPORTS,
  ROUTE_REDESIGN_REPORTS_DIRECT_BILL_COMISSION,
  ROUTE_REDESIGN_REPORTS_AGENT_LOSSESBYAGENTREPORTS,
  ROUTE_REDESIGN_REPORTS_AGENT_PLRREPORTS,
  ROUTE_REDESIGN_REPORTS_BILLING_PAYMENT_BALANCE_REPORT,
  ROUTE_REDESIGN_REPORTS_BOOK_MANAGEMENT,
  ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_MADE_REPORT,
  ROUTE_REDESIGN_REPORTS_BILLING_TAR,
  ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_DUE,
  ROUTE_REDESIGN_REPORTS_CL_RENEWALS,
} from 'constants/routes';

const Reports = () => {
  const history = useHistory();

  const handleGoToRoute = (e) => {
    const route = e.currentTarget.getAttribute('data-route');
    history.push(route);
  };

  return (
    <ViewContainer title="Reports">
      <Row spacing={3}>
        <Col xs={6}>
          <Card style={{ height: '100%' }}>
            <Box mb={4}>
              <Typography variant="h6">Agent Reports</Typography>
            </Box>
            <Feature allowed={featureFlags.ACCESS_AGENCY_BILLED_STATEMENTS_REPORT}>
              <Box mt={2}>
                <Link data-route={ROUTE_REDESIGN_REPORTS_AGENT_ABSREPORTS} onClick={handleGoToRoute}>
                  Agency Billed Statements
                </Link>
              </Box>
            </Feature>
            <Feature allowed={featureFlags.ACCESS_APNE_REPORT}>
              <Box mt={2}>
                <Link data-route={ROUTE_REDESIGN_REPORTS_AGENT_APNEREPORT} onClick={handleGoToRoute}>
                  AP&E Reports
                </Link>
              </Box>
            </Feature>
            <Feature allowed={featureFlags.ACCESS_LOSSES_BY_AGENT_REPORT}>
              <Box mt={2}>
                <Link data-route={ROUTE_REDESIGN_REPORTS_AGENT_LOSSESBYAGENTREPORTS} onClick={handleGoToRoute}>
                  Losses By Agent
                </Link>
              </Box>
            </Feature>
            <Feature allowed={featureFlags.ACCESS_POLICY_LOSS_RUN_REPORT}>
              <Box mt={2}>
                <Link data-route={ROUTE_REDESIGN_REPORTS_AGENT_PLRREPORTS} onClick={handleGoToRoute}>
                  Policy Loss Runs
                </Link>
              </Box>
            </Feature>
            <Feature allowed={featureFlags.ACCESS_ANNUAL_MILAGE_REPORT}>
              <Box mt={2}>
                <Link data-route={ROUTE_REDESIGN_REPORTS_AGENT_ANNUALMILEAGEVALIDATIONREPORT} onClick={handleGoToRoute}>
                  Annual Mileage Validation Report
                </Link>
              </Box>
            </Feature>
            <Feature allowed={featureFlags.ACCESS_ANNUAL_MILAGE_REPORT}>
              <Box mt={2}>
                <Link data-route={ROUTE_REDESIGN_REPORTS_AGENT_CCREPORTS} onClick={handleGoToRoute}>
                  Chargeback Calculation Report
                </Link>
              </Box>
            </Feature>
            <Feature allowed={featureFlags.ACCESS_COMMERCIAL_LINES_SERVICES}>
              <Box mt={2}>
                <Link data-route={`${ROUTE_REDESIGN_REPORTS_CL_RENEWALS}`} onClick={handleGoToRoute}>
                  CLMSS Renewal Transition
                </Link>
              </Box>
            </Feature>
          </Card>
        </Col>

        <Feature allowed={featureFlags.ACCESS_MANAGE_MSA_BOOK_REPORT}>
          <Col xs={6}>
            <Card style={{ height: '100%' }}>
              <Box mb={4}>
                <Typography variant="h6">Book Management Reports</Typography>
              </Box>
              <Box mt={2}>
                <Link data-route={`${ROUTE_REDESIGN_REPORTS_BOOK_MANAGEMENT}/book-mgmt-non-pay-policy`} onClick={handleGoToRoute}>
                  Non-Pay Notice Due
                </Link>
              </Box>
              <Box mt={2}>
                <Link data-route={`${ROUTE_REDESIGN_REPORTS_BOOK_MANAGEMENT}/book-mgmt-cancelled-policy`} onClick={handleGoToRoute}>
                  Cancelled Policies
                </Link>
              </Box>
              <Box mt={2}>
                <Link data-route={`${ROUTE_REDESIGN_REPORTS_BOOK_MANAGEMENT}/book-mgmt-expiring-policy`} onClick={handleGoToRoute}>
                  Expiring Policies
                </Link>
              </Box>
              <Box mt={2}>
                <Link data-route={`${ROUTE_REDESIGN_REPORTS_BOOK_MANAGEMENT}/book-mgmt-installment-due`} onClick={handleGoToRoute}>
                  Installment Due
                </Link>
              </Box>
              <Box mt={2}>
                <Link data-route={`${ROUTE_REDESIGN_REPORTS_BOOK_MANAGEMENT}/book-mgmt-payment-method`} onClick={handleGoToRoute}>
                  Payment Method
                </Link>
              </Box>
              <Box mt={2}>
                <Link data-route={`${ROUTE_REDESIGN_REPORTS_BOOK_MANAGEMENT}/book-mgmt-policy-status`} onClick={handleGoToRoute}>
                  Policy Status
                </Link>
              </Box>
            </Card>
          </Col>
        </Feature>

        <Col xs={6}>
          <Card style={{ height: '100%' }}>
            <Box mb={4}>
              <Typography variant="h6">Billing Reports</Typography>
            </Box>
            <Box mt={2}>
              <Feature allowed={featureFlags.ACCESS_PAYMENT_BALANCE_REPORT}>
                <Link data-route={ROUTE_REDESIGN_REPORTS_BILLING_PAYMENT_BALANCE_REPORT} onClick={handleGoToRoute}>
                  Payments Balance
                </Link>
              </Feature>
            </Box>
            <Box mt={2}>
              <Link data-route={ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_DUE} onClick={handleGoToRoute}>
                Payments Due (10 days or less)
              </Link>
            </Box>
            <Box mt={2}>
              <Link data-route={ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_MADE_REPORT} onClick={handleGoToRoute}>
                Payments Made
              </Link>
            </Box>
            <Feature allowed={featureFlags.ACCESS_TAR_REPORT}>
              <Box mt={2}>
                <Link data-route={ROUTE_REDESIGN_REPORTS_BILLING_TAR} onClick={handleGoToRoute}>
                  Transaction Activity Report
                </Link>
              </Box>
            </Feature>
            <Feature allowed={featureFlags.ACCESS_DIRECT_BILL_COMMISSION_REPORT}>
              <Box mt={2}>
                <Link data-route={ROUTE_REDESIGN_REPORTS_DIRECT_BILL_COMISSION} onClick={handleGoToRoute}>
                  Direct Bill Commission Statements
                </Link>
              </Box>
            </Feature>
          </Card>
        </Col>
      </Row>
    </ViewContainer>
  );
};

export default Reports;
