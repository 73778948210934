import { useEffect } from 'react';
import axios from 'axios';

const useFetchSelectMenuItems = (url, successCallback, errorCallback = () => {}) => {
  useEffect(() => {
    let unmounted = false;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axios
      .get(url, {
        cancelToken: source.token,
      })
      .then((response) => {
        successCallback(response);
      })
      .catch((err) => {
        if (!unmounted) {
          if (axios.isCancel(err)) {
            console.error(`request cancelled in ${url}:${err.message}`);
          } else {
            console.error(`Error occured in ${url} with error message of: ${err.message}`);
            errorCallback();
          }
        }
      });

    return () => {
      unmounted = true;
      source.cancel('Cancelling in cleanup');
    };
  }, []);
};

export default useFetchSelectMenuItems;
