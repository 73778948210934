import cuid from 'cuid';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import {
  GET_BILL_PAYMENT_REPORT_AGENT_CODES,
  GET_BILL_PAYMENT_REPORT_CONTENTS,
  GET_BILL_PAYMENT_REPORT_USER_SELECTIONS,
} from '../../../constants/api';

import BillingServicesReportsPaymentsMadeRenderCell from './BillingServicesReportsPaymentsMadeRenderCell';

const fetchAgentCodeSuggestions = async () => {
  const { data } = await axios.get(GET_BILL_PAYMENT_REPORT_AGENT_CODES);
  const suggestions = data.map((el) => _.trim(el));
  return suggestions;
};

const fetchEnteredBySuggestions = async () => {
  const { data } = await axios.get(GET_BILL_PAYMENT_REPORT_USER_SELECTIONS);
  const suggestions = data.map((el) => _.trim(el));
  return suggestions;
};

const fetchReportContent = async (_params, initialRequest = false) => {
  let queryString = '';
  if (!initialRequest) {
    queryString = Object.keys(_params)
      .map((key) => {
        if (_params[key]) {
          return `${key}=${_.trim(_params[key])}`;
        }
      })
      .join('&');
    queryString = `?${queryString}`;
  }
  const { data } = await axios.get(`${GET_BILL_PAYMENT_REPORT_CONTENTS}${queryString}`);
  const rows = data.map((el) => ({ id: cuid(), ...el }));
  return rows;
};

const handleChangeFormData = (event, _property, _formData, _setFormData) => {
  _setFormData({ ..._formData, [_property]: event.target.value });
};

const getInvalidText = (property, value) => {
  const trimmedValue = value.trim();
  const trimmedValueLength = trimmedValue.length;
  if (!trimmedValueLength) return '';
  switch (property) {
    case 'agentCode':
      return trimmedValueLength ? '' : 'Agent code not found';

    default:
      return '';
  }
};

const handleAOAutocompleteOnChange = (_property, e, _value, _formData, _setFormData) => {
  _setFormData((prevValue) => ({ ...prevValue, [_property]: _value }));
};

const handleAOAutocompleteOnInputChange = (
  _property,
  e,
  _value,
  _autocompleteInputValues,
  _setAutocompleteInputValues,
) => {
  _setAutocompleteInputValues({
    ..._autocompleteInputValues,
    [_property]: {
      ..._autocompleteInputValues[_property],
      value: _value,
      invalidText: getInvalidText(_property, _value),
    },
  });
};

const handleAOAutocompleteOnBlur = (property, formData, setFormData, autocompleteInputValues) => {
  if (formData[property] !== autocompleteInputValues[property].value) {
    setFormData({ ...formData, [property]: autocompleteInputValues[property].value });
  }
};

const handleDateOnChange = (date, _property, formData, setFormData) => {
  const formattedDate = moment(date).format('MM/DD/YYYY');
  setFormData({ ...formData, [_property]: date ? formattedDate : null });
};

const handleTextFieldOnChange = (_property, e, _setFormData) => {
  _setFormData((prevVal) => ({ ...prevVal, [_property]: e.target.value }));
};

const reportContentColumns = (_setDownloadingPdfError) => {
  return [
    {
      field: 'currentDay',
      headerName: 'Date',
      sortable: true,
      flex: 1,
      type: 'date',
    },
    {
      field: 'userOrLockbox',
      headerName: 'User',
      sortable: true,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      flex: 1,
    },
    {
      field: 'payAccountNumber',
      headerName: 'Account #',
      sortable: true,
      flex: 1,
    },
    {
      field: 'payPaymentAmount',
      headerName: 'Amount',
      sortable: true,
      flex: 1,
      renderCell: (params) =>
        BillingServicesReportsPaymentsMadeRenderCell(params, _setDownloadingPdfError),
    },
    {
      field: 'payAgentNumber',
      headerName: 'Agent #',
      sortable: true,
      flex: 1,
    },
    {
      field: 'payType',
      headerName: 'Trans',
      sortable: true,
      flex: 1,
    },
  ];
};

const handleClickChangeReport = async (_params, _setReportContentData, _setAlertData) => {
  const {
    enteredBy,
    agentCode,
    amountType,
    eftType,
    toDate,
    amountTo,
    amountFrom,
    paymentsDateRange,
    fromDate,
    transactionType,
  } = _params;
  const queryParams = {
    EnteredByUser: enteredBy,
    agent: agentCode,
    amountType: amountType,
    eft: eftType,
    endDate: toDate,
    maxAmount: amountTo,
    minAmount: amountFrom,
    reportPeriod: paymentsDateRange,
    startDate: fromDate,
    tranType: transactionType,
  };

  _setReportContentData((prevVal) => ({ ...prevVal, loading: true }));

  try {
    _setAlertData((prevVal) => ({ ...prevVal, isVisible: false }));
    const payload = await fetchReportContent(queryParams);
    const dataGridRows = _.isNil(payload) || payload.length < 1 ? [] : payload;
    if (!_.isNil(dataGridRows) && dataGridRows.length < 1) {
      _setAlertData((prevVal) => ({ ...prevVal, isVisible: true }));
      _setReportContentData((prevVal) => ({ ...prevVal, rows: [], isVisible: false }));
    } else {
      _setReportContentData((prevVal) => ({
        ...prevVal,
        isVisible: true,
        rows: dataGridRows,
      }));
    }
    _setReportContentData((prevVal) => ({ ...prevVal, loading: false }));
  } catch (error) {
    console.error(error);
    _setAlertData((prevVal) => ({
      ...prevVal,
      message: error.response.data.message,
      severity: 'error',
      isVisible: true,
    }));
    _setReportContentData((prevVal) => ({ ...prevVal, loading: false }));
  }
};

export {
  handleChangeFormData,
  fetchAgentCodeSuggestions,
  fetchReportContent,
  fetchEnteredBySuggestions,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
  handleDateOnChange,
  handleTextFieldOnChange,
  reportContentColumns,
  handleClickChangeReport,
};
