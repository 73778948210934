export { default as ClientAgencyCodeFilter } from './ClientAgencyCodeFilter';
export { default as ClientCard } from './ClientCard';
export { default as ClientCardSkeleton } from './ClientCardSkeleton';
export { default as ClientFilters } from './ClientFilters';
export { default as ClientLOBFilter } from './ClientLOBFilter';
export { default as ClientNameFilter } from './ClientNameFilter';
export { default as ClientProductFilter } from './ClientProductFilter';
export { default as ClientResults } from './ClientResults';
export { default as Clients } from './Clients';

export const SELECT_ALL_VALUE = 'ALL';
export const NAME_SEARCH_MINIMUM = 3;

export const FILTER_TYPES = {
  POLICY: 'policyFilter',
  AGENCY_CODES: 'locations',
  LOB: 'lob',
  NAME_SEARCH: 'searchName',
  PRODUCT: 'product',
  ITEM_PER_PAGE: 'itemsPerPage',
  PAGE_NUMBER: 'pageNumber',
};

export const DEFAULT_PARAMS = {
  [FILTER_TYPES.ITEM_PER_PAGE]: 20,
  [FILTER_TYPES.PAGE_NUMBER]: 0,
};

export const POLICY_PARAM_VALUES = {
  EXPIRING_POLICIES: 'EXPIRING_POLICIES',
  OVERDUE_PAYMENTS: 'OVERDUE_PAYMENTS',
};

export const POLICY_FILTER_VALUES = {
  ALL: 'ALL',
  EXPIRING: 'EXPIRING',
  OVERDUE: 'OVERDUE',
};
