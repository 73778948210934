import axios from 'axios';
import _ from 'lodash';
import cuid from 'cuid';

import PLSMaAutoDecReprintRenderCell from './PLSMaAutoDecReprintRenderCell';
import { GET_PL_DOCUMENTS_MA_AUTO_DOCS } from '../../../constants/api';

const getColData = (_setErrorRetrievedDocuments, _setErrorDownloadingPdf) => {
  const plMaAutoDecReprintColData = [
    {
      field: 'docType',
      headerName: 'Document Type',
      width: `25%`,
      flex: 1,
    },
    {
      field: 'docDesc',
      headerName: 'Description',
      sortable: true,
      flex: 2,
    },
    {
      field: 'docPageId',
      headerName: 'PDF Link',
      sortable: true,
      flex: 1,
      renderCell: (params) => PLSMaAutoDecReprintRenderCell(params, _setErrorDownloadingPdf),
    },
  ];

  return plMaAutoDecReprintColData;
};
const plMaAutoDecReprintColData = [
  {
    field: 'docType',
    headerName: 'Document Type',
    width: `25%`,
    flex: 1,
  },
  {
    field: 'docDesc',
    headerName: 'Description',
    sortable: true,
    flex: 2,
  },
  {
    field: 'docPageId',
    headerName: 'PDF Link',
    sortable: true,
    flex: 1,
    renderCell: PLSMaAutoDecReprintRenderCell,
  },
];

// --- Event Handlers for PL MA Auto Dec Reprint ---
const handleChangePlMaAutoDecReprintNumber = (
  event,
  _setPlMaAutoDecReprintNumber,
  _setPolicyNumberStateValue,
  _setInvalidPolicyNumber,
) => {
  const policyNumber = event.target.value;
  _setPlMaAutoDecReprintNumber(policyNumber);
  const policyHead = policyNumber.slice(0, 2);
  _setInvalidPolicyNumber(false);
  _setPolicyNumberStateValue('');
  if (policyNumber.length >= 8) {
    _setPolicyNumberStateValue(policyNumber);
    if (policyHead > 20 && policyHead < 30) {
      _setInvalidPolicyNumber(false);
    } else {
      _setInvalidPolicyNumber(true);
    }
  }
};

const handleOnClickRetrieveMaAutoDecReprintDocuments = (
  setLoadingRetrievedDocuments,
  setNoRetrievedDocuments,
  setRetrievedDocuments,
  plPolicyDocumentNumber,
  setDataGridIsVisible,
  setDocSearchCustomerInfo,
  setErrorRetrievedDocuments,
  setErrorDownloadingPdf,
  setInsuredStateValue,
) => {
  setErrorDownloadingPdf(false);
  setLoadingRetrievedDocuments(true);
  setNoRetrievedDocuments(false);
  setRetrievedDocuments(null);
  axios({ url: `${GET_PL_DOCUMENTS_MA_AUTO_DOCS}/${plPolicyDocumentNumber}` })
    .then((response) => {
      const responseData = response.data.docs;
      // check to see if response is empty,
      // if there is data, toggle setSearch value
      // to hide the "search" section
      if (_.isEmpty(responseData)) {
        setNoRetrievedDocuments(true);
        setDataGridIsVisible(false);
        setInsuredStateValue(response.data.insuredName);
      } else {
        setDataGridIsVisible(true);
        setDocSearchCustomerInfo(response.data);
        for (let data of responseData) {
          data['id'] = cuid();
        }
        setRetrievedDocuments(responseData);
        setInsuredStateValue(response.data.insuredName);
      }
      setLoadingRetrievedDocuments(false);
    })
    .catch((error) => {
      console.error(error);
      setDataGridIsVisible(false);
      setErrorRetrievedDocuments(error);
      setNoRetrievedDocuments(true);
      setLoadingRetrievedDocuments(false);
    });
};

export {
  getColData,
  plMaAutoDecReprintColData,
  handleChangePlMaAutoDecReprintNumber,
  handleOnClickRetrieveMaAutoDecReprintDocuments,
};
