import React from 'react';
import { Row, ViewContainer } from 'components/AORedesign';
import BillingInquiry from './BillingInquiry';
import PaymentManagement from './PaymentManagement';
import FormsAndRequests from './FormsAndRequests';
import GuidesAndContacts from './GuidesAndContacts';
import featureFlags from 'constants/featureFlags';
import { Feature } from 'components/Feature';

const Billing = () => {
  return (
    <ViewContainer title="Billing">
      <BillingInquiry />
      <Feature allowed={featureFlags.ACCESS_PAYMENT_MANAGEMT}>
        <PaymentManagement />
      </Feature>
      <Row spacing={3}>
        <FormsAndRequests />
        <GuidesAndContacts />
      </Row>
    </ViewContainer>
  );
};

export default Billing;
