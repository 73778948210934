import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiAvatar from '@material-ui/core/Avatar';

import { componentIdStringGenerator } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
  },
}));

const Avatar = (props) => {
  const classes = useStyles();

  return (
    <MuiAvatar
      className={classes.root}
      {...props}
      id={componentIdStringGenerator('Avatar', props.id ? props.id : props.children)}
    />
  );
};

Avatar.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

export default Avatar;
