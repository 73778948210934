import axios from 'axios';

import {
  PAYMENT_MANAGEMENT_GET_CURRENT_PAYMENTS,
} from '../../../../../constants/api';


const getManageCurrentPaymentByDetailId = async (_id) => {
  const { data } = await axios.get(`${PAYMENT_MANAGEMENT_GET_CURRENT_PAYMENTS}/${_id}`);
  return data;
};

export { getManageCurrentPaymentByDetailId };
