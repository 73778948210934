import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import cuid from 'cuid';
import useAxios from 'axios-hooks';
import _ from 'lodash';
import { GET_NEWS, NEWS_ARTICLES } from '../../../constants/api';
import NewsArticleModal from '../../NewsArticles/NewsArticleModal';

import { AODataGrid } from '../../AODataGrid';
import { ROUTE_NEWS_EDIT } from '../../../constants/routes';
import SearchNewsArticlesTypeRenderCell from './SearchNewsArticlesTypeRenderCell';
import { AOButton } from '../../AOButton';
import { renderDisplayStatus, renderDisplayDates } from './SearchNewsArticlesDataGrid.api';

const SearchNewsArticlesDataGrid = ({ rows }) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const [
    { data: dataNewsReview, loading: loadingNewsReview, error: errorNewsReview },
    executeNewsReview,
  ] = useAxios();

  /* Click Event to capture row data */
  const handleOnTitleClick = async ({ row: { seqId } }) => {
    console.log(seqId);
    const editNews = `${ROUTE_NEWS_EDIT}?id=${seqId}`;
    history.push(editNews);
  };

  const renderTitle = (params) => {
    const { title } = params.row;
    return <AOButton onClick={() => handleOnTitleClick(params)}>{title}</AOButton>;
  };

  const handleOpenForNewsReview = (params) => {
    const { seqId, ext } = params.row;
    const trimmedExt = _.trim(ext); // remove whitespace
    if (_.isEmpty(trimmedExt)) {
      setOpen(true);
      executeNewsReview({
        url: `${GET_NEWS}${NEWS_ARTICLES}/${seqId}`,
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const allNewsGridColDef = [
    {
      field: cuid(),
      headerName: 'Id',
      hide: true,
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 800,
      flex: 1,
      renderCell: renderTitle,
    },
    {
      field: 'displayDates',
      headerName: 'Display Dates',
      flex: 1,
      width: 200,
      renderCell: renderDisplayDates,
    },
    {
      field: 'status',
      headerName: 'status',
      flex: 1,
      width: 200,
      renderCell: renderDisplayStatus,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      width: 200,
      renderCell: (params) => (
        <SearchNewsArticlesTypeRenderCell
          type={params.row.type}
          extension={params.row.extension}
          seqId={params.row.seqId}
          handleOpenForNewsReview={() => handleOpenForNewsReview(params)}
        />
      ),
    },
  ];

  return (
    <>
      <AODataGrid
        rows={rows}
        columns={allNewsGridColDef}
        autoHeight
        rowsPerPageOptions={[25, 50, 75, 100]}
        noResultsMessage="No Results"
      />
      <NewsArticleModal
        open={open}
        handleClose={handleClose}
        loading={loadingNewsReview}
        error={errorNewsReview}
        data={dataNewsReview}
      />
    </>
  );
};
SearchNewsArticlesDataGrid.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      displayDates: PropTypes.string,
      status: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};

export default SearchNewsArticlesDataGrid;
