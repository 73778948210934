import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';

const CustomerInfoRow = ({ title, data }) => {
  const cellPadding = 1;
  return (
    <Grid container>
      <Grid item xs={2} style={{ backgroundColor: `rgba(0, 0, 0, 0.12)` }}>
        <Box p={cellPadding}>
          <Typography align="right">
            <strong>{title}</strong>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Box p={cellPadding}>
          <Typography>{data}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

CustomerInfoRow.propTypes = {
  title: PropTypes.string,
  data: PropTypes.string,
};

export default CustomerInfoRow;
