import axios from 'axios';

import { GET_PL_AUTO_COMPLETION_AGENCY, GET_CL_WIP_BASE_URL } from '../../constants/api';

import OpenWindowService from '../CommonUtilityServices/OpenWindowService';

const API_CLS_WIP_BASE_URL = GET_CL_WIP_BASE_URL;
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';

const clWipLobMenuItemsData = [
  {
    code: 'ALL',
    value: 'All',
  },
  {
    code: 'AU',
    value: 'Automobile',
  },
  {
    code: 'HO',
    value: 'HomeOwner',
  },
];

const clWipSearchResultsColumnsData = [
  {
    field: 'reference',
    headerName: 'Policy/QT/Ref #',
    editable: true,
    flex: 1,
  },
  {
    field: 'insName',
    headerName: 'Named Insured/Description',
    flex: 1,
    editable: true,
  },
  {
    field: 'lob',
    headerName: 'LOB',
    type: 'number',
    flex: 1,
    editable: true,
  },
  {
    field: 'effDate',
    headerName: 'Effective Date',
    type: 'number',
    flex: 1,
    editable: true,
  },
  {
    field: 'entDate',
    headerName: 'Entered Date',
    type: 'number',
    flex: 1,
    editable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'number',
    flex: 1,
    editable: true,
  },
  {
    field: 'userID',
    headerName: 'User ID',
    type: 'number',
    flex: 1,
    editable: true,
  },
  {
    field: 'agentNum',
    headerName: 'Agent Code',
    type: 'number',
    flex: 1,
    editable: true,
  },
];

const clPolicyChangeViewSearchTypeMenuItemsData = [
  {
    code: '',
    value: 'Select Search Type',
  },
  {
    code: 'polNum',
    value: 'Policy Number',
  },
  {
    code: 'lastName',
    value: 'Policy Holder Last Name',
  },
];

const fetchClWipAgentCodeAutoCompletion = async (_newInputValue, _setOptions, _setLoading) => {
  try {
    const response = await axios.get(`${GET_PL_AUTO_COMPLETION_AGENCY}${_newInputValue}`);
    if (response.data.length < 1) {
      _setOptions(['']);
    } else {
      _setOptions(response.data);
    }
    _setLoading(false);
  } catch (error) {
    // handle error
    console.error(error);
    _setLoading(false);
    return error;
  }
};

const handleRouteAndOpenInNewWindow = (event) => {
  const routeUrl = event.currentTarget.getAttribute('data-route');
  return OpenWindowService(routeUrl, 'POST', null, null);
};

export {
  API_CLS_WIP_BASE_URL,
  clPolicyChangeViewSearchTypeMenuItemsData,
  clWipLobMenuItemsData,
  clWipSearchResultsColumnsData,
  fetchClWipAgentCodeAutoCompletion,
  redirectUrlFunction,
  handleRouteAndOpenInNewWindow,
};
