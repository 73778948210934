import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { GET_LOSSES_BY_AGENT_URL } from '../../constants/api';

import { ScreenSmith } from '../ScreenSmith';
import LossesByAgentReportSkeleton from './LossesByAgentReportSkeleton';
import { ReportsHeader } from '../ReportsHeader';
import {
  handleCreateLbaReport,
  handleAgencyCodeOnClick,
  handleOnChangeMonthYear,
} from './LossesByAgentReport.api';
import LossesByAgentReportAgentView from './LossesByAgentReportAgentView';
import { handleOnChange } from '../CommonUtilityServices/EventHandlerService';
import useScreenDefinition from '../../hooks/useScreenDefinition';

const LocalComponents = {
  ReportsHeader,
  LossesByAgentReportAgentView,
};

const LossesByAgentReport = () => {
  const [periodOptions, setPeriodOptions] = useState([]);
  const [permAgenciesOptions, setPermAgenciesOptions] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [isDownloadingFileErrorVisible, setIsDownloadingFileErrorVisible] = useState(false);
  const [downloadingFileError, setDownloadingFileError] = useState(false);
  const [formData, setFormData] = useState({
    agencyCode: '',
    period: '',
  });
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`LossesByAgentReport`);

  useEffect(async () => {
    const dateData = await axios.get(`${GET_LOSSES_BY_AGENT_URL}/dates`);
    let { data: dates } = dateData;
    dates = dates.map((date) => {
      const dateValue = date.dateValue.split(' ');
      return { ...date, code: dateValue[0], value: date.dateDisplay };
    });
    setPeriodOptions(dates);
  }, []);

  const generateReportFormDataRef = useRef({});

  const localStateMap = {
    agencyCode: formData.agencyCode,
    periodOptions,
    period: formData.period,
    loadingFile,
    isDownloadingFileErrorVisible,
    downloadingFileError,
    permAgenciesOptions,
  };

  const callBackFunctions = {
    handleAgencyCodeOnClick: (e) => handleAgencyCodeOnClick(e, formData, setFormData),
    handleOnChangeMonthYear: (e) => handleOnChangeMonthYear(e, formData, setFormData),
    handleCreateLbaReport: (e) =>
      handleCreateLbaReport(
        e,
        formData,
        setLoadingFile,
        setIsDownloadingFileErrorVisible,
        setDownloadingFileError,
        permAgenciesOptions,
      ),
    handleOnChange: (e) => handleOnChange(e, formData, setFormData, generateReportFormDataRef),
    setPermAgenciesOptions,
  };

  if (loadingSd) return <LossesByAgentReportSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          componentMap={LocalComponents}
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
        />
      )}
    </>
  );
};

export default LossesByAgentReport;
