import { createTheme } from '@material-ui/core/styles';

const theme = () =>
  createTheme({
    palette: {
      common: {
        black: '#000000',
        white: '#ffffff',
      },
      divider: '#0000001f',
      text: {
        primary: '#000000de',
        secondary: '#00000099',
        disabled: '#00000061',
        hint: '#00000061',
      },
      background: {
        paper: '#ffffff',
        default: '#f2f3f4',
      },
      primary: {
        main: '#0b3b60',
        light: '#5093b5',
        dark: '#001536',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#036080',
        light: '#5097ba',
        dark: '#003851',
        contrastText: '#ffffff',
      },
      error: {
        main: '#da190b',
        light: '#ff5c3d',
        dark: '#cb0000',
        contrastText: '#ffffff',
      },
      warning: {
        main: '#ff9800',
        light: '#ffb547',
        dark: '#c77700',
        contrastText: '#000000de',
      },
      info: {
        main: '#0a6fc2',
        light: '#62b3f0',
        dark: '#0464b9',
        contrastText: '#ffffff',
      },
      success: {
        main: '#367c3a',
        light: '#6fc173',
        dark: '#28642b',
        contrastText: '#ffffff',
      },
      action: {
        active: '#0000008a',
        hover: '#0000000a',
        selected: '#00000014',
        disabled: '#00000042',
        disabledBackground: '#0000001f',
        focus: '#0000001f',
      },
      alert: {
        errorContent: '#570a04',
        errorFill: '#fbe8e7',
        warningContent: '#663d00',
        warningFill: '#fff5e5',
        infoContent: '#042c4e',
        infoFill: '#e6f1f9',
        successContent: '#163217',
        successFill: '#ebf2eb',
      },
      app: {
        barDefaultFill: '#f5f5f5',
      },
      avatar: {
        defaultFill: '#bdbdbd',
      },
      backdrop: {
        fill: '#00000080',
      },
      breadcrumbs: {
        collapseFill: '#f5f5f5',
      },
      chip: {
        defaultCloseFill: '#000000',
        defaultHoverFill: '#0000001f',
        defaultFocusFill: '#ffffff33',
        defaultEnabledBorder: '#616161',
      },
      input: {
        standardEnabledBorder: '#0000006b',
        standardHoverBorder: '#000000',
        filledEnabledFill: '#0000000f',
        filledHoverFill: '#00000017',
        outlinedEnabledBorder: '#0000003b',
        outlinedHoverBorder: '#000000',
      },
      paper: {
        outlinedBorder: '#0000001f',
      },
      rating: {
        enabledBorder: '#0000003b',
      },
      snackbar: {
        fill: '#323232',
      },
      switch: {
        knobFill: '#fafafa',
        slideFill: '#000000',
      },
      table: {
        rowStripingFill: '#0000000a',
      },
      tooltip: {
        fill: '#616161',
      },
      grey: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        a100: '#f5f5f5',
        a200: '#eeeeee',
        a400: '#bdbdbd',
        a700: '#616161',
      },
      denim: {
        blue50: '#e2eff3',
        blue100: '#b8d7e4',
        blue200: '#8fbdd2',
        blue300: '#6aa4c0',
        blue400: '#5093b5',
        blue500: '#3982ab',
        blue600: '#226690',
        blue700: '#19567f',
        blue800: '#0b3b60',
        blue900: '#001536',
        blueA100: '#0b3b60',
        blueA200: '#78b9e4',
        blueA400: '#2682c1',
        blueA700: '#164b6f',
      },
      aquamarine: {
        50: '#cef4ff',
        100: '#abdff9',
        200: '#8dc5e3',
        300: '#6babcc',
        400: '#5097ba',
        500: '#2e84a9',
        600: '#1d7597',
        700: '#036080',
        800: '#004d6a',
        900: '#003851',
        a100: '#9ddeff',
        a200: '#7cbee2',
        a400: '#59a3c8',
        a700: '#002e4e',
      },
      jade: {
        50: '#e0f1f1',
        100: '#b1dedb',
        200: '#7fc9c4',
        300: '#4cb3ab',
        400: '#25a299',
        500: '#009287',
        600: '#00857a',
        700: '#01756a',
        800: '#02655b',
        900: '#02493f',
        a100: '#b1ffff',
        a200: '#84ece0',
        a400: '#54caba',
        a700: '#3a7d6e',
      },
      orange: {
        50: '#f9e9e6',
        100: '#facdba',
        200: '#f7ad8d',
        300: '#f48f5f',
        400: '#f2773b',
        500: '#f06314',
        600: '#e55d0f',
        700: '#d8560a',
        900: '#b14202',
      },
    },
    typography: {
      h1: {
        fontWeight: 400,
        fontSize: 36,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '42px',
      },
      h2: {
        fontWeight: 500,
        fontSize: 32,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '37px',
      },
      h3: {
        fontWeight: 400,
        fontSize: 28,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '33px',
      },
      h4: {
        fontWeight: 500,
        fontSize: 24,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '28px',
      },
      h5: {
        fontWeight: 500,
        fontSize: 20,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '24px',
      },
      h6: {
        fontWeight: 700,
        fontSize: 18,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '21px',
      },
      h1Brand: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 36,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '42px',
      },
      h2Brand: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: 32,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '37px',
      },
      h3Brand: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 28,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '33px',
      },
      h4Brand: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        fontSize: 24,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '28px',
      },
      h5Brand: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: 20,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '24px',
      },
      h6Brand: {
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: 18,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '21px',
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '24px',
      },
      body1Light: {
        fontWeight: 300,
        fontSize: 16,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '24px',
      },
      body1Medium: {
        fontWeight: 500,
        fontSize: 16,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '24px',
      },
      body1Bold: {
        fontWeight: 700,
        fontSize: 16,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '24px',
      },
      body1Italic: {
        fontStyle: 'italic',
        fontWeight: 400,
        fontSize: 16,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '24px',
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '20.02px',
      },
      body2Light: {
        fontWeight: 300,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '20.02px',
      },
      body2Medium: {
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '20.02px',
      },
      body2Bold: {
        fontWeight: 700,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '20.02px',
      },
      body2Italic: {
        fontStyle: 'italic',
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '20.02px',
      },
      body3: {
        fontWeight: 400,
        fontSize: 18,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '27px',
      },
      body3Light: {
        fontWeight: 300,
        fontSize: 18,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '27px',
      },
      body3Medium: {
        fontWeight: 500,
        fontSize: 18,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '27px',
      },
      body3Bold: {
        fontWeight: 700,
        fontSize: 18,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '27px',
      },
      body3Italic: {
        fontStyle: 'italic',
        fontWeight: 400,
        fontSize: 18,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '27px',
      },
      body4: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '18px',
      },
      body4Light: {
        fontWeight: 300,
        fontSize: 12,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '18px',
      },
      body4Medium: {
        fontWeight: 600,
        fontSize: 12,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '18px',
      },
      body4Bold: {
        fontWeight: 700,
        fontSize: 12,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '18px',
      },
      body4Italic: {
        fontStyle: 'italic',
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '18px',
      },
      subtitle1: {
        fontWeight: 400,
        fontSize: 16,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '28px',
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.100',
        lineHeight: '21.98px',
      },
      caption: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'left',
        letterSpacing: '0.40px',
        lineHeight: '19.92px',
      },
      overline: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'left',
        letterSpacing: 1,
        lineHeight: '31.92px',
      },
      buttonMedium: {
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.40px',
        lineHeight: '24px',
      },
      buttonSmall: {
        fontWeight: 500,
        fontSize: 13,
        textAlign: 'left',
        letterSpacing: '0.46px',
        lineHeight: '22px',
      },
      buttonLarge: {
        fontWeight: 500,
        fontSize: 15,
        textAlign: 'left',
        letterSpacing: '0.46px',
        lineHeight: '26px',
      },
      alertTitle: {
        fontWeight: 500,
        fontSize: 16,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '24px',
      },
      alertDescription: {
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '20.02px',
      },
      avatarInitials: {
        fontWeight: 400,
        fontSize: 20,
        textAlign: 'left',
        letterSpacing: '0.140',
        lineHeight: '20px',
      },
      badgeLabel: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'left',
        letterSpacing: '0.140',
        lineHeight: '20px',
      },
      inputLabel: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '12px',
      },
      helperText: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'left',
        letterSpacing: '0.40px',
        lineHeight: '19.92px',
      },
      inputText: {
        fontWeight: 400,
        fontSize: 16,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '24px',
      },
      chipLabel: {
        fontWeight: 400,
        fontSize: 13,
        textAlign: 'left',
        letterSpacing: '0.160',
        lineHeight: '18px',
      },
      tooltipLabel: {
        fontWeight: 500,
        fontSize: 10,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '14px',
      },
      tableHeader: {
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.170',
        lineHeight: '24px',
      },
      menuItem: {
        fontWeight: 400,
        fontSize: 16,
        textAlign: 'left',
        letterSpacing: '0.15px',
        lineHeight: '24px',
      },
      menuItemDense: {
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.170',
        lineHeight: '24px',
      },
      listSubheader: {
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.100',
        lineHeight: '48px',
      },
      bottomNavigationActiveLabel: {
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'left',
        letterSpacing: '0.40px',
        lineHeight: '23.24px',
      },
      navigationRailLabel: {
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'left',
        letterSpacing: 0,
        lineHeight: '16px',
      },
    },
  });

export default theme;
