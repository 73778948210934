import React from 'react';
import PropTypes from 'prop-types';

import RowCol from '../ScreenSmith/RowCol';
import { AOAutocomplete } from '../AOAutocomplete';

const AnnualMileageValidationReportInternalUserView = ({
  agencyName,
  agencyNameInputValue,
  agencyNameOptions,
  handleAgencyNameOnBlur,
  handleAgencyNameOnChange,
  handleAgencyNameOnInputChange,
}) => {
  return (
    <>
      <RowCol mb={2}>
        <AOAutocomplete
          handleOnChange={handleAgencyNameOnChange}
          handleOnInputChange={handleAgencyNameOnInputChange}
          id="amvr-agency-name"
          inputValue={agencyNameInputValue}
          label="Agency Name"
          labelId="amvr-agency-name-label"
          onBlur={handleAgencyNameOnBlur}
          options={agencyNameOptions}
          placeholder="Enter Search Input"
          value={agencyName}
        />
      </RowCol>
    </>
  );
};

AnnualMileageValidationReportInternalUserView.propTypes = {
  agencyName: PropTypes.string,
  agencyNameInputValue: PropTypes.string,
  agencyNameOptions: PropTypes.array,
  handleAgencyNameOnBlur: PropTypes.func,
  handleAgencyNameOnChange: PropTypes.func,
  handleAgencyNameOnInputChange: PropTypes.func,
  setAgencyNameOptions: PropTypes.func,
};

export default AnnualMileageValidationReportInternalUserView;
