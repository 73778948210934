import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AOBaseButton from './AOBaseButton';
import AOHrefNewTabButton from './AOHrefNewTabButton';
import AOHrefNewWindowButton from './AOHrefNewWindowButton';
import AORedirectUrlButton from './AORedirectUrlButton';
import AORouteButton from './AORouteButton';
import AORouteLegacyButton from './AORouteLegacyButton';

const AOButton = ({
  loading,
  route,
  routeLegacy,
  hrefNewTab,
  hrefNewWindow,
  redirectUrl,
  children,
  endIcon,
  startIcon,
  ...props
}) => {
  // create an object of possible button types
  const buttonTypes = { route, routeLegacy, hrefNewTab, hrefNewWindow, redirectUrl };

  // create a button type component map
  const AOButtonType = {
    base: AOBaseButton,
    hrefNewTab: AOHrefNewTabButton,
    hrefNewWindow: AOHrefNewWindowButton,
    redirectUrl: AORedirectUrlButton,
    route: AORouteButton,
    routeLegacy: AORouteLegacyButton,
  };

  // loop through possible button type object
  // and determine what it is.  if no button
  // type is found, default to base button
  const determineButtonType = () => {
    let buttonType = 'base';
    for (const [key, value] of Object.entries(buttonTypes)) {
      if (!_.isNil(value)) {
        buttonType = key.toString();
      }
    }
    return buttonType;
  };

  // assign button type const
  const typeOfButton = determineButtonType();

  // build button type properties to spread in create
  // element
  const buttonTypeProperties = typeOfButton !== 'base' && {
    [typeOfButton]: buttonTypes[typeOfButton],
  };
  // render button component according
  // to given criteria, spread
  //  all props, and render children
  return React.createElement(
    AOButtonType[typeOfButton],
    {
      loading,
      endIcon,
      startIcon,
      ...buttonTypeProperties,
      ...props,
    },
    children,
  );
};

AOButton.defaultProps = {
  route: null,
  routeLegacy: null,
  hrefNewWindow: null,
  redirectUrl: null,
};

AOButton.propTypes = {
  loading: PropTypes.bool,
  route: PropTypes.string,
  routeLegacy: PropTypes.string,
  hrefNewTab: PropTypes.string,
  hrefNewWindow: PropTypes.string,
  redirectUrl: PropTypes.shape({
    requestBody: PropTypes.object,
    httpMethod: PropTypes.string,
  }),
  children: PropTypes.node,
  endIcon: PropTypes.string,
  startIcon: PropTypes.string,
};

export default AOButton;
