import React, { useState, useRef } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import { CardTabSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import useFetchSelectMenuItems from '../../hooks/useFetchSelectMenuItems';
import { ReportsHeader } from '../ReportsHeader';
import APnEReportAgentView from './APnEReportAgentView';
import APnEReportInternalUserView from './APnEReportInternalUserView';

import { StyledAPnEReport } from './APnEReport.styled';
import { GET_APNE_URL } from '../../constants/api';
import { filterOptions, getOptionLabel } from '../CommonUtilityServices/EventHandlerService';
import {
  defaultYearMonthOption,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
  handleOnChange,
  handleGenerateReportOnClick,
} from './APnEReport.api';
import { getMonthName } from '../../helpers';

const LocalComponents = {
  ReportsHeader,
  APnEReportAgentView,
  APnEReportInternalUserView,
};

const APnEReport = () => {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [permAgenciesOptions, setPermAgenciesOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);

  const [formData, setFormData] = useState({
    customerNumber: '',
    locationNumber: '',
    year: '',
  });

  const generateReportFormDataRef = useRef({});

  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    customerNumber: { value: '' },
    locationNumber: { value: '' },
  });

  const { UiDefinitionsData, loadingSd } = useScreenDefinition('APnEReport');

  useFetchSelectMenuItems(`${GET_APNE_URL}/maxDate`, (response) => {
    const { year, month } = response.data;
    const yearOption = { value: `${getMonthName(month)} ${year}`, code: 0 };
    setYearOptions([yearOption, defaultYearMonthOption]);
    setFormData({ ...formData, year: yearOption.code });
    generateReportFormDataRef.current.year = yearOption.code;
  });

  const aPnEReportState = {
    customerNumber: formData.customerNumber,
    customerNumberInputValue: autocompleteInputValues.customerNumber.value,
    locationNumber: formData.locationNumber,
    locationNumberInputValue: autocompleteInputValues.locationNumber.value,
    year: formData.year,
    customerOptions,
    locationOptions,
    permAgenciesOptions,
    yearOptions,
    errorMessage,
    generateReportButtonDisabled:
      formData.year === '' ||
      (!formData.locationNumber && permAgenciesOptions.length) ||
      loadingPdf,
    loadingPdf,
    isDownloadingPdfErrorVisible,
  };

  const aPnEReportCallbackFunctions = {
    setPermAgenciesOptions,
    setCustomerOptions,
    setLocationOptions,
    handleCustomerOnChange: (e, value) =>
      handleAOAutocompleteOnChange(
        'customerNumber',
        e,
        value,
        formData,
        setFormData,
        generateReportFormDataRef,
      ),
    handleCustomerOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'customerNumber',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        generateReportFormDataRef,
      ),
    handleCustomerOnBlur: () =>
      handleAOAutocompleteOnBlur('customerNumber', formData, setFormData, autocompleteInputValues),
    handleLocationOnChange: (e, value) =>
      handleAOAutocompleteOnChange(
        'locationNumber',
        e,
        value,
        formData,
        setFormData,
        generateReportFormDataRef,
      ),
    handleLocationOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'locationNumber',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        generateReportFormDataRef,
      ),
    handleLocationOnBlur: () =>
      handleAOAutocompleteOnBlur('locationNumber', formData, setFormData, autocompleteInputValues),
    handleOnChange: (e) => handleOnChange(e, formData, setFormData, generateReportFormDataRef),
    getOptionLabel,
    filterOptions,
    handleGenerateReportOnClick: () =>
      handleGenerateReportOnClick(
        generateReportFormDataRef,
        setErrorMessage,
        setLoadingPdf,
        setIsDownloadingPdfErrorVisible,
        permAgenciesOptions,
      ),
  };

  return loadingSd ? (
    <CardTabSkeleton />
  ) : (
    <StyledAPnEReport>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={LocalComponents}
          functionMap={aPnEReportCallbackFunctions}
          stateMap={aPnEReportState}
        />
      )}
    </StyledAPnEReport>
  );
};

export default APnEReport;
