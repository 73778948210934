import React, { useState } from 'react';
import cuid from 'cuid';
import { Box, Card } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const CLSAuditFormsSkeleton = ({ ...otherProps }) => {
  const [skeletonItems] = useState(Array.from(Array(5).keys()));
  return (
    <Box>
      <Box {...otherProps}>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box display="flex" justifyContent="flex-start">
            <Skeleton
              width={175}
              style={{ marginRight: `24px`, padding: `6px 8px` }}
              animation="wave"
            />
          </Box>
        </Box>
        <Box py={2}>
          <Card>
            <Box p={4}>
              {skeletonItems.map(() => (
                <Box key={cuid()}>
                  <Skeleton
                    width={275}
                    style={{ marginRight: `24px`, padding: `6px 8px` }}
                    animation="wave"
                  />
                </Box>
              ))}
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default CLSAuditFormsSkeleton;
