import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GET_DBC_URL, GET_PERM_AGENCIES } from 'constants/api';

import { handleCreateDbcReport, handleOnChangeAgencyCode, handleOnChangeMonthYear, handleOnChangeAgencyNameDD, handleOnBlurAgencyCode } from './DbcReport.api';

import { Box, Button, Col, Row, Select, TextField } from 'components/AORedesign';
import { AOAlert } from 'components/AOAlert';

const DbcReportExternal = () => {
  const [agencyNameDDOptions, setAgencyNameDDOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);
  const [formData, setFormData] = useState({
    agencyName: '',
    agencyCode: '',
    year: '',
  });

  useEffect(async () => {
    try {
      const dateData = await axios.get(`${GET_DBC_URL}/dates`);
      let { data: dates } = dateData;
      dates = dates.map((date) => {
        return { ...date, value: date.dateValue, label: date.dateDisplay.replace('-', ' ') };
      });
      setYearOptions(dates);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(async () => {
    try {
      const permAgenceisPayload = await axios.get(`${GET_PERM_AGENCIES}`);
      const { data: agencyDataRaw } = permAgenceisPayload;
      const agencyData = agencyDataRaw.map((agency) => {
        return { ...agency, value: agency.agencyCode, label: agency.agencyName };
      });
      setAgencyNameDDOptions(agencyData);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <>
      <Box mb={2}>{isDownloadingPdfErrorVisible && <AOAlert message={downloadingPdfError} severity="error" />}</Box>
      <Box mb={2}>
        <Select
          id="dbc-agency-name-dropdown"
          onChange={(e) => handleOnChangeAgencyNameDD(e, setFormData)}
          value={formData.agencyName}
          menuItems={agencyNameDDOptions}
          labelId="dbc-agency-name-dropdown-label"
          name="agencyName"
          label="Select Location"
        />
      </Box>
      <Box mb={2}>
        <TextField
          id="dbc-agency-code"
          onChange={(e) => handleOnChangeAgencyCode(e, setFormData)}
          onBlur={(e) => handleOnBlurAgencyCode(e, setFormData)}
          value={formData.agencyCode}
          label="Agency Code"
          placeholder="Enter Search Input"
        />
      </Box>
      <Box mb={2}>
        <Select
          onChange={(e) => handleOnChangeMonthYear(e, setFormData)}
          value={formData.year}
          menuItems={yearOptions}
          id="dbc-year"
          labelId="dbc-year-label"
          label="Month/Year"
          name="year"
        />
      </Box>
      <Row spacing="2">
        <Col>
          <Button
            id="dbc-generate-report-button"
            onClick={() => handleCreateDbcReport(setLoadingPdf, setIsDownloadingPdfErrorVisible, setDownloadingPdfError, formData, 'pdf')}
            loading={loadingPdf}
            variant="contained"
            color="primary">
            Generate Pdf
          </Button>
        </Col>
        <Col>
          <Button
            id="dbc-generate-excel-report-button"
            onClick={() => handleCreateDbcReport(setLoadingPdf, setIsDownloadingPdfErrorVisible, setDownloadingPdfError, formData, 'excel')}
            loading={loadingPdf}
            variant="outlined"
            color="primary">
            Generate Excel
          </Button>
        </Col>
      </Row>
      <Box mb={4} />
    </>
  );
};

export default DbcReportExternal;
