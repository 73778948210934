import React from 'react';

import { Box, Link, WorkflowViewContainer } from 'components/AORedesign';
import { UserAdminGroupInfo } from 'components/AORedesignViews/UserAdmin';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';

const UserAdminGroupInformation = () => {
  const TriggerButton = (props) => <Link {...props}>Get Group Information</Link>;

  return (
    <Feature allowed={featureFlags.ACCESS_USER_ADMIN}>
      <WorkflowViewContainer appBarTitle={'Get Group Information'} TriggerButton={TriggerButton} block>
        <Box mx={9} my={5}>
          <UserAdminGroupInfo />
        </Box>
      </WorkflowViewContainer>
    </Feature>
  );
};

export default UserAdminGroupInformation;
