import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useAxios from 'axios-hooks';
import PropTypes from 'prop-types';

import { APIRequestHandler } from '../APIRequestHandler';
import { LegalAgreement } from '../LegalAgreement';

import AppDataContext from 'contexts/appData.context';
import AgentContext from '../../contexts/agent.context';
import AppConfigContext from '../../contexts/appConfig.context';

import { ROUTE_DASH, ROUTE_EMPLOYEE_HOME } from 'constants/routes';
import { getAnnouncementsAsync } from 'redux/app-store';
import { SESSION_URL } from '../../constants/api';
import featureFlags from '../../constants/featureFlags';

const AppConfigProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [{ data: userSession, loading: sessionLoading, error: sessionError }] = useAxios({
    method: 'post',

    url: `${SESSION_URL}`,
  });

  const [agentData, setAgentData] = useState({});
  const [appConfig, setAppConfig] = useState({});
  const { updateDashboardCounts } = useContext(AppDataContext);

  const history = useHistory();

  useEffect(() => {
    if (!sessionLoading && !_.isNil(userSession)) {
      const { PersonalData, UserID, LegacyWebUrl, LegacyWebUrlDomain, Locations, FeatureFlags, Dashboard, ProducerID, StateCodes } = userSession;
      const agentData = {
        ...PersonalData,
        UserID,
        Locations,
        FeatureFlags,
        ProducerID,
        StateCodes,
      };

      const PmacsId = { PMACID: ProducerID ? ProducerID : 'NONE' };
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ PmacsId });

      setAgentData(agentData);
      setAppConfig({
        legacyUrl: LegacyWebUrl.substr(0, LegacyWebUrl.length - 1),

        legacyWebUrlDomain: LegacyWebUrlDomain,
      }); // remove last char of legacy url "/"
      for (const key in Dashboard) {
        updateDashboardCounts(key, Dashboard[key]);
      }

      // pathname will be '/' if not defined, so length === 1
      if (window.location.pathname.length <= 1) {
        if (FeatureFlags[featureFlags.ACCESS_USER_IS_AGENT]) {
          // if agent, go to /dash
          history.push(ROUTE_DASH);
        } else {
          // else, go to /home
          history.push(ROUTE_EMPLOYEE_HOME);
        }
      }

      dispatch(getAnnouncementsAsync());
    }
  }, [sessionLoading]);

  const hideLegalAgreementModal = () => {
    setAgentData((prevState) => ({
      ...prevState,
      FeatureFlags: { ...prevState.FeatureFlags, [featureFlags.ACKNOWLEDGED_LEGAL_NOTICE]: true },
    }));
  };

  return (
    <APIRequestHandler loading={sessionLoading} error={sessionError}>
      <AppConfigContext.Provider value={appConfig}>
        {!agentData?.FeatureFlags || agentData?.FeatureFlags?.[featureFlags.ACKNOWLEDGED_LEGAL_NOTICE] ? (
          <AgentContext.Provider value={agentData}>{children}</AgentContext.Provider>
        ) : (
          <LegalAgreement hideLegalAgreementModal={hideLegalAgreementModal} />
        )}
      </AppConfigContext.Provider>
    </APIRequestHandler>
  );
};

AppConfigProvider.propTypes = {
  children: PropTypes.node,
};

export default AppConfigProvider;
