import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Typography, Box, Paper, LinearProgress } from '@material-ui/core';

import {
  AGENCY_ADMIN_GET_AVAILABLE_AGENCIES,
  AGENCY_ADMIN_GET_SELECTED_AGENCIES,
  AGENCY_ADMIN_PUT_ADD_REMOVE,
} from '../../../constants/api';
import { ROUTE_USER_ADMIN } from '../../../constants/routes';
import { EditUserAgenciesContext } from '../context/userAdmin.context';
import Col from '../../ScreenSmith/Col';
import { AOTransferList } from '../../AOTransferList';
import { AOButton } from '../../AOButton';

const EditUserAgenciesAgent = () => {
  const [availableAgencies, setAvailableAgencies] = useState([]);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [checked, setChecked] = useState([]);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const { userDetails } = useContext(EditUserAgenciesContext);

  useEffect(async () => {
    try {
      const userNameQueryString = `?userName=${userDetails.name}`;

      const getAvailableAgenciesUrl = `${AGENCY_ADMIN_GET_AVAILABLE_AGENCIES}${userNameQueryString}`;
      const availableRes = await axios.get(getAvailableAgenciesUrl);
      setAvailableAgencies(Array.isArray(availableRes.data) ? availableRes.data : []);

      const getSelectedAgenciesUrl = `${AGENCY_ADMIN_GET_SELECTED_AGENCIES}${userNameQueryString}`;
      const selectedRes = await axios.get(getSelectedAgenciesUrl);
      setSelectedAgencies(Array.isArray(selectedRes.data) ? selectedRes.data : []);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  const handleSubmitOnClick = async () => {
    setSubmitButtonLoading(true);
    try {
      const getUserDetailsUrl = `${AGENCY_ADMIN_PUT_ADD_REMOVE}`;
      await axios.put(getUserDetailsUrl, {
        userName: userDetails.name,
        agencies: selectedAgencies,
      });
    } catch (error) {
      console.error(error);
    }
    setSubmitButtonLoading(false);
  };

  return (
    <>
      <Col xs={7}>
        <Paper variant="outlined" square style={{ boxSizing: 'border-box', height: '100%' }}>
          <Box p={1} display={'flex'}>
            <Typography>
              <Box fontSize={'1.25rem'} fontWeight={'bold'}>
                Add or Remove Agency Rights
              </Box>
            </Typography>
          </Box>
          <Box p={1} style={{ maxHeight: '15rem', overflow: 'auto' }} ml={2}>
            <Typography color="textSecondary">
              The list box at left shows the agencies you may grant access to. To add an agency,
              move the agency number from the left hand box to the right hand box using the buttons
              at center. To retrieve information about an agency, double click on the agency code
              number.
            </Typography>
          </Box>
        </Paper>
      </Col>
      <Col xs={12}>
        <Paper variant="outlined" square>
          {loading ? (
            <LinearProgress />
          ) : (
            <Box p={1} display={'flex'} justifyContent={'center'}>
              <Box textAlign={'center'}>
                <Box mb={2}>
                  <AOTransferList
                    checked={checked}
                    label={'Agencies to Add'}
                    left={availableAgencies}
                    leftLabel={'Available Agencies'}
                    right={selectedAgencies}
                    rightLabel={'Selected Agencies'}
                    setLeft={(e) => setAvailableAgencies(e)}
                    setRight={(e) => setSelectedAgencies(e)}
                    setChecked={setChecked}></AOTransferList>
                </Box>
                <Box>
                  <AOButton
                    id={'search-user-agency-name-submit-button'}
                    variant={'contained'}
                    color={'secondary'}
                    onClick={handleSubmitOnClick}
                    loading={submitButtonLoading}>
                    Submit
                  </AOButton>
                  <Box display={'inline'} mr={1} />
                  <AOButton
                    id={'search-user-agency-name-cancel-button'}
                    variant={'outlined'}
                    route={ROUTE_USER_ADMIN}
                    color={'secondary'}>
                    Cancel
                  </AOButton>
                </Box>
              </Box>
            </Box>
          )}
        </Paper>
      </Col>
    </>
  );
};

export default EditUserAgenciesAgent;
