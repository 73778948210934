import axios from 'axios';
import cuid from 'cuid';
import _ from 'lodash';
// import moment from 'moment';

import {
  GET_BILLING_PAYMENT_ACCOUNTS,
  POST_BILLING_PAYMENT_POST_PAYMENTS,
} from '../../../../constants/api';

import { currencyStringFormatter, isRealNumber } from '../../../../helpers';

const rowInit = { id: cuid() };

const fetchPaymentAccountsList = async (_setPostPaymentData) => {
  try {
    const { data } = await axios.get(GET_BILLING_PAYMENT_ACCOUNTS);
    const accounts = data.map((el) => ({ id: cuid(), ...el }));
    _setPostPaymentData((prevValue) => ({
      ...prevValue,
      paymentAccountsList: accounts,
      paymentAccountsListLoading: false,
      alert: {
        ...prevValue.alert,
      },
    }));
  } catch (error) {
    _setPostPaymentData((prevValue) => ({
      ...prevValue,
      alert: {
        ...prevValue.alert,
        isVisible: true,
        message: `${error.message}.  Please try again.`,
        severity: 'error',
      },
    }));
  }
};

const paymentAccCol = [
  {
    field: 'accountNumber',
    headerName: 'Account #',
    sortable: true,
    flex: 1,
  },
  {
    field: 'accountName',
    headerName: 'Account Name',
    sortable: true,
    flex: 2,
  },
];

const enterPaymentCol = [
  ...paymentAccCol,
  {
    field: 'minimumDue',
    headerName: 'Minimum Due',
    sortable: true,
    flex: 1,
    valueFormatter: (params) => {
      return currencyStringFormatter(params.value);
    },
  },
  {
    field: 'paymentAmount',
    headerName: 'Payment Amount',
    sortable: true,
    flex: 1,
    editable: true,
    valueFormatter: (params) => {
      return currencyStringFormatter(params.value);
    },
  },
  {
    field: 'paymentMethod',
    headerName: 'Payment Method',
    sortable: true,
    flex: 1,
    editable: true,
  },
];

const paymentValidationCol = [
  ...paymentAccCol,
  {
    field: 'minimumDue',
    headerName: 'Minimum Due',
    sortable: true,
    flex: 1,
  },
  {
    field: 'paymentAmount',
    headerName: 'Payment Amount',
    sortable: true,
    flex: 1,
  },
  {
    field: 'paymentMethod',
    headerName: 'Payment Method',
    sortable: true,
    flex: 1,
  },
];

const onSelectionModelChange = (
  _selection,
  _postPaymentData,
  _setSelectionModel,
  _setPostPaymentData,
) => {
  let selectedAccounts = [];
  const { paymentAccountsList } = _postPaymentData;

  _selection.forEach((s) => {
    let filteredSelection = _.filter(paymentAccountsList, { id: s });
    let mutatedFilteredSelection = {
      ...filteredSelection[0],
      paymentMethod: 'cash',
    };
    selectedAccounts.push(mutatedFilteredSelection);
  });
  _setSelectionModel(_selection);
  _setPostPaymentData((prevValue) => ({
    ...prevValue,
    paymentAccountsListToEdit: selectedAccounts,
    alert: {
      ...prevValue.alert,
    },
  }));
};

const clearAlerts = (_setPostPaymentData) => {
  _setPostPaymentData((prevValue) => ({
    ...prevValue,
    alert: {
      isVisible: false,
      message: null,
      severity: null,
    },
  }));
};

const goToScreen = (_screen, _setCurrentScreen) => {
  _setCurrentScreen(_screen);
};

const paymentsHaveBeenSelected = (_postPaymentData) => {
  const { paymentAccountsListToEdit } = _postPaymentData;
  const hasPayments = _.has(paymentAccountsListToEdit[0], 'paymentAmount');
  return hasPayments;
};

const handleClickPaymentList = (e, _screenMappings, _setCurrentScreen, _setPostPaymentData) => {
  e.preventDefault();
  clearAlerts(_setPostPaymentData);
  goToScreen(_screenMappings.start, _setCurrentScreen);
};

const handleClickEnterPayments = (
  _screenMappings,
  _setCurrentScreen,
  _postPaymentData,
  _setPostPaymentData,
) => {
  clearAlerts(_setPostPaymentData);
  if (paymentsHaveBeenSelected(_postPaymentData)) {
    goToScreen(_screenMappings.enterPayments, _setCurrentScreen);
    _setPostPaymentData((prevValue) => ({
      ...prevValue,
      alert: {
        ...prevValue.alert,
        isVisible: false,
      },
    }));
  } else {
    // error: no payment selected
    _setPostPaymentData((prevValue) => ({
      ...prevValue,
      alert: {
        isVisible: true,
        message: 'Please make a selection to continue.',
        severity: 'error',
      },
    }));
  }
};

const exceedsPaymentBalance = (_accountBalance, _enteredPaymentAmount) => {
  const exceeds = _.gt(_enteredPaymentAmount, _accountBalance);
  return exceeds;
};

const calculatePaymentTotals = (editedPaymentAccountslist) => {
  let total = 0;
  for (let payment of editedPaymentAccountslist) {
    total = _.add(_.toNumber(total), _.toNumber(payment.paymentAmount));
  }
  return total;
};

const handleOnCellEditCommit = (_params, _postPaymentData, _setPostPaymentData) => {
  let paymentTotal;
  // Get id of row that is being
  // edited along with field and value. This
  // data comes from internal state of datagrid
  const { id: paramId, field, value } = _params;
  let editedPaymentAccountslist = [];
  const { paymentAccountsListToEdit } = _postPaymentData;

  // loop through "enter payment" data, find row
  // by id and update key value pairs of that
  // row object
  paymentAccountsListToEdit.forEach((row) => {
    let obj;
    let formatedNumberVal;
    if (row.id === paramId) {
      if (field === 'paymentAmount' && (isRealNumber(value) === false || value < 0)) {
        obj = row;
        _setPostPaymentData((prevValue) => ({
          ...prevValue,
          alert: {
            isVisible: true,
            message: `Payment amount must be in a valid dollar and cents amount.  Example 123.45.`,
            severity: 'warning',
          },
          enterPaymentContinueDisabled: true,
        }));
      } else if (field === 'paymentAmount' && exceedsPaymentBalance(row.accountBalance, value)) {
        obj = row;
        _setPostPaymentData((prevValue) => ({
          ...prevValue,
          alert: {
            isVisible: true,
            message: `You payment cannot be more than the account balance.  The account balance is ${currencyStringFormatter(
              row.accountBalance,
            )}`,
            severity: 'warning',
          },
          enterPaymentContinueDisabled: true,
        }));
      } else {
        field === 'paymentAmount'
          ? (formatedNumberVal = _.toNumber(value))
          : (formatedNumberVal = value);

        obj = {
          ...row,
          [field]: formatedNumberVal,
        };
        _setPostPaymentData((prevValue) => ({
          ...prevValue,
          alert: {
            ...prevValue.alert,
            isVisible: false,
          },
          enterPaymentContinueDisabled: false,
        }));
      }
    } else {
      obj = row;
    }
    editedPaymentAccountslist.push(obj);
  });

  paymentTotal = calculatePaymentTotals(editedPaymentAccountslist);
  _setPostPaymentData((prevValue) => ({
    ...prevValue,
    paymentAccountsListToEdit: editedPaymentAccountslist,
    enterPaymentTotal: paymentTotal,
    alert: {
      ...prevValue.alert,
    },
  }));
};

const handleClickResetEnterPayment = (e, _postPaymentData, _setPostPaymentData) => {
  e.preventDefault();
  const { paymentAccountsListToEdit } = _postPaymentData;

  const clearedPaymentAccountListToEdit = paymentAccountsListToEdit.map((el) => {
    let clearedObj = { ...el, paymentAmount: 0 };
    return clearedObj;
  });

  _setPostPaymentData((prevValue) => ({
    ...prevValue,
    alert: {
      ...prevValue.alert,
    },
    paymentAccountListToEdit: clearedPaymentAccountListToEdit,
  }));
};

const handleClickPaymentValidation = (
  e,
  _screenMappings,
  _setCurrentScreen,
  _setPostPaymentData,
) => {
  e.preventDefault();
  clearAlerts(_setPostPaymentData);
  goToScreen(_screenMappings.paymentValidation, _setCurrentScreen);
};

const handleClickPaymentConfirmation = (
  e,
  _screenMappings,
  _setCurrentScreen,
  _setPostPaymentData,
) => {
  e.preventDefault();
  clearAlerts(_setPostPaymentData);
  goToScreen(_screenMappings.paymentConfirmation, _setCurrentScreen);
};

const sendPostPayment = async (
  _requestBody,
  _setPostPaymentData,
  _screenMappings,
  _setCurrentScreen,
) => {
  // /billing/payment/post-payment
  let post;
  try {
    const { data } = await axios.post(POST_BILLING_PAYMENT_POST_PAYMENTS, _requestBody);
    _setCurrentScreen(_screenMappings.paymentConfirmation);
    _setPostPaymentData((prevValue) => ({
      ...prevValue,
      alert: {
        ...prevValue.alert,
      },
      paymentAccountsListToEdit: data,
    }));
    post = data;
  } catch (error) {
    _setPostPaymentData((prevValue) => ({
      ...prevValue,
      alert: {
        isVisible: true,
        message: error.message,
        severity: 'error',
      },
    }));
  }
  return post;
};

const handleClickSubmitPostPayment = (
  _screenMappings,
  _setCurrentScreen,
  _postPaymentData,
  _setPostPaymentData,
) => {
  const { paymentAccountsListToEdit } = _postPaymentData;
  const reshapedReqBody = paymentAccountsListToEdit.map((el) => {
    let reshaped = _.omit(el, 'id');
    return reshaped;
  });
  const requestBody = reshapedReqBody;

  sendPostPayment(requestBody, _setPostPaymentData, _screenMappings, _setCurrentScreen);
};

export {
  rowInit,
  paymentAccCol,
  fetchPaymentAccountsList,
  onSelectionModelChange,
  handleClickPaymentList,
  handleClickEnterPayments,
  handleClickResetEnterPayment,
  enterPaymentCol,
  handleOnCellEditCommit,
  handleClickPaymentValidation,
  paymentValidationCol,
  handleClickPaymentConfirmation,
  handleClickSubmitPostPayment,
};
