/**
 * utility to determine which variant
 * to render for SVG log
 *
 * @param {string} variant
 */
const getSvgLogoColor = (variant) => {
  let colorObj = {
    msaTxtColor: '#0B3B5F',
    insTxtColor: '#231F20',
    handColor: '#A2A9AC',
  };
  switch (variant) {
    case 'white': {
      colorObj = { ...colorObj, msaTxtColor: '#fff', insTxtColor: '#fff', handColor: '#fff' };
      break;
    }
    case 'black': {
      colorObj = { ...colorObj, msaTxtColor: '#000', insTxtColor: '#000', handColor: '#000' };
      break;
    }
    case 'default':
      return colorObj;
  }
  return colorObj;
};

export { getSvgLogoColor };
