import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import useAxios from 'axios-hooks';

import { AOAlert } from '../AOAlert';

import { oktaApi } from './SecurityProvider.api';

const SecurityProvider = ({ children }) => {
  const history = useHistory();
  let oktaAuth = {};

  const [{ data: dataOkta, loading, error }] = useAxios({
    url: oktaApi,
    method: 'get',
  });

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  if (dataOkta) {
    oktaAuth = new OktaAuth({
      issuer: `${dataOkta.issuer}`,
      clientId: `${dataOkta.clientId}`,
      redirectUri: `${dataOkta.redirectUri}`,
      pkce: `${dataOkta.pkce}`,
      scopes: ['openid', 'AgentsOnlyAPI', 'payment_scope', 'profile', 'email'],
    });
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <AOAlert severity="error" message="Oops! System is currently unavailable.  Please try again later.  If problem persists, please contact Customer Service at 877-927-5672" />;

  return (
    <>
      {dataOkta && (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          {children}
        </Security>
      )}
    </>
  );
};

SecurityProvider.propTypes = {
  children: PropTypes.node,
};

export default SecurityProvider;
