import axios from 'axios';
import cuid from 'cuid';
import _ from 'lodash';
import { GET_DOWNLOAD_MEDIA } from '../../constants/api';

const convertToBlob = (_data) => {
  function b64toBlob(b64Data) {
    const data = atob(b64Data);
    return Uint8Array.from(data, (b) => b.charCodeAt(0));
  }
  const body = b64toBlob(_data);
  const blob = new Blob([body]);
  return blob;
};

const DownloadPdfService = (_data, _fileName) => {
  const blob = convertToBlob(_data);

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, _fileName);
  } else {
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', _fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const DownloadPdfNewWindowService = (_data, _fileName) => {
  const blob = convertToBlob(_data);
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, _fileName);
  } else {
    const file = new Blob([blob], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
};

const getFileExtension = (_contentType) => {
  return _contentType === 'application/doc' ? '.doc' : '.pdf';
};

const makeAxiosRequest = (_httpMethod, _url, _requestBody, _overrideBaseUrl) => {
  if (_requestBody !== null && _httpMethod === 'POST') {
    return axios.post(`${_url}`, _requestBody);
  } else {
    return axios({ url: `${_overrideBaseUrl ? _overrideBaseUrl : GET_DOWNLOAD_MEDIA}${_url}` });
  }
};

const errorHandling = (
  errorMessage,
  _setIsDownloadingPdfErrorVisible,
  _setLoadingPdf,
  _setDownloadingPdfError,
) => {
  _setIsDownloadingPdfErrorVisible(true);
  _setLoadingPdf(false);
  _setDownloadingPdfError(errorMessage);
};

const handlePdfDownload = (
  _httpMethod,
  _requestBody,
  _pdfUrl,
  _setLoadingPdf,
  _setDownloadingPdfError,
  _setIsDownloadingPdfErrorVisible,
  _newWindow = null,
  _overrideBaseUrl = '',
) => {
  _setIsDownloadingPdfErrorVisible && _setIsDownloadingPdfErrorVisible(false);
  _setLoadingPdf(true);
  _setDownloadingPdfError(false);

  makeAxiosRequest(_httpMethod, _pdfUrl, _requestBody, _overrideBaseUrl)
    .then((response) => {
      if (response.data.size < 1 || response.data === '' || response.data === ' ') {
        errorHandling(
          'No Document Found',
          _setIsDownloadingPdfErrorVisible,
          _setLoadingPdf,
          _setDownloadingPdfError,
        );
      } else if (response.data.message && response.data.message !== '') {
        // We want to make sure there is actually a message and the message not blank or undefined.
        errorHandling(
          response.data.message,
          _setIsDownloadingPdfErrorVisible,
          _setLoadingPdf,
          _setDownloadingPdfError,
        );
      } else {
        const fileName = `${
          `${cuid()}_document` + getFileExtension(response.headers['content-type'])
        }`;
        !_.isNil(_newWindow)
          ? DownloadPdfNewWindowService(response.data, fileName)
          : DownloadPdfService(response.data, fileName);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 307) {
        window.location.href = error.response.data.url;
      } else {
        console.error(error);
        errorHandling(
          'Error downloading pdf.',
          _setIsDownloadingPdfErrorVisible,
          _setLoadingPdf,
          _setDownloadingPdfError,
        );
      }
    })
    .finally(() => {
      _setLoadingPdf(false);
    });
};

export { DownloadPdfService, DownloadPdfNewWindowService, handlePdfDownload };
