import axios from 'axios';
import _ from 'lodash';
import { POST_BILLING_PAYMENT_REVERSE_PAYMENT } from '../../../../constants/api';
import {
  adjustPaymentCols,
  onSelectionModelChange,
  fetchCurrentPayments,
} from '../AdjustPayment/AdjustPayment.api';

const handleOnClickReset = (setSelectionModel) => {
  setSelectionModel([]);
};

const handleOnClickEnterPaymentValidation = (screenMappings, setCurrentScreen) => {
  setCurrentScreen(screenMappings.paymentValidation);
};

const handleEditOnClickFromValidation = (screenMappings, setCurrentScreen, setSelectionModel) => {
  setCurrentScreen(screenMappings.start);
  setSelectionModel([]);
};

const handleOnClickSubmitPayment = (
  selectedRow,
  setPaymentListData,
  screenMappings,
  setCurrentScreen,
) => {
  // remove id property as api does not accept id
  const selectedRowNoId = _.omit(selectedRow, ['id']);

  setPaymentListData((prevValue) => ({
    ...prevValue,
    alert: { ...prevValue.alert, message: null, isVisible: false },
  }));

  axios
    .post(POST_BILLING_PAYMENT_REVERSE_PAYMENT, selectedRowNoId)
    .then((response) => {
      setPaymentListData((prevValue) => ({
        ...prevValue,
        alert: { ...prevValue.alert },
      }));

      console.info(response);
      setCurrentScreen(screenMappings.paymentConfirmation);
    })
    .catch((error) => {
      console.error(error);
      setPaymentListData((prevValue) => ({
        ...prevValue,
        alert: { ...prevValue.alert, message: error, isVisible: true },
      }));
    });
};

export {
  adjustPaymentCols as reportInsufficientFundsCols,
  onSelectionModelChange,
  fetchCurrentPayments,
  handleOnClickReset,
  handleOnClickEnterPaymentValidation,
  handleEditOnClickFromValidation,
  handleOnClickSubmitPayment,
};
