import { ThemeProvider, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import theme from './theme';

// Should convert to using CSSBaseline - https://stackoverflow.com/questions/59145165/change-root-background-color-with-material-ui-theme
const useGlobalStyles = makeStyles({
  '@global': {
    body: {
      backgroundColor: ' #f2f3f4',
    },
  },
});

const MSAThemeProvider = ({ children }) => {
  const appTheme = theme();

  // set default background color
  useGlobalStyles();

  return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};
MSAThemeProvider.defaultProps = {
  children: PropTypes.isNotNull, // you can amend this accordingly
};

MSAThemeProvider.propTypes = {
  children: PropTypes.node,
  redesign: PropTypes.bool,
};

export default MSAThemeProvider;
