import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import { AOCard } from '../AOCard';
import RowCol from '../ScreenSmith/RowCol';
import Row from '../ScreenSmith/Row';
import RowItem from '../ScreenSmith/RowItem';
import { ViewContainer } from '../Layout';

const StormReference = () => {
  return (
    <ViewContainer title={'Storm Information and Resources'}>
      <RowCol mb={2}>
        <AOCard>
          <RowCol mb={2}>
            <Typography color={'textSecondary'}>
              This site is designed to provide all the information you need to prepare for hurricane
              season and handle losses your customers may experience from a named storm in their
              area.
            </Typography>
          </RowCol>
          <RowCol mb={2}>
            <Typography color={'textSecondary'}>
              {`The Main Street America Group is committed to settling your customers' claims quickly
              and efficiently so that their quality of life can be restored as soon as possible.`}
            </Typography>
          </RowCol>

          <RowCol mb={2}>
            <Paper variant={'outlined'} square>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant={'h6'}>
                    Binding Authority Policy During Named Storms
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography color={'textSecondary'}>
                    The Main Street America Group would like to remind its agent-customers that{' '}
                    {
                      <Box display={'inline'} sx={{ textDecoration: 'underline' }}>
                        binding authority is suspended
                      </Box>
                    }{' '}
                    for property coverage with all of our writing companies in counties in which the
                    National Weather Service has declared a tropical storm, or hurricane watch or
                    warning.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography color={'textSecondary'}>
                    Binding authority will be reinstated when the watch and/or warning has been
                    lifted. Business will be conducted as usual in all counties not included in a
                    watch or warning.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography color={'textSecondary'}>
                    {`Main Street America's writing companies include NGM Insurance Company, Old
                  Dominion Insurance Company, Main Street America Assurance Company, MSA Insurance
                  Company, Great Lakes Casualty Insurance Company, Grain Dealers Mutual Insurance
                  Company and Spring Valley Mutual Insurance Company.`}
                  </Typography>
                </Box>
                <Box>
                  <Typography color={'textSecondary'}>
                    If you have any questions regarding our binding authority guidelines, please
                    contact your Main Street America field representative, or personal lines or
                    commercial lines underwriter.
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </RowCol>

          <RowCol mb={2}>
            <Paper variant={'outlined'} square>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant={'h6'}>Storm Claims Reporting</Typography>
                </Box>
                <Typography>
                  <Box fontSize={'0.875rem'}>
                    Storm-related claims, as well as all other claims, can be reported to Main
                    Street America 24 hours a day, seven days a week. We offer several options for
                    submitting a First Notice of Loss:
                  </Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>Customer Care Unit</Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>{`Phone: (877) 4CLAIMS (877) 425-2467`}</Box>
                </Typography>
                <Typography>
                  <Box mb={2} fontSize={'0.875rem'}>{`Fax: (877) 282-3844`}</Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>Online: www.msagroup.com</Box>
                </Typography>
              </Box>
            </Paper>
          </RowCol>

          <RowCol mb={2}>
            <Paper variant={'outlined'} square>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant={'h6'}>Questions Regarding Claims</Typography>
                </Box>
                <Typography>
                  <Box fontSize={'0.875rem'} mb={2}>
                    {`Questions regarding claims should be directed to Main Street America's Customer
                    Care Unit. You can also contact the adjuster assigned to the claim or the state
                    manager, as listed below:`}
                  </Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>
                    <Box display={'inline'} fontWeight={'bold'}>
                      Peg Charette
                    </Box>
                    <Box display={'inline'}>, Customer Care Unit Manager</Box>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Phone:
                      </RowItem>
                      <RowItem xs>(877) 425-2467, ext. 416</RowItem>
                    </Row>
                    <Row mb={2}>
                      <RowItem xs={4} mr={2}>
                        E-Mail:
                      </RowItem>
                      <RowItem xs>
                        <Link href={'mailto:CharettP@msagroup.com'}>CharettP@msagroup.com</Link>
                      </RowItem>
                    </Row>
                  </Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>
                    <Box fontWeight={'bold'} sx={{ textDecoration: 'underline' }}>
                      Glass Connection Glass Claims
                    </Box>
                    <Row mb={2}>
                      <RowItem xs={4} mr={2}>
                        Safelite Express Glass Connection:
                      </RowItem>
                      <RowItem xs>(877) 640-6024</RowItem>
                    </Row>
                  </Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>
                    <Box display={'inline'} fontWeight={'bold'}>
                      George Rivett
                    </Box>
                    <Box display={'inline'}>, Program Manager</Box>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Phone:
                      </RowItem>
                      <RowItem xs>(877) 425-2467, ext. 192</RowItem>
                    </Row>
                    <Row mb={2}>
                      <RowItem xs={4} mr={2}>
                        E-Mail:
                      </RowItem>
                      <RowItem xs>
                        <Link href={'mailto:RivettG@msagroup.com'}>RivettG@msagroup.com</Link>
                      </RowItem>
                    </Row>
                  </Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>
                    <Box fontWeight={'bold'} sx={{ textDecoration: 'underline' }}>
                      Massachusetts
                    </Box>
                    <Box fontWeight={'bold'}>Deb Pospiel</Box>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Phone:
                      </RowItem>
                      <RowItem xs>(877) 425-2467, ext. 200</RowItem>
                    </Row>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Cell:
                      </RowItem>
                      <RowItem xs>(508) 789-9185</RowItem>
                    </Row>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Fax:
                      </RowItem>
                      <RowItem xs>(508) 407-6008</RowItem>
                    </Row>
                    <Row mb={2}>
                      <RowItem xs={4} mr={2}>
                        E-Mail:
                      </RowItem>
                      <RowItem xs>
                        <Link href={'mailto:PospielD@msagroup.com'}>PospielD@msagroup.com</Link>
                      </RowItem>
                    </Row>
                  </Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>
                    <Box fontWeight={'bold'} sx={{ textDecoration: 'underline' }}>
                      Connecticut, Maine, New Hampshire, Rhode Island, Vermont
                    </Box>
                    <Box fontWeight={'bold'}>Alice Moriarty</Box>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Phone:
                      </RowItem>
                      <RowItem xs>(877) 425-2467, ext. 242</RowItem>
                    </Row>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Cell:
                      </RowItem>
                      <RowItem xs>(508) 864-6477</RowItem>
                    </Row>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Fax:
                      </RowItem>
                      <RowItem xs>(508) 407-6473</RowItem>
                    </Row>
                    <Row mb={2}>
                      <RowItem xs={4} mr={2}>
                        E-Mail:
                      </RowItem>
                      <RowItem xs>
                        <Link href={'mailto:MoriartA@msagroup.com'}>MoriartA@msagroup.com</Link>
                      </RowItem>
                    </Row>
                  </Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>
                    <Box fontWeight={'bold'} sx={{ textDecoration: 'underline' }}>
                      Arizona, Arkansas, Colorado, Florida, Georgia, Idaho, Indiana, Michigan,
                      Mississippi, Nevada, North Carolina, Oklahoma, South Carolina, Tennessee,
                      Utah, Virginia
                    </Box>
                    <Box fontWeight={'bold'}>Jeff Price</Box>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Phone:
                      </RowItem>
                      <RowItem xs>(800) 346-3861, ext. 1459</RowItem>
                    </Row>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Cell:
                      </RowItem>
                      <RowItem xs>(804) 347-3569</RowItem>
                    </Row>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Fax:
                      </RowItem>
                      <RowItem xs>(866) 520-3723</RowItem>
                    </Row>
                    <Row mb={2}>
                      <RowItem xs={4} mr={2}>
                        E-Mail:
                      </RowItem>
                      <RowItem xs>
                        <Link href={'mailto:PriceJ@msagroup.com'}>PriceJ@msagroup.com</Link>
                      </RowItem>
                    </Row>
                  </Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>
                    <Box fontWeight={'bold'} sx={{ textDecoration: 'underline' }}>
                      Delaware, Maryland, New York, Pennsylvania
                    </Box>
                    <Box fontWeight={'bold'}>Kathy Killgore</Box>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Phone:
                      </RowItem>
                      <RowItem xs>(800) 962-5515, ext. 1450</RowItem>
                    </Row>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Cell:
                      </RowItem>
                      <RowItem xs>(315) 761-6675</RowItem>
                    </Row>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        Fax:
                      </RowItem>
                      <RowItem xs>(866) 420-8192</RowItem>
                    </Row>
                    <Row>
                      <RowItem xs={4} mr={2}>
                        E-Mail:
                      </RowItem>
                      <RowItem xs>
                        <Link href={'mailto:KillgorK@msagroup.com'}>KillgorK@msagroup.com</Link>
                      </RowItem>
                    </Row>
                  </Box>
                </Typography>
              </Box>
            </Paper>
          </RowCol>

          <RowCol mb={2}>
            <Paper variant={'outlined'} square>
              <Box p={2}>
                <Box mb={2}>
                  <Typography variant={'h6'}>Immediate Customer Service Program</Typography>
                </Box>
                <Typography color={'textSecondary'}>
                  <Box mb={2}>
                    As a reminder, through our ICS program, you can deliver immediate payment
                    directly to your customers for property damage losses after a storm on personal
                    lines and commercial lines policies up to a limit of $2,500.*
                  </Box>
                  <Box sx={{ textDecoration: 'underline' }}>Process for Policyholder Payments</Box>
                  <Box mb={2}>
                    Following is the process for providing policyholder payment for a loss through
                    the ICS program:
                  </Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>
                    Explain all applicable coverages to your customer.
                  </Box>
                  <Box fontSize={'0.875rem'}>Request an estimate of damages and the receipts.</Box>
                  <Box fontSize={'0.875rem'}>Apply the deductible.</Box>
                  <Box fontSize={'0.875rem'}>Apply depreciation, if applicable.</Box>
                  <Box fontSize={'0.875rem'}>Issue payment on the ICS check.</Box>
                  <Box fontSize={'0.875rem'} mb={2}>
                    Fax the ICS form, a copy of the check and all supporting documents, such as
                    estimates, to the Customer Care Unit for processing at (877) 282-3844.
                  </Box>
                </Typography>
                <Typography color={'textSecondary'}>
                  <Box sx={{ textDecoration: 'underline' }}>Eligible Losses</Box>
                  <Box mb={2}>
                    The following types of claims can be easily settled by writing an ICS check:
                  </Box>
                </Typography>
                <Typography>
                  <Box fontSize={'0.875rem'}>Food spoilage</Box>
                  <Box fontSize={'0.875rem'}>Downed trees</Box>
                  <Box fontSize={'0.875rem'}>Roof and/or shingle damage under $2,500**</Box>
                  <Box fontSize={'0.875rem'}>Siding and/or trim damage under $2,500**</Box>
                  <Box fontSize={'0.875rem'}>
                    Reimbursement for temporary repairs (i.e., tarps, lumber, water extraction)
                  </Box>
                  <Box fontSize={'0.875rem'} mb={2}>
                    Auto comprehensive losses under $2,500 (i.e., trees fallen on autos)
                  </Box>
                </Typography>
                <Box mb={2}>
                  <Typography color={'textSecondary'}>
                    * Draft authority may be available for a higher amount with the approval of our
                    claim department.
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography color={'textSecondary'}>
                    ** In Maryland, these claims should not be handled through ICS.
                  </Typography>
                </Box>
                <Typography color={'textSecondary'}>
                  <Box sx={{ textDecoration: 'underline' }}>
                    Registration for ICS and More Information
                  </Box>
                  <Box mb={2}>
                    <Box display={'inline'} mr={1}>
                      To sign up for the ICS program, order a supply of checks or return existing
                      checks, please contact George Rivett, claims corporate product manager, (877)
                      425-2467, ext. 192, or via e-mail at
                    </Box>
                    <Box display={'inline'}>
                      <Link href={'mailto:rivettG@msagroup.com.'}>rivettG@msagroup.com.</Link>
                    </Box>
                  </Box>
                  <Box>
                    If you have any questions regarding whether or not a claim qualifies for the ICS
                    program, please contact George Rivett.
                  </Box>
                </Typography>
              </Box>
            </Paper>
          </RowCol>
        </AOCard>
      </RowCol>
    </ViewContainer>
  );
};

export default StormReference;
