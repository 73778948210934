import { Box, Col, Row, Typography } from 'components/AORedesign';
import { currencyStringFormatter } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCachedPolicy } from 'redux/app-store';
import PolicyDetailsCardContainer from './PolicyDetailsCardContainer';
import PolicyDetailsCardSkeleton from './PolicyDetailsCardSkeleton';

const PolicyDetailsCard = ({ phoneNumber }) => {
  const cachedPolicy = useSelector(selectCachedPolicy);

  // create pointer to policy
  const policyDetails = cachedPolicy.policy;

  const getAddressLine1 = (address) => {
    return [address.line1, address.line2]
      .filter((element) => {
        return element !== null && element !== undefined;
      })
      .join(' ')
      .trim();
  };

  const getAddressLine2 = (address) => {
    return [address.city ? `${address.city},` : '', address.state, address.zipcode]
      .filter((element) => {
        return element !== null && element !== undefined;
      })
      .join(' ')
      .trim();
  };

  const renderPolicyDetails = () => {
    return (
      <PolicyDetailsCardContainer title="Policy Details">
        {policyDetails.namedInsured ? (
          <>
            <Box mb={4}>
              <Row>
                <Col xs={6}>
                  <Box>
                    <Typography color="textSecondary" style={{ fontSize: '0.75rem', fontStyle: 'regular' }}>
                      TOTAL PREMIUM
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography variant="h4">
                      {policyDetails?.premium?.totalPremium ? currencyStringFormatter(policyDetails?.premium?.totalPremium) : ''}
                    </Typography>
                  </Box>
                </Col>
                <Col xs={6}>
                  <Box>
                    <Typography color="textSecondary" style={{ fontSize: '0.75rem', fontStyle: 'regular' }}>
                      MAILING ADDRESS
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    {policyDetails?.addresses && policyDetails?.addresses?.length > 0 && (
                      <>
                        <Typography>{getAddressLine1(policyDetails.addresses[0])}</Typography>
                        <Typography>{getAddressLine2(policyDetails.addresses[0])}</Typography>
                      </>
                    )}
                  </Box>
                </Col>
              </Row>
            </Box>
            <Box mb={4}>
              <Row>
                <Col xs={6}>
                  <Box>
                    <Typography color="textSecondary" style={{ fontSize: '0.75rem', fontStyle: 'regular' }}>
                      NAMED INSURED
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography>{policyDetails?.namedInsured}</Typography>
                  </Box>
                  {phoneNumber && (
                    <Box mt={1}>
                      <Typography>{phoneNumber}</Typography>
                    </Box>
                  )}
                </Col>

                <Col xs={6}>
                  <Box>
                    <Typography color="textSecondary" style={{ fontSize: '0.75rem', fontStyle: 'regular' }}>
                      ADDITIONAL INSURED
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Typography>{policyDetails.additionalInsured}</Typography>
                  </Box>
                </Col>
              </Row>
            </Box>
          </>
        ) : (
          <Typography color="textSecondary">No information found.</Typography>
        )}
      </PolicyDetailsCardContainer>
    );
  };

  const renderContent = () => {
    if (cachedPolicy.retrieveStatus === 'RETRIEVING') {
      return <PolicyDetailsCardSkeleton />;
    } else if (cachedPolicy.retrieveStatus === 'RETRIEVED') {
      return renderPolicyDetails();
    } else {
      return null;
    }
  };
  return renderContent();
};

PolicyDetailsCard.propTypes = {
  phoneNumber: PropTypes.string,
};

export default PolicyDetailsCard;
