import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import { CLSPolicyChangeViewSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';

import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import { MOMENT_DATE_FORMAT_MMDDYYYY } from '../../helpers';

const LocalComponents = {};

const CLSPolicyChangeView = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('CLSPolicyChangeViewPanel');

  const [transactionEffectiveDate, setTransactionEffectiveDate] = useState(
    moment().format(MOMENT_DATE_FORMAT_MMDDYYYY),
  );
  const [policyNumberInput, setPolicyNumberInput] = useState('');
  const [redirectingToClPolicyChangeView, setRedirectingToClPolicyChangeView] = useState(false);
  const [isClPolicyChangeRedirectErrorVisible, setIsClPolicyChangeRedirectErrorVisible] =
    useState(false);
  const [clPolicyChangeRedirectError, setClPolicyChangeRedirectError] = useState(false);

  //--- Event Handlers for Policy Change / Policy View ---
  const handleChangeTransactionEffectiveDate = (date) => {
    setTransactionEffectiveDate(moment(date).format(MOMENT_DATE_FORMAT_MMDDYYYY));
  };

  const handleChangePolicyNumberInput = (event) => {
    const inputValue = event.target.value;
    setPolicyNumberInput(inputValue);

    let invalidPolicyNumber = false;
    const prefix = inputValue.slice(0, 1).toUpperCase();
    const char2 = inputValue.toUpperCase();
    const check1 = /[1-9]+/;
    const msg_InvPolChgPolicy =
      'Policy is not eligible for online processing. Please submit ACORD Change Request';

    /*****************************************************************
     ** Valid policies include prefixes B1 - B9 (comm. auto) and    **
     ** M1 - M9 only (MA auto). And ensure no spaces in policy num. **
     *****************************************************************/
    if (inputValue.trim().length > 1) {
      if (!(prefix == 'M' || prefix == 'B')) invalidPolicyNumber = true;
      if (!(char2.search(check1) >= 0)) invalidPolicyNumber = true;
    }

    setIsClPolicyChangeRedirectErrorVisible(invalidPolicyNumber);
    setClPolicyChangeRedirectError(invalidPolicyNumber ? msg_InvPolChgPolicy : '');
  };

  const getRequestBody = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'View / Change Policy',

      DetailedContext: [
        {
          name: 'transactionEffectiveDate',
          value: transactionEffectiveDate,
        },
        {
          name: 'policyNumber',
          value: policyNumberInput,
        },
      ],
    };
    return request_body;
  };

  const handleClickClPolicyChangeViewSubmitButton = () => {
    const request_body = getRequestBody();
    setRedirectingToClPolicyChangeView(true);
    return redirectUrlFunction(
      request_body,
      'POST',
      null,
      setClPolicyChangeRedirectError,
      setIsClPolicyChangeRedirectErrorVisible,
    );
  };

  const clState = {
    transactionEffectiveDate,
    redirectingToClPolicyChangeView,
    policyNumberInput,
    isClPolicyChangeRedirectErrorVisible,
    clPolicyChangeRedirectError,
    policyChangeButtonDisabled:
      policyNumberInput.trim().length < 2 ||
      policyNumberInput.trim().length > 10 ||
      !moment(transactionEffectiveDate, MOMENT_DATE_FORMAT_MMDDYYYY, true).isValid(),
  };

  const callBackFunctions = {
    handleChangeTransactionEffectiveDate,
    handleChangePolicyNumberInput,
    handleClickClPolicyChangeViewSubmitButton,
  };

  if (loadingSd) return <CLSPolicyChangeViewSkeleton />;
  return (
    !_.isNil(UiDefinitionsData) && (
      <ScreenSmith
        definition={UiDefinitionsData}
        componentMap={LocalComponents}
        functionMap={callBackFunctions}
        stateMap={clState}
      />
    )
  );
};

export default CLSPolicyChangeView;
