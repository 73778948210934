import React from 'react';
import PropTypes from 'prop-types';

import { StyledAOButton, StyledButtonProgress } from './AOButton.styled';

const AOButtonLoadingContainer = ({ loading, children, fullHeight, fullWidth }) => {
  return (
    <StyledAOButton fullHeight={fullHeight} fullWidth={fullWidth}>
      {children}
      {loading && <StyledButtonProgress size={24} />}
    </StyledAOButton>
  );
};

AOButtonLoadingContainer.defaultProps = {
  fullHeight: false,
  fullWidth: false,
};

AOButtonLoadingContainer.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default AOButtonLoadingContainer;
