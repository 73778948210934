import { makeStyles } from '@material-ui/core/styles';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';
import { Box, Card, Col, Row, ViewContainer } from 'components/AORedesign';
import { ROUTE_REDESIGN_RESOURCES_MARKETING, ROUTE_REDESIGN_RESOURCES_TRAINING, ROUTE_REDESIGN_RESOURCES_UNDERWRITING } from 'constants/routes';
import React from 'react';
import CardButton from './CardButton';

import AdditionalInformation from './AdditionalInformation';
import AgencyFormRequests from './AgencyFormsRequests';
import ClientFormRequests from './ClientFormRequests';
import MsaResources from './MsaResources';

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}));

const UNDERWRITING_MATERIALS = 'Underwriting Materials';
const MARKETING_MATERIALS = 'Marketing Materials';
const TRAINING_MATERIALS = 'Training Materials';

const Resources = () => {
  const classes = useStyles();

  return (
    <ViewContainer title="Resources">
      <Box mb={2}>
        <Row spacing={3}>
          <Col xs={4}>
            <CardButton icon={<LibraryBooksOutlinedIcon fontSize="large" />} title={UNDERWRITING_MATERIALS} route={ROUTE_REDESIGN_RESOURCES_UNDERWRITING} />
          </Col>
          <Col xs={4}>
            <CardButton icon={<PhotoLibraryOutlinedIcon fontSize="large" />} title={MARKETING_MATERIALS} route={ROUTE_REDESIGN_RESOURCES_MARKETING} />
          </Col>
          <Col xs={4}>
            <CardButton icon={<VideoLibraryOutlinedIcon fontSize="large" />} title={TRAINING_MATERIALS} route={ROUTE_REDESIGN_RESOURCES_TRAINING} />
          </Col>
        </Row>
      </Box>
      <Box mb={2}>
        <Row spacing={3}>
          <Col xs={6}>
            <Card className={classes.fullHeight}>
              <ClientFormRequests />
            </Card>
          </Col>
          <Col xs={6}>
            <Card className={classes.fullHeight}>
              <AgencyFormRequests />
            </Card>
          </Col>
        </Row>
      </Box>
      <Box>
        <Row spacing={3}>
          <Col xs={6}>
            <Card className={classes.fullHeight}>
              <AdditionalInformation />
            </Card>
          </Col>
          <Col xs={6}>
            <Card className={classes.fullHeight}>
              <MsaResources />
            </Card>
          </Col>
        </Row>
      </Box>
    </ViewContainer>
  );
};

export default Resources;
