import React from 'react';

import { Link, WorkflowViewContainer } from 'components/AORedesign';
import { Box } from 'components/AORedesign/Box';
import { Feature } from 'components/Feature';
import { UserAdminAddUser } from 'components/UserAdmin';
import featureFlags from 'constants/featureFlags';

const ADD_USER_LABEL = 'Add User';

const UserAdminAddUserLink = () => {
  const TriggerButton = (props) => (
    <Link {...props} startIcon={'addOutlined'}>
      {ADD_USER_LABEL}
    </Link>
  );

  return (
    <Feature allowed={featureFlags.ACCESS_AGENCY_SECURITY_USER_ADMIN}>
      <WorkflowViewContainer appBarTitle={ADD_USER_LABEL} TriggerButton={TriggerButton}>
        <Box mx={9} my={5}>
          <UserAdminAddUser title={'User Information'} hideReturn />
        </Box>
      </WorkflowViewContainer>
    </Feature>
  );
};

export default UserAdminAddUserLink;
