import Skeleton from '@material-ui/lab/Skeleton';
import { Col, Row } from 'components/AORedesign';
import React from 'react';

const PolicyDetailsSkeleton = () => {
  return (
    <Row xs={12} direction="column" spacing={1}>
      <Col>
        <Skeleton variant="rectangular" style={{ width: '100%', height: '70px' }} animation="wave" />
      </Col>
      <Col>
        <Row spacing={1}>
          <Col xs={6}>
            <Skeleton variant="rectangular" style={{ width: '100%', height: '180px' }} animation="wave" />
          </Col>
          <Col xs={6}>
            <Skeleton variant="rectangular" style={{ width: '100%', height: '180px' }} animation="wave" />
          </Col>
        </Row>
      </Col>
      <Col>
        <Row spacing={1}>
          <Col xs={6}>
            <Skeleton variant="rectangular" style={{ width: '100%', height: '180px' }} animation="wave" />
          </Col>
          <Col xs={6}>
            <Skeleton variant="rectangular" style={{ width: '100%', height: '180px' }} animation="wave" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PolicyDetailsSkeleton;
