import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import cuid from 'cuid';
import { Box, Card, CardContent, CardHeader, Typography, ViewContainer, DataGrid } from 'components/AORedesign';
import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';
import { getPaymentsDueRows, getPaymentsDueRowCountData, getPaymentsDueColumns } from './PaymentsDue.api';
import AppDataContext from 'contexts/appData.context';

const PaymentsDue = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentsDueRows, setPaymentsDueRows] = useState();
  const [paginationData, setPaginationData] = useState({
    totalCount: null,
    currentPage: 1,
  });
  const history = useHistory();
  const { setPreviousPageName } = useContext(AppDataContext);

  useEffect(async () => {
    try {
      setErrorMessage('');
      const currentPageData = await getPaymentsDueRows(paginationData.currentPage);
      const { data: currentPage } = currentPageData;
      setPaymentsDueRows(currentPage);
      if (!paginationData.totalCount) {
        const rowCountData = await getPaymentsDueRowCountData();
        const { data: rowCount } = rowCountData;
        setPaginationData((prevVal) => ({ ...prevVal, totalCount: rowCount }));
      }
    } catch (error) {
      setErrorMessage(error.message);
      console.error(error);
    }
  }, [paginationData.currentPage]);

  const handleClickPaymentsDuePaginationChange = (page) => {
    setPaginationData((prevVal) => ({ ...prevVal, currentPage: page + 1 }));
  };

  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
      <Card id="BillingReportsPaymentsDueRowColAODataGrid">
        <CardHeader title="Payments Due (10 days or less)" />
        <CardContent>
          {errorMessage && (
            <Box mb={2}>
              <Typography color="error">{errorMessage}</Typography>
            </Box>
          )}
          <Box mb={2} id="BillingReportsPaymentsDueAODataGridSpacer">
            <DataGrid
              id="BillingReportsPaymentsDueAODataGrid"
              onPageChange={handleClickPaymentsDuePaginationChange}
              rows={paymentsDueRows?.map((el) => ({
                id: cuid(),
                ...el,
              }))}
              columns={getPaymentsDueColumns(history, setPreviousPageName)}
              rowCount={paginationData.totalCount}
              pageSize={50}
              pagination={true}
              paginationMode="server"
            />
          </Box>
        </CardContent>
      </Card>
    </ViewContainer>
  );
};

export default PaymentsDue;
