import React, { useState } from 'react';
import cuid from 'cuid';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const NewsArticlesSkeleton = () => {
  const [skeletonItems] = useState(Array.from(Array(2).keys()));

  return (
    <Box>
      {skeletonItems.map(() => {
        return (
          <Box px={2} key={cuid()}>
            <Box>
              <Skeleton width={75} animation="wave" />
            </Box>
            <Box>
              <Box display="flex" mx={2} key={cuid()}>
                <Box mr={2}>
                  <Skeleton width={75} animation="wave" />
                </Box>
                <Skeleton width={150} animation="wave" />
              </Box>
              <Box display="flex" mx={2} key={cuid()}>
                <Box mr={2}>
                  <Skeleton width={75} animation="wave" />
                </Box>
                <Skeleton width={175} animation="wave" />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default NewsArticlesSkeleton;
