import React, { useState, useContext } from 'react';
import _ from 'lodash';

import AppConfigContext from '../../contexts/appConfig.context';
import { ScreenSmith } from '../ScreenSmith';
import { CLSAuditFormsSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { handleFileDownload } from '../CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';
import { handleStateXltOnClick } from '../CommonUtilityServices/DownloadFileService';

const AgencyToolkitAgencyAutomation = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(
    `AgencyToolkitAgencyAutomationPanel`,
  );

  const { legacyWebUrlDomain, legacyUrl } = useContext(AppConfigContext);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState(false);

  const handleClickDownloadFile = (event) => {
    const _fileUrl = event.currentTarget.getAttribute('data-file');

    handleFileDownload(
      _fileUrl,
      setLoadingPdf,
      setDownloadingPdfError,
      setIsDownloadingPdfErrorVisible,
    );
  };

  const handleClickDownloadPdf = (event) => {
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');

    handlePdfDownload(
      'GET',
      null,
      pdfUrl,
      setLoadingPdf,
      setDownloadingPdfError,
      setIsDownloadingPdfErrorVisible,
      'newWindow',
    );
  };

  const handleClickProxyRedirect = (event) => {
    const routeUrl = event.currentTarget.getAttribute('data-route');
    window.open(`${legacyWebUrlDomain}/agentsonly${routeUrl}`, '_blank');
  };

  const handleClickLegacyDoc = (event) => {
    const docUrl = event.currentTarget.getAttribute('data-pdf');
    handleStateXltOnClick(legacyUrl, docUrl);
  };

  const localStateMap = { loadingPdf, downloadingPdfError, isDownloadingPdfErrorVisible };

  const callBackFunctions = {
    handleClickDownloadPdf,
    handleClickDownloadFile,
    handleClickProxyRedirect,
    handleClickLegacyDoc,
  };

  return loadingSd ? (
    <CLSAuditFormsSkeleton />
  ) : (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          stateMap={localStateMap}
          functionMap={callBackFunctions}
        />
      )}
    </>
  );
};

export default AgencyToolkitAgencyAutomation;
