import { useContext } from 'react';
import PropTypes from 'prop-types';

import agentContext from '../../contexts/agent.context';

const Feature = ({ children, allowed, forbidden }) => {
  const { FeatureFlags } = useContext(agentContext);

  let featureEnabled = true
  if (allowed) {
    featureEnabled = false;
    if (allowed instanceof Array) {
      for (let fn of allowed) {
        featureEnabled = featureEnabled || (FeatureFlags?.[fn] ?? false)
      }
    } else {
      featureEnabled = FeatureFlags?.[allowed] ?? false;
    }
  }
  
  if (forbidden) {
    if (forbidden instanceof Array) {
      for (let fn of forbidden) {
        featureEnabled = featureEnabled && (FeatureFlags?.[fn] ? false : true)
      }
    } else {
      featureEnabled = FeatureFlags?.[forbidden] ? false : true ;
    }
  } 

  return featureEnabled ? children : null;
};

Feature.propTypes = {
  children: PropTypes.node,
  forbidden: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // pass this prop is you want to explicitly hide the rendered content from whoever has the passed flag == true (inverse)
  allowed: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Feature;
