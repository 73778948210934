import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Box, Button, Col, Icon, Row, Typography, WorkflowViewContainer } from 'components/AORedesign';
import PLSPolicyChangeView from 'components/PersonalLinesServices/PLSPolicyChangeView';
import {
  COMMERCIAL_LOB_VIEW,
  PERSONAL_LOB_VIEW,
  WorkflowCardBonds,
  WorkflowCardCommercial,
  WorkflowCardFarmRanch,
  WorkflowCardPersonal,
  WorkflowContainer,
} from 'components/Workflow';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CLPolicyViewChange from './CLPolicyViewChange';

const LABEL_TEXT = 'Policy View/Change';

const useStyles = makeStyles((theme) => ({
  title: theme.typography.h3Brand,
  container: { margin: `${theme.spacing(5.5)}px auto`, minWidth: '75rem' },
}));

const PolicyViewChange = ({ TriggerButton }) => {
  const [policyView, setPolicyView] = useState('');
  const classes = useStyles();

  const handleBackOnClick = () => {
    setPolicyView('');
  };

  const handlePersonalCardOnClick = () => {
    setPolicyView(PERSONAL_LOB_VIEW);
  };

  const handleCommercialCardOnClick = () => {
    setPolicyView(COMMERCIAL_LOB_VIEW);
  };

  const renderTitle = () => {
    if (policyView === PERSONAL_LOB_VIEW) {
      return (
        <Box mb={3}>
          <Typography variant="h3" className={classes.title}>
            Personal Lines
          </Typography>
        </Box>
      );
    } else if (policyView === COMMERCIAL_LOB_VIEW) {
      return (
        <Box mb={3}>
          <Typography variant="h3" className={classes.title}>
            Commercial Lines
          </Typography>
        </Box>
      );
    } else {
      return null;
    }
  };

  const renderContent = () => {
    if (!policyView) {
      return (
        <WorkflowContainer>
          <Row spacing={3} justifyContent="center">
            <Col>
              <WorkflowCardPersonal onClick={handlePersonalCardOnClick} />
            </Col>
            <Col>
              <WorkflowCardCommercial onClick={handleCommercialCardOnClick} />
            </Col>
            <Col>
              <WorkflowCardBonds />
            </Col>
            <Col>
              <WorkflowCardFarmRanch />
            </Col>
          </Row>
        </WorkflowContainer>
      );
    } else if (policyView === PERSONAL_LOB_VIEW) {
      return (
        <WorkflowContainer>
          <PLSPolicyChangeView />
        </WorkflowContainer>
      );
    } else if (policyView === COMMERCIAL_LOB_VIEW) {
      return <CLPolicyViewChange />;
    } else {
      return null;
    }
  };

  return (
    <WorkflowViewContainer
      appBarTitle={`Select Product For ${LABEL_TEXT}`}
      buttonLabel={LABEL_TEXT}
      startIcon={<Icon iconName="icons-icon-published-with-changes" />}
      handleResetOnClose={handleBackOnClick}
      TriggerButton={TriggerButton}>
      <Box className={classes.container}>
        {renderTitle()}
        {renderContent()}
        {policyView.length > 0 && (
          <Box pt={3} pb={2}>
            <Button onClick={handleBackOnClick} startIcon={<ArrowBackIcon />} variant="text" color="secondary">
              Back
            </Button>
          </Box>
        )}
      </Box>
    </WorkflowViewContainer>
  );
};

PolicyViewChange.propTypes = {
  TriggerButton: PropTypes.node,
};

export default PolicyViewChange;
