import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import cuid from 'cuid';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { usePopupState, bindTrigger, bindMenu, bindHover } from 'material-ui-popup-state/hooks';
import _ from 'lodash';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import AppDataContext from '../../contexts/appData.context';
import AppConfigContext from '../../contexts/appConfig.context';
import agentContext from '../../contexts/agent.context';
import { ROUTE_COMMERCIAL_LINES_SERVICES } from '../../constants/routes';
import featureFlags from '../../constants/featureFlags';

const NavigationHoverMenu = ({ id, title, subMenu }) => {
  const { legacyUrl } = useContext(AppConfigContext);
  const { clearAppetiteData } = useContext(AppDataContext);
  const { FeatureFlags } = useContext(agentContext);

  const history = useHistory();
  const popupState = usePopupState({
    popupId: 'navMenu',
    variant: 'popover',
  });

  const handleMenuItemClick = (_actions) => {
    const { type, action } = _actions;
    let menuItemAction;
    switch (type) {
      case 'href': {
        menuItemAction = () => window.open(`${action}`, '_blank');
        break;
      }
      case 'routeLegacy': {
        menuItemAction = () => window.open(`${legacyUrl}${action}`, '_blank');
        break;
      }
      case 'redirectUrl': {
        menuItemAction = () => {
          const { requestBody, httpMethod } = action;
          return redirectUrlFunction(
            requestBody,
            httpMethod,
            null,
            () => {},
            () => {},
          );
        };
        break;
      }
      default: {
        menuItemAction = () => {
          // Need to clear the appetite data in AppData Context when we go to ask Kodiak via nav menu
          if (
            action === ROUTE_COMMERCIAL_LINES_SERVICES &&
            history.location.pathname !== ROUTE_COMMERCIAL_LINES_SERVICES
          ) {
            clearAppetiteData();
          }
          history.push(`${action}`);
        };
        break;
      }
    }
    return () => menuItemAction();
  };

  return (
    <div>
      <Button
        {...bindTrigger(popupState)}
        {...bindHover(popupState)}
        style={{ cursor: `default` }}
        id={id}>
        {title}
      </Button>
      <Menu
        {...bindMenu(popupState)}
        MenuListProps={{ onMouseLeave: () => popupState.close() }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        {!_.isNil(subMenu) &&
          subMenu
            .filter((item) => {
              return (
                item.UiElementId !== 'bondsTraining' ||
                (item.UiElementId === 'bondsTraining' &&
                  FeatureFlags?.[featureFlags.BONDS_TRAINING_PERMITTED])
              );
            })
            .map(({ Representation, Actions, UiElementId, ...props }) => (
              <MenuItem
                onClick={handleMenuItemClick(Actions[0])}
                key={cuid()}
                id={UiElementId}
                {...props}>
                {Representation}
              </MenuItem>
            ))}
      </Menu>
    </div>
  );
};

NavigationHoverMenu.propTypes = {
  title: PropTypes.string,
  subMenu: PropTypes.array,
  id: PropTypes.string,
};

export default NavigationHoverMenu;
