import { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';

import { GET_UI_DEFINITION } from '../constants/api';
import { getMockData } from '../utils/sd2md';

const getErrorAlertDefinition = (fileName, message) => {
  return {
    UiElementId: '',
    ComponentType: 'RowCol',
    Representation: '',
    ComponentProperties: {},
    Children: [
      {
        UiElementId: '',
        ComponentType: 'AOAlert',
        Representation: '',
        ComponentProperties: {
          severity: 'error',
          alertTitleMessage: `Failed to load ${fileName}`,
          message: message,
        },
        Children: [],
      },
    ],
  };
};

const useScreenDefinition = (fileName, dependencies = []) => {
  const [UiDefinitionsData, setUiDefinitionsData] = useState(null);
  const [loadingSd, setLoadingSd] = useState(true);

  useEffect(() => {
    const nilFileName = _.isNil(fileName);

    if (!nilFileName) {
      let unmounted = false;
      let defs;

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      const setMockData = () => {
        try {
          const mockData = getMockData(`${fileName}.json`);
          defs = [mockData];
        } catch (error) {
          defs = nilFileName ? [] : [getErrorAlertDefinition(fileName, '')];
        }
      };

      axios
        .get(`${GET_UI_DEFINITION}/${fileName}`, {
          cancelToken: source.token,
        })
        .then((response) => {
          const { UiDefinitions } = response.data;
          const clsWipDefs = [UiDefinitions];
          defs = clsWipDefs;
        })
        .catch((err) => {
          if (!unmounted) {
            if (axios.isCancel(err)) {
              console.error(`request cancelled in ${fileName}:${err.message}`);
            } else {
              console.error(`another error happened in ${fileName}:` + err.message);
            }
          }

          defs = nilFileName ? [] : [getErrorAlertDefinition(fileName, err.message)];
        })
        .finally(() => {
          if (process.env.NODE_ENV === 'development') {
            if (process.env.REACT_APP_SKIP_MOCK_SCREENS == 'true') {
              // using local api to serve up screen defns
              console.log('Pulling Screen Defn from API');
            } else {
              // This is to handle the case where the file is not initialized on a new screen in local dev.
              console.log('Pulling Screen Defn from MockData');
              setMockData();
            }
          }

          setLoadingSd(false);
          setUiDefinitionsData(defs);
        });

      return () => {
        unmounted = true;
        source.cancel('Cancelling in cleanup');
      };
    }
  }, [...dependencies]);

  return { UiDefinitionsData, loadingSd };
};

export default useScreenDefinition;
