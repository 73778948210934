import React from 'react';
import _ from 'lodash';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import { ScreenSmith } from '../ScreenSmith';

import { StyledReportsHeader } from './ReportsHeader.styled';

const ReportsHeader = () => {
  const { UiDefinitionsData } = useScreenDefinition('ReportsHeader');

  return (
    <StyledReportsHeader>
      {!_.isNil(UiDefinitionsData) && <ScreenSmith definition={UiDefinitionsData} />}
    </StyledReportsHeader>
  );
};

export default ReportsHeader;
