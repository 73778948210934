import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  REDIRECT_TO_NEW_WINDOW,
  REDIRECT_AS_REPLACEMENT,
  REDIRECT_TO_NEW_TAB,
} from '../../constants/api';

//By default redirect urls opens up in a new tab, otherwise opens up in new window or self based on the redirectType
const openInNewWindow = (
  _redirectUrl,
  http_method,
  data,
  pageName,
  redirectType = REDIRECT_TO_NEW_TAB,
  responseQueryStrings,
) => {
  let redirectUrl = _redirectUrl;
  let win = window;
  let form;
  if (!_.isNull(pageName) && redirectType === REDIRECT_TO_NEW_WINDOW) {
    win = window.open(
      '',
      pageName,
      'height=1200,width=1500,scrollbars=yes,resizable=yes,top=0,left=0',
    );
    form = win.document.createElement('form');
  } else {
    form = document.createElement('form');
  }

  if (!_.isNil(responseQueryStrings)) {
    const parameters = [];

    for (const param of responseQueryStrings) {
      parameters.push(
        `${param.name}=${Array.isArray(param.value) ? param.value.join(',') : param.value}`,
      );
    }
    if (parameters.length > 1) {
      const queryStringsToAppend = `?${parameters.join('&')}`;
      redirectUrl += queryStringsToAppend;
    }
  }

  if (redirectUrl.indexOf('?') > 0) {
    var indexValue = redirectUrl.indexOf('?');
    var queryStrings = redirectUrl.substr(indexValue + 1).split('&');
    for (var queryString in queryStrings) {
      let queryStringParams = queryStrings[queryString].split('=');
      var hiddenField = win.document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', queryStringParams[0]);
      hiddenField.setAttribute('value', queryStringParams[1]);
      form.appendChild(hiddenField);
      win.document.body.appendChild(form);
    }
    const okta_token = localStorage.getItem('okta-token-storage');
    if (redirectUrl.indexOf('plv4.msa') > 0) {
      if (okta_token !== undefined && okta_token !== null) {
        const access_token = JSON.parse(okta_token).accessToken.accessToken;
        let hiddenField = win.document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', 'token');
        hiddenField.setAttribute('value', access_token);
        form.appendChild(hiddenField);
      }
    }
    if (redirectUrl.includes('paymentus')) {
      let hiddenField = win.document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', 'itok');
      hiddenField.setAttribute('value', data.itok);
      form.appendChild(hiddenField);
    }
    form.setAttribute('method', 'get');
    form.setAttribute('action', redirectUrl);
    form.style.display = 'none';
    if (!_.isNull(pageName) && redirectType === REDIRECT_TO_NEW_WINDOW) {
      form.setAttribute('target', pageName);
    } else if (redirectType === REDIRECT_AS_REPLACEMENT) {
      form.setAttribute('target', '_self');
    } else {
      form.setAttribute('target', '_blank');
    }
    win.document.body.appendChild(form);
    form.submit();
  } else {
    if (!_.isNil(data)) {
      var jsonData = data;
      if (data.requestParameters) {
        jsonData = data.requestParameters;
      }
      //if data has httpMethod and if it is post then pass data.httpBody to jsonData
      if (data.httpMethod) {
        form.setAttribute('method', data.httpMethod);
        if (data.httpMethod === 'POST' || data.httpMethod === 'PUT') {
          jsonData = data.httpBody;
        }
      } else {
        form.setAttribute('method', http_method);
      }
      for (var key in jsonData) {
        if (jsonData[key] !== null) {
          var hiddenInput1 = win.document.createElement('input');
          hiddenInput1.setAttribute('type', 'hidden');
          hiddenInput1.setAttribute('name', key);
          hiddenInput1.setAttribute('value', jsonData[key]);
          form.appendChild(hiddenInput1);
        }
      }
    }
    form.setAttribute('method', http_method);
    form.setAttribute('action', redirectUrl);
    if (!_.isNil(pageName) && redirectType === REDIRECT_TO_NEW_WINDOW) {
      form.setAttribute('target', pageName);
    } else if (redirectType === REDIRECT_AS_REPLACEMENT) {
      form.setAttribute('target', '_self');
    } else {
      form.setAttribute('target', '_blank');
    }
    form.style.display = 'none';
    win.document.body.appendChild(form);
    form.submit();
    win.document.body.removeChild(form);
  }
};

const OpenWindowService = (url, http_method, data, pageName, redirectType, queryString) => {
  openInNewWindow(url, http_method, data, pageName, redirectType, queryString);
};

OpenWindowService.propTypes = {
  url: PropTypes.string,
  data: PropTypes.object,
  pageName: PropTypes.string,
  redirectType: PropTypes.string,
};

export default OpenWindowService;
