import { AppBar, IconButton, Slide, Toolbar, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';

import { PolicyViewChange } from 'components/PolicyViewChange';
import { StartQuote } from 'components/StartQuote';
import { Box, Button, Dialog, Icon, Typography } from '../../AORedesign';

const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      minWidth: '76rem',
      marginTop: props.contained ? undefined : theme.spacing(8),
      inset: props.contained ? '64px 0 0 72px !important' : undefined,
    },
    paper: { backgroundColor: theme.palette.background.default, boxShadow: 'unset' },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    container: { overflow: 'auto', display: props.block ? 'block' : 'flex', justifyContent: 'center' },
    actionButton: {
      color: theme.palette.common.white,
    },
  }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WorkflowDialog = ({ appBarTitle, block, children, contained, handleClose, open, showStartQuote, showPolicyViewChange }) => {
  const classes = useStyles({ block, contained })();

  const StartQuoteButton = (props) => (
    <Button className={classes.actionButton} variant="text" startIcon={<Icon iconName="icons-icon-request-quote" />} {...props}>
      Start Quote
    </Button>
  );

  const PolicyViewChangeButton = (props) => (
    <Button className={classes.actionButton} variant="text" startIcon={<Icon iconName="icons-icon-published-with-changes" />} {...props}>
      Policy View/Change
    </Button>
  );

  return (
    <Dialog
      hideBackdrop
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      BackdropProps={{ style: { backgroundColor: 'unset' } }}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            {contained ? <ArrowBackIcon /> : <CloseIcon />}
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {appBarTitle}
          </Typography>

          {showStartQuote && <StartQuote TriggerButton={StartQuoteButton} />}
          {showPolicyViewChange && <PolicyViewChange TriggerButton={PolicyViewChangeButton} />}
        </Toolbar>
      </AppBar>
      <Box className={classes.container}>{children}</Box>
    </Dialog>
  );
};

WorkflowDialog.propTypes = {
  appBarTitle: PropTypes.string,
  block: PropTypes.bool,
  children: PropTypes.node,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  contained: PropTypes.bool,
  showStartQuote: PropTypes.bool,
  showPolicyViewChange: PropTypes.bool,
};

export default WorkflowDialog;
