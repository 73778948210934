import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import cuid from 'cuid';

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Col,
  DataGrid,
  FormControl,
  Link,
  Radio,
  RadioGroup,
  Row,
  Select,
  TextField,
  Typography,
  defaultPaginationData,
} from 'components/AORedesign';
import {
  SEARCH_BY_ACCOUNT_NUMBER,
  SEARCH_BY_INSURED_NAME,
  SEARCH_BY_POLICY_NUMBER,
  billingSearchCriteriaMenuItems,
  fetchAccountNumberFormSuggestions,
  fetchInsuredNameFormSuggestions,
  fetchPolicyNumberFormSuggestions,
  getInvalidText,
  insuredNameSearchRadioOptions,
} from './Billing.api';
import {
  ACCOUNT_NUMBER_URL,
  GET_BILLING_EXACT_SEARCH,
  GET_BILLING_SEARCH,
  GET_BILLING_SEARCH_PAGE_COUNT,
  INSURED_LAST_NAME_URL,
  INSURED_LAST_NAME_COUNT_URL,
  POLICY_NUMBER_URL,
  GET_BILLING_SEARCH_V2,
} from 'constants/api';
import { ROUTE_BILLING_DETAILS } from 'constants/routes';
import { useDebounce } from 'hooks/useDebounce';
import { Divider } from '@material-ui/core';
import AppDataContext from 'contexts/appData.context';

const defaultSearchResult = { loading: false, data: null };
const TIMEOUT_ERROR_MESSAGE = 'Search criteria is too large. Please refine your search and try again.';
const TIMEOUT_INVALID_TEXT = 'Refine search criteria.';
const TIMEOUT_ERROR = 'Network Error';

const BillingInquiry = () => {
  const { setShowServiceUnavailable, handleSetSnackbarData } = useContext(AppDataContext);
  const [formData, setFormData] = useState({
    policyNumber: '',
    accountNumber: '',
    insuredName: '',
  });
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    policyNumber: { value: '', invalidText: '' },
    accountNumber: { value: '', invalidText: '' },
    insuredName: { value: '', invalidText: '' },
  });
  const [selectedSearchCriteria, setSelectedSearchCriteria] = useState(SEARCH_BY_POLICY_NUMBER);
  const [insuredLastNameRadioValue, setInsuredLastNameRadioValue] = useState('exact');
  const [policyNumberOptions, setPolicyNumberOptions] = useState([]);
  const [accountNumberOptions, setAccountNumberOptions] = useState([]);
  const [insuredNameOptions, setInsuredNameOptions] = useState([]);
  const [searchResults, setSearchResults] = useState(defaultSearchResult);
  const [paginationData, setPaginationData] = useState(defaultPaginationData);

  const history = useHistory();
  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);

  // Policy Number debounce
  useEffect(async () => {
    if (autocompleteInputValues.policyNumber.value.length >= 3) {
      const suggestions = await fetchPolicyNumberFormSuggestions({
        searchValue: autocompleteInputValues.policyNumber.value,
      }).catch((err) => {
        console.error(err);
      });
      setPolicyNumberOptions(suggestions);
    } else {
      setPolicyNumberOptions([]);
    }
  }, [debouncedState.policyNumber]);

  // Account Number debounce
  useEffect(async () => {
    if (autocompleteInputValues.accountNumber.value.length >= 3) {
      const suggestions = await fetchAccountNumberFormSuggestions({
        searchValue: autocompleteInputValues.accountNumber.value,
      }).catch((err) => {
        console.error(err);
      });
      setAccountNumberOptions(suggestions);
    } else {
      setAccountNumberOptions([]);
    }
  }, [debouncedState.accountNumber]);

  // Insured Name debounce
  useEffect(async () => {
    if (autocompleteInputValues.insuredName.value.length >= 3) {
      const suggestions = await fetchInsuredNameFormSuggestions({
        searchValue: autocompleteInputValues.insuredName.value,
      }).catch((err) => {
        console.error(err);
      });
      setInsuredNameOptions(suggestions);
    } else {
      setInsuredNameOptions([]);
    }
  }, [debouncedState.insuredName]);

  const handleChangeSearchCriteriaSelect = (e) => {
    const { value } = e.target;
    setSelectedSearchCriteria(value);
    setSearchResults(defaultSearchResult);
  };

  const handleChangeInsuredLastNameRadioValue = (e) => {
    const value = e.target.value;
    setInsuredLastNameRadioValue(value);
  };

  const handleAutocompleteOnChange = (e, value) => {
    setFormData({ ...formData, [selectedSearchCriteria]: value });
  };

  const handleAutocompleteOnInputChange = (e, value) => {
    setAutocompleteInputValues({
      ...autocompleteInputValues,
      [selectedSearchCriteria]: {
        ...autocompleteInputValues[selectedSearchCriteria],
        value,
        invalidText: '',
      },
    });
    setDebouncedState({
      ...debouncedState,
      [selectedSearchCriteria]: { ...debouncedState[selectedSearchCriteria], value },
    });
  };

  const handleAutocompleteOnBlur = () => {
    if (formData[selectedSearchCriteria] !== autocompleteInputValues[selectedSearchCriteria].value) {
      setFormData({
        ...formData,
        [selectedSearchCriteria]: autocompleteInputValues[selectedSearchCriteria].value,
      });
    }
  };

  const handleSearch = async (page, pageSize) => {
    const invalidText = getInvalidText(selectedSearchCriteria, formData[selectedSearchCriteria]);
    const isValidSearch = invalidText.length === 0;
    setAutocompleteInputValues({
      ...autocompleteInputValues,
      [selectedSearchCriteria]: {
        ...autocompleteInputValues[selectedSearchCriteria],
        invalidText,
      },
    });

    if (isValidSearch) {
      setPaginationData((prevState) => ({ ...prevState, currentPage: page, pageSize }));
      setSearchResults((prevState) => ({
        ...prevState,
        loading: true,
      }));

      try {
        const searchValue = formData[selectedSearchCriteria];
        let rowCount, searchResults;

        switch (selectedSearchCriteria) {
          case SEARCH_BY_POLICY_NUMBER: {
            rowCount = await axios.get(`${GET_BILLING_SEARCH_PAGE_COUNT}/exact${POLICY_NUMBER_URL}/${searchValue}`);
            searchResults = await axios.get(`${GET_BILLING_EXACT_SEARCH}${POLICY_NUMBER_URL}/${searchValue}/${rowCount.data}`);
            break;
          }
          case SEARCH_BY_ACCOUNT_NUMBER: {
            rowCount = await axios.get(`${GET_BILLING_SEARCH_PAGE_COUNT}/exact${ACCOUNT_NUMBER_URL}/${searchValue}`);
            searchResults = await axios.get(`${GET_BILLING_EXACT_SEARCH}${ACCOUNT_NUMBER_URL}/${searchValue}/${rowCount.data}`);
            break;
          }
          case SEARCH_BY_INSURED_NAME: {
            const count_params = {
              insuredName: searchValue,
              searchType: insuredLastNameRadioValue.toUpperCase(),
            };
            const search_params = {
              insuredName: searchValue,
              searchType: insuredLastNameRadioValue.toUpperCase(),
              pageNumber: page + 1,
              rowsPerPage: pageSize,
            };
            if (page === 0) {
              rowCount = await axios.get(`${GET_BILLING_SEARCH_V2}${INSURED_LAST_NAME_COUNT_URL}`, {
                params: count_params,
              });
              rowCount = { data: rowCount.data.totalItems };
            } else {
              rowCount = { data: paginationData.totalCount };
            }
            searchResults = await axios.get(`${GET_BILLING_SEARCH_V2}${INSURED_LAST_NAME_URL}`, {
              params: search_params,
            });
            searchResults = { data: searchResults.data.accounts };
            break;
          }
          default: {
            searchResults = await axios.get(`${GET_BILLING_SEARCH}${POLICY_NUMBER_URL}/${searchValue}`);
            break;
          }
        }

        const { data: rowCountData } = rowCount;
        setPaginationData((prevVal) => ({
          ...prevVal,
          totalCount: rowCountData,
        }));

        const { data: searchResultsData } = searchResults;
        setSearchResults({
          loading: false,
          data: searchResultsData?.map((sr) => ({ id: cuid(), ...sr })),
        });
      } catch (error) {
        console.error(error);
        if (error.message === TIMEOUT_ERROR) {
          setAutocompleteInputValues({
            ...autocompleteInputValues,
            [selectedSearchCriteria]: {
              ...autocompleteInputValues[selectedSearchCriteria],
              invalidText: TIMEOUT_INVALID_TEXT,
            },
          });
          handleSetSnackbarData({
            message: TIMEOUT_ERROR_MESSAGE,
            severity: 'error',
          });
        } else {
          setShowServiceUnavailable(true);
        }
        setSearchResults({ loading: false, data: null });
      }
    }
  };

  const handleOnClickSearch = () => {
    handleSearch(defaultPaginationData.currentPage, paginationData.pageSize);
  };

  const handleOnPageChange = (page) => {
    setPaginationData((prevState) => ({ ...prevState, currentPage: page }));
    handleSearch(page, paginationData.pageSize);
  };

  const handleOnPageSizeChange = (pageSize) => {
    handleSearch(defaultPaginationData.currentPage, pageSize);
  };

  const renderCell = (params) => {
    const handleOnClick = () => {
      history.push(`${ROUTE_BILLING_DETAILS}/${params.row.accountNumber}`);
    };

    return params.row.accountNumber && <Link onClick={handleOnClick}>{params.row.accountNumber?.trim()}</Link>;
  };

  const billingSearchColumns = [
    {
      field: 'name',
      headerName: 'Account Name',
      sortable: true,
      flex: 1,
    },
    {
      field: 'accountNumber',
      headerName: 'Account Number',
      sortable: true,
      minWidth: 200,
      renderCell,
    },
    {
      field: 'address',
      headerName: 'Address',
      sortable: true,
      flex: 2,
    },
  ];

  return (
    <Box mb={2}>
      <Card>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleOnClickSearch();
          }}>
          <Row>
            <Col xs={12}>
              <Box mb={4}>
                <Typography variant="h6">Billing Inquiry</Typography>
              </Box>
              <Box mb={2}>
                <Row spacing={2} alignItems="center">
                  <Col>
                    <Select
                      label="Search By"
                      id="billing-inquiry-search-by-select"
                      labelId="billing-inquiry-search-by-select-label"
                      value={selectedSearchCriteria}
                      menuItems={billingSearchCriteriaMenuItems}
                      onChange={handleChangeSearchCriteriaSelect}
                    />
                  </Col>
                  <Col>
                    <>
                      {selectedSearchCriteria === SEARCH_BY_POLICY_NUMBER && (
                        <Autocomplete
                          style={{ width: 400 }}
                          id="billing-services-policy-number-input"
                          value={formData.policyNumber}
                          inputValue={autocompleteInputValues.policyNumber.value}
                          options={policyNumberOptions ?? []}
                          onChange={handleAutocompleteOnChange}
                          onInputChange={handleAutocompleteOnInputChange}
                          onBlur={handleAutocompleteOnBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Policy Number"
                              placeholder="Enter Policy Number"
                              variant="outlined"
                              error={autocompleteInputValues.policyNumber.invalidText.length > 0}
                              helperText={autocompleteInputValues.policyNumber.invalidText}
                            />
                          )}
                        />
                      )}
                      {selectedSearchCriteria === SEARCH_BY_ACCOUNT_NUMBER && (
                        <Autocomplete
                          style={{ width: 400 }}
                          id="billing-services-account-number-input"
                          value={formData.accountNumber}
                          inputValue={autocompleteInputValues.accountNumber.value}
                          options={accountNumberOptions ?? []}
                          onChange={handleAutocompleteOnChange}
                          onInputChange={handleAutocompleteOnInputChange}
                          onBlur={handleAutocompleteOnBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Account Number"
                              placeholder="Enter Account Number"
                              variant="outlined"
                              error={autocompleteInputValues.accountNumber.invalidText.length > 0}
                              helperText={autocompleteInputValues.accountNumber.invalidText}
                            />
                          )}
                        />
                      )}
                      {selectedSearchCriteria === SEARCH_BY_INSURED_NAME && (
                        <Autocomplete
                          style={{ width: 400 }}
                          id="billing-services-account-number-input"
                          value={formData.insuredName}
                          inputValue={autocompleteInputValues.insuredName.value}
                          options={insuredNameOptions ?? []}
                          onChange={handleAutocompleteOnChange}
                          onInputChange={handleAutocompleteOnInputChange}
                          onBlur={handleAutocompleteOnBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Insured Name"
                              placeholder="Enter Insured Last Name"
                              variant="outlined"
                              error={autocompleteInputValues.insuredName.invalidText.length > 0}
                              helperText={autocompleteInputValues.insuredName.invalidText}
                            />
                          )}
                        />
                      )}
                    </>
                  </Col>
                  <Col>
                    <Box>
                      <Button type="submit" disabled={searchResults?.loading}>
                        Search
                      </Button>
                    </Box>
                  </Col>
                </Row>
              </Box>
              {selectedSearchCriteria === SEARCH_BY_INSURED_NAME && (
                <Box mt={2}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      id="billing-services-insured-last-name-radio"
                      aria-label="billing-services-insured-last-name-radio"
                      value={insuredLastNameRadioValue}
                      onChange={handleChangeInsuredLastNameRadioValue}
                      row>
                      {insuredNameSearchRadioOptions.map((option) => (
                        <Radio key={`${option.label}-${option.value}`} label={option.label} value={option.value} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
              )}
            </Col>
          </Row>
        </form>

        {searchResults?.data !== null && (
          <>
            <Box my={3}>
              <Divider />
            </Box>
            <DataGrid
              columns={billingSearchColumns}
              loading={searchResults.loading}
              onPageChange={handleOnPageChange}
              onPageSizeChange={handleOnPageSizeChange}
              page={paginationData.currentPage}
              pageSize={paginationData.pageSize}
              paginationMode="server"
              rowCount={paginationData.totalCount}
              rows={searchResults.data}
              noResultsMessage="No results found. Adjust your search."
            />
          </>
        )}
      </Card>
    </Box>
  );
};

export default BillingInquiry;
