import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '../AORedesign';
import { StartQuote } from 'components/StartQuote';
import { PolicyViewChange } from 'components/PolicyViewChange';

const QuickActions = ({ hideStartQuote, hidePolicyViewChange }) => {
  return (
    <Row spacing={1}>
      <Col>{!hideStartQuote && <StartQuote />}</Col>
      <Col>{!hidePolicyViewChange && <PolicyViewChange />}</Col>
    </Row>
  );
};

QuickActions.propTypes = {
  hidePolicyViewChange: PropTypes.bool,
  hideStartQuote: PropTypes.bool,
};

export default QuickActions;
