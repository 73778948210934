import React from 'react';
import PropTypes from 'prop-types';
import { SecureRoute } from '@okta/okta-react';

import { AppConfigProvider } from '../AppConfigProvider';

const RouteWrapper = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <SecureRoute
      {...rest}
      render={(props) => (
        <AppConfigProvider>
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        </AppConfigProvider>
      )}
    />
  );
};

RouteWrapper.propTypes = {
  component: PropTypes.func,
  layout: PropTypes.func,
};

export default RouteWrapper;
