import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import axios from 'axios';

import { ScreenSmith } from '../ScreenSmith';
import { CardTabSkeleton } from '../AOSkeleton';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { ReportsHeader } from '../ReportsHeader';
import AnnualMileageValidationReportAgentView from './AnnualMileageValidationReportAgentView';
import AnnualMileageValidationReportInternalUserView from './AnnualMileageValidationReportInternalUserView';

import { StyledAnnualMileageValidationReport } from './AnnualMileageValidationReport.styled';
import { handleCreateOnClick } from './AnnualMileageValidationReport.api';
import { getPastYearMonthsMenuItems } from '../../helpers/dateHelpers';
import {
  handleOnChange,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleAOAutocompleteOnBlur,
} from '../../components/CommonUtilityServices/EventHandlerService';
import { GET_REPORTS_URL } from '../../constants/api';
import { useDebounce } from '../../hooks/useDebounce';

const LocalComponents = {
  ReportsHeader,
  AnnualMileageValidationReportAgentView,
  AnnualMileageValidationReportInternalUserView,
};

const AnnualMileageValidationReport = () => {
  // Agent view
  const [agencyCodeOptions, setAgencyCodeOptions] = useState([]);
  // Internal user view
  const [agencyNameOptions, setAgencyNameOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingPdf, setLoadingPdf] = useState(false);

  const [formData, setFormData] = useState({
    AgtNum: '',
    AsOfDate: '',
  });

  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    AgtNum: { value: '' },
  });
  const [debouncedState, setDebouncedState] = useDebounce(autocompleteInputValues);

  const { UiDefinitionsData, loadingSd } = useScreenDefinition('AnnualMileageValidationReport');

  useEffect(async () => {
    if (autocompleteInputValues.AgtNum.value.length >= 3) {
      axios
        .get(
          `${GET_REPORTS_URL}/agencies/agency-suggestions?searchValue=${autocompleteInputValues.AgtNum.value}`,
        )
        .then((results) => setAgencyNameOptions(results.data))
        .catch((error) => console.error(error));
    } else {
      setAgencyNameOptions([]);
    }
  }, [debouncedState.AgtNum]);

  const aMVReportState = {
    effectivePeriodMenuItems: getPastYearMonthsMenuItems(),
    agencyCodeOptions,
    agencyNameOptions,
    agentCode: formData.AgtNum,
    errorMessage,
    loadingPdf,
    createButtonDisabled: formData.AgtNum.length < 6 || !formData.AsOfDate || loadingPdf,
  };
  const aMVReportCallbackFunctions = {
    handleOnChange: (e) => handleOnChange(e, formData, setFormData),
    setAgencyCodeOptions,
    setAgencyNameOptions,
    handleAgencyNameOnBlur: () =>
      handleAOAutocompleteOnBlur('AgtNum', formData, setFormData, autocompleteInputValues),
    handleAgencyNameOnChange: (e, value) =>
      handleAOAutocompleteOnChange('AgtNum', e, value, formData, setFormData),
    handleAgencyNameOnInputChange: (e, value) =>
      handleAOAutocompleteOnInputChange(
        'AgtNum',
        e,
        value,
        autocompleteInputValues,
        setAutocompleteInputValues,
        debouncedState,
        setDebouncedState,
      ),
    handleCreateOnClick: () => handleCreateOnClick(formData, setErrorMessage, setLoadingPdf),
  };

  return loadingSd ? (
    <CardTabSkeleton />
  ) : (
    <StyledAnnualMileageValidationReport>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={LocalComponents}
          functionMap={aMVReportCallbackFunctions}
          stateMap={aMVReportState}
        />
      )}
    </StyledAnnualMileageValidationReport>
  );
};

export default AnnualMileageValidationReport;
