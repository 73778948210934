import React from 'react';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { StyledCompoundLinkAndText } from './CompoundLinkAndText.styled';

const CompoundLinkAndText = ({ pre, peri, post }) => {
  // return plain text if a string or return a link
  // if it is an object with shape of text and url
  const setLinkOrText = (_el) => {
    const linkOrText = _.isPlainObject(_el) ? (
      <Link
        to={_el.url}
        style={{
          marginLeft: '5px',
          marginRight: '5px',
          textDecoration: 'none',
        }}>
        <Box display="inline" color="secondary.main">
          {_el.text}
        </Box>
      </Link>
    ) : (
      _el
    );
    return linkOrText;
  };
  return (
    <StyledCompoundLinkAndText>
      <Typography>
        {pre && setLinkOrText(pre)}
        {peri && setLinkOrText(peri)}
        {post && setLinkOrText(post)}
      </Typography>
    </StyledCompoundLinkAndText>
  );
};

CompoundLinkAndText.propTypes = {
  pre: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  ]),
  peri: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  ]),
  post: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  ]),
};

export default CompoundLinkAndText;
