import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { ViewContainer } from '../Layout';
import { AOButton } from '../AOButton';
import { ROUTE_USER_ADMIN } from '../../constants/routes';

const UserAdminViewContainer = ({ children, title, hideReturn }) => {
  return (
    <ViewContainer title={title}>
      {!hideReturn && (
        <Box mb={2} display={'flex'} justifyContent={'flex-end'}>
          <AOButton color={'secondary'} route={ROUTE_USER_ADMIN} id="user-admin-container-button-user-admin">
            User Administration
          </AOButton>
        </Box>
      )}
      {children}
    </ViewContainer>
  );
};

UserAdminViewContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  hideReturn: PropTypes.bool,
};

export default UserAdminViewContainer;
