import { rem } from 'polished';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import AOButton from '../AOButton/AOButton';

const StyledAOProductCardGrid = styled(Box)(() => ({
  display: 'grid',
  gridGap: `${rem(16)}`,
  margin: '0 auto',
  padding: 0,
  maxWidth: `${rem(1200)}`,

  '@media (min-width: 42em)': {
    gridTemplateColumns: 'repeat(4, 0fr)',
  },
}));

const StyledAOProductCard = styled(InputLabel)(({ theme, selected }) => ({
  width: rem(288),
  boxSizing: 'border-box',
  borderRadius: `${rem(4)}`,
  position: 'relative',
  border: `${rem(1)} solid ${selected ? theme.palette.secondary.main : `rgba(0,0,0,0.12)`}`,
}));

const AOProductCardRecommendedMarker = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  textTransform: 'uppercase',
  backgroundColor: theme.palette.jade[600],
  color: theme.palette.common.white,
  padding: `${rem(10.5)} ${rem(16)}`,
  fontSize: rem(12),
  fontFamily: `'Montserrat', sans-serif`,
  fontWeight: 600,
  borderRadius: `${rem(4)} 0 0 0`,
}));

const StyledAOProductCardContainer = styled(Box)(() => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  transition: 'border-color 0.2s ease-out',
}));

const StyledAOProductCardIconContainer = styled('div')(({ theme }) => ({
  margin: `${rem(60)} auto 1rem`,
  width: `${rem(56)}`,
  height: `${rem(56)}`,
  borderRadius: rem(99),
  backgroundColor: theme.palette.secondary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledAOProductCardIcon = styled('img')(() => ({
  width: `${rem(30)}`,
  filter: 'brightness(0) invert(1)',
  height: 'auto',
}));

const StyledAOProductCardNoIcon = styled('div')(({ theme }) => ({
  margin: `${rem(60)} auto 1rem`,
  width: `${rem(56)}`,
  borderRadius: rem(99),
  height: `${rem(56)}`,
  backgroundColor: theme.palette.secondary.main,
}));

const StyledAOProductCardRadio = styled(Radio)(() => ({
  fontSize: 'inherit',
  margin: 0,
  position: 'absolute',
  right: `${rem(12)}`,
  top: `${rem(8)}`,
  cursor: 'pointer',
  width: rem(20),
  height: rem(20),
  padding: 0,
}));

const StyledAOProductCardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  fontSize: `${rem(20)}`,
  lineHeight: `${rem(23.44)}`,
  height: `${rem(75)}`,
  textAlign: 'center',
  padding: `0 1rem`,
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 3,
  '-webkit-box-orient': 'vertical',
}));

const StyledAOProductCardDescription = styled(Typography)(() => ({
  fontSize: `${rem(14)}`,
  lineHeight: `${rem(20)}`,
  height: `${rem(60)}`,
  padding: `0 1rem`,
  textAlign: 'center',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 3,
  '-webkit-box-orient': 'vertical',
}));

const StyledAOProductCardButton = styled(AOButton)(() => ({
  marginTop: '0.5rem',
  padding: '0.5rem 1rem',
  width: '100%',
  textAlign: 'center',
}));

export {
  StyledAOProductCardGrid,
  StyledAOProductCard,
  StyledAOProductCardContainer,
  AOProductCardRecommendedMarker,
  StyledAOProductCardIconContainer,
  StyledAOProductCardIcon,
  StyledAOProductCardNoIcon,
  StyledAOProductCardRadio,
  StyledAOProductCardTitle,
  StyledAOProductCardDescription,
  StyledAOProductCardButton,
};
