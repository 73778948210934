import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';

import RowCol from '../ScreenSmith/RowCol';
import { AORadio } from '../AORadio';
import { AOTextField } from '../AOTextField';
import { AOAutocomplete } from '../AOAutocomplete';

const CCReportInternalUserView = ({
  options,
  handleOnChange,
  handleOnInputChange,
  inputValue,
  onBlur,
  onChange,
  resetAgencyCode,
  searchByType,
  setSearchByType,
  value,
}) => {
  const handleRadioOnChange = (e) => {
    setSearchByType(e.target.value);
    resetAgencyCode();
  };

  return (
    <RowCol mb={2}>
      <RowCol mb={2}>
        <Typography>Search By:</Typography>
        <FormControl>
          <RadioGroup
            onChange={handleRadioOnChange}
            value={searchByType}
            aria-label="search-by-type"
            name="search-by-type"
            row>
            <AORadio id="cc-chargeback-report-radio-name" value="name" label="Agency Name" />
            <AORadio id="cc-chargeback-report-radio-code" value="code" label="Agency Code" />
          </RadioGroup>
        </FormControl>
      </RowCol>
      <RowCol mb={2}>
        {searchByType === 'name' ? (
          <AOAutocomplete
            handleOnChange={handleOnChange}
            handleOnInputChange={handleOnInputChange}
            inputValue={inputValue}
            label="Agency Name"
            onBlur={onBlur}
            options={options}
            placeholder="Enter Agency Name"
            value={value}
          />
        ) : (
          <AOTextField
            label="Agency Code"
            name="agencyCode"
            onChange={onChange}
            placeholder="Enter Agency Code"
            value={value}
          />
        )}
      </RowCol>
    </RowCol>
  );
};

CCReportInternalUserView.propTypes = {
  options: PropTypes.array,
  handleOnChange: PropTypes.func,
  handleOnInputChange: PropTypes.func,
  inputValue: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  resetAgencyCode: PropTypes.func,
  searchByType: PropTypes.string,
  setSearchByType: PropTypes.func,
  value: PropTypes.string,
};

export default CCReportInternalUserView;
