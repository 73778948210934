import axios from 'axios';
import { PAYMENT_MANAGEMENT_GET_CURRENT_PAYMENTS } from '../../../../constants/api';
import { currencyStringFormatter } from '../../../../helpers';

const manageCurrentPaymentGridColDef = [
  {
    field: 'index',
    headerName: 'Id',
    hide: true,
    flex: 1,
  },
  {
    field: 'dateCreated',
    headerName: 'Date',
    flex: 1,
  },
  {
    field: 'accountNumber',
    headerName: 'Account',
    flex: 1,
  },
  {
    field: 'cwaPolicy',
    headerName: 'Policy',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Ins. Name',
    flex: 1,
  },
  {
    field: 'paymentAmount',
    headerName: 'Amount',
    flex: 1,
    renderCell: ({ value }) => currencyStringFormatter(value),
  },
  {
    field: 'agentNumber',
    headerName: 'Agent Number',
    flex: 1,
  },
  {
    field: 'userId',
    headerName: 'User',
    flex: 1,
  },
  {
    field: 'method',
    headerName: 'Method',
    flex: 1,
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 1,
  },
];

const getManageCurrentPaymentsGridRows = async () => {
  const { data } = await axios.get(PAYMENT_MANAGEMENT_GET_CURRENT_PAYMENTS);
  return data;
};

export { manageCurrentPaymentGridColDef, getManageCurrentPaymentsGridRows };
