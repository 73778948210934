import React, { useContext, useEffect } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import { CardTabSkeleton } from '../AOSkeleton';

import AgencyToolkitReportsStatements from './AgencyToolkitReportsStatements';
import AgencyToolkitSupplyOrdering from './AgencyToolkitSupplyOrdering';
import AgencyToolkitMarketingMaterials from './AgencyToolkitMarketingMaterials';
import AgencyToolkitTrainingMaterials from './AgencyToolkitTrainingMaterials';
import AgencyToolkitAgencyAutomation from './AgencyToolkitAgencyAutomation';
import AgencyToolkitRegionalContacts from './AgencyToolkitRegionalContacts';

import { StyledAgencyToolkit } from './AgencyToolkit.styled';

import useScreenDefinition from '../../hooks/useScreenDefinition';

import { handleRoute } from '../CommonUtilityServices/HandleRouteService';
import { handleTabChange } from '../CommonUtilityServices/EventHandlerService';
import AppConfigContext from '../../contexts/appConfig.context';

const PanelComponents = {
  AgencyToolkitSupplyOrdering,
  AgencyToolkitTrainingMaterials,
  AgencyToolkitMarketingMaterials,
  AgencyToolkitReportsStatements,
  AgencyToolkitAgencyAutomation,
  AgencyToolkitRegionalContacts,
};

const AgencyToolkit = () => {
  // Screen defs
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`AgencyToolkitHome`);
  const { legacyUrl } = useContext(AppConfigContext);

  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    const url = window.location.href;
    const anchor = 'training';

    if (url.includes(anchor)) {
      setTabValue(3); // Change the tab value to 3 for Training Materials if the URL has the 'training' anchor
    }
  }, []);

  const plState = {
    tabValue,
  };

  const callBackFunctions = {
    handleRoute,
    handleTabChange: (e, newValue) => handleTabChange(e, newValue, setTabValue),
    handleRouteToLegacyOnClick: (event) => {
      const route = event.currentTarget.getAttribute('data-route');
      window.open(`${legacyUrl}${route}`, '_blank');
    },
  };

  if (loadingSd) return <CardTabSkeleton />;

  return (
    <StyledAgencyToolkit>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          componentMap={PanelComponents}
          functionMap={callBackFunctions}
          stateMap={plState}
        />
      )}
    </StyledAgencyToolkit>
  );
};

export default AgencyToolkit;
