import { Box, styled } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { rem } from 'polished';

const StyledQuickReferenceCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const StyledQuickReferenceCardTitle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 500,
  textAlign: 'center',
  color: theme.palette.secondary.main,
  fontSize: rem(14),
}));

export { StyledQuickReferenceCard, StyledQuickReferenceCardTitle };
