import React, { useState } from 'react';

import useFetchSelectMenuItems from 'hooks/useFetchSelectMenuItems';
import {
  handleOnChange,
  handleAOAutocompleteOnChange,
  filterOptions,
  getOptionLabel,
  handleAOAutocompleteOnInputChange,
} from 'components/CommonUtilityServices/EventHandlerService';
import { handleCreateOnClick } from './ABSReport.api';
import { GET_ABS_REPORT_AGENTS, GET_ABS_REPORT_DATES } from 'constants/api';
import { getMonthName } from 'helpers';
import { Autocomplete, Box, Button, Card, CardContent, CardHeader, Select, TextField, Typography, ViewContainer } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import { ROUTE_REDESIGN_REPORTS } from 'constants/routes';

const ABSReport = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [pdfLoading, setLoadingPdf] = useState(false);

  const [periodOptions, setPeriodOptions] = useState([]);
  const [permAgenciesOptions, setPermAgenciesOptions] = useState([]);

  const [formData, setFormData] = useState({ period: '', periodOptions: '', agencyCode: '' });
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({ agencyCode: { value: '', invalidText: '' } });

  useFetchSelectMenuItems(GET_ABS_REPORT_AGENTS, (response) => {
    if (response.data.length) {
      const agencies = response.data;
      setPermAgenciesOptions(
        agencies.map((agency) => ({
          value: agency.agencyDisplay,
          code: agency.agencyValue,
        })),
      );
    }
  });

  useFetchSelectMenuItems(GET_ABS_REPORT_DATES, (response) => {
    if (response.data.length) {
      const periods = response.data;

      setPeriodOptions(
        periods.map((period) => {
          const date = new Date(period.reportDate);
          const dateLabel = `${getMonthName(date.getMonth() + 1)} ${date.getFullYear()} `;
          return { label: dateLabel, value: period.reportDate };
        }),
      );
    }
  });

  const handleOnChange_PROXY = (e) => handleOnChange(e, formData, setFormData);
  const handleCreateOnClick_PROXY = () => handleCreateOnClick(formData, setLoadingPdf, setErrorMessage);
  const handleOnChangeAgencyCode = (e, value) => handleAOAutocompleteOnChange('agencyCode', e, value?.code, formData, setFormData);
  const handleOnInputChangeAgencyCode = (e, value) =>
    handleAOAutocompleteOnInputChange('agencyCode', e, value, autocompleteInputValues, setAutocompleteInputValues, {}, () => {});

  return (
    <ViewContainer returnRoute={{ route: ROUTE_REDESIGN_REPORTS, text: 'Reports' }}>
      <Feature allowed="Access_AgencyBilledStatements_Report">
        <Card>
          <CardHeader title="Agency Billed Statements Report" />
          <CardContent>
            {errorMessage && (
              <Box mb={2}>
                <Typography color="error">{errorMessage}</Typography>
              </Box>
            )}
            <Box mb={2}>
              <Autocomplete
                id="abs-report-agent-code"
                filterOptions={filterOptions}
                getOptionLabel={getOptionLabel}
                onChange={handleOnChangeAgencyCode}
                onInputChange={handleOnInputChangeAgencyCode}
                style={{ width: 400 }}
                disableClearable
                value={formData.agencyCode}
                options={permAgenciesOptions}
                freeSolo={false}
                renderInput={(params) => (
                  <TextField {...params} id="agent-code-label" label="Agent Code" placeholder="Select or start typing" variant="outlined" />
                )}
              />
            </Box>
            <Box mb={2}>
              <Select
                onChange={handleOnChange_PROXY}
                value={formData.period}
                menuItems={periodOptions || []}
                id="abs-report-statement-date"
                label="Statement Date"
                name="period"
              />
            </Box>
            <Box>
              <Button
                onClick={handleCreateOnClick_PROXY}
                loading={pdfLoading}
                disabled={pdfLoading || !formData.agencyCode || !formData.period}
                id="abs-report-generate-report-button"
                color="primary"
                variant="contained">
                Generate Report
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Feature>
    </ViewContainer>
  );
};

export default ABSReport;
