import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GET_REPORTS_URL } from '../../../constants/api';

import { handlePdfDownload } from '../../CommonUtilityServices/DownloadPdfService';

const BillingServicesReportsPaymentsMadeRenderCell = (
  { row: { payAccountNumber, payIndex, payPaymentAmount } },
  setDownloadingPdfError,
) => {
  const [loadingPdf, setLoadingPdf] = useState(false);

  const handleClickPdfDownload = () => {
    const request_body = {
      Area: 'billing-payment',
      DetailedContext: [
        {
          name: 'accountNumber',
          value: payAccountNumber,
        },
        {
          name: 'payIndex',
          value: payIndex,
        },
      ],
      TypeOfRedirect: 'Generate-PDF',
    };

    handlePdfDownload(
      'POST',
      request_body,
      GET_REPORTS_URL,
      setLoadingPdf,
      setDownloadingPdfError,
      setDownloadingPdfError,
      true,
    );
  };

  if (loadingPdf)
    return (
      <Box py={2}>
        <CircularProgress color="inherit" size={20} />
      </Box>
    );

  return (
    <Link
      color="secondary"
      style={{ 'font-weight': '700' }}
      onClick={() => {
        handleClickPdfDownload();
      }}>
      {payPaymentAmount}
    </Link>
  );
};

BillingServicesReportsPaymentsMadeRenderCell.propTypes = {
  row: PropTypes.shape({
    payAccountNumber: PropTypes.string,
    payIndex: PropTypes.string,
    payPaymentAmount: PropTypes.string,
  }),
  id: PropTypes.string,
  downloadingErrorControlFunc: PropTypes.func,
};

export default BillingServicesReportsPaymentsMadeRenderCell;
