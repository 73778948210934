import React from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import BillingServicesSkeleton from './BillingServicesSkeleton';

import BillingServicesForms from './BillingServicesForms';
import BillingServicesGuideFaqs from './GuideFaqs/BillingServicesGuideFaqs';
import BillingServicesPaymentManagement from './PaymentManagement/BillingServicesPaymentManagement';
import BillingServicesReports from './BillingReports/BillingServicesReports';
import BillingServicesRequests from './BillingServicesRequests';
import BillingServicesSearch from './BillingServicesSearch';

import useScreenDefinition from '../../hooks/useScreenDefinition';

import { handleRoute } from '../CommonUtilityServices/HandleRouteService';
import { handleTabChange } from '../CommonUtilityServices/EventHandlerService';

const PanelComponents = {
  BillingServicesForms,
  BillingServicesGuideFaqs,
  BillingServicesPaymentManagement,
  BillingServicesReports,
  BillingServicesRequests,
  BillingServicesSearch,
};

const BillingServices = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`BillingServicesHome`);

  const [tabValue, setTabValue] = React.useState(0);

  const localStateMap = {
    tabValue,
  };

  const callBackFunctions = {
    handleRoute,
    handleTabChange: (e, newValue) => handleTabChange(e, newValue, setTabValue),
  };

  if (loadingSd) return <BillingServicesSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={PanelComponents}
        />
      )}
    </>
  );
};

export default BillingServices;
