import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import { Box, CardContent, Icon, Typography } from 'components/AORedesign';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    width: '270px',
    height: '250px',
    padding: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexShrink: 0,
    boxSizing: 'border-box',
  },
  iconContainer: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    borderRadius: '100px',
    boxSizing: 'border-box',
    height: '40px',
    width: '40px',
  },
  icon: {
    filter: 'brightness(0) invert(1)',
  },
}));

const WorkflowCard = ({ icon, title, description, onClick }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined" onClick={onClick}>
      <CardContent className={classes.content}>
        <Box className={classes.iconContainer}>
          <Box className={classes.icon}>
            <Icon iconName={icon} />
          </Box>
        </Box>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body2" color="textSecondary" component="div">
          <Box textAlign="center">{description}</Box>
        </Typography>
      </CardContent>
    </Card>
  );
};

WorkflowCard.propTypes = {
  icon: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default WorkflowCard;
