import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, CardActionArea, MuiCard, Typography } from 'components/AORedesign';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    position: 'relative',
  },
  count: {
    fontSize: `${theme.spacing(6)}px`,
    marginBottom: '12px',
    fontWeight: 500,
    lineHeight: `${theme.spacing(5.25)}px`,
    letterSpacing: '-0.56px',
  },
  icon: {
    backgroundColor: theme.palette.primary.dark,
    position: 'absolute',
    top: -11,
    right: -11,
    padding: '25px 24px 20px 21px',
    borderRadius: '99px',
  },
  title: {
    textTransform: 'capitalize',
  },
  subtitle: {
    textTransform: 'uppercase',
  },
}));

const DashboardActionCard = ({ count, icon, onClick, subtitle, title }) => {
  const classes = useStyles();
  return (
    <MuiCard className={classes.root} onClick={onClick}>
      <CardActionArea>
        <SvgIcon fontSize="large" className={classes.icon}>
          {icon}
        </SvgIcon>
        <Box py={2.5} px={2}>
          <Typography className={classes.count}>{count ?? '--'}</Typography>
          <Typography className={classes.title} variant="h5">
            {title}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle2">
            {subtitle}
          </Typography>
        </Box>
      </CardActionArea>
    </MuiCard>
  );
};

DashboardActionCard.propTypes = {
  count: PropTypes.number,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default DashboardActionCard;
