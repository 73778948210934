import AccountAdminContext from 'contexts/accountAdmin.context';
import React, { useState } from 'react';
import ContactUs from './ContactUs';
import MsaServices from './MsaServices';
import ReferenceMaterial from './ReferenceMaterial';
import UserManagement from './UserManagement';

import { makeStyles } from '@material-ui/core';
import { Box, Card, Col, Row, ViewContainer } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  flexBox: {
    flexGrow: 1,
  },
  card: {
    height: '100%',
  },
}));

const AccountAdmin = () => {
  const classes = useStyles();
  const [contactUsData, setContactUsData] = useState(null);
  const [contactUsRegions, setContactUsRegions] = useState(null);
  const [msaServicesData, setMsaServicesData] = useState(null);
  const accountAdminHasData = () => {
    return !!contactUsData && !!contactUsRegions && !!msaServicesData;
  };

  return (
    <Feature allowed={featureFlags.ACCESS_USER_IS_AGENT}>
      <AccountAdminContext.Provider
        value={{
          contactUsData,
          setContactUsData,
          contactUsRegions,
          setContactUsRegions,
          msaServicesData,
          setMsaServicesData,
          accountAdminHasData,
        }}>
        <ViewContainer title="Agency Info" hidePolicyViewChange hideStartQuote>
          <Row spacing={3}>
            <Col xs={6}>
              <Box className={classes.column}>
                <Feature allowed={featureFlags.ACCESS_AGENCY_SECURITY_USER_ADMIN}>
                  <Box mb={3}>
                    <Card id="ao-account-admin-user-management">
                      <UserManagement />
                    </Card>
                  </Box>
                </Feature>
                <Box className={classes.flexBox}>
                  <Card id="ao-account-admin-contact-us" className={classes.card}>
                    <ContactUs />
                  </Card>
                </Box>
              </Box>
            </Col>
            <Col xs={6}>
              <Box className={classes.column}>
                <Box mb={3} className={classes.flexBox}>
                  <Card id="ao-account-admin-msa-services" className={classes.card}>
                    <MsaServices />
                  </Card>
                </Box>
                <Box className={classes.flexBox}>
                  <Card id="ao-account-admin-reference-material" className={classes.card}>
                    <ReferenceMaterial />
                  </Card>
                </Box>
              </Box>
            </Col>
          </Row>
        </ViewContainer>
      </AccountAdminContext.Provider>
    </Feature>
  );
};

export default AccountAdmin;
