import { makeStyles } from '@material-ui/core';
import useAxios from 'axios-hooks';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ROUTE_R_ANNOUNCEMENTS } from 'constants/routes';
import { ANNOUNCEMENT_TYPES } from 'constants/static';
import moment from 'moment';
import { selectAnnouncements } from 'redux/app-store';
import { GET_NEWS, NEWS_ARTICLES } from '../../constants/api';
import { Alert, Box, Button, CardActions, Divider, Link, MuiCard, Typography } from '../AORedesign';
import { handleFileDownload } from '../CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';
import NewsArticleModal from '../NewsArticles/NewsArticleModal';
import NewsArticlesSkeleton from '../NewsArticles/NewsArticlesSkeleton';

const useStyles = makeStyles(() => ({
  cardActions: {
    justifyContent: 'center',
  },
}));

const DashboardAnnouncements = () => {
  const { announcements: data, loading, error } = useSelector((state) => selectAnnouncements(state, [ANNOUNCEMENT_TYPES.ANNOUNCEMENT_HOME_PAGE]));

  const [open, setOpen] = useState(false);
  const [, setLoadingFile] = useState(false);
  const [loadingFileError, setLoadingFileError] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const [{ data: dataNewsReview, loading: loadingNewsReview, error: errorNewsReview }, executeNewsReview] = useAxios();

  const handleOpen = (id, ext) => {
    const trimmedExt = ext.trim();
    if (trimmedExt.length === 0) {
      setOpen(true);
      executeNewsReview({
        url: `${GET_NEWS}${NEWS_ARTICLES}/${id}`,
      });
    } else {
      const fileURL = `${NEWS_ARTICLES}/${id}`;
      if (trimmedExt === 'pdf') {
        handlePdfDownload('GET', null, fileURL, setLoadingFile, setLoadingFileError, setLoadingFileError, 'newWindow', `${GET_NEWS}`);
      } else {
        handleFileDownload(fileURL, setLoadingFile, setLoadingFileError, setLoadingFileError, 'newWindow', 'GET', undefined, GET_NEWS);
      }
    }
  };

  // Per aols-ui code, the only accepted file extensions are pdf and doc
  const getLinkEndIcon = (ext) => {
    const trimmedExt = ext.trim();
    switch (trimmedExt) {
      case 'pdf':
        return 'pdf';
      case 'doc':
        return 'document';
      default:
        return;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNews = () => {
    const getDateFormattedMMDDYYY = (announcement) => moment(announcement?.StartDate).format('MM/DD/YYYY');

    const thirtyDaysAgo = moment().subtract(30, 'days').startOf('day');
    const dashAnnouncements = data?.filter((announcement) => {
      const announcementDate = getDateFormattedMMDDYYY(announcement);
      const showAnnouncement = moment(announcementDate).isAfter(thirtyDaysAgo);
      return showAnnouncement;
    });

    if (loading) {
      return <NewsArticlesSkeleton />;
    } else if (dashAnnouncements?.length > 0) {
      return dashAnnouncements?.map((announcement, index) => {
        const announcementDate = getDateFormattedMMDDYYY(announcement);
        return (
          // Limit to 5 announcements
          index < 5 && (
            <Box key={`${announcement.seqId}-${announcement.StartDate}-${index}`} mb={1}>
              <Typography component="p" variant="overline">
                {announcementDate}
              </Typography>
              <Link disabled={true} onClick={() => handleOpen(announcement.seqId, announcement.ext)} endIcon={getLinkEndIcon(announcement.ext)}>
                {announcement.title}
              </Link>
            </Box>
          )
        );
      });
    } else if (dashAnnouncements?.length === 0 && error === null) {
      return 'Check back soon for news on Main Street America.';
    }
  };

  const handleViewAllAnnouncementsOnClick = () => history.push(ROUTE_R_ANNOUNCEMENTS);

  return (
    <>
      <MuiCard>
        {(error || loadingFileError) && (
          <Box px={2} mb={-2}>
            <Alert severity="error">{error ?? loadingFileError}</Alert>
          </Box>
        )}
        <Box p={3}>
          <Box mb={2}>
            <Typography variant="h6">Agents Only Announcements</Typography>
          </Box>
          {renderNews()}
        </Box>
        <Divider />
        <CardActions className={classes.cardActions}>
          <Button color="secondary" variant="text" onClick={handleViewAllAnnouncementsOnClick}>
            View All Announcements
          </Button>
        </CardActions>
      </MuiCard>
      <NewsArticleModal open={open} handleClose={handleClose} loading={loadingNewsReview} error={errorNewsReview} data={dataNewsReview} />
    </>
  );
};

export default DashboardAnnouncements;
