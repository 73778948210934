import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { Snackbar } from 'components/AORedesign';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAppSnackbarProps } from 'redux/app-store';

const AppSnackbar = () => {
  const appSnackbarProps = useSelector((state) => state.app.appSnackbarProps);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setAppSnackbarProps(null));
  };

  return (
    <Snackbar
      open={appSnackbarProps?.message?.length}
      onClose={handleClose}
      message={!appSnackbarProps?.severity?.length && appSnackbarProps?.message}
      action={
        !appSnackbarProps?.severity?.length && (
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      }>
      {appSnackbarProps?.severity?.length && (
        <Alert onClose={handleClose} variant="filled" severity={appSnackbarProps?.severity}>
          {appSnackbarProps?.message}
        </Alert>
      )}
    </Snackbar>
  );
};

export default AppSnackbar;
