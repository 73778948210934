import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box, Card, CardContent, Paper, Typography, ViewContainer } from '../AORedesign';

const BillingDetailsSkeleton = ({ items }) => {
  const [skeletonItems] = useState(Array.from(Array(items).keys()));
  return (
    <ViewContainer>
      <Card>
        <Box pl={2}>
          <Typography variant="h4">
            <Skeleton width={200} />
          </Typography>
        </Box>
        <CardContent>
          {skeletonItems.map(() => (
            <>
              <Paper key={cuid()}>
                <Skeleton
                  width={100}
                  style={{ marginRight: `24px`, padding: `6px 8px` }}
                  animation="wave"
                />
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={200} />
              </Paper>
              <Box mb={3} />
            </>
          ))}
        </CardContent>
      </Card>
    </ViewContainer>
  );
};

BillingDetailsSkeleton.propTypes = {
  items: PropTypes.number,
};

export default BillingDetailsSkeleton;
