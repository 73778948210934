import React from 'react';
import cuid from 'cuid';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';

import TableRow from '@material-ui/core/TableRow';
const DetailsDataTableRow = ({ data }) => {
  return (
    <TableRow>
      <TableCell />
      {data.map((el) => (
        <TableCell key={cuid()}>{el}</TableCell>
      ))}
    </TableRow>
  );
};
DetailsDataTableRow.propTypes = {
  data: PropTypes.object,
};

export default DetailsDataTableRow;
