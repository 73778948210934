import React from 'react';

import { Box, Card, Link, Typography, WorkflowViewContainer } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import ViewAgencyInfo from 'components/UserAdmin/views/ViewAgencyInfo';
import featureFlags from 'constants/featureFlags';

const UserAdminViewAgencyInfoRedesign = () => {
  const TriggerButton = (props) => <Link {...props}>View Agency Information</Link>;

  return (
    <Feature allowed={featureFlags.ACCESS_USER_ADMIN}>
      <WorkflowViewContainer appBarTitle={'View Agency Information'} TriggerButton={TriggerButton} block>
        <Box mx={9} my={5}>
          <Card>
            <Box mb={3}>
              <Typography variant="h5">{'Search For Agency'}</Typography>
            </Box>
            <ViewAgencyInfo />
          </Card>
        </Box>
      </WorkflowViewContainer>
    </Feature>
  );
};

export default UserAdminViewAgencyInfoRedesign;
