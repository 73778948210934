import React from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../../ScreenSmith';
import BillingServicesPaymentManagementSkeleton from './BillingServicesPaymentManagementSkeleton';

import useScreenDefinition from '../../../hooks/useScreenDefinition';

import { AdjustPayment } from './AdjustPayment/index';
import { PostPayment } from './PostPayment/index';
import { ReportInsufficientFunds } from './ReportInsufficientFunds/index';
import { BillingServicesReportsPaymentBalance } from './PaymentBalance/index';
import { PaymentHistory } from './PaymentHistory';
import { ManageCurrentPayment } from './ManageCurrentPayment';

import {
  handleActivePanelChange,
  handleClickGoToPanelById,
} from './BillingServicesPaymentManagement.api';

const BillingServicesPaymentManagement = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(
    `BillingServicesPaymentManagementPanel`,
  );
  const [activePanel, setActivePanel] = React.useState('0');

  const localStateMap = {
    paymentManagementActive: activePanel === '0',
    adjustPaymentActive: activePanel === '1',
    postPaymentActive: activePanel === '2',
    reportInsufficientFundsActive: activePanel === '3',
    reportPaymentBalanceActive: activePanel === '4',
    reportPaymentHistoryActive: activePanel === '5',
    manageCurrentPayment: activePanel === '6',

    resetFlow: () => {
      setActivePanel('0'); // define state var that is fn to use as a prop in children
    },
    handleClickGoToAdjustPayment: () => handleClickGoToPanelById(setActivePanel, 1),
    handleClickGoToPostPayment: () => handleClickGoToPanelById(setActivePanel, 2),
    handleClickGoToNSFPayment: () => handleClickGoToPanelById(setActivePanel, 3),
    handleClickGoToPaymentBalance: () => handleClickGoToPanelById(setActivePanel, 4),
    handleClickGoToPaymentHistory: () => handleClickGoToPanelById(setActivePanel, 5),
    handleClickGoToManageCurrentPayment: () => handleClickGoToPanelById(setActivePanel, 6),
  };

  const callBackFunctions = {
    handleActivePanelChange: (e) => handleActivePanelChange(e, setActivePanel),
  };

  const localComponents = {
    AdjustPayment,
    PostPayment,
    ReportInsufficientFunds,
    BillingServicesReportsPaymentBalance,
    PaymentHistory,
    ManageCurrentPayment,
  };

  if (loadingSd) return <BillingServicesPaymentManagementSkeleton />;

  return (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith
          definition={UiDefinitionsData}
          functionMap={callBackFunctions}
          stateMap={localStateMap}
          componentMap={localComponents}
        />
      )}
    </>
  );
};

export default BillingServicesPaymentManagement;
