import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { AOAlert } from '../AOAlert';
import { AOLinkButton } from '../AOLinkButton';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import { FullHeightMuiPaper, ViewContainer } from '../Layout';

import { Link } from '@material-ui/core';
import ACCORD_DWELLINGFIRE_FORM from '../PLUmbrella/DwellingFireTools/AcordDwellingFireForm.pdf';
import PL_DWELLINGFIRE_RATING_TOOL from '../PLUmbrella/DwellingFireTools/PL_DwellingFire_Rating_Tool.xlsx';

const PLUmbrella = () => {
  const [redirectError, setRedirectError] = useState(null);

  const handlePlUmbrellaRedirect = () => {
    const requestBody = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'Umbrella Quick Quote',
      DetailedContext: [],
    };

    setRedirectError(null);
    redirectUrlFunction(requestBody, 'POST', null, setRedirectError, setRedirectError);
  };

  return (
    <ViewContainer title="Personal Umbrella Tools and Information">
      {redirectError && <AOAlert severity={'error'}>{redirectError}</AOAlert>}
      <Paper>
        <Box pt={3} px={3}>
          <Paper variant="outlined" square>
            <Box display={'flex'} justifyContent={'center'} py={3}>
              <AOLinkButton route="/resources/underwriting">Underwriting Guidelines</AOLinkButton>
            </Box>
          </Paper>
        </Box>
        <Box p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <FullHeightMuiPaper variant="outlined" square>
                <Box p={3}>
                  <Typography variant="h5">Personal Umbrella Tools</Typography>
                  <Box p={1}></Box>
                  <Typography variant="h6">Regions: New England. Northeast, Southeast</Typography>
                  <Typography>
                    {/* This should have a 'campaign' icon as startAdornment, but the icon is only present in MUI 5, not 4. */}
                    <Box fontSize={12} color="text.secondary" mt={1.5}>
                      UMBRELLA RATING TOOL AND APPLICATION
                    </Box>
                  </Typography>
                  <Box mt={0.75}>
                    <AOLinkButton onClick={handlePlUmbrellaRedirect}>Try Our New Umbrella Quoting Experience</AOLinkButton>
                  </Box>
                  <Typography>
                    <Box fontSize={11}>Get a quick quote in under a minute</Box>
                  </Typography>
                </Box>
              </FullHeightMuiPaper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FullHeightMuiPaper variant="outlined" square>
                <Box p={3}>
                  <Typography variant="h5">Dwelling Fire Tools</Typography>
                  <Box p={1}></Box>
                  <Typography variant="h6">Target Profile and Rating Tool</Typography>
                  <Box mt={0.75}>
                    <AOLinkButton hrefNewTab={PL_DWELLINGFIRE_RATING_TOOL}>Personal Lines Dwelling Fire Rating Tool</AOLinkButton>
                    <Typography>
                      <Box fontSize={11}>A tool to assist you in providing a real-time premium estimate for your clients</Box>
                    </Typography>
                  </Box>
                  <Box mt={10}></Box>
                  <Typography variant="h6">Application</Typography>
                  <Box mt={0.75}>
                    <AOLinkButton hrefNewTab={ACCORD_DWELLINGFIRE_FORM}>ACCORD Application for Dwelling Fire Coverage</AOLinkButton>
                    <Typography>
                      <Box fontSize={11}>
                        Use this application when applying for Dwelling Fire coverage with Main Street America. Please submit your completed application and all
                        of the required documentation to{' '}
                        <Link color="secondary" component={Link} href="mailto:msaplprocessing@msagroup.com">
                          msaplprocessing@msagroup.com
                        </Link>{' '}
                        or fax to 877-282-3845.
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </FullHeightMuiPaper>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </ViewContainer>
  );
};

export default PLUmbrella;
