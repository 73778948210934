import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';

import { ScreenSmith } from '../ScreenSmith';
import ClaimsSkeleton from './ClaimsSkeleton';
import ClaimsReport from './ClaimsReport';
import ClaimsStatus from './ClaimsStatus';
import ClaimsSearch from './ClaimsSearch';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import { handleFileDownload } from '../CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from '../CommonUtilityServices/DownloadPdfService';
import { handleTabChange } from '../CommonUtilityServices/EventHandlerService';
import AppDataContext from '../../contexts/appData.context';

const PanelComponents = { ClaimsReport, ClaimsStatus, ClaimsSearch };

const Claims = () => {
  const { UiDefinitionsData, loadingSd } = useScreenDefinition(`ClaimsHome`);
  const { claimsInquiry, setClaimsInquiry } = useContext(AppDataContext);

  const [tabValue, setTabValue] = useState(0);
  const [loadingFile, setLoadingFile] = useState(false);
  const [isDownloadingFileErrorVisible, setIsDownloadingFileErrorVisible] = useState(false);
  const [downloadingFileError, setDownloadingFileError] = useState(false);

  useEffect(() => {
    if (claimsInquiry) {
      setTabValue(2);
    }
  }, [claimsInquiry]);

  useEffect(() => {
    const url = window.location.href;
    const anchor = 'open-claims-status';
    if (url.includes(anchor)) {
      setTabValue(1); // Change the tab value to open claims status from dashboard
    }
  }, []);

  const localStateMap = {
    tabValue,
    loadingFile,
    isDownloadingFileErrorVisible,
    downloadingFileError,
    claimsInquiry,
  };

  const callBackFunctions = {
    handleTabChange: (e, newValue) => handleTabChange(e, newValue, setTabValue),
    handleClickDownloadFile: (e) => {
      const fileUrl = e.currentTarget.value;

      handleFileDownload(fileUrl, setLoadingFile, setDownloadingFileError, setIsDownloadingFileErrorVisible);
    },
    handleClickDownloadPdf: (e) => {
      const pdfUrl = e.currentTarget.value;

      handlePdfDownload('GET', null, pdfUrl, setLoadingFile, setDownloadingFileError, setIsDownloadingFileErrorVisible, 'newWindow');
    },
    setClaimsInquiry,
  };

  return loadingSd ? (
    <ClaimsSkeleton />
  ) : (
    <>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith definition={UiDefinitionsData} functionMap={callBackFunctions} stateMap={localStateMap} componentMap={PanelComponents} />
      )}
    </>
  );
};

export default Claims;
