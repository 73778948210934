import { rem } from 'polished';

import { Box, styled, CircularProgress } from '@material-ui/core';
import { StyledSvgLogoDestination } from '../Header/Header.styled';

const StyledAOCircularProgress = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    padding: `${rem(16)}`,
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  // height: `${rem(500)}`,
  position: 'fixed',
  top: '0px',
  right: '0',
  left: '0',
  bottom: '0',
  backgroundColor: theme.palette.background.default,
  zIndex: theme.zIndex.drawer + 1,
}));

const StyledAOCircularProgressLogoDestination = styled(StyledSvgLogoDestination)(() => ({
  marginBottom: `${rem(80)}`,
}));

const StyledAOCircularProgressLoader = styled(CircularProgress)(() => ({
  marginTop: `${rem(20)}`,
}));

export {
  StyledAOCircularProgress,
  StyledAOCircularProgressLogoDestination,
  StyledAOCircularProgressLoader,
};
