import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import useAxios from 'axios-hooks';
import _ from 'lodash';
import moment from 'moment';

import { ScreenSmith } from '../ScreenSmith';
import useScreenDefinition from '../../hooks/useScreenDefinition';
import { QuoteNewBusinessSkeleton } from '../AOSkeleton';

import {
  GET_PL_NEW_QUOTE_NEW_BUSINESS_PRODUCT_LINES_URL,
  GET_PL_POLICY_FORMS_URL,
  GET_QUOTE_NEW_BUSINESS_STATUS,
  GET_RISK_STATES_URL,
  GET_PERSONAL_LINES,
} from '../../constants/api';

import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';

const PLSQuoteNewBusiness = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('PLSQuoteNewBusinessPanel');

  const [selectedLob, setSelectedLob] = useState('');
  const [productLines, setProductLines] = useState([]);
  const [riskStates, setRiskStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedNewQuoteEffectiveDate, setSelectedNewQuoteEffectiveDate] = useState(
    moment().format('MM/DD/yyyy'),
  );
  const [policyForms, setPolicyForms] = useState();
  const [quoteNewBusinessStatus, setQuoteNewBusinessStatus] = useState({});
  const [selectedPolicyForm, setSelectedPolicyForm] = useState('');
  const [isPolicyFormsVisible, setIsPolicyFormsVisible] = useState(false);
  const [isNewQuoteButtonVisible, setIsNewQuoteButtonVisible] = useState(false);
  const [isNewBusinessButtonVisible, setIsNewBusinessButtonVisible] = useState(false);
  const [effectiveDateOrLater, setEffectiveDateOrLater] = useState(null);
  const [effectiveDateOrLaterIsVisible, setEffectiveDateOrLaterIsVisible] = useState(false);
  const [isPlsQuoteNewBusinessRedirectErrorVisible, setIsPlsQuoteNewBusinessRedirectErrorVisible] =
    useState(false);
  const [plsQuoteNewBusinessRedirectError, setPlsQuoteNewBusinessRedirectError] = useState(false);

  const handleClickNewQuote = () => {
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'New Quote',
      DetailedContext: [
        {
          name: 'businessType',
          value: 'PL',
        },
        {
          name: 'productLine',
          value: selectedLob,
        },
        {
          name: 'state',
          value: selectedState,
        },
        {
          name: 'effectiveDate',
          value: selectedNewQuoteEffectiveDate,
        },
        {
          name: 'policyFormProductLine',
          value: selectedPolicyForm,
        },
      ],
    };

    return redirect(request_body);
  };

  const handleClickNewBusiness = () => {
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'Quote New Business',
      DetailedContext: [
        {
          name: 'businessType',
          value: 'PL',
        },
        {
          name: 'productLine',
          value: selectedLob,
        },
        {
          name: 'state',
          value: selectedState,
        },
        {
          name: 'effectiveDate',
          value: selectedNewQuoteEffectiveDate,
        },
      ],
    };
    return redirect(request_body);
  };

  const redirect = (request_body) => {
    return redirectUrlFunction(
      request_body,
      'POST',
      null,
      setPlsQuoteNewBusinessRedirectError,
      setIsPlsQuoteNewBusinessRedirectErrorVisible,
    );
  };

  // --- Event Handlers for Quote/New Business ---
  const productLinesUrl = `${GET_PL_NEW_QUOTE_NEW_BUSINESS_PRODUCT_LINES_URL}`;

  const [{ data: productLinesData, loading: productLinesLoading }] = useAxios({
    url: `${productLinesUrl}`,
  });

  const getProductLines = () => {
    return productLines;
  };

  const handleChangeNewQuoteLineOfBusiness = useCallback(async (event) => {
    setSelectedLob(event.target.value);
    setIsPolicyFormsVisible(false);
    setSelectedNewQuoteEffectiveDate(moment().format('MM/DD/yyyy'));
    setSelectedState('');
    setSelectedPolicyForm('');
    setIsNewQuoteButtonVisible(false);
    setIsNewBusinessButtonVisible(false);
    setEffectiveDateOrLaterIsVisible(false);
    setIsPlsQuoteNewBusinessRedirectErrorVisible(false);
    const riskMenuItems = await getRiskStatemenuItems(event);
    setRiskStates(riskMenuItems);
    setIsPlsQuoteNewBusinessRedirectErrorVisible(false);
  });

  const handleChangeRiskState = useCallback(async (event) => {
    setEffectiveDateOrLaterIsVisible(false);
    setSelectedPolicyForm('');
    setIsPolicyFormsVisible(false);
    setIsNewQuoteButtonVisible(false);
    setIsNewBusinessButtonVisible(false);
    getQuoteNewBusinessStatus(selectedNewQuoteEffectiveDate, event.target.value);
    setSelectedState(event.target.value);
    setIsPlsQuoteNewBusinessRedirectErrorVisible(false);
  });

  const getQuoteNewBusinessStatus = async (effectiveDate, riskState) => {
    try {
      const quoteNewBusinessStatusData = await getQuoteNewBusinesStatusData(
        effectiveDate,
        riskState,
      );
      setQuoteNewBusinessStatus(quoteNewBusinessStatusData);
      if (!_.isNil(quoteNewBusinessStatusData.data.message)) {
        setEffectiveDateOrLaterIsVisible(true);
        setEffectiveDateOrLater(quoteNewBusinessStatusData.data.message);
      } else {
        setEffectiveDateOrLaterIsVisible(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeNewQuoteNewBusinessEffectiveDate = (date, value) => {
    setSelectedPolicyForm('');
    setIsNewQuoteButtonVisible(false);
    setIsNewBusinessButtonVisible(false);
    setEffectiveDateOrLaterIsVisible(false);
    setSelectedNewQuoteEffectiveDate(value);
    setIsPlsQuoteNewBusinessRedirectErrorVisible(false);
    if (selectedState.trim() !== '') {
      getQuoteNewBusinessStatus(value, selectedState);
    }
  };

  const handleChangePolicyForm = useCallback(async (event) => {
    setSelectedPolicyForm(event.target.value);
    renderQuoteNewBusinessButtons();
  });

  const renderQuoteNewBusinessButtons = () => {
    if (quoteNewBusinessStatus.data.flgReadyForBusiness) {
      if (quoteNewBusinessStatus.data.flgQuoteOnly) {
        setIsNewQuoteButtonVisible(true);
        setIsNewBusinessButtonVisible(false);
      } else {
        setIsNewBusinessButtonVisible(true);
        setIsNewQuoteButtonVisible(true);
      }
    }
  };

  const getQuoteNewBusinesStatusData = async (effectiveDate, selectedRiskStateItem) => {
    const quoteNewBusinessStatusUrl = `${GET_QUOTE_NEW_BUSINESS_STATUS}${GET_PERSONAL_LINES}?productLine=${selectedLob}&effectiveDate=${effectiveDate}&riskState=${selectedRiskStateItem}`;
    const quoteNewBusinessStatus = await axios.get(`${quoteNewBusinessStatusUrl}`);
    return quoteNewBusinessStatus;
  };

  const getRiskStatemenuItems = async (event) => {
    const riskStatesUrl = `${GET_RISK_STATES_URL}/${event.target.value}${GET_PERSONAL_LINES}`;
    const states = await axios.get(`${riskStatesUrl}`);
    const riskStateMenuItems = states.data.map((el) => {
      return {
        code: el.stateCode,
        value: el.stateName,
      };
    });

    return riskStateMenuItems;
  };

  const getPolicyFormMenuItems = async () => {
    const policyFormsUrl = `${GET_PL_POLICY_FORMS_URL}Homeowner${GET_PERSONAL_LINES}/${selectedState}`;
    const policyFormsData = await axios.get(`${policyFormsUrl}`);
    const policyFormsMenuItems = policyFormsData.data.map((el) => {
      return {
        code: el.productLineCode,
        value: el.productLineCode + ' ' + el.policyForm,
      };
    });
    return policyFormsMenuItems;
  };

  const plState = {
    effectiveDateOrLater,
    effectiveDateOrLaterIsVisible,
    isNewBusinessButtonVisible,
    isNewQuoteButtonVisible,
    isPolicyFormsVisible,
    policyForms,
    productLines,
    riskStates,
    selectedLob,
    selectedNewQuoteEffectiveDate,
    selectedPolicyForm,
    selectedState,
    isPlsQuoteNewBusinessRedirectErrorVisible,
    plsQuoteNewBusinessRedirectError,
  };

  const callBackFunctions = {
    getProductLines,
    handleChangeNewQuoteLineOfBusiness,
    handleChangeNewQuoteNewBusinessEffectiveDate,
    handleChangePolicyForm,
    handleChangeRiskState,
    handleClickNewBusiness,
    handleClickNewQuote,
  };

  useEffect(async () => {
    setIsPolicyFormsVisible(false);
    if (!_.isNil(quoteNewBusinessStatus.data) && !_.isNil(UiDefinitionsData)) {
      if (quoteNewBusinessStatus.data.serviceProviderCode === 'E1P' && selectedLob === 'HO') {
        setIsPolicyFormsVisible(true);
        const policyFormsMenuItems = await getPolicyFormMenuItems(event);
        setPolicyForms(policyFormsMenuItems);
      } else {
        renderQuoteNewBusinessButtons();
      }
    }
  }, [quoteNewBusinessStatus, UiDefinitionsData]);

  useEffect(() => {
    if (!productLinesLoading && !_.isNil(UiDefinitionsData)) {
      const productLinesMenuItems = productLinesData?.map((el) => {
        return {
          code: el.productLineCode,
          value: el.productLineName,
        };
      });
      setProductLines(productLinesMenuItems);
    }
  }, [productLinesLoading, UiDefinitionsData]);

  if (loadingSd) return <QuoteNewBusinessSkeleton />;

  return (
    !_.isNil(UiDefinitionsData) && (
      <ScreenSmith
        definition={UiDefinitionsData}
        functionMap={callBackFunctions}
        stateMap={plState}
      />
    )
  );
};

export default PLSQuoteNewBusiness;
