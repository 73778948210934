import { handlePdfDownload } from '../../CommonUtilityServices/DownloadPdfService';

const handleClickDownloadPdf = (e, setIsDownloadingPdfErrorVisible, setLoadingPdf, setDownloadingPdfError) => {
  setIsDownloadingPdfErrorVisible(false);
  const pdfUrl = e.currentTarget.getAttribute('data-pdf');
  handlePdfDownload(
    'GET',
    null,
    pdfUrl,
    setLoadingPdf,
    setDownloadingPdfError,
    setIsDownloadingPdfErrorVisible,
    'newWindow',
  );
};

export { handleClickDownloadPdf }