import axios from 'axios';
import moment from 'moment';

const AOC_API_HEALTH_CHECK_DETAILS_URL = `${process.env.REACT_APP_API_URL}/healthcheck`;
const AOC_API_HEALTH_CHECK_LIST_URL = `${process.env.REACT_APP_API_URL}/healthcheck/list`;
const AOC_API_HEALTH_CHECK_INITIATE_URL = `${process.env.REACT_APP_API_URL}/healthcheck/initiate`;

/**
 * Fetches list of run IDs from the API.
 */
export const getHealthCheckRunsList = async () => {
  // Make API call to fetch Run ID's
  try {
    const { data } = await axios.get(AOC_API_HEALTH_CHECK_LIST_URL);
    return data;
  } catch (error) {
    errorProcessing(error);
    throw error; // Rethrow the error
  }
};

/**
 *  Makes a call to backend API to retrieve details for a given run id
 * @param {*} runId the unique run identifier
 * @param {*} MockData
 * @returns Details for a specific Run ID
 */
export const getHealthCheckRunDetail = async (runId) => {
  try {
    const { data } = await axios.get(`${AOC_API_HEALTH_CHECK_DETAILS_URL}/${runId}`);
    return data;
  } catch (error) {
    errorProcessing(error);
    throw error;
  }
};

/**
 * Makes a call to backend API to initiate a new Health Check run
 * @param {boolean} MockData - Whether to use mock data
 * @returns {Promise<any>} - API response for initiation
 */
export const initiateHealthCheck = async () => {
  try {
    const runid = moment.utc().format('YYYYMMDDHHmmss') + '-O';
    console.log('runid in utc: ' + runid);
    const response = await axios.post(AOC_API_HEALTH_CHECK_INITIATE_URL, { runid });
    return response.data;
  } catch (error) {
    errorProcessing(error);
    throw error;
  }
};

const errorProcessing = (error) => {
  if (error.response) {
    // Server responded with an error status code
    console.error('Error initiating new Health Check Run: Server error response recieved, ', error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    console.error('Error initiating new Health Check Run - No response received from server');
  } else {
    // Something else happened while setting up the request
    console.error('Error initiating new Health Check Run:', error.message);
  }
};

export default { getHealthCheckRunsList, getHealthCheckRunDetail };
