import React from 'react';
import PropTypes from 'prop-types';
import MuiPaper from '@material-ui/core/Paper';

import { Box } from 'components/AORedesign';

const WorkflowContainer = ({ children }) => {
  return (
    <MuiPaper elevation={1} square>
      <Box p={3}>{children}</Box>
    </MuiPaper>
  );
};

WorkflowContainer.propTypes = {
  children: PropTypes.node,
};

export default WorkflowContainer;
