import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button, Card, Col, Row, Select, ViewContainer } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAppSnackbarProps } from 'redux/app-store';
import moment from 'moment';
import { getHealthCheckRunDetail, getHealthCheckRunsList, initiateHealthCheck } from 'services/integration/aoc-api-health-check-service';

/**
 * React component for displaying results of health check runs (Step Function/State Machine results that are written to Dynamodb).
 * The following functionality is provided
 * 1. Displays a list of run IDS on page load
 * 2. Allows the user to refresh the list of run ids
 * 3. When a Run ID is selected from the list, a 'Health Check Summary Table' is displayed for that run ID.
 *    A Run ID includes execution of the following step-functions: app-db connectivity, db-db connectivity and api-checks - each with same run number
 *    The Health Check Summary Table lists the step functions - in both us-west-2 and us-east-1 - and the status for each
 *    For example (Test Suite equates to a Step Function/State Machine)
 *    | Test Suite                                     | us-east-1 | us-west-2 |
 *    | Database Connectivity, Application Subnets     | passed    | passed    |
 *    | Database Connectivity, Database Subnets        | passed    | failed    |
 *    | API Connectivity, Application Subnets          | passed    | passed    |
 *
 * 4. Allows any status (Passed/Failed) field in the to be clicked, which then will display a new table Test Suite Summary that lists all the tests for that run and the status for each test
 *    For example it would list
 *    | Database          | subnet-a | subnet-b | subnet-c | subnet-d | subnet-e | subnet-f |
 *    | AgentsOnlyReports | Passed   | Passed   | Passed   | Passed   | Failed   | Passed   |
 *    | AIDB              | Passed   | Passed   | Passed   | Failed   | Passed   | Passed   |
 *
 * 5. The User can then click on any Status for details on specific tests run for that status and a Test Details table will display
 *    For example
 *    | Test Name            |  Status  |  Message                                                          |
 *    | Resolving host name  |  Passed  |  IP Address is: 10.176.96.160 for 'MSAPTCMSADEVDC2.msadev.local'  |
 *    | Connect to LDAP      |  Passed  |  Connected!                                                       |
 *    | Basic Health Check   |  Passed  |  One row returned as expected!                                    |
 *
 * 6. User is able to navigate backwards or forwards to prior tables
 * 7. User can inititate a new health check run - call is made to API which fires of the step functions with a new run number.
 */

const HealthChecks = () => {
  /**
   * State variables:
   * - runIds: Array of Run IDs retrieved from API
   * - selectedRunId: ID of the currently selected Run
   * - isLoading: Object to manage loading state
   * - runDetails: Details of the selected Test Suite Run
   * - testSuiteStatusDetails: Details of run status
   * - testSuiteStatusRegion: Region of Status clicked
   * - testSuiteStatusName: Test Suite Name of Status clicked
   * - specificTestDetails: Details of a specific test run
   * - specificTestDetailsAppSubnet: App Subnet of test suite status clicked
   * - specificTestDetailsTestName: Test Name of test suite status clicked
   * - visibleSection: Enum - if 0 the HealthcheckSummay table will display, if 1 the Regional Suite Sumary and if 2 the Test Details table
   */
  const [runIds, setRunIds] = useState([]);
  const [selectedRunId, setSelectedRunId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [latestRunId, setLatestRunId] = useState();
  const [runDetails, setRunDetails] = useState(null);
  const [testSuiteStatusDetails, setTestSuiteStatusDetails] = useState(null);
  const [testSuiteStatusRegion, setTestSuiteStatusRegion] = useState(null);
  const [testSuiteStatusName, setTestSuiteStatusName] = useState(null);
  const [specificTestDetails, setSpecificTestDetails] = useState(null);
  const [specificTestDetailsAppSubnet, setSpecificTestDetailsAppSubnet] = useState(null);
  const [specificTestDetailsTestName, setSpecificTestDetailsTestName] = useState(null);
  const SectionsEnum = {
    HEALTHCHECK_SUMMARY: 0,
    REGIONAL_SUITE_SUMMARY: 1,
    TEST_DETAILS: 2,
  };
  const [visibleSection, setVisibleSection] = useState(SectionsEnum.HEALTHCHECK_SUMMARY);
  const dispatch = useDispatch();

  // Get the timezone offset of the user's browser
  const timezoneOffset = moment().utcOffset();
  /**
   * Fires/runs on first page load/mount only
   */
  useEffect(() => {
    fetchRunIds();
  }, []);

  /**
   * Fires/runs if selectedRunId or runid change
   */
  useEffect(() => {
    /**
     * Select the first row in the runids list if none is selected. (First row is most recent, Runids sorted in descending date order. )
     * If a runid was selected, retrieve details for that runid.
     * Sets the HealthCheck Summary table to visible.
     */
    const selectBoxChangeUseEffect = async () => {
      if (!selectedRunId && runIds.length > 0) {
        setSelectedRunId(runIds[0]); // Default to first row in select if none selected
      } else if (selectedRunId) {
        await fetchRunDetails(selectedRunId);
      }
      setVisibleSection(SectionsEnum.HEALTHCHECK_SUMMARY); // set the HealthCheck Summary table to visible.
    };
    selectBoxChangeUseEffect();
  }, [selectedRunId, runIds]);

  const handleRefreshButtonClick = async () => {
    await fetchRunIds();
  };

  const handleNewHealthCheckButtonClick = async () => {
    try {
      const data = await initiateHealthCheck(true);
      if (data.success) {
        const localRunId = moment.utc(data.run_id, 'YYYYMMDDHHmmss').utcOffset(timezoneOffset).format('YYYY-MM-DD HH:mm:ss');

        dispatchSnackBarMessage('success', data.message + ' Run ID: ' + localRunId);
      } else {
        dispatchSnackBarMessage('error', data.error);
      }
    } catch (error) {
      dispatchSnackBarMessage('error', 'Error instantiating a new Health Check Run. See console log for details.');
    }
  };

  /**
   * Fetches list of run IDs from the API.
   */
  const fetchRunIds = async () => {
    setIsLoading(true);
    try {
      const data = await getHealthCheckRunsList();
      setRunIds(data);
      //If the most recent run id has changed and there was a run id selected from dropdown list, then set latest runid to the new runid and set selected runid to '' which will cause the
      //most recent run id to be selected.
      if (latestRunId === null || latestRunId === undefined || latestRunId !== data[0]) {
        setLatestRunId(data[0]);
        setSelectedRunId(''); //If a more current runid is retrieved (Only retrieved on refresh and page load), then forces select of latest row - selectBoxChangeUseEffect.
      }
    } catch (error) {
      dispatchSnackBarMessage('error', "Error retrieving list of Run ID's. See console log for details.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Handle runid select event.
   * Set selected Runid which will trigger selectBoxChangeUseEffect
   * @param {*} event
   */
  const handleRunIdChange = (event) => {
    setSelectedRunId(event.target.value);
  };

  /**
   * Fetches details of a specific Test Suite Run.
   * @param {string} runId - ID of the run
   */
  const fetchRunDetails = async (runId) => {
    try {
      setIsLoading(true);
      const details = await getHealthCheckRunDetail(runId, true);
      setRunDetails(details);
    } catch (error) {
      dispatchSnackBarMessage('error', 'Error retrieving details for run id: ' + runId + '. See console log for details.');
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Display message to user using snackbar
   * @param {*} severity success, warning, error etc.
   * @param {*} message
   */
  const dispatchSnackBarMessage = (severity, message) => {
    dispatch(
      setAppSnackbarProps({
        message: message,
        severity: severity,
      }),
    );
  };

  /**
   * Handles click event on Health Check Summary Table status cell.
   * @param {Object} data - Data related to run status
   */
  const handleHealthCheckStatusClick = (details, region, testSuiteName) => {
    setTestSuiteStatusDetails(details);
    setTestSuiteStatusRegion(region);
    setTestSuiteStatusName(testSuiteName);
    setVisibleSection(SectionsEnum.REGIONAL_SUITE_SUMMARY);
  };

  /**
   * Handles click event on Health Check Summary Table status cell.
   * @param {Object} data - Data related to run status
   */
  const handleTestSuiteStatusClick = (details, appSubnet, testName) => {
    setSpecificTestDetails(details);
    setSpecificTestDetailsAppSubnet(appSubnet);
    setSpecificTestDetailsTestName(testName);
    setVisibleSection(SectionsEnum.TEST_DETAILS);
  };

  /**
   * Button provided on test details Table to navigate back to Test Suite Summary Table
   */
  const handleBackToTestSuiteSummary = () => {
    setVisibleSection(SectionsEnum.REGIONAL_SUITE_SUMMARY);
  };

  /**
   * Button provided on Test Suite Summary Table to navigate back to Health Check summary Table
   */
  const handleBackToHealthCheckSummary = () => {
    setVisibleSection(SectionsEnum.HEALTHCHECK_SUMMARY);
  };

  /**
   *
   * @returns JSX for the Health Check Sumary Table
   */
  const renderHealthCheckTable = () => {
    if (isLoading && visibleSection === SectionsEnum.HEALTHCHECK_SUMMARY && runDetails) {
      return <Skeleton variant="rect" height={400} width="100%" />;
    } else {
      if (visibleSection === SectionsEnum.HEALTHCHECK_SUMMARY && runDetails) {
        const localRunId = moment.utc(selectedRunId, 'YYYYMMDDHHmmss').utcOffset(timezoneOffset).format('YYYY-MM-DD HH:mm:ss');
        // Render the health check status table
        return (
          <Box>
            <Row>
              <h2>Health Check Summary for {localRunId}</h2>
              <Table>
                <TableHead>
                  <TableRow>
                    {runDetails.headers.map((header, index) => (
                      <TableCell key={index} style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {runDetails.rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ fontWeight: 'bold' }}> {row.name}</TableCell>
                      {row.columns.map((col, index2) => (
                        <TableCell key={index2}>
                          <div
                            onClick={() => handleHealthCheckStatusClick(col.details, runDetails.headers[index2 + 1], row.name)}
                            style={{ color: col.status.toLowerCase() === 'passed' ? 'green' : col.status.toLowerCase() === 'failed' ? 'red' : 'inherit' }}>
                            {col.status}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <Row>
                <Button color="primary" id="back-to-test-suite" variant="contained" onClick={handleNewHealthCheckButtonClick}>
                  Initiate new Health Check Run
                </Button>
              </Row>
            </Row>
          </Box>
        );
      }
    }
  };

  /**
   *
   * @returns jsx for the Test Suite Summary Table
   */
  const renderHealthCheckStatusTable = () => {
    if (isLoading && visibleSection === SectionsEnum.REGIONAL_SUITE_SUMMARY) {
      return <Skeleton variant="rect" height={400} width="100%" />;
    } else {
      if (visibleSection === SectionsEnum.REGIONAL_SUITE_SUMMARY) {
        return (
          <Box>
            <Row>
              <h2>
                Test Suite Summary for {testSuiteStatusName} in {testSuiteStatusRegion}
              </h2>
            </Row>
            <Row>
              <Button color="primary" id="back-to-test-suite" variant="contained" onClick={handleBackToHealthCheckSummary}>
                Health Check Summary
              </Button>
            </Row>
            <Row>
              <Table>
                <TableHead>
                  <TableRow>
                    {testSuiteStatusDetails.headers.map((header, index) => (
                      <TableCell key={index} style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {testSuiteStatusDetails.rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ fontWeight: 'bold' }}>{row.name}</TableCell>
                      {row.columns.map((col, index2) => (
                        <TableCell key={index2}>
                          <div
                            onClick={() => handleTestSuiteStatusClick(col.details, testSuiteStatusDetails.headers[index2 + 1], row.name)}
                            style={{ color: col.status.toLowerCase() === 'passed' ? 'green' : col.status.toLowerCase() === 'failed' ? 'red' : 'inherit' }}>
                            {col.status}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Row>
          </Box>
        );
      }
    }
  };

  /**
   *
   * @returns Jsx for the Test Details Table
   */
  const renderSpecificTestTable = () => {
    if (isLoading && visibleSection === SectionsEnum.TEST_DETAILS) {
      return <Skeleton variant="rect" height={400} width="100%" />;
    } else {
      if (visibleSection === SectionsEnum.TEST_DETAILS) {
        // Render the specific test table
        return (
          <Box>
            <Row>
              <Row>
                <h2>
                  Test Details for {specificTestDetailsTestName} in {specificTestDetailsAppSubnet}
                </h2>
              </Row>
              <Row>
                <Button color="primary" id="back-to-test-suite" variant="contained" onClick={handleBackToTestSuiteSummary}>
                  Test Suite Summary
                </Button>
              </Row>
              <Table>
                <TableHead>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}> Test Name </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}> Status </TableCell>
                  <TableCell style={{ fontWeight: 'bold', fontSize: '1.1em' }}> Message </TableCell>
                </TableHead>
                <TableBody>
                  {specificTestDetails.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ fontWeight: 'bold' }}>{row.testName}</TableCell>
                      <TableCell style={{ color: row.status.toLowerCase() === 'passed' ? 'green' : row.status.toLowerCase() === 'failed' ? 'red' : 'inherit' }}>
                        {row.status}
                      </TableCell>
                      <TableCell>{row.message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Row>
          </Box>
        );
      }
    }
  };

  /**
   * Return the JSX for this component
   */
  return (
    <Feature allowed={featureFlags.ACCESS_HEALTH_CHECKS}>
      <ViewContainer title="Health Checks" hidePolicyViewChange={true} hideStartQuote={true}>
        <Card>
          <h2>Select Run ID</h2>
          <Row>
            <Col>
              {runIds.length > 0 ? (
                <Select
                  labelId="runIds-label"
                  disabled={isLoading}
                  value={selectedRunId}
                  onChange={handleRunIdChange}
                  menuItems={runIds.map((runId) => ({
                    value: runId,
                    label: moment.utc(runId, 'YYYYMMDDHHmmss').utcOffset(timezoneOffset).format('YYYY-MM-DD HH:mm:ss'),
                  }))}
                  style={{ marginRight: '10px' }}
                />
              ) : (
                <span>No run IDs available</span>
              )}
            </Col>
            <Col>
              <Button color="primary" id="runids-refresh-button" variant="contained" disabled={isLoading} onClick={handleRefreshButtonClick}>
                Refresh
              </Button>
            </Col>
          </Row>

          {renderHealthCheckTable()}
          {renderHealthCheckStatusTable()}
          {renderSpecificTestTable()}
        </Card>
      </ViewContainer>
    </Feature>
  );
};

export default HealthChecks;
