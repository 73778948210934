import React from 'react';
import PropTypes from 'prop-types';
import MuiListItemText from '@material-ui/core/ListItemText';

import { componentIdStringGenerator } from '../../../helpers';

const ListItemText = (props) => {
  return (
    <MuiListItemText
      {...props}
      id={componentIdStringGenerator('ListItemText', props.id ? props.id : props.primary)}>
      {props.children}
    </MuiListItemText>
  );
};

ListItemText.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  primary: PropTypes.string,
};

export default ListItemText;
